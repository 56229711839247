import React, { useEffect, useState } from "react";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { Button, Checkbox, Modal, Table } from "Uikit";
import { BasePaginationResponse } from "Api/BaseResponse";
import { UserAchievementResponse } from "Api/Responses/AchievementResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";

interface IRemoveAchievementModal {
    id: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    achievementId: string;
    userId: string;
    userFirstName: string;
    userLastName: string;
    fetchUserAchievement: (
        achievementId: string,
        userId: string,
        sort: SortingState,
    ) => Promise<BasePaginationResponse<UserAchievementResponse>>;
    onRemove: (userId: string, achievementIds: string[]) => void;
}
export const RemoveAchievementModal = ({
    id,
    isOpen,
    setIsOpen,
    achievementId,
    userId,
    userFirstName,
    userLastName,
    fetchUserAchievement,
    onRemove,
}: IRemoveAchievementModal) => {
    const [nextStep, setNextStep] = useState(false);

    const [data, setData] = useState<BasePaginationResponse<UserAchievementResponse>>();
    const [sorting, setSorting] = useState<SortingState>([]);

    const [rowSelection, setRowSelection] = useState({});
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const columns = React.useMemo<ColumnDef<UserAchievementResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Кто выдал",
                accessorKey: "givenBy",
                enableResizing: true,
                size: 250,
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <UserAvatar
                                avatarId={info.row.original.givenBy.avatarId}
                                color={info.row.original.givenBy.defaultAvatarColor}
                                userInitials={`${info.row.original.givenBy.firstName?.slice(
                                    0,
                                    1,
                                )}${info.row.original.givenBy.lastName?.slice(0, 1)}`}
                                size={28}
                            />
                            <span className="group-hover:text-blue">
                                {info.row.original.givenBy.lastName + " " + info.row.original.givenBy.firstName}
                            </span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Комментарий",
                accessorKey: "comment",
                footer: (props) => props.column.id,
            },
            {
                header: "Выдана",
                accessorKey: "givenAt",
                enableResizing: true,
                size: 180,
                cell: (info) => {
                    return formatDate(info.row.original.givenAt, DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
        ],
        [],
    );

    const onClose = () => {
        setNextStep(false);
        setData(undefined);

        setSorting([]);
        setRowSelection({});

        setIsOpen(false);
    };

    useEffect(() => {
        if (!isOpen || !fetchUserAchievement || !achievementId || !userId) {
            return;
        }

        const fetch = async () => {
            setData(await fetchUserAchievement(achievementId, userId, sorting));
        };
        fetch().then();
    }, [isOpen, fetchUserAchievement, userId, achievementId, sorting]);

    return (
        <Modal id={id} className="!max-w-200" title="Забрать ачивку" isOpen={isOpen} setIsOpen={onClose}>
            <Modal.Body className="!min-h-[36rem]">
                {nextStep && (
                    <div>
                        <div className="text-sm small leading-6 text-[#9B9B9B] mr-auto">
                            Выбрано ачивок: {selectedRows?.length}
                        </div>
                        <div className="my-4">Вы действительно хотите забрать выбранные ачивки у пользователя?</div>
                    </div>
                )}
                {!nextStep && (
                    <div>
                        <p
                            className="text-sm small leading-6 text-[#9B9B9B] mr-auto"
                            id="adminSettingsRemoveAchieveModalUserName"
                        >
                            {userLastName + " " + userFirstName}
                        </p>
                        <Table
                            rowTrClassName="rounded-lg hover:bg-background"
                            rowClassName="!py-2 border-none after:bg-transparent"
                            columnClassName="!min-w-fit pb-4"
                            columns={columns}
                            data={data?.Content}
                            rowSelection={rowSelection}
                            onRowSelectionChange={setRowSelection}
                            setSelectedRows={setSelectedRows}
                            sorting={sorting}
                            onSortingChange={setSorting}
                        />
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="space-x-4">
                    <Button
                        id="adminSettingsRemoveAchieveModalBtnCancel"
                        variant="outline"
                        size="medium"
                        color="secondary"
                        onClick={() => {
                            if (nextStep) {
                                setNextStep(false);
                            } else {
                                onClose();
                            }
                        }}
                    >
                        {nextStep ? "Назад" : "Отмена"}
                    </Button>
                    <Button
                        id="adminSettingsRemoveAchieveModalBtnOk"
                        size="medium"
                        color="danger"
                        disabled={!nextStep && selectedRows.length === 0}
                        onClick={() => {
                            if (nextStep) {
                                onRemove(
                                    userId,
                                    Object.keys(selectedRows).map((index) => data!.Content[Number(index)].id),
                                );
                                onClose();
                            } else {
                                setNextStep(true);
                            }
                        }}
                    >
                        {nextStep ? "Подтвердить" : "Забрать ачивку"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
