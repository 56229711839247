import React, { useEffect, useState } from "react";
import { ComboBox, CustomDatePicker } from "Uikit";

export const DateRangeField = ({ accessor, value, onChange }: any) => {
    const options = [
        { value: "last-day", label: "Последний день" },
        { value: "last-week", label: "Последняя неделя" },
        { value: "last-month", label: "Последний месяц" },
        { value: "last-3-months", label: "Последние 3 месяца" },
        { value: "last-year", label: "Последний год" },
        { value: "range", label: "Произвольный диапазон" },
    ];
    const [type, setType] = useState<{ value: string; label: string } | null>(
        value ? options.find(({ value: optionValue }) => optionValue === value.type) ?? null : null,
    );
    const [date, setDate] = useState<{ startDate: Date; endDate: Date }>(
        value
            ? value.date
            : {
                  startDate: new Date(),
                  endDate: new Date(),
              },
    );
    const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);

    const onChangeType = (type: { value: string; label: string } | null) => {
        setType(type);

        if (type === null) {
            return;
        }

        let startDate = new Date();
        let endDate = new Date();

        if (type.value === "last-day") {
            startDate.setHours(0, 0, 0);
            endDate.setHours(23, 59, 59);
        } else if (type.value === "last-week") {
            startDate.setDate(startDate.getDate() - 7);
            startDate.setHours(0, 0, 0);
            endDate.setHours(23, 59, 59);
        } else if (type.value === "last-month") {
            startDate.setDate(startDate.getDate() - 30);
            startDate.setHours(0, 0, 0);
            endDate.setHours(23, 59, 59);
        } else if (type.value === "last-3-months") {
            startDate.setDate(startDate.getDate() - 90);
            startDate.setHours(0, 0, 0);
            endDate.setHours(23, 59, 59);
        } else if (type.value === "last-year") {
            startDate.setDate(startDate.getDate() - 365);
            startDate.setHours(0, 0, 0);
            endDate.setHours(23, 59, 59);
        } else if (type.value === "range") {
            startDate = date.startDate;
            endDate = date.endDate;

            startDate.setHours(0, 0, 0);
            endDate.setHours(23, 59, 59);
        }

        setDate({ startDate, endDate });
    };

    useEffect(() => {
        if (value === undefined) {
            setType(null);
        }
    }, [value]);
    useEffect(() => {
        if (date.startDate >= date.endDate) {
            onChange(accessor, undefined);
            return;
        }

        onChange(accessor, { date, type: type?.value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessor, onChange, date]);

    return (
        <>
            <ComboBox
                className="mt-3 sm:mt-4 z-[100]"
                placeholder="За все время"
                isCreatable={false}
                isMulti={false}
                options={options}
                value={type}
                onChange={onChangeType}
            />
            {type?.value === "range" && (
                <div className={`filter-range flex gap-5 mt-4${isDatePickerOpened ? " relative z-[100]" : ""}`}>
                    <div className="max-w-[50%] relative">
                        <CustomDatePicker
                            dateFormat="от dd.MM.yyyy"
                            selectsStart
                            startDate={date.startDate}
                            endDate={date.endDate}
                            selected={date.startDate}
                            onChange={(p: Date) => {
                                p.setHours(0, 0, 0);
                                setDate({ ...date, startDate: p });
                            }}
                            onFocus={() => {
                                setIsDatePickerOpened(true);
                            }}
                            onBlur={() => {
                                setIsDatePickerOpened(false);
                            }}
                        />
                    </div>
                    <div className="max-w-[50%] relative">
                        <CustomDatePicker
                            className="!border-red focus:ring-red"
                            dateFormat="до dd.MM.yyyy"
                            selectsEnd
                            minDate={date.startDate}
                            startDate={date.startDate}
                            endDate={date.endDate}
                            selected={date.endDate}
                            onChange={(p: Date) => {
                                p.setHours(23, 59, 59);
                                setDate({ ...date, endDate: p });
                            }}
                            onFocus={() => {
                                setIsDatePickerOpened(true);
                            }}
                            onBlur={() => {
                                setIsDatePickerOpened(false);
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
