import React from "react";
import Skeleton from "react-loading-skeleton";

import { Icon, Icons } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { TestsCardList } from "./TestsCardList";
import { TestsCardListMobile } from "./TestsCardListMobile";
import { TestUserListResponse } from "Api/Responses/TestResponse";
import { testsKeys, useChangeTestFavoriteStatus, useReviewTests } from "./tests.hooks";
import { Preloader } from "Components/Preloader/Preloader";
import { useScreenSize } from "hooks/useMediaQuery";

export const TestsReview = () => {
    const { data: tests, isFetched } = useReviewTests();

    const { mutate: mutateFavoriteStatus } = useChangeTestFavoriteStatus();

    const onFavoriteChange = (resource: TestUserListResponse, isFavorite: boolean) => {
        mutateFavoriteStatus({ resource, isFavorite, queryKeys: testsKeys.listReview() });
    };

    const { size } = useScreenSize();
    const isSmall = size === "small";

    return (
        <>
            <div className="relative">
                <Preloader
                    className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] gap-y-5 sm:gap-x-6.5 2xl:gap-x-8 sm:gap-y-8"
                    isShow={!isFetched}
                >
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={p}>
                                <div className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                                <div className="leading-5 line-clamp-2 pt-3">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                            </div>
                        );
                    })}
                </Preloader>
            </div>
            {isFetched && tests?.length === 0 && (
                <Empty
                    title="На проверке ничего нет"
                    description={
                        <span>
                            На эту вкладку будет попадать то, что не
                            <br />
                            прошло автоматическую проверку
                        </span>
                    }
                    topElement={
                        <div className="flex-center mb-4 2xl:mb-5">
                            <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                <Icon
                                    icon={Icons.EmojiSad}
                                    width={36}
                                    height={36}
                                    color="fill-primary"
                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                />
                            </div>
                        </div>
                    }
                />
            )}
            {tests && tests.length > 0 && (
                <>
                    <div className="hidden sm:block">
                        <TestsCardList
                            id="userTestsReview"
                            isFinished={false}
                            isReview={true}
                            showStatusChangeTime
                            data={tests}
                            onFavoriteChange={onFavoriteChange}
                        />
                    </div>
                    {isSmall && (
                        <div className="block sm:hidden">
                            <TestsCardListMobile id="userTestsReview" isFinished={false} isReview={true} data={tests} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
