import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Player from "video.js/dist/types/player";
import { TVoidFunction } from "types";

interface VideoPlayerHookProps {
    src: any;
    rewind?: boolean;
    controls?: boolean;
    fluid?: boolean;
    startTime?: number;
    autoplay?: boolean;
    onError?: TVoidFunction;
}

export function useVideoPlayer({
    src,
    controls,
    fluid,
    autoplay,
    onError: onErrorHandler,
    startTime = 0,
    rewind = true,
}: VideoPlayerHookProps) {
    const playerRef = useRef<any>(null);
    const [duration, setDuration] = useState(0);
    const [isReady, setIsReady] = useState(false);
    const memoizedSrc = useMemo(() => {
        return { src, type: "video/mp4" };
    }, [src]);

    const onReady = useCallback(
        (player: Player) => {
            if (!memoizedSrc) {
                return;
            }
            playerRef.current = player;
            setIsReady(true);
        },
        [memoizedSrc],
    );

    const onError = useCallback(() => {
        onErrorHandler?.();
    }, [onErrorHandler]);

    const getPlayerProps = useCallback(() => {
        return {
            controls,
            fluid,
            autoplay,
            onReady,
            rewind,
            watchTime: startTime,
            src: memoizedSrc,
            onError,
        };
    }, [controls, fluid, autoplay, onReady, rewind, startTime, memoizedSrc, onError]);

    useEffect(() => {
        if (!playerRef.current || !isReady) {
            return;
        }

        if (!memoizedSrc?.src) {
            setIsReady(false);
        } else {
            playerRef.current.one("canplay", () => {
                setTimeout(() => {
                    playerRef.current.currentTime(startTime);
                }, 0 /* 2000 */);
            });
            playerRef.current.one("durationchange", () => {
                setDuration(playerRef.current?.duration() || 0);
            });
        }
    }, [memoizedSrc, playerRef, isReady, startTime]);

    return {
        player: playerRef.current,
        getPlayerProps,
        duration,
    };
}
