import { Icon, Icons } from "Uikit/Icon/Icon";
import clsx from "clsx";
import React from "react";

export const FilterLink = ({ href, value, name }: { href: string; value: number; name: string }) => {
    const content = (
        <div className="inline-flex space-x-1">
            <span className="flex align-middle items-center">
                <Icon
                    icon={Icons.ShareBox}
                    width={18}
                    height={18}
                    color={value === 0 ? "fill-[#939393]" : "fill-primary hover:underline"}
                />
            </span>
            <span className={clsx("flex !font-sans", value === 0 ? "text-[#939393]" : "text-primary hover:underline")}>
                {name}
            </span>
        </div>
    );

    if (value === 0) {
        return content;
    }
    return (
        <a href={href} target="_blank" rel="noreferrer" className="flex">
            {content}
        </a>
    );
};
