import React, { ReactNode } from "react";

export const ContentLayout = ({
    id,
    className,
    children,
}: {
    id?: string;
    className?: string;
    children: ReactNode;
}) => {
    return (
        <div id={id} className={`relative flex flex-col pb-6 px-3 sm:px-4 ${className ?? ""}`}>
            {children}
        </div>
    );
};
