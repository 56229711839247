import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Api from "Api";
import { Breadcrumbs, Button, Icon, Icons } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { ContentLayout } from "Containers";
import { useNavigateToSource } from "hooks/useNavigateToSouce";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { useDispatch } from "react-redux";

export const TaskSended = () => {
    const dispatch = useDispatch();

    const { id } = useParams();
    const { goSourcePage } = useNavigateToSource("/tasks");

    const dataQuery = useQuery(
        ["task", "collection"],
        async () => {
            return await Api.TaskPublic.Preview({ uuid: id as string });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data, isLoading, isError } = dataQuery;

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Задание"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    return (
        <>
            <ContentLayout className="mx-auto sm:pl-4 sm:pr-6.5 max-w-[1216px] 2xl:max-w-[1506px] h-full !pb-0">
                <Breadcrumbs className="hidden sm:block">
                    <Breadcrumbs.Link title="Задания" url="/tasks" />
                    {data?.title && <Breadcrumbs.Link title={data?.title} url={`/task/${id}`} />}
                </Breadcrumbs>
                {isLoading && (
                    <div className="w-full h-full flex-center px-10">
                        <Loader />
                    </div>
                )}
                {isError && (
                    <div className="w-full h-full flex-center px-10">
                        <p className="text-md text-center text-red">
                            Произошла ошибка запроса - невозможно загрузить страницу
                        </p>
                    </div>
                )}
                {data && (
                    <div className="flex flex-col w-full h-full">
                        <div className="hidden sm:flex justify-end">
                            <Button
                                className="!border-0 hover:!shadow-none active:!bg-transparent active:!ring-0 !p-0 2xl:w-6.25 2xl:h-6.25"
                                variant="outline"
                                color="secondary"
                                onClick={goSourcePage}
                            >
                                <Icon
                                    icon={Icons.Close}
                                    width={20}
                                    height={20}
                                    color="fill-[#939393]"
                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                />
                            </Button>
                        </div>
                        <div className="flex-center flex-col pt-6 sm:pt-0 flex-grow text-center sm:text-left">
                            <h1 className="mb-4 2xl:mb-5 2xl:!text-4xl 2xl:!leading-[41px] text-center">
                                Задание пройдено и отправлено на проверку
                            </h1>
                            <p className="text-blue-drk 2xl:text-md">После проверки Вы получите уведомление</p>
                        </div>
                        <div className="mt-auto sm:h-[246px] 2xl:h-[308px]"></div>
                    </div>
                )}
            </ContentLayout>
            <div className="absolute bottom-0 left-0 w-full flex flex-center mt-auto sm:py-22.5 sm:bg-blue-lightest text-center sm:h-[246px] 2xl:h-[308px]">
                <Button
                    color="primary"
                    className="w-full text-center px-14 py-5 sm:!px-5.5 sm:!py-2.5 2xl:!px-7 2xl:!py-3 sm:text-md 2xl:!text-lg sm:w-[272px] 2xl:w-[340px] sm:!h-[66px] 2xl:!h-[82px] sm:!rounded-xxl 2xl:!rounded-2.5xl"
                    onClick={goSourcePage}
                >
                    Вернуться в раздел
                </Button>
            </div>
        </>
    );
};
