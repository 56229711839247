import React from "react";
import clsx from "clsx";
import { Icon, Icons, flash } from "Uikit";
import { TestRequestQuestionAnswer } from "Api/Requests/TestRequest";
import { UIErrorMessages } from "Enums";

interface ITestContentItemSelectImage {
    index: number;
    answer: TestRequestQuestionAnswer;
    deletable: boolean;
    onChange: (index: number, answer: TestRequestQuestionAnswer) => void;
    onDelete: (index: number) => void;
}

export const TestContentItemSelectImage = ({
    index,
    answer,
    deletable,
    onChange,
    onDelete,
}: ITestContentItemSelectImage) => {
    return (
        <div
            className="group relative w-45 h-45 rounded-2lg cursor-pointer"
            onClick={() => onChange(index, { ...answer, isCorrect: !answer.isCorrect })}
        >
            <div
                className={clsx(
                    "absolute top-2.25 left-2.25 flex justify-center items-center w-7.5 h-7.5 rounded-full cursor-pointer",
                    answer.isCorrect ? "bg-blue" : "bg-black opacity-50",
                )}
            >
                <Icon icon={Icons.Check} width={22} height={22} color="fill-white" />
            </div>
            {deletable && (
                <div
                    className="absolute top-3 right-3 flex justify-center items-center w-6 h-6 bg-white rounded-full cursor-pointer
                    opacity-0 group-hover:opacity-50 group-hover:hover:opacity-100 group-hover:hover:bg-blue group-hover:hover:[&>*]:fill-white"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete(index);
                    }}
                >
                    <Icon icon={Icons.Close} width={20} height={20} color="fill-gray" />
                </div>
            )}
            {answer.pictureId !== null && (
                <img
                    className={clsx(
                        "w-full h-full rounded-2lg object-cover",
                        answer.isCorrect ? "border-3 border-blue" : "",
                    )}
                    src={
                        typeof answer.pictureId === "object"
                            ? answer.pictureId?.base64
                            : "/service/lms-upload/api/file/download/" + answer.pictureId
                    }
                    alt={answer.id}
                    onError={() => {
                        flash.error(UIErrorMessages.FILE_LOADING_ERROR);

                        onDelete(index);
                    }}
                />
            )}
        </div>
    );
};
