// Склонение числительных
export const numWord = (value: number, words: string[]) => {
    // ['вещь', 'вещи', 'вещей']
    value = Math.abs(value) % 100;
    const num = (Math.abs(value) % 100) % 10;

    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num == 1) return words[0];

    return words[2];
};
