import { createNanoEvents, Emitter } from "nanoevents";
import { useEffect, useRef } from "react";

const formEmitter = createNanoEvents();

export const useFormEmitter = () => {
    const emitterRef = useRef<Emitter>();

    useEffect(() => {
        emitterRef.current = formEmitter;
    }, [emitterRef]);

    return emitterRef;
};
