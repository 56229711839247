import { Expose, Type } from "class-transformer";
import { BaseResponse } from "Api/BaseResponse";

export class AchievementUser {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public email: string;

    @Expose()
    @Type(() => Number)
    public rating: number;
}

export class AchievementResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public rating: string;

    @Expose()
    @Type(() => String)
    public maxQuantity: string;

    @Expose()
    @Type(() => Number)
    public overallQuantity: number;

    @Expose()
    @Type(() => Boolean)
    public enabled: boolean;

    @Expose()
    @Type(() => Boolean)
    public userProfileAssignmentDisabled: boolean;

    @Expose()
    @Type(() => Number)
    public modifyTimestamp: number;
}

export class AchievementHistoryResponse extends BaseResponse {
    @Expose()
    @Type(() => AchievementResponse)
    public achievement: AchievementResponse;

    @Expose()
    @Type(() => AchievementUser)
    public madeBy: AchievementUser;

    @Expose()
    @Type(() => AchievementUser)
    public user: AchievementUser;

    @Expose()
    @Type(() => String)
    public action: string;

    @Expose()
    @Type(() => Number)
    public timestamp: number;
}

export class AchievementUserResponse {
    @Expose()
    @Type(() => String)
    public achievementId: string;

    @Expose()
    @Type(() => Number)
    public quantity: number;

    @Expose()
    @Type(() => Number)
    public lastGivenAt: number;

    @Expose()
    @Type(() => AchievementUser)
    public givenTo: AchievementUser;
}

export class UserAchievementResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => AchievementResponse)
    public achievement: AchievementResponse;

    @Expose()
    @Type(() => AchievementUser)
    public givenTo: AchievementUser;

    @Expose()
    @Type(() => AchievementUser)
    public givenBy: AchievementUser;

    @Expose()
    @Type(() => String)
    public comment: string;

    @Expose()
    @Type(() => Number)
    public givenAt: number;
}
