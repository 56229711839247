import { BaseRequest } from "../BaseRequest";
import { ProgressStatus, ResourceType, ResourceState } from "Enums";

export class CommonsAddFavoritesRequest extends BaseRequest {
    public resourceId: string;
    public resourceSolutionId?: string;
    public componentId?: string;
    public courseId?: string;
    public title: string;
    public logoId: string;
    public state: ResourceState;
    public type: ResourceType;
    public progressStatus: ProgressStatus;
    public ratingPoints: number;
    public deadlineTimestamp: number | null | undefined;
    public passingNumber: number | null | undefined;
}

export class CommonsRemoveFavoritesRequest extends BaseRequest {
    public resourceId: string;
    public passingNumber: number | null | undefined;
    public type: ResourceType;
}
