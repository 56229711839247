import React, { useState } from "react";
import { Button, Icon, Icons, PopoverList } from "Uikit";
import { UserState } from "types/User";
import { TVoidFunction } from "types";

interface RowActionPopoverProps {
    type: "ACTIVE" | "BLOCKED";
    setRowFunc: TVoidFunction;
    setBlockType: (type: "block" | "unblock") => void;
    setBlockModalIsOpen: (isOpen: boolean) => void;
    onMoveClick: TVoidFunction;
}

export const PopoverRowAction = ({
    type,
    setRowFunc,
    setBlockType,
    setBlockModalIsOpen,
    onMoveClick,
}: RowActionPopoverProps) => {
    const [visible, setVisible] = useState(false);

    if (type === UserState.BLOCKED) {
        return (
            <Button
                shape="round"
                color="common"
                className="!text-black "
                icon={<Icon icon={Icons.Refresh} width={20} height={20} color="fill-blue-drk hover:fill-blue-hover" />}
                iconPlacement="center"
                onClick={() => {
                    setVisible(false);
                    setRowFunc();
                    setBlockType("unblock");
                    setBlockModalIsOpen(true);
                }}
            />
        );
    }

    return (
        <div id="membersRowActionPopover">
            <PopoverList offset={[0, 18]} visible={visible} onClickOutside={() => setVisible(false)} className="">
                <Button
                    shape={"round"}
                    color={"common"}
                    className="!text-blue-drk"
                    onClick={() => setVisible(!visible)}
                >
                    ...
                </Button>
                <PopoverList.Item
                    name="teamMove"
                    onClickItem={() => {
                        setVisible(false);
                        setRowFunc();
                        onMoveClick();
                    }}
                >
                    Изменить команду
                </PopoverList.Item>
                <PopoverList.Item
                    name="unblockUser"
                    onClickItem={() => {
                        setVisible(false);
                        setRowFunc();
                        setBlockType("block");
                        setBlockModalIsOpen(true);
                    }}
                >
                    Заблокировать
                </PopoverList.Item>
            </PopoverList>
        </div>
    );
};
