import React, {
    memo,
    MutableRefObject,
    PropsWithChildren,
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
} from "react";
import { Icon, Icons, ITreeProps, Tree, TreeSearch, NodeType } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { ID } from "types/ID";
import { TeamTreeAllBasicInfoResponse, TeamTreeResponse } from "Api/Responses/TeamResponse";
import { useQuery } from "react-query";
import Api from "Api";
import tail from "lodash/tail";
import { isEmpty } from "lodash";
import { numWord } from "helpers/numWord";
import { useTreeRef } from "hooks/useTreeRef";
import clsx from "clsx";
import { UserRole } from "types/User";

interface TeamTreeProps {
    id?: string;
    outerRef?: MutableRefObject<any>;

    onlyIncludes?: ID[];
    mustExclude?: ID[];
    checkedTeams?: ID[];
    isAllDisabled?: boolean;
    showNodesTableHeader?: boolean;
    showSelectAllCheckBox?: boolean;

    checkable: boolean;
    selectable?: boolean;
    onSelectNode?: (node: any) => void;
    onNodeAddComponentClick?: (arg?: string) => void;
    nodeAddComponentLink?: string;
    sortFunction?: ((arg1: TeamTreeNode, arg2: TeamTreeNode) => number) | null;
    checkOnNameClick?: boolean;

    isRoleTeams?: boolean;
}

const NODE_TYPE_STUB = "PROJECT";

export interface TeamTreeNode {
    id: string;
    name: string;
    membersCount: number;
    nodeType: string;
    children: TeamTreeNode[];
    highlight?: boolean;
    isDisabled?: boolean;
    state: {
        checked?: boolean;
        indeterminate?: boolean;
        error?: boolean;
        open?: boolean;
    };
}

export const TeamTree = memo(
    ({
        id,
        outerRef,
        onlyIncludes = [],
        mustExclude = [],
        checkable,
        selectable,
        onSelectNode,
        renderSearch,
        inLayout,
        nodeAddComponent,
        checkedChange,
        checkedTeams,
        onSelectAll,
        searchable,
        onNodeAddComponentClick,
        nodeAddComponentLink,
        disabled,
        renderHeader,
        renderSelectAllHead,
        allChecked,
        isAllDisabled,
        showNodesTableHeader = true,
        showSelectAllCheckBox = true,
        sortFunction = ({ name: name1 }: TeamTreeNode, { name: name2 }: TeamTreeNode) => {
            if (name1 > name2) {
                return 1;
            }
            if (name2 > name1) {
                return -1;
            }
            return 0;
        },
        checkOnNameClick = true,
        hideMembers = false,
        isRoleTeams = false,
    }: PropsWithChildren<TeamTreeProps> &
        Pick<
            ITreeProps,
            | "renderSearch"
            | "inLayout"
            | "nodeAddComponent"
            | "checkedChange"
            | "onSelectAll"
            | "searchable"
            | "disabled"
            | "renderHeader"
            | "renderSelectAllHead"
            | "showNodesTableHeader"
            | "allChecked"
        > & {
            hideMembers?: boolean;
        }) => {
        const treeRef = useTreeRef(outerRef);
        const checkedItemsRef = useRef<any[]>([]);

        const [initialCheckedTeams] = useState(checkedTeams);
        const [initialOnlyIncludes] = useState(onlyIncludes);
        const [initialMustExclude] = useState(mustExclude);

        const [teamTree, setTeamTree] = useState<TeamTreeNode[]>([]);

        const [isTreeSorted, setIsTreeSorted] = useState(false);
        const [disabledTeams, setDisabledTeams] = useState<string[]>();

        const { data: teams = [] as TeamTreeResponse[] | TeamTreeAllBasicInfoResponse[], isFetching: isTeamsFetching } =
            useQuery(
                ["teams", "tree", "basic-info", "collection", isRoleTeams],
                async () => {
                    if (isRoleTeams) {
                        return await Api.LMSRoles.getTeams();
                    }

                    return await Api.Teams.TreeAllBasicInfo([]);
                },
                {
                    keepPreviousData: false,
                    refetchOnWindowFocus: false,
                    staleTime: 5 * 60 * 1000,
                },
            );

        const { data: user } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
        const isAdmin = useMemo(() => user && user.role === UserRole.ADMIN, [user]);

        const { data: role } = useQuery(["role", "get", user?.id], () => Api.Role.Id(user!.id), {
            enabled: !!user && isAdmin,
        });

        const collectTeamIds = useCallback(
            (teams: TeamTreeResponse[] | TeamTreeAllBasicInfoResponse[], collectedIds = [] as string[]) => {
                if (!teams) return collectedIds;

                teams.forEach((team) => {
                    collectedIds.push(team.id);

                    if (team.subTeams && team.subTeams.length > 0) {
                        collectTeamIds(team.subTeams, collectedIds);
                    }
                });

                return collectedIds;
            },
            [],
        );

        const sortTeamTree = (tree: TeamTreeNode[], reverse = false) => {
            const sortedTree = reverse
                ? tree.reverse()
                : tree.sort(sortFunction as unknown as (a: TeamTreeNode, b: TeamTreeNode) => number);
            for (const element of sortedTree) {
                if (element?.children?.length > 0) {
                    element.children = sortTeamTree(element.children, reverse);
                }
            }

            return sortedTree;
        };

        const SelectAllHead = () => {
            return (
                <div className="flex items-center justify-between h-full grow border-b border-[#E6E9ED] uppercase p4-table">
                    <div
                        className={clsx("flex items-center", sortFunction && " cursor-pointer")}
                        onClick={
                            sortFunction
                                ? () => {
                                      const sortedTeam = sortTeamTree(teamTree, isTreeSorted);
                                      setTeamTree(sortedTeam);
                                      treeRef.current?.tree.loadData(sortedTeam);
                                      treeRef.current?.tree.checkNode(checkedItemsRef.current[0]);
                                      setIsTreeSorted((prevSorted) => {
                                          return !prevSorted;
                                      });

                                      // Установка выбранных нод после соротировки (иначе они сбрасываются)
                                      setTimeout(() => {
                                          const checkedNodes = checkedItemsRef.current;
                                          let parentNodeObj: TeamTreeNode | null = null;
                                          checkedNodes.forEach(({ id, children }) => {
                                              if (id.startsWith("root:")) {
                                                  const targetNode = treeRef.current?.tree.getNodeById(id);
                                                  treeRef.current?.tree.openNode(targetNode);
                                                  children.forEach((item: TeamTreeNode) => {
                                                      treeRef.current?.tree.checkNode(item, true);
                                                  });
                                              } else {
                                                  const targetNode = treeRef.current?.tree.getNodeById(id);
                                                  const parentNode = targetNode.parent;
                                                  if (parentNodeObj?.id !== parentNode.id) {
                                                      parentNodeObj = parentNode;
                                                      treeRef.current?.tree.openNode(parentNode);
                                                  }
                                                  treeRef.current?.tree.checkNode(targetNode, true);
                                              }
                                          });
                                      }, 0);
                                  }
                                : undefined
                        }
                    >
                        <span className="text-gray-text">Команда</span>
                        {sortFunction && (
                            <div className="ml-1">
                                <Icon color="fill-gray-text" icon={Icons.Sort} width={14} height={14} />
                            </div>
                        )}
                    </div>
                    {!hideMembers && (
                        <div className="flex items-center">
                            <span className="text-gray-text">Кол-во участников</span>
                            <div className="ml-3">
                                <Icon color="fill-secondary" icon={Icons.Sort} width={14} height={14} />
                            </div>
                        </div>
                    )}
                </div>
            );
        };

        useEffect(() => {
            if (!isAdmin || !teams || !role) return;

            const allTeamIds = [] as string[];
            collectTeamIds(teams, allTeamIds);

            const visibleTeamIds = role.visibleTeams.map((t) => t.id);
            const disabledTeamsResult = allTeamIds.filter((teamId) => !visibleTeamIds.includes(teamId));
            setDisabledTeams(disabledTeamsResult);
        }, [teams, role, isAdmin, collectTeamIds]);

        useEffect(() => {
            if (isEmpty(teams) || (isAdmin && (!role || !disabledTeams))) {
                return;
            }

            setTeamTree(
                teams?.length > 0
                    ? createTeamTree(
                          teams,
                          initialMustExclude,
                          initialOnlyIncludes,
                          disabledTeams,
                          initialCheckedTeams,
                          checkable,
                          isRoleTeams,
                      )
                    : [],
            );
        }, [
            teams,
            initialOnlyIncludes,
            initialMustExclude,
            initialCheckedTeams,
            disabledTeams,
            isAdmin,
            role,
            checkable,
            isRoleTeams,
        ]);

        useEffect(() => {
            if (checkable && teamTree.length !== 0 && treeRef.current?.tree?.nodeTable.data) {
                treeRef.current.tree.nodes.forEach((node: any) => {
                    const checked = checkedTeams?.includes(node.id.replace("root:", ""));
                    if (!checked) {
                        node.id = node.id.replace("root:", "");
                    }
                    treeRef.current.tree.checkNode(node, checked);
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checkedTeams, teamTree]);

        useEffect(() => {
            if (treeRef.current?.tree) {
                treeRef?.current?.tree?.setAllNodesDisabled(isAllDisabled);
            }
        }, [isAllDisabled, treeRef]);

        return (
            <>
                {isTeamsFetching ? (
                    <div className="flex justify-center items-center h-full">
                        <Loader />
                    </div>
                ) : (
                    <Tree
                        id={id}
                        data={teamTree}
                        inLayout={inLayout}
                        renderSearch={renderSearch ?? DefaultSearch}
                        onSelectAll={onSelectAll}
                        renderSelectAllHead={renderSelectAllHead ?? SelectAllHead}
                        nodeWrapperClass=""
                        nodeAddComponent={
                            nodeAddComponent ??
                            ((props: INodeMembersLayoutProps) => (
                                <NodeMembersLayout {...props} hideMembers={hideMembers} />
                            ))
                        }
                        outerRef={treeRef}
                        checkable={checkable}
                        selectable={selectable}
                        onSelectNode={onSelectNode}
                        checkedChange={(nodes: any, targetNode?: any, tree?: any) => {
                            checkedChange?.(nodes, targetNode, tree);
                            checkedItemsRef.current = Array.from(
                                new Set(
                                    Object.values(nodes).reduce((cur: any[], acc: any) => {
                                        return [...(acc as any[]), ...cur];
                                    }, []),
                                ),
                            );
                        }}
                        searchable={searchable}
                        onNodeAddComponentClick={onNodeAddComponentClick}
                        nodeAddComponentLink={nodeAddComponentLink}
                        checkOnNameClick={checkOnNameClick}
                        disabled={disabled}
                        checkedWhenDisabled={false}
                        isTeamTree
                        rowHeight={32}
                        renderHeader={renderHeader}
                        allChecked={allChecked}
                        showNodesTableHeader={showNodesTableHeader}
                        showSelectAllCheckBox={showSelectAllCheckBox}
                        checkedNodesIds={checkedTeams}
                    />
                )}
            </>
        );
    },
);

const DefaultSearch = (searchProps: { query: string; setQuery: (query: string) => void }) => (
    <div className="flex justify-between items-center">
        <div className="flex justify-between items-center gap-4">
            <div className="min-w-[360px] shrink-0">
                <TreeSearch classNames={{ container: "pl-0" }} placeholder="Поиск по названию" {...searchProps} />
            </div>
        </div>
    </div>
);

interface INodeMembersLayoutProps {
    node: any;
    hideMembers?: boolean;
}

const NodeMembersLayout = ({ node, hideMembers = false }: INodeMembersLayoutProps) => {
    if (hideMembers) {
        return null;
    }

    const getMembersCount = (node: any) => {
        let response = node.membersCount;

        for (const element of node.children) {
            if (element.name === "Текущая команда") {
                continue;
            }

            response += getMembersCount(element);
        }

        return response;
    };
    const getMembersLink = (node: any) => {
        let response = [node.id.replace("root:", "")];

        for (const element of node.children) {
            if (element.name === "Текущая команда") {
                continue;
            }

            response = response.concat(getMembersLink(element));
        }

        return response;
    };

    const membersCount = getMembersCount(node);
    const membersLink = getMembersLink(node).join(",");

    return (
        <div className="flex">
            <div className="w-42 pl-3 p2">
                <a
                    className={`flex items-center gap-1 cursor-pointer ml-auto shrink-0 ${
                        membersCount === 0 ? "pointer-events-none text-gray-light" : ""
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    href={`/admin/members?teamId.in=${membersLink}`}
                >
                    <Icon
                        color={membersCount === 0 ? "fill-gray-light" : "fill-primary"}
                        icon={Icons.ShareBox}
                        width={18}
                        height={18}
                    />
                    {`${membersCount ?? 0} ${numWord(membersCount ?? 0, ["участник", "участника", "участников"])}`}
                </a>
            </div>
        </div>
    );
};

export const createTeamTree = (
    teams: TeamTreeResponse[] | TeamTreeAllBasicInfoResponse[],
    exclude: ID[] = [],
    includeOnly: ID[] = [],
    disabledTeams: ID[] = [],
    checkedTeams: ID[] = [],
    checkable = false,
    isRoleTeams = false,
): TeamTreeNode[] => {
    if (teams.length === 0) {
        return [];
    }

    const filter = (team: { id: string }): boolean => {
        const id = team.id.startsWith("root:") ? team.id.substring(5) : team.id;
        return !!id && !exclude.includes(id) && (includeOnly.length === 0 || includeOnly.includes(id));
    };

    return [...teams]
        .sort((teamA, teamB) => teamB.subTeams.length - teamA.subTeams.length)
        .filter(filter)
        .map((team) => {
            let children: TeamTreeNode[] = createTeamTree(
                // const children: TeamTreeNode[] = createTeamTree(
                team.subTeams,
                [],
                [],
                disabledTeams,
                checkedTeams,
                checkable,
                isRoleTeams,
            ); /* .filter(filter) */

            const isDisabled = disabledTeams.includes(team.id);
            if (checkable && children.length > 0) {
                children = [
                    {
                        id: team.id,
                        name: "Текущая команда",
                        membersCount: team.usersCount ?? 0,
                        nodeType: NodeType.ACTUAL_ROOT,
                        children: [] as TeamTreeNode[],
                        highlight: false,
                        isDisabled: isDisabled || (isRoleTeams && !(team as any)["accessible"]),
                        state: {
                            checked: checkedTeams.includes(team.id),
                            indeterminate: false,
                            error: false,
                        },
                    } as TeamTreeNode,
                ].concat(children);
            }

            const id = children.length > 0 ? `root:${team.id}` : team.id;

            const state = getNodeState(
                children,
                {
                    checked: checkedTeams.includes(id),
                    indeterminate: false,
                    error: false,
                    open: true,
                },
                checkable,
            );

            return {
                id,
                name: team.name,
                membersCount: team.usersCount ?? 0,
                nodeType: NODE_TYPE_STUB,
                children,
                highlight: false,
                isDisabled: isDisabled || (isRoleTeams && !(team as any)["accessible"]),
                state,
            };
        });
};

interface NodeState {
    checked: boolean;
    indeterminate: boolean;
    error: boolean;
    open: boolean;
}

const getNodeState = (children: TeamTreeNode[], state: NodeState, checkable: boolean): NodeState => {
    if (children.length === 0) {
        return Object.freeze(state);
    }

    const allNodes = flatten(children, []);
    const checked = checkable ? allNodes.every((node) => node.state.checked) : state.checked;
    return {
        checked,
        // indeterminate: !checked && allNodes.some((node) => node.state.checked),
        indeterminate: checkable
            ? !checked && allNodes.some((node) => node.state.checked)
            : checked || allNodes.some((node) => node.state.checked),
        open: !checked && allNodes.some((node) => node.state.checked),
        error: false,
    };
};

export const flatten = (children: TeamTreeNode[], acc: TeamTreeNode[]): TeamTreeNode[] => {
    if (children.length === 0) {
        return acc;
    }

    return flatten((children[0].children ?? []).concat(tail(children)), acc.concat(children[0]));
};
