import { getSourcePage, setSourcePage, SourcePage } from "slices/utilSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";

export const useNavigateToSource = (fallbackPath?: string) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sourcePage = useSelector(getSourcePage);
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        const source = params.get("source");
        if (source) {
            dispatch(setSourcePage(source as SourcePage));
            setParams(() => new URLSearchParams(""), { replace: true });
        }
    }, [params, dispatch, setParams]);

    const goSourcePage = useCallback(() => {
        const path = fallbackPath ? fallbackPath : getSourcePagePath(sourcePage);
        console.log("useNavigateToSouce goSourcePage fallbackPath", fallbackPath);
        console.log("useNavigateToSouce goSourcePage getSourcePagePath(sourcePage)", getSourcePagePath(sourcePage));
        navigate(path);
        dispatch(setSourcePage(null));
    }, [dispatch, navigate, sourcePage, fallbackPath]);

    return useMemo(
        () => ({
            goSourcePage,
            sourcePage,
            navigateWithCurrentSource: (url: string) => navigate(`${url}?source=${sourcePage}`),
        }),
        [goSourcePage, sourcePage, navigate],
    );
};

const getSourcePagePath = (sourcePage?: SourcePage | null) => {
    switch (sourcePage) {
        case SourcePage.HOME:
            return "/";
        case SourcePage.TESTS:
            return "/tests";
        default:
            return "/";
    }
};
