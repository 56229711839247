import React, { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Icon, Icons } from "Uikit/Icon/Icon";
import { Checkbox } from "Uikit/Forms/Checkbox";
import { Button } from "Uikit/Button/Button";
import { PopoverList } from "Uikit/Popover/PopoverList";
import clsx from "clsx";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

interface SidebarListItemProps {
    id: string;
    name: string;
    checked: boolean;
    selected: boolean;
    editable: boolean;
    checkable?: boolean;
    draggable?: boolean;
    disabled?: boolean;
    nodeAddList?: { name: string }[];
    onItemClick: (item: { id: string; name: string }) => void;
    onNodeAddClick?: (event: any, nodeId: any) => void;
    onCheckedChange?: (id: string) => void;
    onSelectNode?: (node: any) => void;
    onEditClick?: (name: string, id: string) => void;
    onDeleteClick?: (name: string, id: string) => void;
    getNodeLink?: (id: any) => string;
}

export const SidebarListItem = ({
    id,
    name,
    checked,
    selected = false,
    editable,
    checkable,
    draggable,
    disabled,
    nodeAddList,
    // onItemClick,
    onCheckedChange,
    onNodeAddClick,
    onSelectNode,
    onEditClick,
    onDeleteClick,
    getNodeLink,
}: SidebarListItemProps) => {
    const { listeners, setNodeRef, transform, transition, setActivatorNodeRef, isDragging } = useSortable({
        id: id,
    });

    const style = {
        transform: CSS.Transform.toString(
            transform ? { x: transform?.x, y: transform?.y, scaleX: transform?.scaleX, scaleY: 1 } : null,
        ),
        transition,
        zIndex: isDragging ? 120 : "initial",
        boxShadow: isDragging ? "0px 8px 20px rgba(0,0,0,0.12)" : "none",
        borderRadius: isDragging ? 10 : 0,
    };

    const [addOptionsVisible, setAddOptionsVisible] = useState(false);
    const [optionsVisible, setOptionsVisible] = useState(false);

    const addList = useMemo(() => {
        return nodeAddList?.map((item: any) => (
            <PopoverList.Item
                name={item.type}
                key={item.name}
                onClickItem={(event: any) => {
                    event.preventDefault();
                    event.stopPropagation();

                    setAddOptionsVisible(false);

                    if (onNodeAddClick) {
                        onNodeAddClick(item, id);
                    }
                }}
            >
                {item.name}
            </PopoverList.Item>
        ));
    }, [nodeAddList, onNodeAddClick, id]);

    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (draggable) return;

        if (onSelectNode) {
            onSelectNode({ id, name });
        }
    };

    const toggleCheck = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();

        onCheckedChange?.(id);
    };

    const link = React.useMemo(() => {
        return getNodeLink ? getNodeLink(id) : "";
    }, [getNodeLink, id]);

    return (
        <a
            href={link}
            className={clsx(
                "relative flex flex-shrink-0 justify-between pl-4 hover:bg-background group p2-accent text-black !no-underline h-8 mb-0.5",
                (selected || checked) && "bg-background",
                selected && "text-blue",
                draggable && "cursor-pointer",
                (optionsVisible || addOptionsVisible) && "z-10",
            )}
            ref={setNodeRef}
            style={style}
            // {...attributes}
            onClick={onClick}
        >
            <div className="h-full flex items-center">
                {checkable && !draggable && (
                    <div className="w-4 h-4">
                        <Checkbox checked={checked} onChange={toggleCheck} disabled={disabled} />
                    </div>
                )}
                {draggable && (
                    <div className="py-1 cursor-grab" ref={setActivatorNodeRef} {...listeners}>
                        <Icon color="fill-[#c9c9c9]" icon={Icons.Grid} width={16} height={16} />
                    </div>
                )}
                <div className={"node-name p-2 font-medium break-all"}>
                    <MultiClumpTooltip clamp={1} label={String(name)} />
                </div>
            </div>
            {!draggable && editable && (
                <div
                    className={clsx(
                        "node-edit-buttons hidden pr-3 h-full items-center pl-2 ml-auto flex-shrink-0 group-hover:flex",
                        (optionsVisible || addOptionsVisible) && "!flex z-30",
                    )}
                >
                    {/* optionsVisible */}
                    {!nodeAddList && (
                        <Button
                            className={"border-none focus:!ring-0"}
                            shape={"round"}
                            size={"small"}
                            icon={
                                <Icon
                                    color="fill-primary"
                                    icon={Icons.PlusFilled}
                                    width={16}
                                    height={16}
                                    className="hover:fill-blue-hover"
                                />
                            }
                            iconPlacement={"center"}
                            color={"common"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (onNodeAddClick) {
                                    onNodeAddClick(e, id);
                                }
                            }}
                        />
                    )}

                    {/* Список создания новых сущностей */}
                    {nodeAddList && nodeAddList?.length > 1 && (
                        <PopoverList
                            offset={[68, 4]}
                            visible={addOptionsVisible}
                            onClickOutside={() => setAddOptionsVisible(false)}
                            className=""
                        >
                            <Button
                                className={"border-none focus:!ring-0"}
                                shape={"round"}
                                size={"small"}
                                icon={
                                    <Icon
                                        color="fill-primary"
                                        icon={Icons.PlusFilled}
                                        width={16}
                                        height={16}
                                        className="hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                color={"common"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setAddOptionsVisible(!addOptionsVisible);
                                }}
                            />

                            {addList}
                        </PopoverList>
                    )}

                    {/* Вариант с PopoverList */}
                    <PopoverList
                        offset={[-68, 4]}
                        visible={optionsVisible}
                        onClickOutside={() => setOptionsVisible(false)}
                        className=""
                    >
                        <Button
                            className={"border-none focus:!ring-0"}
                            shape={"round"}
                            size={"small"}
                            icon={
                                <Icon
                                    color="fill-primary"
                                    icon={Icons.Dots}
                                    width={16}
                                    height={16}
                                    className="hover:fill-blue-hover"
                                />
                            }
                            iconPlacement={"center"}
                            color={"common"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                setOptionsVisible(!optionsVisible);
                            }}
                        />
                        <PopoverList.Item
                            name="teamAdd"
                            onClickItem={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();

                                setOptionsVisible(false);
                                onEditClick?.(name, id);
                            }}
                        >
                            Переименовать
                        </PopoverList.Item>
                        <PopoverList.Item
                            name="teamRemove"
                            onClickItem={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();

                                setOptionsVisible(false);
                                onDeleteClick?.(name, id);
                            }}
                        >
                            Удалить
                        </PopoverList.Item>
                    </PopoverList>
                </div>
            )}
        </a>
    );
};
