import React from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";

import { ResourceType } from "Enums";
import { BasePaginationResponse, BaseResponse } from "Api/BaseResponse";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { TestUserListResponse } from "Api/Responses/TestResponse";
import Api from "Api/index";
import { isAfter } from "date-fns";
import { SortingState } from "@tanstack/react-table";

type Filters = { [key: string]: any };

export const testsKeys = {
    lists: ["ui/tests"] as const,
    listAvailable: () => [...testsKeys.lists, "available"],
    listReview: () => [...testsKeys.lists, "review"],
    listResult: (sorting: SortingState, filters: Filters) => [...testsKeys.lists, "result", { sorting, filters }],
};

const formatSearch = (str: string) => {
    let response = "";

    for (const element of str) {
        if (element.toLowerCase() != element.toUpperCase() || !isNaN(Number(element))) {
            response += element;
        }
    }

    return response;
};

const getUserTests = async () => {
    return await Api.Test.UserList({});
};

const getUserReviewTests = async () => {
    return await Api.Test.UserReview({});
};

export function useAvailableTests(filters: { [key: string]: any }) {
    return useQuery({
        queryKey: testsKeys.listAvailable(),
        queryFn: getUserTests,
        select: React.useCallback(
            (data: TestUserListResponse[]) => {
                if (filters["complexity"]) {
                    data = data?.filter(
                        (p) =>
                            p.ratingPoints >= (filters["complexity"]["minValue"] || 0) &&
                            p.ratingPoints <= (filters["complexity"]["maxValue"] || Number.MAX_SAFE_INTEGER),
                    );
                }

                if (filters["withDeadline"]) {
                    data = data?.filter((p) => p.deadlineTime);
                }

                if (filters["searchQuery"]) {
                    data = data?.filter((p) =>
                        formatSearch(p.title.toLowerCase().replace("ё", "е")).includes(
                            formatSearch(filters["searchQuery"].toLowerCase().replace("ё", "е")),
                        ),
                    );
                }

                return data;
            },
            [filters],
        ),
        keepPreviousData: false,
    });
}

export function useReviewTests() {
    return useQuery({
        queryKey: testsKeys.listReview(),
        queryFn: getUserReviewTests,
        select: React.useCallback(
            (data: TestUserListResponse[]) =>
                data.sort((item1: TestUserListResponse, item2: TestUserListResponse) => {
                    if (isAfter(new Date(item2.statusChangeTime), new Date(item1.statusChangeTime))) {
                        return 1;
                    }

                    if (isAfter(new Date(item1.statusChangeTime), new Date(item2.statusChangeTime))) {
                        return -1;
                    }

                    return 0;
                }),
            [],
        ),
        keepPreviousData: false,
    });
}

export function useResultTests(sorting: SortingState, parsedFiltersData: Filters) {
    return useInfiniteQuery({
        queryKey: testsKeys.listResult(sorting, parsedFiltersData),
        queryFn: async ({ pageParam }) => {
            let page = 0;

            if (pageParam) {
                page = pageParam === 1 ? 6 : pageParam;
            }

            return await Api.Test.UserFinished(page, pageParam ? 4 : 24, sorting, parsedFiltersData);
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
            return lastPage.Page + 1;
        },
    });
}

async function toggleFavoriteStatus({ resource, isFavorite }: { resource: Resource; isFavorite: boolean }) {
    if (isFavorite) {
        return await Api.Favorite.Add(
            Object.assign(new CommonsAddFavoritesRequest(), {
                resourceId: resource.resourceId,
                resourceSolutionId: resource.id,
                title: resource?.title,
                logoId: resource?.logoId,
                state: "ACTIVE",
                type: ResourceType.QUIZ,
                progressStatus: resource.progressStatus,
                ratingPoints: resource?.ratingPoints,
                deadlineTimestamp: resource?.deadlineTime ?? null,
            }),
        );
    } else {
        return await Api.Commons.removeFavorites(
            Object.assign(new CommonsRemoveFavoritesRequest(), {
                resourceId: resource.resourceId,
                type: ResourceType.QUIZ,
            }),
        );
    }
}

type Resource = TestUserListResponse;

export function useChangeTestFavoriteStatus() {
    const queryClient = useQueryClient();

    return useMutation<BaseResponse, unknown, { resource: Resource; isFavorite: boolean; queryKeys: any }>(
        toggleFavoriteStatus,
        {
            onSuccess: (_data, variables) => {
                if (variables.queryKeys.includes("result")) {
                    queryClient.setQueryData<{
                        pages: BasePaginationResponse<Resource>[];
                        pageParams: unknown[];
                    }>(variables.queryKeys, (oldData) => {
                        let resource: Resource | undefined;

                        oldData?.pages?.forEach((page) => {
                            resource = page.Content.find((item) => item.id === variables.resource.id);
                        });

                        if (resource) {
                            resource.isFavorite = variables.isFavorite;
                        }

                        return oldData!;
                    });
                } else {
                    queryClient.setQueriesData<Resource[]>(variables.queryKeys, (oldData) => {
                        return oldData!.map((t) =>
                            t.id === variables.resource.id ? { ...t, isFavorite: variables.isFavorite } : t,
                        );
                    });
                }

                queryClient.invalidateQueries(["favorites"]).then();
            },
        },
    );
}
