import React from "react";
import clsx from "clsx";
import { Button, Icon, Icons } from "Uikit";

interface FavoriteButtonProps {
    onChange: (e: any) => void;
    isFavorite?: boolean;
    className?: string;
    variant?: "light" | "dark";
}

export const FavoriteButton = ({ onChange, className, isFavorite = false, variant = "dark" }: FavoriteButtonProps) => {
    const changeFavoriteStatusHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onChange(!isFavorite);
    };

    return (
        <Button
            className={clsx(
                "!p-0 !border-0 hover:!shadow-none active:!ring-0 hover:!ring-0 !rounded-full w-6.5 h-6.5 2xl:w-8 2xl:h-8 group/favorite",
                !isFavorite && variant === "light" && "hover:bg-black-50 bg-transparent active:!bg-black-50",
                !isFavorite && variant === "dark" && "hover:!bg-black-50 !bg-black-20 active:!bg-black-50",
                isFavorite && ["dark", "light"].includes(variant) && "hover:!bg-gray-blue !bg-background-light",
                className,
            )}
            variant="outline"
            color="secondary"
            onClick={changeFavoriteStatusHandler}
        >
            {isFavorite && ["dark", "light"].includes(variant) && (
                <Icon
                    icon={Icons.SaveFilled}
                    width={18}
                    height={18}
                    color="fill-gray-dark group-hover/favorite:fill-black transition-colors"
                    className="2xl:!w-5.5 2xl:!h-5.5"
                />
            )}
            {!isFavorite && (
                <Icon
                    icon={Icons.Save}
                    width={18}
                    height={18}
                    color={variant === "dark" ? "fill-white" : "fill-black-50"}
                    className={clsx(
                        "2xl:!w-5.5 2xl:!h-5.5",
                        variant === "light" ? "group-hover/favorite:fill-white" : "",
                        "transition-colors",
                    )}
                />
            )}
        </Button>
    );
};
