import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Api from "Api";
import { Breadcrumbs, Button } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { ContentLayout } from "Containers";

export const TaskChanged = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const dataQuery = useQuery(
        ["task", "collection"],
        async () => {
            return await Api.TaskPublic.Preview({ uuid: id as string });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data, isLoading, isError } = dataQuery;

    const goBack = () => navigate("/tasks");

    return (
        <ContentLayout className="h-full">
            <Breadcrumbs>
                <Breadcrumbs.Link title="Задания" url="/tasks" />
                {data?.title && <Breadcrumbs.Link title={data?.title} url={`/task/${id}`} />}
            </Breadcrumbs>
            {isLoading && (
                <div className="w-full h-full flex-center px-10">
                    <Loader />
                </div>
            )}
            {isError && (
                <div className="w-full h-full flex-center px-10">
                    <p className="text-md text-center text-red">
                        Произошла ошибка запроса - невозможно загрузить страницу
                    </p>
                </div>
            )}
            {data && (
                <div className="flex flex-col w-full h-full">
                    <div className="flex-center flex-col pt-6 flex-grow">
                        <h1 className="mb-4">Задание было изменено администратором</h1>
                        <p className="text-blue-drk">
                            Только что задание, которое вы проходили, было изменено администратором. Все актуальные
                            задания находятся в разделе «Задания»
                        </p>
                        <Button color={"primary"} className="mt-7.5" onClick={goBack}>
                            Ну спасибо...
                        </Button>
                    </div>
                </div>
            )}
        </ContentLayout>
    );
};
