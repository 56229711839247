import React, { useId } from "react";
import clsx from "clsx";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Icon, Icons, TextareaAutoSizable } from "Uikit";
import { TestRequestQuestionAnswer } from "Api/Requests/TestRequest";
import { testingRegexp } from "Uikit/Forms/Input";

interface ITestContentItemSelectText {
    index: number;
    isMulti: boolean;
    answer: TestRequestQuestionAnswer;
    deletable: boolean;
    error: string;
    onChange: (index: number, answer: TestRequestQuestionAnswer) => void;
    onDelete: (index: number) => void;
}
export const TestContentItemSelectText = ({
    index,
    isMulti,
    answer,
    deletable,
    error,
    onChange,
    onDelete,
}: ITestContentItemSelectText) => {
    const { listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
        id: answer.id as UniqueIdentifier,
    });
    const id = useId();

    const style = {
        transform: CSS.Transform.toString(
            transform ? { x: transform?.x, y: transform?.y, scaleX: transform?.scaleX, scaleY: 1 } : null,
        ),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} className="flex items-center mb-5">
            {isMulti && !answer.isCorrect && (
                <div
                    className="mr-2 min-w-6 h-6 bg-white border border-gray-stroke rounded-md"
                    onClick={() => onChange(index, { ...answer, isCorrect: !answer.isCorrect })}
                />
            )}
            {isMulti && answer.isCorrect && (
                <div
                    className="flex justify-center items-center mr-2 min-w-6 h-6 bg-blue rounded-md"
                    onClick={() => onChange(index, { ...answer, isCorrect: !answer.isCorrect })}
                >
                    <Icon icon={Icons.Check} width={20} height={20} color="fill-white" />
                </div>
            )}
            {!isMulti && (
                <input
                    type="radio"
                    readOnly
                    checked={answer.isCorrect}
                    onClick={() => onChange(index, { ...answer, isCorrect: !answer.isCorrect })}
                    className={clsx(
                        answer.isCorrect ? "bg-[#1380CE] text-blue border-white border-2 ring-1 !ring-blue" : "",
                        "mr-2 cursor-pointer radio w-6 h-6 border-gray-background ring-1 ring-input-stroke",
                        "focus:ring-offset-0 focus:ring-1 ring-offset-0 focus:ring-blue",
                    )}
                />
            )}
            <TextareaAutoSizable
                id={id + "adminNewTestContentTabInputUtil"}
                name="adminNewTestContentTabInputUtil"
                className="resize-none"
                value={answer.answerText}
                placeholder="Введите ответ"
                cutRegExp={testingRegexp}
                maxLength={512}
                error={error && !answer.answerText ? error : ""}
                onChange={(e) => onChange(index, { ...answer, answerText: e.target.value })}
            />
            <div
                className="cursor-pointer"
                onClick={() => (deletable ? onDelete(index) : null)}
                id={id + "adminNewTestContentTabBtnClose"}
            >
                <Icon className="mx-4" icon={Icons.Close} width={20} height={20} color="fill-gray-light" />
            </div>
            <div
                className="cursor-grab"
                ref={setActivatorNodeRef}
                {...listeners}
                id={id + "adminNewTestContentTabBtnGrid"}
            >
                <Icon icon={Icons.Grid} width={20} height={20} color="fill-gray-light" />
            </div>
        </div>
    );
};
