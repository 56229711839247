import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { formatSendTime } from "helpers/dateHelper";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import Api from "Api/index";
import { numCapEnd } from "helpers/numCapEnd";
import { LogoSize } from "Api/Services/UploadApi";

export const PersonalAchievements = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { data: user } = useQuery(["users", "personal", id], () =>
        id ? Api.User.Read(id) : Api.User.GetCurrentUser(),
    );

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Ачивки"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    return (
        <div className="flex flex-col px-3 w-full">
            {user?.achievements.map((achievement) => {
                return (
                    <div key={achievement.id} className="flex py-5 first:pt-3 w-full border-b border-gray-blue">
                        {achievement.logoId && (
                            <img
                                className="rounded-full w-15 h-15 object-cover"
                                src={Api.Upload.GetLogo(achievement.logoId, LogoSize.THUMB_MICRO)}
                                alt={achievement.name}
                            />
                        )}
                        {!achievement.logoId && <div className="rounded-full w-15 h-15 bg-gray-blue" />}
                        <div className="flex flex-col items-start ml-3 w-full">
                            <div className="mb-1 py-1 px-2 bg-blue-10 rounded-lg text-blue p2">
                                +{achievement.rating}{" "}
                                {numCapEnd({ one: "балл", two: "балла", few: "баллов" }, Number(achievement.rating))}
                            </div>
                            <h4 className="mb-3 text-black">{achievement.name}</h4>
                            <div className="flex justify-between items-center mb-1 w-full">
                                <div className="flex items-center">
                                    <UserAvatar
                                        avatarId={achievement.givenBy.avatarId}
                                        color={achievement.givenBy.defaultAvatarColor || undefined}
                                        userInitials={`${achievement.givenBy.firstName?.slice(
                                            0,
                                            1,
                                        )}${achievement.givenBy.lastName?.slice(0, 1)}`}
                                        size={20}
                                    />
                                    <span className="ml-1 text-gray-dark p3 leading-0">
                                        {achievement.givenBy.firstName} {achievement.givenBy.lastName}
                                    </span>
                                </div>
                                <span className="text-gray p4">{formatSendTime(achievement.givenAt)}</span>
                            </div>
                            {achievement.comment && (
                                <div className="py-2 px-3 bg-background-light rounded-lg text-black p3">
                                    {achievement.comment}
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
