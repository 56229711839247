import { CMIElement, CMIExit, CMIStatus } from "./ScormModel";

export enum CMIErrorCode {
    NO_ERROR = 0,
    GENERAL_EXCEPTION = 101,
    INVALID_ARGUMENT_ERROR = 201,
    ELEMENT_CANNOT_HAVE_CHILDREN = 202,
    ELEMENT_NOT_AN_ARRAY = 203,
    NOT_INITIALIZED = 301,
    NOT_IMPLEMENTED_ERROR = 401,
    INVALID_SET_VALUE = 402,
    ELEMENT_IS_READ_ONLY = 403,
    ELEMENT_IS_WRITE_ONLY = 404,
    INCORRECT_DATA_TYPE = 405,
}

export class ScormLMSApi {
    public cmiElement: CMIElement;
    public onFinish: (() => Promise<void>) | null = null;
    public isReloadInProgress: boolean;

    public Reset(cmiElement?: CMIElement) {
        if (cmiElement) {
            this.cmiElement = cmiElement;
        } else {
            this.cmiElement = this._initCmiElement();
        }
    }

    constructor(cmiElement?: CMIElement) {
        if (cmiElement) {
            this.cmiElement = cmiElement;
        } else {
            this.cmiElement = this._initCmiElement();
        }
    }

    LMSInitialize(event: string): string {
        console.log(`LMS 1.2 '${event}' event started`);
        return "true";
    }

    async LMSFinish(event: string): Promise<string> {
        console.log(`LMS Finish: ${event}`);
        if (!this.isReloadInProgress) {
            if (this.onFinish) {
                await this.onFinish();
            }
            window.close();
        }
        return "true";
    }

    LMSGetValue(element: string): string {
        console.log(`Scorm requested value of element '${element}'`);

        switch (element) {
            case "cmi.core.lesson_status":
                console.log(`Value of the element in LMS is '${this.cmiElement.core.lesson_status}'`);
                return this.cmiElement.core.lesson_status;
            case "cmi.core.lesson_location":
                console.log(`Value of the element in LMS is '${this.cmiElement.core.lesson_location}'`);
                return this.cmiElement.core.lesson_location;
            case "cmi.core.score.raw":
                console.log(`Value of the element in LMS is '${this.cmiElement.core.score.raw.toString()}'`);
                return this.cmiElement.core.score.raw.toString();
            case "cmi.core.score.min":
                console.log(`Value of the element in LMS is '${this.cmiElement.core.score.min.toString()}'`);
                return this.cmiElement.core.score.min.toString();
            case "cmi.core.score.max":
                console.log(`Value of the element in LMS is '${this.cmiElement.core.score.max.toString()}'`);
                return this.cmiElement.core.score.max.toString();
            case "cmi.suspend_data":
                console.log(`Value of the element in LMS is '${this.cmiElement.suspend_data}'`);
                return this.cmiElement.suspend_data;
            case "cmi.launch_data":
                console.log(`Value of the element in LMS is '${this.cmiElement.launch_data}'`);
                return this.cmiElement.launch_data;
            case "cmi.comments":
                console.log(`Value of the element in LMS is '${this.cmiElement.comments}'`);
                return this.cmiElement.comments;
            case "cmi.comments_from_lms":
                console.log(`Value of the element in LMS is '${this.cmiElement.comments_from_lms}'`);
                return this.cmiElement.comments_from_lms;
            case "cmi.core.session_time":
                console.log(`Value of the element in LMS is '${this.cmiElement.core.session_time}'`);
                return this.cmiElement.core.session_time;
            case "cmi.core.student_id":
                console.log(`Value of the element in LMS is '${this.cmiElement.core.student_id}'`);
                return "10b566e3-c962-40b4-909b-dffd5b230751";
            case "cmi.core.student_name":
                console.log(`Value of the element in LMS is '${this.cmiElement.core.student_name}'`);
                return "User";
            case "cmi.core.exit": {
                if (this.cmiElement.suspend_data) {
                    console.log(`Value of the element in LMS is 'suspended'`);
                    return "suspended";
                } else {
                    console.log(`Value of the element in LMS is '${this.cmiElement.core.exit}'`);
                    return this.cmiElement.core.exit;
                }
            }
            case "cmi.core.lesson_mode": {
                if (this.cmiElement.suspend_data) {
                    console.log(`Value of the element in LMS is 'review'`);
                    return "review";
                } else if (
                    this.cmiElement.core.lesson_status === "incomplete" &&
                    this.cmiElement.core.exit === "suspended"
                ) {
                    console.log(`Value of the element in LMS is 'normal'`);
                    return "normal";
                } else {
                    console.log(`Value of the element in LMS is 'browse'`);
                    return "browse";
                }
            }
            default:
                console.log(`Unknown element: ${element}`);
                return "";
        }
    }

    LMSSetValue(element: string, value: string): string {
        console.log(`Scorm sent value of element '${element}' : ${value}`);

        switch (element) {
            case "cmi.core.lesson_status": {
                const status = convertToCMIStatus(value);
                if (status !== null) {
                    this.cmiElement.core.lesson_status = status;
                }
                break;
            }
            case "cmi.core.lesson_location":
                this.cmiElement.core.lesson_location = value;
                break;
            case "cmi.core.session_time":
                this.cmiElement.core.session_time = value;
                break;
            case "cmi.core.score.raw":
                this.cmiElement.core.score.raw = parseFloat(value);
                break;
            case "cmi.core.score.min":
                this.cmiElement.core.score.min = parseFloat(value);
                break;
            case "cmi.core.score.max":
                this.cmiElement.core.score.max = parseFloat(value);
                break;
            case "cmi.suspend_data":
                this.cmiElement.suspend_data = value;
                break;
            case "cmi.launch_data":
                this.cmiElement.launch_data = value;
                break;
            case "cmi.comments":
                this.cmiElement.comments = value;
                break;
            case "cmi.core.exit": {
                const exit = convertToCMIExit(value);
                if (exit !== null) {
                    this.cmiElement.core.exit = exit;
                }
                break;
            }
            case "cmi.comments_from_lms":
                this.cmiElement.comments_from_lms = value;
                break;
            default:
                console.log(`Unknown element: ${element}`);
        }
        return "";
    }

    LMSCommit(event: string) {
        console.log(`lms commit: ${event}`);
    }

    LMSGetLastError(): CMIErrorCode {
        console.log("lms get last error");
        return CMIErrorCode.NO_ERROR;
    }

    LMSGetErrorString(errorCode: CMIErrorCode): string {
        console.log("lms get error string");
        return `Some error - ${errorCode}`;
    }

    LMSGetDiagnostic(errorCode: CMIErrorCode): string {
        console.log("lms get diagnostic");
        return `Some error diagnostic -${errorCode}`;
    }

    private _initCmiElement(): CMIElement {
        return {
            core: {
                student_id: "",
                student_name: "",
                lesson_location: "",
                credit: "",
                lesson_status: "browsed",
                entry: "abinitio",
                score: {
                    scaled: 0,
                    raw: 0,
                    min: 0,
                    max: 10,
                },
                total_time: null,
                lesson_mode: "normal",
                exit: "logout",
                session_time: new Date().getTime(),
            },
            suspend_data: "",
            launch_data: "",
            comments: "",
            comments_from_lms: "",
            objectives: [],
            student_data: {
                mastery_score: 10,
                max_time_allowed: 0,
                time_limit_action: "exit,no message",
            },
            student_id: "",
            student_name: "",
            student_preference: {
                audio: 0,
                language: "English",
                speed: 0,
                text: 0,
            },
            interactions: [],
        };
    }
}

function convertToCMIStatus(value: string): CMIStatus | null {
    switch (value) {
        case "passed":
        case "completed":
        case "failed":
        case "incomplete":
        case "browsed":
        case "not attempted":
            return value;
        default:
            console.error(`Invalid CMIStatus value: ${value}`);
            return null;
    }
}

function convertToCMIExit(value: string): CMIExit | null {
    switch (value) {
        case "time-out":
        case "suspended":
        case "suspend":
        case "logout":
            return value;
        default:
            console.error(`Invalid CMIStatus value: ${value}`);
            return null;
    }
}
