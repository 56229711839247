import { BaseRequest } from "../BaseRequest";

export class TaskCategoryCreateRequest extends BaseRequest {
    public title: string;
}

export class TaskCategoryEditRequest extends BaseRequest {
    public id: string;
    public title: string;
}

export class TaskCategoryReorderRequest extends BaseRequest {
    public categoryIds: string[];
}
