import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { FormikHelpers, FormikProps, FormikState, useFormik } from "formik";
import * as _ from "lodash";

import { FileUploadType, MaterialType } from "Enums";

import { MaterialHeader } from "./components/MaterialHeader";
import { MaterialArticle } from "./components/Tabs/MaterialArticle";
import { MaterialDocument } from "./components/Tabs/MaterialDocument";
import { MaterialSettings } from "./components/Tabs/MaterialSettings";
import { MaterialVideo } from "./components/Tabs/MaterialVideo";
import { Breadcrumbs, Content, Tabs, TabsWrapper, flash } from "Uikit";
import { IMaterialForm } from "./Material";

import {
    articleValidationSchema,
    documentValidationSchema,
    htmlValidationSchema,
    scormValidationSchema,
    videoValidationSchema,
} from "./validationSchemas";

import Api from "Api";
import { MaterialCreateRequest, MaterialEditRequest } from "Api/Requests/MaterialRequest";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import { useFormEmitter } from "hooks/useFormEmitter";
import { Unsubscribe } from "nanoevents";
import { AccountableUser } from "Api/Responses/UserResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { ApproxTime } from "Components/ApproxTime/ApproxTime";
import { CancelModal } from "Components/CancelModal/CancelModal";
import { ErrorCode } from "Api/BaseResponse";
import { MaterialAttachment } from "./components/Tabs/MaterialAttachment";
import { IOption } from "types";

interface MaterialFormProps {
    material: IMaterialForm;
    actionType: "CREATE" | "COPY" | "EDIT";
    categoryOptions: IOption[];
    ratingOptions: IOption[];
    userOptions: { label: string; value: string; payload: AccountableUser }[];
    defaultLogos: string[];
    queryParams: URLSearchParams;
    setMaterial: (material: IMaterialForm) => void;
}

const validationSchema = {
    [MaterialType.Article]: articleValidationSchema,
    [MaterialType.Video]: videoValidationSchema,
    [MaterialType.Document]: documentValidationSchema,
    [MaterialType.SCORM]: scormValidationSchema,
    [MaterialType.HTML]: htmlValidationSchema,
};

export const breadCrumbsMaterialType: { [key: string]: string } = {
    VIDEO: "Новое видео",
    DOCUMENT: "Новый документ",
    ARTICLE: "Новая статья",
    SCORM: "Новый SCORM",
    HTML: "Новый HTML5",
};

export const MaterialForm = ({
    material,
    actionType,
    categoryOptions,
    ratingOptions,
    userOptions,
    defaultLogos,
    setMaterial,
    queryParams,
}: MaterialFormProps) => {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const formEmitter = useFormEmitter();
    const navigate = useNavigate();

    const [action, setAction] = useState(actionType);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isEditCancel, setIsEditCancel] = useState<boolean>(false);

    const [editorInstance, setEditorInstance] = useState<any>(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const blocker = useBlocker((params) => {
        if (isFormDirty && params?.historyAction !== "REPLACE") {
            setIsCancel(true);
        }

        return isFormDirty;
    });

    const { values, setFieldValue, ...rest }: FormikProps<IMaterialForm> = useFormik({
        initialValues: material,
        validationSchema: material.type ? validationSchema[material.type] : null,
        onSubmit: (values, actions) => {
            onSubmit(values, actions);
        },
    });

    const [headerTitle, setHeaderTitle] = useState(
        action !== "CREATE" ? values.settings.title : breadCrumbsMaterialType[material.type],
    );

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);

    const onSubmitHandler = async () => {
        const errors = await rest.validateForm();

        if (!_.isEmpty(errors?.settings)) {
            flash.error("Ошибка, не все поля заполнены правильно");
        } else if (errors?.fileId) {
            flash.error("Материал обязательный для добавления");
        }

        rest.handleSubmit();
        setIsSubmitted(true);
    };

    const onSubmit = (values: IMaterialForm, actions: FormikHelpers<IMaterialForm>) => {
        const submit = action === "CREATE" || action === "COPY" ? onCreate : onEdit;

        if (material.isActive !== values.isActive) {
            openDialog({
                title: `Изменить статус на «${values.isActive ? "Активен" : "Скрыт"}»`,
                content: values.isActive
                    ? "Все пользователи, у кого есть доступ, увидят данный материал"
                    : "У всех пользователей пропадет данный материал",
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    submit(values).then();
                    closeDialog();

                    actions.setSubmitting(true);
                },
            });
        } else {
            submit(values).then();
            actions.setSubmitting(true);
        }
    };

    const uploadFiles = (files: any[], fileUploadType = FileUploadType.RESOURCE_ATTACHMENT) => {
        const promises = files.map((file: any) => {
            return Api.File.UploadFile(file, null, null, true, fileUploadType);
        });

        return Promise.allSettled(promises).then((results) => {
            return results.reduce<any[]>((acc, result): any => {
                if (result.status === "fulfilled") {
                    acc.push(result.value);
                }

                return acc;
            }, []);
        });
    };

    const removeFiles = (files: any[]) => {
        const promises = files.map((file: any) => {
            if (material.type === MaterialType.SCORM) {
                return Api.ScormFile.Remove(file.id);
            }
        });

        return Promise.allSettled(promises).then((results) => {
            return results.reduce<any[]>((acc, result): any => {
                if (result.status === "fulfilled") {
                    acc.push(result.value);
                }

                return acc;
            }, []);
        });
    };

    const onCreate = async (values: IMaterialForm) => {
        const request = new MaterialCreateRequest();
        let logo;

        try {
            if (values.settings.image) {
                logo = await uploadFiles([values.settings.image], FileUploadType.RESOURCE_LOGO);
            }

            request.type = values.type || "ARTICLE";
            request.state = values.isActive ? "ACTIVE" : "HIDDEN";
            request.categoryId = values.settings.category.value;
            request.logoId = logo ? logo[0].id : values.settings.logoId;
            request.managerUserId = values.settings.userId;
            request.complexity = values.settings.complexity?.value || "MEDIUM";
            request.scoreRewriteLimit = values.settings.scoreRewriteLimit;
            request.hasQuestions = false;
            request.questionsRequired = false;
            request.allowComments = values.settings.isAllowComments;
            request.allowRewind = !values.settings.isAllowRewind;
            request.approxCompletionMinutes = values.approxCompletionMinutes || 0;
            request.isApproxCompletionMinutesManuallyChanged = values.isApproxCompletionMinutesManuallyChanged;
            request.attachedFileIds = values.article.attachments.map((i) => i.id);
            request.title = String(values.settings.title).trim();
            request.description = String(values.settings.description).trim();
            request.content = values.article.content || null;
            request.fileId = values.fileId;
            // request.isTraining = values.article.isTraining ?? false;

            if (values.type === "DOCUMENT") {
                request.fileType = values.fileType;
            }

            const createResponse = await Api.Material.createMaterial(request);

            flash.success("Все изменения сохранены!");

            const updatedValues = {
                ...values,
                id: createResponse.id,
                modifyTime: createResponse.modifyTimestamp,
                scorm: createResponse.scorm,
                file: createResponse.file,
            };

            rest.resetForm({
                values: updatedValues,
            } as Partial<FormikState<IMaterialForm>>);
            formEmitter.current?.emit("dirty", false);
            setAction("EDIT");
            setMaterial(updatedValues);
            setHeaderTitle(String(values.settings.title).trim());

            navigate(`/admin/material/${values.type}/${createResponse.id}`, { replace: true });
        } catch (error: any) {
            if (error.errorCode === ErrorCode.TITLE_ALREADY_EXISTS) {
                rest.setFieldError("settings.title", "Данное название уже используется");
            }
            console.log(error);
        }
    };

    const onEdit = async (values: IMaterialForm) => {
        try {
            const request = new MaterialEditRequest(values.id ?? "");
            let logo;

            if (values.type === "ARTICLE") {
                const removedAttachments = material.article.attachments.filter(
                    (i) => !values.article.attachments.some((j) => j.id === i.id),
                );

                await removeFiles(removedAttachments);
            }

            if (values.settings.image) {
                logo = await uploadFiles([values.settings.image], FileUploadType.RESOURCE_LOGO);
            }

            request.id = values?.id ?? "";
            request.type = values.type || "ARTICLE";
            request.state = values.isActive ? "ACTIVE" : "HIDDEN";
            request.categoryId = values.settings.category.value;
            request.logoId = logo ? logo[0].id : values.settings.logoId;
            request.managerUserId = values.settings.userId;
            request.complexity = values.settings.complexity?.value || "MEDIUM";
            request.scoreRewriteLimit = values.settings.scoreRewriteLimit;
            request.hasQuestions = false;
            request.questionsRequired = false;
            request.allowComments = values.settings.isAllowComments;
            request.allowRewind = !values.settings.isAllowRewind;
            request.approxCompletionMinutes = values.approxCompletionMinutes || 0;
            request.isApproxCompletionMinutesManuallyChanged = values.isApproxCompletionMinutesManuallyChanged;
            request.attachedFileIds = values.article.attachments.map((i) => i.id);
            request.title = String(values.settings.title).trim();
            request.description = String(values.settings.description).trim();
            request.content = values.article.content || null;
            request.fileId = values.fileId;
            request.fileType = values.fileType;
            request.isTraining = values.article.isTraining ?? false;

            if (values.type === "DOCUMENT") {
                request.fileType = values.fileType;
            }

            const editResponse = await Api.Material.editMaterial(request);

            flash.success("Все изменения сохранены!");

            formEmitter.current?.emit("dirty", false);
            setMaterial(values);
            rest.resetForm({
                values: {
                    ...values,
                    modifyTime: editResponse.modifyTimestamp,
                },
            } as Partial<FormikState<IMaterialForm>>);

            setHeaderTitle(String(values.settings.title).trim());
            navigate(`/admin/material/${values.type}/${request.id}`, { replace: true });
        } catch (error: any) {
            if (error.errorCode === ErrorCode.TITLE_ALREADY_EXISTS) {
                rest.setFieldError("settings.title", "Данное название уже используется");
            }
            console.log(error);
        }
    };

    const onMaterialStatusChange = (isActive: boolean) => {
        setFieldValue("isActive", isActive).then();
    };

    const goToMaterials = useCallback(() => {
        navigate("/admin/materials");
    }, [navigate]);

    const onCancelChange = () => {
        action === "EDIT" ? setIsEditCancel(true) : goToMaterials();
    };

    const onCancelModalSubmit = useCallback(() => {
        formEmitter.current?.emit("dirty", false);

        if (isCancel && blocker.state === "blocked") {
            blocker.proceed();
        } else {
            setIsEditCancel(false);
            rest.resetForm();
            editorInstance?.setData(material.article.content);
        }
    }, [formEmitter, blocker, rest, isCancel, editorInstance, material.article.content]);

    const tabsComponent = (): ReactNode => {
        if (selectedTab === 0 && values.modifyTime) {
            return `Обновлен ${formatDate(values.modifyTime * 1000, DateFormat.DATE_TIME_LONG)}`;
        } else if (selectedTab === 1) {
            return (
                <ApproxTime
                    canEdit
                    time={values.approxCompletionMinutes || 1}
                    onChange={(time: number) => {
                        setFieldValue("approxCompletionMinutes", time).then();
                        setFieldValue("isApproxCompletionMinutesManuallyChanged", true).then();
                    }}
                />
            );
        }
    };

    const onCharCountChange = useCallback(
        (charCount: number) => {
            const approxCompletionMinutes = Math.ceil(((charCount / 100) * 18) / 60) || 1;

            if (
                !values.isApproxCompletionMinutesManuallyChanged &&
                (charCount || approxCompletionMinutes !== values.approxCompletionMinutes)
            ) {
                setFieldValue("approxCompletionMinutes", approxCompletionMinutes);
            }
        },
        [setFieldValue, values.isApproxCompletionMinutesManuallyChanged, values.approxCompletionMinutes],
    );

    const setVideoDuration = useCallback(
        (duration: number) => {
            if (!values.isApproxCompletionMinutesManuallyChanged) {
                setFieldValue("approxCompletionMinutes", duration).then();
            }
        },
        [setFieldValue, values.isApproxCompletionMinutesManuallyChanged],
    );

    useEffect(() => {
        const { current: emitter } = formEmitter;
        let unbind: Unsubscribe;

        if (emitter) {
            unbind = emitter?.on("dirty", (dirty: any) => setIsFormDirty(dirty));
        }

        return () => {
            unbind?.();
        };
    }, [formEmitter]);

    useEffect(() => {
        formEmitter.current?.emit("dirty", rest.dirty);
    }, [rest.dirty, formEmitter]);

    useEffect(() => {
        const editMode = queryParams.get("editMode");

        if (editMode) {
            formEmitter.current?.emit("dirty", editMode);
        }
    }, [formEmitter, queryParams]);

    return (
        <>
            <Confirmation {...dialogState} />

            <CancelModal
                id="materialPageCancelModal"
                isEdit={isEditCancel}
                isOpen={isEditCancel || isCancel}
                setIsOpen={isEditCancel ? setIsEditCancel : setIsCancel}
                onSubmit={onCancelModalSubmit}
            />

            <div className="w-full h-full flex flex-col flex-grow px-4">
                <Breadcrumbs id="adminNewMaterialBreadcrumbs">
                    <Breadcrumbs.Link title={"Администратор"} />
                    <Breadcrumbs.Link title={"Материалы"} url={"/admin/materials"} />
                    {material.settings.category.value && (
                        <Breadcrumbs.Link
                            title={material.settings.category.label}
                            url={`/admin/materials?categoryId=${material.settings.category.value}`}
                        />
                    )}
                    {headerTitle}
                </Breadcrumbs>

                <MaterialHeader
                    materialId={values.id}
                    title={headerTitle}
                    type={material.type}
                    isActive={values.isActive}
                    onMaterialStatusChange={onMaterialStatusChange}
                    onCancelChange={onCancelChange}
                    onClose={goToMaterials}
                    onSubmitHandler={onSubmitHandler}
                    isFormDirty={isFormDirty}
                    isSubmitDisabled={isSubmitDisabled}
                />
                <TabsWrapper
                    className="relative pt-6 flex flex-col flex-grow"
                    selectedIndex={selectedTab}
                    onChange={setSelectedTab}
                >
                    <TabsWrapper.Tabs
                        classname="flex flex-grow justify-between items-center space-x-4 h-max"
                        label={tabsComponent()}
                        id="adminNewMaterialTabs"
                    >
                        <Tabs.Tab error={isSubmitted && !!rest.errors.settings} title="Настройки" />
                        {material.type === MaterialType.Article ? (
                            <Tabs.Tab error={isSubmitted && !!rest.errors.article} title="Содержимое" />
                        ) : (
                            <Tabs.Tab error={isSubmitted && !!rest.errors.fileId} title="Содержимое" />
                        )}
                    </TabsWrapper.Tabs>
                    <TabsWrapper.Content className="flex flex-col flex-grow">
                        <Content.Body className="h-fit pb-24">
                            <MaterialSettings
                                type={material.type}
                                material={values.settings}
                                categories={categoryOptions}
                                users={userOptions}
                                complexity={ratingOptions}
                                defaultLogos={defaultLogos}
                                errors={rest.errors.settings}
                                isSubmitted={isSubmitted}
                                onChange={setFieldValue}
                                // isTraining={values.article.isTraining}
                            />
                        </Content.Body>
                        <Content.Body className={"h-full"}>
                            {material.type === MaterialType.Article && (
                                <MaterialArticle
                                    content={values.article.content}
                                    attachments={values.article.attachments}
                                    errors={rest.errors.article}
                                    isSubmitted={isSubmitted}
                                    onChange={setFieldValue}
                                    onCharCountChange={onCharCountChange}
                                    setEditorInstance={setEditorInstance}
                                    setIsSubmitDisabled={setIsSubmitDisabled}
                                />
                            )}
                            {material.type === MaterialType.Video && (
                                <MaterialVideo
                                    isAllowRewind={!values.settings.isAllowRewind}
                                    attachment={values.file}
                                    videoUrl={
                                        values.fileId ? `/service/lms-upload/api/video/get-range/${values.fileId}` : ""
                                    }
                                    onVideoDurationChange={setVideoDuration}
                                    onChange={setFieldValue}
                                    setIsSubmitDisabled={setIsSubmitDisabled}
                                />
                            )}
                            {material.type === MaterialType.Document && (
                                <MaterialDocument attachment={values.file} onChange={setFieldValue} />
                            )}
                            {(material.type === MaterialType.SCORM || material.type === MaterialType.HTML) && (
                                <MaterialAttachment
                                    attachment={values.file}
                                    type={material.type}
                                    material={material}
                                    onChange={setFieldValue}
                                    isFormDirty={isFormDirty}
                                />
                            )}
                        </Content.Body>
                    </TabsWrapper.Content>
                </TabsWrapper>
            </div>
        </>
    );
};
