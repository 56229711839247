import { MaterialCategoryCreateRequest, MaterialCategoryEditRequest } from "Api/Requests/MaterialCategoryRequest";
import { MaterialReorderRequest } from "Api/Requests/MaterialRequest";
import { MaterialCategoryTreeResponse } from "Api/Responses/MaterialCategoryResponse";
import BaseApi from "../BaseApi";
import { BaseIdRequest } from "../BaseRequest";
import { BaseArrayResponse, BaseResponse } from "../BaseResponse";

export default class MaterialCategoryApi extends BaseApi {
    List = async (): Promise<BaseArrayResponse<MaterialCategoryTreeResponse>> => {
        return await this.Get(new BaseArrayResponse(MaterialCategoryTreeResponse), "/all");
    };

    Create = async (data: MaterialCategoryCreateRequest): Promise<MaterialCategoryTreeResponse> => {
        return await this.Post(new MaterialCategoryTreeResponse(), "", data);
    };

    Edit = async (data: MaterialCategoryEditRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/edit", data);
    };

    Reorder = async (data: MaterialReorderRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/reorder", data);
    };

    Remove = async (data: BaseIdRequest): Promise<BaseResponse> => {
        return await this.Delete(BaseResponse, "/delete/" + data.uuid);
    };
}
