import { CMIElement2004, CMIStatus } from "./ScormModel";

export enum CMIErrorCode {
    NO_ERROR = 0,
    GENERAL_EXCEPTION = 101,
    INVALID_ARGUMENT_ERROR = 201,
    ELEMENT_CANNOT_HAVE_CHILDREN = 202,
    ELEMENT_NOT_AN_ARRAY = 203,
    NOT_INITIALIZED = 301,
    NOT_IMPLEMENTED_ERROR = 401,
    INVALID_SET_VALUE = 402,
    ELEMENT_IS_READ_ONLY = 403,
    ELEMENT_IS_WRITE_ONLY = 404,
    INCORRECT_DATA_TYPE = 405,
}

export class ScormLMSApi2004 {
    public cmiElement: CMIElement2004;
    public onFinish: (() => Promise<void>) | null = null;
    public isReloadInProgress: boolean;

    public Reset(cmiElement?: CMIElement2004) {
        if (cmiElement) {
            this.cmiElement = cmiElement;
        } else {
            this.cmiElement = this._initCmiElement();
        }
    }

    constructor(cmiElement?: CMIElement2004) {
        if (cmiElement) {
            this.cmiElement = cmiElement;
        } else {
            this.cmiElement = this._initCmiElement();
        }
    }

    Initialize(event: string): string {
        console.log(`LMS 2004 '${event}' event started`);
        return "true";
    }

    async Terminate(event: string): Promise<string> {
        console.log(`LMS Terminate: ${event}`);
        if (!this.isReloadInProgress) {
            if (this.onFinish) {
                await this.onFinish();
            }
            window.close();
        }
        return "true";
    }

    GetValue(element: string): string {
        console.log(`Scorm requested value of element '${element}'`);

        switch (element) {
            case "cmi.completion_status":
                console.log(`Value of the element in LMS is '${this.cmiElement.completion_status}'`);
                return this.cmiElement.completion_status;
            case "cmi.success_status":
                console.log(`Value of the element in LMS is '${this.cmiElement.success_status}'`);
                return this.cmiElement.success_status;
            case "cmi._version":
                console.log(`Value of the element in LMS is '${this.cmiElement._version}'`);
                return this.cmiElement._version;
            case "cmi.learner_id":
                console.log(`Value of the element in LMS is '${this.cmiElement.learner_id}'`);
                return this.cmiElement.learner_id;
            case "cmi.learner_name":
                console.log(`Value of the element in LMS is '${this.cmiElement.learner_name}'`);
                return this.cmiElement.learner_name;
            case "cmi.learner_preference.audio_level":
                console.log(`Value of the element in LMS is '${this.cmiElement.learner_preference.audio_level}'`);
                return this.cmiElement.learner_preference.audio_level;
            case "cmi.learner_preference.language":
                console.log(`Value of the element in LMS is '${this.cmiElement.learner_preference.language}'`);
                return this.cmiElement.learner_preference.language;
            case "cmi.learner_preference.delivery_speed":
                console.log(`Value of the element in LMS is '${this.cmiElement.learner_preference.delivery_speed}'`);
                return this.cmiElement.learner_preference.delivery_speed;
            case "cmi.learner_preference.audio_captioning":
                console.log(`Value of the element in LMS is '${this.cmiElement.learner_preference.audio_captioning}'`);
                return this.cmiElement.learner_preference.audio_captioning;
            case "cmi.location":
                console.log(`Value of the element in LMS is '${this.cmiElement.location}'`);
                return this.cmiElement.location;
            case "cmi.score.scaled":
                console.log(`Value of the element in LMS is '${this.cmiElement.score.scaled.toString()}'`);
                return this.cmiElement.score.scaled.toString();
            case "cmi.score.raw":
                console.log(`Value of the element in LMS is '${this.cmiElement.score.raw.toString()}'`);
                return this.cmiElement.score.raw.toString();
            case "cmi.score.min":
                console.log(`Value of the element in LMS is '${this.cmiElement.score.min.toString()}'`);
                return this.cmiElement.score.min.toString();
            case "cmi.score.max":
                console.log(`Value of the element in LMS is '${this.cmiElement.score.max.toString()}'`);
                return this.cmiElement.score.max.toString();
            case "cmi.suspend_data":
                console.log(`Value of the element in LMS is '${this.cmiElement.suspend_data}'`);
                return this.cmiElement.suspend_data;
            case "cmi.launch_data":
                console.log(`Value of the element in LMS is '${this.cmiElement.launch_data}'`);
                return this.cmiElement.launch_data;
            case "cmi.comments":
                console.log(`Value of the element in LMS is '${this.cmiElement.comments}'`);
                return this.cmiElement.comments;
            case "cmi.comments_from_lms":
                console.log(`Value of the element in LMS is '${this.cmiElement.comments_from_lms}'`);
                return this.cmiElement.comments_from_lms;
            case "cmi.session_time":
                console.log(`Value of the element in LMS is '${this.cmiElement.session_time}'`);
                return this.cmiElement.session_time;
            case "cmi.progress_measure":
                console.log(`Value of the element in LMS is '${this.cmiElement.progress_measure}'`);
                return this.cmiElement.progress_measure;
            case "cmi.exit": {
                if (this.cmiElement.suspend_data) {
                    console.log(`Value of the element in LMS is 'suspend'`);
                    return "suspend";
                } else {
                    console.log(`Value of the element in LMS is '${this.cmiElement.exit}'`);
                    return this.cmiElement.exit;
                }
            }
            case "cmi.mode": {
                if (this.cmiElement.suspend_data) {
                    console.log(`Value of the element in LMS is 'normal'`);
                    return "normal";
                } else if (this.cmiElement.completion_status === "incomplete" && this.cmiElement.exit === "suspend") {
                    console.log(`Value of the element in LMS is 'review'`);
                    return "review";
                } else {
                    console.log(`Value of the element in LMS is 'browse'`);
                    return "browse";
                }
            }
            default:
                console.log(`Unknown element: ${element}`);
                return "";
        }
    }

    SetValue(element: string, value: string): string {
        console.log(`Scorm sent value of element '${element}' : ${value}`);

        switch (element) {
            case "cmi.completion_status": {
                const status = convertToCMIStatus(value);
                if (status !== null) {
                    this.cmiElement.completion_status = status;
                }
                break;
            }
            case "cmi.success_status":
                this.cmiElement.success_status = value;
                break;
            case "cmi.learner_id":
                this.cmiElement.learner_id = value;
                break;
            case "cmi._version":
                this.cmiElement._version = value;
                break;
            case "cmi.learner_name":
                this.cmiElement.learner_name = value;
                break;
            case "cmi.learner_preference.audio_level":
                this.cmiElement.learner_preference.audio_level = value;
                break;
            case "cmi.learner_preference.language":
                this.cmiElement.learner_preference.language = value;
                break;
            case "cmi.learner_preference.delivery_speed":
                this.cmiElement.learner_preference.delivery_speed = value;
                break;
            case "cmi.learner_preference.audio_captioning":
                this.cmiElement.learner_preference.audio_captioning = value;
                break;
            case "cmi.location":
                this.cmiElement.location = value;
                break;
            case "cmi.score.scaled":
                this.cmiElement.score.scaled = parseFloat(value);
                break;
            case "cmi.score.raw":
                this.cmiElement.score.raw = parseFloat(value);
                break;
            case "cmi.score.min":
                this.cmiElement.score.min = parseFloat(value);
                break;
            case "cmi.score.max":
                this.cmiElement.score.max = parseFloat(value);
                break;
            case "cmi.suspend_data":
                if (this.cmiElement.completion_status !== "completed") {
                    this.cmiElement.suspend_data = value;
                }
                break;
            case "cmi.launch_data":
                this.cmiElement.launch_data = value;
                break;
            case "cmi.comments":
                this.cmiElement.comments = value;
                break;
            case "cmi.comments_from_lms":
                this.cmiElement.comments_from_lms = value;
                break;
            case "cmi.session_time":
                if (this.cmiElement.completion_status !== "completed") {
                    this.cmiElement.session_time = value;
                }
                break;
            case "cmi.progress_measure":
                this.cmiElement.progress_measure = value;
                break;
            case "cmi.exit":
                this.cmiElement.exit = value;
                break;
            case "cmi.mode":
                this.cmiElement.mode = value;
                break;
            default:
                console.log(`Unknown element: ${element}`);
        }

        return "";
    }

    Commit(event: string) {
        console.log(`lms commit: ${event}`);
    }

    GetLastError(): CMIErrorCode {
        console.log("lms get last error");
        return CMIErrorCode.NO_ERROR;
    }

    GetErrorString(errorCode: CMIErrorCode): string {
        console.log("lms get error string");
        return `Some error - ${errorCode}`;
    }

    GetDiagnostic(errorCode: CMIErrorCode): string {
        console.log("lms get diagnostic");
        return `Some error diagnostic -${errorCode}`;
    }

    private _initCmiElement(): CMIElement2004 {
        return {
            _version: "",
            learner_id: "",
            learner_name: "",
            learner_preference: {
                audio_level: "",
                language: "",
                delivery_speed: "",
                audio_captioning: "",
            },
            completion_status: "not attempted",
            success_status: "unknown",
            location: "",
            score: {
                scaled: 0,
                raw: 0,
                min: 0,
                max: 100,
            },
            session_time: "",
            progress_measure: "",
            exit: "",
            mode: "",
            suspend_data: "",
            launch_data: "",
            comments: "",
            comments_from_lms: "",
            objectives: [],
            student_data: {
                mastery_score: 80,
                max_time_allowed: 0,
                time_limit_action: "exit,no message",
            },
            student_preference: {
                audio: 0,
                language: "English",
                speed: 0,
                text: 0,
            },
            interactions: [],
        };
    }
}

function convertToCMIStatus(value: string): CMIStatus | null {
    switch (value) {
        case "passed":
        case "completed":
        case "failed":
        case "incomplete":
        case "browsed":
        case "not attempted":
            return value;
        default:
            console.error(`Invalid CMIStatus value: ${value}`);
            return null;
    }
}
