import React, { SyntheticEvent, useEffect } from "react";
import Select, {
    components,
    DropdownIndicatorProps,
    GroupBase,
    MenuListProps,
    MenuProps,
    MultiValueProps,
    Props,
    SelectComponentsConfig,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import "./ComboBox.css";
import { Icon, Icons, Tooltip } from "../";
import clsx from "clsx";
import { PrerenderCustomOption } from "Uikit/Forms/SelectCustomComponents/CustomOption";
import { TVoidFunction } from "types";
import { useScreenSize } from "hooks/useMediaQuery";

// Добавляем обработчик onClick для селекта
declare module "react-select/dist/declarations/src/Select" {
    export interface Props<
        Option,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        IsMulti extends boolean,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Group extends GroupBase<Option>,
    > {
        onClick?: (e: SyntheticEvent) => void;
        allPlaceholder?: boolean;
        optionsPlaceholder?: (count: number) => string;
    }
}

export interface ComboBoxProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
    extends Props<Option, IsMulti, Group> {
    isCreatable?: boolean;
    className?: string;
    error?: string;
    addOptionClick?: TVoidFunction;
}

export const CustomClearContainer = (props: any) => {
    return (
        <components.ClearIndicator {...props}>
            <Icon icon={Icons.Close} width={18} height={18} color="fill-gray" />
        </components.ClearIndicator>
    );
};

export const CustomIndicatorsContainer = (props: any) => {
    const { size } = useScreenSize();
    const isLarge = size === "large";
    const iconAlertSide = isLarge ? 28 : 20;

    return (
        <div className={`flex ${props.selectProps?.error ? "!pr-3 2xl:!pr-3" : ""}`}>
            <components.IndicatorsContainer {...props} />
            {props.selectProps?.error && (
                <Tooltip content={props.selectProps?.error}>
                    <div className="pt-1 2xl:pt-0 pr-0 cursor-pointer">
                        <Icon
                            icon={Icons.AlertCircle}
                            color="fill-danger"
                            width={iconAlertSide}
                            height={iconAlertSide}
                        />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export const Menu = (props: MenuProps) => {
    useEffect(() => {
        const menu = document.getElementById(props.innerProps.id ?? "");
        const scrollWrapper = menu?.closest(`.js-scrollbar`);

        if (menu && scrollWrapper) {
            const menuCoords = menu.getBoundingClientRect();
            const scrollWrapperCoords = scrollWrapper.getBoundingClientRect();

            if (menuCoords.bottom > scrollWrapperCoords.bottom) {
                scrollWrapper?.scrollBy({
                    top: menuCoords.bottom - scrollWrapperCoords.bottom + 10,
                    behavior: "smooth",
                });
            }
        }
    }, [props]);

    const value = props.getValue();
    const displaySelectedOptionsMenuIsOpen =
        (props.selectProps as any).additional?.displaySelectedOptionsMenuIsOpen &&
        value.length > 0 &&
        props.selectProps.inputValue.length === 0;

    return (
        <components.Menu {...props}>
            {displaySelectedOptionsMenuIsOpen ? (
                <>
                    {props.getValue().map((option, i) => {
                        return (
                            <PrerenderCustomOption
                                key={i}
                                option={option}
                                formatOptionLabel={props.selectProps.formatOptionLabel as any}
                            />
                        );
                    })}
                </>
            ) : (
                props.children
            )}
        </components.Menu>
    );
};

export const MenuList = (props: MenuListProps) => {
    return <components.MenuList {...props} className="custom-scrollbar"></components.MenuList>;
};

export const MultiValue = (props: MultiValueProps) => {
    if (
        props?.selectProps.inputValue ||
        ((props?.selectProps as any).defaultAdditional?.isInputFocused && props?.selectProps.menuIsOpen)
    ) {
        return null;
    }
    return <components.MultiValue {...props}></components.MultiValue>;
};

export const DropdownIndicator = (
    props: DropdownIndicatorProps<{ value: string; label: string }, false, GroupBase<any>>,
) => {
    return (
        <components.DropdownIndicator className="shrink-0 mr-2 2xl:ml-1 -translate-y-1 -translate-x-1" {...props}>
            <Icon icon={Icons.ChevronDown} width={10} height={6} />
        </components.DropdownIndicator>
    );
};

export const ComboBox = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
    isCreatable = false,
    className,
    ...rest
}: ComboBoxProps<Option, IsMulti, Group>) => {
    const SelectType = isCreatable ? CreatableSelect : Select;
    const componentsProps = {
        DropdownIndicator,
        ClearIndicator: CustomClearContainer,
        IndicatorsContainer: CustomIndicatorsContainer,
        Menu,
        MenuList,
        MultiValue,
        ...rest.components,
    } as unknown as SelectComponentsConfig<Option, IsMulti, Group>;

    return (
        <SelectType
            className={clsx("ui-combo-box", rest.error && `border rounded-lg border-danger`, className)}
            classNamePrefix="ui-combo-box"
            menuPlacement="auto"
            noOptionsMessage={() => <div className="flex w-full p2">Ничего не найдено</div>}
            {...rest}
            components={componentsProps}
            // menuPortalTarget={document.getElementById("root")}
            filterOption={(option, searchText) => option.label?.toLowerCase().includes(searchText?.toLowerCase())}
        />
    );
};
