import React from "react";
import { Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { Modal, Button, FormField, InputField } from "Uikit";
import { TVoidFunction } from "types";

const validationSchema = object().shape({
    title: string()
        .required("обязательно для ввода")
        .min(1, "должно быть не короче 1 символа")
        .max(64, "должно быть не длиннее 64 символов"),
});

export interface ICoursesCategoryModalForm {
    title: string;
}

interface ICoursesCategoryModalProps {
    isOpen: boolean;
    id?: string;
    title?: string;
    onChange: (data: ICoursesCategoryModalForm, forkikHelpers: FormikHelpers<ICoursesCategoryModalForm>) => void;
    onClose: TVoidFunction;
}
export const CoursesCategoryModal = ({ isOpen, id, title, onChange, onClose }: ICoursesCategoryModalProps) => {
    const initialValues: ICoursesCategoryModalForm = { title: title ?? "" };

    if (!isOpen) {
        return null;
    }

    return (
        <Formik validateOnChange initialValues={initialValues} onSubmit={onChange} validationSchema={validationSchema}>
            {({ handleSubmit, values }) => {
                return (
                    <Modal
                        isOpen={isOpen}
                        title={id ? "Редактирование категории" : "Создание категории"}
                        onClose={onClose}
                    >
                        <Modal.Body>
                            <div className="space-y-3.5 mt-4 mb-3.5">
                                <FormField name="title" label="Название категории" isRequired>
                                    <InputField required={true} id="createCategoryCourseInput1" />
                                </FormField>
                            </div>
                            <p className="mb-2 text-[#C9C9C9] text-sm">
                                Название категории отображается в пользовательском интерфейсе
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline"
                                color="secondary"
                                onClick={onClose}
                                id="createCategoryCourseModalBtnCancel"
                            >
                                Отмена
                            </Button>
                            <Button onClick={handleSubmit} disabled={!values.title} id="createCategoryCourseModalBtnOk">
                                {id ? "Сохранить" : "Создать"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            }}
        </Formik>
    );
};
