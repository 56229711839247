import React, { ReactNode, useState } from "react";
import { Button } from "Uikit";
import { TaskCard } from "./TaskCard";

interface ITaskCardsListProps {
    title?: string;
    dataLength: number;
    controlDisplayedItemsLength?: boolean;
    children: ReactNode;
    id?: string;
}

export const TaskCardsList = ({
    title,
    dataLength,
    controlDisplayedItemsLength = false,
    children,
    id,
}: ITaskCardsListProps) => {
    const content = Array.isArray(children) ? children : [children];
    const [displayedItemsLength, setDisplayedItemsLength] = useState(controlDisplayedItemsLength ? 7 : dataLength);

    return (
        <div className="mb-6 sm:mb-8 2xl:mb-9">
            {title && (
                <div className="flex items-center gap-5 2xl:gap-6.25 mb-5">
                    <h2 id={id + "Title"} className="hidden sm:block 2xl:!text-2xl 2xl:!leading-[35px]">
                        {title}
                    </h2>
                    <h3 id={id + "Title"} className="block sm:hidden">
                        {title}
                    </h3>
                    <span className="hidden sm:block p4 text-disabled-stroke 2xl:text-sm">Всего {dataLength}</span>
                </div>
            )}
            <div
                id={id + "Section"}
                className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] sm:gap-x-6.5 2xl:gap-x-8 gap-y-8 2xl:gap-y-9"
            >
                {React.Children.map(
                    content.filter((child) => child),
                    (child, index) => {
                        if (!child || index > displayedItemsLength) {
                            return null;
                        }

                        return (
                            <TaskCardsList.TaskCard
                                key={child.props.task.id}
                                task={child.props.task}
                                onClick={child.props.onClick}
                                showStatusChangeTime={child.props.showStatusChangeTime}
                                statusChangeTimePre={child.props.statusChangeTimePre}
                                isDone={child.props.isDone}
                                onFavoriteChange={child.props.onFavoriteChange}
                            />
                        );
                    },
                )}
            </div>
            {controlDisplayedItemsLength && dataLength - 1 > displayedItemsLength && (
                <div className="mb-10 w-full sm:mt-8 2xl:mt-9 sm:mb-0">
                    <Button
                        variant="outline"
                        className="w-full rounded-lg whitespace-nowrap font-medium !text-black !border-gray-stroke active:!bg-transparent"
                        onClick={() => {
                            setDisplayedItemsLength((prevState) => {
                                return prevState + 8;
                            });
                        }}
                    >
                        Посмотреть еще
                    </Button>
                </div>
            )}
        </div>
    );
};

TaskCardsList.TaskCard = TaskCard;
