import React, { ChangeEvent } from "react";
import { FormGroup, Checkbox } from "Uikit";
import { TaskAnswerResponseItem } from "Api/Responses/TaskResponse";
import { getObjectUniqueId } from "helpers/objectUniqueId";

interface ITaskQuestionMultipleChoiceProps {
    value: string[];
    onChange: (event: ChangeEvent) => void;
    answers: TaskAnswerResponseItem[];
}

export const TaskQuestionMultipleChoice = ({ value, onChange, answers }: ITaskQuestionMultipleChoiceProps) => {
    return (
        <FormGroup value={value} className="flex flex-col gap-4 !bg-transparent sm:!mb-0 2xl:gap-5">
            {answers
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((variant) => {
                    return (
                        <Checkbox
                            key={`TaskItemChoiceItem_${getObjectUniqueId(variant)}`}
                            checked={value?.includes(variant.id)}
                            variant="large"
                            label={variant.answer}
                            value={variant.id}
                            onChange={onChange}
                        />
                    );
                })}
        </FormGroup>
    );
};
