import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import Api from "Api";
import { ProgramReadUserResponse } from "Api/Responses/ProgramResponse";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { setIsFavoriteSelected } from "slices/headerSlice";
import { ResourceType } from "Enums";

export function useGetUIProgram<T = ProgramReadUserResponse>(
    programId: string,
    config?: UseQueryOptions<T, Error, T, any>,
) {
    const dispatch = useDispatch();

    return useQuery<T, Error, T, any>({
        queryKey: ["ui/program", programId],
        queryFn: async () => {
            const res = await Api.Program.ReadUI(programId);
            dispatch(setIsFavoriteSelected(res.isFavorite));
            dispatch(setIsFavoriteSelected(true));
            return res;
        },
        enabled: !!programId,
        ...config,
    });
}

export function useChangeCourseFavoriteStatus() {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(
        async function (program: ProgramReadUserResponse) {
            if (!program?.isFavorite) {
                return await Api.Commons.addFavorites(
                    Object.assign(new CommonsAddFavoritesRequest(), {
                        resourceId: program.resourceId,
                        resourceSolutionId: program.progressId,
                        title: program?.title,
                        logoId: program?.logoId,
                        state: program?.state,
                        type: ResourceType.PROGRAM,
                        progressStatus: program?.progressStatus,
                        ratingPoints: program?.ratingPoints,
                        deadlineTimestamp: program?.deadlineTime ?? 0,
                    }),
                );
            } else if (program?.isFavorite) {
                return await Api.Commons.removeFavorites(
                    Object.assign(new CommonsRemoveFavoritesRequest(), {
                        // resourceId: program.resourceId,
                        resourceId: program.progressId,
                        type: ResourceType.PROGRAM,
                    }),
                );
            } else {
                return null;
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.setQueryData<ProgramReadUserResponse>(
                    ["ui/program", variables.resourceId],
                    (prevCourse) => {
                        dispatch(setIsFavoriteSelected(!prevCourse?.isFavorite));
                        return Object.assign(new ProgramReadUserResponse(), {
                            ...prevCourse,
                            isFavorite: !prevCourse?.isFavorite,
                        });
                    },
                );
            },
        },
    );
}
