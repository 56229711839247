import React from "react";
import "react-day-picker/dist/style.css";
import { Icon, Icons, Tooltip, Input } from "Uikit";

interface IInfoCardRootInputProps {
    actual: number;
    value: number;
    onChange: (value: number) => void;
    id: string;
    tooltip?: string;
}

export const InfoCardRootInput = ({ actual, value, onChange, id, tooltip = "Доступно" }: IInfoCardRootInputProps) => {
    return (
        <div className="inline-flex gap-2 items-center">
            <span>{actual}</span>
            <span className="text-gray">из</span>
            <Input
                isRequired={true}
                placeholder="Введите заголовок"
                value={value}
                onChange={(e) => onChange(Number(e.target.value))}
                maxLength={128}
                type="number"
                // min={actual}
                id={id}
                className="!w-[calc(6ch+48px)]"
                rootClassName="grow-0 !w-auto"
                onBlur={() => {}}
                // onBlur={() => {
                //     if (actual > value) {
                //         onChange(Number(actual > value ? actual : value));
                //     }
                // }}
            />
            <div className="inline-flex gap-1 w-[calc(9ch+14px)]">
                <span className="text-primary">({value - actual})</span>
                <Tooltip placement="top" content={tooltip}>
                    <Icon
                        icon={Icons.Question}
                        color="fill-gray-light"
                        width={14}
                        height={14}
                        className="cursor-pointer"
                    />
                </Tooltip>
            </div>
        </div>
    );
};
