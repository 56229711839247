import { UseQueryOptions, useInfiniteQuery, useQuery } from "react-query";
import { PaginationState, SortingState } from "@tanstack/react-table";

import { BasePaginationResponse } from "Api/BaseResponse";
import { UIRatingListResponse } from "Api/Responses/RatingResponse";
import Api from "Api/index";
import { IOption } from "types";

export function useGetUIRatingUserTeams() {
    return useQuery(
        ["teams", "tree", "current-user", "collection"],
        async () => {
            return await Api.Teams.TreeCurrentUser();
        },
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );
}

export function useGetUIRating<
    T = { users: BasePaginationResponse<UIRatingListResponse>; currentUser: UIRatingListResponse },
>(
    pagination: PaginationState,
    sorting: SortingState,
    search?: string,
    teamId?: string,
    period?: IOption,
    config?: UseQueryOptions<T, Error, T, any>,
) {
    return useQuery<T, Error, T, any>({
        queryKey: ["ui/rating", "desktop", pagination, sorting, search, period],
        queryFn: async () => {
            const filtersData: any = {};

            if (search) {
                filtersData["searchQuery.contains"] = search;
            }

            if (teamId) {
                filtersData["teamId.equal"] = teamId;
            }

            if (period) {
                filtersData["ratingType.equal"] = period.value;
            }

            const currentUserRating = await Api.Rating.getCurrentUserRating();
            const usersRating = await Api.Rating.getAllRating(
                pagination.pageIndex,
                pagination.pageSize,
                sorting,
                filtersData,
            );
            usersRating.Content.unshift(currentUserRating);

            return { users: usersRating, currentUser: currentUserRating };
        },
        ...config,
    });
}

export function useGetUIMobileRating<T = BasePaginationResponse<UIRatingListResponse>>(
    search: string,
    teamId: string,
    period?: IOption,
    config?: UseQueryOptions<T, Error, T, any>,
) {
    return useInfiniteQuery<T, Error, T, any>({
        queryKey: ["users", "rating", search, teamId, period],
        queryFn: async ({ pageParam = 0 }) => {
            const filtersData: any = {};

            if (search) {
                filtersData["searchQuery.contains"] = search;
            }

            if (teamId) {
                filtersData["teamId.equal"] = teamId;
            }

            if (period) {
                filtersData["ratingType.equal"] = period.value;
            }

            const usersRating = await Api.Rating.getAllRating(
                pageParam,
                15,
                [{ id: "rating", desc: true }],
                filtersData,
            );

            if (pageParam === 0) {
                const currentUserRating = await Api.Rating.getCurrentUserRating();
                usersRating.Content.unshift(currentUserRating);
            }

            return usersRating;
        },
        getNextPageParam: (lastPage: any, allPages: any) => {
            const nextPage = allPages.length;
            return lastPage.Content.length !== 0 ? nextPage : undefined;
        },
        ...config,
    });
}
