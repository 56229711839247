import React, { useState } from "react";
import { useInvalidate } from "hooks/useInvalidate";
import { Button, ComboBox, FormGroup, Label, Modal, SubmitButton } from "Uikit";
import Api from "Api";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { ParentTeamChoiceModal } from "../../Settings/Group/Teams/ParentTeamChoiceModal";
import { IOption, TVoidFunction } from "types";

interface IBlockMemberModal {
    id: string;
    type: "block" | "unblock";
    isOpen: boolean;
    teams?: TeamTreeAllBasicInfoResponse[];
    selectedUsers?: { id?: string; firstName: string; lastName: string }[];
    onSubmit: TVoidFunction;
    onClose: TVoidFunction;
}
export const BlockMemberModal = ({ id, type, isOpen, teams, selectedUsers, onSubmit, onClose }: IBlockMemberModal) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [teamId, setTeamId] = useState<string | undefined>(undefined);
    const [checkedTeamIds, setCheckedTeamIds] = useState<Record<string, string[]>>({});

    const [selectedTeams, setSelectedTeams] = useState<any>({});

    const invalidate = useInvalidate();
    const selectedCount = selectedUsers?.length ?? 0;

    const modalTitle = {
        block: {
            text: `Блокировка ${selectedCount === 1 ? "пользователя" : "пользователей"}`,
        },
        unblock: {
            text: `Разблокировка ${selectedCount === 1 ? "пользователя" : "пользователей"}`,
        },
    };
    const modalBodyText = {
        block: {
            text: `Вы действительно хотите заблокировать ${
                selectedCount === 1
                    ? "пользователя? Пользователь больше не сможет"
                    : "пользователей? Они больше не смогут"
            }
              войти в систему`,
        },
        unblock: {
            text: `Вы действительно хотите разблокировать ${
                selectedCount === 1 ? "пользователя? Пользователь снова сможет" : "пользователей? Они снова смогут"
            } 
            пользоваться системой`,
        },
    };

    const getTeamOptions = (teamId: string) => {
        const parentTeam = teams?.find((p) => p.id === teamId);

        const getTeams = (team: TeamTreeAllBasicInfoResponse) => {
            let response: IOption[] = [{ label: team.name, value: team.id }];

            for (const element of team.subTeams) {
                response = response.concat(getTeams(element));
            }

            return response;
        };

        return getTeams(parentTeam!);
    };
    const handleSubmit = async () => {
        if (!selectedUsers?.length) {
            return;
        }

        setIsLoading(true);

        await Promise.all(
            selectedUsers.map((user) =>
                type === "block"
                    ? Api.User.Block(user.id!)
                    : Api.User.UnBlock(
                          user.id!,
                          Object.keys(selectedTeams).map((p) => selectedTeams[p].value),
                      ),
            ),
        );

        setIsLoading(false);

        invalidate("users");
        invalidate(["users", "blocked"]);

        onSubmit();
    };

    const onModalClose = () => {
        setSelectedTeams({});
        onClose();
    };

    if (!teams) {
        return null;
    }

    return (
        <Modal id={id} className="max-h-150" isOpen={isOpen} title={modalTitle[type].text} onClose={onModalClose}>
            <ParentTeamChoiceModal
                isOpen={!!teamId}
                title="Выбор команд"
                isMulti={false}
                selectable={true}
                teamIds={teams.filter((p1) => p1.id !== teamId).map((p1) => p1.id)}
                onSelect={(selectedTeamId) => {
                    setSelectedTeams((prev: any) => {
                        prev[teamId!] = getTeamOptions(teamId!).find(
                            (p) => p.value === selectedTeamId[0].replace("root:", ""),
                        );

                        return prev;
                    });
                    setCheckedTeamIds((prevCheckedTeamIds) => {
                        return {
                            ...prevCheckedTeamIds,
                            [teamId as string]: selectedTeamId,
                        };
                    });

                    const modalBody = document.getElementById("blockMemberModalBody")!;
                    const modalBodyScrollTop = modalBody.scrollTop;

                    setTeamId(undefined);
                    setTimeout(() => modalBody.scrollTo(0, modalBodyScrollTop), 300);
                }}
                onClose={() => {
                    const modalBody = document.getElementById("blockMemberModalBody")!;
                    const modalBodyScrollTop = modalBody.scrollTop;

                    setTeamId(undefined);
                    setTimeout(() => modalBody.scrollTo(0, modalBodyScrollTop), 300);
                }}
                checkedTeamIds={checkedTeamIds[teamId as string]}
            />
            <Modal.Body id="blockMemberModalBody" className="!mb-0 pb-8 max-h-110 overflow-x-hidden overflow-y-auto">
                <div className="text-sm small leading-6 text-[#9B9B9B] mr-auto">
                    {selectedUsers && selectedCount === 1
                        ? selectedUsers[0].lastName + " " + selectedUsers[0].firstName
                        : `Выбрано пользователей: ${selectedCount}`}
                </div>
                <div className="mb-6 mt-3">
                    <p className="text-sm text-[#262626]-500">{modalBodyText[type].text}</p>
                </div>
                {type !== "block" && (
                    <FormGroup id="unblockMemberModalBodyRootTeamsList">
                        {teams.map((p, index) => {
                            return (
                                <FormGroup
                                    key={p.id}
                                    className="last:mb-0"
                                    onClick={() => setTeamId(p.id)}
                                    id={"unblockMemberModalBodyRootTeamGroup" + index}
                                >
                                    <Label isRequired>{p.name}</Label>
                                    <ComboBox
                                        openMenuOnClick={false}
                                        menuPlacement="auto"
                                        placeholder="Выберите команду"
                                        options={getTeamOptions(p.id)}
                                        value={selectedTeams[p.id]}
                                    />
                                </FormGroup>
                            );
                        })}
                    </FormGroup>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="border-[#EAEDF3]"
                    variant="outline"
                    color="secondary"
                    onClick={onModalClose}
                    id="adminMembersModalBlockCancel"
                >
                    Отмена
                </Button>
                <SubmitButton
                    isLoading={isLoading}
                    color={type === "block" ? "danger" : "primary"}
                    disabled={type !== "block" && Object.keys(selectedTeams).length !== teams.length}
                    onClick={handleSubmit}
                    id="adminMembersModalBlockOk"
                >
                    {type === "block" ? "Заблокировать" : "Разблокировать"}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};
