import React from "react";
import { Button, Modal } from "Uikit";
import { Resource } from "Api/Responses/lmsRoles/ResourceAccess";

interface AccessModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    selectedItems: Resource[];
    accessAction: "grant" | "revoke";
    type: string;
}

const getTypeText = (type: string) => {
    switch (type) {
        case "training":
            return "данную программу обучения";
        case "courses":
            return "данный курс";
        case "quiz":
            return "данный тест";
        case "exercises":
            return "данное задание";
        case "questions":
            return "данный опрос";
        default:
            return "данный материал";
    }
};

export const AccessModal = ({ isOpen, setIsOpen, selectedItems, accessAction, type }: AccessModalProps) => {
    const modalFooter = [
        <Button
            key={0}
            onClick={() => setIsOpen(false)}
            variant="outline"
            size={"medium"}
            color={"secondary"}
            className={"border-[#EAEDF3] "}
        >
            Отмена
        </Button>,
        <Button
            key={1}
            onClick={() => setIsOpen(false)}
            size={"medium"}
            color={accessAction === "grant" ? "primary" : "danger"}
        >
            {accessAction === "grant" ? "Открыть" : "Забрать"} доступ
        </Button>,
    ];

    const modalTitle = `${accessAction === "grant" ? "Открыть " : "Забрать "} доступ`;

    const modalBodyText = {
        grant: `Вы действительно хотите открыть доступ для ${
            selectedItems.length === 1
                ? "пользователя? Пользователь получит доступ к курсу и сможет "
                : "пользователей? Пользователи получат доступ к курсу и смогут "
        }
        его видеть`,
        revoke: `Вы действительно хотите забрать доступ? Пользователь больше не увидит ${getTypeText(type)}`,
    };

    const modalBodyUsers = selectedItems.length > 4 ? "пользователей" : "пользователя";
    const modalBody = (
        <div>
            <div className="text-sm small leading-6 text-[#9B9B9B] mr-auto">
                {selectedItems.length === 1
                    ? selectedItems[0].name
                    : `Выбрано ${selectedItems.length} ${modalBodyUsers}`}
            </div>

            <div className="mb-6 mt-3">
                <p className="text-sm text-[#262626]-500">{modalBodyText[accessAction]}</p>
            </div>
        </div>
    );
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={modalTitle}>
            <Modal.Body>{modalBody}</Modal.Body>
            <Modal.Footer>{modalFooter}</Modal.Footer>
        </Modal>
    );
};
