import { BaseRequest } from "Api/BaseRequest";
import { CMIStatus } from "Pages/Admin/Material/components/Scorm/ScormModel";

export class ScormProgressRequest extends BaseRequest {
    materialId?: string;
    componentId?: string;
    courseId?: string;
    version: string;
    completionStatus: CMIStatus;
    successStatus?: string;
    score: number;
    complexity: "EASY" | "MEDIUM" | "HARD" | "SCORM";
    scoreRewriteLimit: number;
    progressData: string;
}
