import React, { ReactNode } from "react";
import { Tab as HeadlessUiTab } from "@headlessui/react";
import { Tabs } from "./Tabs";
import { Content } from "./Content";

interface ITabsWrapper {
    children: ReactNode;
    className?: string;
    selectedIndex?: number;
    onChange?: (index: number) => void;
}
export const TabsWrapper = ({ children, className, selectedIndex, onChange }: ITabsWrapper) => {
    return (
        <div className={className ?? "flex flex-col"}>
            <HeadlessUiTab.Group selectedIndex={selectedIndex} onChange={onChange}>
                {children}
            </HeadlessUiTab.Group>
        </div>
    );
};

TabsWrapper.Tabs = Tabs;
TabsWrapper.Content = Content;
