import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as _ from "lodash";

import { MaterialType } from "Enums";

import { MaterialForm } from "./MaterialForm";
import { Loader } from "Uikit/Loader/Loader";

import Api from "../../../Api";
import { BaseIdRequest1 } from "Api/BaseRequest";
import { FileReadResponse } from "Api/Responses/FileResponse";
import { FilePreviewResponse } from "Api/Responses/UploadResponse";
import { BaseObjectToArrayResponse } from "Api/BaseResponse";
import { MaterialFile } from "Api/Responses/MaterialResponse";
import { CurrentUserResponse } from "Api/Responses/UserResponse";
import { ScormResponse } from "Api/Responses/ScormResponse";
import { useQueryClient } from "react-query";
import { numCapEnd } from "helpers/numCapEnd";
import { IOption } from "types";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

export interface IMaterialForm {
    id?: string;
    type: MaterialType;
    settings: {
        image?: File;
        logoId: string;
        title: string;
        description: string;
        category: IOption;
        complexity: IOption;
        scoreRewriteLimit: number | null;
        userId: string;
        isAllowRewind: boolean;
        isAllowComments: boolean;
    };
    article: {
        content: string;
        isTraining: boolean;
        attachments: FileReadResponse[];
    };
    file?: MaterialFile;
    fileId: string;
    fileType?: string;
    documentPreview?: FilePreviewResponse;
    approxCompletionMinutes: number;
    isApproxCompletionMinutesManuallyChanged: boolean;
    isActive: boolean;
    questions: any[];
    modifyTime: number;
    scorm?: ScormResponse;
}

const initialValues: IMaterialForm = {
    id: undefined,
    type: MaterialType.Article,
    settings: {
        image: undefined,
        logoId: "",
        title: "",
        description: "",
        category: { label: "", value: "" },
        complexity: { label: "", value: "" },
        scoreRewriteLimit: null,
        userId: "",
        isAllowComments: true,
        isAllowRewind: false,
    },
    article: {
        isTraining: false,
        content: "",
        attachments: [],
    },
    fileId: "",
    documentPreview: undefined,
    approxCompletionMinutes: 0,
    isApproxCompletionMinutesManuallyChanged: false,
    isActive: true,
    questions: [],
    modifyTime: 0,
    scorm: undefined,
};

const getRatingCaption = (value: number) => {
    return numCapEnd({ one: "балл", two: "балла", few: "баллов" }, value);
};

export const Material = ({ action }: { action: "CREATE" | "COPY" | "EDIT" }) => {
    const queryClient = useQueryClient();
    const location = useLocation();
    const params = useParams();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const [isLoading, setIsLoading] = useState(true);
    const [isInit, setIsInit] = useState(false);

    const [ratingList, setRatingList] = useState<IOption[]>([]);
    const [categoryList, setCategoryList] = useState<IOption[]>([]);
    const [material, setMaterial] = useState<IMaterialForm>(_.cloneDeep(initialValues));
    const [defaultLogos, setDefaultLogos] = useState<any>([]);

    const responsibleList = useResponsibleList(material.settings.userId);

    const initComponentData = useCallback(async () => {
        if (isInit) return;

        const categoryId = queryParams.get("categoryId");
        const materialId = params?.id;
        const type = params?.type;

        try {
            const _material = _.cloneDeep(initialValues);
            const defaultLogosRes = await getDefaultLogos();
            const currentUser: CurrentUserResponse | undefined = queryClient.getQueryData(["users", "current"]);

            const categoriesRes = await Api.MaterialCategory.List();
            setCategoryList(
                categoriesRes.Content.map((i) => ({
                    label: i.title,
                    value: i.id,
                })),
            );
            if (categoryId) {
                const selectedCategory = categoriesRes.Content.find((i) => i.id === categoryId);

                if (selectedCategory) {
                    _material.settings.category = { label: selectedCategory.title, value: selectedCategory.id };
                }
            }

            // const usersRes = await Api.User.GetAdministratorsList();

            if (type && type !== "MATERIAL" && !materialId) {
                const ratingResponse = await Api.Rating.ReadByType("MATERIAL", type);
                const ratingList = [
                    { label: "Без оценки (0 баллов)", value: "NONE" },
                    { label: `Низкая (${ratingResponse.EASY} ${getRatingCaption(ratingResponse.EASY)})`, value: "LOW" },
                    {
                        label: `Средняя (${ratingResponse.MEDIUM} ${getRatingCaption(ratingResponse.MEDIUM)})`,
                        value: "MEDIUM",
                    },
                    {
                        label: `Высокая (${ratingResponse.HARD} ${getRatingCaption(ratingResponse.HARD)})`,
                        value: "HIGH",
                    },
                ];

                if (type === "SCORM") {
                    ratingList.push({ label: `Из теста в SCORM`, value: "SCORM" });
                }
                setRatingList(ratingList);
                _material.settings.complexity = ratingList.find((i) => i.value === "MEDIUM") ?? {
                    label: "",
                    value: "",
                };
                _material.type = type as MaterialType;
            }

            if (currentUser) {
                _material.settings.userId = currentUser.id;
            }

            _material.settings.logoId =
                defaultLogosRes.Content[Math.floor(Math.random() * defaultLogosRes.Content.length)];

            _material.approxCompletionMinutes = type === MaterialType.Document || type === MaterialType.SCORM ? 5 : 1;

            if (materialId) {
                const materialResponse = await Api.Material.Read(new BaseIdRequest1(materialId));
                const materialCategory = categoriesRes.Content.find((i) => i.id === materialResponse.category?.id);
                // const materialUser = usersRes.Content.find((i) => i.id === materialResponse.managerUserId);
                const ratingResponse = await Api.Rating.ReadByType("MATERIAL", materialResponse.type || "ARTICLE");
                const ratingList = [
                    { label: "Без оценки (0 баллов)", value: "NONE" },
                    { label: `Низкая (${ratingResponse.EASY} ${getRatingCaption(ratingResponse.EASY)})`, value: "LOW" },
                    {
                        label: `Средняя (${ratingResponse.MEDIUM} ${getRatingCaption(ratingResponse.MEDIUM)})`,
                        value: "MEDIUM",
                    },
                    {
                        label: `Высокая (${ratingResponse.HARD} ${getRatingCaption(ratingResponse.HARD)})`,
                        value: "HIGH",
                    },
                ];

                if (type === "SCORM") {
                    ratingList.push({ label: `Из теста в SCORM`, value: "SCORM" });
                }
                setRatingList(ratingList);

                _material.id = materialResponse.id;
                _material.type = (materialResponse.type || type) as MaterialType;
                _material.settings.image = undefined;
                _material.settings.logoId = materialResponse.logoId ?? initialValues.settings.logoId;
                _material.settings.title =
                    action === "COPY" ? "Копия 1 " + materialResponse.title : materialResponse.title;
                _material.settings.description = materialResponse.description;
                _material.settings.scoreRewriteLimit = materialResponse.scoreRewriteLimit;
                _material.settings.category = materialCategory
                    ? {
                          label: materialCategory.title,
                          value: materialCategory.id,
                      }
                    : _material.settings.category;
                _material.settings.complexity = ratingList.find((i) => i.value === materialResponse.complexity) ?? {
                    label: `Средняя (${ratingResponse.MEDIUM} ${getRatingCaption(ratingResponse.MEDIUM)})`,
                    value: "MEDIUM",
                };

                // if (materialUser) {
                //     _material.settings.userId = materialUser.id;
                // }

                _material.settings.isAllowComments = materialResponse.allowComments;
                _material.settings.isAllowRewind = !materialResponse.allowRewind;
                _material.article.content = materialResponse.content ?? "";
                _material.article.attachments = materialResponse.attachedFiles || [];
                _material.article.isTraining = materialResponse.isTraining ?? false;
                _material.isActive = action === "COPY" ? false : materialResponse.state === "ACTIVE";

                _material.approxCompletionMinutes =
                    materialResponse.type === MaterialType.Document
                        ? materialResponse?.approxCompletionMinutes ?? 5
                        : materialResponse.approxCompletionMinutes || 1;
                _material.isApproxCompletionMinutesManuallyChanged =
                    materialResponse.isApproxCompletionMinutesManuallyChanged ?? false;
                _material.modifyTime = materialResponse.modifyTimestamp;
                _material.fileId = materialResponse.file?.id;
                _material.file = materialResponse.file;
                _material.scorm = materialResponse.scorm;
            }

            setMaterial((prevState) => ({
                ...prevState,
                ..._material,
            }));
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
            setIsInit(true);
        }
    }, [queryParams, params, action, isInit, queryClient]);

    const getDefaultLogos = async (): Promise<BaseObjectToArrayResponse<string>> => {
        const res = await Api.Upload.GetDefaultLogos();

        setDefaultLogos(res.Content);

        return res;
    };

    useEffect(() => {
        initComponentData().then();
    }, [initComponentData]);

    useEffect(() => {
        if (params.id) {
            setIsInit(false);
        }
    }, [params.id]);

    return (
        <div className="w-full h-full flex justify-center items-center">
            {!isLoading ? (
                <MaterialForm
                    material={material}
                    actionType={action}
                    categoryOptions={categoryList}
                    ratingOptions={ratingList}
                    userOptions={responsibleList}
                    defaultLogos={defaultLogos}
                    queryParams={queryParams}
                    setMaterial={setMaterial}
                />
            ) : (
                <Loader />
            )}
        </div>
    );
};
