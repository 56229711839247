import React, { useState } from "react";
import classNames from "classnames";
import cx from "./audio-player.module.scss";
import { Icon, Icons } from "Uikit/index";

interface VolumeProps {
    volume: number;
    onVolumeUpdate: (e: number) => void;
}

export default function Volume({ volume, onVolumeUpdate }: Readonly<VolumeProps>) {
    const [isMute, setIsMute] = useState(false);
    const [currentVolume, setCurrentVolume] = useState(volume);
    const volumePercentage = volume * 100;

    const calcClickedVolume = (e: MouseEvent) => {
        const clickPositionInPage = e.pageX;
        const bar = document.getElementById("volume-bar") as HTMLDivElement;

        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;

        let clickPositionInBar;

        if (clickPositionInPage - barStart > barWidth) {
            clickPositionInBar = barWidth;
        } else if (clickPositionInPage - barStart < 0) {
            clickPositionInBar = 0;
        } else {
            clickPositionInBar = clickPositionInPage - barStart;
        }

        const volumePerPixel = 100 / barWidth;
        return (volumePerPixel * clickPositionInBar) / 100;
    };

    const handleVolumeDrag = (e: MouseEvent) => {
        if (isMute) {
            setIsMute(false);
        }

        onVolumeUpdate(calcClickedVolume(e));

        const updateVolumeOnMove = (eMove: MouseEvent) => {
            onVolumeUpdate(calcClickedVolume(eMove));
        };

        document.addEventListener("mousemove", updateVolumeOnMove);
        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateVolumeOnMove);
        });
    };

    function mute() {
        if (!isMute) {
            setIsMute(true);
            setCurrentVolume(volume);

            onVolumeUpdate(0);
        } else {
            setIsMute(false);
            setCurrentVolume(0);

            onVolumeUpdate(currentVolume);
        }
    }

    return (
        <div className={cx.volume}>
            <div className={cx.muteIcon} onClick={mute}>
                <Icon icon={isMute ? Icons.VolumeOff : Icons.VolumeOn} width={14} height={14} />
            </div>
            <div
                id="volume-bar"
                className={classNames(cx.barProgress, cx.volumeValue)}
                style={{
                    background: `linear-gradient(to right, #1280CE ${volumePercentage}%, #1280CE 0, #EAEDF3  ${volumePercentage}%, #EAEDF3 100%)`,
                }}
                onMouseDown={(e) => handleVolumeDrag(e as unknown as MouseEvent)}
            >
                <span className={cx.barProgressKnob} style={{ left: `${volumePercentage - 2}%` }} />
            </div>
        </div>
    );
}
