import React from "react";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";

interface ICustomFormatUserOptionsLabelProps {
    label: string;
    value?: {
        avatarId: string;
        defaultAvatarColor: string;
        firstName: string;
        lastName: string;
    };
}

// Кастомный компонент для react-select
// Отображение выбранного значения асинхронном селекте с аватаркой/без аватарки
export const CustomFormatUserOptionsLabel = ({
    label,
    value,
}: ICustomFormatUserOptionsLabelProps): React.ReactElement => {
    return (
        <div className="flex gap-2">
            <UserAvatar
                avatarId={value?.avatarId}
                color={value?.defaultAvatarColor}
                userInitials={`${value?.firstName?.slice(0, 1)}${value?.lastName?.slice(0, 1)}`}
                size={20}
            />
            <div>{label}</div>
        </div>
    );
};
