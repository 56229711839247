import React from "react";
import { Button, Modal } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { Except } from "type-fest";
import { TVoidFunction } from "types";

interface LoadingModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    onCancel: TVoidFunction;
}

export const LoadingModal = ({ isOpen, onClose, onCancel, ...rest }: LoadingModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Загрузка файла" className="!w-142 !max-w-142 !overflow-visible">
            <ModalContent onClose={onClose} onCancel={onCancel} {...rest} />
        </Modal>
    );
};

const ModalContent = ({ onClose, onCancel }: Except<LoadingModalProps, "isOpen">) => {
    const handleSubmit = async () => {
        onClose();
        onCancel();
    };

    return (
        <>
            <Modal.Body className="flex flex-col items-center gap-5 pt-6">
                <Loader />
                <p className="text-center">
                    Дождитесь окончания формирования файла. Загрузка начнется автоматически...
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    onClick={handleSubmit}
                    variant="outline"
                    size={"medium"}
                    color={"common"}
                    className="border-[#EAEDF3]"
                >
                    Отменить действие
                </Button>
            </Modal.Footer>
        </>
    );
};
