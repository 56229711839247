import React, { ReactNode } from "react";
import clsx from "clsx";

interface ButtonProps {
    label: string;
    checked?: boolean;
    readOnly?: boolean;
    onChange?: (value: ReactNode) => void;
    value: ReactNode;
    className?: string;
}

export const Button = ({ label, checked, onChange, value, readOnly, className }: ButtonProps) => {
    return (
        <li className={clsx("w-full rounded-t-lg", className)}>
            <div className="flex items-center form-check">
                <label
                    onClick={() => {
                        if (onChange && !readOnly) {
                            onChange(value);
                        }
                    }}
                    className="flex gap-2 2xl:gap-2.5 items-center"
                >
                    <input
                        type="radio"
                        checked={checked}
                        name="list-radio"
                        readOnly={readOnly}
                        onChange={() => {}}
                        className={clsx(
                            checked
                                ? "bg-[#1380CE] text-blue border-white border-2 2xl:border-4 ring-1 !ring-blue"
                                : "",
                            "cursor-pointer radio w-5 h-5 2xl:w-7.5 2xl:h-7.5 border-gray-background ring-1 ring-input-stroke",
                            !readOnly && "focus:ring-offset-0 focus:ring-1 ring-offset-0 focus:ring-blue",
                        )}
                    />
                    <span className="w-full p1 2xl:text-md text-black block">{label}</span>
                </label>
            </div>
        </li>
    );
};
