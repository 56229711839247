import React, { useState, useEffect, Fragment } from "react";
import { useQuery } from "react-query";
import isAfter from "date-fns/isAfter";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash/debounce";
import Api from "Api";
import { useInvalidate } from "hooks/useInvalidate";
import { TaskPublicListResponse } from "Api/Responses/TaskPublicResponse";
import { Empty } from "Uikit/Page/Empty";
import { Icon, Icons, Button } from "Uikit";
import { Filter } from "Components/Filter/Filter";
import { ProgressStatus } from "Enums";
import { TaskCardsList } from "./TaskCardsList";
import { TasksCardListMobile } from "./TasksCardListMobile";
import { TasksSearch } from "./TasksSearch";
import { tasksKeys, useChangeTaskFavoriteStatus } from "./task.hooks";
import { Preloader } from "Components/Preloader/Preloader";
import { useScreenSize } from "hooks/useMediaQuery";

export const TasksAll = () => {
    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const [tasks, setTasks] = useState<TaskPublicListResponse[]>([]);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [search, setSearch] = useState<string>("");
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const [isMounted, setIsMounted] = useState(false);

    const dataQuery = useQuery({
        queryKey: tasksKeys.listAvailable(),
        queryFn: async () => {
            return (await Api.TaskPublic.AvailableList()) as unknown as TaskPublicListResponse[];
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: isMounted,
    });

    const { mutate: mutateFavoriteStatus } = useChangeTaskFavoriteStatus();

    const onFavoriteChange = (resource: TaskPublicListResponse, isFavorite: boolean) => {
        mutateFavoriteStatus({ resource, isFavorite, queryKeys: tasksKeys.listAvailable() });
    };

    const { data, isFetching } = dataQuery;

    useEffect(() => {
        const dataLength = data?.length ?? 0;
        if (dataLength > 0) {
            setTasks(data as TaskPublicListResponse[]);
        }
    }, [data]);

    const filtersConfig = [
        {
            label: "Награда, баллы",
            fields: [
                {
                    accessor: "complexity",
                    type: "range",
                    min:
                        data?.length !== 0
                            ? data?.reduce((prev, curr) => (prev.ratingPoints < curr.ratingPoints ? prev : curr))
                                  .ratingPoints
                            : 0,
                    max:
                        data?.length !== 0
                            ? data?.reduce((prev, curr) => (prev.ratingPoints > curr.ratingPoints ? prev : curr))
                                  .ratingPoints
                            : 0,
                },
            ],
        },
        {
            label: "Дополнительно",
            fields: [
                {
                    accessor: "withDeadline",
                    label: "С дедлайном",
                    type: "checkbox",
                    default: true,
                },
            ],
        },
    ];

    useEffect(() => {
        if (filters["complexity"]) {
            setTasks(
                !filters["complexity"]["maxValue"]
                    ? data?.filter(
                          (p) =>
                              p.ratingPoints >= filters["complexity"]["minValue"] &&
                              p.ratingPoints <=
                                  data?.reduce((prev, curr) => (prev.ratingPoints > curr.ratingPoints ? prev : curr))
                                      .ratingPoints,
                      ) ?? []
                    : data?.filter(
                          (p) =>
                              p.ratingPoints >= filters["complexity"]["minValue"] &&
                              p.ratingPoints <= filters["complexity"]["maxValue"],
                      ) ?? [],
            );
        }
        if (filters["withDeadline"]) {
            setTasks(data?.filter((p) => p.deadlineTime) ?? []);
        }
        if (Object.keys(filters).length === 0) {
            setTasks(data ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, filters]);

    const debounceSearch = debounce((value) => {
        setSearch(value);
    }, 500);

    useEffect(() => {
        setTasks(data?.filter((p) => p.title.toLowerCase().includes(search.toLowerCase())) ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // Требуют перепрохождения
    const tasksToRework =
        tasks.filter(({ progressStatus }) => {
            return progressStatus === ProgressStatus.RE_WORK;
        }) ?? [];

    // Актуальное
    const tasksActual =
        tasks
            ?.filter(({ progressStatus, deadlineTime }) => {
                return (
                    progressStatus === ProgressStatus.IN_PROGRESS ||
                    (!!deadlineTime && progressStatus !== ProgressStatus.RE_WORK)
                );
            })
            .sort((item1: TaskPublicListResponse, item2: TaskPublicListResponse) => {
                if (item1.deadlineTime && item2.deadlineTime) {
                    if (isAfter(new Date(item1.deadlineTime), new Date(item2.deadlineTime))) {
                        return 1;
                    }
                    if (isAfter(new Date(item2.deadlineTime), new Date(item1.deadlineTime))) {
                        return -1;
                    }
                } else {
                    if (!item1.deadlineTime && item2.deadlineTime) {
                        return 1;
                    }
                    if (item1.deadlineTime && !item2.deadlineTime) {
                        return -1;
                    }
                    if (!item1.deadlineTime && !item2.deadlineTime) {
                        if (isAfter(new Date(item1.statusChangeTime), new Date(item2.statusChangeTime))) {
                            return 1;
                        }
                        if (isAfter(new Date(item2.statusChangeTime), new Date(item1.statusChangeTime))) {
                            return -1;
                        }
                    }
                }
                return 0;
            }) ?? [];

    // Категории
    const tasksCategorized: {
        categoryId: string;
        categoryTitle: string;
        tasks: TaskPublicListResponse[];
    }[] = [];

    tasks
        .filter(({ progressStatus, deadlineTime }) => {
            if (deadlineTime) {
                return false;
            } else {
                return ![ProgressStatus.RE_WORK, ProgressStatus.IN_PROGRESS].includes(progressStatus);
            }
        })
        .forEach((taskItem) => {
            const { category } = taskItem;
            if (category) {
                const { id, title } = category;
                const categoryItem = tasksCategorized.find((item) => item.categoryId === id);
                if (categoryItem) {
                    categoryItem.tasks.push(taskItem);
                } else {
                    tasksCategorized.push({
                        categoryId: id,
                        categoryTitle: title,
                        tasks: [taskItem],
                    });
                }
            }
        });
    const dataLength = tasks.length ?? 0;

    const goToTask = (solutionId: string, resourceId: string) => navigate(`/task/${resourceId}`);

    useEffect(() => {
        setIsMounted(true);
        invalidate("tasks");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { size } = useScreenSize();
    const isSmall = size === "small";

    return (
        <>
            {isSmall && (
                <div
                    className="absolute top-3.5 right-10 block sm:hidden z-[100]"
                    onClick={() => setIsFilterShow(true)}
                >
                    <Icon icon={Icons.Filter} width={18} height={18} color="stroke-blue-drk" />
                </div>
            )}
            <Filter
                isActive={isFilterShow}
                setIsActive={setIsFilterShow}
                configuration={filtersConfig}
                filters={filters}
                onChange={setFilters}
            />
            {!isFetching && (data?.length !== 0 || Object.keys(filters).length !== 0) && (
                <div className="hidden sm:flex justify-between mb-5 2xl:mb-6.25">
                    <TasksSearch onChange={(value) => debounceSearch(value)} />
                    <Button
                        variant="outline"
                        color="secondary"
                        size="medium"
                        className="border-[#E6E9ED] rounded-lg font-medium"
                        icon={
                            <Icon
                                icon={Icons.Filter}
                                width={20}
                                height={20}
                                color="stroke-blue"
                                className="2xl:!w-6.25 2xl:!h-6.25"
                            />
                        }
                        iconPlacement={"left"}
                        onClick={() => setIsFilterShow(true)}
                        id="userTasksBtnFilter"
                    >
                        Фильтры
                    </Button>
                </div>
            )}
            {dataLength > 0 && !isFetching && (
                <>
                    {/* Требуют перепрохождения */}
                    {tasksToRework.length > 0 && (
                        <TaskCardsList
                            title="Требуют перепрохождения"
                            dataLength={tasksToRework.length}
                            controlDisplayedItemsLength
                            id="userTasksReplay"
                        >
                            {tasksToRework.map((item) => {
                                return (
                                    <TaskCardsList.TaskCard
                                        key={item.id}
                                        task={item}
                                        onClick={goToTask}
                                        onFavoriteChange={onFavoriteChange}
                                    />
                                );
                            })}
                        </TaskCardsList>
                    )}
                    {/* Актуальное */}
                    {tasksActual.length > 0 && (
                        <TaskCardsList
                            title="Актуальное"
                            dataLength={tasksActual.length}
                            controlDisplayedItemsLength
                            id="userTasksActual"
                        >
                            {tasksActual.map((item) => {
                                return (
                                    <TaskCardsList.TaskCard
                                        key={item.id}
                                        task={item}
                                        onClick={goToTask}
                                        onFavoriteChange={onFavoriteChange}
                                    />
                                );
                            })}
                        </TaskCardsList>
                    )}
                    {[tasksToRework, tasksActual, tasksCategorized].every((item) => item.length > 0) && (
                        <div className="border-b-[1px] border-solid border-blue-gray mb-7"></div>
                    )}
                    {/* Категории */}
                    {tasksCategorized.length > 0 && (
                        <>
                            {tasksCategorized.map(({ categoryId, categoryTitle, tasks }) => {
                                return (
                                    <Fragment key={categoryId}>
                                        <div className="hidden sm:block">
                                            <TaskCardsList
                                                key={categoryId}
                                                title={categoryTitle}
                                                dataLength={tasks.length}
                                                controlDisplayedItemsLength
                                                id={`userTasks${categoryId}`}
                                            >
                                                {tasks.map((item) => {
                                                    return (
                                                        <TaskCardsList.TaskCard
                                                            key={item.id}
                                                            task={item}
                                                            onClick={goToTask}
                                                            onFavoriteChange={onFavoriteChange}
                                                        />
                                                    );
                                                })}
                                            </TaskCardsList>
                                        </div>
                                        {isSmall && (
                                            <div className="block sm:hidden">
                                                <TasksCardListMobile
                                                    key={categoryId}
                                                    id={`userTasks${categoryId}`}
                                                    isFinished={false}
                                                    isReview={false}
                                                    title={categoryTitle}
                                                    data={tasks}
                                                />
                                            </div>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </>
                    )}
                </>
            )}
            <div className="relative">
                <Preloader className="flex flex-col" isShow={isFetching}>
                    <div className="mb-5 w-full h-10 rounded-2xl overflow-hidden leading-0">
                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                    </div>
                    <div className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] sm:gap-6.5 2xl:gap-8">
                        {Array.from(Array(16).keys()).map((p) => {
                            return (
                                <div key={p}>
                                    <div className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                    </div>
                                    <div className="leading-5 line-clamp-2 pt-3">
                                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Preloader>
            </div>
            {!isFetching && data?.length === 0 && Object.keys(filters).length === 0 && (
                <div className="h-full flex justify-center">
                    <Empty
                        title="Все задания пройдены"
                        description="Вы прошли все задания, скоро тут появятся новые"
                        topElement={
                            <div className="flex-center mb-4 2xl:mb-5">
                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.Check}
                                        width={"48px"}
                                        height={"48px"}
                                        color={"fill-primary"}
                                        className="2xl:!w-15 2xl:!h-15"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
            {!isFetching && dataLength === 0 && (Object.keys(filters).length !== 0 || search) && (
                <div className="h-full flex justify-center">
                    <Empty
                        title="Ничего не найдено"
                        description="По заданным параметрам результатов нет"
                        topElement={
                            <div className="flex-center mb-4 2xl:mb-5">
                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.EmojiSad}
                                        width={"36px"}
                                        height={"36px"}
                                        color={"fill-primary"}
                                        className="2xl:!w-11.25 2xl:!h-11.25"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
        </>
    );
};
