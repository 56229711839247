import React from "react";
import { Input } from "Uikit";
import { numberWithSpecSymbolsRegexp } from "Uikit/Forms/Input";

interface ITaskQuestionNumberProps {
    mandatory?: boolean;
    value: string;
    name?: string;
    onChange?: (e: React.ChangeEvent<any>) => void;
    error?: string;
    placeholder?: string;
    available?: boolean;
}
const eRegexp = new RegExp(/[eе]/gi);

export const TaskQuestionNumber = ({
    mandatory = false,
    value,
    onChange,
    error,
    name,
    placeholder = "Введите число",
    available = true,
}: ITaskQuestionNumberProps) => {
    return (
        <Input
            rootClassName="!bg-transparent"
            className={`!bg-white border-0 ${!available ? "pointer-events-none" : ""}`}
            isRequired={mandatory}
            value={value}
            onChange={(e) => {
                const eMatch = RegExp(eRegexp).exec(e.target.value);
                if (eMatch && eMatch.length > 1) {
                    return;
                }
                onChange?.(e);
            }}
            placeholder={placeholder}
            error={error}
            name={name}
            maxLength={20}
            cutRegExp={numberWithSpecSymbolsRegexp}
        />
    );
};
