import React, { useState } from "react";
import clsx from "clsx";
import { Icon, Icons } from "Uikit";
import { RemoveAccessMaterialModal } from "./Modal/RemoveMaterialAccessModal";
import { MemberAccessTable } from "./MemberAccessTable";
import { useQuery } from "react-query";
import Api from "Api/index";
import { ID } from "types/ID";
import { Content, Resource } from "Api/Responses/lmsRoles/ResourceAccess";
import { Loader } from "Uikit/Loader/Loader";

interface IMemberAccessesSectionProps {
    resources: Resource[];
    resourceType: string;
    className?: string;
}

const resourceTypeString: any = {
    training: "Программы обучения",
    courses: "Курсы",
    quiz: "Тесты",
    exercises: "Задания",
    questions: "Опросы",
    news: "",
    library: "",
};

const MemberAccessesSection = ({ className, resources, resourceType }: IMemberAccessesSectionProps) => {
    const [sectionOpen, setSectionOpen] = useState(false);
    return (
        <section className="py-4 border-b border-[#EAEDF3]">
            <details className="group">
                <summary
                    // onClick={() => navigate(path ? path : "")}
                    onClick={() => setSectionOpen(!sectionOpen)}
                    className={clsx(
                        "flex items-center overflow-hidden ",
                        "text-ellipsis whitespace-nowrap rounded",
                        "transition duration-300 ease-in-out cursor-pointer",
                        "group-open:mb-4",
                        className,
                    )}
                >
                    <h3 className="font-semibold flex-grow">{resourceTypeString[resourceType]}</h3>
                    <Icon className="group-open:rotate-180" icon={Icons.ChevronDown} width={"16px"} height={"16px"} />
                </summary>
                {sectionOpen ? <MemberAccessTable data={resources} type={resourceType} /> : null}
            </details>
        </section>
    );
};

interface MemberAccessesProps {
    userId: ID;
    className?: string;
}

const resourceTypes: (keyof Content)[] = ["training", "courses", "quiz", "exercises", "questions", "news", "library"];

export const MemberAccesses = ({ className, userId }: MemberAccessesProps) => {
    const { data: access, isLoading } = useQuery("/access/user/get-for/user", () => Api.LMSRoles.getAccessFor(userId));

    const [removeAccessModalOpen, setRemoveAccessModalOpen] = useState(false);

    if (isLoading) {
        return <Loader />;
    }

    const noResources = !resourceTypes.some((resourceType) => access?.content?.[resourceType]);

    return (
        <div id="memberPageAccesses">
            {noResources ? (
                <div className="mt-40 text-center text-[#949494]">
                    <div className="rounded-full bg-blue-10 inline-block p-4">
                        <Icon icon={Icons.Pin} width={36} height={36} className="stroke-none !fill-blue" />
                    </div>
                    <br />
                    <h1 className="text-black my-4">Нет доступов</h1>
                    На пользователя ничего не назначено
                </div>
            ) : (
                <>
                    {resourceTypes.map((resourceType, index) => {
                        const resources = access?.content?.[resourceType];

                        if (!resources) {
                            return null;
                        }

                        return (
                            <MemberAccessesSection
                                key={index}
                                resources={resources}
                                resourceType={resourceType}
                                className={className}
                            />
                        );
                    })}
                </>
            )}
            <RemoveAccessMaterialModal
                id="memberPageRemoveAccessModal"
                isOpen={removeAccessModalOpen}
                setIsOpen={setRemoveAccessModalOpen}
                materialTitle="«Бухгалтерский учет и налогообложение»"
            />
        </div>
    );
};
