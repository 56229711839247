import { Input, InputProps as KitInputProps } from "./Input";
import { useField } from "formik";
import React, { InputHTMLAttributes, useContext, forwardRef, FunctionComponent } from "react";
import { FormFieldContext, useError } from "./FormField";

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, Pick<KitInputProps, "cutRegExp"> {
    as?: FunctionComponent<any>;
    isRequired?: boolean;
}

export const InputField = forwardRef(({ as: Tag = Input, isRequired, onBlur, ...rest }: InputProps, ref) => {
    const name = useContext(FormFieldContext);
    const [field] = useField(name);
    const error = useError(name);

    const handleBlur = async (e: any) => {
        field.onBlur(e);
        onBlur?.(e);
    };

    return (
        <Tag
            ref={ref}
            isRequired={isRequired}
            isError={!!error}
            {...field}
            {...rest}
            value={field.value ?? ""}
            onBlur={handleBlur}
            error={error}
        />
    );
});
