import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TeamTree, TeamTreeNode } from "Components/Common/TeamTree";
import {
    ComboBox,
    Modal,
    Icon,
    Icons,
    SecondaryButton,
    SubmitButton,
    Option,
    Toggle,
    Tooltip,
    SelectAvatarMultiValueLabel,
    SelectMultiValueRemove,
} from "Uikit";
import { ClearIndicatorProps, ValueContainerProps, components, Props } from "react-select";
import { ID, IOption } from "types";
import { getCheckedTreeNodes } from "helpers/TreeUtils";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { useQuery } from "react-query";
import Api from "Api/index";
import { numWord } from "helpers/numWord";
import { flattenTeams } from "models/Team";
import intersection from "lodash/intersection";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";

type ReactSelectArbitraryProps = Pick<Props, "optionsPlaceholder">;

interface TeamMultiSelectProps extends ReactSelectArbitraryProps {
    onChange: (teams: any[], all: boolean) => void;
    value?: ID[];
    onlyIncludes?: ID[];
    showTeams?: boolean;
    checkable?: boolean;
    selectable?: boolean;
    isMulti?: boolean;
    id?: string;
    treeHeader?: (() => ReactNode) | null;
    enableAllToggle?: boolean;
    allValue?: boolean;
    requireEachTeamChoice?: boolean;
    stacked?: boolean;
    styles?: { ValueContainer?: CSSProperties };
    isClearable?: boolean;
    allPlaceholder?: boolean;
    withAvatar?: boolean;
    sortFunction?: ((arg1: TeamTreeNode, arg2: TeamTreeNode) => number) | null;
    showTeamsCounter?: boolean;
    title?: string;
    checkOnNameClick?: boolean;
    hideMembers?: boolean;
    className?: string;
    placeholder?: string;
}

export const TeamMultiSelect = ({
    onChange,
    value = [],
    onlyIncludes,
    showTeams = true,
    selectable = false,
    checkable = true,
    isMulti = true,
    id,
    treeHeader = null,
    enableAllToggle = false,
    allValue = false,
    requireEachTeamChoice = false,
    stacked = false,
    styles,
    isClearable = false,
    allPlaceholder = false,
    withAvatar = false,
    sortFunction,
    showTeamsCounter = true,
    title = "Выбор команд",
    checkOnNameClick = true,
    optionsPlaceholder,
    hideMembers = false,
    className = "",
    placeholder = "Выберите команду",
}: TeamMultiSelectProps) => {
    const [all, setAll] = useState<boolean>(allValue ?? false);
    const treeRef = useRef<any>();

    const { data: teams = [] as TeamTreeAllBasicInfoResponse[] } = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const [treeOpen, setTreeOpen] = useState(false);
    const [currentIds, setCurrentIds] = useState<string[]>(value);
    const currentIdsRef = useRef<string[]>(value.filter((item) => !item.startsWith("root")));

    const [include, setInclude] = useState(onlyIncludes ?? []);
    const getTeamOptions = useCallback(() => {
        const getTeams = (team: TeamTreeAllBasicInfoResponse) => {
            let response: IOption[] = [{ label: team.name, value: team.id }];

            for (let i = 0; i < team.subTeams?.length; i++) {
                response = response.concat(getTeams(team.subTeams[i]));
            }

            return response;
        };

        return getTeams({ subTeams: teams } as TeamTreeAllBasicInfoResponse);
    }, [teams]);

    useEffect(() => {
        if (checkable && !value.length && currentIds.length && currentIdsRef.current.length) {
            setCurrentIds(value);
            currentIdsRef.current = value;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const toggleTree = useCallback(() => {
        setTreeOpen((prev) => !prev);
        if (!checkable && currentIds.length > 0 && value.length === 0) {
            setCurrentIds([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeOpen, value]);

    const handleSubmit = () => {
        if (selectable) {
            if (currentIds[0]) {
                onChange([currentIds[0]], all);
            }
        } else {
            onChange(
                currentIds.filter((item) => !item.startsWith("root:")),
                all,
            );
        }

        currentIdsRef.current = currentIds;
        toggleTree();
    };

    const onSelectNode = useCallback((node: any) => {
        setCurrentIds([node.id, ...(checkable ? getCheckedTreeNodes(treeRef.current.tree).map((p: any) => p.id) : [])]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectAll = useCallback(() => {
        const { tree } = treeRef.current;
        const checkedNodes = getCheckedTreeNodes(tree).map((p: any) => p.id);

        setCurrentIds(checkedNodes.length > 0 ? checkedNodes : []);
    }, []);

    const onCheckedChange = useCallback(() => {
        const checkedNodes = getCheckedTreeNodes(treeRef.current.tree).map((p: any) => p.id);
        setCurrentIds(Array.from(new Set(checkedNodes)));
    }, []);

    const ValueContainer = ({ children, ...props }: ValueContainerProps<Option>) => {
        if (showTeams) {
            return (
                <components.ValueContainer className="text-ellipsis whitespace-nowrap" {...props}>
                    {children}
                </components.ValueContainer>
            );
        }

        return (
            <div className="grow pl-4.5" onClick={toggleTree}>
                <div className="flex">
                    {showTeams ? (
                        <div className="grow">Выберите команду</div>
                    ) : (
                        <div className="text-black">Команды</div>
                    )}
                </div>
            </div>
        );
    };
    const ClearIndicator = ({ children, ...props }: ClearIndicatorProps<any>) => {
        const itemsLength = props.getValue().length;

        if (!isMulti) {
            return null;
        }

        const onClear = () => {
            props.clearValue();
            onChange([], all);
        };

        return (
            <components.ClearIndicator {...props} className={"gap-1 !pr-7 relative"}>
                {children}
                {itemsLength > 0 && (
                    <div className="absolute group right-0 flex-center rounded-full top-1/2 -translate-y-1/2 h-5.5 min-w-[22px] text-sm bg-primary text-white cursor-pointer">
                        <span className="group-hover:hidden">{itemsLength}</span>
                        <div onClick={onClear} className="hidden group-hover:block">
                            <Icon icon={Icons.Close} width={18} height={18} color="fill-white" />
                        </div>
                    </div>
                )}
            </components.ClearIndicator>
        );
    };
    const DropdownIndicator = () => {
        return (
            <div onClick={toggleTree}>
                <Icon icon={Icons.List} className="mr-3 ml-2" />
            </div>
        );
    };

    useEffect(() => setInclude(onlyIncludes ?? []), [onlyIncludes]);

    const currentTeamOptions = getTeamOptions().filter((p) => value?.indexOf(p.value) !== -1);

    const TableHeader = () => {
        return (
            <div className="flex items-center justify-between grow uppercase p4-table">
                <div className="flex items-center">
                    <div className="flex justify-center min-w-7">
                        <Icon color="fill-primary" icon={Icons.Subtract} width={14} height={14} />
                    </div>
                    <span className="text-gray-text uppercase">Команда</span>
                    <div className="ml-3">
                        <Icon color="fill-secondary" icon={Icons.Sort} width={14} height={14} />
                    </div>
                    {enableAllToggle && (
                        <>
                            <Toggle
                                enabled={all}
                                onChange={(p) => {
                                    setAll(p);
                                    onSelectAll();
                                }}
                                className="ml-4"
                                id="removeParentTeamToggle1"
                            />
                            <span className="p2 normal-case">Все</span>
                        </>
                    )}
                </div>
                {!hideMembers && (
                    <div className="flex items-center">
                        <span className="text-gray-text uppercase">Кол-во участников</span>
                        <div className="ml-3">
                            <Icon color="fill-secondary" icon={Icons.Sort} width={14} height={14} />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const disabled = useMemo(() => {
        const haveTeamWithNoChoice =
            requireEachTeamChoice &&
            teams.some((root) => {
                const rootTeamIds = flattenTeams([...root.subTeams])
                    .map((t) => t.id)
                    .concat(root.id);
                return intersection(rootTeamIds, currentIds).length === 0;
            });
        const noChoice = currentIds?.length === 0;

        return checkable ? !all && (noChoice || haveTeamWithNoChoice) : currentIds.length === 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIds, teams, requireEachTeamChoice, all]);

    let checkedTeams: string[];

    if (checkable) {
        // if (enableAllToggle) {
        //     checkedTeams = currentIds;
        // } else {
        //     checkedTeams = currentIdsRef.current;
        // }
        checkedTeams = currentIdsRef.current;
    } else if (currentIdsRef.current?.find((item) => item.includes(currentTeamOptions[0]?.value))) {
        checkedTeams = [currentIdsRef.current?.find((item) => item.includes(currentTeamOptions[0]?.value)) ?? ""];
    } else {
        checkedTeams = [];
    }

    return (
        <>
            <ComboBox
                isMulti={isMulti}
                isClearable={isClearable}
                allPlaceholder={allPlaceholder}
                optionsPlaceholder={optionsPlaceholder}
                components={{
                    ValueContainer: stacked
                        ? (props) => (
                              <StackedValueContainer
                                  {...props}
                                  style={styles?.ValueContainer}
                                  clearValue={() => {
                                      setAll(false);
                                      onChange([], false);
                                      setCurrentIds([]);
                                      currentIdsRef.current = [];
                                  }}
                                  watchVisibleElements={false}
                              />
                          )
                        : ValueContainer,
                    ClearIndicator,
                    DropdownIndicator,
                    MultiValueLabel: SelectAvatarMultiValueLabel({ withAvatar }),
                    MultiValueRemove: SelectMultiValueRemove,
                }}
                menuIsOpen={false}
                options={getTeamOptions()}
                value={currentTeamOptions}
                placeholder={placeholder}
                onMenuOpen={toggleTree}
                classNames={{ control: () => "!cursor-pointer", container: () => "!w-full" }}
                onChange={(options) => {
                    const newValue = Array.isArray(options) ? options.map((p) => p.value) : [options];
                    const rootIds = currentIds
                        .filter((item) => item?.startsWith("root:"))
                        .filter((item) => {
                            return newValue.includes(item.replace("root:", ""));
                        });
                    const newValueArr = [...rootIds, ...newValue];
                    setCurrentIds(() => newValueArr);
                    currentIdsRef.current = newValueArr;
                    setAll(false);
                    onChange(newValue, all);
                }}
                id={id}
                isCreatable={false}
                className={className}
            />
            <Modal title={title} isOpen={treeOpen} onClose={toggleTree} className="!max-w-200 z-100">
                <Modal.Body>
                    <div className="mb-8 h-100">
                        {treeOpen && (
                            <TeamTree
                                outerRef={treeRef}
                                // checkedTeams={currentIds}
                                checkedTeams={checkedTeams}
                                checkable={checkable}
                                selectable={selectable}
                                onlyIncludes={include}
                                mustExclude={[]}
                                nodeAddComponentLink=""
                                onSelectNode={onSelectNode}
                                checkedChange={checkable ? onCheckedChange : undefined}
                                // onSelectAll={checkable ? onCheckedChange : undefined}
                                onSelectAll={onSelectAll}
                                id="teamMultiSelectTree"
                                renderHeader={treeHeader}
                                renderSelectAllHead={enableAllToggle ? TableHeader : undefined}
                                disabled={all}
                                allChecked={all}
                                showSelectAllCheckBox={false}
                                sortFunction={sortFunction}
                                checkOnNameClick={checkOnNameClick}
                                hideMembers={hideMembers}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {showTeamsCounter && (
                        <div className="flex items-center w-full text-gray-text p1">
                            {`Выбрано ${currentIds.filter((item) => !item.startsWith("root"))!.length} ${numWord(
                                currentIds.filter((item) => !item.startsWith("root"))!.length,
                                ["команда", "команды", "команд"],
                            )}`}
                        </div>
                    )}
                    <SecondaryButton onClick={toggleTree} id="teamMultiSelectBtnCancel">
                        Отмена
                    </SecondaryButton>
                    {/* <SubmitButton disabled={disabled} onClick={handleSubmit} id="teamMultiSelectBtnOk">
                        Выбрать
                    </SubmitButton> */}
                    <Tooltip
                        content="Нужно сделать выбор в каждой корневой команде"
                        disabled={requireEachTeamChoice ? !disabled : true}
                    >
                        <SubmitButton disabled={disabled} onClick={handleSubmit} id="teamMultiSelectBtnOk">
                            Выбрать
                        </SubmitButton>
                    </Tooltip>
                </Modal.Footer>
            </Modal>
        </>
    );
};
