import React, { useEffect, useState } from "react";

import { Icon, Icons, flash } from "Uikit";
import { humanFileSize } from "helpers/humanFileSize";
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from "constants/attachments";
import { getFileViewType } from "helpers/file";
import { UIErrorMessages } from "Enums";
import NotificationConnect, { NotificationSubscribe, WssMessage, WssMessageType } from "Api/Wss/Notifications";
import { fetchFileInfo } from "helpers/fetchFileInfo";

interface ISelectedFile {
    type: string;
    id: string;
    size: string;
    name: string;
    extension: string;
    disposableLink: string;
}

interface IMaterialArticleAttachmentProps {
    attachment: any;
    onClick: (selectedFile: ISelectedFile) => void;
    removeFile: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, attachment: any) => void;
}

const hostname = window.location.hostname.includes("localhost")
    ? "http://localhost:3000"
    : window.location.protocol + "//" + window.location.hostname;

const onError = () => {
    flash.error(UIErrorMessages.FILE_LOADING_ERROR);
};

export const MaterialArticleAttachment = ({ attachment, onClick, removeFile }: IMaterialArticleAttachmentProps) => {
    const [isVideoProcessed, setIsVideoProcessed] = useState(false);
    const id = attachment?.serverData?.id ?? attachment?.file?.id ?? attachment?.id;
    const name = attachment?.name ?? attachment?.file?.name ?? attachment?.serverData?.name;
    const disposableLink = attachment?.disposableLink ?? attachment?.serverData?.disposableLink;
    const fileType = attachment?.contentType ?? attachment?.file?.type ?? attachment?.serverData?.contentType;
    const extension = attachment?.extension ?? attachment?.file?.extension ?? attachment?.serverData?.extension;
    const isVideo = (attachment?.file?.type || attachment?.contentType)?.includes("video");

    useEffect(() => {
        if (attachment?.id && attachment?.disposableLink && isVideo) {
            fetchFileInfo(attachment?.id, setIsVideoProcessed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachment]);

    useEffect(() => {
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.messageType === WssMessageType.NOTIFICATION) {
                const { actionType } = message.body;
                if (actionType === "PROCESSING_COMPLETE") {
                    setIsVideoProcessed(true);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="flex p-4 border border-gray-blue rounded-lg cursor-pointer group mb-4 last-of-type:mb-0 relative"
            onClick={() => {
                onClick({
                    type: fileType,
                    id: id,
                    size: attachment.size,
                    name,
                    extension,
                    disposableLink,
                });
            }}
        >
            <div className="flex items-center mr-auto">
                <Icon
                    className="mr-5"
                    icon={FILES_TYPE_ICONS[extension] || Icons.FileOther}
                    width="32px"
                    height="32px"
                />
                <div>
                    <div className="flex items-center pb-2 group-hover:text-blue-light">
                        {((isVideo && !isVideoProcessed) || !attachment.disposableLink) && (
                            <img className="mr-1" src={"/icons/loading.svg"} width={16} height={16} alt="" />
                        )}
                        {name}
                    </div>

                    {((!isVideo && !attachment.status) || (isVideo && isVideoProcessed)) && (
                        <div className="text-gray-text">
                            {FILES_TYPE_SHORT_NAME[extension]} - {humanFileSize(attachment.size, true)}
                        </div>
                    )}

                    <div className="text-gray-text">
                        {!isVideo ? (
                            <>
                                {attachment.status === "loading" && (
                                    <>
                                        {humanFileSize(attachment.uploaded, true) +
                                            " / " +
                                            humanFileSize(attachment.size, true) +
                                            " – " +
                                            attachment.timeLeft}
                                    </>
                                )}
                            </>
                        ) : (
                            <>{!isVideoProcessed && "Видео обрабатывается"}</>
                        )}
                    </div>

                    {attachment.status === "done" && (
                        <div className="text-gray-text">{humanFileSize(attachment.size, true)} – Успешно загружено</div>
                    )}
                </div>
            </div>
            {attachment.status !== "loading" && (
                <>
                    <div className="flex space-x-3">
                        <a
                            href={`${hostname}/service/lms-upload/api/file/download/${id}`}
                            download={name}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Icon icon={Icons.Download} width={20} height={20} color="fill-blue-drk" />
                        </a>
                        <div onClick={(e) => removeFile(e, attachment)}>
                            <Icon icon={Icons.Delete} width={20} height={20} color="fill-blue-drk" />
                        </div>
                    </div>
                    {getFileViewType({ type: attachment.contentType, extension: attachment.extension }) === "IMAGE" && (
                        <img
                            src={`/service/lms-upload/api/file/download/${attachment.id}`}
                            width="0"
                            height="0"
                            className="hidden"
                            onError={() => {
                                onError();
                                removeFile(undefined, attachment);
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};
