import React, { useRef, useState } from "react";
import { Icon, Icons } from "Uikit";
import { TUserAttachments } from "Api/Requests/TaskPublicRequest";
import { humanFileSize } from "helpers/humanFileSize";
import Api from "Api";
import { FILE_INPUT_ACCEPTED_TYPES } from "constants/attachments";

interface ITaskQuestionImageProps {
    value: TUserAttachments[];
    onChange: (event: any) => void;
    onDelete: (index: number) => void;
    accept: string;
    onAttachmentClick: ({
        type,
        id,
        size,
        name,
        extension,
        disposableLink,
    }: {
        type: string;
        id: string;
        size: string;
        name: string;
        extension: string;
        disposableLink: string;
    }) => void;
}

export const TaskQuestionImage = ({
    value,
    onDelete,
    onChange,
    accept,
    onAttachmentClick,
}: ITaskQuestionImageProps) => {
    const valueRef = useRef(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [base64Content, setBase64Content] = useState<string[]>([]);
    let counter = 0;

    return (
        <>
            {(!value || value?.length <= 9) && (
                <div className="relative flex items-center w-full px-4 2xl:px-5 py-4.5 2xl:py-5.5 rounded-lg 2xl:rounded-2lg bg-white text-blue gap-2 2xl:gap-2.5">
                    <Icon
                        icon={Icons.Camera}
                        width="20px"
                        height="20px"
                        color="fill-blue"
                        className="2xl:!w-6.25 2xl:!h-6.25"
                    />
                    <span className="2xl:text-md">Прикрепить изображение</span>
                    <input
                        type="file"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                        }}
                        onChange={onChange}
                        accept={accept}
                    />
                </div>
            )}
            {value?.length > 0 && (
                <div className="mt-6 2xl:mt-7 flex flex-wrap sm:grid sm:grid-cols-[repeat(3,180px)] 2xl:grid-cols-[repeat(3,225px)] gap-1 sm:gap-4 2xl:gap-4.5">
                    {value.map(({ id, size, type, name, base64 }, index) => {
                        if (!base64 && valueRef.current === 0) {
                            valueRef.current = value.length;
                            value.forEach(({ name }) => {
                                if (counter < valueRef.current) {
                                    Api.File.DownloadFile(id as string).then((blob) => {
                                        const file = new File([blob], name ?? "");
                                        const reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        reader.onload = () => {
                                            setBase64Content((prevContent) => {
                                                return [...prevContent, reader.result!.toString()];
                                            });
                                        };
                                        counter++;
                                    });
                                }
                            });
                        }

                        return (
                            <div
                                key={id ?? base64}
                                className="w-[calc(33vw-22px)] sm:w-45 h-[calc(33vw-22px)] sm:h-45 2xl:w-[225px] 2xl:h-[225px] relative group cursor-pointer"
                                onClick={() => {
                                    onAttachmentClick({
                                        type: type as string,
                                        id: id as string,
                                        size: size as unknown as string,
                                        name: name as string,
                                        extension: FILE_INPUT_ACCEPTED_TYPES[type as string],
                                        disposableLink: `/service/lms-upload/api/file/download/${id}`,
                                    });
                                }}
                            >
                                {type && size && (
                                    <>
                                        <div className="absolute z-10 bottom-1 sm:bottom-3 2xl:bottom-3.75 right-1 sm:right-3 2xl:right-3.75 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 bg-black-50 rounded-md 2xl:rounded-lg text-white h-5.25 2xl:h-6.5">
                                            <p className="p4 sm:p3">
                                                {`${type?.replace("image/", "")} · ${humanFileSize(size, true)}`}
                                            </p>
                                        </div>
                                        <div
                                            className="absolute top-1.5 sm:top-3 2xl:top-3.75 right-1.5 sm:right-3 2xl:right-3.75 flex justify-center items-center w-3 sm:w-6 2xl:w-7.5 h-3 sm:h-6 2xl:h-7.5 bg-white rounded-full cursor-pointer
                                            opacity-50 sm:opacity-0 group-hover:opacity-50 group-hover:hover:opacity-100 group-hover:hover:bg-blue group-hover:hover:[&>*]:fill-white"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDelete(index);
                                            }}
                                        >
                                            <Icon
                                                className="p-1/2 2xl:!w-6.25 2xl:!h-6.25"
                                                icon={Icons.Close}
                                                width={20}
                                                height={20}
                                                color="fill-gray"
                                            />
                                        </div>
                                    </>
                                )}
                                <img
                                    className="w-full h-full rounded-2lg 2xl:rounded-xl object-cover"
                                    src={
                                        base64 || base64Content[index]
                                            ? base64 ?? base64Content[index]
                                            : id
                                            ? `/service/lms-upload/api/file/download/${id}`
                                            : ""
                                    }
                                    alt={name}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
