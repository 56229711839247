import clsx from "clsx";
import { FormGroup, Label, Tooltip } from "../index";
import { useField, useFormikContext } from "formik";
import { isString } from "lodash";
import React, { createContext, ReactNode } from "react";
import { Placement } from "tippy.js";

export const FormFieldContext = createContext<string>("");

interface FormFieldProps {
    name: string;
    label?: string | ReactNode;
    className?: string;
    children: ReactNode;
    tooltip?: ReactNode;
    placement?: Placement;
    isRequired?: boolean;
}

export const FormField = ({
    name,
    label = " ",
    className,
    children,
    tooltip,
    placement = "auto",
    isRequired,
}: FormFieldProps) => {
    const hasLabel = (isString(label) && label.trim().length > 0) || (label && !isString(label));
    const displayLabel = hasLabel ? label : " ";

    return (
        <FormGroup className={className}>
            {hasLabel && (
                <Label className={clsx("whitespace-nowrap")} isRequired={isRequired}>
                    {displayLabel}
                    {tooltip && (
                        <Tooltip placement={placement} content={tooltip}>
                            <span>*</span>
                        </Tooltip>
                    )}
                </Label>
            )}

            <FormFieldContext.Provider value={name}>{children}</FormFieldContext.Provider>
        </FormGroup>
    );
};

export const useError = (name: string, prevalidate?: boolean) => {
    const [, meta] = useField(name);
    const formik = useFormikContext();

    if (formik.submitCount > 0 || prevalidate) {
        return meta.error ?? null;
    }

    return null;
};
