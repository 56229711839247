import { SortingState } from "@tanstack/react-table";

const range = (len: number) => {
    const arr = [];
    for (let i = 0; i < len; i++) {
        arr.push(i);
    }
    return arr;
};

export function makeData<T>(el: () => T, ...lens: number[]) {
    const makeDataLevel = (depth = 0): T[] => {
        const len = lens[depth]!;
        return range(len).map((_d): T => {
            return {
                ...el(),
                subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
            };
        });
    };

    return makeDataLevel();
}

export function makeDataWithSorting<T>(el: () => T, sorting?: SortingState, ...lens: number[]) {
    const fieldSort = sorting?.at(0);

    const compareFn = (a: any, b: any) => {
        if (!fieldSort) return 0;
        const { id, desc } = fieldSort;
        if (a[id] < b[id]) {
            return desc ? 1 : -1;
        } else if (a[id] > b[id]) {
            return desc ? -1 : 1;
        }
        return 0;
    };

    const makeDataLevel = (depth = 0): T[] => {
        const len = lens[depth]!;
        const data = range(len).map(
            (_d): T => ({
                ...el(),
                subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
            }),
        );

        return fieldSort ? data.sort(compareFn) : data;
    };

    return makeDataLevel();
}
export async function fetchData<T>(data: T[], options: { pageIndex: number; pageSize: number }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    return {
        rows: data.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(data.length / options.pageSize),
    };
}
