import React, { useState, useEffect, useRef } from "react";
import { FileUpload, Icon, Icons, Tooltip, flash } from "Uikit";
import { Section } from "Uikit/Forms/Section";
import { VideoPlayer } from "Components/VideoPlayer";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { FileReadResponse } from "Api/Responses/FileResponse";
import { MaterialFile } from "Api/Responses/MaterialResponse";
import { useVideoPlayer } from "hooks/useVideoPlayer";
import { useUpload } from "hooks/useUpload";
import { FileUploadType, UIErrorMessages } from "Enums";
import NotificationConnect, { NotificationSubscribe, WssMessage, WssMessageType } from "Api/Wss/Notifications";
import { fetchFileInfo } from "helpers/fetchFileInfo";

interface MaterialVideoProps {
    attachment?: MaterialFile;
    videoUrl?: string;
    isAllowRewind?: boolean;
    onChange: (field: string, value: any) => void;
    onVideoDurationChange: (duration: number) => void;
    setIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
}

export const MaterialVideo = ({
    attachment,
    videoUrl,
    onChange,
    onVideoDurationChange,
    setIsSubmitDisabled,
}: MaterialVideoProps) => {
    // const playerRef = useRef<any>();
    const { upload } = useUpload();
    const [file, setFile] = useState<any>();
    const [fileUploadData, setFileUploadData] = useState<any>();
    const [isVideoFileProcessed, setIsVideoFileProcessed] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const attachmentRef = useRef<MaterialFile | null | undefined>(attachment);
    const showPlayerDelay = attachmentRef.current ? 0 : 1000;
    const { duration, getPlayerProps } = useVideoPlayer({
        fluid: true,
        controls: true,
        rewind: true,
        src: videoUrl ?? null,
    });

    const hostname = window.location.hostname.includes("localhost")
        ? "https://lms-prod-api.gran-soft.ru"
        : window.location.protocol + "//" + window.location.hostname;

    function uploadFile(file: any) {
        if (!file) {
            return;
        }

        onChange?.("fileId", null);
        onChange?.("file", null);
        setIsSubmitDisabled(true);

        const onUpdate = async (file: any) => setFileUploadData(file);

        const onError = ({ errorCode }: BadRequestResponse) => {
            if (errorCode === ErrorCode.FILE_CONTENT_TYPE_ERROR) {
                onChange?.(
                    "fileId",
                    attachmentRef.current ? (attachmentRef?.current as unknown as FileReadResponse)?.id : null,
                );
                onChange?.("file", attachmentRef.current ? attachmentRef.current : null);
                flash.error(UIErrorMessages.FILE_LOADING_ERROR);
                setFile(null);
                setFileUploadData(null);
                return;
            }
        };
        const onFinished = async (file: any) => {
            onChange?.("fileId", file.serverData.id);
            onChange?.("file", file.serverData);
            attachmentRef.current = file.serverData;
            setFile(file.serverData);
            setIsSubmitDisabled(false);
        };

        const extensionIdx = file.name.lastIndexOf(".");
        file["extension"] = file.name.slice(extensionIdx + 1);

        const u = upload({
            file: file,
            onUpdate,
            onFinished,
            onError,
            fileUploadType: FileUploadType.MATERIAL_VIDEO,
        });

        // u.start()
        // .then();
        const start = u.start();
        start.catch((error) => {
            const knownError = error as BadRequestResponse;
            if (
                [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                    String(knownError.errorCode) as ErrorCode,
                )
            ) {
                flash.error(UIErrorMessages.FILE_LOADING_ERROR);
            }
            setFile(null);
            return;
        });

        setFile(u);
    }

    function cancelUpload() {
        file?.cancel();
        setFile(attachment);
    }

    useEffect(() => {
        if (duration) {
            onVideoDurationChange(Math.ceil(duration / 60));
        }
    }, [duration, onVideoDurationChange]);

    // Флаг готовности устанавливается по таймауту на случай, если уведомление придёт раньше загрузки видео в lms-upload
    // Не смотря на то, что сейчас такая возможность на бэкенде уже исключена
    if (!isInitialized) {
        setIsVideoFileProcessed(false);
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.messageType === WssMessageType.NOTIFICATION) {
                const { actionType } = message.body;
                if (actionType === "PROCESSING_COMPLETE") {
                    setTimeout(() => {
                        setIsVideoFileProcessed(true);
                    }, 1000);
                }
            }
        });
        setIsInitialized(true);
    }

    useEffect(() => {
        setIsVideoFileProcessed(false);
        if (attachment?.id && attachment?.disposableLink && videoUrl) {
            fetchFileInfo(attachment.id, (isProcessed) => {
                setTimeout(() => {
                    setIsVideoFileProcessed(isProcessed);
                }, showPlayerDelay);
            });
        }
    }, [attachment, videoUrl, showPlayerDelay]);

    return (
        <Section isRequired label="Видео">
            {(!videoUrl || (videoUrl && !isVideoFileProcessed)) && (
                <FileUpload
                    attachment={fileUploadData || attachment}
                    onChange={uploadFile}
                    onCancelUpload={cancelUpload}
                    accept={{ "video/mp4": [] }}
                    maxSize={2147483648}
                    acceptDescription="MP4 до 2 ГБ"
                    isVideoProcessed={isVideoFileProcessed}
                />
            )}
            {!!videoUrl && attachment?.id && attachment?.disposableLink && isVideoFileProcessed && (
                <div
                    className="relative w-full h-full group flex justify-center items-center ml-auto rounded-lg"
                    id="adminNewMaterialVideoContent"
                >
                    <VideoPlayer
                        {...getPlayerProps()}
                        onError={() => {
                            onChange?.("fileId", null);
                            onChange?.("file", null);
                            setFile(null);
                        }}
                    />
                    <div className="group-hover:opacity-100 opacity-0 absolute z-10 flex top-5 right-5 space-x-1">
                        <Tooltip content="Скачать">
                            <a
                                href={hostname + "/service/lms-upload/api/file/download/" + attachment?.id}
                                download={attachment?.name ?? "video.mp4"}
                                className="flex items-center justify-center w-8 h-8 rounded-lg bg-black cursor-pointer"
                                id="adminNewMaterialVideoLinkDownload"
                            >
                                <Icon icon={Icons.Download} width="20px" height="20px" color="fill-white" />
                            </a>
                        </Tooltip>
                        {/* Временно закомментировано, тю.к. на данный момент фунционал не реализовывается (v.1.1) */}
                        {/* <Tooltip content="Загрузить">
                            <div className="relative flex items-center justify-center w-8 h-8 rounded-lg bg-black cursor-pointer">
                                <input
                                    className="absolute top-0 left-0 right-0 bottom-0 opacity-0"
                                    type="file"
                                    accept="video/mp4"
                                    onChange={async (e) => {
                                        uploadFile(e.target.files?.[0]);
                                        e.target.files = null;
                                        e.target.value = "";
                                    }}
                                    id="adminNewMaterialVideoInputDownload"
                                />
                                <Icon icon={Icons.Upload} width="20px" height="20px" color="fill-white" />
                            </div>
                        </Tooltip> */}
                    </div>
                </div>
            )}
        </Section>
    );
};

// Сохранённый элемент вёрстки. Пока не используется.
// eslint-disable-next-line
// const _PlayerBackground = () => {
//     return (
//         <div className="group relative w-full rounded-lg bg-gray-dark">
//             <div className="group-hover:opacity-100 opacity-0 absolute z-10 flex top-5 right-5 space-x-1">
//                 {[Icons.Download, Icons.Upload, Icons.Delete].map((icon, idx) => {
//                     return (
//                         <div
//                             key={idx}
//                             className="flex items-center justify-center w-8 h-8 rounded-lg bg-black cursor-pointer"
//                         >
//                             <Icon icon={icon} width="20px" height="20px" color="fill-white" />
//                         </div>
//                     );
//                 })}
//             </div>

//             {/*    Плеер здесь */}
//         </div>
//     );
// };
