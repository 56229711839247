import { BaseRequest } from "../BaseRequest";
import { ID } from "types/ID";
import { ResourceType } from "Enums";

export class TeamAccessRequest extends BaseRequest {
    public resourceId: ID;
    public resourceType: string;
    public teams: ID[];
    public allTeams: boolean;
}

export class UserAccessRequest extends BaseRequest {
    public resourceId: ID;
    public resourceType: ResourceType;
    public users: ID[];
}
