import { useCallback, useEffect, useRef } from "react";

export const useRequestAnimationFrame = (callback: (time: number) => void) => {
    const requestRef = useRef<number>();
    const previousTimeRef = useRef<number>();

    const animate = useCallback(
        (time: number) => {
            if (previousTimeRef.current) callback(time - previousTimeRef.current);
            previousTimeRef.current = time;
            requestRef.current = requestAnimationFrame(animate);
        },
        [callback],
    );

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);

        if (requestRef.current) {
            return () => cancelAnimationFrame(requestRef.current ?? 0);
        }
    }, [animate]);
};
