import React, { useRef, useState } from "react";
import axios from "axios";
import { Transition } from "react-transition-group";
import { Button, Icon, Icons, RadioGroup } from "Uikit";
import { LoadingModal } from "../../Statistics/Modal/LoadingModal";
import Api from "Api";

const duration = 150;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
    opacity: 0,
};

const transitionStyles = {
    entering: { opacity: 1, transform: "translateX(0)" },
    entered: { opacity: 1, transform: "translateX(0)" },
    exiting: { opacity: 0, transform: "translateX(100%)" },
    exited: { opacity: 0, transform: "translateX(100%)" },
    unmounted: { opacity: 0, transform: "translateX(100%)" },
};

interface IExportModal {
    isOpen: boolean;
    setIsOpen: (show: boolean) => void;
    filters: { [id: string]: any };
}
export const ExportModal = ({ isOpen, setIsOpen, filters }: IExportModal) => {
    const nodeRef = useRef(null);

    const [isExportAll, setIsExportAll] = useState(true);
    const [isLoadingModal, setIsLoadingModal] = useState(false);

    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();

    const onClose = () => {
        setIsExportAll(true);
        setIsOpen(false);
    };
    const onExport = async () => {
        setIsLoadingModal(true);
        onClose();

        const filter: { [id: string]: any } = {};

        if (!isExportAll) {
            const filtersKeys = Object.keys(filters);

            for (const element of filtersKeys) {
                if (element === "jobTitle.in" || element === "office.in") {
                    filter[element] = filters[element].map((p: any) => p.value).join(",");
                } else if (element === "teamId.in") {
                    filter[element] = filters[element].join(",");
                } else if (element === "role.in") {
                    if (filters[element].value !== "ALL") {
                        filter[element] = filters[element].value;
                    }
                } else {
                    filter[element] = filters[element];
                }
            }
        }

        const exportResponse = await Api.User.Export(filter, cancelTokenSource.token);

        const link = document.createElement("a");
        const blob = new Blob([exportResponse.data], { type: "application/octet-stream" });

        link.href = URL.createObjectURL(blob);
        link.download = decodeURIComponent(
            exportResponse.headers["content-disposition"]
                .substring(exportResponse.headers["content-disposition"].indexOf("filename*=UTF-8''"))
                .replace("filename*=UTF-8''", ""),
        );

        link.click();
        setIsLoadingModal(false);
    };

    return (
        <>
            <LoadingModal
                isOpen={isLoadingModal}
                onClose={() => setIsLoadingModal(false)}
                onCancel={() => cancelTokenSource.cancel()}
            />
            {isOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-[rgba(26,26,26,0.1)] z-[100]"
                    onClick={onClose}
                ></div>
            )}
            <Transition nodeRef={nodeRef} in={isOpen} timeout={duration}>
                {(state) => (
                    <div
                        className="fixed top-0 right-0 flex flex-col w-full sm:w-105 h-full bg-white shadow-[0px_8px_20px_rgba(0,0,0,0.12)] rounded-tl-md rounded-bl-md z-[101] isolate"
                        onClick={(e) => e.stopPropagation()}
                        id="filterWindow"
                        ref={nodeRef}
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                    >
                        <div className="flex items-center justify-between px-3 sm:px-6 pt-1.5 sm:pt-6 pb-1.5 sm:pb-2">
                            <p className="hidden sm:block text-[#262626] text-xl font-medium">Настройка экспорта</p>
                            <Button
                                shape="round"
                                color="common"
                                icon={<Icon icon={Icons.Close} width={32} height={32} color="fill-[#878E9C]" />}
                                iconPlacement="center"
                                onClick={onClose}
                            />
                        </div>
                        <div className="flex flex-col grow py-5 sm:py-6 px-3 sm:px-6 overflow-auto">
                            <RadioGroup
                                value={isExportAll ? "isExportAll" : "isExportFiltered"}
                                onChange={(value: any) => {
                                    setIsExportAll(value === "isExportAll");
                                }}
                            >
                                <RadioGroup.Button
                                    className="!text-base"
                                    key="isExportAll"
                                    label="Экспорт всех участников системы"
                                    value="isExportAll"
                                />
                                <RadioGroup.Button
                                    className="!text-base"
                                    key="isExportFiltered"
                                    label="Экспорт отфильтрованных участников"
                                    value="isExportFiltered"
                                />
                            </RadioGroup>
                        </div>
                        <div className="border-t border-[#EAEDF3] px-3 sm:px-6 py-2.5 sm:py-4">
                            <Button className="w-full" color="gray" onClick={onExport}>
                                Экспортировать
                            </Button>
                        </div>
                    </div>
                )}
            </Transition>
        </>
    );
};
