import React, { ReactNode } from "react";
import clsx from "clsx";
import { Icon, Icons } from "Uikit";
import { Link } from "./Link";

interface IBreadcrumbs {
    id?: string;
    className?: string;
    children: ReactNode;
}
export const Breadcrumbs = ({ id, className, children }: IBreadcrumbs) => {
    const content = Array.isArray(children) ? children : [children];

    return (
        <nav id={id} className={clsx("bg-grey-light rounded-md w-full pt-0.5 pb-2.5 2xl:pb-3 break-all", className)}>
            <div>
                {React.Children.map(
                    content.filter((child) => child),
                    (child, index) => {
                        if (!child) {
                            return null;
                        }

                        return (
                            <span
                                className={clsx(
                                    "text-blue-drk text-xs 2xl:text-sm",
                                    content.length > 1 && "last:text-black last:pointer-events-none",
                                )}
                            >
                                {child}
                                {index < content.filter((child) => child).length - 1 && (
                                    <Icon
                                        className="inline-block my-auto mx-2"
                                        icon={Icons.ChevronRight}
                                        width={8}
                                        height={8}
                                        color="fill-blue-drk"
                                    />
                                )}
                            </span>
                        );
                    },
                )}
            </div>
        </nav>
    );
};

Breadcrumbs.Link = Link;
