import { CancelToken } from "axios";
import BaseApi from "../BaseApi";
import { BaseRequest } from "../BaseRequest";
import { GeneratePreviewRequest } from "../Requests/PreviewRequest";
import { GeneratePreviewResponse, GetPreviewResponse } from "../Responses/PreviewResponse";

export default class PreviewApi extends BaseApi {
    generateFilePreview = async (data: GeneratePreviewRequest): Promise<GeneratePreviewResponse> => {
        return await this.Post(GeneratePreviewResponse, "/generate", data);
    };

    getFilePreview = async (fileId: string, cancelToken?: CancelToken): Promise<GetPreviewResponse> => {
        return await this.Get(GetPreviewResponse, `/get/${fileId}`, new BaseRequest(), "json", {}, cancelToken);
    };
}
