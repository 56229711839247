import React, { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

interface ItemProps {
    className?: string;
    title: string;
    path: string;
    content?: ReactNode;
}

export const Item = ({ className, title, path }: ItemProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActiveItem =
        location["pathname"] === path ||
        (location["pathname"] !== "/" &&
            path !== "/" &&
            location["pathname"] !== "/admin" &&
            path !== "/admin" &&
            location.pathname.indexOf(path) !== -1);

    return (
        <li>
            <div
                className={clsx(
                    isActiveItem ? "is-active" : "",
                    className,
                    "group flex items-center text-base pb-5 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer",
                )}
                onClick={() => navigate(path || "")}
            >
                <span className="group-hover:text-primary group-[.is-active]:text-primary text-gray-dark p1 transition-all ease-in-out duration-300">
                    {title}
                </span>
            </div>
        </li>
    );
};
