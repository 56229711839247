import tail from "lodash/tail";

const innerFlattenDeep = <T>(collection: T[], f: (element: T) => T[], acc: T[]): T[] => {
    if (collection.length === 0) {
        return acc;
    }
    const head = collection[0];

    return innerFlattenDeep(f(head).concat(tail(collection)), f, [head, ...acc]);
};

export const flattenDeep = <T>(collection: T[], f: (element: T) => T[]) => {
    return innerFlattenDeep(collection, f, []);
};
