import { SystemPropertyResponse } from "Api/Responses/CommonsResponses";

export const GetSystemProperty = (
    property: string,
    defaultValue: string,
    systemProperties?: SystemPropertyResponse[],
): string => {
    if (!systemProperties) {
        return defaultValue;
    }

    const data = systemProperties.filter((p) => p.systemProperty === property);

    if (data.length === 0) {
        return defaultValue;
    }

    return data[0].value;
};
