import React, { useState } from "react";
import { Button, Icon, Icons, PopoverList } from "Uikit";
import { CurrentUserResponse } from "Api/Responses/UserResponse";
import { AchievementChoiceModal } from "./Achievement/AchievementChoiceModal";
import { AchievementResponse } from "Api/Responses/AchievementResponse";
import { AchievementApplyModal } from "Components/Achievement/AchievementApplyModal";
import { AchievementIcons } from "Components/Achievement/AchievementIcons";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import clsx from "clsx";
import { MenuItem } from "Containers/Menu/Menu";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "typescript-cookie";
import { useMutation, useQuery } from "react-query";
import Api from "Api/index";
import { AchievementsOwnModal } from "Components/AchievementsOwnModal";
import { useScreenSize } from "hooks/useMediaQuery";
import { TScreenSize } from "types/Screen";

const mobileMenu: MenuItem[] = [
    {
        Id: "profileMenuStatisticsLink",
        Title: "Статистика",
        Icon: Icons.BarChart,
        Path: "/personal/statistics",
        ActivePaths: [],
        Count: 0,
        IsSeparator: false,
        IsBottom: false,
    },
    {
        Id: "profileMenuProgressLink",
        Title: "Прогресс",
        Icon: Icons.BarChartSquare,
        Path: "/personal/progress",
        ActivePaths: [],
        Count: 0,
        IsSeparator: false,
        IsBottom: false,
    },
    {
        Id: "profileMenuLogoutLink",
        Title: "Выход",
        Icon: Icons.AdminMenuIconLogout,
        Path: "/personal/logout",
        ActivePaths: [],
        Count: 0,
        IsSeparator: true,
        IsBottom: false,
        Fill: "fill-red",
    },
];

interface IProfileBannerAvatarProps {
    user: CurrentUserResponse;
    size?: TScreenSize;
}

const ProfileBannerAvatar = ({ user, size = "small" }: IProfileBannerAvatarProps) => {
    const { avatarId, defaultAvatarColor, firstName, lastName } = user;
    const classNames = {
        large: "bottom-6 left-7.5",
        medium: "bottom-6 left-6",
        small: "top-10",
    };

    const sizes = {
        large: 224,
        medium: 180,
        small: 120,
    };

    return (
        <UserAvatar
            className={`absolute ring-white ring-4 rounded-full ${classNames[size]}`}
            avatarId={avatarId}
            color={defaultAvatarColor!}
            userInitials={`${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`}
            size={sizes[size]}
        />
    );
};

interface IProfileNameProps {
    content: string;
    size?: TScreenSize;
}

const ProfileName = ({ content, size = "small" }: IProfileNameProps) => {
    const classNames = {
        large: "mt-7 text-4xl",
        medium: "mt-5.5",
        small: "",
    };
    const className = `line-clamp-1 ${classNames[size]}`;

    return size === "large" ? (
        <h1 className={className}>{content}</h1>
    ) : size === "medium" ? (
        <h1 className={className}>{content}</h1>
    ) : (
        <h3 className={className}>{content}</h3>
    );
};

interface IProfileRatingProps {
    totalRank: number;
    rating: number;
    size?: TScreenSize;
}

const ProfileRating = ({ totalRank, rating, size = "small" }: IProfileRatingProps) => {
    const headerClasses = {
        large: "text-[28px]",
        medium: "text-xl",
        small: "text-base",
    };
    const sizes = {
        large: 30,
        medium: 24,
        small: 20,
    };

    return (
        <>
            <div className="flex flex-col items-center sm:items-start">
                <span className={`mb-0.5 sm:mb-0 text-blue-drk ${size === "large" ? "p2" : "p4"}`}>
                    В общем рейтинге
                </span>
                <div className="flex space-x-2 items-center">
                    <Icon icon={Icons.Bar} width={sizes[size]} height={sizes[size]} color="fill-blue-drk" />
                    <p className={`text-blue-drk ${headerClasses[size]}`}>{totalRank}</p>
                </div>
            </div>
            <div className="flex flex-col items-center sm:items-start">
                <span className={`mb-0.5 sm:mb-0 text-blue-drk ${size === "large" ? "p2" : "p4"}`}>Кол-во баллов</span>
                <div className="flex space-x-2 items-center">
                    <Icon icon={Icons.Trophy} width={sizes[size]} height={sizes[size]} color="fill-blue-drk" />
                    <p className={`text-blue-drk ${headerClasses[size]}`}>{rating}</p>
                </div>
            </div>
        </>
    );
};

interface IProfileBanner {
    user: CurrentUserResponse;
    isCurrent: boolean;
}
export const ProfileBanner = ({ user, isCurrent }: IProfileBanner) => {
    const navigate = useNavigate();
    const { mutateAsync: logout } = useMutation(() => Api.Auth.Logout());

    const [achievementChoiceModalOpen, setAchievementChoiceModalOpen] = useState(false);
    const [currentAchievement, setCurrentAchievement] = useState<AchievementResponse | null>(null);

    const [showAchievementsModal, setShowAchievementsModal] = useState(false);
    const { data: visitor } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());

    const handleAchievement = () => {
        setAchievementChoiceModalOpen(true);
    };

    const handleEdit = () => {
        navigate("/personal/edit");
    };

    const handleAchievementChosen = (achievement: AchievementResponse) => {
        setAchievementChoiceModalOpen(false);
        setCurrentAchievement(achievement);
    };

    const onLogout = async () => {
        await logout();

        removeCookie("USER_SESSION", { path: "/", domain: window.location.hostname });
        document.location.reload();
    };

    const toggleAchievementsModal = () => {
        setShowAchievementsModal(!showAchievementsModal);
    };

    const isVisitorMentor = visitor?.isMentorOf(user);
    const { size } = useScreenSize();
    const isLarge = size === "large";
    const isMedium = size === "medium";

    return (
        <div>
            <div
                className={clsx(
                    "relative flex flex-col h-86.25 bg-gradient-to-r from-[#AAD8F9] to-[#0084E2] justify-end",
                    "sm:p-1 sm:h-61 2xl:h-[305px] sm:bg-gradient-to-l sm:rounded-3xl 2xl-",
                )}
            >
                <AchievementChoiceModal
                    isOpen={achievementChoiceModalOpen}
                    onClose={() => setAchievementChoiceModalOpen(false)}
                    onChange={handleAchievementChosen}
                    except={user.achievements}
                />
                <AchievementApplyModal
                    userId={user.id}
                    achievement={currentAchievement}
                    isOpen={!!currentAchievement}
                    onClose={() => setCurrentAchievement(null)}
                    onBack={() => {
                        setCurrentAchievement(null);
                        setAchievementChoiceModalOpen(true);
                    }}
                />
                <div
                    className={clsx(
                        "flex flex-col items-center h-61.25 rounded-t-4xl bg-white ring-gray-blue ring-1",
                        "sm:relative sm:flex-row sm:items-start sm:h-36 sm:rounded-2.5xl",
                        "2xl:h-45",
                        isMedium && !isLarge && "pl-56",
                        isLarge && "pl-[280px]",
                    )}
                >
                    <ProfileBannerAvatar user={user} size={size} />
                    <div className="flex flex-col items-center sm:items-start mt-18 sm:mt-0">
                        <ProfileName content={`${user.firstName} ${user.lastName}`} size={size} />
                        <div className="p4 sm:p2 2xl:text-md text-gray">{user.jobTitle?.name}</div>
                        {(isLarge || isMedium) && (
                            <AchievementIcons
                                achievements={user.achievements}
                                limit={8}
                                onClick={
                                    isLarge
                                        ? toggleAchievementsModal
                                        : isMedium
                                        ? toggleAchievementsModal
                                        : () => navigate(`/personal${!isCurrent ? "/" + user.id : ""}/achievements`)
                                }
                                size={size}
                            />
                        )}
                    </div>
                    <div className="flex grow justify-center self-center text-gray p4 space-x-10 mx-10">
                        <ProfileRating rating={user.rating} totalRank={user.totalRank} size={size} />
                    </div>
                    {(isLarge || isMedium) && (
                        <div className="flex space-x-2 self-center mr-10">
                            {/*{!isCurrent && (*/}
                            {/*    <Button*/}
                            {/*        icon={<Icon icon={Icons.MessageTyping} width={isLarge ? 25 : 20} height={isLarge ? 25 : 20} color="fill-white" />}*/}
                            {/*        iconPlacement="left"*/}
                            {/*    >*/}
                            {/*        <span className={`${isLarge && "text-[17px]"}`}>Написать</span>*/}
                            {/*    </Button>*/}
                            {/*)}*/}

                            {isCurrent && (
                                <Button
                                    iconPlacement="center"
                                    icon={
                                        <Icon
                                            icon={Icons.Pencil}
                                            width={isLarge ? 25 : 20}
                                            height={isLarge ? 25 : 20}
                                            className="!fill-blue"
                                        />
                                    }
                                    shape="round"
                                    className={`!bg-blue-10 ${isLarge && "w-12.5 h-12.5"}`}
                                    onClick={handleEdit}
                                />
                            )}

                            {isVisitorMentor && (
                                <PopoverList>
                                    <Button
                                        iconPlacement="center"
                                        icon={
                                            <Icon
                                                icon={Icons.Dots}
                                                width={isLarge ? 30 : 20}
                                                height={isLarge ? 30 : 20}
                                                className="!fill-blue"
                                            />
                                        }
                                        shape="round"
                                        className={`!bg-blue-10 ${isLarge && "w-12.5 h-12.5"}`}
                                    />
                                    <PopoverList.Item name="achievement" onClickItem={handleAchievement}>
                                        <span className={`${isLarge && "text-[17px]"}`}>Выдать ачивку</span>
                                    </PopoverList.Item>
                                    {/*<PopoverList.Item name="edit" onClickItem={handleEdit}>*/}
                                    {/*    <span className={`${isLarge && "text-[17px]"}`}>Редактировать</span>*/}
                                    {/*</PopoverList.Item>*/}
                                </PopoverList>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {!isLarge && !isMedium && (
                <div className="flex flex-col px-3 pt-5">
                    {mobileMenu.map((item) => {
                        if (item.Id === "profileMenuLogoutLink" && !isCurrent) {
                            return null;
                        }

                        let path = item.Path;

                        if (!isCurrent) {
                            path = "/personal/" + user.id + "/" + item.Path.split("/personal/")[1];
                        }

                        return (
                            <div
                                key={item.Id}
                                className={clsx(
                                    "flex justify-between items-center p-1.25 mb-5",
                                    item.IsBottom && "mt-auto",
                                    item.IsSeparator && "pt-5 border-t border-gray-blue",
                                )}
                                onClick={() => (item.Id === "profileMenuLogoutLink" ? onLogout() : navigate(path))}
                            >
                                <div className="flex items-center">
                                    <Icon
                                        className="mr-4"
                                        icon={item.Icon}
                                        width={22}
                                        height={22}
                                        color={item.Fill ? item.Fill : "fill-gray-dark"}
                                    />
                                    <span className="p2-accent text-black">{item.Title}</span>
                                </div>
                                <Icon icon={Icons.ChevronRight} width={14} height={14} color="fill-blue" />
                            </div>
                        );
                    })}
                </div>
            )}
            <AchievementsOwnModal isOpen={showAchievementsModal} onClose={toggleAchievementsModal} />
        </div>
    );
};
