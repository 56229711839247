import React, { useMemo, useState, useCallback } from "react";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { Modal } from "Uikit/Modal/Modal";
import { SecondaryButton } from "Uikit/Button/SecondaryButton";
import { SubmitButton } from "Uikit/Button/SubmitButton";
import { useMutation, useQuery } from "react-query";
import Api from "Api/index";
import { Label } from "Uikit/Forms/Label";
import { ParentTeamChoiceModal } from "./ParentTeamChoiceModal";
import { FormGroup } from "Uikit/Forms/FormGroup";
import { ComboBox, Option } from "Uikit";
import { ID } from "types/ID";
import { Except } from "type-fest";
import { flattenTeams } from "models/Team";
import { TVoidFunction } from "types";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { ValueContainerProps, components } from "react-select";

interface RemoveFullChildTeamModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    team?: Partial<TeamTreeAllBasicInfoResponse>;
}

export const RemoveFullChildTeamModal = ({ isOpen, onClose, team }: RemoveFullChildTeamModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Удаление команды" description={team?.name}>
            <ModalContent onClose={onClose} team={team} />
        </Modal>
    );
};

const ModalContent = ({ onClose, team }: Except<RemoveFullChildTeamModalProps, "isOpen">) => {
    const [teamModalOpen, setTeamModalOpen] = useState(false);
    const [teamMoveInto, setTeamMoveInto] = useState<{ id: string; name: string }>();

    const { mutateAsync: removeTeam, isLoading } = useMutation(() => {
        return Api.Teams.Remove(team!.id!, teamMoveInto!.id);
    });

    const teamId: ID[] = useMemo(() => (team?.id ? [team.id] : []), [team]);
    const { data: teams = [] as TeamTreeAllBasicInfoResponse[] } = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const onlyIncludes: ID[] = useMemo(() => {
        if (teams?.length > 0 && team) {
            const root = teams.find((t) => t.id === team.parentId);
            if (root) {
                return flattenTeams([root]).map((t) => t.id);
            }
        }
        return [];
    }, [team, teams]);

    const handleSubmit = async () => {
        await removeTeam();
        onClose();
    };

    const toggleModal = useCallback(() => {
        setTeamModalOpen((prev) => !prev);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTeamModalOpen]);

    const ValueContainer = ({ children, ...props }: ValueContainerProps<Option>) => {
        return (
            <components.ValueContainer className="text-ellipsis whitespace-nowrap" {...props}>
                <div className="absolute top-0 left-0 right-0 h-full flex items-center px-4.5" onClick={toggleModal}>
                    {children}
                </div>
            </components.ValueContainer>
        );
    };

    return (
        <>
            <Modal.Body>
                Вы действительно хотите удалить команду? Для ее удаления нужно перенести всех участников, в том числе
                состоящих во вложенных в нее командах.
                <FormGroup>
                    <Label isRequired className="mt-1">
                        Куда
                    </Label>
                    {/* <Select
                        className="bg-[#F6F7F9]"
                        placeholder="Выберите команду"
                        value={team?.parentId}
                        defaultValue=""
                        onClick={() => setTeamModalOpen(true)}
                        id="removeFullChildTeamSelect1"
                    >
                        <option value="" className="!text-[#939393]" selected disabled hidden>
                            {teamToMoveInto ? teamToMoveInto.name : "Выберите команду"}
                        </option>
                    </Select> */}
                    <ComboBox
                        className="mt-3 sm:mt-4"
                        placeholder="Выберите команду"
                        isCreatable={false}
                        isMulti={false}
                        value={teamMoveInto ? [{ value: teamMoveInto.id, label: teamMoveInto.name }] : undefined}
                        components={{
                            DropdownIndicator: () => (
                                <div
                                    className="absolute left-auto top-0 right-0 h-full flex items-center"
                                    onClick={toggleModal}
                                >
                                    <Icon
                                        className="mr-3 ml-2 cursor-pointer"
                                        icon={Icons.List}
                                        width={20}
                                        height={20}
                                        color="fill-blue-drk"
                                    />
                                </div>
                            ),
                            ValueContainer: ValueContainer,
                        }}
                        menuIsOpen={false}
                        onClick={() => {
                            console.log("Click");
                        }}
                        isSearchable={false}
                    />
                    <ParentTeamChoiceModal
                        isOpen={teamModalOpen}
                        isMulti={false}
                        selectable={true}
                        onClose={() => setTeamModalOpen(false)}
                        teamIds={teamId}
                        onlyIncludes={onlyIncludes}
                        onSelect={(id, _all, nodes) => {
                            setTeamMoveInto({
                                id: id[0].split("root:").filter((id) => id.length > 0)[0],
                                name: nodes ? nodes[0].name : "",
                            });
                            setTeamModalOpen(false);
                        }}
                        title="Выбор команды"
                    />
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton onClick={onClose} disabled={isLoading} id="removeFullChildTeamBtnCancel">
                    Отмена
                </SecondaryButton>
                <SubmitButton
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    color="danger"
                    id="removeFullChildTeamBtnOk"
                    disabled={!teamMoveInto}
                >
                    Удалить
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};
