import React from "react";
import { RadioGroup } from "Uikit";
import { TaskAnswerResponseItem } from "Api/Responses/TaskResponse";
import { getObjectUniqueId } from "helpers/objectUniqueId";

interface ITaskQuestionSingleChoiceProps {
    value: string;
    onChange: (value: boolean) => void;
    answers: TaskAnswerResponseItem[];
}

export const TaskQuestionSingleChoice = ({ value, onChange, answers }: ITaskQuestionSingleChoiceProps) => {
    return (
        <RadioGroup value={value} onChange={onChange} listClassName="!bg-transparent">
            {answers
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((variant) => {
                    return (
                        <RadioGroup.Button
                            key={`TaskItemChoiceItem_${getObjectUniqueId(variant)}`}
                            label={variant.answer}
                            value={variant.id}
                            checked={value === variant.answer}
                        />
                    );
                })}
        </RadioGroup>
    );
};
