import React, { useEffect, useRef, useState } from "react";
import { useCKEditor } from "ckeditor4-react";
import clsx from "clsx";
import { getEditorConfig } from "./editor-config";
import "./ck-styles.css";

interface EditorProps {
    initData: string;
    error?: string;
    onChange?: (value: string) => void;
    onWordCountChange?: (value: number) => void;
    onInstanceReady?: (editor: any) => void;
}

export const TextEditor = ({ initData = "", error, onChange, onWordCountChange, onInstanceReady }: EditorProps) => {
    const [element, setElement] = useState<HTMLDivElement | null>(null);
    const changeTimerIdRef = useRef<any>(null);
    const dataReadyTimerIdRef = useRef<any>(null);

    const { status, editor } = useCKEditor({
        element,
        initContent: initData,
        config: getEditorConfig(),
    });

    useEffect(() => {
        if (!editor) {
            return;
        }
        editor.on("instanceReady", (e: any) => {
            onInstanceReady?.(e.editor);
        });

        editor.on("dataReady", (e: any) => {
            if (dataReadyTimerIdRef.current) clearTimeout(dataReadyTimerIdRef.current);

            dataReadyTimerIdRef.current = setTimeout(() => {
                onWordCountChange?.(e.editor?.wordCount?.charCount);
            }, 300);
        });

        editor.on("change", (e: any) => {
            if (changeTimerIdRef.current) clearTimeout(changeTimerIdRef.current);

            onChange?.(e.editor.getData().replaceAll("&nbsp;", " "));

            changeTimerIdRef.current = setTimeout(() => {
                onWordCountChange?.(e.editor?.wordCount?.charCount);
            }, 300);
        });
    }, [editor, onInstanceReady, onWordCountChange, onChange]);

    return (
        <div
            data-ck-editor="5"
            style={{ color: "#161616", position: "relative" }}
            className={clsx(error && "border rounded !border-red overflow-hidden")}
        >
            <div ref={setElement} style={status !== "ready" ? { visibility: "hidden" } : undefined}></div>
        </div>
    );
};

export default TextEditor;
