import BaseApi from "../BaseApi";
import { RoleResponse, TeamAccess } from "Api/Responses/RoleResponse";
import { ID } from "types/ID";
import { BaseArrayResponse, BaseResponse } from "Api/BaseResponse";
import { BasePaginationRequest } from "Api/BaseRequest";
import { TeamAccessRequest, UserAccessRequest } from "Api/Requests/AccessRequest";
import { UserAccess } from "Api/Responses/lmsRoles/UserAccess";
import { ResourceAccess } from "Api/Responses/lmsRoles/ResourceAccess";
import { TeamTreeResponse } from "Api/Responses/TeamResponse";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class LMSRolesApi extends BaseApi {
    getTeams = (): Promise<TeamTreeResponse[]> => {
        return this.Get([], "/teams/");
    };

    getTeamAccess = (resourceId: ID): Promise<TeamAccess> => {
        return this.Get(new TeamAccess(), `/access/team/get/${resourceId}`);
    };

    setTeamAccess = (payload: TeamAccessRequest) => {
        return this.Post(new BaseResponse(), "/access/team/set", payload);
    };

    getUserAccess = (resourceId: ID, { filters, page, size, sort }: BasePaginationRequest): Promise<UserAccess> => {
        const params = LMSFilterParams({
            filter: filters as { [id: string]: string },
            page,
            size,
        });
        params.append("sort", sort ?? "");

        return this.Get(new UserAccess(), `/access/user/get/${resourceId}?${params.toString()}`);
    };

    setUserAccess = (payload: UserAccessRequest) => {
        return this.Post(new BaseResponse(), "/access/user/set", payload);
    };

    getAccessFor = (userId: ID) => {
        return this.Get(new ResourceAccess(), `/access/user/get-for-user/${userId}`);
    };

    getPermittedRolesForNewUser = () => {
        return this.Get(new BaseArrayResponse(RoleResponse), "/role/permitted");
    };

    getPermittedRoles = (userId: string) => {
        return this.Get(new BaseArrayResponse(RoleResponse), `/role/permitted/${userId}`);
    };
}
