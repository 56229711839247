import React from "react";
import { Icon, Icons } from "../Icon/Icon";
import { Input } from "../Forms/Input";
import clsx from "clsx";

interface TreeSearchClassNames {
    container?: string;
}

export interface TreeSearchProps {
    query: string;
    setQuery: (query: string) => void;
    disabled?: boolean;
    placeholder?: string;
    classNames?: TreeSearchClassNames;
    onSearchFilterChange?: (value: string) => void;
}

export const TreeSearch = ({
    query,
    setQuery,
    disabled = false,
    placeholder = "Поиск по дереву",
    classNames,
}: TreeSearchProps) => {
    return (
        <div className={clsx("py-3 relative", classNames?.container)}>
            <div className="absolute left-1 flex items-center inset-y-0 p-1 pl-2 z-20">
                <Icon className="" icon={Icons.Search} width={18} height={18} color="fill-blue-drk" />
            </div>
            <Input
                className={"w-full !placeholder-gray-text !border-1  !border-[#E6E9ED] px-4.5 !pl-10"}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={placeholder}
                disabled={disabled}
                id="treeSearchInput"
            />
        </div>
    );
};
