import { Empty } from "Uikit/Page/Empty";
import React from "react";
import { Button, Icon, Icons } from "./Uikit";
import { useNavigate, useRouteError } from "react-router-dom";

export const RouterErrorBoundary = () => {
    const navigate = useNavigate();
    const error = useRouteError();

    console.log(error);
    if (error.status === 404) {
        return (
            <Empty className="fixed inset-0 !mt-0" childrenClassName="relative" title="">
                <>
                    <div>
                        <svg
                            width="1010"
                            height="600"
                            viewBox="0 0 1010 600"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M318.879 139.623L321.079 144.081L325.999 144.797L322.439 148.267L323.28 153.168L318.879 150.854L314.478 153.168L315.319 148.267L311.758 144.797L316.679 144.081L318.879 139.623Z"
                                fill="#EBEBEB"
                            />
                            <path
                                d="M759.304 349.508L761.505 353.966L766.424 354.682L762.864 358.152L763.705 363.052L759.304 360.739L754.903 363.052L755.743 358.152L752.183 354.682L757.104 353.966L759.304 349.508Z"
                                fill="#F5F5F5"
                            />
                            <path
                                d="M602.069 418.897L604.269 423.355L609.19 424.07L605.628 427.541L606.469 432.441L602.069 430.128L597.668 432.441L598.509 427.541L594.948 424.07L599.868 423.355L602.069 418.897Z"
                                fill="#F5F5F5"
                            />
                            <path
                                d="M572.501 60.5705L573.318 62.2252L575.144 62.4906C575.6 62.5573 575.782 63.1166 575.452 63.4391L574.13 64.7277L574.442 66.5471C574.519 67.0016 574.043 67.3473 573.636 67.1336L572.002 66.275L570.368 67.1336C569.959 67.3486 569.483 67.0016 569.562 66.5471L569.874 64.7277L568.552 63.4391C568.223 63.1179 568.404 62.5573 568.86 62.4906L570.686 62.2252L571.503 60.5705C571.708 60.1568 572.297 60.1568 572.501 60.5705Z"
                                fill="#EBEBEB"
                            />
                            <path
                                d="M247.74 457.726L248.557 459.381L250.383 459.646C250.839 459.713 251.021 460.272 250.691 460.595L249.369 461.883L249.681 463.703C249.758 464.157 249.282 464.503 248.875 464.289L247.241 463.431L245.607 464.289C245.198 464.503 244.722 464.157 244.801 463.703L245.113 461.883L243.791 460.595C243.462 460.274 243.643 459.713 244.099 459.646L245.925 459.381L246.741 457.726C246.947 457.312 247.536 457.312 247.74 457.726Z"
                                fill="#F5F5F5"
                            />
                            <path
                                d="M284.452 43.08L285.269 44.7348L287.095 45.0001C287.551 45.0668 287.733 45.6261 287.402 45.9486L286.081 47.2373L286.393 49.0567C286.47 49.5112 285.994 49.8568 285.586 49.6418L283.951 48.7832L282.318 49.6418C281.91 49.8568 281.434 49.5098 281.511 49.0567L281.823 47.2373L280.502 45.9486C280.172 45.6275 280.353 45.0668 280.809 45.0001L282.635 44.7348L283.452 43.08C283.659 42.6664 284.248 42.6664 284.452 43.08Z"
                                fill="#EBEBEB"
                            />
                            <path
                                d="M282.242 371.058C282.242 372.055 281.434 372.864 280.436 372.864C279.439 372.864 278.631 372.055 278.631 371.058C278.631 370.06 279.439 369.252 280.436 369.252C281.433 369.252 282.242 370.06 282.242 371.058Z"
                                fill="#EBEBEB"
                            />
                            <path
                                d="M540.169 144.586C540.169 145.584 539.36 146.392 538.363 146.392C537.365 146.392 536.557 145.584 536.557 144.586C536.557 143.589 537.365 142.781 538.363 142.781C539.36 142.781 540.169 143.589 540.169 144.586Z"
                                fill="#EBEBEB"
                            />
                            <path
                                d="M486.322 44.5756C486.322 45.573 485.514 46.3813 484.517 46.3813C483.519 46.3813 482.711 45.573 482.711 44.5756C482.711 43.5781 483.519 42.7698 484.517 42.7698C485.514 42.7698 486.322 43.5795 486.322 44.5756Z"
                                fill="#F5F5F5"
                            />
                            <path
                                d="M320.76 461.785C326.135 460.516 329.463 455.13 328.194 449.756C326.925 444.382 321.539 441.054 316.165 442.323C310.79 443.592 307.462 448.977 308.731 454.352C310 459.726 315.386 463.054 320.76 461.785Z"
                                fill="#F0F0F0"
                            />
                            <path
                                d="M314.986 446.261C312.863 446.261 310.89 446.942 309.284 448.112C308.753 449.337 308.454 450.684 308.454 452.099C308.454 457.624 312.931 462.088 318.456 462.088C320.32 462.088 322.076 461.57 323.572 460.674C323.559 460.66 323.559 460.66 323.572 460.66C324.321 459.272 324.743 457.694 324.743 456.006C324.743 450.63 320.375 446.261 314.986 446.261Z"
                                fill="#E6E6E6"
                            />
                            <mask
                                id="mask0_15661_241915"
                                style={{ maskType: "alpha" }}
                                maskUnits="userSpaceOnUse"
                                x="283"
                                y="166"
                                width="144"
                                height="167"
                            >
                                <path
                                    d="M365.998 302.101H283.187V264.713L365.998 166.276H405.618V266.833H426.152V302.101H405.618V332.681H365.998V302.101ZM365.998 266.833V215.343L322.24 266.833H365.998Z"
                                    fill="#0084E2"
                                />
                            </mask>
                            <g mask="url(#mask0_15661_241915)">
                                <path
                                    d="M365.998 302.101H283.187V264.713L365.998 166.276H405.618V266.833H426.152V302.101H405.618V332.681H365.998V302.101ZM365.998 266.833V215.343L322.24 266.833H365.998Z"
                                    fill="#0084E2"
                                />
                                <path
                                    opacity="0.2"
                                    d="M398.275 265.432L424.254 215.343L374.164 241.322C358.072 249.669 343.214 260.206 330.015 272.631L244.628 353.014L286.582 394.967L366.964 309.581C379.391 296.383 389.928 281.525 398.275 265.432Z"
                                    fill="black"
                                />
                            </g>
                            <mask
                                id="mask1_15661_241915"
                                style={{ maskType: "alpha" }}
                                maskUnits="userSpaceOnUse"
                                x="440"
                                y="166"
                                width="135"
                                height="170"
                            >
                                <path
                                    d="M440.328 250.204C440.328 219.104 445.925 197.342 457.124 184.915C468.322 172.49 485.377 166.276 508.296 166.276C519.306 166.276 528.347 167.636 535.415 170.35C542.483 173.067 548.251 176.601 552.714 180.953C557.177 185.306 560.694 189.881 563.26 194.68C565.828 199.478 567.892 205.078 569.455 211.478C572.504 223.68 574.03 236.404 574.03 249.647C574.03 279.333 569.007 301.061 558.963 314.824C548.917 328.59 531.619 335.47 507.066 335.47C493.3 335.47 482.177 333.274 473.696 328.885C465.213 324.497 458.255 318.06 452.826 309.578C448.879 303.553 445.811 295.312 443.618 284.858C441.423 274.404 440.328 262.854 440.328 250.204ZM485.417 250.315C485.417 271.149 487.258 285.379 490.94 293.006C494.624 300.632 499.962 304.445 506.956 304.445C511.569 304.445 515.568 302.827 518.954 299.59C522.339 296.354 524.831 291.24 526.433 284.246C528.032 277.252 528.832 266.351 528.832 251.544C528.832 229.82 526.991 215.218 523.308 207.74C519.625 200.261 514.101 196.523 506.734 196.523C499.218 196.523 493.787 200.337 490.438 207.963C487.091 215.589 485.417 229.707 485.417 250.315Z"
                                    fill="#0084E2"
                                />
                            </mask>
                            <g mask="url(#mask1_15661_241915)">
                                <path
                                    d="M440.328 250.204C440.328 219.104 445.925 197.342 457.124 184.915C468.322 172.49 485.377 166.276 508.296 166.276C519.306 166.276 528.347 167.636 535.415 170.35C542.483 173.067 548.251 176.601 552.714 180.953C557.177 185.306 560.694 189.881 563.26 194.68C565.828 199.478 567.892 205.078 569.455 211.478C572.504 223.68 574.03 236.404 574.03 249.647C574.03 279.333 569.007 301.061 558.963 314.824C548.917 328.59 531.619 335.47 507.066 335.47C493.3 335.47 482.177 333.274 473.696 328.885C465.213 324.497 458.255 318.06 452.826 309.578C448.879 303.553 445.811 295.312 443.618 284.858C441.423 274.404 440.328 262.854 440.328 250.204ZM485.417 250.315C485.417 271.149 487.258 285.379 490.94 293.006C494.624 300.632 499.962 304.445 506.956 304.445C511.569 304.445 515.568 302.827 518.954 299.59C522.339 296.354 524.831 291.24 526.433 284.246C528.032 277.252 528.832 266.351 528.832 251.544C528.832 229.82 526.991 215.218 523.308 207.74C519.625 200.261 514.101 196.523 506.734 196.523C499.218 196.523 493.787 200.337 490.438 207.963C487.091 215.589 485.417 229.707 485.417 250.315Z"
                                    fill="#0084E2"
                                />
                                <path
                                    opacity="0.2"
                                    d="M293.631 346.81C296.579 377.428 361.671 434.037 459.649 410.087C582.121 380.149 520.885 220.255 585.523 173.988C650.161 127.721 866.529 273.326 895.106 220.936C917.967 179.023 837.952 109.577 795.087 80.0925"
                                    stroke="black"
                                    strokeWidth="2.72161"
                                />
                            </g>
                            <path
                                d="M670.905 302.101H588.093V264.713L670.905 166.276H710.525V266.833H731.059V302.101H710.525V332.681H670.905V302.101ZM670.905 266.833V215.343L627.148 266.833H670.905Z"
                                fill="#0084E2"
                            />
                            <g opacity="0.3">
                                <path
                                    d="M478.491 108.83C478.491 110.232 477.355 111.368 475.953 111.368C474.552 111.368 473.415 110.232 473.415 108.83C473.415 107.428 474.552 106.292 475.953 106.292C477.355 106.292 478.491 107.428 478.491 108.83Z"
                                    fill="#0084E2"
                                />
                                <path
                                    d="M264.327 206.582C265.322 206.582 266.128 205.776 266.128 204.782C266.128 203.787 265.322 202.981 264.327 202.981C263.333 202.981 262.527 203.787 262.527 204.782C262.527 205.776 263.333 206.582 264.327 206.582Z"
                                    fill="#0084E2"
                                />
                                <path
                                    d="M441.102 388.909C442.525 388.909 443.678 387.756 443.678 386.333C443.678 384.911 442.525 383.757 441.102 383.757C439.679 383.757 438.526 384.911 438.526 386.333C438.526 387.756 439.679 388.909 441.102 388.909Z"
                                    fill="#0084E2"
                                />
                                <path
                                    d="M622.942 364.259C623.937 364.259 624.743 363.453 624.743 362.459C624.743 361.465 623.937 360.659 622.942 360.659C621.948 360.659 621.142 361.465 621.142 362.459C621.142 363.453 621.948 364.259 622.942 364.259Z"
                                    fill="#0084E2"
                                />
                                <path
                                    d="M742.251 40.7913C742.251 41.786 741.445 42.5916 740.45 42.5916C739.456 42.5916 738.65 41.786 738.65 40.7913C738.65 39.7965 739.456 38.9909 740.45 38.9909C741.445 38.9909 742.251 39.7965 742.251 40.7913Z"
                                    fill="#0084E2"
                                />
                                <path
                                    d="M400.119 4.51769C400.119 5.51243 399.314 6.31803 398.319 6.31803C397.324 6.31803 396.518 5.51243 396.518 4.51769C396.518 3.52294 397.324 2.71735 398.319 2.71735C399.314 2.71735 400.119 3.52294 400.119 4.51769Z"
                                    fill="#0084E2"
                                />
                                <path
                                    d="M642.923 98.8254C643.917 98.8254 644.723 98.0193 644.723 97.025C644.723 96.0307 643.917 95.2247 642.923 95.2247C641.929 95.2247 641.123 96.0307 641.123 97.025C641.123 98.0193 641.929 98.8254 642.923 98.8254Z"
                                    fill="#0084E2"
                                />
                            </g>
                            <path
                                d="M306.436 98.8322C322.903 98.8322 336.251 85.4835 336.251 69.0171C336.251 52.5506 322.903 39.2019 306.436 39.2019C289.97 39.2019 276.621 52.5506 276.621 69.0171C276.621 85.4835 289.97 98.8322 306.436 98.8322Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.7"
                                d="M306.436 98.8322C322.903 98.8322 336.251 85.4835 336.251 69.0171C336.251 52.5506 322.903 39.2019 306.436 39.2019C289.97 39.2019 276.621 52.5506 276.621 69.0171C276.621 85.4835 289.97 98.8322 306.436 98.8322Z"
                                fill="white"
                            />
                            <path
                                opacity="0.2"
                                d="M296.161 46.5217C291.982 46.5217 288.015 47.3858 284.402 48.9303C279.571 54.2279 276.624 61.2837 276.624 69.0185C276.624 85.4801 289.969 98.8255 306.431 98.8255C310.623 98.8255 314.619 97.9613 318.246 96.4032C318.246 96.4032 318.246 96.4032 318.246 96.3896C323.049 91.0907 325.981 84.0635 325.981 76.3437C325.983 59.8671 312.637 46.5217 296.161 46.5217Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.2"
                                d="M293.197 54.4049C293.197 55.9099 291.976 57.1305 290.471 57.1305C288.966 57.1305 287.746 55.9099 287.746 54.4049C287.746 52.8998 288.966 51.6792 290.471 51.6792C291.976 51.6792 293.197 52.8998 293.197 54.4049Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.2"
                                d="M325.257 51.6791C325.257 53.1841 324.037 54.4048 322.532 54.4048C321.027 54.4048 319.806 53.1841 319.806 51.6791C319.806 50.174 321.027 48.9534 322.532 48.9534C324.037 48.9548 325.257 50.174 325.257 51.6791Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.2"
                                d="M319.806 71.1127C319.806 73.5757 317.81 75.5734 315.345 75.5734C312.882 75.5734 310.885 73.5757 310.885 71.1127C310.885 68.6496 312.881 66.652 315.345 66.652C317.808 66.652 319.806 68.6496 319.806 71.1127Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.2"
                                d="M305.632 83.8293C305.632 87.1224 302.962 89.791 299.67 89.791C296.377 89.791 293.708 87.1211 293.708 83.8293C293.708 80.5362 296.378 77.8676 299.67 77.8676C302.962 77.8676 305.632 80.5375 305.632 83.8293Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M784.888 428.735C788.208 414.673 779.5 400.581 765.438 397.261C751.376 393.94 737.285 402.648 733.965 416.71C730.644 430.772 739.352 444.864 753.414 448.184C767.476 451.505 781.567 442.797 784.888 428.735Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.3"
                                d="M784.888 428.735C788.208 414.673 779.5 400.581 765.438 397.261C751.376 393.94 737.285 402.648 733.965 416.71C730.644 430.772 739.352 444.864 753.414 448.184C767.476 451.505 781.567 442.797 784.888 428.735Z"
                                fill="white"
                            />
                            <path
                                opacity="0.4"
                                d="M775.942 402.384C773.562 401.661 771.027 401.279 768.406 401.279C753.955 401.279 742.239 412.995 742.239 427.444C742.239 435.634 745.993 442.929 751.887 447.731C754.267 448.454 756.802 448.836 759.424 448.836C773.875 448.836 785.59 437.12 785.59 422.671C785.59 414.483 781.836 407.172 775.942 402.384Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M734.433 430.428C706.491 443.576 727.335 453.71 766.701 439.563C803.148 426.464 817.005 409.94 784.462 415.061C786.141 422.012 740.492 439.799 734.433 430.428Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M270.497 346.81C273.446 377.428 338.537 434.037 436.515 410.087C558.987 380.15 497.751 220.255 562.389 173.988C627.027 127.721 843.395 273.327 871.972 220.936C894.833 179.023 814.818 109.577 771.953 80.0926"
                                stroke="#339DE8"
                                strokeWidth="2.72161"
                            />
                            <path
                                d="M740.325 42.6012C749.653 42.4311 758.808 45.5052 758.808 45.5052C758.808 45.5052 773.729 71.0488 775.548 76.8948C774.921 82.6646 765.186 92.1236 765.186 92.1236L740.325 42.6012Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.3"
                                d="M740.325 42.6012C749.653 42.4311 758.808 45.5052 758.808 45.5052C758.808 45.5052 773.729 71.0488 775.548 76.8948C774.921 82.6646 765.186 92.1236 765.186 92.1236L740.325 42.6012Z"
                                fill="white"
                            />
                            <path
                                d="M784.662 166.685C779.033 159.543 773.507 152.268 768.932 144.388C767.789 142.419 766.706 140.413 765.681 138.38C765.099 137.225 764.535 136.06 763.988 134.887C763.511 133.862 762.456 132.353 762.35 131.234C760.716 113.99 766.619 101.297 760.951 87.4546L738.537 96.2141C738.537 96.2141 740.444 120.875 744.794 135.627C747.494 144.78 753.605 152.704 759.495 160.036C761.337 162.33 763.113 164.676 764.952 166.973C766.692 169.148 768.642 171.143 770.387 173.306C773.036 176.59 773.918 179.724 771.904 183.595C771.797 183.802 771.684 184.008 771.568 184.212C770.996 185.22 773.672 186.363 775.352 184.24C778.131 180.728 777.694 180.944 780.063 177.949C781.502 176.131 783.154 174.168 784.58 172.461C785.973 170.797 786.005 168.388 784.662 166.685Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.7"
                                d="M784.662 166.685C779.033 159.543 773.507 152.268 768.932 144.388C767.789 142.419 766.706 140.413 765.681 138.38C765.099 137.225 764.535 136.06 763.988 134.887C763.511 133.862 762.456 132.353 762.35 131.234C760.716 113.99 766.619 101.297 760.951 87.4546L738.537 96.2141C738.537 96.2141 740.444 120.875 744.794 135.627C747.494 144.78 753.605 152.704 759.495 160.036C761.337 162.33 763.113 164.676 764.952 166.973C766.692 169.148 768.642 171.143 770.387 173.306C773.036 176.59 773.918 179.724 771.904 183.595C771.797 183.802 771.684 184.008 771.568 184.212C770.996 185.22 773.672 186.363 775.352 184.24C778.131 180.728 777.694 180.944 780.063 177.949C781.502 176.131 783.154 174.168 784.58 172.461C785.973 170.797 786.005 168.388 784.662 166.685Z"
                                fill="white"
                            />
                            <path
                                opacity="0.3"
                                d="M779.182 159.588C776.181 163.214 770.081 167.919 767.392 169.825C767.977 170.477 768.556 171.142 769.137 171.808C774.673 168.192 779.05 163.502 780.71 161.596C780.197 160.936 779.689 160.261 779.182 159.588Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.3"
                                d="M784.656 166.687C784.604 166.616 784.55 166.559 784.498 166.49C783.554 167.474 782.889 169.043 782.777 170.804C782.687 172.02 782.891 173.133 783.29 174.003C783.743 173.478 784.166 172.958 784.581 172.464C785.98 170.798 785.999 168.391 784.656 166.687Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M734.997 58.9336C732.652 62.3437 729.888 65.7049 726.321 68.7232C724.551 70.2405 722.53 71.6135 720.311 72.7784C719.191 73.3499 718.014 73.8452 716.796 74.2548L715.877 74.5501L715.581 74.6427C715.366 74.708 715.149 74.7665 714.931 74.8182C714.52 74.9108 714.108 74.9734 713.743 75.0033C712.27 75.1149 711.202 74.9053 710.321 74.6862C708.583 74.2127 707.423 73.5799 706.324 72.9662C705.243 72.3416 704.293 71.702 703.393 71.042C701.621 69.6934 700.018 68.3394 698.524 66.8943C695.517 64.023 692.85 61.0455 690.373 57.7293C689.244 56.2174 689.554 54.0769 691.066 52.9488C692.232 52.0765 693.774 52.0629 694.934 52.7964L695.038 52.863C698.249 54.9015 701.489 57.0516 704.651 58.9758C706.247 59.9174 707.806 60.8646 709.34 61.6021C710.094 61.9831 710.833 62.3301 711.5 62.5764C712.149 62.8295 712.796 62.9738 712.965 62.9398C713.036 62.9357 712.951 62.8391 712.451 62.8568C712.33 62.8636 712.158 62.8799 711.984 62.918C711.883 62.937 711.785 62.9629 711.687 62.9942C711.593 63.0255 711.727 62.9684 711.735 62.9602L712.185 62.7302C712.784 62.4254 713.358 62.0444 713.941 61.6565C715.106 60.8686 716.215 59.8684 717.347 58.7716C719.587 56.5467 721.742 53.7775 724.018 50.898L724.047 50.8586C726.377 47.9083 730.658 47.4062 733.608 49.7359C736.433 51.9663 737.001 56.0065 734.997 58.9336Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.7"
                                d="M734.997 58.9336C732.652 62.3437 729.888 65.7049 726.321 68.7232C724.551 70.2405 722.53 71.6135 720.311 72.7784C719.191 73.3499 718.014 73.8452 716.796 74.2548L715.877 74.5501L715.581 74.6427C715.366 74.708 715.149 74.7665 714.931 74.8182C714.52 74.9108 714.108 74.9734 713.743 75.0033C712.27 75.1149 711.202 74.9053 710.321 74.6862C708.583 74.2127 707.423 73.5799 706.324 72.9662C705.243 72.3416 704.293 71.702 703.393 71.042C701.621 69.6934 700.018 68.3394 698.524 66.8943C695.517 64.023 692.85 61.0455 690.373 57.7293C689.244 56.2174 689.554 54.0769 691.066 52.9488C692.232 52.0765 693.774 52.0629 694.934 52.7964L695.038 52.863C698.249 54.9015 701.489 57.0516 704.651 58.9758C706.247 59.9174 707.806 60.8646 709.34 61.6021C710.094 61.9831 710.833 62.3301 711.5 62.5764C712.149 62.8295 712.796 62.9738 712.965 62.9398C713.036 62.9357 712.951 62.8391 712.451 62.8568C712.33 62.8636 712.158 62.8799 711.984 62.918C711.883 62.937 711.785 62.9629 711.687 62.9942C711.593 63.0255 711.727 62.9684 711.735 62.9602L712.185 62.7302C712.784 62.4254 713.358 62.0444 713.941 61.6565C715.106 60.8686 716.215 59.8684 717.347 58.7716C719.587 56.5467 721.742 53.7775 724.018 50.898L724.047 50.8586C726.377 47.9083 730.658 47.4062 733.608 49.7359C736.433 51.9663 737.001 56.0065 734.997 58.9336Z"
                                fill="white"
                            />
                            <path
                                d="M685.25 50.0407L686.844 52.7161C686.844 52.7161 688.062 56.2759 690.499 56.9345L697.105 54.7954L696.774 54.2402C696.777 54.2415 696.781 54.2415 696.784 54.2415C695.937 52.9528 696.028 50.468 696.326 48.401C696.623 46.3339 695.551 46.2604 694.759 46.7748C694.302 47.0714 693.978 48.0145 693.606 49.031C693.281 48.5833 692.933 48.1519 692.539 47.76L690.521 45.7501C689.65 44.8833 688.256 44.8438 687.337 45.6603L685.701 47.1136C684.868 47.8539 684.679 49.0827 685.25 50.0407Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.7"
                                d="M685.25 50.0407L686.844 52.7161C686.844 52.7161 688.062 56.2759 690.499 56.9345L697.105 54.7954L696.774 54.2402C696.777 54.2415 696.781 54.2415 696.784 54.2415C695.937 52.9528 696.028 50.468 696.326 48.401C696.623 46.3339 695.551 46.2604 694.759 46.7748C694.302 47.0714 693.978 48.0145 693.606 49.031C693.281 48.5833 692.933 48.1519 692.539 47.76L690.521 45.7501C689.65 44.8833 688.256 44.8438 687.337 45.6603L685.701 47.1136C684.868 47.8539 684.679 49.0827 685.25 50.0407Z"
                                fill="white"
                            />
                            <path
                                d="M747.015 40.2537C740.689 41.7887 731.997 45.3989 726.128 49.0554C724.047 50.3509 723.009 52.8112 723.499 55.2131C726.117 68.059 732.091 85.2664 738.54 96.2127L768.629 83.7654C768.836 78.4583 761.531 61.285 754.09 44.6872C752.754 41.7016 750.194 39.4835 747.015 40.2537Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.8"
                                d="M747.015 40.2537C740.689 41.7887 731.997 45.3989 726.128 49.0554C724.047 50.3509 723.009 52.8112 723.499 55.2131C726.117 68.059 732.091 85.2664 738.54 96.2127L768.629 83.7654C768.836 78.4583 761.531 61.285 754.09 44.6872C752.754 41.7016 750.194 39.4835 747.015 40.2537Z"
                                fill="white"
                            />
                            <path
                                opacity="0.3"
                                d="M758.748 55.209L752.777 53.2073C754.113 56.7005 758.945 61.1285 762.343 63.729C761.223 60.9992 760.009 58.1456 758.748 55.209Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M745.032 26.7764C742.549 22.0395 737.175 19.6553 730.722 20.1942C725.282 20.6487 720.456 26.2035 721.029 29.2055C721.601 32.2074 726.18 33.4784 727.008 34.5208L723.235 37.256C721.357 38.6181 720.99 41.2662 722.424 43.0897C724.012 45.1064 726.102 47.213 727.32 48.7003C737.749 48.4295 745.462 44.4505 748.241 40.6226C747.242 35.7523 747.502 31.4875 745.032 26.7764Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.8"
                                d="M745.032 26.7764C742.549 22.0395 737.175 19.6553 730.722 20.1942C725.282 20.6487 720.456 26.2035 721.029 29.2055C721.601 32.2074 726.18 33.4784 727.008 34.5208L723.235 37.256C721.357 38.6181 720.99 41.2662 722.424 43.0897C724.012 45.1064 726.102 47.213 727.32 48.7003C737.749 48.4295 745.462 44.4505 748.241 40.6226C747.242 35.7523 747.502 31.4875 745.032 26.7764Z"
                                fill="white"
                            />
                            <path
                                d="M739.921 29.7103C741.992 35.0119 739.375 40.9886 734.074 43.0597C728.772 45.1309 722.795 42.514 720.724 37.2124C718.653 31.912 721.27 25.934 726.572 23.8629C731.872 21.7917 737.849 24.4099 739.921 29.7103Z"
                                fill="#263238"
                            />
                            <path
                                d="M828.274 152.346C828.123 147.87 827.93 148.318 827.799 144.502C827.72 142.185 827.711 139.618 827.696 137.395C827.681 135.224 826.147 133.367 824.02 132.935C822.217 132.569 820.408 132.221 818.608 131.837C816.259 131.336 813.938 130.797 811.631 130.128C809.843 129.608 808.07 129.035 806.317 128.404C804.439 127.727 802.587 126.986 800.753 126.199C798.608 125.279 796.49 124.3 794.387 123.29C792.04 122.163 789.71 120.998 787.389 119.818C778.433 104.993 778.24 95.2234 768.629 83.764L747.999 93.0964C747.999 93.0964 763.157 119.993 773.48 131.396C779.422 137.959 788.745 140.918 797.06 143.065C803.058 144.614 809.141 145.799 815.231 146.918C817.598 147.352 820.172 147.52 822.209 148.934C823.813 150.047 824.643 151.785 825.117 153.632C825.213 154.009 825.295 154.39 825.369 154.773C825.586 155.912 828.366 155.052 828.274 152.346Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.8"
                                d="M828.274 152.346C828.123 147.87 827.93 148.318 827.799 144.502C827.72 142.185 827.711 139.618 827.696 137.395C827.681 135.224 826.147 133.367 824.02 132.935C822.217 132.569 820.408 132.221 818.608 131.837C816.259 131.336 813.938 130.797 811.631 130.128C809.843 129.608 808.07 129.035 806.317 128.404C804.439 127.727 802.587 126.986 800.753 126.199C798.608 125.279 796.49 124.3 794.387 123.29C792.04 122.163 789.71 120.998 787.389 119.818C778.433 104.993 778.24 95.2234 768.629 83.764L747.999 93.0964C747.999 93.0964 763.157 119.993 773.48 131.396C779.422 137.959 788.745 140.918 797.06 143.065C803.058 144.614 809.141 145.799 815.231 146.918C817.598 147.352 820.172 147.52 822.209 148.934C823.813 150.047 824.643 151.785 825.117 153.632C825.213 154.009 825.295 154.39 825.369 154.773C825.586 155.912 828.366 155.052 828.274 152.346Z"
                                fill="white"
                            />
                            <path
                                opacity="0.3"
                                d="M817.812 131.667C817 131.489 816.175 131.306 815.354 131.112C815.418 135.811 813.215 143.926 812.406 146.385C813.264 146.547 814.135 146.713 814.994 146.877C817.382 140.42 817.758 133.584 817.812 131.667Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.3"
                                d="M827.693 137.396C827.678 135.22 826.15 133.367 824.027 132.931C823.6 132.844 823.159 132.752 822.729 132.678C822.819 133.957 823.613 135.442 824.944 136.62C825.816 137.407 826.803 137.929 827.709 138.176C827.696 137.905 827.694 137.651 827.693 137.396Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M737.995 29.7975C738.547 31.8604 736.237 34.0296 734.389 32.2455C732.89 30.7976 730.631 28.3727 728.787 27.2201C726.893 26.0348 729.418 23.9719 732.393 24.772C735.17 25.5205 737.444 27.7331 737.995 29.7975Z"
                                fill="white"
                            />
                            <path
                                d="M738.152 95.5514C737.193 95.9052 738.945 97.5327 738.945 97.5327C738.945 97.5327 757.956 91.0186 769.557 84.3112C769.655 82.9082 768.634 82.1638 768.634 82.1638C768.634 82.1638 754.52 89.5176 738.152 95.5514Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.5"
                                d="M738.152 95.5514C737.193 95.9052 738.945 97.5327 738.945 97.5327C738.945 97.5327 757.956 91.0186 769.557 84.3112C769.655 82.9082 768.634 82.1638 768.634 82.1638C768.634 82.1638 754.52 89.5176 738.152 95.5514Z"
                                fill="white"
                            />
                            <path
                                d="M752.167 39.3543C755.92 41.2499 759.453 43.2571 762.981 45.4792C764.738 46.5856 766.487 47.7218 768.209 48.9479C769.946 50.1495 771.659 51.4164 773.378 52.8425L774.023 53.3705L774.827 54.0958C775.399 54.5993 775.818 55.0634 776.238 55.526C776.679 56.0037 777.042 56.4636 777.4 56.9222C777.772 57.3835 778.124 57.8421 778.441 58.2939C779.787 60.1011 780.884 61.9395 781.96 63.763C784.052 67.4603 785.863 71.1521 787.357 75.1243C788.024 76.8961 787.129 78.872 785.356 79.5401C784.025 80.0409 782.577 79.6599 781.657 78.6883L781.59 78.6148C778.856 75.719 776.234 72.664 773.595 69.8267C771.014 66.9976 768.272 64.0937 766.061 62.8308C762.972 60.9039 759.532 58.9907 756.113 57.1059L745.821 51.3933L745.796 51.3797C742.509 49.5562 741.324 45.4139 743.148 42.1276C744.929 38.9229 748.924 37.7213 752.167 39.3543Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.8"
                                d="M752.167 39.3543C755.92 41.2499 759.453 43.2571 762.981 45.4792C764.738 46.5856 766.487 47.7218 768.209 48.9479C769.946 50.1495 771.659 51.4164 773.378 52.8425L774.023 53.3705L774.827 54.0958C775.399 54.5993 775.818 55.0634 776.238 55.526C776.679 56.0037 777.042 56.4636 777.4 56.9222C777.772 57.3835 778.124 57.8421 778.441 58.2939C779.787 60.1011 780.884 61.9395 781.96 63.763C784.052 67.4603 785.863 71.1521 787.357 75.1243C788.024 76.8961 787.129 78.872 785.356 79.5401C784.025 80.0409 782.577 79.6599 781.657 78.6883L781.59 78.6148C778.856 75.719 776.234 72.664 773.595 69.8267C771.014 66.9976 768.272 64.0937 766.061 62.8308C762.972 60.9039 759.532 58.9907 756.113 57.1059L745.821 51.3933L745.796 51.3797C742.509 49.5562 741.324 45.4139 743.148 42.1276C744.929 38.9229 748.924 37.7213 752.167 39.3543Z"
                                fill="white"
                            />
                            <path
                                d="M790.641 81.7773L789.486 78.8856C789.486 78.8856 788.841 75.1788 786.537 74.1459L779.678 75.2237L779.918 75.8238C779.915 75.8224 779.912 75.8211 779.908 75.8211C780.542 77.2254 780.062 79.6667 779.444 81.6603C778.826 83.6552 779.874 83.8961 780.737 83.5123C781.235 83.2905 781.703 82.4101 782.228 81.4643C782.48 81.9583 782.756 82.4386 783.083 82.8863L784.761 85.1875C785.485 86.1795 786.857 86.438 787.892 85.7753L789.735 84.5955C790.675 83.9954 791.056 82.8129 790.641 81.7773Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.8"
                                d="M790.641 81.7773L789.486 78.8856C789.486 78.8856 788.841 75.1788 786.537 74.1459L779.678 75.2237L779.918 75.8238C779.915 75.8224 779.912 75.8211 779.908 75.8211C780.542 77.2254 780.062 79.6667 779.444 81.6603C778.826 83.6552 779.874 83.8961 780.737 83.5123C781.235 83.2905 781.703 82.4101 782.228 81.4643C782.48 81.9583 782.756 82.4386 783.083 82.8863L784.761 85.1875C785.485 86.1795 786.857 86.438 787.892 85.7753L789.735 84.5955C790.675 83.9954 791.056 82.8129 790.641 81.7773Z"
                                fill="white"
                            />
                            <path
                                opacity="0.3"
                                d="M746.419 54.9952L744.76 55.13L734.575 79.7387C735.173 79.7795 735.723 79.7292 736.232 79.604C736.232 79.604 749.513 74.6548 753.533 72.8408C750.828 67.2343 746.419 54.9952 746.419 54.9952Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M727.82 61.4522C728.387 65.8844 731.71 75.3038 734.576 79.7387C739.55 78.0241 747.857 74.7894 751.877 72.9755C749.171 67.3703 746.321 59.6464 744.762 55.1313C740.476 55.4157 731.137 58.9334 727.82 61.4522Z"
                                fill="white"
                            />
                            <path
                                opacity="0.3"
                                d="M737.933 66.7744C737.378 68.6319 735.421 69.6865 733.565 69.13C731.709 68.5734 730.653 66.6179 731.209 64.7618C731.766 62.9043 733.721 61.8497 735.578 62.4062C737.434 62.9614 738.488 64.9169 737.933 66.7744Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.6"
                                d="M740.536 62.0863C740.354 62.6946 739.713 63.0403 739.105 62.8579C738.496 62.6756 738.151 62.0346 738.332 61.4263C738.514 60.8181 739.155 60.4724 739.763 60.6548C740.373 60.8358 740.719 61.4767 740.536 62.0863Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.6"
                                d="M743.574 60.9039C743.391 61.5122 742.75 61.8579 742.142 61.6755C741.534 61.4932 741.188 60.8522 741.37 60.2439C741.553 59.6357 742.194 59.29 742.802 59.4724C743.41 59.6547 743.756 60.2957 743.574 60.9039Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.5"
                                d="M747.855 71.5019L735.271 76.4742L734.511 74.0003L747.094 69.0266L747.855 71.5019Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M183.253 332.464C190.87 340.081 207.07 356.281 207.07 356.281L273.764 293.457C250.813 284.731 206.075 309.644 183.253 332.464Z"
                                fill="#263238"
                            />
                            <path
                                d="M266.309 415.52C258.692 407.903 242.492 391.703 242.492 391.703L305.316 325.01C314.042 347.96 289.131 392.698 266.309 415.52Z"
                                fill="#263238"
                            />
                            <path
                                d="M357.451 265.433L383.43 215.343L333.34 241.322C317.247 249.669 302.39 260.206 289.19 272.631L203.804 353.014L245.758 394.967L326.14 309.581C338.567 296.383 349.104 281.525 357.451 265.433Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.6"
                                d="M357.451 265.433L383.43 215.343L333.34 241.322C317.247 249.669 302.39 260.206 289.19 272.631L203.804 353.014L245.758 394.967L326.14 309.581C338.567 296.383 349.104 281.525 357.451 265.433Z"
                                fill="white"
                            />
                            <path
                                d="M304.106 312.221C313.8 312.221 321.659 304.362 321.659 294.668C321.659 284.974 313.8 277.115 304.106 277.115C294.412 277.115 286.553 284.974 286.553 294.668C286.553 304.362 294.412 312.221 304.106 312.221Z"
                                fill="white"
                            />
                            <path
                                d="M304.105 305.942C310.332 305.942 315.379 300.895 315.379 294.668C315.379 288.442 310.332 283.394 304.105 283.394C297.878 283.394 292.831 288.442 292.831 294.668C292.831 300.895 297.878 305.942 304.105 305.942Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.3"
                                d="M259.945 379.899L218.874 338.829L252.5 307.171L291.602 346.272L259.945 379.899Z"
                                fill="#0084E2"
                            />
                            <path
                                d="M192.55 370.101C161.789 372.604 151.6 392.291 148.124 418.463C146.352 431.811 145.566 445.316 134.357 453.129C131.246 455.298 132.791 460.133 136.582 460.015C177.867 458.726 197.124 438.509 199.542 430.043C198.709 435.395 197.496 439.617 196.151 442.937C194.768 446.348 198.515 449.506 201.634 447.551C213.224 440.29 227.76 426.928 228.726 405.26C220.157 393.217 192.55 370.101 192.55 370.101Z"
                                fill="#0084E2"
                            />
                            <path
                                opacity="0.2"
                                d="M192.55 370.101C161.789 372.604 151.6 392.291 148.124 418.463C146.352 431.811 145.566 445.316 134.357 453.129C131.246 455.298 132.791 460.133 136.582 460.015C177.867 458.726 197.124 438.509 199.542 430.043C198.709 435.395 197.496 439.617 196.151 442.937C194.768 446.348 198.515 449.506 201.634 447.551C213.224 440.29 227.76 426.928 228.726 405.26C220.157 393.217 192.55 370.101 192.55 370.101Z"
                                fill="white"
                            />
                            <path
                                d="M233.451 414.578L184.195 365.322L210.435 359.646L239.128 388.338L233.451 414.578Z"
                                fill="#0084E2"
                            />
                        </svg>
                    </div>
                    <div className="absolute bottom-15 left-[50%] -translate-x-[50%]">
                        <p className="mb-5 text-blue-drk" id="emptyPageDecription">
                            Упс... Такой страницы у нас нет
                        </p>
                        <Button
                            size={"medium"}
                            className="rounded-lg whitespace-nowrap font-medium"
                            icon={
                                <Icon
                                    className="mr-1.5"
                                    icon={Icons.UserMenuIconHome}
                                    color="fill-white"
                                    width={17}
                                    height={17}
                                />
                            }
                            iconPlacement={"left"}
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            На главную
                        </Button>
                    </div>
                </>
            </Empty>
        );
    }

    return (
        <Empty
            className="fixed inset-0 !mt-0"
            title="Что-то пошло не так"
            description="Мы уже работаем над устранением ошибки. Пожалуйста, перезагрузите страницу или перейдите на главную"
        >
            <Button
                size={"medium"}
                className="rounded-lg whitespace-nowrap font-medium"
                icon={
                    <Icon className="mr-1.5" icon={Icons.UserMenuIconHome} color="fill-white" width={17} height={17} />
                }
                iconPlacement={"left"}
                onClick={() => {
                    navigate("/");
                }}
            >
                На главную
            </Button>
        </Empty>
    );
};
