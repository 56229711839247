import { NodeType } from "Uikit/Tree/TreeNode";

const DEFAULT_CHECKED = {
    [NodeType.PROJECT]: [],
    [NodeType.SECTION]: [],
    [NodeType.ARTICLE]: [],
    [NodeType.ACTUAL_ROOT]: [],
};

export function createCheckedBucket() {
    let state = DEFAULT_CHECKED;

    return {
        change: (tree) => {
            const bucket = JSON.parse(JSON.stringify(DEFAULT_CHECKED));

            function add(node) {
                bucket[node.nodeType].push(node);
            }

            function traverse(nodes) {
                for (let node of nodes) {
                    if (node.state.checked && !node.state.indeterminate) {
                        add(node);
                    } else {
                        traverse(node.children);
                    }
                }
            }

            traverse(tree.nodes.filter((n) => n.nodeType === NodeType.PROJECT));

            state = bucket;
        },

        getState: () => {
            return state;
        },

        // hasChecked: () => {
        //     return !!(
        //         state &&
        //         (state[NodeType.PROJECT].length > 0 ||
        //             state[NodeType.SECTION].length > 0 ||
        //             state[NodeType.ARTICLE].length > 0)
        //     );
        // },
    };
}
