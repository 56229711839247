import React, { useState } from "react";
import { Button, PopoverList } from "Uikit";
import { TVoidFunction } from "types";

interface SettingsPositionRowActionPopoverProps {
    onDelete: TVoidFunction;
    id?: string;
}

export const RowActionPopover = ({ onDelete, id }: SettingsPositionRowActionPopoverProps) => {
    const [visible, setVisible] = useState(false);

    return (
        <div id={id}>
            <PopoverList
                offset={[0, 18]}
                visible={visible}
                onClickOutside={() => setVisible(false)}
                className="border-gray"
            >
                <Button
                    shape={"round"}
                    color={"common"}
                    className="!text-blue-drk hover:!text-blue-hover"
                    onClick={() => setVisible(!visible)}
                >
                    ...
                </Button>
                <PopoverList.Item
                    name="remove"
                    className="border-gray"
                    onClickItem={() => {
                        onDelete();
                        setVisible(false);
                    }}
                >
                    Удалить
                </PopoverList.Item>
            </PopoverList>
        </div>
    );
};
