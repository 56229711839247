import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ColumnDef, PaginationState, SortingState } from "@tanstack/react-table";
import { Empty } from "Uikit/Page/Empty";
import { Button, Icon, Icons, Table } from "Uikit";
import { AddAchievementModal } from "./Modal/AddAchievementModal";
import { RemoveAchievementModal } from "./Modal/RemoveAchievementModal";
import { AchievementRequest } from "Api/Requests/AchievementRequest";
import { AchievementUser, AchievementUserResponse, UserAchievementResponse } from "Api/Responses/AchievementResponse";
import { BaseArrayResponse, BasePaginationResponse } from "Api/BaseResponse";
import { UserListResponse } from "Api/Responses/UserResponse";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { IOption } from "types";

interface IAchievementContent {
    achievement: AchievementRequest;
    offices: any;
    jobs: IOption[];
    fetch: (page: number, size: number, sort: SortingState) => Promise<BasePaginationResponse<AchievementUserResponse>>;
    fetchUsers: (
        page: number,
        size: number,
        sort: SortingState,
        filters: any,
    ) => Promise<BaseArrayResponse<UserListResponse>>;
    fetchUserAchievement: (
        achievementId: string,
        userId: string,
        sort: SortingState,
    ) => Promise<BasePaginationResponse<UserAchievementResponse>>;
    onAdd: (users: string[], comment: string) => void;
    onRemove: (userId: string, achievementIds: string[]) => void;
}
export const AchievementContent = ({
    achievement,
    offices,
    jobs,
    fetch,
    fetchUsers,
    fetchUserAchievement,
    onAdd,
    onRemove,
}: IAchievementContent) => {
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });

    const [sorting, setSorting] = useState<SortingState>([{ id: "lastGivenAt", desc: true }]);
    const [rowSelection, setRowSelection] = useState({});

    const [isAddAchievementModalOpen, setIsAddAchievementModalOpen] = useState(false);
    const [isRemoveAchievementModalOpen, setIsRemoveAchievementModalOpen] = useState(false);

    const [selectedUser, setSelectedUser] = useState<AchievementUser>();
    const [achievements, setAchievements] = useState<BasePaginationResponse<AchievementUserResponse>>();

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["achievementUsers", "collection", pagination, sorting],
        async () => await fetch(pageIndex, pageSize, sorting),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const formatDateTime = (ms: number) => {
        const monthAbbr = [
            "Янв.",
            "Февр.",
            "Марта",
            "Апр.",
            "Мая",
            "Июня",
            "Июля",
            "Авг.",
            "Сент.",
            "Окт.",
            "Нояб.",
            "Дек.",
        ];
        const date = new Date(ms);

        const year = date.getFullYear();
        const month = monthAbbr[date.getMonth()];
        const day = date.getDate();
        const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

        return `${day} ${month}, ${year}, ${hours}:${minutes}`;
    };
    const onOpenAchievementModal = async () => {
        setAchievements(await fetch(0, 9999, [{ id: "lastGivenAt", desc: true }]));
        setIsAddAchievementModalOpen(true);
    };

    const columns = React.useMemo<ColumnDef<AchievementUserResponse>[]>(
        () => [
            {
                header: "Участник",
                accessorKey: "user.firstName,user.lastName",
                accessorFn: (row) => `${row.givenTo.firstName} ${row.givenTo.lastName}`,
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <UserAvatar
                                avatarId={info.row.original.givenTo.avatarId}
                                color={info.row.original.givenTo.defaultAvatarColor}
                                userInitials={`${info.row.original.givenTo.firstName?.slice(
                                    0,
                                    1,
                                )}${info.row.original.givenTo.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <span className="group-hover:text-blue">
                                {info.row.original.givenTo.lastName + " " + info.row.original.givenTo.firstName}
                            </span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Выдана раз",
                accessorKey: "quantity",
                cell: (info) => info.row.original.quantity,
                footer: (props) => props.column.id,
            },
            {
                header: "Последний раз выдана",
                accessorKey: "lastGivenAt",
                enableResizing: true,
                size: 250,
                cell: (info) => {
                    return formatDateTime(info.row.original.lastGivenAt);
                },
                footer: (props) => props.column.id,
            },
            {
                header: "",
                id: "buttons",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 30,
                cell: (info) => {
                    return (
                        <Button
                            shape="round"
                            color="common"
                            icon={
                                <Icon
                                    icon={Icons.Close}
                                    width={18}
                                    height={18}
                                    color="fill-blue-drk hover:fill-blue-hover"
                                />
                            }
                            iconPlacement="center"
                            onClick={() => {
                                setSelectedUser(info.row.original.givenTo);
                                setIsRemoveAchievementModalOpen(true);
                            }}
                        />
                    );
                },
            },
        ],
        [],
    );
    const controlButtons = (
        <Button
            className="rounded-lg whitespace-nowrap font-medium"
            size="medium"
            icon={<Icon className="mr-1.5" icon={Icons.PlusFilled} color="fill-white" width={17} height={17} />}
            iconPlacement="left"
            onClick={onOpenAchievementModal}
            id="adminSettingsNewAchievementGive"
        >
            Выдать ачивку
        </Button>
    );

    return (
        <div className="h-fit" id="SettingsAchievementUsersTable">
            <AddAchievementModal
                id="SettingsAchievementAddModal"
                isOpen={isAddAchievementModalOpen}
                setIsOpen={setIsAddAchievementModalOpen}
                achievement={achievement}
                offices={offices}
                jobs={jobs}
                achievements={achievements}
                fetch={fetchUsers}
                onAdd={onAdd}
            />
            <RemoveAchievementModal
                id="SettingsAchievementRemoveModal"
                isOpen={isRemoveAchievementModalOpen}
                setIsOpen={setIsRemoveAchievementModalOpen}
                achievementId={achievement.id}
                userId={selectedUser ? selectedUser.id : ""}
                userFirstName={selectedUser ? selectedUser.firstName : ""}
                userLastName={selectedUser ? selectedUser.lastName : ""}
                fetchUserAchievement={fetchUserAchievement}
                onRemove={onRemove}
            />
            {!dataQuery.isFetching && dataQuery.data?.Content.length === 0 && (
                <Empty
                    title="Ачивка никому не выдана"
                    description="Назначьте ачивку на пользователей. Это повысит их интерес к обучению"
                >
                    <Button
                        className="rounded-lg whitespace-nowrap font-medium"
                        size="medium"
                        icon={
                            <Icon
                                className="mr-1.5"
                                icon={Icons.PlusFilled}
                                color="fill-white"
                                width={17}
                                height={17}
                            />
                        }
                        iconPlacement="left"
                        onClick={onOpenAchievementModal}
                        id="adminSettingsNewAchievementGive"
                    >
                        Выдать ачивку
                    </Button>
                </Empty>
            )}
            {!dataQuery.isFetching && dataQuery.data?.Content.length !== 0 && (
                <Table
                    columns={columns}
                    id="adminSettingsNewAchievementTable"
                    controlButtons={controlButtons}
                    pageCount={dataQuery.data?.TotalPages}
                    emptyMessage="По заданным параметрам результатов нет"
                    searchTitle="Поиск по названию"
                    isFetching={dataQuery.isFetching}
                    data={dataQuery.data?.Content}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    pagination={pagination}
                    onPaginationChange={setPagination}
                    rowSelection={rowSelection}
                    onRowSelectionChange={setRowSelection}
                />
            )}
        </div>
    );
};
