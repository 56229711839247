import React from "react";
import { useNavigate } from "react-router-dom";
import { TrainingCardMobile } from "./TrainingCardMobile";
import { UiCoursesCategoryListItem, UiCoursesList } from "Api/Responses/CourseResponse";

interface ITrainingCardListMobile {
    id?: string;
    title?: string;
    data: UiCoursesCategoryListItem[] | UiCoursesList[];
    showStatusChangeTime: boolean;
}
export const TrainingCardListMobile = ({ id, title, data, showStatusChangeTime = false }: ITrainingCardListMobile) => {
    const navigate = useNavigate();

    return (
        <div className="mb-7" key={id}>
            {title && (
                <h3 id={id + "Title"} className="mb-4 text-black">
                    {title}
                </h3>
            )}
            {data.map((course) => {
                return (
                    <TrainingCardMobile
                        key={course.resourceId}
                        course={course}
                        showStatusChangeTime={showStatusChangeTime}
                        onClick={() => navigate(`/training/course/${course.resourceId}`)}
                    />
                );
            })}
        </div>
    );
};
