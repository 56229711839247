/**
 * Highlight the substring background color in string
 * @param {string} str - Original string
 * @param {string} substring - Substring to be modified
 * @param {string} color - Background color of substring
 */
export const highlightSubstring = (str = "", substring = "", color = "bg-input-stroke text-black") => {
    const regExp = new RegExp(substring.replace(/([ёе])/gi, "([ёе])"), "gi");
    const isFind = regExp.test(str);

    return {
        find: isFind,
        text: isFind ? String(str).replace(regExp, `<span class="${color}">$&</span>`) : str,
    };
};
