import React, { ReactNode } from "react";
import { Modal, SubmitButton, SecondaryButton } from "../";
import { ButtonProps } from "../Button/Button";
import { TVoidFunction } from "types";

interface PromptModalProps extends Pick<ButtonProps, "color"> {
    title?: string;
    children: ReactNode;
    isLoading?: boolean;
    dialogText?: [string, string];
    onConfirm: TVoidFunction;
    onClose: TVoidFunction;
    isOpen: boolean;
}

export const PromptModal = ({
    title = "",
    isOpen,
    color = "danger",
    isLoading = false,
    dialogText = ["Отмена", "Подтверждаю"],
    children,
    onClose,
    onConfirm,
}: PromptModalProps) => {
    const [cancelText, confirmText] = dialogText;
    return (
        <Modal isOpen={isOpen} onClose={onClose} setIsOpen={() => {}} title={title}>
            <Modal.Body>{children}</Modal.Body>

            <Modal.Footer>
                <fieldset className="flex justify-end space-x-4">
                    <SecondaryButton onClick={onClose} id="promptModalBtnCancel">
                        {cancelText}
                    </SecondaryButton>

                    <SubmitButton
                        type="button"
                        color={color}
                        isLoading={isLoading}
                        onClick={onConfirm}
                        id="promptModalBtnOk"
                    >
                        {confirmText}
                    </SubmitButton>
                </fieldset>
            </Modal.Footer>
        </Modal>
    );
};
