import React from "react";
import { Button, ButtonProps } from "./Button";
import clsx from "clsx";

interface SubmitButtonProps extends ButtonProps {
    isLoading?: boolean;
}

const loadingColors: any = {
    primary: "!bg-primary-light",
    secondary: "!bg-secondary-light",
    success: "!bg-success-light",
    warning: "!bg-warning-light",
    caution: "!bg-caution-100",
    danger: "!bg-danger-100",
};

export const SubmitButton = ({ color = "primary", isLoading, className, children, ...rest }: SubmitButtonProps) => {
    return (
        <Button
            color={color}
            disabled={isLoading}
            className={clsx(className, "relative", isLoading && loadingColors[color])}
            {...rest}
        >
            <span className={clsx(isLoading ? "flex-shrink flex-grow opacity-0" : "opacity-100")}>{children}</span>
        </Button>
    );
};
