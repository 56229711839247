import React, { ReactNode, useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";
import { Tooltip } from "Uikit/Tooltip";

interface OneLineClampWithTooltipProps {
    children: ReactNode;
    tooltip: string;
}

export const OneLineClampWithTooltip = ({ children, tooltip }: OneLineClampWithTooltipProps) => {
    const [clamp, setClamp] = useState(false);
    const [setRef, { width }] = useElementSize();
    const [element, setElement] = useState<HTMLDivElement | null>();

    useEffect(() => {
        if (element) {
            if (width < (element.children?.item(1)?.scrollWidth ?? 0)) {
                setClamp(true);
            } else {
                setClamp(false);
            }
        }
    }, [element, width]);

    return (
        <div className="overflow-hidden">
            <Tooltip disabled={!clamp} content={tooltip}>
                <div
                    ref={(ref) => {
                        setRef(ref);
                        setElement(ref);
                    }}
                    className="flex line-clamp-1"
                >
                    {children}
                </div>
            </Tooltip>
        </div>
    );
};
