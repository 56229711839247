import React, { Fragment } from "react";
import { TeamUserAvatar } from "./TeamUserAvatar";

interface ITeamUser {
    id: string;
    avatarId: string;
    defaultAvatarColor: string;
    firstName: string;
    lastName: string;
}
interface ITeamUsersProps {
    usersCount: number;
    users: ITeamUser[];
    fullListLength: number;
    label?: string;
    limit?: number;
    variant?: "normal" | "large";
    avatarsListClass?: string;
}
export const TeamUsers = ({
    usersCount,
    users,
    label,
    fullListLength,
    avatarsListClass,
    variant = "normal",
    limit = 3,
}: ITeamUsersProps) => {
    const sizes = {
        normal: "min-w-5 2xl:min-w-6.25 h-5 2xl:h-6.25 px-1 rounded-[10px] 2xl:rounded-full",
        large: "min-w-9 2xl:min-w-[45px] h-9 2xl:h-[45px] px-3 rounded-[18px] 2xl:rounded-full",
    };

    return (
        <div className="flex gap-2.5 2xl:gap-3 items-center">
            {users.length > 0 && (
                <div className={`flex items-center ${avatarsListClass ?? ""}`}>
                    {users.map(({ id, avatarId, defaultAvatarColor, firstName, lastName }, index) => {
                        const Avatar = (
                            <TeamUserAvatar
                                avatarId={avatarId}
                                defaultAvatarColor={defaultAvatarColor}
                                firstName={firstName}
                                lastName={lastName}
                                size={variant}
                            />
                        );

                        return (
                            <Fragment key={id}>
                                {index <= limit - 2 && <>{Avatar}</>}
                                {index === limit - 1 && (
                                    <>
                                        {usersCount <= limit && <>{Avatar}</>}
                                        {!(usersCount <= limit) && (
                                            <div
                                                className={`text-white flex-center bg-blue-dark outline outline-1 outline-white text-xxs 2xl:text-sm ${sizes[variant]}`}
                                            >
                                                <p>+{fullListLength - (limit - 1)}</p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            )}
            {label && (
                <p
                    className={`text-blue-dark leading-4.5 2xl:text-md 2xl:leading-6 ${
                        usersCount <= limit ? "ml-1.5 2xl:ml-2" : ""
                    }`}
                >
                    {fullListLength} {label}
                </p>
            )}
        </div>
    );
};
