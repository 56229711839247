import React, { useContext, useEffect, useState } from "react";
import { useInvalidate } from "hooks/useInvalidate";
import { SortingState } from "@tanstack/react-table";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Button, Content, flash, Icon, Icons, Tabs, TabsWrapper, Toggle } from "Uikit";
import { TreeWrapperContext } from "Containers/TreeWrapper/TreeWrapperContext";
import { AchievementSettings } from "./AchievementSettings";
import { AchievementContent } from "./AchievementContent";
import { AchievementRequest } from "Api/Requests/AchievementRequest";
import Api from "Api";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { useDialog } from "hooks/useDialog";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { FileUploadType } from "Enums";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { IOption } from "types";

export interface IAchievement {
    isEdit?: boolean;
}
export const Achievement = ({ isEdit }: IAchievement) => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();

    const [isChanged, setIsChanged] = useState(false);
    const [isToggleChanged, setIsToggleChanged] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const { dialogState, openDialog, closeDialog } = useDialog();

    const { setTreeProps } = useContext(TreeWrapperContext);
    const { id } = useParams();

    const [initTitle, setInitTitle] = useState("Новая ачивка");

    const [logo, setLogo] = useState<File | undefined>(undefined);
    const [achievement, setAchievement] = useState<AchievementRequest>({
        id: "",
        logoId: "",
        name: "",
        rating: "",
        maxQuantity: null,
        enabled: true,
        userProfileAssignmentDisabled: false,
        modifyTimestamp: 0,
    });

    const [offices, setOffices] = useState<any>([]);
    const [jobOptions, setJobOptions] = useState<IOption[]>([]);

    const fetchAchievementUsers = async (page: number, size: number, sort: SortingState) => {
        return await Api.Achievement.Users(id!, page, size, sort);
    };
    const fetchUsers = async (page: number, size: number, sort: SortingState, filters: any) => {
        return await Api.User.GetList(page, size, sort, filters);
    };
    const fetchUserAchievement = async (achievementId: string, userId: string, sort: SortingState) => {
        return await Api.Achievement.UserList(userId, 0, 2000, sort, { "achievementId.equal": achievementId });
    };

    const onChangeLogo = (data: File, image: string) => {
        setLogo(data);
        setAchievement({ ...achievement, logoId: image });
        setIsChanged(true);
    };

    const onValidate = () => {
        const errors: any = {};

        errors["logoId"] = !achievement.logoId ? "Поле обязательно для заполнения" : undefined;
        errors["name"] = !achievement.name ? "Поле обязательно для заполнения" : undefined;
        errors["maxQuantity"] =
            achievement.maxQuantity != null && !achievement.maxQuantity ? "Поле обязательно для заполнения" : undefined;
        errors["rating"] = undefined;

        if (!achievement.rating) {
            errors["rating"] = "Поле обязательно для заполнения";
        } else if (isNaN(+achievement.rating)) {
            errors["rating"] = "Введите количество баллов";
        }

        if (Object.keys(errors).filter((p) => errors[p]).length !== 0) {
            setErrors(errors);
            return false;
        }

        setErrors({});
        return true;
    };

    const onSave = async () => {
        if (!onValidate()) {
            return;
        }

        if (logo) {
            const uploadFileResponse = await Api.File.UploadFile(
                logo,
                undefined,
                undefined,
                false,
                FileUploadType.ACHIEVEMENT_LOGO,
            );
            achievement.logoId = uploadFileResponse.id;
        }

        if (!isToggleChanged) {
            await onSaveHandler();
        } else {
            openDialog({
                title: `Изменить статус на ${achievement.enabled ? "«Выдача разрешена»" : "«Выдача запрещена»"}`,
                content: achievement.enabled
                    ? `Всем пользователям у кого есть доступ можно будет выдать ачивку ${achievement.name}`
                    : `Ачивка ${achievement.name} станет недоступна для выдачи всем пользователям. Ранее выданные ачивки не изменятся`,
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onSaveHandler();
                    closeDialog();
                },
            });
        }
    };

    const onSaveHandler = async () => {
        try {
            if (achievement.id) {
                await Api.Achievement.Edit(achievement);

                flash.success("Ачивка успешно сохранена!");
                navigate(`/admin/settings/achievement/${achievement.id}`);
            } else {
                const response = await Api.Achievement.Create(achievement);
                setAchievement({ ...achievement, id: response.id });

                flash.success("Ачивка успешно создана!");
                navigate(`/admin/settings/achievement/${response.id}`);
            }

            setInitTitle(achievement.name);
            setIsChanged(false);
            setIsToggleChanged(false);
        } catch (error) {
            if (error instanceof BadRequestResponse) {
                console.log("BAD request response");
                if (error.errorCode === ErrorCode.ACHIEVEMENT) {
                    flash.error("Ачивка с таким названием уже существует.");
                } else {
                    console.log(error);
                }
            } else {
                console.log("IS not BadRequestResponse");
            }
        }
    };

    const onAdd = async (users: string[], comment: string) => {
        try {
            await Api.Achievement.UserCreate({ achievementId: id!, userIds: users, comment: comment });

            invalidate("achievementUsers");
            flash.success("Ачивка успешно выдана!");
        } catch {
            flash.error("Ачивка не может быть выдана!");
        }
    };
    const onRemove = async (userId: string, achievementIds: string[]) => {
        try {
            await Api.Achievement.UserRemove({ userId, achievementIds });

            invalidate("achievementUsers");
            flash.success("Ачивка успешно удалена!");
        } catch {
            flash.error("Ачивка не может быть удалена!");
        }
    };

    useEffect(() => {
        if (!id) {
            return;
        }

        const fetch = async () => {
            const response = await Api.Achievement.Read(id);

            setAchievement({
                id: response.id,
                logoId: response.logoId,
                name: response.name,
                rating: response.rating,
                maxQuantity: response.maxQuantity === "-1" ? null : response.maxQuantity,
                enabled: response.enabled,
                userProfileAssignmentDisabled: response.userProfileAssignmentDisabled,
                modifyTimestamp: response.modifyTimestamp,
            });
            setInitTitle(response.name);
        };

        fetch().then();
    }, [id]);

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps(undefined);
    }, [setTreeProps]);

    useEffect(() => {
        const fetch = async () => {
            const offices = await Api.Office.List();
            setOffices(
                offices.Content.map((p) => {
                    return { label: p.name, value: p.id };
                }),
            );

            const jobs = await Api.Job.GetJobs();
            const collator = new Intl.Collator("en");
            const specials = ["-", "–", "—", "(", ")", "#", "№", "&", "_", "+", "/"];

            setJobOptions(
                jobs.Content.map((p) => ({ label: p.name, value: p.id! })).sort((a: any, b: any) => {
                    if (specials.includes(a.label[0])) {
                        return -1;
                    } else if (specials.includes(b.label[0])) {
                        return 1;
                    }

                    if (!isNaN(Number(a.label))) {
                        return -1;
                    } else if (!isNaN(Number(b.label))) {
                        return 1;
                    }

                    if (!isNaN(a.label) && !isNaN(b.label)) {
                        const aNum = Number(a.label);
                        const bNum = Number(b.label);
                        if (aNum < bNum) {
                            return -1;
                        } else if (aNum > bNum) {
                            return 1;
                        }
                    }

                    return collator.compare(a.label, b.label);
                }),
            );
        };
        fetch().then();
    }, []);

    const handleChangeAchieveEnabled = () => {
        setAchievement((achievement) => ({ ...achievement, enabled: !achievement.enabled }));
        setIsChanged(true);
        setIsToggleChanged((isToggleChanged) => !isToggleChanged);
    };

    return (
        <div className="w-full h-full px-4 pb-4" id="SettingsAchievementPage">
            <Breadcrumbs className="mb-3" id="adminSettingsNewAchievementBreadcrumbs">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Настройки" />
                <Breadcrumbs.Link title="Ачивки" url="/admin/settings/achievements" />
                <Breadcrumbs.Link title={initTitle} />
            </Breadcrumbs>
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-black" id="adminSettingsNewAchievementTitle">
                    {initTitle}
                </h1>
                <div className="flex items-center">
                    <Toggle
                        className="mr-7.5"
                        label={achievement.enabled ? "Выдача разрешена" : "Выдача запрещена"}
                        enabled={achievement.enabled}
                        onChange={handleChangeAchieveEnabled}
                        id="adminSettingsNewAchievementToggleCommit"
                    />
                    {(isChanged || isEdit || !achievement.id) && (
                        <Button
                            className="mr-4"
                            variant="outline"
                            color="secondary"
                            onClick={() => navigate("/admin/settings/achievements")}
                            id="adminSettingsNewAchievementBtnCancel"
                        >
                            Отменить
                        </Button>
                    )}
                    {(isChanged || isEdit || !achievement.id) && (
                        <Button className="mr-4" onClick={onSave} id="adminSettingsNewAchievementBtnOk">
                            Сохранить
                        </Button>
                    )}
                    {!isChanged && !isEdit && achievement.id && (
                        <Button
                            className="w-10 h-10 !p-0"
                            variant="outline"
                            color="secondary"
                            onClick={() => navigate("/admin/settings/achievements")}
                            id="adminSettingsNewAchievementBtn"
                        >
                            <Icon icon={Icons.Close} width={24} height={24} color="fill-[#939393]" />
                        </Button>
                    )}
                </div>
            </div>
            <TabsWrapper className="h-fit">
                <TabsWrapper.Tabs
                    classname="flex flex-grow justify-between items-center space-x-4 h-max"
                    label={
                        achievement.modifyTimestamp
                            ? `Обновлен ${formatDate(achievement.modifyTimestamp * 1000, DateFormat.DATE_TIME_LONG)}`
                            : ""
                    }
                    id="adminSettingsNewAchievementTabs"
                >
                    <Tabs.Tab title="Настройки" error={errors["name"] || errors["rating"]} />
                    <Tabs.Tab
                        title="Выдана"
                        disabled={isChanged || isEdit || !achievement.id}
                        tooltip={isChanged || isEdit || !achievement.id ? "Будет доступно после сохранения" : ""}
                    />
                    {/*<Tabs.Tab*/}
                    {/*    title="Доступ"*/}
                    {/*    disabled={isChanged || isEdit || !achievement.id}*/}
                    {/*    tooltip={isChanged || isEdit || !achievement.id ? "Будет доступно после сохранения" : ""}*/}
                    {/*/>*/}
                </TabsWrapper.Tabs>
                <TabsWrapper.Content>
                    <Content.Body className="h-fit">
                        <AchievementSettings
                            errors={errors}
                            achievement={achievement}
                            onChange={(p) => {
                                setIsChanged(true);
                                setAchievement(p);
                            }}
                            onChangeLogo={onChangeLogo}
                        />
                    </Content.Body>
                    <Content.Body className="mt-6 h-fit">
                        <AchievementContent
                            achievement={achievement}
                            offices={offices}
                            jobs={jobOptions}
                            fetch={fetchAchievementUsers}
                            fetchUsers={fetchUsers}
                            fetchUserAchievement={fetchUserAchievement}
                            onAdd={onAdd}
                            onRemove={onRemove}
                        />
                    </Content.Body>
                    {/*<Content.Body className="mt-6 h-fit">*/}
                    {/*    <Access resourceId={id} showProgressFilter={false} />*/}
                    {/*</Content.Body>*/}
                </TabsWrapper.Content>
            </TabsWrapper>
            <Confirmation {...dialogState} />
        </div>
    );
};
