import React, { ReactNode } from "react";
import { Button } from "./Button";
import { Label } from "../Label";
import clsx from "clsx";

interface RadioGroupProps {
    children?: ReactNode;
    label?: string;
    onChange?: (value: any) => void;
    value?: ReactNode;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    listClassName?: string;
}

export const RadioGroup = ({
    children,
    className,
    label,
    value,
    disabled,
    readOnly = false,
    onChange,
    listClassName,
}: RadioGroupProps) => {
    return (
        <div className={clsx("w-full", className)}>
            {label ? <Label className={"pb-4 text-[#939292]"}>{label}</Label> : null}
            <ul
                className={clsx(
                    "w-full space-y-4 2xl:space-y-5 font-medium text-gray-900 bg-white rounded-lg",
                    listClassName,
                )}
            >
                {React.Children.map(children, (radio) => {
                    if (React.isValidElement(radio)) {
                        return React.cloneElement(radio, {
                            ...radio.props,
                            disabled: disabled,
                            checked: value === radio.props.value,
                            onChange: onChange,
                            readOnly,
                        });
                    }

                    return radio;
                })}
            </ul>
        </div>
    );
};

RadioGroup.Button = Button;
