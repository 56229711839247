import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Icons, Icon } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import Api from "Api";
import { TeamsList } from "./TeamsList";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { useDispatch } from "react-redux";
import { Preloader } from "Components/Preloader/Preloader";
import Skeleton from "react-loading-skeleton";

export const Teams = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data, isFetching } = useQuery(
        ["teams", "tree", "current-user", "collection"],
        async () => {
            return await Api.Teams.TreeCurrentUser();
        },
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const onCardClick = (id: string) => {
        navigate(`/team/${id}`);
    };

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Мои команды"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    return (
        <div className="flex flex-wrap justify-center min-h-full">
            <div className="flex flex-col sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] w-full">
                <Breadcrumbs className="hidden sm:block" id="teamsBreadcrumbs">
                    <Breadcrumbs.Link title="Команда" url="" />
                </Breadcrumbs>
                <div className="relative">
                    <Preloader className="flex flex-col" isShow={isFetching}>
                        <div className="mb-4 w-full h-7.5 rounded-2xl overflow-hidden leading-0">
                            <Skeleton className="rounded-2xl" width="100%" height="100%" />
                        </div>
                        <div className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] gap-y-5 sm:gap-x-6.5 2xl:gap-x-8 sm:gap-y-9">
                            {Array.from(Array(16).keys()).map((p) => {
                                return (
                                    <div
                                        key={p}
                                        className="w-60 h-41 sm:w-[274px] 2xl:w-[342px] sm:h-35 2xl:h-[175px] rounded-2xl overflow-hidden leading-0"
                                    >
                                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                    </div>
                                );
                            })}
                        </div>
                    </Preloader>
                </div>
                {!isFetching && data?.teams?.length === 0 && data?.mentorTeams?.length === 0 && (
                    <Empty
                        topElement={
                            <div className="flex-center mb-4 2xl:mb-5">
                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.Users}
                                        width={"36px"}
                                        height={"36px"}
                                        color={"fill-primary"}
                                        className="2xl:!w-11.25 2xl:!h-11.25"
                                    />
                                </div>
                            </div>
                        }
                        title="Вы не состоите ни в одной команде"
                        description={
                            <span>
                                К сожалению сейчас вы не состоите ни в одной команде.
                                <br />
                                Обратитесь к администратору, чтоб он добавил вас к команде.
                            </span>
                        }
                    ></Empty>
                )}
                {!isFetching && (data?.teams?.length !== 0 || data?.mentorTeams?.length !== 0) && (
                    <div className="flex flex-col gap-10 sm:gap-12 2xl:gap-15 px-3 sm:px-0 sm:pb-20">
                        {data?.teams && data?.teams?.length !== 0 && (
                            <TeamsList title="Мои команды" teams={data?.teams} onClick={onCardClick} />
                        )}
                        {data?.mentorTeams && data?.mentorTeams?.length !== 0 && (
                            <TeamsList title="Я наставляю" teams={data?.mentorTeams} onClick={onCardClick} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
