import { Expose, Type } from "class-transformer";
import { ProgressStatus, ResourceType, TestQuestionType } from "Enums";
import { BasePaginationResponse, BaseResponse } from "../BaseResponse";

export class StatisticResponse {
    @Expose()
    @Type(() => Boolean)
    isMentor: boolean;

    @Expose()
    @Type(() => LastActivityStatistics)
    lastActivityStatistics: LastActivityStatistics;

    @Expose()
    @Type(() => TrainingProgramStatistics)
    trainingProgramStatistics: TrainingProgramStatistics;

    @Expose()
    @Type(() => CourseStatistics)
    courseStatistics: CourseStatistics;

    @Expose()
    @Type(() => QuizStatistics)
    quizStatistics: QuizStatistics;
}

export class LastActivityStatistics {
    @Expose()
    @Type(() => Number)
    certificatesCount: number;

    @Expose()
    @Type(() => Number)
    materialsPassedCount: number;

    @Expose()
    @Type(() => Number)
    rating: number;
}

export class TrainingProgramStatistics {
    @Expose()
    @Type(() => Number)
    inProgressCount: number;

    @Expose()
    @Type(() => Number)
    passedCount: number;

    @Expose()
    @Type(() => Number)
    totalCount: number;

    @Expose()
    @Type(() => Number)
    availableCount: number;
}

export class CourseStatistics {
    @Expose()
    @Type(() => Number)
    totalCount: number;

    @Expose()
    @Type(() => Number)
    passedCount: number;

    @Expose()
    @Type(() => Number)
    inProgressCount: number;

    @Expose()
    @Type(() => Number)
    availableCount: number;
}

export class QuizStatistics {
    @Expose()
    @Type(() => Number)
    failedCount: number;

    @Expose()
    @Type(() => Number)
    passedCount: number;

    @Expose()
    @Type(() => Number)
    totalCount: number;

    @Expose()
    @Type(() => Number)
    availableCount: number;
}

export class Achievement {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => String)
    logoId: string;
}

export class JobTitle {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => Number)
    userCount: number;
}

class DataItemUser {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    login: string;

    @Expose()
    @Type(() => String)
    avatarId: string;

    @Expose()
    @Type(() => String)
    firstName: string;

    @Expose()
    @Type(() => String)
    lastName: string;

    @Expose()
    @Type(() => String)
    middleName: string;

    @Expose()
    @Type(() => String)
    defaultAvatarColor: string;

    @Expose()
    @Type(() => String)
    state: string;

    @Expose()
    @Type(() => String)
    role: string;

    @Expose()
    @Type(() => JobTitle)
    jobTitle: JobTitle;
}

class DataItemCourse {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => String)
    logoId: string;
}

export class StatisticCourseResponseData {
    @Expose()
    @Type(() => DataItemUser)
    user: DataItemUser;

    @Expose()
    @Type(() => DataItemCourse)
    course: DataItemCourse;

    @Expose()
    @Type(() => String)
    status: ProgressStatus;

    @Expose()
    @Type(() => Number)
    progress: number;

    @Expose()
    @Type(() => Number)
    points: number;
}

export class StatisticCourseResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    reportId: string;

    @Expose()
    @Type(() => BasePaginationResponse)
    data: BasePaginationResponse<StatisticCourseResponseData>;
}

export class ProgressItem {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    resourceId?: string;

    @Expose()
    @Type(() => String)
    userResourceId?: string;

    @Expose()
    @Type(() => String)
    title: string;

    @Expose()
    @Type(() => String)
    logoId: string;

    @Expose()
    @Type(() => String)
    type: string;

    @Expose()
    @Type(() => String)
    state: string;

    @Expose()
    @Type(() => Number)
    rating?: number;

    @Expose()
    @Type(() => String)
    progressStatus: string;

    @Expose()
    @Type(() => Number)
    passingNumber: number;

    @Expose()
    @Type(() => Number)
    progressPercentage: number;

    @Expose()
    @Type(() => Boolean)
    isFailedDeadlineTime?: boolean;

    @Expose()
    @Type(() => Number)
    lastActivityTime: number;

    get progressString() {
        switch (this.progressStatus) {
            case ProgressStatus.ALL:
                return "Любой";
            case ProgressStatus.NOT_STARTED:
                return "Не начато";
            case ProgressStatus.ON_REVIEW:
                return "На проверке";
            case ProgressStatus.FAILED:
                return this.type === ResourceType.QUIZ ? "Провален" : "Отклонён`";
            case ProgressStatus.PASSED:
                return this.type === ResourceType.PROGRAM ? "Пройдена" : "Пройден";
            case ProgressStatus.IN_PROGRESS: {
                // let percent = "";

                // if (this.type === ResourceType.EXERCISE) {
                //     percent = ` (${
                //         Number.isInteger(this.progressPercentage)
                //             ? this.progressPercentage.toFixed(1)
                //             : this.progressPercentage ?? 0
                //     }%)`;
                // }

                return `В процессе`;
            }
            case ProgressStatus.RE_WORK:
                return "Требует перепрохождения";
            case ProgressStatus.BLOCKED:
                return "Заблокировано";
            default:
                return this.progressStatus;
        }
    }

    get typeString() {
        switch (this.type) {
            case ResourceType.QUIZ:
                return "Тест";
            case ResourceType.POLL:
                return "Опрос";
            case ResourceType.EXERCISE:
                return "Задание";
            case ResourceType.COURSE:
                return "Курс";
            case ResourceType.PROGRAM:
                return "Программа";
            case ResourceType.VIDEO:
                return "Видео";
            case ResourceType.DOCUMENT:
                return "Документ";
            case ResourceType.SCORM:
                return "Курс";
            case ResourceType.HTML:
                return "Html";
            case ResourceType.ARTICLE:
                return "Статья";
            case ResourceType.NEWS:
                return "Новость";
            default:
                return this.type;
        }
    }
}

class DataContentItemTestName {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => String)
    logoId: string;
}

export class StatisticTestResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    reportId: string;

    @Expose()
    @Type(() => BasePaginationResponse)
    data: BasePaginationResponse<StatisticTestResponseData>;
}

export class StatisticTestResponseData {
    @Expose()
    @Type(() => DataItemUser)
    user: DataItemUser;

    @Expose()
    @Type(() => DataContentItemTestName)
    quiz: DataContentItemTestName;

    @Expose()
    @Type(() => String)
    status: string;

    @Expose()
    @Type(() => Number)
    progress: number;

    @Expose()
    @Type(() => Number)
    rating: number;

    @Expose()
    @Type(() => Number)
    startTime: number;

    @Expose()
    @Type(() => Number)
    completionTime: number;

    @Expose()
    @Type(() => Number)
    attemptNumber: number;

    @Expose()
    @Type(() => Number)
    maxAttempts: number | null;
}

export class StatisticTaskResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    reportId: string;

    @Expose()
    @Type(() => BasePaginationResponse)
    data: BasePaginationResponse<StatisticTaskResponseData>;
}

export class StatisticTaskResponseData {
    @Expose()
    @Type(() => DataItemUser)
    user: DataItemUser;

    @Expose()
    @Type(() => DataContentItemTaskName)
    exercise: DataContentItemTaskName;

    @Expose()
    @Type(() => String)
    status: string;

    @Expose()
    @Type(() => Number)
    progress: number;

    @Expose()
    @Type(() => Number)
    rating: number;

    @Expose()
    @Type(() => Number)
    startTime: number;

    @Expose()
    @Type(() => Number)
    completionTime: number;

    @Expose()
    @Type(() => Number)
    passingNumber: number;

    @Expose()
    @Type(() => Number)
    passingLimit: number | null;

    @Expose()
    @Type(() => Number)
    attemptNumber: number;
}

class DataContentItemTaskName {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => String)
    logoId: string;
}

export class StatisticMaterialResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    reportId: string;

    @Expose()
    @Type(() => BasePaginationResponse)
    data: BasePaginationResponse<StatisticMaterialResponseData>;
}

export class StatisticMaterialResponseData {
    @Expose()
    @Type(() => DataItemUser)
    user: DataItemUser;

    @Expose()
    @Type(() => DataContentItemMaterialName)
    material: DataContentItemMaterialName;

    @Expose()
    @Type(() => String)
    status: ProgressStatus;

    @Expose()
    @Type(() => Number)
    progress: number;

    @Expose()
    @Type(() => Number)
    rating: number;

    @Expose()
    @Type(() => Number)
    startTime: number;

    @Expose()
    @Type(() => Number)
    completionTime: number;

    @Expose()
    @Type(() => Number)
    attemptNumber: number;

    @Expose()
    @Type(() => Number)
    maxAttempts: number | null;

    @Expose()
    @Type(() => String)
    materialType: string;
}

class DataContentItemMaterialName {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => String)
    logoId: string;
}

export class StatisticProgramResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    reportId: string;

    @Expose()
    @Type(() => BasePaginationResponse)
    data: BasePaginationResponse<StatisticProgramResponseData>;
}

export class StatisticProgramResponseData {
    @Expose()
    @Type(() => DataItemUser)
    user: DataItemUser;

    @Expose()
    @Type(() => DataItemProgram)
    program: DataItemProgram;

    @Expose()
    @Type(() => String)
    status: ProgressStatus;

    @Expose()
    @Type(() => Number)
    progress: number;

    @Expose()
    @Type(() => Number)
    points: number;
}

class DataItemProgram {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => String)
    logoId: string;
}

export class StatisticTestTeamsResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    reportId: string;

    @Expose()
    @Type(() => BasePaginationResponse)
    data: BasePaginationResponse<StatisticTestTeamsResponseData>;
}

export class StatisticTestTeamsResponseData {
    @Expose()
    @Type(() => TeamItemTest)
    team: TeamItemTest;

    @Expose()
    @Type(() => Number)
    memberCount: number;

    @Expose()
    @Type(() => Number)
    passedCount: number;

    @Expose()
    @Type(() => Number)
    failedCount: number;

    @Expose()
    @Type(() => Number)
    notStartedCount: number;
}

class TeamItemTest {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;
}

export class StatisticTestAnswersResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    reportId: string;

    @Expose()
    @Type(() => BasePaginationResponse)
    data: BasePaginationResponse<StatisticTestAnswersResponseData>;
}

export class StatisticTestAnswersResponseData {
    @Expose()
    @Type(() => QuestionItemTest)
    question: QuestionItemTest;

    @Expose()
    @Type(() => Number)
    totalCount: number;

    @Expose()
    @Type(() => Number)
    passedCount: number;

    @Expose()
    @Type(() => Number)
    passPercentage: number;
}

class QuestionItemTest {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    logoId: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => String)
    questionType: TestQuestionType;
}
