import { SearchListResponse, SearchMultiResponse } from "Api/Responses/SearchResponse";
import BaseApi from "../BaseApi";
import { SearchRequest } from "Api/Requests/SearchRequest";

export default class SearchApi extends BaseApi {
    PublicSearchMulti = async (data: SearchRequest): Promise<SearchMultiResponse> => {
        return await this.Post(new SearchMultiResponse(), "/user-resource/search/multi", data);
    };

    PublicSearchList = async (data: SearchRequest): Promise<SearchListResponse> => {
        return await this.Post(new SearchListResponse(), "/user-resource/search/list", data);
    };

    SearchMulti = async (data: SearchRequest): Promise<SearchMultiResponse> => {
        return await this.Post(new SearchMultiResponse(), "/resource/search/multi", data);
    };

    SearchList = async (data: SearchRequest): Promise<SearchListResponse> => {
        return await this.Post(new SearchListResponse(), "/resource/search/list", data);
    };
}
