import React from "react";
import { MultiValueRemoveProps } from "react-select";
import { Icon, Icons } from "Uikit";
import { useScreenSize } from "hooks/useMediaQuery";

export interface IMultiLabelOption {
    readonly value: string;
    readonly label: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

// Кастомный компонент для react-select
// Отображение кнопки удаления выбранного значения в мультиселекте
export const SelectMultiValueRemove = (props: MultiValueRemoveProps<IMultiLabelOption>) => {
    const { size } = useScreenSize();
    const isLarge = size === "large";

    return (
        <div
            {...props.innerProps}
            className={`${props.innerProps.className} ui-combo-box__multi-value__remove--transparent group flex-center cursor-pointer !hover:bg-transparent px-0.5`}
        >
            <Icon
                className="!group-hover:fill-white"
                icon={Icons.Close}
                width={isLarge ? 30 : 24}
                height={isLarge ? 30 : 24}
            />
        </div>
    );
};
