import React, { ReactNode } from "react";
import { Icon } from "Uikit/Icon/Icon";
import clsx from "clsx";

const colors = {
    gray: "bg-gray",
    blue: "bg-blue",
    red: "bg-red",
    "blue-50": "bg-blue-50",
};

interface BadgeProps {
    color?: keyof typeof colors;
    icon?: React.ReactElement;
    size?: "small" | "normal" | "large";
    children: ReactNode;
    classNames?: {
        icon?: string;
    };
}

export const Badge = ({ color = "blue", icon, size = "normal", children, classNames }: BadgeProps) => {
    return (
        <div
            className={clsx(
                "items-center inline-flex space-x-1 2xl:space-x-1.25 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 rounded-md 2xl:rounded-lg h-5.25 2xl:h-6.5",
                colors[color],
            )}
        >
            {icon && (
                <Icon icon={icon} width={iconSizes[size]} height={iconSizes[size]} className={clsx(classNames?.icon)} />
            )}

            <div className="flex items-center p3 2xl:text-2sm text-white">{children}</div>
        </div>
    );
};

const iconSizes = {
    small: 11,
    normal: 14,
    large: 15,
};
