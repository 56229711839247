import { Expose, Type } from "class-transformer";

export class UserAccess {
    @Expose()
    @Type(() => String)
    resourceId: string;

    @Expose()
    @Type(() => UserElement)
    users: UserElement[];
}

export class UserElement {
    @Expose()
    @Type(() => User)
    user: User;

    @Expose()
    @Type(() => Number)
    date: number;

    @Expose()
    @Type(() => String)
    progress: string;
}

export class User {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    avatarId: string;

    @Expose()
    @Type(() => String)
    defaultAvatarColor: string;

    @Expose()
    @Type(() => String)
    login: string;

    @Expose()
    @Type(() => String)
    firstName: string;

    @Expose()
    @Type(() => String)
    lastName: string;

    @Expose()
    @Type(() => Job)
    job: Job;
}

export class Job {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    name: string;
}
