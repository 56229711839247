import { createSlice } from "@reduxjs/toolkit";

export interface IProgramSlice {
    currentCourse: {
        id: string;
        title: string;
    };
    currentMaterial: {
        id: string;
        title: string;
    };
}
const initialState: IProgramSlice = {
    currentCourse: {
        id: "",
        title: "",
    },
    currentMaterial: {
        id: "",
        title: "",
    },
};

export const programSlice = createSlice({
    name: "program",
    initialState,
    reducers: {
        setCurrentCourse: (state, action) => {
            state.currentCourse = action.payload;
        },
        setCurrentMaterial: (state, action) => {
            state.currentMaterial = action.payload;
        },
    },
});

export const { setCurrentCourse, setCurrentMaterial } = programSlice.actions;

export default programSlice.reducer;
