import React, { ReactNode } from "react";
import { Section } from "./Section";
import clsx from "clsx";

interface SectionsWrapperProps {
    firstColumnTitle?: string;
    secondColumnTitle?: string;
    children: ReactNode;
    className?: string;
    columnProportion?: keyof typeof columnProportions;
}

const columnProportions = {
    "50/50": "w-full",
    "40/50": "w-4/5",
    "30/50": "w-3/5",
    "20/60": "w-2/5",
};

export const SectionsWrapper = ({
    firstColumnTitle,
    secondColumnTitle,
    children,
    className,
    columnProportion,
}: SectionsWrapperProps) => {
    return (
        <div className={clsx(className, "h-full mt-8")}>
            {firstColumnTitle && (
                <div className="flex pb-2 border-b border-gray-blue">
                    <span
                        className={clsx(
                            columnProportion ? columnProportions[columnProportion] : columnProportions["20/60"],
                            "text-gray-text",
                        )}
                    >
                        {firstColumnTitle}
                    </span>
                    <span className="w-full text-gray-text">{secondColumnTitle}</span>
                </div>
            )}

            {columnProportion
                ? React.Children.map(children, (section, index) => {
                      if (React.isValidElement(section)) {
                          return React.cloneElement(section, {
                              ...section.props,
                              columnProportion: columnProportion,
                              className:
                                  index !== React.Children.count(children) - 1
                                      ? "border-b border-gray-blue " + section.props.className
                                      : section.props.className,
                          });
                      }
                      return section;
                  })
                : children}
        </div>
    );
};

SectionsWrapper.Section = Section;
