import React, { ReactNode, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon, Icons } from "../Icon/Icon";
import { ModalBody as Body } from "./ModalBody";
import { ModalFooter as Footer } from "./ModalFooter";
import clsx from "clsx";
import { TVoidFunction } from "types";

const sizes = {
    small: "max-w-100",
    medium: "max-w-125",
    extraMedium: "max-w-142 2xl:max-w-[710px]",
    large: "max-w-150",
    huge: "max-w-200",
};

interface ModalProps {
    isOpen?: boolean;
    onClose?: TVoidFunction;
    setIsOpen?: (open: boolean) => void;
    title: string;
    description?: string;
    children: ReactNode;
    className?: string;
    id?: string;
    size?: keyof typeof sizes;
}

export const Modal = ({
    size = "extraMedium",
    isOpen = false,
    setIsOpen,
    onClose,
    title = "",
    description = "",
    children,
    className,
    id,
}: ModalProps) => {
    const handleClose = () => {
        setIsOpen?.(false);
        onClose?.();
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-[102] overflow-y-scroll" onClose={() => {}}>
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-20 bg-black"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-20 bg-black"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={clsx(
                                sizes[size],
                                "border-[1px] border-[#EAEDF3] inline-block w-full",
                                "text-left align-middle transition-all",
                                "transform bg-white shadow-xl rounded-lg 2xl:rounded-2lg",
                                className,
                            )}
                            id={id}
                        >
                            <div className={"w-full flex items-start px-6 2xl:px-7.5 mt-6.5 2xl:mt-8"}>
                                <div>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-bold text-gray-900 2xl:!text-4xl 2xl:!leading-[41px]"
                                        id="modalDialogTitle"
                                    >
                                        {title}
                                    </Dialog.Title>
                                    {description && (
                                        <Dialog.Description className="text-gray-text pt-1 break-anywhere">
                                            {description}
                                        </Dialog.Description>
                                    )}
                                </div>
                                <div onClick={handleClose} className="cursor-pointer ml-auto">
                                    <Icon
                                        icon={Icons.Close}
                                        width="32px"
                                        height="32px"
                                        className={"content-end 2xl:!w-10 2xl:!h-10"}
                                        color="fill-blue-drk"
                                        id="modalDialogClose"
                                    />
                                </div>
                            </div>

                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

Modal.Body = Body;
Modal.Footer = Footer;
