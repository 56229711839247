import React, { ReactNode } from "react";
import { components, OptionProps } from "react-select";
import { Checkbox } from "Uikit";

// Кастомный компонент для react-select
// Кастомный option

type OptionValue = { value: { id: string } | string };

export const CustomOption = (props: OptionProps) => {
    const isSelected = (props.selectProps.value as OptionValue[])
        .map(({ value }) => (typeof value === "string" ? value : value.id))
        .some((selectedId) => {
            const optionValue = (props.data as OptionValue).value;
            return selectedId === (typeof optionValue === "string" ? optionValue : optionValue.id);
        });

    return (
        <components.Option
            {...props}
            className={`!px-0 flex justify-start hover:!bg-unset ${isSelected && "!bg-unset"}`}
        >
            <div className="flex gap-2 items-center justify-start px-5 ">
                <Checkbox
                    className="grow-0 !w-auto shrink cursor-pointer"
                    checked={isSelected}
                    stopClickPropagation={false}
                />
                {props.children}
            </div>
        </components.Option>
    );
};

interface PrerenderCustomOptionProps<Option> {
    option: Option;
    formatOptionLabel?: (data: Option) => ReactNode;
}

export const PrerenderCustomOption = <T,>({ option, formatOptionLabel }: PrerenderCustomOptionProps<T>) => {
    return (
        <div className="!px-0 py-4 flex justify-start hover:!bg-unset !bg-unset ui-combo-box__option ui-combo-box__option--is-focused css-d7l1ni-option">
            <div className="flex gap-2 items-center justify-start px-5 ">
                <Checkbox className="grow-0 !w-auto shrink cursor-pointer" checked stopClickPropagation={false} />
                {formatOptionLabel?.(option as any)}
            </div>
        </div>
    );
};
