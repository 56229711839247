import BaseApi from "Api/BaseApi";
import { BaseResponse, BasePaginationResponse } from "Api/BaseResponse";
import { FeedbackRequest, FeedbackListRequest, FeedbackCheckRequest } from "Api/Requests/FeedbackRequest";
import { FeedbackResponse, FeedbackListResponse } from "Api/Responses/FeedbackResponse";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class FeedbackApi extends BaseApi {
    SendFeedback = async (data: FeedbackRequest): Promise<FeedbackResponse> => {
        return await this.Post(FeedbackResponse, "/feedback", data);
    };

    FeedbackList = async ({
        resourceId,
        filters,
        page,
        size,
        sort,
    }: FeedbackListRequest): Promise<BasePaginationResponse<FeedbackListResponse>> => {
        const params = LMSFilterParams({
            filter: filters as { [id: string]: string },
            page,
            size,
        });
        params.append("sort", sort ?? "");

        return this.Get(
            new BasePaginationResponse(FeedbackListResponse),
            `/feedback/${resourceId}?${params.toString()}`,
        );
    };

    FeedbackCheck = async (data: FeedbackCheckRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, `/feedback/review/check`, data);
    };
}
