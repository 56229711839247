import { useQuery } from "react-query";
import { AccountableUser } from "Api/Responses/UserResponse";
import Api from "Api/index";

export const useResponsibleList = (
    reviewerId?: string,
): { label: string; value: string; payload: AccountableUser }[] => {
    return (
        useQuery(
            ["responsible", "list", "collection", reviewerId],
            async () => {
                const response = await Api.Role.GetResponsibleList(reviewerId);
                return response.map((p) => ({
                    label: p.lastName + " " + p.firstName,
                    value: p.id,
                    payload: p,
                }));
            },
            {
                keepPreviousData: false,
                refetchOnWindowFocus: false,
                staleTime: 5 * 60 * 1000,
            },
        ).data || []
    );
};
