import React from "react";
import { Button, Modal } from "Uikit";
import { Row } from "@tanstack/react-table";
import clsx from "clsx";
import { UserAchievementResponse } from "Api/Responses/AchievementResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { numCapEnd } from "helpers/numCapEnd";

interface TakeAwayAchievementModalProps {
    className?: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    achievement?: Row<UserAchievementResponse>;
    id: string;
}

export const RemoveAchievementModal = ({
    className,
    isOpen,
    setIsOpen,
    achievement,
    id,
}: TakeAwayAchievementModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={achievement ? formatDate(achievement.original.givenAt, DateFormat.DATE_TIME_LONG) : ""}
            className={clsx("max-w-2xl", className)}
            id={id}
        >
            <Modal.Body className="mt-4 mb-6">
                <div className="grid grid-cols-2 grid-rows-2 gap-2">
                    <div>
                        <span className="text-[#5D5C5C]">Участник</span>
                        <div className="flex mt-2">
                            <img className="rounded-full h-10 w-10" src={achievement?.original.givenTo.avatarId} />
                            <span className="ml-4 my-auto">
                                {achievement?.original.givenTo.firstName + " " + achievement?.original.givenTo.lastName}
                            </span>
                        </div>
                    </div>

                    <div>
                        <span className="text-[#5D5C5C]">
                            Ачивка (+{achievement?.original.achievement.rating} $
                            {numCapEnd(
                                { one: "балл", two: "балла", few: "баллов" },
                                Number(achievement?.original.achievement.rating),
                            )}
                            )
                        </span>
                        <div className="flex mt-2">
                            <img className="rounded-full h-10 w-10" />
                            <span className="ml-4 my-auto">{achievement?.original.achievement.name}</span>
                        </div>
                    </div>

                    <div>
                        <span className="text-[#5D5C5C]">Выдал ачивку</span>
                        <div className="flex mt-2">
                            <img className="rounded-full h-10 w-10" src={achievement?.original.givenBy.avatarId} />
                            <span className="ml-4 my-auto">
                                {achievement?.original.givenBy.firstName + " " + achievement?.original.givenBy.lastName}
                            </span>
                        </div>
                    </div>

                    <div>
                        <span className="text-[#5D5C5C]">Комментарий</span>
                        <p className="mt-2">{achievement?.original.comment}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    key={0}
                    onClick={() => setIsOpen(false)}
                    variant="outline"
                    color={"secondary"}
                    className={"border-[#EAEDF3] "}
                    id="adminNewMemberAchievementTabModalRemoveAchCancel"
                >
                    Отмена
                </Button>

                <Button
                    key={0}
                    onClick={() => setIsOpen(false)}
                    color={"danger"}
                    className={"border-[#EAEDF3] "}
                    id="adminNewMemberAchievementTabModalRemoveAchTake"
                >
                    Забрать ачивку
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
