import { Expose, Type } from "class-transformer";
import { ITeamAccess, Permission } from "types";
import { BaseResponse } from "Api/BaseResponse";

export class RoleResponse {
    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public readableName: string;

    @Expose()
    @Type(() => String)
    public permissions: Permission;

    @Expose()
    @Type(() => Boolean)
    public defaultRole: boolean;
}

export class RoleItemResponseTeam {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;
}

export class RoleItemResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public roleName: string;

    @Expose()
    @Type(() => Boolean)
    public admin: boolean;

    @Expose()
    @Type(() => Boolean)
    public programs: boolean;

    @Expose()
    @Type(() => Boolean)
    public courses: boolean;

    @Expose()
    @Type(() => Boolean)
    public tests: boolean;

    @Expose()
    @Type(() => Boolean)
    public exercise: boolean;

    @Expose()
    @Type(() => Boolean)
    public materials: boolean;

    @Expose()
    @Type(() => Boolean)
    public statistic: boolean;

    @Expose()
    @Type(() => Boolean)
    public userControl: boolean;

    @Expose()
    @Type(() => Boolean)
    public allTeams: boolean;

    @Expose()
    @Type(() => Boolean)
    public license: boolean;

    @Expose()
    @Type(() => RoleItemResponseTeam)
    public visibleTeams: RoleItemResponseTeam[];
}

export class TeamAccess implements ITeamAccess {
    @Expose()
    @Type(() => String)
    resourceId: string;

    @Expose()
    @Type(() => String)
    teams: string[];

    @Expose()
    @Type(() => Boolean)
    allTeams: boolean;
}
