import React from "react";
import { Modal, Button, FormGroup, Label, Input } from "Uikit";
import { FormikProps, useFormik } from "formik";
import { object, string } from "yup";
import { ErrorCode } from "Api/BaseResponse";
import { TVoidFunction } from "types";

interface CategoryModalProps {
    isOpen: boolean;
    categoryId?: string;
    categoryName?: string;
    onClose: TVoidFunction;
    onSubmit: (name: string) => Promise<any>;
}

const validationSchema = object().shape({
    name: string().max(64, "Название должно быть от 1 до 64 символов").required("Обязательно для ввода"),
});

interface FormValues {
    name: string;
}

export const CategoryModal = ({ isOpen, categoryId, categoryName, onClose, onSubmit }: CategoryModalProps) => {
    const { values, resetForm, ...rest }: FormikProps<{ name: string }> = useFormik({
        initialValues: { name: categoryName ?? "" },
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values).then();
        },
    });

    const title = categoryId ? "Редактирование категории" : "Создание категории";

    const handleSubmit = async (values: FormValues) => {
        const name = String(values.name).trim();
        await rest.setFieldValue("name", name);

        if (!name) {
            return;
        }

        const res = await onSubmit(name);

        if (res?.errorCode === ErrorCode.TITLE_ALREADY_EXISTS) {
            rest.setErrors({ name: "Категория с таким названием уже существует" });
            return;
        }

        handleOnClose();
    };

    const handleOnClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setIsOpen={(_unused) => {}}
            onClose={handleOnClose}
            title={title}
        >
            <Modal.Body>
                <div className="space-y-3.5 pt-6">
                    <FormGroup>
                        <Label isRequired={true}>Название категории</Label>
                        <Input
                            isRequired={true}
                            name={"name"}
                            value={values.name}
                            error={values.name && rest.errors?.name}
                            placeholder="Введите заголовок"
                            onChange={rest.handleChange}
                            maxLength={64}
                            id="categoryModalInput1"
                        />
                    </FormGroup>
                    <div className="text-gray-light">Название категории отображается в пользовательском интерфейсе</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline" color="secondary" onClick={handleOnClose} id="categoryModalBtnCancel">
                    Отмена
                </Button>
                <Button onClick={rest.handleSubmit} disabled={!values.name} id="categoryModalBtnOk">
                    {categoryId ? "Сохранить" : "Создать"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
