import BaseApi from "../BaseApi";
import { BaseRequest } from "../BaseRequest";
import { BaseResponse } from "../BaseResponse";
import { MoveUserRequest, TeamCreateRequest, TeamUpdateRequest } from "../Requests/TeamRequest";
import {
    TeamInUserSpaceResponse,
    TeamTreeAllBasicInfoResponse,
    TeamTreeCurrentUserResponse,
} from "../Responses/TeamResponse";
import { ID } from "types/ID";
import { Sorting } from "@devexpress/dx-react-grid";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class TeamApi extends BaseApi {
    Create = async (data: TeamCreateRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/teams", data);
    };

    Update = async (data: TeamUpdateRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, "/teams", data);
    };

    Remove = async (id: ID, moveUsersToTeamId?: ID | null): Promise<BaseResponse> => {
        return await this.Delete(BaseResponse, "/teams", Object.assign(new BaseRequest(), { id, moveUsersToTeamId }));
    };

    MoveUser = async (request: MoveUserRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, "/teams/users/change", request);
    };

    getTeam = async (id: ID): Promise<TeamInUserSpaceResponse> => {
        return await this.Get(TeamInUserSpaceResponse, `/teams/page-in-user-space/${id}`);
    };

    TreeCurrentUser = async (): Promise<TeamTreeCurrentUserResponse> => {
        return await this.Get(TeamTreeCurrentUserResponse, "/teams/tree/current-user");
    };

    TreeAllBasicInfo = async (sort: Sorting[]): Promise<TeamTreeAllBasicInfoResponse[]> => {
        const params = LMSFilterParams();

        if (sort.length > 0) {
            params.append("sort", `${sort[0].columnName},${sort[0].direction}`);
        }

        return await this.Get([], `/teams/tree/all/basic-info?${params.toString()}`);
    };
}
