import BaseApi from "../BaseApi";
import { faker } from "@faker-js/faker";
import { BasePaginationRequest, BaseIdRequest, BaseRequest } from "../BaseRequest";
import { BaseResponse, BasePaginationResponse, BaseArrayResponse } from "../BaseResponse";
import {
    ProgramRequest,
    ProgramCategoryRequest,
    ProgramCategoryReorderRequest,
    ProgrammUnArchiveRequest,
} from "../Requests/ProgramRequest";
import {
    ProgramListResponse,
    ProgramResponse,
    ProgramReadProgressResponse,
    ProgramCategoryResponse,
    ProgramReadResponse,
    ProgramReadUserResponse,
    FilterRatingInfo,
} from "../Responses/ProgramResponse";
import { LMSFilterParams } from "models/LMSFilterParams";
import { ResourceType } from "Enums";
import { SortingState } from "@tanstack/react-table";
import { ArchiveResponse } from "Api/Responses/ArchiveResponse";

export default class ProgramApi extends BaseApi {
    CategoryGet = async (): Promise<BaseArrayResponse<ProgramCategoryResponse>> => {
        return await this.Get(new BaseArrayResponse(ProgramCategoryResponse), "/program-category/all");
    };

    CategoryCreate = async (data: ProgramCategoryRequest): Promise<ProgramCategoryResponse> => {
        return await this.Post(new ProgramCategoryResponse(), "/program-category", data);
    };

    CategoryEdit = async (data: ProgramCategoryRequest): Promise<ProgramCategoryResponse> => {
        return await this.Post(new ProgramCategoryResponse(), "/program-category/edit", data);
    };

    CategoryDelete = async (id: string): Promise<BaseResponse> => {
        return await this.Delete(new BaseResponse(), `/program-category/delete/${id}`);
    };

    CategoryReorder = async (data: ProgramCategoryReorderRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/program-category/reorder", data);
    };

    List = ({
        filters,
        page,
        size,
        sort,
    }: BasePaginationRequest): Promise<BasePaginationResponse<ProgramListResponse>> => {
        const params = LMSFilterParams({
            filter: filters as { [id: string]: string },
            page,
            size,
        });
        params.append("sort", sort ?? "");

        return this.Get(new BasePaginationResponse(ProgramListResponse), `/program/all?${params.toString()}`);
    };

    Read = async (data: BaseIdRequest): Promise<ProgramReadResponse> => {
        return await this.Get(ProgramReadResponse, `/program/${data.uuid}`);
    };

    Create = async (data: ProgramRequest): Promise<ProgramResponse> => {
        return await this.Post(ProgramResponse, "/program", data);
    };

    Edit = async (data: ProgramRequest): Promise<ProgramResponse> => {
        return await this.Post(ProgramResponse, "/program/edit", data);
    };

    Copy = async (data: BaseIdRequest): Promise<BaseResponse> => {
        return await this.Get(BaseResponse, `/program/copy/${data.uuid}`);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ReadProgress = async (_data: BaseIdRequest): Promise<ProgramReadProgressResponse> => {
        return {
            id: faker.datatype.uuid() /* daprecated: better use faker.string.uuid() */,
            passedCount: faker.datatype.number(100),
            timeSpent: faker.datatype.number(10),
        };
    };

    State = async (resourceIds: string[], state: string, type: ResourceType): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/resource-state", {
            resourceIds,
            state,
            type,
        } as BaseRequest);
    };

    ArchiveList = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<ArchiveResponse>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(ArchiveResponse),
            `/program/archive/list?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    UnArchive = async (data: ProgrammUnArchiveRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/program/unarchive", data);
    };

    StateCheck = async (resourceIds: string[], state: string, type: ResourceType): Promise<string[]> => {
        return await this.Post([], "/resource-state/check", {
            resourceIds,
            state,
            type,
        } as BaseRequest);
    };

    ReadUI = async (id: string): Promise<ProgramReadUserResponse> => {
        return await this.Get(ProgramReadUserResponse, "/program/user/" + id);
    };

    FilterRatingInfo = async (): Promise<FilterRatingInfo> => {
        return await this.Get(FilterRatingInfo, "/program/filter-rating-info");
    };
}
