import React, { useState } from "react";
import { Table } from "./Table";
import { ColumnDef, PaginationState, SortingState } from "@tanstack/react-table";

import { useQuery } from "react-query";
import { Checkbox } from "..";
import { fetchActiveUserData } from "./fetchTestData";
import { UserActiveListResponse } from "Api/Responses/UserResponse";

export const TableDemo = () => {
    const columns = React.useMemo<ColumnDef<UserActiveListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Имя",
                footer: (props) => props.column.id,
                cell: (info) => info.getValue(),
                accessorKey: "firstName",
            },
            {
                header: "Фамилия",
                footer: (props) => props.column.id,
                cell: (info) => info.getValue(),
                accessorKey: "lastName",
            },
            {
                header: "Возраст",
                accessorKey: "age",
                footer: (props) => props.column.id,
            },
            {
                header: "Статус",
                accessorKey: "status",
                footer: (props) => props.column.id,
            },
            {
                header: "Прогресс",
                accessorKey: "progress",
                footer: (props) => props.column.id,
            },
        ],
        [],
    );
    const [sorting, setSorting] = useState<SortingState>([]);
    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    const [rowSelection, setRowSelection] = useState({});

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const fetchDataOptions = {
        pageIndex,
        pageSize,
        sort: sorting,
    };

    const dataQuery = useQuery(["data", fetchDataOptions], () => fetchActiveUserData(fetchDataOptions), {
        keepPreviousData: true,
    });

    return (
        <Table
            columns={columns}
            pageCount={dataQuery.data?.pageCount}
            data={dataQuery.data?.rows}
            pagination={pagination}
            onPaginationChange={setPagination}
            isFetching={dataQuery.isFetching}
            rowSelection={rowSelection}
            onRowSelectionChange={setRowSelection}
            sorting={sorting}
            onSortingChange={setSorting}
        />
    );
};
