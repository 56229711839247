import BaseApi from "../BaseApi";
import { BaseIdPaginationRequest } from "../BaseRequest";
import { BasePaginationResponse, BaseResponse } from "../BaseResponse";
import { RatingEditRequest } from "../Requests/RatingRequest";
import {
    RatingCommandResponse,
    RatingReadByTypeResponse,
    RatingReadResponse,
    UIRatingListResponse,
} from "../Responses/RatingResponse";
import { SortingState } from "@tanstack/react-table";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class RatingApi extends BaseApi {
    Read = async (): Promise<RatingReadResponse> => {
        return await this.Get(RatingReadResponse, "/rating");
    };

    ReadByType = async (resourceType: string, type?: string): Promise<RatingReadByTypeResponse> => {
        return await this.Get(RatingReadByTypeResponse, `/rating/${resourceType}` + (type ? `?type=${type}` : ""));
    };

    Edit = async (data: RatingEditRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, "/rating", data);
    };

    Command = async (_data: BaseIdPaginationRequest): Promise<BasePaginationResponse<RatingCommandResponse>> => {
        const response = new BasePaginationResponse(RatingCommandResponse);
        response.Total = 1;
        response.Content = [
            {
                Id: 1,
                Image: "",
                FirstName: "Admin",
                LastName: "Admin",
                MiddleName: "Admin",
                Login: "admin",
                Email: "admin@gmail.com",
                Pass: 0,
                Point: 0,
            },
        ];
        return response;
    };

    getAllRating = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<UIRatingListResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size });
        return this.Get(new BasePaginationResponse(UIRatingListResponse), `/rating/users?${params.toString()}`);
    };

    getCurrentUserRating = (): Promise<UIRatingListResponse> => {
        return this.Get(new UIRatingListResponse(), "/rating/users/current");
    };
}
