import React, { useEffect, useMemo } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Player from "video.js/dist/types/player";

import { Icon, Icons } from "Uikit/index";
import { flash } from "Uikit/Notification/flash";
import { Controls } from "./Controls";
import { useEventListener } from "usehooks-ts";
import { UIErrorMessages } from "Enums";

interface Options {
    autoplay?: boolean;
    responsive?: boolean;
    fluid?: boolean;
    rewind?: boolean;
    watchTime?: number;
    src?: any;
}

interface VideoPlayerProps extends Options {
    onReady?: (player: Player) => void;
    onError?: (error: any) => void;
    onPlayerUnmount?: (playbackTimeInSeconds: number) => void;
}

export const VideoPlayer = ({
    onReady,
    onError,
    onPlayerUnmount,
    autoplay = false,
    responsive = false,
    fluid = false,
    rewind = true,
    watchTime = 0,
    src,
}: VideoPlayerProps) => {
    const videoContainerRef = React.useRef<HTMLDivElement | null>(null);
    const videoRef = React.useRef<HTMLDivElement | null>(null);
    const playerRef = React.useRef<Player | null>(null);

    const [watchedTime, setWatchedTime] = React.useState(watchTime);
    const [currentTime, setCurrentTime] = React.useState(watchTime);
    const [volume, setVolume] = React.useState(1);

    const [status, setStatus] = React.useState("PAUSE");

    const options: Options = useMemo(
        () => ({
            autoplay,
            controls: false,
            responsive,
            fluid,
            width: 800,
            height: 487,
            aspectRatio: "16:9",
        }),
        [autoplay, responsive, fluid],
    );

    const getCurrentTime = () => {
        if (!playerRef.current) {
            return 0;
        }

        return playerRef.current.currentTime();
    };

    const getDuration = () => {
        if (!playerRef.current) {
            return 0;
        }

        return isNaN(Number(playerRef.current.duration())) ? undefined : playerRef.current.duration();
    };

    useEffect(() => {
        if (!playerRef.current && src) {
            const videoElement = document.createElement("video-js");

            videoElement.classList.add("vjs-big-play-centered");
            videoRef.current!.appendChild(videoElement);

            const player = (playerRef.current = videojs(videoElement, options));

            player.src(src);

            player.on("ready", () => {
                videojs.log("player is ready");

                onReady?.(player);
            });
            player.on("error", (e: any) => {
                videojs.log("player error", e);

                flash.error(UIErrorMessages.FILE_LOADING_ERROR);

                onError?.(e);
            });

            (player as any).markers({
                markerStyle: {
                    width: "7px",
                    "border-radius": "30%",
                    "background-color": "red",
                },
                markerTip: {
                    display: true,
                    text: function (marker: any) {
                        return "Break: " + marker.text;
                    },
                    time: function (marker: any) {
                        return marker.time;
                    },
                },
                breakOverlay: {
                    display: false,
                    displayTime: 3,
                    style: {
                        width: "100%",
                        height: "20%",
                        "background-color": "rgba(0,0,0,0.7)",
                        color: "white",
                        "font-size": "17px",
                    },
                    text: function (marker: any) {
                        return "Break overlay: " + marker.overlayText;
                    },
                },
                onMarkerClick: function (_marker: any) {},
                onMarkerReached: function (_marker: any) {},
            });
        } else {
            const player = playerRef.current;
            player?.autoplay(options.autoplay);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, videoRef, src]);

    useEffect(() => {
        const player = playerRef.current;

        if (player) {
            player.on("timeupdate", () => {
                setCurrentTime(player.currentTime() ?? 0);

                if (!rewind) {
                    setWatchedTime((prevTime) => {
                        if ((player.currentTime() || 0) > prevTime) {
                            return player.currentTime() ?? 0;
                        }

                        return prevTime;
                    });
                }
            });

            player.on("volumechange", () => {
                setVolume(player.volume() ?? 0);
            });

            player.on("ended", () => {
                player.pause();
                setStatus("END");
            });
        }

        return () => {
            if (player && !player.isDisposed()) {
                onPlayerUnmount?.(Math.round(player.currentTime() ?? 0));
                player.dispose();
                playerRef.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerRef, rewind]);

    const onPlayStatusChange = () => {
        if (!playerRef.current) {
            return;
        }

        setStatus((prevState) => {
            switch (prevState) {
                case "PAUSE":
                case "END":
                    playerRef.current?.play();

                    return "PLAY";
                case "PLAY":
                    playerRef.current?.pause();

                    return "PAUSE";

                default:
                    return "PAUSE";
            }
        });
    };

    const onTimeChange = (time: number) => {
        if (!playerRef.current) {
            return;
        }

        playerRef.current.currentTime(time);
    };

    const onVolumeChange = (value: number) => {
        if (!playerRef.current) {
            return;
        }

        playerRef.current.volume(value);
    };

    const onFullscreenHandler = async () => {
        const videoEl = playerRef.current?.el() as HTMLDivElement;

        if (document.fullscreenElement) {
            await document.exitFullscreen();
            videoEl.style.removeProperty("height");
            videoEl.style.removeProperty("padding-top");
        } else {
            await videoContainerRef.current?.requestFullscreen();
            videoEl?.classList.add("vjs-fullscreen");
            videoEl.style.height = "100%";
            videoEl.style.paddingTop = "0";
        }
    };

    useEventListener(
        "fullscreenchange",
        () => {
            if (!document.fullscreenElement) {
                const videoEl = playerRef.current?.el() as HTMLDivElement;

                videoEl.style.removeProperty("height");
                videoEl.style.removeProperty("padding-top");
            }
        },
        videoContainerRef,
    );

    return (
        <div ref={videoContainerRef} className="relative w-full h-full rounded-lg 2xl:rounded-2lg overflow-hidden">
            <div className="w-full h-full flex items-center justify-center" onClick={onPlayStatusChange}>
                <div className="w-full h-full" ref={videoRef} />
                {status === "PAUSE" && (
                    <div className="absolute flex justify-center items-center w-15 2xl:w-[75px] h-15 2xl:h-[75px] rounded-full bg-blue cursor-pointer">
                        <Icon
                            icon={Icons.PlayFilled}
                            width={24}
                            height={24}
                            color="fill-white"
                            className="2xl:!w-7.5 2xl:!h-7.5"
                        />
                    </div>
                )}
                {status === "END" && (
                    <div className="absolute flex justify-center items-center w-15 2xl:w-[75px] h-15 2xl:h-[75px] rounded-full bg-blue cursor-pointer">
                        <Icon
                            icon={Icons.Refresh}
                            width={24}
                            height={24}
                            color="fill-white"
                            className="2xl:!w-7.5 2xl:!h-7.5"
                        />
                    </div>
                )}
            </div>
            <Controls
                className="absolute bottom-0 left-0 right-0"
                status={status}
                rewind={rewind}
                watchedTime={watchedTime}
                currentTime={currentTime}
                volume={volume}
                getDuration={getDuration}
                getCurrentTime={getCurrentTime}
                onPlayStatusChange={onPlayStatusChange}
                onFullscreenHandler={onFullscreenHandler}
                onTimeChange={onTimeChange}
                onVolumeChange={onVolumeChange}
            />
        </div>
    );
};
