import React, { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";

interface BodyProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    className?: string;
}

export const ModalBody = ({ children, className, ...rest }: BodyProps) => {
    return (
        <div className={clsx("mb-8 px-6 2xl:px-7.5 pt-3 2xl:pt-7.5", className)} {...rest}>
            {children}
        </div>
    );
};
