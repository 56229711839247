import React, { useEffect, useRef, useState } from "react";
import Tippy from "@tippyjs/react";
import clsx from "clsx";
import useResizeObserver from "@react-hook/resize-observer";
import { TVoidFunction } from "types";

interface MultiClumpTooltipProps {
    className?: string;
    label?: string;
    clamp?: number;
    isContextResize?: boolean;
    onClick?: TVoidFunction;
    textClassName?: string;
}

type THandleClick = TVoidFunction;

export const MultiClumpTooltip = ({
    className,
    label = "",
    clamp = 2,
    isContextResize = true,
    onClick,
    textClassName = "",
}: MultiClumpTooltipProps) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [isTooltip, setIsTooltip] = useState(false);
    const isTextClamped = (elm: Element) => elm.scrollHeight > elm.clientHeight;

    useResizeObserver(isContextResize ? textRef?.current : null, (entry) => {
        setIsTooltip(isTextClamped(entry.target));
    });

    useEffect(() => {
        if (!isContextResize && textRef?.current) {
            setIsTooltip(isTextClamped(textRef.current));
        }
    }, [isContextResize]);

    useEffect(() => {
        if (textRef?.current) {
            setIsTooltip(isTextClamped(textRef.current));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick: THandleClick = () => {
        onClick?.();
    };

    return (
        <Tippy content={label} disabled={!isTooltip} className="break-anywhere">
            <div className={clsx("break-words break-all", className)} onClick={handleClick}>
                <div ref={textRef} className={`line-clamp-${clamp} ${textClassName}`}>
                    {label}
                </div>
            </div>
        </Tippy>
    );
};
