import BaseApi from "../BaseApi";
import { ScormProgressRequest } from "Api/Requests/ScormProgressRequest";
import { BaseResponse } from "Api/BaseResponse";
import { ScormProgressResponse } from "Api/Responses/ScormProgressResponse";

export default class ScormProgressApi extends BaseApi {
    SaveProgress = async (data: ScormProgressRequest): Promise<BaseResponse> => {
        return await this.Post(new BaseResponse(), ``, data);
    };

    GetProgress = async (materialId: string): Promise<ScormProgressResponse> => {
        return await this.Get(new ScormProgressResponse(), `/${materialId}`);
    };
}
