import React, { useState } from "react";
import { Modal, Button, FormField, Input } from "Uikit";
import { Formik } from "formik";
import { object, string } from "yup";
import { TVoidFunction } from "types";

interface CategoryModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    categoryId?: string;
    categoryName?: string;
    onSubmit: (name: string) => Promise<{ error: boolean }>;
}

const validationSchema = object().shape({
    name: string().required("обязательно для ввода"),
});

interface FormValues {
    name: string;
}

export const TasksCategoryModal = ({ isOpen, onClose, categoryId, categoryName, onSubmit }: CategoryModalProps) => {
    const [errorMsg, setErrorMsg] = useState("");
    if (!isOpen) {
        return null;
    }

    const initialValues: FormValues = { name: categoryName ?? "" };

    const handleSubmit = async (values: FormValues) => {
        const { error } = await onSubmit(values.name);

        if (!error) {
            setErrorMsg("");
            onClose();

            return;
        }
        setErrorMsg("Данное название уже используется");
    };

    return (
        <Formik
            validateOnChange
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, values, setFieldValue }) => {
                return (
                    <Modal
                        isOpen={isOpen}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        setIsOpen={(_unused) => {}}
                        onClose={() => {
                            setErrorMsg("");
                            onClose();
                        }}
                        title={categoryId ? "Редактирование категории" : "Создание категории"}
                    >
                        <Modal.Body>
                            <div className="space-y-3.5 mt-4">
                                <FormField name="name" label="Название категории" isRequired>
                                    <Input
                                        isRequired={true}
                                        value={values.name}
                                        onChange={(e) => {
                                            setFieldValue("name", e.target.value).then();
                                        }}
                                        placeholder="Введите название категории"
                                        error={errorMsg}
                                        id="createCategoryTaskInput1"
                                    />
                                </FormField>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline"
                                color="secondary"
                                onClick={onClose}
                                id="createCategoryTaskBtnCancel"
                            >
                                Отмена
                            </Button>
                            <Button onClick={handleSubmit} disabled={!values.name} id="createCategoryTaskBtnOk">
                                {categoryId ? "Сохранить" : "Создать"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            }}
        </Formik>
    );
};
