import clsx from "clsx";
import React, { ElementType, InputHTMLAttributes, forwardRef } from "react";
import { Except } from "type-fest";

const sizes = {
    small: "px-3 py-1.5 text-sm leading-sm",
    medium: "px-4.5 py-2.2 text-base leading-base 2xl:py-3 2xl:px-5.5 2xl:text-2md 2xl:rounded-2lg 2xl:h-12.5",
    large: "px-5 py-3.5 text-md leading-md",
};

export interface BaseInputProps extends Except<InputHTMLAttributes<HTMLInputElement>, "size"> {
    as: ElementType;
    size?: keyof typeof sizes;
    isError?: boolean;
    className?: string;
    isRequired?: boolean;
    rows?: number;
}

export const BaseInput = forwardRef(
    ({ as: Tag, size = "medium", isError, className, isRequired: _isRequired, ...rest }: BaseInputProps, ref) => {
        return (
            <div className="relative flex w-full items-center">
                <Tag
                    ref={ref}
                    className={clsx(
                        "w-full rounded-lg placeholder-gray-text disabled:text-gray leading-p150 font-normal",
                        "border border-input-stroke disabled:bg-gray-blue min-h-[40px] 2xl:min-h-[50px]",
                        rest.readOnly && "cursor-default",
                        sizes[size],
                        isError && "!border-danger !focus:border-danger focus:ring-0 focus:ring-danger",
                        !isError && !rest.readOnly && "focus:border-primary focus:ring-0 focus:ring-primary",
                        rest.readOnly && "focus:border-input-stroke focus:ring-0",
                        "border-1",
                        Tag === "textarea" && size === "medium" && "!h-fit",
                        className,
                    )}
                    {...rest}
                />
            </div>
        );
    },
);
