import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface IHeaderSlice {
    isHidden: boolean;
    isBack: boolean;
    backUrl: string;
    title: string;
    isNotifications: boolean;
    notificationsCount: number;
    isFavorite: boolean;
    isFavoriteSelected: boolean;
}
const initialState: IHeaderSlice = {
    isHidden: false,
    isBack: false,
    backUrl: "",
    title: "Главная",
    isNotifications: false,
    notificationsCount: 0,
    isFavorite: false,
    isFavoriteSelected: false,
};

export const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setIsHidden: (state, action) => {
            state.isHidden = action.payload;
        },
        setIsBack: (state, action) => {
            state.isBack = action.payload;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setBackUrl: (state, action) => {
            state.backUrl = action.payload;
        },
        setIsNotifications: (state, action) => {
            state.isNotifications = action.payload;
        },
        setNotificationsCount: (state, action) => {
            if (action.payload === "+1") {
                state.notificationsCount += 1;
            } else if (action.payload === "-1") {
                if (state.notificationsCount > 0) {
                    state.notificationsCount -= 1;
                }
            } else {
                state.notificationsCount = action.payload;
            }
        },
        setIsFavorite: (state, action) => {
            state.isFavorite = action.payload;
        },
        setIsFavoriteSelected: (state, action) => {
            state.isFavoriteSelected = action.payload;
        },
    },
});

export const getSourcePage = (state: RootState) => {
    return state.header.backUrl;
};

export const {
    setIsHidden,
    setIsBack,
    setTitle,
    setBackUrl,
    setIsNotifications,
    setNotificationsCount,
    setIsFavorite,
    setIsFavoriteSelected,
} = headerSlice.actions;

export default headerSlice.reducer;
