import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Icon, Icons } from "Uikit";

export const ScrollToTop = () => {
    const [showBtn, setShowBtn] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const listener = () => {
            const windowHeight = document.documentElement.clientHeight;
            if (window.scrollY > windowHeight * 2 && !location.pathname.includes("admin")) {
                setShowBtn(true);
            } else {
                setShowBtn(false);
            }
        };
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    }, [location.pathname]);

    const handleGoToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            {showBtn && (
                <Button
                    className="fixed z-10 bottom-6.5 right-6.5 hidden sm:flex justify-center items-center w-15 h-15 !rounded-full !p-0"
                    onClick={handleGoToTop}
                >
                    <Icon icon={Icons.ArrowUp} width={26} height={26} color="fill-white" />
                </Button>
            )}
        </>
    );
};
