import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { TVoidFunction } from "types";

interface LinkProps {
    title: string;
    url?: string;
    refObj?: any;
    onClick?: TVoidFunction;
}

export const Link = ({ title, url, refObj = null, onClick }: LinkProps) => {
    return (
        <>
            {url && (
                <RouterLink to={url} ref={refObj} className="text-current" onClick={onClick}>
                    {title}
                </RouterLink>
            )}
            {!url && <span ref={refObj}>{title}</span>}
        </>
    );
};
