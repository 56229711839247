import React, { ReactNode } from "react";
import { Route, Routes } from "react-router-dom";
import { Item } from "./Item";
import clsx from "clsx";
import { Group } from "./Group";

interface SideMenuProps {
    id?: string;
    className?: string;
    description?: string;
    children?: ReactNode;
}

export const SideMenu = ({ id, className, description, children }: SideMenuProps) => {
    const childrenArr: any[] = [];
    const collectChildrenRecursive: any = (children: ReactNode) => {
        React.Children.forEach(children, (child: any) => {
            if (!child) {
                return;
            }
            childrenArr.push(child);

            if (child.props.children) {
                collectChildrenRecursive(child.props.children);
            }
        });
    };
    collectChildrenRecursive(children);

    return (
        <div className={clsx("h-full flex", className)} id={id}>
            <div
                className="-mt-14 min-w-65 max-w-65 border-r border-[#EAEDF3] py-3 px-4 relative z-[100]"
                id={id + "Submenu"}
            >
                {description && <p className="mb-6 text-gray p4">{description}</p>}
                <ul className="relative">{children}</ul>
            </div>

            <Routes>
                {childrenArr.map((child: any) => {
                    return (
                        <Route
                            key={child.props.path ? child.props.path : ""}
                            path={child.props.path ? child.props.path : ""}
                            element={child.props.content}
                        />
                    );
                })}
            </Routes>
        </div>
    );
};

SideMenu.Group = Group;
SideMenu.Item = Item;
