import useResizeObserver from "@react-hook/resize-observer";
import { useLayoutEffect, useState } from "react";

export const useResize = (target: any) => {
    const [size, setSize] = useState(0);

    useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect());
    }, [target]);

    useResizeObserver(target, (entry: any) => {
        setSize(entry.contentRect);
    });
    return size;
};
