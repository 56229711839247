import React, { useCallback, useEffect, useState } from "react";
import { UserRequest } from "Api/Requests/UserRequest";
import { Section } from "Uikit/Forms/Section";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { FormGroup } from "Uikit/Forms/FormGroup";
import { Label } from "Uikit/Forms/Label";
import { ComboBox } from "Uikit/Forms/ComboBox";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { useCurrentUser } from "hooks/useCurrentUser";
import { RoleName } from "Enums";
import { ParentTeamChoiceModal } from "../Settings/Group/Teams/ParentTeamChoiceModal";
import { IOption } from "types";

interface IMemberCommands {
    errors: any;
    user: UserRequest;
    teams?: TeamTreeAllBasicInfoResponse[];
    onChange: (course: UserRequest) => void;
}
export const MemberCommands = ({ errors, user, teams, onChange }: IMemberCommands) => {
    const [teamId, setTeamId] = useState<string | undefined>(undefined);
    const [selectedTeams, setSelectedTeams] = useState<any>({});
    const currentUser = useCurrentUser();

    const getTeamOptions = useCallback(
        (teamId: string) => {
            const parentTeam = teams?.find((p) => p.id === teamId);

            const getTeams = (team: TeamTreeAllBasicInfoResponse) => {
                let response: IOption[] = [{ label: team.name, value: team.id }];

                for (const element of team.subTeams) {
                    response = response.concat(getTeams(element));
                }

                return response;
            };

            return getTeams(parentTeam!);
        },
        [teams],
    );

    useEffect(() => {
        const data = teams!.map((p: TeamTreeAllBasicInfoResponse) => {
            const option = getTeamOptions(p.id).find((p1) => user.teamIds.find((p2) => p2 === p1.value) !== undefined);

            return { id: p.id, option: option };
        });

        const obj: any = {};

        for (const element of data) {
            if (element.option === undefined) {
                continue;
            }

            obj[element.id] = element.option;
        }

        setSelectedTeams(obj);
    }, [teams, user, getTeamOptions]);

    if (!teams) {
        return null;
    }

    return (
        <>
            <ParentTeamChoiceModal
                isOpen={!!teamId}
                title="Выбор команд"
                isMulti={false}
                teamIds={teams.filter((p1) => p1.id !== teamId).map((p1) => p1.id)}
                onSelect={(selectedTeamId) => {
                    if (!teamId) {
                        return;
                    }

                    setSelectedTeams((prev: any) => {
                        prev[teamId!] = getTeamOptions(teamId!).find(
                            (p) => p.value === selectedTeamId[0].replace("root:", ""),
                        );

                        return prev;
                    });

                    setTeamId(undefined);
                    onChange({ ...user, teamIds: Object.keys(selectedTeams).map((p) => selectedTeams[p].value) });
                }}
                onClose={() => setTeamId(undefined)}
                selectable
                hideMembers={currentUser?.data?.role === RoleName.ADMIN}
            />
            <Section label="Список корневых команд" id="adminNewMemberRootTeamsList">
                {teams.map((p, index) => {
                    return (
                        <FormGroup
                            key={p.id}
                            className="last:mb-0"
                            onClick={() => setTeamId(p.id)}
                            id={`adminNewMemberRootTeamGroup${index}`}
                        >
                            <Label isRequired className="flex text-ellipsis overflow-hidden">
                                {p.name}
                            </Label>
                            <ComboBox
                                openMenuOnClick={false}
                                menuPlacement="auto"
                                placeholder="Выберите команду"
                                components={{
                                    DropdownIndicator: () => (
                                        <Icon
                                            className="mr-3 ml-2 cursor-pointer"
                                            icon={Icons.List}
                                            width={20}
                                            height={20}
                                            color="fill-blue-drk"
                                        />
                                    ),
                                }}
                                options={getTeamOptions(p.id)}
                                value={selectedTeams[p.id]}
                                error={errors["teamIds"] && !selectedTeams[p.id] ? errors["teamIds"] : ""}
                                id={"adminSettingsCommandsTabComboBox" + p.id}
                            />
                        </FormGroup>
                    );
                })}
            </Section>
        </>
    );
};
