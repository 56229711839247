import { BaseResponse } from "Api/BaseResponse";
import { Expose, Type } from "class-transformer";
import { ProgressStatus } from "Enums";

export class NextMaterial {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    resourceId: string;

    @Expose()
    @Type(() => String)
    type: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;
}

export class CourseStartResponse extends BaseResponse {
    // @Expose()
    // @Type(() => String)
    // public id: string;

    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => NextMaterial)
    public next: NextMaterial;
}

export class MaterialCompleteResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => NextMaterial)
    public next: NextMaterial;
}

export class CourseProgressResponse {
    @Expose()
    @Type(() => Number)
    public totalCount: number;

    @Expose()
    @Type(() => Number)
    public passedCount: number;

    @Expose()
    @Type(() => Number)
    public timeSpent: number;
}
