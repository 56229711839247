import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Api from "../../../Api";
import { Breadcrumbs, TabsWrapper, Tabs, Content } from "Uikit";
import "./Tasks.scss";
import { TasksAll } from "./TasksAll";
import { TasksUnderReview } from "./TasksUnderReview";
import { TasksResults } from "./TasksResults";
import { TaskPublicNewCountResponse } from "Api/Responses/TaskPublicResponse";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { useDispatch } from "react-redux";

interface ITasksCounterProps {
    count: number;
}

const TasksCounter = ({ count }: ITasksCounterProps) => {
    return count ? (
        <div className="min-w-3.5 2xl:min-w-4.5 h-3.5 2xl:h-4.5 py-0.5 px-1 flex-center rounded-full bg-red text-white absolute left-full top-1 text-xxs">
            {count}
        </div>
    ) : null;
};

export const Tasks = () => {
    const dispatch = useDispatch();
    const [newCount, setNewCount] = useState<TaskPublicNewCountResponse>({
        allTab: 0,
        resultsTab: 0,
    });

    const dataQuery = useQuery(
        ["data"],
        async () => {
            return await Api.TaskPublic.NewCount();
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        if (dataQuery.isFetched) {
            setNewCount(dataQuery.data as TaskPublicNewCountResponse);
        }
    }, [dataQuery]);
    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Задания"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    return (
        <div className="h-full flex flex-wrap justify-center pt-3 sm:pt-0 px-3 sm:px-0">
            <TabsWrapper className="sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] w-full h-full flex flex-col">
                <Breadcrumbs className="hidden sm:block" id="userTasksBreadcrumbs">
                    <Breadcrumbs.Link title="Задания" />
                </Breadcrumbs>
                <TabsWrapper.Tabs classname="mb-5 2xl:mb-6.25" id="userTasksTabsBlock">
                    <Tabs.Tab
                        title="Все задания"
                        className="relative"
                        additionalElement={<TasksCounter count={newCount.allTab} />}
                    />
                    <Tabs.Tab title="На проверке" />
                    <Tabs.Tab
                        title="Результаты"
                        className="relative"
                        additionalElement={<TasksCounter count={newCount.resultsTab} />}
                    />
                </TabsWrapper.Tabs>
                <TabsWrapper.Content>
                    <Content.Body className="h-full flex flex-col">
                        <TasksAll />
                    </Content.Body>
                    <Content.Body className="h-full flex flex-col">
                        <TasksUnderReview />
                    </Content.Body>
                    <Content.Body className="h-full flex flex-col">
                        <TasksResults />
                    </Content.Body>
                </TabsWrapper.Content>
            </TabsWrapper>
        </div>
    );
};
