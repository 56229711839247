import { CMIStatus } from "Pages/Admin/Material/components/Scorm/ScormModel";
import { Expose, Type } from "class-transformer";
import { BaseResponse } from "Api/BaseResponse";

export class ScormProgressResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public materialId: string;

    @Expose()
    @Type(() => String)
    public version: string;

    @Expose()
    @Type(() => String)
    public completionStatus: CMIStatus;

    @Expose()
    @Type(() => String)
    public successStatus: string;

    @Expose()
    @Type(() => Number)
    public passingCount: number;

    @Expose()
    @Type(() => String)
    public progressData: string;
}
