import React from "react";
import { Button, Icon, Icons } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { TVoidFunction } from "types";

interface ICoursesEmpty {
    isEmptyCategories: boolean;
    onCreateCategoryClick: TVoidFunction;
    onCreateProgramClick: TVoidFunction;
}
export const CoursesEmpty = ({ isEmptyCategories, onCreateCategoryClick, onCreateProgramClick }: ICoursesEmpty) => {
    return (
        <Empty
            title={isEmptyCategories ? "Созданных курсов пока нет" : "В категории пока нет курсов"}
            description={
                isEmptyCategories
                    ? `Курс без категории существовать не может. Сначала создайте категорию, затем курс. 
                    Название категории отображается в пользовательском интерфейсе`
                    : `Создайте курсы и поместите их в эту категорию, чтобы они здесь появились`
            }
        >
            {isEmptyCategories && (
                <>
                    <Button
                        icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-white" />}
                        iconPlacement="left"
                        onClick={onCreateCategoryClick}
                        id="emptyPageBtnCreateCourseCategory"
                    >
                        Создать категорию
                    </Button>
                    <Button
                        variant="outline"
                        color="secondary"
                        icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-primary" />}
                        iconPlacement="left"
                        onClick={onCreateProgramClick}
                        id="emptyPageBtnCreateCourse"
                    >
                        Создать курс
                    </Button>
                </>
            )}
            {!isEmptyCategories && (
                <Button
                    icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-white" />}
                    iconPlacement="left"
                    onClick={onCreateProgramClick}
                    id="emptyPageBtnCreateCourse"
                >
                    Создать курс
                </Button>
            )}
        </Empty>
    );
};
