import BaseApi from "../BaseApi";
import { faker } from "@faker-js/faker";
import { makeDataWithSorting } from "Uikit/Table/fetchData";
import { BaseIdPaginationRequest } from "../BaseRequest";
import { BaseArrayResponse, BasePaginationResponse } from "../BaseResponse";
import { CommentReadResponse } from "../Responses/CommentResponse";

export default class CommentApi extends BaseApi {
    List = async (data: BaseIdPaginationRequest): Promise<BaseArrayResponse<CommentReadResponse>> => {
        const comment = () => ({
            id: faker.datatype.uuid(),
            parentId: faker.datatype.number(30),
            user: {
                id: faker.datatype.uuid(),
                firstName: faker.lorem.words(1),
                lastName: faker.lorem.words(1),
                middleName: faker.lorem.words(1),
                avatarId: faker.datatype.uuid(),
                login: faker.lorem.words(1),
                email: faker.lorem.words(1) + "@gmail.com",
            },
            point: faker.datatype.number(5),
            comment: faker.lorem.words(30),
            createDate: faker.date.recent(),
        });

        const response = new BasePaginationResponse(CommentReadResponse);
        response.Total = 1;
        response.Content = makeDataWithSorting(comment, data.sort as any, 10000);
        return response;
    };
}
