import { useState, useEffect } from "react";

export function useAudioPlayer() {
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(100);

    const [curTime, setCurTime] = useState(0);
    const [playing, setPlaying] = useState(false);

    const [clickedTime, setClickedTime] = useState<number | null>(0);
    const [clickedVolume, setClickedVolume] = useState<number | null>(null);

    useEffect(() => {
        const audio = document.getElementById("audioPlayer") as HTMLAudioElement;

        const setAudioData = () => {
            setDuration(audio.duration);
            setCurTime(audio.currentTime);
            setVolume(audio.volume);
        };

        const setAudioTime = () => setCurTime(audio.currentTime);
        const setAudioVolume = () => setVolume(audio.volume);

        audio.addEventListener("loadeddata", setAudioData);
        audio.addEventListener("timeupdate", setAudioTime);
        audio.addEventListener("volumechange", setAudioVolume);

        playing ? audio.play() : audio.pause();

        if (clickedTime && clickedTime !== curTime) {
            audio.currentTime = clickedTime;
            setClickedTime(null);
        }

        if (clickedVolume && clickedVolume !== volume) {
            audio.volume = clickedVolume;
            setClickedVolume(null);
        }

        return () => {
            audio.removeEventListener("loadeddata", setAudioData);
            audio.removeEventListener("timeupdate", setAudioTime);
            audio.addEventListener("volumechange", setAudioVolume);
        };
    }, [clickedTime, clickedVolume, curTime, playing, volume]);

    return {
        volume,
        curTime,
        duration,
        playing,
        setPlaying,
        setClickedTime,
        setClickedVolume,
    };
}
