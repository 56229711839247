import React, { ReactNode } from "react";
import { Button, ButtonProps } from "./Button";

interface SecondaryButtonProps extends ButtonProps {
    icon?: ReactNode;
    onClick?: (...arguments_: any[]) => any;
    children: ReactNode;
}

export const SecondaryButton = ({
    type = "button",
    size = "medium",
    onClick,
    children,
    ...rest
}: SecondaryButtonProps) => (
    <Button type={type} variant="outline" color="secondary" size={size} onClick={onClick} {...rest}>
        {children}
    </Button>
);
