import React, { useMemo, useState } from "react";
import { ComboBox, Label, Modal, FormGroup, SecondaryButton, SubmitButton, flash /* , Icon, Icons */ } from "Uikit";
import { UserListResponse } from "Api/Responses/UserResponse";
import { Except } from "type-fest";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { useMutation, useQuery } from "react-query";
import Api from "Api/index";
import { SingleValue } from "react-select";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { MoveUserRequest } from "Api/Requests/TeamRequest";
import { useInvalidate } from "hooks/useInvalidate";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { flattenTeams } from "models/Team";
import { TVoidFunction } from "types";

interface MoveMembersModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    users?: UserListResponse[];
    hideTeamMembers?: boolean;
}

export const MoveMembersModal = ({ isOpen, onClose, ...rest }: MoveMembersModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Перемещение участников" className="!max-w-142">
            <ModalContent onClose={onClose} {...rest} />
        </Modal>
    );
};

// const treeHeader = () => (
//     <div className="flex justify-between text-gray-text uppercase p4-table">
//         <div className="flex items-center">
//             <div className="flex justify-center min-w-7">
//                 <Icon color="fill-primary" icon={Icons.Subtract} width={14} height={14} />
//             </div>
//             <span>команда</span>
//         </div>

//         <div className="flex items-center pr-[42px]">
//             <span>Кол-во участников</span>
//         </div>
//     </div>
// );

const getRootTeam = (
    subTeamId: string,
    teamsWhereSearch: TeamTreeAllBasicInfoResponse[],
): TeamTreeAllBasicInfoResponse | null => {
    let rootTeam: TeamTreeAllBasicInfoResponse | null = null;
    teamsWhereSearch.forEach((team) => {
        const find = (subTeamId: string, teamWhereSearch: TeamTreeAllBasicInfoResponse) => {
            if (
                subTeamId === team.id ||
                (teamWhereSearch.subTeams.length > 0 &&
                    teamWhereSearch.subTeams.some(({ id: subTeamsItemId }) => {
                        return subTeamsItemId === subTeamId;
                    }))
            ) {
                rootTeam = team;
            }
            teamWhereSearch.subTeams.forEach((teamItem) => {
                find(subTeamId, teamItem);
            });
        };
        return find(subTeamId, team);
    });
    return rootTeam;
};

const ModalContent = ({ onClose, users = [], hideTeamMembers = false }: Except<MoveMembersModalProps, "isOpen">) => {
    const invalidate = useInvalidate();
    const [rootTeam, setRootTeam] = useState<TeamTreeAllBasicInfoResponse>();
    const [destinationTeam, setDestinationTeam] = useState<TeamTreeAllBasicInfoResponse>();

    const { mutateAsync: update } = useMutation(() => {
        const usersIds = users.map((user) => user.id);
        // Команды выбранных пользователей - [{ [id команды]: [id пользователей], ... }]
        const usersTeams = users.reduce((prev: Record<string, string[]>, cur) => {
            const { id: userId, teams: curTeams } = cur;

            curTeams.forEach((team) => {
                if (prev[team.id]) {
                    prev[team.id] = [...prev[team.id], userId];
                } else {
                    prev[team.id] = [userId];
                }
            });

            return prev;
        }, {});
        const fromTeam: Record<string, string[]> = {};
        const toTeam: Record<string, string[]> = {};
        const payload = {
            fromTeam,
            toTeam,
        };
        const destinationRootTeam = getRootTeam(destinationTeam?.id as string, teams as TeamTreeAllBasicInfoResponse[]);
        // Перемещение внутри одной команды
        Object.keys(usersTeams).forEach((key) => {
            const rootTeam = getRootTeam(key, teams as TeamTreeAllBasicInfoResponse[]);
            if (rootTeam?.id === destinationRootTeam?.id) {
                payload.fromTeam = {
                    ...payload.fromTeam,
                    [key]: usersTeams[key],
                };
                payload.toTeam = {
                    ...payload.toTeam,
                    [destinationTeam!.id]: usersIds,
                };
            }
        });

        return Api.Teams.MoveUser(Object.assign(new MoveUserRequest(), payload));
    });

    const { data: teams } = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );
    const rootTeamOptions = (teams ?? []).map((team) => ({ label: team.name, value: team.id }));

    const handleSubmit = async () => {
        try {
            await update();

            invalidate("teams");
            invalidate("members");
            onClose();
        } catch (error) {
            if (error instanceof BadRequestResponse) {
                if (error.errorCode === ErrorCode.WRONG_TEAM_TRANSITION) {
                    flash.error("Users can't be moved between teams with different parents");
                } else {
                    flash.error("Неизвестная ошибка");
                }
            } else {
                flash.error("Неизвестная ошибка");
            }
        }
    };

    const onlyIncludes = useMemo(() => {
        return rootTeam ? flattenTeams([rootTeam]).map((team) => team.id) : [];
    }, [rootTeam]);

    const value = useMemo(() => (destinationTeam ? [destinationTeam.id] : []), [destinationTeam]);

    const handleDestinationChange = (teams: any[]) => {
        const allTeams = flattenTeams([rootTeam!]);
        const value = allTeams.find((team) => team.id === (teams[0]?.value ?? teams[0]?.replace("root:", "")));

        setDestinationTeam(value);
    };

    return (
        <>
            <Modal.Body>
                <div className="mt-5" />
                <FormGroup id="adminMembersMoveGroupFrom">
                    <Label isRequired>Корневая команда, в которой будет происходить перемещение</Label>
                    <ComboBox
                        placeholder="Выберите корневую команду"
                        options={rootTeamOptions}
                        value={rootTeamOptions.find((option) => option.value === rootTeam?.id)}
                        onChange={(option: SingleValue<any>) => {
                            setDestinationTeam(undefined);
                            setRootTeam((teams ?? []).find((team) => team.id === option.value));
                        }}
                        maxMenuHeight={247}
                        id="adminMembersMoveComboBoxFrom"
                    />
                </FormGroup>
                {rootTeam && (
                    <FormGroup id="adminMembersMoveGroupTo">
                        <Label isRequired>Куда</Label>
                        <TeamMultiSelect
                            onChange={handleDestinationChange}
                            checkable={false}
                            selectable
                            isMulti={false}
                            onlyIncludes={onlyIncludes}
                            value={value}
                            // treeHeader={treeHeader}
                            id="adminMembersMoveComboBoxTo"
                            showTeamsCounter={false}
                            title="Выбор команды"
                            hideMembers={hideTeamMembers}
                        />
                    </FormGroup>
                )}
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton onClick={onClose} id="adminMembersBtnCancelMove">
                    Отмена
                </SecondaryButton>
                <SubmitButton disabled={!destinationTeam} onClick={handleSubmit} id="adminMembersBtnMove">
                    Переместить
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};
