import React, { useState, useEffect, ChangeEvent, forwardRef, useImperativeHandle, useRef } from "react";
import { SingleValue } from "react-select";
import { Input, Label, Toggle, FormGroup, ComboBox, Section, Icon, Icons } from "Uikit";
import { extendedRegexp } from "Uikit/Forms/Input";
import { TestRequest } from "Api/Requests/TestRequest";
import { QuizType } from "Enums";

const quizTypes = [
    {
        label: "Линейное",
        value: QuizType.LINEAR,
    },
    {
        label: "Произвольное",
        value: QuizType.LOOSE,
    },
];

interface ITestTesting {
    errors: any;
    test: TestRequest;
    onChange: (test: TestRequest) => void;
    isMaxAttemptsEnabled: boolean;
    setIsMaxAttemptsEnabled: (arg: boolean) => void;
}

type TTestPassingSetting = number | null | boolean;

const toggleTestPassingSettings = (
    changedSettings: Record<string, TTestPassingSetting>,
    droppedSettings: Record<string, TTestPassingSetting>,
    test: TestRequest,
    onChange: (test: TestRequest) => void,
) => {
    onChange({
        ...test,
        ...droppedSettings,
        ...changedSettings,
    });
};
const droppedSettings = {
    quizTimeLimit: null,
    questionTimeLimit: null,
};

type TTimeLimitInputValue = number | undefined;

interface ITestTestingTimeLimitInputProps {
    value: number;
    onChange: (arg: TTimeLimitInputValue | null) => void;
    getFieldValueOnChange: (e: ChangeEvent<HTMLInputElement>) => TTimeLimitInputValue;
    getFieldValueOnBlur?: (e: ChangeEvent<HTMLInputElement>) => TTimeLimitInputValue;
    mins?: {
        onBlur?: number;
    };
}
// Инпут ограничения времени
// "Ограничить время прохождения теста" (мин./сек.), "Ограничить время прохождения вопроса" ( мин./сек.)
const TestTestingTimeLimitInput = forwardRef(
    (
        {
            value,
            onChange,
            getFieldValueOnChange,
            getFieldValueOnBlur,
            mins = {
                onBlur: 0,
            },
        }: ITestTestingTimeLimitInputProps,
        ref,
    ) => {
        const [localValue, setLocalValue] = useState<TTimeLimitInputValue>(value);

        useEffect(() => {
            onChange(localValue ?? 0);
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, [localValue]);

        useImperativeHandle(ref, () => ({
            setLocalValue(val: TTimeLimitInputValue) {
                setLocalValue(val);
            },
        }));

        return (
            <Input
                className="max-w-30"
                type="number"
                value={localValue}
                onChange={(e) => {
                    const val = getFieldValueOnChange(e);
                    setLocalValue(val);
                }}
                onBlur={(e) => {
                    if (getFieldValueOnBlur) {
                        const val = getFieldValueOnBlur(e);

                        setLocalValue(val);
                    } else {
                        if (!localValue) {
                            setLocalValue(mins.onBlur);
                        }
                    }
                }}
                id="adminNewTesttestingTabInputTestSec"
            />
        );
    },
);

export const TestTesting = ({
    errors,
    test,
    onChange,
    isMaxAttemptsEnabled,
    setIsMaxAttemptsEnabled,
}: ITestTesting) => {
    const questionTimeLimitSecondsRef = useRef();

    return (
        <>
            <Section label="Настройки прохождения теста">
                <FormGroup>
                    <Label isRequired={true}>Прохождение теста</Label>
                    <ComboBox
                        isSearchable
                        value={quizTypes[quizTypes.findIndex((p) => test.quizType === p.value)]}
                        options={quizTypes}
                        onChange={(option: SingleValue<any>) => {
                            const values: {
                                quizType: QuizType;
                                quizTimeLimit?: number | null;
                                questionTimeLimit?: number | null;
                            } = {
                                quizType: option.value,
                            };
                            if (option.value === QuizType.LOOSE) {
                                values.quizTimeLimit = null;
                                values.questionTimeLimit = null;
                            }
                            onChange({ ...test, ...values });
                        }}
                        error={errors["quizType"]}
                        id="adminNewTesttestingTabComboBoxQuizType"
                    />
                </FormGroup>
                <FormGroup>
                    <Label isRequired={true}>Необходимый процент правильных ответов, чтобы сдать тест</Label>
                    <Input
                        placeholder="Введите числовое значение от 0 до 100"
                        cutRegExp={new RegExp(/\D/gi)}
                        value={test.passPercentage}
                        onChange={(e) => {
                            let str = e.target.value;
                            if (str.match(/^0+\d/g)) {
                                if (str.match(/^0+$/g)) {
                                    str = "0";
                                } else {
                                    str = str.replaceAll(/^0+/g, "");
                                }
                            }
                            onChange({ ...test, passPercentage: str });
                        }}
                        error={errors["passPercentage"]}
                        id="adminNewTesttestingTabInputPercentage"
                        min={0}
                        max={100}
                    />
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Ограничить время прохождения теста"
                        enabled={test.quizTimeLimit !== null}
                        onChange={(p) => {
                            toggleTestPassingSettings(
                                { quizTimeLimit: p ? 60 : null },
                                droppedSettings,
                                test,
                                onChange,
                            );
                        }}
                        id="adminNewTesttestingTabToggleLimitTest"
                    />
                    {test.quizTimeLimit !== null && (
                        <div className="flex items-center space-x-3.5">
                            <TestTestingTimeLimitInput
                                value={Math.floor(test.quizTimeLimit / 60)}
                                getFieldValueOnChange={(e) => {
                                    let val: number | undefined = Number(e.target.value);
                                    const min = undefined;
                                    const limit = 480;
                                    // val = val <= min ? min : val > limit ? limit : val;
                                    val = e.target.value === "" ? min : val > limit ? limit : val;

                                    return val;
                                }}
                                getFieldValueOnBlur={(e) => {
                                    let val = Number(e.target.value);
                                    const min = 1;
                                    if (val < min) {
                                        val = min;
                                    }

                                    return val;
                                }}
                                onChange={(val) => {
                                    onChange({
                                        ...test,
                                        quizTimeLimit: Number(val) * 60 + (test.quizTimeLimit! % 60),
                                    });
                                }}
                            />
                            &nbsp;мин
                            <TestTestingTimeLimitInput
                                value={test.quizTimeLimit % 60}
                                getFieldValueOnChange={(e) => {
                                    let val: number | undefined = Number(e.target.value);
                                    const min = undefined;
                                    const limit = 59;
                                    // val = val <= min ? min : val > limit ? limit : val;
                                    val = e.target.value === "" ? min : val > limit ? limit : val;

                                    return val;
                                }}
                                onChange={(val) => {
                                    onChange({
                                        ...test,
                                        quizTimeLimit: test.quizTimeLimit! - (test.quizTimeLimit! % 60) + Number(val),
                                    });
                                }}
                            />
                            &nbsp;сек
                        </div>
                    )}
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className={`mr-auto ${test.quizType === QuizType.LOOSE && "opacity-50"}`}
                        label="Ограничить время прохождения вопроса"
                        enabled={test.questionTimeLimit !== null}
                        disabled={test.quizType === QuizType.LOOSE}
                        onChange={(p) => {
                            toggleTestPassingSettings(
                                { questionTimeLimit: p ? 60 : null },
                                droppedSettings,
                                test,
                                onChange,
                            );
                        }}
                        id="adminNewTesttestingTabToggleLimitQuestion"
                    />
                    {test.questionTimeLimit !== null && (
                        <div className="flex items-center space-x-3.5">
                            <TestTestingTimeLimitInput
                                value={Math.floor(test.questionTimeLimit / 60)}
                                getFieldValueOnChange={(e) => {
                                    let val: number | undefined = Number(e.target.value);
                                    const min = undefined;
                                    const limit = 480;
                                    // val = val <= min ? min : val > limit ? limit : val;
                                    val = e.target.value === "" ? min : val > limit ? limit : val;

                                    return val;
                                }}
                                getFieldValueOnBlur={(e) => {
                                    let val = 0;
                                    const limit = 5;
                                    if (Number(test.questionTimeLimit) <= limit) {
                                        onChange({
                                            ...test,
                                            questionTimeLimit: limit,
                                        });
                                        if (questionTimeLimitSecondsRef?.current) {
                                            (
                                                questionTimeLimitSecondsRef.current as {
                                                    setLocalValue: (arg: TTimeLimitInputValue) => void;
                                                }
                                            ).setLocalValue(limit);
                                        }
                                    }
                                    val = Number(e.target.value);

                                    return val;
                                }}
                                onChange={(val) => {
                                    onChange({
                                        ...test,
                                        questionTimeLimit: Number(val) * 60 + (test.questionTimeLimit! % 60),
                                    });
                                }}
                            />
                            &nbsp;мин
                            <TestTestingTimeLimitInput
                                value={test.questionTimeLimit % 60}
                                getFieldValueOnChange={(e) => {
                                    let val: number | undefined = Number(e.target.value);
                                    const min = undefined;
                                    const limit = 59;
                                    // val = val <= min ? min : val > limit ? limit : val;
                                    val = e.target.value === "" ? min : val > limit ? limit : val;

                                    return val;
                                }}
                                onChange={(val) => {
                                    const questionTimeLimitValue =
                                        test.questionTimeLimit! - (test.questionTimeLimit! % 60) + Number(val);
                                    const limit = 5;
                                    onChange({
                                        ...test,
                                        questionTimeLimit:
                                            questionTimeLimitValue <= limit ? limit : questionTimeLimitValue,
                                    });
                                }}
                                getFieldValueOnBlur={(e) => {
                                    let val = 0;
                                    const limit = 5;
                                    if (Number(test.questionTimeLimit) <= limit) {
                                        return limit;
                                    }
                                    val = Number(e.target.value);

                                    return val;
                                }}
                                ref={questionTimeLimitSecondsRef}
                            />
                            &nbsp;сек
                        </div>
                    )}
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Отображать правильность ответов в процессе прохождения"
                        enabled={test.showMistakesDuringQuiz}
                        onChange={(p) => {
                            onChange({
                                ...test,
                                showMistakesDuringQuiz: p,
                            });
                        }}
                        id="adminNewTesttestingTabToggleShowRightsProc"
                    />
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Показать ошибки пользователя после прохождения"
                        enabled={test.showMistakesAfterQuiz}
                        onChange={(p) => {
                            onChange({
                                ...test,
                                showMistakesAfterQuiz: p,
                            });
                        }}
                        id="adminNewTesttestingTabToggleShowRightsAfter"
                    />
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Не назначать баллы рейтинга за прохождения теста"
                        enabled={test.notRated}
                        onChange={(p) => {
                            onChange({
                                ...test,
                                notRated: p,
                            });
                        }}
                        id="adminNewTesttestingTabToggleNoPoints"
                    />
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Перемешать вопросы"
                        tip="Каждый участник получит индивидуальный порядок вопросов"
                        enabled={test.shuffledQuestions}
                        onChange={(p) => {
                            onChange({
                                ...test,
                                shuffledQuestions: p,
                            });
                        }}
                        id="adminNewTesttestingTabToggleMixQuestions"
                    />
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Перемешать ответы внутри вопросов"
                        tip="Каждый участник получит индивидуальный порядок ответов на вопрос"
                        enabled={test.shuffledAnswers}
                        onChange={(p) => {
                            onChange({
                                ...test,
                                shuffledAnswers: p,
                            });
                        }}
                        id="adminNewTesttestingTabToggleShowmixAnswers"
                    />
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Ограничить количество попыток прохождения"
                        tip="Перепроходить тест можно бесконечно или указанное количество раз"
                        enabled={isMaxAttemptsEnabled}
                        onChange={(p) => {
                            const testUpdated = test.maxAttempts
                                ? test
                                : {
                                      ...test,
                                      maxAttempts: 1,
                                  };
                            onChange(testUpdated);
                            setIsMaxAttemptsEnabled(p);
                        }}
                        id="adminNewTesttestingTabToggleLimitIn"
                    />
                    {isMaxAttemptsEnabled && (
                        <div className="flex items-center">
                            <Input
                                className="max-w-30 text-center"
                                type="number"
                                value={test.maxAttempts as number | undefined}
                                onChange={(e) => {
                                    let val: number | undefined = Number(e.target.value);
                                    const min = 0;
                                    const limit = 99;
                                    val = val <= min ? undefined : val > limit ? limit : val;

                                    onChange({ ...test, maxAttempts: val });
                                }}
                                onBlur={(e) => {
                                    let val = Number(e.target.value);
                                    const min = 1;

                                    if (val < min) {
                                        val = min;
                                    }
                                    onChange({ ...test, maxAttempts: val });
                                }}
                                id="adminNewTestTestingTabInputLimitIn"
                                before={
                                    <div
                                        className={`cursor-pointer ${
                                            test.maxAttempts === 1 && " pointer-events-none cursor-default opacity-50"
                                        }`}
                                        onClick={() => {
                                            const val =
                                                test.maxAttempts && test.maxAttempts > 1 ? test.maxAttempts - 1 : null;
                                            onChange({
                                                ...test,
                                                maxAttempts: val,
                                            });
                                        }}
                                        id="adminNewMaterialBtnDecrementLimit"
                                    >
                                        <Icon icon={Icons.Minus} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                                after={
                                    <div
                                        className={`cursor-pointer ${
                                            test.maxAttempts === 99 && " pointer-events-none cursor-default opacity-50"
                                        }`}
                                        onClick={() => {
                                            const val = test.maxAttempts ? test.maxAttempts + 1 : 1;
                                            onChange({
                                                ...test,
                                                maxAttempts: val,
                                            });
                                        }}
                                        id="adminNewMaterialBtnIncrementLimit"
                                    >
                                        <Icon icon={Icons.Plus} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                                error={errors["maxAttempts"]}
                            />
                        </div>
                    )}
                </FormGroup>
            </Section>
            <Section label="Сообщение после прохождения">
                <FormGroup>
                    <Label>Сообщение при успешном прохождении</Label>
                    <Input
                        placeholder="Поздравляем! Тест успешно пройден"
                        value={test.successMessage}
                        onChange={(e) => onChange({ ...test, successMessage: e.target.value })}
                        id="adminNewTesttestingTabInputSuccess"
                        maxLength={512}
                        cutRegExp={extendedRegexp}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Сообщение при неуспешном прохождении</Label>
                    <Input
                        placeholder="К сожалению, тест был провален"
                        value={test.failureMessage}
                        onChange={(e) => onChange({ ...test, failureMessage: e.target.value })}
                        id="adminNewTesttestingTabInputFail"
                        maxLength={512}
                        cutRegExp={extendedRegexp}
                    />
                </FormGroup>
            </Section>
        </>
    );
};
