import React, { ReactNode } from "react";
import clsx from "clsx";

interface LabelProps {
    className?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    isError?: boolean;
    children?: ReactNode;
}

export const Label = ({
    isDisabled = false,
    isRequired = false,
    // isError = false,
    children,
    className,
    ...rest
}: LabelProps) => {
    return (
        <label
            className={clsx(
                "ui-label",
                className,
                "pb-1.5 block text-sm font-normal text-gray-dark",
                isDisabled && "text-gray",
                // isError && "text-danger",
            )}
            {...rest}
        >
            {children} {isRequired ? <span className="text-red">*</span> : null}
        </label>
    );
};
