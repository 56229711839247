import React from "react";
import { SideMenu } from "Uikit";
import { Courses as StatisticsCourses } from "./Group/Courses/Courses";
import { Materials as StatisticsMaterials } from "./Group/Materials/Materials";
import { Tests as StatisticsTests } from "./Group/Tests/Tests";
import { Tasks as StatisticsTasks } from "./Group/Tasks/Tasks";
import { Programs as StatisticsPrograms } from "./Group/Programs/Programs";
import { TestTeams as StatisticsTestTeams } from "./Group/Tests/teams/TestTeams";
import { TestAnswers as StatisticsTestAnswers } from "./Group/Tests/answers/TestAnswers";

export const Statistics = () => {
    return (
        <div className="h-full">
            <SideMenu id="statisticsMenu">
                <SideMenu.Group title="Программы" path="" content={""} open>
                    <SideMenu.Item title="Отчет по программам" path="programs" content={<StatisticsPrograms />} />
                </SideMenu.Group>
                <SideMenu.Group title="Курсы" path="" content={""} open>
                    <SideMenu.Item title="Отчет по курсам" path="courses" content={<StatisticsCourses />} />
                    {/*<SideMenu.Item title="Отчет по участнику" path="courses-user" content={<StatisticsUser />} />*/}
                </SideMenu.Group>
                <SideMenu.Group title="Материалы" path="" content={""} open>
                    <SideMenu.Item title="Отчет по материалам" path="materials" content={<StatisticsMaterials />} />
                </SideMenu.Group>
                <SideMenu.Group title="Тесты" path="" content={""} open>
                    <SideMenu.Item title="Отчет по тестам" path="tests" content={<StatisticsTests />} />
                    <SideMenu.Item title="Отчет по ответам" path="test-answers" content={<StatisticsTestAnswers />} />
                    {/*<SideMenu.Item title="Отчет по участнику" path="test-user" content={<StatisticsTestsUser />} />*/}
                    <SideMenu.Item title="Отчет по командам" path="test-teams" content={<StatisticsTestTeams />} />
                </SideMenu.Group>
                <SideMenu.Group title="Задания" path="" content={""} open>
                    <SideMenu.Item title="Отчет по заданиям" path="tasks" content={<StatisticsTasks />} />
                </SideMenu.Group>
            </SideMenu>
        </div>
    );
};
