import React, { useEffect, useState } from "react";
import { ComboBox } from "Uikit";

const defaultMultiState: any = [];

export const SelectField = ({
    isMulti,
    placeholder,
    options,
    accessor,
    value,
    onChange,
    components,
    defaultValue,
    isClearable = false,
    menuIsOpen = undefined,
    onClick,
    isSearchable,
}: any) => {
    const [option, setOption] = useState(value ? value : isMulti ? defaultMultiState : undefined);

    useEffect(() => {
        if (value === undefined) {
            setOption(isMulti ? defaultMultiState : undefined);
            return;
        }

        setOption(value);
    }, [isMulti, options, value]);
    useEffect(() => {
        if (!option) {
            return;
        }

        if ((isMulti && option.length === 0) || (!isMulti && option.value === undefined)) {
            onChange(accessor, undefined);
            return;
        }

        onChange(accessor, option);
    }, [isMulti, accessor, option, onChange]);

    return (
        <ComboBox
            className="mt-3 sm:mt-4"
            placeholder={placeholder}
            isCreatable={false}
            isMulti={isMulti}
            options={options}
            value={option}
            onChange={(option) => setOption(option)}
            components={components}
            defaultInputValue={defaultValue}
            isClearable={isClearable}
            menuIsOpen={menuIsOpen}
            onClick={onClick}
            isSearchable={isSearchable}
        />
    );
};
