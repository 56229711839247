import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useInvalidate } from "hooks/useInvalidate";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { TeamsList } from "../Teams/TeamsList";
import Api from "Api/index";

export const TeamNested = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const invalidate = useInvalidate();
    const { id } = useParams();

    const dataQuery = useQuery(
        ["teams", "tree", "page-in-user-space", "collection", id],
        async () => await Api.Teams.getTeam(id as string),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const onCardClick = (id: string) => {
        invalidate("team");
        navigate(`/team/${id}`);
    };

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Вложенные команды"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    if (!dataQuery.data) {
        return null;
    }

    return (
        <div className="py-3 px-3">
            <TeamsList teams={dataQuery.data?.subTeams} onClick={onCardClick} />
        </div>
    );
};
