import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { MenuItem } from "Containers/Menu/Menu";
import { Icon, Icons } from "Uikit";

export const MobileMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menuItems = useMemo<MenuItem[]>(() => {
        return [
            {
                Id: "mobileMenuTrainingLink",
                Title: "Мое обучение",
                Icon: Icons.UserMenuIconTraining,
                Path: "/training",
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
            },
            {
                Id: "mobileMenuTestsLink",
                Title: "Тесты",
                Icon: Icons.UserMenuIconTest,
                Path: "/tests",
                ActivePaths: ["/test"],
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
            },
            {
                Id: "mobileMenuTasksLink",
                Title: "Задания",
                Icon: Icons.UserMenuIconTask,
                Path: "/tasks",
                ActivePaths: ["/task"],
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
            },
            {
                Id: "mobileMenuTeamsLink",
                Title: "Мои команды",
                Icon: Icons.UserMenuIconTeam,
                Path: "/teams",
                ActivePaths: ["/teams", "/team"],
                Count: NaN,
                IsSeparator: true,
                IsBottom: false,
            },
            {
                Id: "mobileMenuRatingLink",
                Title: "Рейтинг",
                Icon: Icons.UserMenuIconRating,
                Path: "/rating",
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
            },
        ];
    }, []);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(false));
        dispatch(setTitle("Меню"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    return (
        <div className="flex flex-col py-5 px-3 w-full bg-white">
            {menuItems.map((item) => {
                return (
                    <div
                        key={item.Id}
                        id={item.Id}
                        className={clsx(
                            "flex justify-between items-center py-1.25 mb-5",
                            item.IsBottom && "mt-auto",
                            item.IsSeparator && "pt-5 border-t border-gray-blue",
                        )}
                        onClick={() => navigate(item.Path)}
                    >
                        <div className="flex items-center">
                            <Icon className="mr-4" icon={item.Icon} width={22} height={22} color="fill-gray-dark" />
                            <span className="p2-accent text-black">{item.Title}</span>
                        </div>
                        <Icon icon={Icons.ChevronRight} width={14} height={14} color="fill-blue" />
                    </div>
                );
            })}
        </div>
    );
};
