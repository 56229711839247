import React, { ReactNode } from "react";
import clsx from "clsx";
import { useScreenSize } from "hooks/useMediaQuery";

const columnProportions = {
    "50/50": "w-full",
    "40/50": "w-4/5",
    "30/50": "w-3/5",
    "20/60": "w-2/5",
};

interface SectionProps {
    id?: string;
    label?: ReactNode;
    description?: ReactNode;
    className?: string;
    children?: ReactNode;
    columnProportion?: keyof typeof columnProportions;
    labelElement?: ReactNode;
    rightColumnClassName?: string;
    isRequired?: boolean;
    leftColumnClassName?: string;
}

export const Section = ({
    id,
    label = "",
    description,
    className,
    children,
    columnProportion,
    labelElement,
    rightColumnClassName,
    isRequired,
    leftColumnClassName = "",
}: SectionProps) => {
    const { size } = useScreenSize();
    const isLarge = size === "large";

    return (
        <div className={clsx("w-full flex border-b border-blue-gray sm:py-6 last:border-b-0 last:mb-30", className)}>
            <div
                className={clsx(
                    columnProportion ? columnProportions[columnProportion] : "min-w-75",
                    "hidden sm:block",
                    leftColumnClassName,
                )}
            >
                {labelElement ? (
                    <>{labelElement}</>
                ) : (
                    <>
                        <div className={`pr-6 ${isLarge ? "text-md" : ""}`}>
                            {label} {isRequired ? <span className="text-red">*</span> : null}
                        </div>
                        <div>
                            <span className="text-gray-light text-sm">{description}</span>
                        </div>
                    </>
                )}
            </div>
            <div className={clsx("mx-auto w-full", rightColumnClassName ?? " max-w-175")} id={id}>
                {children}
            </div>
            <div className="hidden sm:block flex-1 max-w-75" />
        </div>
    );
};
