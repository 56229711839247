export function passwordValidator(password: string, required = true) {
    if (!required && (!password || password.trim().length === 0)) {
        return null;
    }

    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,120}$/.test(password)) {
        return "Длина пароля должна быть от 8 до 120 символов, и он должен содержать как минимум одну прописную букву, одну заглавную букву и одну цифру.";
    }

    return null;
}

export const generatePassword = (len: number) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result;

    do {
        result = "";

        for (let i = 0; i < len; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
    } while (passwordValidator(result) !== null);

    return result;
};
