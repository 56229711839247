import React, { useState, useEffect } from "react";
import { Modal, Button, FormGroup, Label, ComboBox } from "Uikit";
import { IOption, TVoidFunction } from "types";

interface IArchiveRestoreModal {
    isOpen: boolean;
    items: string | { id: string; title: string; categoryId: string }[] | undefined;
    categories: IOption[];
    onRestore: (category: IOption | null) => void;
    onClose: TVoidFunction;
}
export const ArchiveRestoreModal = ({ isOpen, items, categories, onRestore, onClose }: IArchiveRestoreModal) => {
    const [category, setCategory] = useState<IOption | null>(null);

    const onCloseClick = () => {
        setCategory(null);
        onClose();
    };

    const isMultiple = Array.isArray(items) && items.length > 1;

    useEffect(() => {
        if (!isMultiple) {
            setCategory(
                categories.find(
                    ({ value }) =>
                        value === (items?.[0] as { id: string; title: string; categoryId: string })?.categoryId,
                ) ?? null,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
        <Modal
            className="!max-w-150 overflow-visible"
            title="Восстановление"
            isOpen={isOpen}
            setIsOpen={onCloseClick}
            onClose={onCloseClick}
            description={
                isMultiple
                    ? `Выбрано элементов: ${Array.isArray(items) ? items.length : 1}`
                    : `«${(items?.[0] as { id: string; title: string; categoryId: string })?.title}»`
            }
        >
            <Modal.Body>
                <div>
                    <FormGroup>
                        <Label isRequired={true}>Категория, в которую восстановить элементы</Label>
                        <ComboBox
                            className="mt-0.5"
                            placeholder="Выберите категорию"
                            isCreatable={false}
                            isMulti={false}
                            options={categories}
                            value={category}
                            onChange={(option) => setCategory(option)}
                            id="adminArchiveRestoreModalComboBox1"
                        />
                    </FormGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex flex-grow text-sm text-gray-text items-center">
                    Статус будет изменен на «Скрыт»
                </div>
                <Button
                    variant="outline"
                    color="secondary"
                    onClick={onCloseClick}
                    id="adminArchiveRestoreModalBtnCancel"
                >
                    Отмена
                </Button>
                <Button disabled={!category} onClick={() => onRestore(category)} id="adminArchiveRestoreModalBtnOk">
                    Восстановить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
