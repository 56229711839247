import React, { useEffect, useRef, useState, useId } from "react";
import { Button, ComboBox, flash, FormGroup, Icon, Icons, Label, TextareaAutoSizable, Toggle } from "Uikit";
import { TestRequestQuestion, TestRequestQuestionAnswer } from "Api/Requests/TestRequest";
import { SingleValue } from "react-select";
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    UniqueIdentifier,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { TestContentItemSelectText } from "./TestContentItemSelectText";
import { TestContentItemSelectImage } from "./TestContentItemSelectImage";
import clsx from "clsx";
import { testingRegexp } from "Uikit/Forms/Input";
import { IOption, TVoidFunction } from "types";
import { getFileSizeErrorMessage } from "helpers/file";

interface ITestContentItemSelect {
    errors: any;
    isMulti: boolean;
    complexity: IOption[];
    question: TestRequestQuestion;
    onEdit: (question: TestRequestQuestion) => void;
    expanded?: boolean;
}

interface ITestContentImageAddProps {
    error: string;
    onClick: TVoidFunction;
}

const TestContentImageAdd = ({ error, onClick }: ITestContentImageAddProps) => {
    return (
        <div
            className={clsx(
                "flex justify-center items-center w-45 h-45 bg-gray-blue rounded-2lg cursor-pointer",
                error ? "border-3 border-red" : "",
            )}
            onClick={onClick}
        >
            <Icon icon={Icons.Plus} width={42} height={42} color="fill-gray-light" />
        </div>
    );
};

export const TestContentItemSelect = ({
    errors,
    isMulti,
    complexity,
    question,
    onEdit,
    expanded = false,
}: ITestContentItemSelect) => {
    const inputRef = useRef(null);
    const answerRef = useRef(null);
    const id = useId();

    const [isImages, setIsImages] = useState<boolean>(false);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const onChangeLogo = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (!RegExp(/^image\//).exec(files[0].type)) {
            flash.error("Для загрузки допустимы следующие форматы - JPG, PNG.");
            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2 Мб"));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => onEdit({ ...question, logoId: { file: files[0], base64: reader.result!.toString() } });
    };
    const onChangeAnswerPicture = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (!RegExp(/^image\//).exec(files[0].type)) {
            flash.error("Для загрузки допустимы следующие форматы - JPG, PNG.");
            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2 Мб"));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            const answers: TestRequestQuestionAnswer[] = Object.assign(question.answers);
            answers.push({
                id: answers.length.toString(),
                pictureId: { file: files[0], base64: reader.result!.toString() },
                answerText: "",
                isCorrect: answers.length === 0,
            });
            onEdit({ ...question, answers });
        };
    };
    const onDragEnd = (event: DragEndEvent) => {
        const answers = Object.assign(question.answers);
        const { active, over } = event;

        if (active.id === over?.id) {
            return;
        }

        const oldIndex = question.answers.findIndex((p) => p.id === active.id);
        const newIndex = question.answers.findIndex((p) => p.id === over?.id);

        onEdit({ ...question, answers: arrayMove(answers, oldIndex, newIndex) });
    };

    const onAddAnswer = () => {
        const answers: TestRequestQuestionAnswer[] = Object.assign(question.answers);
        answers.push({ id: "", pictureId: null, answerText: "", isCorrect: false });
        onEdit({ ...question, answers });
    };
    const onChangeAnswer = (index: number, answer: TestRequestQuestionAnswer) => {
        const answers: TestRequestQuestionAnswer[] = Object.assign(question.answers);

        if (!isMulti && answer.isCorrect) {
            answers.forEach((p) => (p.isCorrect = false));
        } else if (!isMulti && !answer.isCorrect && answers[index].isCorrect) {
            answer.isCorrect = true;
        }

        answers[index] = answer;
        onEdit({ ...question, answers });
    };
    const onDeleteAnswer = (index: number) => {
        const answers: TestRequestQuestionAnswer[] = Object.assign([], question.answers);
        answers.splice(index, 1);

        onEdit({ ...question, answers });
    };

    useEffect(() => {
        if (question.answers.filter((p) => p.pictureId).length === question.answers.length) {
            setIsImages(true);
        } else {
            setIsImages(false);
        }
    }, [question]);

    return (
        <>
            {!expanded && (
                <FormGroup>
                    <Label className="mb-4 !p-0 break-all">{question.questionText}</Label>
                    {question.logoId && (
                        <img
                            className="mb-4 w-40 h-22.5 rounded-lg object-cover"
                            src={
                                typeof question.logoId === "object"
                                    ? question.logoId.base64
                                    : "/service/lms-upload/api/file/download/" + question.logoId
                            }
                            alt={question.questionText}
                        />
                    )}
                    {!isImages && (
                        <div className="flex flex-col">
                            {question.answers.map((answer, index) => {
                                return (
                                    <div className="flex items-center mb-4 last:mb-0" key={index}>
                                        {isMulti && !answer.isCorrect && (
                                            <div className="mr-2 min-w-6 h-6 bg-white border border-gray-stroke rounded-md"></div>
                                        )}
                                        {isMulti && answer.isCorrect && (
                                            <div className="flex justify-center items-center mr-2 min-w-6 h-6 bg-blue rounded-md">
                                                <Icon icon={Icons.Check} width={20} height={20} color="fill-white" />
                                            </div>
                                        )}
                                        {!isMulti && (
                                            <input
                                                type="radio"
                                                readOnly
                                                id={id}
                                                checked={answer.isCorrect}
                                                className={clsx(
                                                    answer.isCorrect
                                                        ? "bg-[#1380CE] text-blue border-white border-2 ring-1 !ring-blue"
                                                        : "",
                                                    "mr-2 cursor-pointer radio w-6 h-6 border-gray-background ring-1 ring-input-stroke",
                                                    "focus:ring-offset-0 focus:ring-1 ring-offset-0 focus:ring-blue",
                                                )}
                                            />
                                        )}
                                        <span className="text-black break-all">{answer.answerText}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {isImages && (
                        <div className="flex">
                            {question.answers.map((answer, index) => {
                                return (
                                    <div key={index} className="relative mr-2.5 w-17.5 h-17.5">
                                        {answer.isCorrect && (
                                            <div className="absolute top-1.5 left-1.5 flex justify-center items-center w-6 h-6 bg-blue rounded-full cursor-pointer">
                                                <Icon icon={Icons.Check} width={12} height={8} color="fill-white" />
                                            </div>
                                        )}
                                        <img
                                            className="w-full h-full rounded-2lg object-cover"
                                            src={
                                                typeof answer.pictureId === "object"
                                                    ? answer.pictureId?.base64
                                                    : "/service/lms-upload/api/file/download/" + answer.pictureId
                                            }
                                            alt={answer.id}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </FormGroup>
            )}
            {expanded && (
                <>
                    <FormGroup className="flex items-center">
                        {question.logoId && (
                            <img
                                className="mr-6.5 w-40 h-22.5 rounded-lg object-cover"
                                src={
                                    typeof question.logoId === "object"
                                        ? question.logoId.base64
                                        : "/service/lms-upload/api/file/download/" + question.logoId
                                }
                                alt={question.questionText}
                            />
                        )}
                        <input
                            ref={inputRef}
                            id={id + "logoInput"}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, image/webp"
                            onChange={onChangeLogo}
                        />
                        <Button
                            variant="outline"
                            color="secondary"
                            className="mr-4"
                            onClick={() => (inputRef.current as unknown as HTMLInputElement).click()}
                            id={id + "adminNewTestContentTabBtnLoadImg"}
                        >
                            Загрузить картинку
                        </Button>
                        {question.logoId && (
                            <Button
                                variant="outline"
                                color="secondary"
                                className="mr-4"
                                onClick={() => onEdit({ ...question, logoId: "" })}
                                id={id + "adminNewTestContentTabBtnDeleteImg"}
                            >
                                Удалить
                            </Button>
                        )}
                        {!question.logoId && (
                            <div className="text-sm text-gray-text">
                                <p>Загрузите картинку вопроса</p>
                                <p>Поддерживаются файлы форматов JPG, PNG до 2Мб</p>
                            </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={true}>Текст вопроса</Label>
                        <TextareaAutoSizable
                            id={id + "adminNewTestContentTabInputTextQuestion"}
                            name="adminNewTestContentTabInputTextQuestion"
                            className="resize-none"
                            value={question.questionText}
                            placeholder="Введите текст вопроса"
                            cutRegExp={testingRegexp}
                            maxLength={1024}
                            error={errors["questionText"]}
                            onChange={(e) => onEdit({ ...question, questionText: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={true}>Сложность вопроса</Label>
                        <ComboBox
                            value={complexity[complexity.findIndex((p) => p.value === question.complexity)]}
                            options={complexity}
                            onChange={(option: SingleValue<any>) => onEdit({ ...question, complexity: option.value })}
                            id={id + "adminNewTestContentTabComboBoxComplexity"}
                            isSearchable={false}
                        />
                    </FormGroup>
                    <FormGroup className="pt-5.5 border-t border-gray-blue">
                        <Toggle
                            className="mb-1.5 mr-auto"
                            label="Список из изображений"
                            enabled={isImages}
                            onChange={(p) => {
                                if (!p) {
                                    onEdit({
                                        ...question,
                                        answers: [
                                            { id: "0", pictureId: null, answerText: "", isCorrect: true },
                                            { id: "1", pictureId: null, answerText: "", isCorrect: false },
                                        ],
                                    });
                                } else {
                                    onEdit({ ...question, answers: [] });
                                }

                                setIsImages(p);
                            }}
                            id={id + "adminNewTestContentTabToggleImgList"}
                        />
                        {!isImages && (
                            <div className="mt-4">
                                <Label isRequired={true}>Варианты ответа</Label>
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragOver={onDragEnd}
                                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                                >
                                    <SortableContext
                                        items={question.answers.map((item) => item.id as UniqueIdentifier)}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {question.answers.map((answer, index) => {
                                            return (
                                                <TestContentItemSelectText
                                                    key={index}
                                                    index={index}
                                                    isMulti={isMulti}
                                                    answer={answer}
                                                    deletable={question.answers.length > 2}
                                                    error={errors["answers"]}
                                                    onChange={onChangeAnswer}
                                                    onDelete={onDeleteAnswer}
                                                />
                                            );
                                        })}
                                    </SortableContext>
                                </DndContext>
                                <Button
                                    className="w-full"
                                    color="gray"
                                    iconPlacement="center"
                                    icon={<Icon className="mr-2" icon={Icons.PlusFilled} color="fill-blue" />}
                                    onClick={onAddAnswer}
                                    id={id + "adminNewTestContentTabBtnAddVar"}
                                >
                                    Добавить вариант
                                </Button>
                            </div>
                        )}
                        {isImages && (
                            // <div className="flex flex-wrap items-center [&>*:nth-child(3n)]:mr-0">
                            <div className="flex flex-wrap items-center gap-7.5 mt-6">
                                {question.answers.map((answer, index) => {
                                    return (
                                        <TestContentItemSelectImage
                                            key={index}
                                            index={index}
                                            answer={answer}
                                            deletable
                                            onChange={onChangeAnswer}
                                            onDelete={onDeleteAnswer}
                                        />
                                    );
                                })}
                                <input
                                    ref={answerRef}
                                    id={id + "answerInput"}
                                    className="hidden"
                                    type="file"
                                    accept="image/png, image/jpeg, image/webp"
                                    onChange={onChangeAnswerPicture}
                                />
                                {question.answers.length === 0 && (
                                    <TestContentImageAdd
                                        error={errors["answers"]}
                                        onClick={() => (answerRef.current as unknown as HTMLInputElement).click()}
                                    />
                                )}
                                {question.answers.length < 6 && (
                                    <TestContentImageAdd
                                        error={question.answers.length < 2 && errors["answers"]}
                                        onClick={() => (answerRef.current as unknown as HTMLInputElement).click()}
                                    />
                                )}
                            </div>
                        )}
                    </FormGroup>
                    {/*<FormGroup className="mt-1.5 pt-5.5 border-t border-gray-blue">*/}
                    {/*    <Label>Предложить материалы при ошибке:</Label>*/}
                    {/*    <Button*/}
                    {/*        className="mt-1 !p-0 h-auto border-0 !text-blue focus:!ring-0"*/}
                    {/*        variant="outline"*/}
                    {/*        color="common"*/}
                    {/*        iconPlacement="center"*/}
                    {/*        icon={<Icon className="mr-2" icon={Icons.PlusFilled} color="fill-blue" />}*/}
                    {/*    >*/}
                    {/*        Добавить материал*/}
                    {/*    </Button>*/}
                    {/*</FormGroup>*/}
                </>
            )}
        </>
    );
};
