import React, { useEffect, useState } from "react";
import api from "Api/index";
import { flash } from "Uikit/Notification/flash";
import { useParams } from "react-router-dom";
import { Loader } from "Uikit/Loader/Loader";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Button } from "Uikit/Button/Button";
import { useScreenSize } from "hooks/useScreenSize";
import clsx from "clsx";

const DEFAULT_COURSE_ID = 8347;

export const SkytrainerPage = () => {
    const size = useScreenSize();
    const { id } = useParams();
    const [iframeSrc, setIframeSrc] = useState<string>();

    useEffect(() => {
        (async () => {
            try {
                const { token } = await api.SkyTrainerApi.getToken();
                setIframeSrc(
                    `https://app.skytrainer.pro/dialog?id=${
                        id ?? DEFAULT_COURSE_ID
                    }&mode=1&training_type=1&token=${token}`,
                );
            } catch {
                flash.error("Не удалось загрузить тренажер.");
            }
        })();
    }, [id]);

    const handleClose = () => {
        window.close();
    };

    const height = `calc(100vh + ${size.width < 1110 ? 55 : 90}px)`;

    return (
        <>
            {iframeSrc ? (
                <div className="relative">
                    <iframe
                        allow="camera *;microphone *"
                        style={{ width: "calc(100vw + 90px)", height }}
                        className={clsx(
                            "w-screen h-screen absolute -left-22.5",
                            size.width < 1110 ? "-top-13.75" : "-top-22.5",
                        )}
                        src={iframeSrc}
                    />
                    <Button
                        color="common"
                        variant="outline"
                        icon={<Icon icon={Icons.Close} width="32px" height="32px" />}
                        iconPlacement={"center"}
                        className="border-[#E6E9ED] !p-1 absolute right-6 top-2"
                        onClick={handleClose}
                    />
                </div>
            ) : (
                <div className="flex items-center w-screen h-screen justify-center">
                    <Loader />
                </div>
            )}
        </>
    );
};
