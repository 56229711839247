import React, { useState, useId } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Icon, Icons, PopoverList } from "Uikit";
import { TestContentItemNumber } from "./TestContentItemNumber";
import { TestContentItemText } from "./TestContentItemText";
import { TestContentItemSelect } from "./TestContentItemSelect";
import { TestRequestQuestion } from "Api/Requests/TestRequest";
import { TestQuestionType } from "Enums";
import { IOption } from "types";

const testItemTypes = [
    { label: "Числовое поле", value: TestQuestionType.NUMBER },
    { label: "Текстовое поле", value: TestQuestionType.TEXT },
    { label: "Один из списка", value: TestQuestionType.ONE_OPTION },
    { label: "Несколько из списка", value: TestQuestionType.MULTIPLE_OPTIONS },
];

interface ITestContentItem {
    index: number;
    complexity: IOption[];
    question: TestRequestQuestion;
    current: number | undefined;
    setCurrent: (index: number | undefined) => void;
    onEdit: (index: number, question: TestRequestQuestion) => void;
    onCopy: (question: TestRequestQuestion) => void;
    onDelete: (question: TestRequestQuestion) => void;
    errors: any;
}
export const TestContentItem = ({
    index,
    complexity,
    question,
    current,
    setCurrent,
    onEdit,
    onCopy,
    onDelete,
    errors,
}: ITestContentItem) => {
    const [typePopoverState, setTypePopoverState] = useState<boolean>(false);
    const { listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
        id: question.id as UniqueIdentifier,
    });
    const idSection = useId();

    const style = {
        transform: CSS.Transform.toString(
            transform ? { x: transform?.x, y: transform?.y, scaleX: transform?.scaleX, scaleY: 1 } : null,
        ),
        transition,
    };

    const handleContentItemClick = () => {
        if (index !== current) {
            setCurrent(index);
        }
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="test-content-item relative flex justify-between pt-6 last:border-0 border-b border-gray-blue bg-white"
            onClick={handleContentItemClick}
        >
            <div className="flex flex-col w-50">
                <div className="cursor-pointer relative">
                    <PopoverList
                        className="w-50 absolute translate-x-[-72px] translate-y-[-18px]"
                        offset={[0, 18]}
                        visible={typePopoverState}
                        onClickOutside={() => setTypePopoverState(false)}
                    >
                        <div
                            className="flex items-center mb-0.25 relative"
                            onClick={() => {
                                if (index !== current) {
                                    return;
                                }

                                typePopoverState ? setTypePopoverState(false) : setTypePopoverState(true);
                            }}
                        >
                            <div className="cursor-grab" ref={setActivatorNodeRef} {...listeners}>
                                <Icon
                                    className="mr-2"
                                    icon={Icons.Grid}
                                    width={20}
                                    height={20}
                                    color="fill-gray-light"
                                    onMouseDown={() => setCurrent(undefined)}
                                />
                            </div>
                            <div className="flex items-center">
                                <b className={`mr-1 text-${Object.keys(errors).length !== 0 ? "red" : "black"}`}>
                                    {
                                        testItemTypes.find((item) => {
                                            return item.value === question.questionType;
                                        })?.label
                                    }
                                </b>
                                {index === current && (
                                    <Icon icon={Icons.ChevronDown} width={12} height={12} color="fill-blue-blue" />
                                )}
                            </div>
                        </div>
                        {testItemTypes.map((taskItemType, taskItemTypeIndex) => {
                            return (
                                <PopoverList.Item
                                    key={taskItemTypeIndex}
                                    name="list"
                                    className={taskItemType.value === question.questionType ? "bg-gray-blue" : ""}
                                    onClickItem={() => {
                                        if (question.questionType !== taskItemType.value) {
                                            const newQuestion = JSON.parse(JSON.stringify(question));

                                            newQuestion.questionType = taskItemType.value;
                                            newQuestion.answers = [];

                                            newQuestion.logoId = null;
                                            newQuestion.complexity = "MEDIUM";

                                            if (
                                                newQuestion.questionType === "ONE_OPTION" ||
                                                newQuestion.questionType === "MULTIPLE_OPTIONS"
                                            ) {
                                                newQuestion.answers.push({
                                                    id: "0",
                                                    pictureId: null,
                                                    answerText: "",
                                                    isCorrect: false,
                                                });
                                                newQuestion.answers.push({
                                                    id: "1",
                                                    pictureId: null,
                                                    answerText: "",
                                                    isCorrect: false,
                                                });
                                            }

                                            onEdit(index, newQuestion);
                                        }

                                        setTypePopoverState(false);
                                    }}
                                >
                                    {taskItemType.label}
                                </PopoverList.Item>
                            );
                        })}
                    </PopoverList>
                </div>
                <span className="ml-7 text-xs text-gray-light">Вопрос {index + 1}</span>
            </div>
            <div className="flex flex-col w-150">
                {question.questionType === TestQuestionType.NUMBER && (
                    <TestContentItemNumber
                        complexity={complexity}
                        question={question}
                        onEdit={(question) => onEdit(index, question)}
                        expanded={index === current}
                        errors={errors}
                    />
                )}
                {question.questionType === TestQuestionType.TEXT && (
                    <TestContentItemText
                        complexity={complexity}
                        question={question}
                        onEdit={(question) => onEdit(index, question)}
                        expanded={index === current}
                        errors={errors}
                    />
                )}
                {(question.questionType === TestQuestionType.ONE_OPTION ||
                    question.questionType === TestQuestionType.MULTIPLE_OPTIONS) && (
                    <TestContentItemSelect
                        isMulti={question.questionType === TestQuestionType.MULTIPLE_OPTIONS}
                        complexity={complexity}
                        question={question}
                        onEdit={(question) => onEdit(index, question)}
                        expanded={index === current}
                        errors={errors}
                    />
                )}
            </div>
            <div className="flex items-start" id={idSection}>
                {index !== current && (
                    <div className="cursor-pointer mr-4" onClick={() => setCurrent(index)}>
                        <Icon icon={Icons.Pencil} width={20} height={20} color="fill-blue-drk hover:fill-blue-hover" />
                    </div>
                )}
                {index === current && (
                    <div className="cursor-pointer mr-4" onClick={() => setCurrent(undefined)}>
                        <Icon icon={Icons.ChevronUp} width={20} height={20} color="fill-blue-drk" />
                    </div>
                )}
                <div
                    className="cursor-pointer mr-4"
                    onClick={(e) => {
                        e.stopPropagation();
                        onCopy(question);
                    }}
                >
                    <Icon icon={Icons.Copy} width={20} height={20} color="fill-blue-drk" />
                </div>
                <div
                    className="cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete(question);
                    }}
                >
                    <Icon icon={Icons.Delete} width={20} height={20} color="fill-blue-drk" />
                </div>
            </div>
        </div>
    );
};
