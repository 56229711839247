export function getEditorConfig(config: { [key: string]: any } = {}): { [key: string]: any } {
    return {
        allowedContent: true,
        bodyClass: "rounded-lg overflow-hidden h-125",
        defaultLanguage: "ru",
        language: "ru",
        toolbarCanCollapse: false,
        height: 398,
        readOnly: false,
        // imageUploadUrl : '/services/upload/api/v1/editor/image?',
        filebrowserUploadUrl: "/service/lms-upload/api/editor/image?",
        format_h1: { element: "h1", attributes: { class: "editorTitle1" } },
        format_h2: { element: "h2", attributes: { class: "editorTitle2" } },
        format_h3: { element: "h3", attributes: { class: "editorTitle3" } },
        format_p: { element: "p", attributes: { class: "" } },
        disableNativeSpellChecker: false,
        toolbar: [
            {
                name: "block-1",
                items: ["Undo", "Redo", "Format", "Bold", "Italic", "Underline", "Strike", "image2"],
            },
            {
                name: "block-2",
                items: ["TextColor", "BGColor"],
            },
            {
                name: "block-3",
                items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
            },
            {
                name: "block-4",
                items: ["NumberedList", "BulletedList"],
            },
            {
                name: "block-5",
                items: ["Link", "Unlink", "Anchor", "Image", "Table"],
            },
            {
                name: "ckwebspeech",
                items: ["redblock", "aquablock", "Blockquote", "Maximize"],
            },
        ],
        wordcount: {
            showParagraphs: false,
            showWordCount: false,
            showCharCount: true,
            showRemaining: true,
            maxCharCount: 50000,
        },
        ckwebspeech: {
            culture: "ru-RU",
            commandvoice: "okay", // trigger command listener
            commands: [
                // action list
                // {'[action]': '['text to trigger action']'}
                { newline: "new line" },
                { newparagraph: "new paragraph" },
                { undo: "undo" },
                { redo: "redo" },
            ],
        },
        removePlugins: "elementspath, scayt",
        editorplaceholder: "Текст...",
        ...config,
    };
}
