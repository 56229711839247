import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Icon, Icons } from "Uikit";
import { TestUserStartedResponseQuestion } from "Api/Responses/TestResponse";

interface ITestingHeaderLoose {
    questions: TestUserStartedResponseQuestion[];
    current: number;
    onQuestionClick: (index: number) => void;
}
export const TestingHeaderLoose = ({ questions, current, onQuestionClick }: ITestingHeaderLoose) => {
    const [start, setStart] = useState(0);
    const [headerWidth, setHeaderWidth] = useState(700);

    const onPrevClick = () => {
        setStart(start - 10 > 0 ? start - 10 : 0);
    };
    const onNextClick = () => {
        const count = Math.floor((headerWidth - (start !== 0 ? 28 : 0)) / 32);
        setStart(start + count < questions.length ? start + 10 : questions.length - count);
    };
    const handleResize = () => {
        const header = document.getElementById("testingHeaderLoose");
        setHeaderWidth(!header ? 700 : header.offsetWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    let headerCount = Math.floor((headerWidth - (start !== 0 ? 28 : 0)) / 32);

    if (questions.length > headerCount && start + headerCount < questions.length) {
        headerCount -= 1;
    }

    if (start === 0) {
        headerCount += 1;
    }

    return (
        <div id="testingHeaderLoose" className="flex flex-col mt-3 sm:mt-0 sm:max-w-175 2xl:max-w-[875px] w-full">
            <div className="flex sm:hidden items-center mb-3">
                <h3 className="mr-1 text-gray-dark">Вопрос {current + 1}</h3>
                <span className="text-gray">из {questions.length}</span>
            </div>
            <div
                id="testingHeaderLooseQuestionsContainer"
                className="flex justify-start items-center mt-3 sm:mt-15 2xl:mt-[75px] mb-5 2xl:mb-6.25 w-full overflow-auto scrollbar-hidden"
            >
                {start !== 0 && (
                    <div
                        className="hidden sm:flex justify-center items-center mr-4 w-5 2xl:w-6.25 h-5 2xl:h-6.25 bg-gray-blue rounded-full cursor-pointer"
                        onClick={onPrevClick}
                    >
                        <Icon
                            icon={Icons.ChevronRight}
                            width={12}
                            height={12}
                            className="rotate-180 2xl:!w-3.75 2xl:!h-3.75"
                        />
                    </div>
                )}
                <div id="testingHeaderLooseQuestions" className="flex items-center">
                    {(window.screen.width > 568 ? questions.slice(start, start + headerCount) : questions).map(
                        (question, index) => {
                            const correctIndex = index + start;

                            return (
                                <div
                                    key={correctIndex}
                                    className={clsx(
                                        "flex justify-center items-center mr-2 2xl:mr-2.5 w-8 sm:w-6 2xl:w-7.5 h-8 sm:h-6 2xl:h-7.5 p3 2xl:p1 rounded-md 2xl:rounded-lg text-gray cursor-pointer",
                                        correctIndex === current ? "border border-blue !text-blue" : null,
                                        correctIndex !== current && question.hasAnswer ? "bg-blue-10 !text-blue" : null,
                                        correctIndex !== current && question.approved !== null && !question.approved
                                            ? "bg-red-10 !text-red"
                                            : null,
                                    )}
                                    onClick={() => onQuestionClick(correctIndex)}
                                >
                                    {correctIndex + 1}
                                </div>
                            );
                        },
                    )}
                </div>
                {questions.length > headerCount && start + headerCount < questions.length && (
                    <div
                        className="hidden sm:flex justify-center items-center ml-2 w-5 h-5 2xl:w-6.25 h-5 2xl:h-6.25 bg-gray-blue rounded-full cursor-pointer"
                        onClick={onNextClick}
                    >
                        <Icon icon={Icons.ChevronRight} width={12} height={12} className="2xl:!w-3.75 2xl:!h-3.75" />
                    </div>
                )}
            </div>
        </div>
    );
};
