import { InterviewQuestionType } from "Enums";
import BaseApi from "../BaseApi";
import { BaseIdRequest } from "../BaseRequest";
import { BaseResponse } from "../BaseResponse";
import { InterviewPassRequest } from "../Requests/InterviewRequest";
import { InterviewReadResponse } from "../Responses/InterviewResponse";

export default class InterviewApi extends BaseApi {
    Read = async (_data: BaseIdRequest): Promise<InterviewReadResponse> => {
        return {
            Id: 1,
            IsHidden: false,
            Image: "",
            Title: "Interview",
            Description: "Interview Description",
            Placement: "Месторасположение 1",
            CategoryId: 1,
            Responsible: {
                Id: 1,
                FirstName: "Admin",
                LastName: "Admin",
                MiddleName: "Admin",
                Image: "",
                Login: "admin",
                Email: "admin@gmail.com",
            },
            AppearDate: new Date(),
            HideDate: new Date(),
            DeadlineDate: new Date(),
            IsRequired: true,
            IsAnonymous: false,
            Questions: [
                {
                    Id: 1,
                    Image: "",
                    Title: "Question 1",
                    Type: InterviewQuestionType.Open,
                    Answers: [],
                },
                {
                    Id: 1,
                    Image: "",
                    Title: "Question 2",
                    Type: InterviewQuestionType.One,
                    Answers: [
                        {
                            Id: 1,
                            Title: "Answer 1",
                            IsRight: true,
                        },
                        {
                            Id: 2,
                            Title: "Answer 2",
                            IsRight: false,
                        },
                        {
                            Id: 3,
                            Title: "Answer 3",
                            IsRight: false,
                        },
                    ],
                },
                {
                    Id: 1,
                    Image: "",
                    Title: "Question 3",
                    Type: InterviewQuestionType.Multiply,
                    Answers: [
                        {
                            Id: 1,
                            Title: "Answer 1",
                            IsRight: true,
                        },
                        {
                            Id: 2,
                            Title: "Answer 2",
                            IsRight: true,
                        },
                        {
                            Id: 3,
                            Title: "Answer 3",
                            IsRight: false,
                        },
                    ],
                },
                {
                    Id: 1,
                    Image: "",
                    Title: "Question 4",
                    Type: InterviewQuestionType.Number,
                    Answers: [
                        {
                            Id: 1,
                            Title: "1",
                            IsRight: true,
                        },
                    ],
                },
            ],
        };
    };

    Pass = async (_data: InterviewPassRequest): Promise<BaseResponse> => {
        return {};
    };
}
