import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Card } from "Components/Card";
import { Filter } from "Components/Filter/Filter";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { SearchInput } from "Uikit/Forms/SearchInput";
import { Button, Icon, Icons } from "Uikit/index";
import { useNavigate } from "react-router-dom";
import { pickBy } from "lodash";
import { Empty } from "Uikit/Page/Empty";
import { useDebounceValue } from "hooks/useDebounceValue";
import { TrainingCardListMobile } from "./TrainingCardListMobile";
import Skeleton from "react-loading-skeleton";
import { trainingKeys, useGetTrainingPassed, useMutateTrainingFavoriteStatus } from "./training.hooks";
import { UiCoursesList } from "Api/Responses/CourseResponse";
import { ResourceType } from "Enums";
import { Preloader } from "Components/Preloader/Preloader";
import { IOption } from "types";
import { useScreenSize } from "hooks/useMediaQuery";

export const TrainingTabPassed = ({ categoryFilterOptions }: { categoryFilterOptions: IOption[] }) => {
    const navigate = useNavigate();
    const { size } = useScreenSize();
    const isMobile = size === "small";
    const isLarge = size !== "small";

    const observerElem = useRef<HTMLDivElement>(null);

    const [query, setQuery] = useState("");
    const [debounceValue] = useDebounceValue(query, 500);

    const [isFilterShow, setIsFilterShow] = useState(false);

    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const parsedFiltersData = useMemo(() => {
        const filtersData = {
            ...pickBy(filters, (a) => a !== null && a !== undefined && a !== ""),
        };

        if (filtersData["ratingPoints"]) {
            filtersData["ratingPoints.greaterThanOrEqual"] = filters["ratingPoints"]["minValue"];
            filtersData["ratingPoints.lessThanOrEqual"] = filters["ratingPoints"]["maxValue"];
            delete filtersData["ratingPoints"];
        }

        if (filtersData["categoryId.in"]) {
            filtersData["categoryId.in"] = filtersData["categoryId.in"].map((c: any) => c.value);
        }

        if (filtersData["passed"]) {
            filtersData["passed" + ".greaterThanOrEqual"] = filters["passed"]["date"]["startDate"].toISOString();
            filtersData["passed" + ".lessThanOrEqual"] = filters["passed"]["date"]["endDate"].toISOString();
            delete filtersData["passed"];
        }

        if (filtersData["deadlineTimestamp.isNull"]) {
            filtersData["deadlineTimestamp.isNull"] = !filtersData["deadlineTimestamp.isNull"];
        }

        if (filtersData["type.equal"]) {
            filtersData["type.equal"] = filtersData["type.equal"].value;
        }

        return filtersData;
    }, [filters]);

    const handleOnSearch = (e: ChangeEvent<HTMLInputElement> | null) => {
        setQuery(e ? e.target.value : "");
    };

    const {
        data: passedCourses,
        isLoading: isCoursesLoading,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useGetTrainingPassed(parsedFiltersData);
    const dataLength = passedCourses?.pages[0]?.length ?? 0;

    const trainingFavoriteStatusMutation = useMutateTrainingFavoriteStatus(parsedFiltersData);

    const onFavoriteChange = (resource: UiCoursesList, isFavorite: boolean, list: keyof typeof trainingKeys) => {
        trainingFavoriteStatusMutation.mutate({ resource, isFavorite, list });
    };

    const filtersConfigPassed = [
        {
            label: "Пройден",
            fields: [
                {
                    accessor: "passed",
                    type: "date-range",
                    placeholder: "За всё время",
                },
            ],
        },
        // {
        //     label: "Тип",
        //     fields: [
        //         {
        //             accessor: "type.equal",
        //             type: "select",
        //             placeholder: "Выберите тип",
        //             options: typeOptions,
        //             defaultValue: "",
        //         },
        //     ],
        // },
        {
            label: "Категория",
            fields: [
                {
                    accessor: "categoryId.in",
                    type: "multi-select",
                    placeholder: "Выберите категорию",
                    options: categoryFilterOptions,
                },
            ],
        },
        // {
        //     label: "Награда, баллы",
        //     fields: [
        //         {
        //             accessor: "ratingPoints",
        //             type: "range",
        //         },
        //     ],
        // },
        // {
        //     label: "Оценка",
        //     fields: [
        //         {
        //             accessor: "rating",
        //             type: "range",
        //         },
        //     ],
        // },
        {
            label: "Дополнительно",
            fields: [
                {
                    accessor: "isRequired.equal",
                    label: "Обязательные",
                    type: "checkbox",
                    default: false,
                },
                {
                    accessor: "hasDeadline.equal",
                    label: "С дедлайном",
                    type: "checkbox",
                    default: false,
                },
            ],
        },
    ];

    const handleObserver = useCallback(
        (entries: any) => {
            const [target] = entries;
            if (target.isIntersecting && hasNextPage) {
                fetchNextPage().then();
            }
        },
        [fetchNextPage, hasNextPage],
    );

    useEffect(() => {
        const element = observerElem.current;
        const option = { threshold: 0 };

        const observer = new IntersectionObserver(handleObserver, option);
        if (element) {
            observer.observe(element);

            return () => observer.unobserve(element);
        }
    }, [fetchNextPage, hasNextPage, handleObserver]);

    useEffect(() => {
        setFilters((prevState) => {
            return {
                ...prevState,
                "resourceTitle.contains": debounceValue,
            };
        });
    }, [debounceValue]);

    return (
        <>
            {isMobile && (
                <div
                    className="absolute top-3.5 right-10 block sm:hidden z-[100]"
                    onClick={() => setIsFilterShow(true)}
                >
                    <Icon icon={Icons.Filter} width={18} height={18} color="stroke-blue-drk" />
                </div>
            )}
            <Filter
                isActive={isFilterShow}
                setIsActive={setIsFilterShow}
                configuration={filtersConfigPassed}
                filters={filters}
                onChange={setFilters}
            />
            {(dataLength !== 0 || Object.keys(filters).length !== 0 || query) && (
                <div className="hidden sm:flex justify-between pb-5 2xl:pb-6.25 w-full">
                    <div>
                        <SearchInput value={query} onChange={handleOnSearch} />
                    </div>
                    <Button
                        variant="outline"
                        color="secondary"
                        size="medium"
                        className="border-[#E6E9ED] rounded-lg font-medium"
                        icon={
                            <Icon
                                icon={Icons.Filter}
                                width={20}
                                height={20}
                                color="stroke-blue"
                                className="2xl:!w-6.25 2xl:!h-6.25"
                            />
                        }
                        iconPlacement={"left"}
                        onClick={() => setIsFilterShow(true)}
                        id="userTrainingBtnFilter"
                    >
                        Фильтры
                    </Button>
                </div>
            )}
            {passedCourses?.pages.some((p: any) => p?.length) && (
                <>
                    {isLarge && (
                        <div
                            className="hidden sm:flex flex-wrap sm:gap-x-6.5 2xl:gap-x-8 gap-y-8 2xl:gap-y-9 2xl:pb-9 mb-8 2xl:mb-9 pb-24"
                            id="userTrainingComplete"
                        >
                            {passedCourses.pages.map((page) => {
                                return page?.map((i) => {
                                    return (
                                        <div
                                            key={i.id}
                                            className="saturate-0 hover:saturate-100 ease-in-out duration-500 w-60 sm:w-[274px] 2xl:w-[342px] cursor-pointer"
                                            onClick={() => {
                                                let url = `/training/course/${i.resourceId}`;
                                                if (i.type === ResourceType.PROGRAM) {
                                                    url = `/training/program/${i.resourceId}`;
                                                }

                                                navigate(url);
                                            }}
                                        >
                                            <Card
                                                className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51"
                                                points={i.ratingPoints}
                                                type={i.type}
                                                // deadline={i.deadlineTimestamp ? dayjs(i.deadlineTimestamp) : undefined}
                                                required={i.isRequired}
                                                logoId={i.logoId}
                                                isFavorite={i.isFavorite}
                                                onFavoriteChange={(isFavorite) =>
                                                    onFavoriteChange(i, isFavorite, "listPassed")
                                                }
                                                completionTime={i.completionTime}
                                            />
                                            <MultiClumpTooltip
                                                className="pt-3"
                                                label={i.title}
                                                textClassName="2xl:text-2md"
                                            />
                                        </div>
                                    );
                                });
                            })}
                        </div>
                    )}
                    {isMobile && (
                        <div className="block sm:hidden">
                            {passedCourses.pages.map((page, index) => {
                                return (
                                    <TrainingCardListMobile
                                        key={index}
                                        id={"userTests" + index}
                                        data={page ?? []}
                                        showStatusChangeTime={true}
                                    />
                                );
                            })}
                        </div>
                    )}
                </>
            )}
            <div className="relative min-h-[1px] flex-shrink-0" ref={observerElem}>
                <Preloader
                    className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] gap-y-5 sm:gap-x-6.5 2xl:gap-x-8 sm:gap-y-9"
                    isShow={!passedCourses || (isFetchingNextPage && !!hasNextPage)}
                >
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={p}>
                                <div className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                                <div className="leading-5 line-clamp-2 pt-3">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                            </div>
                        );
                    })}
                </Preloader>
                {!isCoursesLoading && !passedCourses?.pages.some((p: any) => p?.length) && (
                    <>
                        {Object.keys(filters).length === 0 && !query && (
                            <div className="h-full flex justify-center">
                                <Empty
                                    title="Ничего не пройдено"
                                    description={
                                        <span>
                                            Все, что было пройдено, будет храниться
                                            <br />в этой вкладке
                                        </span>
                                    }
                                    topElement={
                                        <div className="flex-center mb-4 2xl:mb-5">
                                            <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 h-16.5 2xl:h-20.5 rounded-full bg-blue-10">
                                                <Icon
                                                    icon={Icons.EmojiSad}
                                                    width={36}
                                                    height={36}
                                                    color={"fill-primary"}
                                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                        )}
                        {(Object.keys(filters).length > 0 || query) && (
                            <div className="h-full flex justify-center">
                                <Empty
                                    title="Ничего не найдено"
                                    description="По заданным параметрам результатов нет"
                                    topElement={
                                        <div className="flex-center mb-4 2xl:mb-5">
                                            <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 h-16.5 2xl:h-20.5 rounded-full bg-blue-10">
                                                <Icon
                                                    icon={Icons.EmojiSad}
                                                    width={"36px"}
                                                    height={"36px"}
                                                    color={"fill-primary"}
                                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
