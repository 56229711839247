import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Breadcrumbs, Button, Icon, Icons, Input, Textarea, flash } from "Uikit";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { Loader } from "Uikit/Loader/Loader";
import { ContentLayout } from "Containers";
import Api from "Api";
import { TaskReviewRequest, TaskReviewOperationRequest } from "Api/Requests/TaskPublicRequest";
import { numCapEnd } from "helpers/numCapEnd";
import { formatDateTime, formatSendTime } from "helpers/dateHelper";
import { TaskPageForm } from "./TaskPageForm";
import { useInvalidate } from "hooks/useInvalidate";

const setTextareaAutoHeight = (textAreaRef: HTMLTextAreaElement | null, textAreaHelperRef: HTMLDivElement | null) => {
    if (textAreaRef && textAreaHelperRef) {
        textAreaRef.style.height = "0px";
        const scrollHeight = textAreaHelperRef.scrollHeight;

        // 17 = 8.5 + 8.5 - padding-top и patting-bottom у textarea
        textAreaRef.style.minHeight = `${Math.ceil((scrollHeight - 17) / 20) * 20}px`;
        textAreaRef.style.height = `${Math.ceil((scrollHeight - 17) / 20) * 20}px`;
    }
};

const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, textAreaHelperRef: HTMLDivElement | null) => {
    useEffect(() => {
        setTextareaAutoHeight(textAreaRef, textAreaHelperRef);
    }, [textAreaRef, textAreaHelperRef]);
};

export const TaskPage = () => {
    const { id } = useParams();
    const [comment, setComment] = useState("");
    const [score, setScore] = useState<number | undefined>(undefined);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const textAreaHelperRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const [urlToNavigate, setUrlToNavigate] = useState<string | null>(null);

    useAutosizeTextArea(textAreaRef.current, textAreaHelperRef.current);

    const onTextareaFocus = () => {
        setTextareaAutoHeight(textAreaRef.current, textAreaHelperRef.current);
    };

    useEffect(() => {
        document.addEventListener("mouseup", (e) => {
            if (textAreaRef.current && !(e?.target as HTMLElement)?.closest("#scoreBlock")) {
                textAreaRef.current.style.height = "40px";
                textAreaRef.current.style.minHeight = "40px";
            }
        });
    }, []);

    const dataQuery = useQuery(
        ["task", "collection", "review", id],
        async () => {
            return await Api.TaskPublic.VerificatedRead({ uuid: id as string });
        },
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        },
    );

    const { mutateAsync: reviewSkip } = useMutation((payload: TaskReviewRequest) => {
        return Api.TaskPublic.ReviewSkip(payload);
    });

    const { mutateAsync: reviewSave } = useMutation((payload: TaskReviewRequest) => {
        return Api.TaskPublic.ReviewSave(payload);
    });

    const { mutateAsync: reviewOperation } = useMutation((payload: TaskReviewOperationRequest) => {
        return Api.TaskPublic.ReviewOperation(payload);
    });

    const { data, isLoading, isError } = dataQuery;
    const scoreLimit = data?.maxRatingPoints ?? 0;

    useEffect(() => {
        if (data) {
            setScore(data.ratingPoints);
            setComment(data.checkComment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onReviewSkipHandler = async () => {
        const { nextExerciseId } = await reviewSkip({
            exerciseId: id as string,
            rating: score,
            comment,
        });
        if (nextExerciseId) {
            setComment("");
            navigate(`/admin/validations/task/${nextExerciseId}`, { replace: false });
            setScore(undefined);
        }
    };

    const goBack = async () => {
        await reviewSave({
            exerciseId: id as string,
            rating: score ?? 0,
            comment,
        });
        navigate(`/admin/validations?tab=tasks`);
    };

    const reviewOperationHandler = async (isApproved: boolean) => {
        const isScoreFieldTouched = typeof score === "number";
        if (!isApproved && !comment) {
            flash.error("Чтобы отклонить задание, напишите комментарий");
            return;
        }
        if (isApproved && isScoreFieldTouched && score < scoreLimit && !comment) {
            flash.error("При снижении количества баллов, напишите комментарий");
            return;
        }
        if (isApproved && !isScoreFieldTouched) {
            flash.error("Укажите количество баллов за выполнение");
            return;
        }
        const { nextExerciseId } = await reviewOperation({
            exerciseId: id as string,
            ratingPoints: score as number,
            comment,
            isApproved,
        });

        invalidate("review");

        flash.success(!isApproved ? "Задание отклонено" : "Задание принято");

        setTimeout(() => {
            if (nextExerciseId) {
                setUrlToNavigate(`/admin/validations/task/${nextExerciseId}`);
            } else {
                setUrlToNavigate(`/admin/validations?tab=tasks`);
            }
            setComment("");
        }, 1000);
    };

    useEffect(() => {
        if (urlToNavigate) {
            navigate(urlToNavigate, { replace: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlToNavigate]);

    return (
        <ContentLayout>
            <Breadcrumbs className="mb-6">
                <Breadcrumbs.Link title={"Администратор"} />
                <Breadcrumbs.Link title={"Проверка"} url={"/admin/validations"} />
                <Breadcrumbs.Link title={"Задания"} url={"/admin/validations?tab=tasks"} />
                {data && <Breadcrumbs.Link title={data?.title} />}
            </Breadcrumbs>

            {isLoading && (
                <div className="w-full h-full flex-center px-10">
                    <Loader />
                </div>
            )}
            {isError && (
                <p className="text-md text-center text-red">Произошла ошибка запроса - невозможно загрузить задание</p>
            )}

            {data && !isError && (
                <>
                    <div className="flex">
                        <div className="w-4/5">
                            <div className="flex items-center gap-3 mb-2">
                                <div className="flex items-center gap-1">
                                    <UserAvatar
                                        avatarId={data.user.avatarId}
                                        color={data.user.defaultAvatarColor}
                                        userInitials={`${data.user.firstName?.slice(0, 1)}${data.user.lastName?.slice(
                                            0,
                                            1,
                                        )}`}
                                        size={20}
                                    />
                                    <p className="text-xxs text-gray">
                                        {data.user.lastName} {data.user.firstName}
                                    </p>
                                </div>
                                <div className="h-4 border-r border-solid border-gray-stroke"></div>
                                <p className="text-xxs text-gray">
                                    Отправлено {formatDateTime(data.statusChangeTime * 1000)}
                                </p>
                                <div className="h-4 border-r border-solid border-gray-stroke"></div>
                                <p className="text-xxs text-gray">Прохождение {data.passingNumber}</p>
                                {/* Не в MVP */}
                                {/* <div className="h-4 border-r border-solid border-gray-stroke"></div>
                                <p className="text-xxs text-gray">
                                    Локация <a href="/" className="text-blue">55.737098, 37.597806</a>
                                </p> */}
                            </div>
                            <h2>{data.title}</h2>
                            {data.description && (
                                <div className="w-1/2 mt-2">
                                    <p className="text-gray-text">{data.description}</p>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-end w-full space-x-4">
                            <Button
                                key={0}
                                onClick={onReviewSkipHandler}
                                size={"medium"}
                                variant="outline"
                                color="common"
                                className={"border-[#EAEDF3] !text-base !font-medium whitespace-nowrap"}
                            >
                                Пропустить задание
                            </Button>

                            <Button
                                shape="square"
                                color="common"
                                variant="outline"
                                icon={<Icon icon={Icons.Close} width={22} height={22} />}
                                iconPlacement={"center"}
                                className={"border-[#EAEDF3] !text-base !font-medium"}
                                onClick={goBack}
                            />
                        </div>
                    </div>
                    {data.lastCheckedComment && (
                        <div className="flex mt-7 bg-blue-lightest rounded-xl">
                            <div className="py-6 w-3/5">
                                <p className="px-6 text-gray">Предыдущий комментарий проверяющего</p>
                            </div>
                            <div className="w-full py-6">
                                <div className="pr-6">
                                    <div className="flex items-center gap-2 mb-2">
                                        <UserAvatar
                                            avatarId={data.lastCheckedComment.user.avatarId}
                                            color={data.lastCheckedComment.user.defaultAvatarColor}
                                            userInitials={`${data.lastCheckedComment.user.firstName?.slice(
                                                0,
                                                1,
                                            )}${data.lastCheckedComment.user.lastName?.slice(0, 1)}`}
                                            size={36}
                                        />
                                        <div>
                                            <p className="text-sm">
                                                {data.lastCheckedComment.user.lastName}{" "}
                                                {data.lastCheckedComment.user.firstName}
                                            </p>
                                            <div className="flex items-center gap-2">
                                                <p className="text-xxs text-gray">
                                                    {formatSendTime(
                                                        data.lastCheckedComment.checkedTime * 1000,
                                                        true,
                                                        true,
                                                    )}
                                                </p>
                                                <div className="h-2.5 border-r border-solid border-gray"></div>
                                                <p className="text-xxs text-gray">Администратор</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p>{data.lastCheckedComment.comment}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <TaskPageForm task={data} />
                    <div className={"w-full flex"} id="scoreBlock">
                        <div className="w-2/5 mt-auto mr-4 relative">
                            <Input
                                placeholder={`Награда ${scoreLimit} ${numCapEnd(
                                    { one: "балл", two: "балла", few: "баллов" },
                                    scoreLimit,
                                )}`}
                                className="!text-black !pr-[68px]"
                                name="score"
                                value={score?.toString()}
                                onChange={(e) => {
                                    const val = Number(e.target.value);
                                    if (val <= scoreLimit) {
                                        setScore(val);
                                    }
                                    if (val > scoreLimit) {
                                        setScore(scoreLimit);
                                    }
                                }}
                                type="number"
                            />
                            <Button
                                className="absolute top-1/2 right-0 -translate-y-1/2 !text-black !px-3 hover:!text-primary"
                                color="common"
                                onClick={() => {
                                    setScore(scoreLimit);
                                }}
                            >
                                МАКС
                            </Button>
                        </div>
                        <div className="w-4/5 h-full mr-9 relative">
                            <div
                                ref={textAreaHelperRef}
                                className="absolute top-0 right-0 w-full px-4.5 py-2.2 min-h-[40px] leading-p150 invisible pointer-events-none break-words"
                            >
                                {comment}
                            </div>
                            <Textarea
                                inputRef={textAreaRef}
                                placeholder="Ваш комментарий"
                                name="comment"
                                value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value);
                                }}
                                onClick={() => {
                                    onTextareaFocus();
                                }}
                                className="bg-white resize-none overflow-hidden h-10 min-h-[40px] 2xl:!h-12.5 2xl:!min-h-12.5"
                                maxLength={512}
                            />
                        </div>
                        <div className="w-3/5 flex space-x-4 mt-auto">
                            <Button
                                key={0}
                                onClick={() => {
                                    reviewOperationHandler(false).then();
                                }}
                                color={"danger"}
                            >
                                Отклонить задание
                            </Button>
                            <Button
                                key={1}
                                onClick={() => {
                                    reviewOperationHandler(true).then();
                                }}
                                color="primary"
                            >
                                Принять задание
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </ContentLayout>
    );
};
