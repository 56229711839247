import React from "react";
import clsx from "clsx";

interface RatingProps {
    className?: string;
    value: number;
}

export const StarRating = ({ className, value }: RatingProps) => {
    const fullStars = Math.floor(value);

    const stars = [];

    function getStar(type: "full" | "partial" | "empty", index: number) {
        const color = type === "empty" ? "text-[#E6E9ED]" : "text-yellow";

        return (
            <i
                style={{ direction: index % 2 === 0 ? "ltr" : "rtl" }}
                className={clsx(color, className, "inline-block w-[10px] overflow-hidden")}
            >
                <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
            </i>
        );
    }

    // full start
    for (let i = 0; i < fullStars * 2; i++) {
        stars.push(getStar("full", i));
    }

    if (value < 5) {
        const isDecimal = !Number.isInteger(value - fullStars);
        // partial star
        if (isDecimal) {
            stars.push(getStar("partial", 0));
            stars.push(getStar("empty", 1));
        }

        const emptyStars = isDecimal ? 4 - fullStars : 5 - fullStars;
        for (let i = 0; i < emptyStars * 2; i++) {
            stars.push(getStar("empty", i));
        }
    }

    return <div className="flex">{stars}</div>;
};
