import { Expose, Type } from "class-transformer";

export class ScormResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public identifier: string;

    @Expose()
    @Type(() => String)
    public version: string;

    @Expose()
    @Type(() => String)
    public schema: string;

    @Expose()
    @Type(() => String)
    public schemaVersion: string;

    @Expose()
    @Type(() => ScormOrganizationResponse)
    public organizations: ScormOrganizationResponse[];
}

export class ScormOrganizationResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public identifier: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => ScormItemResponse)
    public items: ScormItemResponse[];
}

export class ScormItemResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => ScormItemResponse)
    public parentItem: ScormItemResponse;

    @Expose()
    @Type(() => ScormOrganizationResponse)
    public organization: ScormOrganizationResponse;

    @Expose()
    @Type(() => String)
    public identifier: string;

    @Expose()
    @Type(() => String)
    public identifierRef: string;

    @Expose()
    @Type(() => String)
    public parameters: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => ScormItemResponse)
    public children: ScormItemResponse[];

    @Expose()
    @Type(() => ScormResourceResponse)
    public resources: ScormResourceResponse[];
}

export class ScormResourceResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => ScormItemResponse)
    public item: ScormItemResponse;

    @Expose()
    @Type(() => String)
    public identifier: string;

    @Expose()
    @Type(() => String)
    public href: string;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => ScormFileResponse)
    public files: ScormFileResponse[];
}

export class ScormFileResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public href: string;

    @Expose()
    @Type(() => ScormResourceResponse)
    public resource: ScormResourceResponse;
}
