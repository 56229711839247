import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useInfiniteQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { SearchResource, SearchResultItem } from "Api/Responses/SearchResponse";
import Api from "Api/index";
import { Loader } from "Uikit/Loader/Loader";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { highlightSubstring } from "helpers/highlightSubstring";
import { numWord } from "helpers/numWord";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { useInterval } from "hooks/useInterval";
import { Empty } from "Uikit/Page/Empty";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../slices/headerSlice";
import { useDispatch } from "react-redux";
import { ProgressStatus, ResourceType } from "Enums";
import { LogoSize } from "Api/Services/UploadApi";

interface SearchProps {
    type: "ADMIN" | "USER";
}

interface IFilters {
    label: string;
    value: string;
    total: number;
}

const userResourceLink: { [key: string]: string } = {
    PROGRAM: "/training/program/",
    COURSE: "/training/course/",
    QUIZ: "/test/",
    EXERCISE: "/task/",
    USER: "/member/",
    POLL: "/interview/",
    NEWS: "/news/",
};
const adminResourceLink: { [key: string]: string } = {
    MATERIAL: "/admin/material/",
    PROGRAM: "/admin/program/",
    COURSE: "/admin/course/",
    QUIZ: "/admin/test/",
    EXERCISE: "/admin/task/",
    USER: "/admin/member/",
    POLL: "/admin/interview/",
    NEWS: "/admin/news/",
};

const _filters: { [key: string]: string } = {
    "": "Все",
    USER_PROGRAM: "Программы",
    PROGRAM: "Программы",
    USER_COURSE: "Курсы",
    COURSE: "Курсы",
    USER_QUIZ: "Тесты",
    QUIZ: "Тесты",
    USER_EXERCISE: "Задания",
    EXERCISE: "Задания",
    POLL: "Опросы",
    USER_POLL: "Опросы",
    USER: "Участники",
    MATERIAL: "Материалы",
    LIBRARY: "Библиотека",
    NEWS: "Новости",
    CALENDAR: "Календарь",
};

const resourceCount: { [key: string]: (count: number) => string } = {
    PROGRAM: (count: number) => numWord(count, ["программа", "программы", "программ"]),
    COURSE: (count: number) => numWord(count, ["курс", "курса", "курсов"]),
    QUIZ: (count: number) => numWord(count, ["тест", "теста", "тестов"]),
    EXERCISE: (count: number) => numWord(count, ["задание", "задания", "заданий"]),
    POLL: (count: number) => numWord(count, ["опрос", "опроса", "опросов"]),
    USER: (count: number) => numWord(count, ["участник", "участника", "участников"]),
    MATERIAL: (count: number) => numWord(count, ["материал", "материала", "материалов"]),
    LIBRARY: (count: number) => numWord(count, ["библиотека", "библиотеки", "библиотек"]),
    NEWS: (count: number) => numWord(count, ["новость", "новости", "новостей"]),
    CALENDAR: (count: number) => numWord(count, ["календарь", "календаря", "календарей"]),
};

export const Search = ({ type }: SearchProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();
    const observerElem = useRef<HTMLDivElement>(null);

    const searchQuery = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get("query") ?? "";
    }, [location.search]);

    const [isLoading, setIsLoading] = useState(true);

    const [filters, setFilters] = useState<{ label: string; value: string; total: number }[]>([]);
    const [selectedFilter, setSelectedFilter] = useState({ label: "Все", value: "", total: 5 });
    const [resources, setResources] = useState<SearchResultItem[]>([]);

    const [searchText, setSearchText] = useState("");
    const [searchCounter, setSearchCounter] = useState(0);
    const [isSearchInputChanged, setIsSearchInputChanged] = useState(false);

    const entityTitles: { [key: string]: string } = useMemo(() => {
        if (type === "ADMIN") {
            return _filters;
        }

        const newObject: { [key: string]: string } = {};

        Object.keys(_filters).forEach((key) => {
            if (key !== "MATERIAL") {
                newObject["USER_" + key] = _filters[key];
            }
        });

        return newObject;
    }, [type]);

    const searchAll = useCallback(
        async (query: string) => {
            setIsLoading(true);
            try {
                const request = type !== "USER" ? Api.Search.SearchMulti : Api.Search.PublicSearchMulti;

                const searchRes = await request({
                    query: query,
                    page: 0,
                    size: 5,
                });

                let totalItems = 0;

                const filters: any = Object.keys(entityTitles)
                    .slice(1)
                    .reduce((acc, key) => {
                        acc[key] = { label: entityTitles[key], value: key, total: 0 };

                        return acc;
                    }, {} as any);

                Object.keys(searchRes.result).forEach((key) => {
                    if (searchRes.result[key].total) {
                        filters[key].total = searchRes.result[key].total;

                        totalItems += searchRes.result[key].total;
                    }
                });

                setFilters([
                    { label: "Все", value: "", total: totalItems },
                    ...Object.keys(filters)
                        .map((key) => filters[key])
                        .filter((f) => f.total),
                ]);
                setResources(
                    Object.keys(filters)
                        .map((key) => {
                            return {
                                label: key,
                                took: null,
                                resources: searchRes.result[key]?.resources || [],
                                total: searchRes.result[key]?.total || 0,
                            };
                        })
                        .filter((f) => f.total),
                );
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        },
        [type, entityTitles],
    );

    const {
        data: resourceSearchQuery,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        ["filteredResources", searchQuery, selectedFilter],
        ({ pageParam = 0 }) => {
            return searchResourceList(pageParam, searchQuery, selectedFilter);
        },
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = allPages.length;

                return lastPage?.length !== 0 ? nextPage : undefined;
            },
        },
    );

    const searchResourceList = useCallback(
        async (page: number, query: string, filter: IFilters) => {
            if (!filter.value) {
                return [];
            }

            try {
                setIsLoading(true);
                const request = type === "USER" ? Api.Search.PublicSearchList : Api.Search.SearchList;
                const searchRes = await request({
                    query: query,
                    page: page,
                    size: 15,
                    resourceTypes: [filter.value],
                });

                setIsLoading(false);

                return searchRes.resources;
            } catch (error) {
                console.log(error);
            }
        },
        [type],
    );

    const navigateToResource = (item: any) => {
        const isAdminPage = location.pathname.includes("/admin");

        const type = item.type.replace("USER_", "");
        const pageRoute = (isAdminPage ? adminResourceLink : userResourceLink)[type];
        let linkId = "";

        if (
            isAdminPage ||
            type === ResourceType.COURSE ||
            type === ResourceType.PROGRAM ||
            type === ResourceType.QUIZ
        ) {
            linkId = item.resourceId;
        } else {
            linkId = item.userResourceId ?? item.id;
        }

        let link = `${pageRoute}${item.type === "MATERIAL" ? item.type + "/" : ""}${linkId}`;

        if (
            (type === ResourceType.EXERCISE || type === ResourceType.QUIZ) &&
            (item.progressStatus === ProgressStatus.PASSED ||
                item.progressStatus === ProgressStatus.ON_REVIEW ||
                item.progressStatus === ProgressStatus.FAILED)
        ) {
            link += "/statistics";
        }

        navigate(link);
    };

    const renderSearchItem = (item: SearchResource, resourceType: string) => {
        const type = resourceType.replace("USER_", "");
        switch (type) {
            case ResourceType.PROGRAM:
            case ResourceType.COURSE:
            case ResourceType.QUIZ:
            case ResourceType.EXERCISE:
            case ResourceType.POLL:
            case ResourceType.MATERIAL:
            case ResourceType.NEWS:
            case ResourceType.CALENDAR:
                return (
                    <div className="flex items-center pb-3">
                        <div className="flex-shrink-0 w-15 h-10 mr-3 rounded bg-gray overflow-hidden">
                            {item.logoId && (
                                <img
                                    className="w-full h-full object-cover"
                                    src={Api.Upload.GetLogo(item.logoId, LogoSize.THUMB_MICRO)}
                                    alt=""
                                />
                            )}
                        </div>
                        <div>
                            <div className="flex gap-3">
                                <div
                                    className="p1 line-clamp-1"
                                    dangerouslySetInnerHTML={{
                                        __html: highlightSubstring(item.title, searchQuery).text,
                                    }}
                                ></div>
                                {item.passingNumber && type !== ResourceType.QUIZ ? (
                                    <div className="flex items-center px-1 leading-4 bg-blue-50 rounded text-xxs text-white">
                                        Прохождение {item.passingNumber}
                                    </div>
                                ) : null}
                            </div>
                            {highlightSubstring(item.description, searchQuery).find ? (
                                <div
                                    className="p4 text-gray line-clamp-1"
                                    dangerouslySetInnerHTML={{
                                        __html: highlightSubstring(item.description, searchQuery).text,
                                    }}
                                ></div>
                            ) : null}
                        </div>
                    </div>
                );
            case "USER":
                return (
                    <div className="flex items-center pb-3">
                        <UserAvatar
                            avatarId={item.logoId}
                            userInitials={`${item.firstName?.slice(0, 1)}${item.lastName?.slice(0, 1)}`}
                            size={40}
                        />
                        <div
                            className="ml-3 p2"
                            dangerouslySetInnerHTML={{
                                __html:
                                    highlightSubstring(item.firstName ?? "", searchQuery).text +
                                    " " +
                                    highlightSubstring(item.lastName ?? "", searchQuery).text,
                            }}
                        ></div>
                    </div>
                );
            case "LIBRARY":
                return <div></div>;

            default:
                return null;
        }
    };

    const handleObserver = useCallback(
        (entries: any) => {
            const [target] = entries;
            if (target.isIntersecting && hasNextPage) {
                fetchNextPage().then();
            }
        },
        [fetchNextPage, hasNextPage],
    );

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = String(e.target.value.replace(/^\s+/, ""))
            .replace(/[^а-яА-ЯёЁa-zA-Z0-9 ]/g, "")
            .replace(/\s{2,}/gi, " ");

        setSearchText(value);
        setSearchCounter(1);
        setIsSearchInputChanged(true);
    };
    useInterval(() => {
        if (isSearchInputChanged && searchCounter === 0) {
            if (searchText) {
                let searchHistory: any = localStorage.getItem("searchHistory");

                if (searchHistory === null) {
                    searchHistory = [];
                } else {
                    searchHistory = JSON.parse(searchHistory);
                }

                if (searchHistory.indexOf(searchText) === -1) {
                    searchHistory.push(searchText);
                }

                localStorage.setItem("searchHistory", JSON.stringify(searchHistory));

                navigate(`/search?query=${searchText}`);
            } else {
                navigate("/search");
            }

            setIsSearchInputChanged(false);
        }

        if (searchCounter !== 0) {
            setSearchCounter(searchCounter - 1);
        }
    }, 500);

    useEffect(() => {
        if (!selectedFilter.value) {
            searchAll(searchQuery).then();
        }
    }, [searchAll, searchQuery, selectedFilter]);
    useEffect(() => {
        const element = observerElem.current;
        const option = { threshold: 0 };

        const observer = new IntersectionObserver(handleObserver, option);
        if (element) {
            observer.observe(element);

            return () => observer.unobserve(element);
        }
    }, [fetchNextPage, hasNextPage, handleObserver]);

    useEffect(() => {
        dispatch(setIsHidden(true));
        dispatch(setIsBack(false));
        dispatch(setTitle("Поиск"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    const searchHistory = JSON.parse(localStorage.getItem("searchHistory") ?? "[]");

    return (
        <div className="w-full h-full pt-1 sm:pt-3 px-3 sm:px-4">
            {/*Mobile search start*/}
            <div className="relative flex mb-4 w-full h-10 z-20 sm:hidden">
                <Icon
                    className="absolute top-2/4 -translate-y-2/4 left-4.5 pointer-events-none"
                    icon={Icons.Search}
                    width={20}
                    height={20}
                />
                <input
                    id="searchInput"
                    type="search"
                    className="hidden sm:block w-full h-full pl-12 pr-5.5 bg-background-light rounded-lg border-0 focus:ring-blue placeholder-gray text-black"
                    placeholder="Глобальный поиск по системе"
                    value={searchText}
                    autoComplete="off"
                    onChange={onChangeSearch}
                />
                <input
                    id="searchInput"
                    type="search"
                    className="block sm:hidden w-full h-full pl-12 pr-5.5 bg-background-light rounded-lg border-0 focus:ring-blue placeholder-gray text-black"
                    placeholder="Поиск"
                    value={searchText}
                    autoComplete="off"
                    onChange={onChangeSearch}
                />
                {searchText && (
                    <div
                        id="searchClearIcon"
                        className="absolute top-2/4 -translate-y-2/4 right-5.5 cursor-pointer bg-background-light pointer-events-none"
                    >
                        <Icon icon={Icons.Close} width={20} height={20} />
                    </div>
                )}
            </div>
            {/*Mobile search finish*/}
            {searchQuery && (
                <h2 className="hidden sm:block pb-3.5">
                    Результаты по запросу «<span className="text-blue-drk">{searchQuery}</span>»
                </h2>
            )}
            {resources.length !== 0 && (
                <div className="scrollbar-hidden flex space-x-2 pb-4 overflow-auto sm:flex-wrap sm:pb-7.5">
                    {filters.map((filter) => {
                        return (
                            <div
                                key={filter.value}
                                className={clsx(
                                    "px-2.5 py-1.5 p3 rounded-2sm whitespace-nowrap",
                                    selectedFilter.value === filter.value ||
                                        (!selectedFilter.value && filter.value === "")
                                        ? "bg-blue text-white cursor-default"
                                        : "bg-background text-blue-drk cursor-pointer",
                                )}
                                onClick={() => setSelectedFilter(filter)}
                            >
                                {filter.label} {filter.total}
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="relative w-full">
                {!isLoading && (searchText || searchQuery) && resources.length === 0 && (
                    <Empty
                        topElement={
                            <div className="flex items-center justify-center mb-4 2xl:mb-5 w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 bg-blue-10 rounded-full">
                                <Icon
                                    icon={Icons.Search}
                                    width={36}
                                    height={36}
                                    color="fill-primary"
                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                />
                            </div>
                        }
                        title="Ничего нет"
                        description="К сожалению, по вашему запросу ничего не было найдено"
                    />
                )}
                {!isLoading && !searchText && !searchQuery && (
                    <div className="flex flex-col sm:hidden">
                        {searchHistory.map((p: string) => {
                            return (
                                <div
                                    key={p}
                                    className="flex items-center mb-5 cursor-pointer"
                                    onClick={() => {
                                        setIsSearchInputChanged(true);
                                        setSearchText(p);
                                    }}
                                >
                                    <Icon icon={Icons.Search} width={16} height={16} color="fill-gray-blue" />
                                    <span className="ml-2.5 p1 text-gray-dark">{p}</span>
                                </div>
                            );
                        })}
                    </div>
                )}
                {selectedFilter?.value === "" &&
                    !isLoading &&
                    resources.map((item) => (
                        <div key={item.label} className="sm:pb-5.5">
                            <div className="flex justify-between items-center">
                                <h3 className="sm:pb-3 sm:p1-accent">
                                    {entityTitles[item.label || selectedFilter.value]}
                                </h3>
                                {item.resources.length < item.total && (
                                    <div
                                        className="block sm:hidden p3 text-primary cursor-pointer"
                                        onClick={() =>
                                            setSelectedFilter({
                                                label: _filters[item.label],
                                                value: item.label,
                                                total: item.total,
                                            })
                                        }
                                    >
                                        Все {item.total} {resourceCount[item.label]?.(item.total)}
                                    </div>
                                )}
                            </div>
                            <div className="w-full sm:bg-background-light sm:rounded-2lg sm:px-5.5 py-4">
                                <div className={clsx("w-full max-w-219 overflow-hidden", item.total > 5 && "sm:pb-4")}>
                                    {item.resources.map((r) => {
                                        return (
                                            <div
                                                key={r.id ?? r.resourceId}
                                                className="flex items-center pb-3 last:pb-0 cursor-pointer"
                                                onClick={() => navigateToResource(r)}
                                            >
                                                {renderSearchItem(r, item.label || selectedFilter.value)}
                                            </div>
                                        );
                                    })}
                                </div>
                                {item.resources.length < item.total && (
                                    <div
                                        className="hidden sm:block p3 text-primary cursor-pointer"
                                        onClick={() =>
                                            setSelectedFilter({
                                                label: _filters[item.label],
                                                value: item.label,
                                                total: item.total,
                                            })
                                        }
                                    >
                                        Показать все {item.total} {resourceCount[item.label]?.(item.total)}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                {selectedFilter.value === "" && isLoading && (
                    <div className="flex justify-center items-center z-20 pointer-events-none">
                        <Loader></Loader>
                    </div>
                )}
                {selectedFilter.value && resourceSearchQuery?.pages.some((p: any) => p?.length) ? (
                    <>
                        <h3 className="sm:p1-accent sm:pb-3">{_filters[selectedFilter.value]}</h3>
                        <div className="w-full sm:bg-background-light sm:rounded-2lg sm:px-5.5 pt-4">
                            {resourceSearchQuery.pages.map((page, idx) => {
                                if (!page?.length) {
                                    return;
                                }

                                return (
                                    <div key={idx} className={clsx("w-full max-w-219 overflow-hidden")}>
                                        {page?.map((r) => {
                                            return (
                                                <div
                                                    key={r.id}
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() => navigateToResource(r)}
                                                >
                                                    {renderSearchItem(r, selectedFilter.value)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>

                        <div className="w-full flex justify-center py-4" ref={observerElem}>
                            {isFetchingNextPage && hasNextPage ? <Loader /> : null}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};
