import { MutableRefObject } from "react";

export const getCheckedTreeNodes = (tree: any): any[] => {
    return Object.values(tree.nodeTable.data)
        .filter((node: any) => node.id && node.state.checked /*  && !node.id.startsWith("root:") */) // DEV
        .map((node: any) => {
            return node;
        });
};

export const awaitTree = (treeRef: MutableRefObject<any>): Promise<any> => {
    return new Promise((resolve) => {
        const handler = setInterval(() => {
            if (treeRef.current) {
                stop();
                resolve(treeRef.current);
            }
        }, 100);

        const stop = () => {
            clearInterval(handler);
        };
    });
};
