import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import Api from "Api/index";
import { UiCoursesCategoryList, UiCoursesList } from "Api/Responses/CourseResponse";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { BaseResponse } from "Api/BaseResponse";

type Filters = { [key: string]: any };

export const trainingKeys = {
    lists: ["ui/training"] as const,
    listAvailable: (filters: Filters) => [...trainingKeys.lists, "available", { filters }],
    listRequired: (filters: Filters) => [...trainingKeys.lists, "required", { filters }],
    listPassed: (filters: Filters) => [...trainingKeys.lists, "passed", { filters }],
};

export function useGetTrainingAvailable(filters: Filters) {
    return useInfiniteQuery(
        trainingKeys.listAvailable(filters),
        async ({ pageParam = 0 }) => {
            try {
                const courses = await Api.Course.TrainingList(pageParam, 3, [], filters);

                return courses.Content;
            } catch (error) {
                console.log(error);
            }
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = allPages.length;
                return lastPage?.length === 3 ? nextPage : undefined;
            },
        },
    );
}

export function useGetTrainingRequired(filters: Filters) {
    return useQuery({
        queryKey: trainingKeys.listRequired(filters),
        queryFn: async () => {
            try {
                const courses = await Api.Course.TrainingRequired(0, 10, [], {
                    ...filters,
                    "deadlineTimestamp.isNull": true,
                });

                return courses.Content;
            } catch (error) {
                console.log(error);
            }
        },
    });
}

export function useGetTrainingPassed(filters: Filters) {
    return useInfiniteQuery(
        trainingKeys.listPassed(filters),
        async ({ pageParam = 0 }) => {
            try {
                const courses = await Api.Course.TrainingPassed(pageParam, 10, [], filters);

                return courses.Content;
            } catch (error) {
                console.log(error);
            }
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = allPages.length;
                return lastPage?.length === 10 ? nextPage : undefined;
            },
        },
    );
}

export function useMutateTrainingAvailable(filters: Filters) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            categoryTitle,
            page,
        }: {
            categoryTitle: string;
            page: number;
            [key: string]: string | number;
        }) => {
            const courses = await Api.Course.TrainingMore(page, 8, [], {
                ...filters,
                "status.notEqual": "PASSED",
                "categoryTitle.in": categoryTitle,
            });

            return courses.Content;
        },
        onSuccess: (data: UiCoursesList[], variables) => {
            queryClient.setQueryData<InfiniteData<UiCoursesCategoryList>>(
                trainingKeys.listAvailable(filters),
                (oldData) => {
                    return {
                        ...oldData,
                        pages: oldData!.pages.map((page) => {
                            if (page.categoryTitle === variables.categoryTitle) {
                                page.items.push(...data);
                            }

                            return page;
                        }),
                    } as InfiniteData<UiCoursesCategoryList>;
                },
            );
        },
    });
}

async function toggleFavoriteStatus({ resource, isFavorite }: { resource: UiCoursesList; isFavorite: boolean }) {
    if (isFavorite) {
        return await Api.Favorite.Add(
            Object.assign(new CommonsAddFavoritesRequest(), {
                resourceId: resource.resourceId,
                resourceSolutionId: resource.progressId,
                title: resource?.title,
                logoId: resource?.logoId,
                state: "ACTIVE",
                type: resource.type,
                progressStatus: resource.progressStatus,
                ratingPoints: resource?.ratingPoints,
                deadlineTimestamp: resource?.deadlineTimestamp ?? 0,
            }),
        );
    } else {
        return await Api.Commons.removeFavorites(
            Object.assign(new CommonsRemoveFavoritesRequest(), {
                resourceId: resource.progressId,
                type: resource.type,
            }),
        );
    }
}

export function useMutateTrainingFavoriteStatus(filters: Filters) {
    const queryClient = useQueryClient();

    return useMutation<
        BaseResponse,
        Error,
        { resource: UiCoursesList; isFavorite: boolean; list: keyof typeof trainingKeys }
    >({
        mutationFn: toggleFavoriteStatus,
        onSuccess: (_data, { resource, isFavorite, list }) => {
            if (list === "listAvailable") {
                queryClient.setQueryData<InfiniteData<UiCoursesCategoryList[]>>(
                    trainingKeys.listAvailable(filters),
                    (oldData) => {
                        return {
                            ...oldData,
                            pages: oldData!.pages.map((page) => {
                                return page.map((p) => {
                                    return {
                                        ...p,
                                        items: p.items.map((i) => {
                                            return i.resourceId === resource.resourceId ? { ...i, isFavorite } : i;
                                        }),
                                    };
                                });
                            }),
                        } as InfiniteData<UiCoursesCategoryList[]>;
                    },
                );
            }

            if (list === "listRequired") {
                queryClient.setQueryData<UiCoursesList[]>(trainingKeys.listRequired(filters), (oldData) => {
                    return [
                        ...oldData!.map((i) => {
                            console.log("listRequired oldData", oldData);
                            return i.resourceId === resource.resourceId ? { ...i, isFavorite } : i;
                        }),
                    ];
                });
            }

            if (list === "listPassed") {
                queryClient.setQueryData<InfiniteData<UiCoursesList[]>>(trainingKeys.listPassed(filters), (oldData) => {
                    return {
                        ...oldData,
                        pages: oldData?.pages.map((page) => {
                            return page.map((i) => (i.resourceId === resource.resourceId ? { ...i, isFavorite } : i));
                        }),
                    } as InfiniteData<UiCoursesList[]>;
                });
            }

            queryClient.invalidateQueries(["favorites"]).then();
        },
    });
}
