import React, { ReactNode } from "react";
import clsx from "clsx";
import isAfter from "date-fns/isAfter";
import differenceInHours from "date-fns/differenceInHours";

interface ITestsCardTime {
    children: ReactNode;
    time?: number | null;
    bg?: string;
}
export const TestsCardTime = ({ children, time = null, bg }: ITestsCardTime) => {
    const today = new Date();
    let bgClass = "bg-black-50";

    if (time) {
        const date = new Date(time * 1000);
        const hoursToDate = differenceInHours(date, today);

        if (isAfter(today, date) || hoursToDate < 48) {
            bgClass = "bg-red";
        } else if (hoursToDate > 48 && hoursToDate < 168) {
            bgClass = "bg-primary";
        }
    }

    return (
        <div
            className={clsx(
                "flex items-center gap-1 2xl:gap-1.25 py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 rounded-md 2xl:rounded-lg p3 text-white h-5.25 2xl:h-6.5 2xl:text-2sm",
                bg ?? bgClass,
            )}
        >
            {children}
        </div>
    );
};
