import BaseApi from "../BaseApi";
import { BasePaginationResponse } from "../BaseResponse";
import { ArchiveListRequest } from "../Requests/ArchiveRequest";
import { ArchiveListResponse } from "../Responses/ArchiveResponse";
import { fetchArchiveData } from "Uikit/Table/fetchTestData";

export default class ArchiveApi extends BaseApi {
    List = async (data: ArchiveListRequest): Promise<BasePaginationResponse<ArchiveListResponse>> => {
        const result = await fetchArchiveData({ pageIndex: data.page, pageSize: data.size, sort: data.sort });

        const response = new BasePaginationResponse(ArchiveListResponse);
        response.Total = result.pageCount;
        response.Content = result.rows;
        return response;
    };
}
