import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";
import { ProgressStatus, ResourceState, ResourceType } from "Enums";

export class CourseCategoryResponse {
    @Expose({ name: "id" })
    @Type(() => String)
    public id: string;

    @Expose({ name: "title" })
    @Type(() => String)
    public title: string;
}

export class CourseCategoryGetResponse extends CourseCategoryResponse {
    @Expose({ name: "numberOfCourses" })
    @Type(() => Number)
    public CoursesCount: number;
}

class CourseCategory {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;
}

export class CourseListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => CourseCategory)
    public category: CourseCategory;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public managerUserId: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Boolean)
    public publicAccess: boolean;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => Number)
    public publicationDelayTimestamp: number;

    @Expose()
    @Type(() => Number)
    public expirationTimestamp: number;

    @Expose()
    @Type(() => Number)
    public deadlineTimestamp: number;

    @Expose()
    @Type(() => Boolean)
    public isRequired: boolean;

    @Expose()
    @Type(() => Boolean)
    public issueCertificate: boolean;

    @Expose()
    @Type(() => Boolean)
    public hideAvgReviewScore: boolean;

    @Expose()
    @Type(() => Boolean)
    public hideUserReviews: boolean;

    @Expose()
    @Type(() => Number)
    public modifyTimestamp: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public createTimestamp: number;

    @Expose()
    @Type(() => Number)
    public componentCount: number;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;

    @Expose()
    @Type(() => Number)
    public averageReviewRating: number;
}

export class CourseReadResponseSectionItem {
    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => String)
    public materialType: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Number)
    public deadlineTimestamp: number;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;

    @Expose()
    @Type(() => Number)
    public availableAttempts: number | null;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => String)
    public materialFileType: string | null;

    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => Boolean)
    public isMostRecent: boolean;
}

export class CourseReadResponseSection {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => CourseReadResponseSectionItem)
    public components: CourseReadResponseSectionItem[];
}

class BaseCourseResponse extends BaseResponse {
    // @Expose()
    // @Type(() => String)
    // public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public managerUserId: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Boolean)
    public publicAccess: boolean;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => Number)
    public publicationDelayTimestamp: number | null;

    @Expose()
    @Type(() => Number)
    public expirationTimestamp: number | null;

    @Expose()
    @Type(() => Number)
    public deadlineTimestamp: number | null;

    @Expose()
    @Type(() => Boolean)
    public isRequired: boolean;

    @Expose()
    @Type(() => Boolean)
    public issueCertificate: boolean;

    @Expose()
    @Type(() => Boolean)
    public hideAvgReviewScore: boolean;

    @Expose()
    @Type(() => Boolean)
    public hideUserReviews: boolean;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;

    @Expose()
    @Type(() => Number)
    public modifyTimestamp: number | null;

    @Expose()
    @Type(() => Number)
    public createTimestamp: number;
}

export class CourseResponse extends BaseCourseResponse {
    @Expose()
    @Type(() => CourseCategoryResponse)
    public id: CourseCategoryResponse;

    @Expose()
    @Type(() => CourseCategoryResponse)
    public category: CourseCategoryResponse;
}

export class CourseReadResponse extends BaseCourseResponse {
    @Expose()
    @Type(() => String)
    public categoryId: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public startTime: number;

    @Expose()
    @Type(() => Number)
    public progressPercent: number;

    @Expose()
    @Type(() => Number)
    public receivedPoints: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;

    @Expose()
    @Type(() => CourseReadResponseSection)
    public sections: CourseReadResponseSection[];

    @Expose()
    @Type(() => Boolean)
    public isApproxCompletionMinutesManuallyChanged: boolean;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public averageReviewRating: string;

    @Expose()
    @Type(() => Boolean)
    public hasNext: boolean;
}

export class CourseCreateResponse extends CourseResponse {
    @Expose()
    @Type(() => CourseCategoryResponse)
    public category: CourseCategoryResponse;
}

export class ArchiveCountResponse extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public trainingProgramCount: number;

    @Expose()
    @Type(() => Number)
    public courseCount: number;

    @Expose()
    @Type(() => Number)
    public materialsCount: number;

    @Expose()
    @Type(() => Number)
    public quizCount: number;

    @Expose()
    @Type(() => Number)
    public exerciseCount: number;

    @Expose()
    @Type(() => Number)
    public pollCount: number;

    @Expose()
    @Type(() => Number)
    public newsCount: number;
}

export class UiCoursesCategoryListItem extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public deadlineTimestamp: number | null;

    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => Boolean)
    public isRequired: boolean;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => String)
    public resourceId: string;
}

export class UiCoursesList extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public deadlineTimestamp: number | null;

    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => Boolean)
    public isRequired: boolean;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public type: ResourceType;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => Number)
    public completionTime: number | null;

    @Expose()
    @Type(() => Boolean)
    public hasNewLabel: boolean;
}

export class UiCoursesCategoryList extends BaseResponse {
    @Expose()
    @Type(() => String)
    public categoryId: string;

    @Expose()
    @Type(() => String)
    public categoryTitle: string;

    @Expose()
    @Type(() => UiCoursesList)
    public items: UiCoursesList[];

    @Expose()
    @Type(() => Number)
    public totalElements: number;
}

export class FilterRatingInfo extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public minAverageReviewRating: number;

    @Expose()
    @Type(() => Number)
    public maxAverageReviewRating: number;

    @Expose()
    @Type(() => Number)
    public minRatingPoints: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;
}

export class CourseReadResponseAdmin extends BaseCourseResponse {
    @Expose()
    @Type(() => String)
    public categoryId: string;

    @Expose()
    @Type(() => CourseReadResponseSection)
    public sections: CourseReadResponseSection[];

    @Expose()
    @Type(() => Boolean)
    public isApproxCompletionMinutesManuallyChanged: boolean;

    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => Number)
    public averageReviewRating: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;
}

export class CoursePublicNewCountResponse extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public allTab: number;

    @Expose()
    @Type(() => Number)
    public passedTab: number;
}
