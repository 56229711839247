import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, PopoverList } from "Uikit";
import { Input } from "Uikit/Forms/Input";
import { Modal } from "Uikit/Modal/Modal";
import { Tree } from "Uikit/Tree/Tree";
import { TreeSearchProps } from "Uikit/Tree/TreeSearch";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Link } from "react-router-dom";
import { ResourceState, ResourceType } from "Enums";
import { flattenDeep } from "helpers/flattenDeep";
import { awaitTree } from "helpers/TreeUtils";
import { TVoidFunction } from "types";

export type TTaskFilterValue = ResourceState | "ALL";

interface TasksModalTreeProps {
    isOpen: boolean;
    title: string;
    setIsOpen: (open: boolean) => void;
    treeData: any[];
    onLoadTree?: (tree: any, treeRef: any) => void;
    onSubmit?: TVoidFunction;
    checkedChange?: (node: any, targetNode?: any, tree?: any) => void;
    submitButtonTitle?: string;
    selectedCount?: number;
    watchSelected?: boolean;
    onTogglerClick?: (node: any) => void;
    onSearchFilterChange?: (value: TTaskFilterValue, query?: string) => void;
    checkable?: boolean;
    checkOnNameClick?: boolean;
    selectable?: boolean;
    onSelectNode?: (node: any) => void;
    showSelectedCounter?: boolean;
    disableRootSelection?: boolean;
    emptyMessage: string;
}

type TTaskFilter = {
    value: TTaskFilterValue;
    label: string;
};

const TaskFilterOptions: TTaskFilter[] = [
    {
        value: "ALL",
        label: "Все",
    },
    {
        value: ResourceState.ACTIVE,
        label: "Активные",
    },
    {
        value: ResourceState.HIDDEN,
        label: "Скрытые",
    },
];

const TreeSearch = ({
    query,
    setQuery,
    onSearchFilterChange,
}: TreeSearchProps & {
    onSearchFilterChange?: (value: TTaskFilterValue, query: string) => void;
}) => {
    const [popoverListOpen, setPopoverListOpen] = useState(false);
    const [taskFilter, setTaskFilter] = useState<TTaskFilter>({
        value: "ALL",
        label: "Все",
    });
    const onPopoverListItemClick = (value: TTaskFilterValue) => {
        const newFilter = TaskFilterOptions.find((item) => {
            return item.value === value;
        });
        setTaskFilter(newFilter as TTaskFilter);
        onSearchFilterChange?.(value, query);
        setPopoverListOpen(false);
    };

    return (
        <div className="relative pt-5 pb-5 w-full flex items-center gap-3.5">
            <div className="absolute left-1 flex items-center inset-y-0 p-1 pl-2 z-20">
                <Icon icon={Icons.Search} width={18} height={18} color="fill-[#939393]" />
            </div>
            <Input
                className="w-full border border-[#E6E9ED] placeholder:text-[#939393] !pl-10"
                placeholder="Поиск по названию"
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                    onSearchFilterChange?.(taskFilter.value, e.target.value);
                }}
            />
            <PopoverList
                offset={[0, 0]}
                visible={popoverListOpen}
                onClickOutside={() => setPopoverListOpen(false)}
                className="!w-[8rem]"
            >
                <Button
                    variant="outline"
                    color="common"
                    size="medium"
                    className="border-[#E6E9ED] rounded-lg font-medium"
                    icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                    iconPlacement={"left"}
                    onClick={() => setPopoverListOpen(true)}
                >
                    {taskFilter.label}
                </Button>
                {TaskFilterOptions.map(({ value, label }) => {
                    return (
                        <PopoverList.Item
                            key={value}
                            name={value}
                            onClickItem={() => {
                                onPopoverListItemClick(value);
                            }}
                            isActive={taskFilter.value === value}
                        >
                            {label}
                        </PopoverList.Item>
                    );
                })}
            </PopoverList>
        </div>
    );
};

export const TasksModalTree = ({
    isOpen,
    title,
    setIsOpen,
    treeData = [],
    onLoadTree,
    onSubmit,
    checkedChange,
    submitButtonTitle = "Добавить",
    selectedCount = 0,
    watchSelected = true,
    onTogglerClick,
    onSearchFilterChange,
    checkable = true,
    checkOnNameClick = false,
    selectable = false,
    onSelectNode,
    showSelectedCounter = true,
    disableRootSelection,
    emptyMessage,
}: TasksModalTreeProps) => {
    const treeRef = useRef<any>();
    const [selected, setSelected] = useState<number>(selectedCount);

    useEffect(() => {
        setSelected((prevSelected) => {
            return prevSelected !== selectedCount ? selectedCount : prevSelected;
        });
    }, [selectedCount]);

    const _treeData = useMemo(() => {
        return treeData.map((dataItem) => {
            if (
                dataItem.children.some(({ state }: any) => {
                    return state?.checked;
                })
            ) {
                return {
                    ...dataItem,
                    state: {
                        ...dataItem.state,
                        checked: true,
                        open: true,
                        indeterminate: !dataItem.children.every(({ state: { checked } }: any) => checked),
                    },
                };
            } else {
                return { ...dataItem };
            }
        });
    }, [treeData]);

    useEffect(() => {
        (async () => {
            const { tree } = await awaitTree(treeRef);
            if (tree && isOpen) {
                const allNodes = flattenDeep(tree.nodes ?? [], (node: any) => node.children);
                const selectedNode = allNodes.find((node) => node.state.selected);
                if (selectedNode) {
                    tree.openNode(selectedNode.parent);
                }
            }
        })();
    }, [isOpen, treeRef]);

    const handleSelectNode = (node: any) => {
        const { tree } = treeRef.current;
        if (tree) {
            // deselect the selected ones early
            tree.nodes.filter((n: any) => n.state.selected).forEach((n: any) => (n.state.selected = false));
            node.state.selected = true;
        }
        onSelectNode?.(node);
    };

    return (
        <Modal isOpen={isOpen} title={title} setIsOpen={setIsOpen} className="!w-150 !max-w-150">
            <Modal.Body className="flex flex-col overflow-hidden h-[543px] mb-2">
                <Tree
                    outerRef={treeRef}
                    onLoadTree={onLoadTree}
                    data={_treeData}
                    isCheckboxInside
                    checkable={checkable}
                    selectable={selectable}
                    markCheckedNodeHover
                    checkOnNameClick={checkOnNameClick}
                    disableRootSelection={disableRootSelection}
                    checkedChange={(nodes, targetNode, tree) => {
                        let selectedNodes = [...nodes["SECTION"], ...nodes["ARTICLE"]];
                        nodes["PROJECT"].map((p: any) => {
                            selectedNodes = [...selectedNodes, ...p.children];
                        });

                        if (checkedChange) {
                            checkedChange(selectedNodes, targetNode, tree);
                        }

                        setSelected(selectedNodes.length);
                    }}
                    onSelectNode={handleSelectNode}
                    renderSearch={TreeSearch}
                    nodeAddComponent={({ node: { id, nodeType, type, status } }) => {
                        if (nodeType === "PROJECT") {
                            return <></>;
                        }

                        let linkTo = "";

                        if (nodeType === "SECTION") {
                            if (type === ResourceType.EXERCISE) {
                                linkTo = `/admin/task/${id}`;
                            } else if (type === ResourceType.QUIZ) {
                                linkTo = `/admin/test/${id}`;
                            } else if (type === ResourceType.COURSE) {
                                linkTo = `/admin/course/${id}`;
                            } else if (type === ResourceType.PROGRAM) {
                                linkTo = `/admin/program/${id}`;
                            } else {
                                linkTo = `/admin/material/${type}/${id}`;
                            }
                        }

                        return (
                            <div className="flex items-center ml-auto">
                                <div className="ml-1 p2 text-gray-light group-hover:hidden">
                                    {status === ResourceState.ACTIVE && "Активен"}
                                    {status === ResourceState.HIDDEN && "Скрыт"}
                                </div>
                                <Link
                                    className="hidden items-center group-hover:flex cursor-pointer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={linkTo}
                                >
                                    <Icon className="ml-1 mr-1" icon={Icons.ShareBox} width={17} height={17} />
                                    Посмотреть
                                </Link>
                            </div>
                        );
                    }}
                    onTogglerClick={onTogglerClick}
                    onSearchFilterChange={onSearchFilterChange}
                    emptyMessage={emptyMessage}
                />
            </Modal.Body>
            <Modal.Footer className={"flex items-center" + (showSelectedCounter ? " justify-between" : "")}>
                {showSelectedCounter ? <p className="p2 text-gray-text">Выбрано {selected}</p> : null}
                <div className="space-x-4">
                    <Button
                        className="border-[#EAEDF3]"
                        variant="outline"
                        color="secondary"
                        onClick={() => setIsOpen(false)}
                    >
                        Отмена
                    </Button>
                    <Button disabled={watchSelected && selected === 0} onClick={onSubmit} color="primary">
                        {submitButtonTitle}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
