import React, { useState } from "react";
import { Button, PopoverList } from "Uikit";
import { ProgramListResponse } from "Api/Responses/ProgramResponse";

interface RowActionPopoverProps {
    program: ProgramListResponse;
    onCopyHandler: (program: ProgramListResponse) => void;
    onArchiveHandler: (program: ProgramListResponse) => void;
}

export const ProgramsRowActionPopover = ({ program, onCopyHandler, onArchiveHandler }: RowActionPopoverProps) => {
    const [visible, setVisible] = useState(false);

    const onCopy = () => {
        onCopyHandler(program);
        setVisible(false);
    };
    const onArchive = () => {
        onArchiveHandler(program);
        setVisible(false);
    };

    return (
        <div>
            <PopoverList offset={[0, 18]} visible={visible} onClickOutside={() => setVisible(false)}>
                <Button
                    shape="round"
                    color="common"
                    className="!text-blue-drk hover:!text-blue-hover"
                    onClick={() => setVisible(!visible)}
                >
                    ...
                </Button>
                <PopoverList.Item name="copy" onClickItem={onCopy}>
                    Создать копию
                </PopoverList.Item>
                <PopoverList.Item name="archive" onClickItem={onArchive}>
                    Архивировать
                </PopoverList.Item>
            </PopoverList>
        </div>
    );
};
