import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TeamTree } from "Components/Common/TeamTree";
import { ID, TVoidFunction } from "types";
import { Except } from "type-fest";
import { Icon, Icons, Modal, SecondaryButton, SubmitButton, Toggle, TreeSearch } from "Uikit";
import { useDebounceValue } from "hooks/useDebounceValue";
import { toggleTreeNodes } from "Uikit/Tree/Tree";
import { getCheckedTreeNodes } from "helpers/TreeUtils";

interface IParentTeamChoiceModal {
    title?: string;
    teamIds?: ID[];
    checkedTeamIds?: ID[];
    isOpen: boolean;
    isMulti: boolean;
    onClose: TVoidFunction;
    onSelect: (id: string[], all?: boolean, nodes?: any[]) => void;
    enableAllToggle?: boolean;
    allValue?: boolean;
    onlyIncludes?: ID[];
    selectable?: boolean;
    hideMembers?: boolean;
}

export const ParentTeamChoiceModal = ({ title, isOpen, onClose, ...rest }: IParentTeamChoiceModal) => {
    return (
        <Modal className="!max-w-225" isOpen={isOpen} onClose={onClose} title={title ?? "Выбор команд"}>
            <ModalContent onClose={onClose} {...rest} />
        </Modal>
    );
};

const ModalContent = ({
    isMulti,
    teamIds,
    checkedTeamIds,
    onClose,
    onSelect,
    enableAllToggle = false,
    allValue = false,
    onlyIncludes,
    selectable = false,
    hideMembers = false,
}: Except<IParentTeamChoiceModal, "isOpen">) => {
    const treeRef = useRef<any>();

    const [currentIds, setCurrentIds] = useState<string[]>([]);
    const [currentNodes, setCurrentNodes] = useState<any[]>([]);

    const [query, setQuery] = useState("");
    const [debouncedValue] = useDebounceValue(query, 300);
    const [all, setAll] = useState<boolean>(allValue ?? false);
    const [checkedCount, setCheckedCount] = useState(
        checkedTeamIds?.filter((id) => {
            return !id.startsWith("root:");
        }).length,
    );
    const currentIdsRef = useRef<string[]>(checkedTeamIds ?? []);

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            toggleTreeNodes(tree, debouncedValue.trim().length === 0, debouncedValue.trim(), currentIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue, treeRef]);

    const handleSubmit = () => {
        if (all) {
            onSelect([], true);
        } else {
            onSelect(currentIds, false, currentNodes);
        }
    };

    const handleSelectNode = useCallback((node: any) => {
        if (!node.isDisabled) {
            setCurrentIds([node.id]);
            setCurrentNodes([node]);
        }
    }, []);

    const handleCheckedChange = useCallback((_nodes: any) => {
        const nodes = getCheckedTreeNodes(treeRef.current.tree);
        setCheckedCount(
            nodes.filter(({ id }) => {
                return !id.startsWith("root:");
            }).length,
        );
        setCurrentIds(nodes.map((node) => node.id));
        setCurrentNodes(nodes);
    }, []);

    const mustExclude = useMemo(() => {
        return teamIds ?? [];
    }, [teamIds]);

    useEffect(() => {
        setCurrentIds(checkedTeamIds ?? []);
    }, [checkedTeamIds]);

    const TableHeader = () => {
        return (
            <div className="flex items-center justify-between grow mr-10">
                <div className="flex items-center">
                    <div className="flex justify-center min-w-7">
                        <Icon color="fill-primary" icon={Icons.Subtract} width={14} height={14} />
                    </div>
                    <span className="text-gray-text uppercase">Команда</span>
                    <div className="ml-3">
                        <Icon color="fill-secondary" icon={Icons.Sort} width={9} height={12} />
                    </div>
                    {enableAllToggle && (
                        <>
                            <Toggle enabled={all} onChange={setAll} className="ml-4" id="removeParentTeamToggle1" />
                            <span className="p2">Все</span>
                        </>
                    )}
                </div>
                <div className="flex items-center">
                    <span className="text-gray-text uppercase">Кол-во участников</span>
                    <div className="ml-3">
                        <Icon color="fill-secondary" icon={Icons.Sort} width={9} height={12} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Modal.Body>
                <div className="mt-3 mb-12 h-125 relative">
                    <TreeSearch query={query} setQuery={setQuery} placeholder="Поиск по названию" />

                    <div className="space-y-1 p4-table mt-2">
                        <div className="h-114">
                            <TeamTree
                                id="parent_team_choice_modal"
                                outerRef={treeRef}
                                checkable={isMulti}
                                searchable={false}
                                selectable={selectable}
                                mustExclude={mustExclude}
                                checkedChange={handleCheckedChange}
                                disabled={all}
                                nodeAddComponentLink=""
                                checkedTeams={enableAllToggle ? checkedTeamIds : currentIdsRef.current}
                                onlyIncludes={onlyIncludes}
                                renderSelectAllHead={enableAllToggle ? TableHeader : undefined}
                                onSelectNode={handleSelectNode}
                                hideMembers={hideMembers}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                {isMulti && (
                    <div className="flex items-center grow text-gray">
                        {all ? "Выбраны все команды" : <>Выбрано {checkedCount}</>}
                    </div>
                )}
                <SecondaryButton onClick={onClose}>Отменить</SecondaryButton>
                <SubmitButton
                    onClick={handleSubmit}
                    disabled={currentIds?.length === 0 && !all}
                    id="removeParentTeamBtnOk"
                >
                    Выбрать
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};
