import React, { useMemo } from "react";
import * as DOMPurify from "dompurify";
import clsx from "clsx";

interface HtmlViewerProps {
    className?: string;
    body: string;
}

export const HtmlViewer = ({ className = "", body = "" }: HtmlViewerProps) => {
    const html = useMemo(() => DOMPurify.sanitize(body, { ADD_ATTR: ["target"] }), [body]);

    return (
        <div
            className={clsx("viewer", className || "w-full max-w-175 2xl:max-w-[875px] overflow-auto")}
            dangerouslySetInnerHTML={{ __html: html }}
        ></div>
    );
};
