import { SortingState } from "@tanstack/react-table";
import BaseApi from "../BaseApi";
import { BasePaginationResponse, BaseResponse } from "../BaseResponse";
import { FavoriteListResponse } from "../Responses/FavoriteResponse";
import { FavoriteAddRequest, FavoriteRemoveRequest } from "Api/Requests/FavoriteRequest";

export default class FavoriteApi extends BaseApi {
    List = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [key: string]: string },
    ): Promise<BasePaginationResponse<FavoriteListResponse>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(FavoriteListResponse),
            `favorites?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    Add = async (data: FavoriteAddRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "favorites/add", data);
    };

    Remove = async (data: FavoriteRemoveRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "favorites/remove", data);
    };

    RemoveList = async (data: FavoriteRemoveRequest[]): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "favorites/remove/list", data);
    };
}
