import React, { useMemo } from "react";
import { Button, FormGroup, Input, Label, Modal } from "Uikit";
import { JobResponse } from "Api/Responses/JobResponse";
import { FormikProps, useFormik } from "formik";
import { Except } from "type-fest";
import { JobsRequest } from "Api/Requests/JobsRequest";
import Api from "Api";
import { useInvalidate } from "hooks/useInvalidate";
import { object, string } from "yup";
import { useQuery } from "react-query";
import compact from "lodash/compact";
import { TVoidFunction } from "types";

const modalTitle = {
    add: {
        text: "Создание должности",
    },
    edit: {
        text: "Редактирование должности",
    },
    remove: {
        text: "Удаление должности",
    },
};

interface PositionModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    selectedPosition?: JobResponse | null;
    type: "add" | "edit" | "remove";
    id: string;
}

// eslint-disable-next-line no-useless-escape
const positionNamePattern = new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9 \-\(\)#№&_+/]/gi);

export const PositionModal = ({ isOpen, onClose, type, ...rest }: PositionModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={modalTitle[type].text}
            description={rest.selectedPosition && type === "remove" ? rest.selectedPosition.name : ""}
        >
            <ModalContent onClose={onClose} type={type} {...rest} />
        </Modal>
    );
};

const ModalContent = ({ onClose, selectedPosition, type }: Except<PositionModalProps, "isOpen">) => {
    const { data: { Content: jobs = [] } = {} } = useQuery(["jobs", "collection"], () => Api.Job.GetJobs());
    const jobNames = useMemo(
        () => compact(jobs.map((job) => job.name.trim().toLowerCase().replace(/ё/g, "е"))),
        [jobs],
    );

    const validationSchema = object({
        name: string()
            .test("uniqueness", "Должность с таким названием уже существует", (name) => {
                return (
                    name?.trim().toLowerCase().replace(/ё/g, "е") ===
                        selectedPosition?.name.trim().toLowerCase().replace(/ё/g, "е") ||
                    type === "remove" ||
                    !name ||
                    !jobNames.includes(name.trim().toLowerCase().replace(/ё/g, "е"))
                );
            })
            .required("Обязательно для ввода"),
    });

    const { values, isSubmitting, ...rest }: FormikProps<{ name: string; userCount: number }> = useFormik({
        initialValues: selectedPosition ?? {
            name: "",
            userCount: 0,
        },
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values).then();
        },
    });

    const invalidate = useInvalidate();

    const handleSubmit = async (values: JobResponse) => {
        if (rest.dirty) {
            const request = Object.assign(new JobsRequest(), { ...values, name: String(values.name).trim() });
            if (type === "add") {
                await Api.Job.Create(request);
            }

            if (type === "edit") {
                await Api.Job.Update(request);
            }
        }

        if (type === "remove") {
            await Api.Job.Remove(values.id!);
        }

        invalidate("jobs");
        onClose();
    };

    return (
        <>
            <Modal.Body>
                <div>
                    {type === "remove" ? (
                        selectedPosition && selectedPosition?.userCount > 0 ? (
                            <div>
                                Вы не можете удалить должность, пока она назначена пользователям. Измените должности
                                участников перед удалением
                            </div>
                        ) : (
                            <div>Вы действительно хотите удалить должность?</div>
                        )
                    ) : (
                        <div className="mb-6 mt-3">
                            <FormGroup>
                                <Label isRequired={true}>Название должности</Label>
                                <Input
                                    isRequired={true}
                                    name={"name"}
                                    value={values.name}
                                    error={rest.errors?.name}
                                    placeholder="Введите название должности"
                                    onChange={rest.handleChange}
                                    maxLength={256}
                                    cutRegExp={positionNamePattern}
                                    id="createPosModalInput"
                                />
                            </FormGroup>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                    variant="outline"
                    size={"medium"}
                    color={"secondary"}
                    className={"border-[#EAEDF3] "}
                    id="createPosModalBtnCancel"
                >
                    {selectedPosition && selectedPosition.userCount > 0 && type === "remove" ? "Закрыть" : "Отмена"}
                </Button>

                {(selectedPosition && selectedPosition.userCount === 0) || type !== "remove" ? (
                    <Button
                        onClick={() => rest.handleSubmit()}
                        size={"medium"}
                        disabled={!values.name || !!rest.errors.name || isSubmitting}
                        color={type === "remove" ? "danger" : "primary"}
                        id="createPosModalBtnOk"
                    >
                        {type === "add" && "Создать"}
                        {type === "edit" && "Сохранить"}
                        {type !== "add" && type !== "edit" && "Удалить"}
                    </Button>
                ) : null}
            </Modal.Footer>
        </>
    );
};
