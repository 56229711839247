import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { Icon, Icons, Search } from "Uikit";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import Api from "Api";

export const TeamMentors = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const [search, setSearch] = useState("");

    const dataQuery = useQuery(
        ["teams", "tree", "page-in-user-space", "collection", id],
        async () => await Api.Teams.getTeam(id as string),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Наставники команды"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    return (
        <div className="flex sm:hidden flex-col px-3">
            <div className="mt-3 mb-4">
                <Search
                    id="teamUsersTableSearchInput"
                    placeholder="Поиск по названию"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />
            </div>
            {dataQuery.data?.mentors
                ?.filter((item) => {
                    return (
                        `${item.firstName}${item.lastName}`
                            .toLowerCase()
                            .indexOf(search.replace(" ", "").toLowerCase()) !== -1
                    );
                })
                .map((item) => {
                    return (
                        <div
                            key={item.id}
                            className="flex justify-between items-center mb-3 last:mb-0 py-4 px-3 rounded-lg bg-background"
                            onClick={() => navigate(`/personal/${item.id}`)}
                        >
                            <div className="flex items-center">
                                <UserAvatar
                                    className="mr-2"
                                    avatarId={item.avatarId}
                                    color={item.defaultAvatarColor}
                                    userInitials={`${item.firstName?.slice(0, 1)}${item.lastName?.slice(0, 1)}`}
                                    size={40}
                                />
                                <div className="flex flex-col">
                                    <p className="mb-1 p2-accent">
                                        {item.firstName} {item.lastName}
                                    </p>
                                    <p className="p4 text-gray">{item.jobTitle.name}</p>
                                </div>
                            </div>
                            <Icon icon={Icons.ChevronRight} width={14} height={14} color="fill-blue" />
                        </div>
                    );
                })}
        </div>
    );
};
