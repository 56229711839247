import React, { MouseEventHandler } from "react";

import { Icon, Icons } from "../Icon/Icon";

interface TreeTogglerProps {
    state: "opened" | "closed";
    onClick: MouseEventHandler<HTMLDivElement>;
}

function TreeToggler({ state, onClick }: Readonly<TreeTogglerProps>) {
    return (
        <>
            {state ? (
                <div onClick={onClick} className="flex-center p-1 cursor-pointer">
                    {state === "opened" && (
                        <Icon icon={Icons.ChevronDown} width={16} height={16} color="fill-blue-drk" />
                    )}
                    {state === "closed" && (
                        <Icon icon={Icons.ChevronRight} width={16} height={16} color="fill-blue-drk" />
                    )}
                </div>
            ) : null}
        </>
    );
}

export default TreeToggler;
