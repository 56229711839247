import "reflect-metadata";
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { initVideoMarkers } from "./init-video-markers";
import "polyfills/promise-allSettled";
import videojs from "video.js";
import store from "./store";
import { ReactQueryDevtools } from "react-query/devtools";
import NotificationConnect, { NotificationSubscribe, WssMessage, WssMessageType } from "Api/Wss/Notifications";
import Api from "Api/index";
import { removeCookie } from "typescript-cookie";

initVideoMarkers(videojs);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
    </Provider>,
);

NotificationConnect();
NotificationSubscribe(async (message: WssMessage) => {
    if (message.messageType === WssMessageType.LOGOUT) {
        await Api.Auth.Logout();
        removeCookie("USER_SESSION", { path: "/", domain: window.location.hostname });
        document.location.replace("/");
    } else if (message.messageType === WssMessageType.TEAMS) {
        await queryClient.invalidateQueries(["teams", "tree"]);
    }
});
