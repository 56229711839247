import React, { useRef } from "react";
import { Icon, Icons, Tooltip, Button, LogoInput } from "Uikit";
import { TLogoFormat } from "Uikit/Forms/LogoInput/LogoInput";
import { TAvatarEditorType } from "Uikit/AvatarEditor/AvatarEditor";
import { TVoidFunction } from "types";

interface IInfoCardLogoInputProps {
    onClick: TVoidFunction;
    value?: string;
    onAvatarChange: (event: any) => void;
    onChangeAvatar: (data: File | null, imgBase64?: string, type?: TAvatarEditorType) => void;
    label: string;
    tooltip: string;
    format?: TLogoFormat;
    isRoot: boolean;
    loadOnImageClick?: boolean;
}

export const InfoCardLogoInput = ({
    onClick,
    value,
    onAvatarChange,
    onChangeAvatar,
    label,
    tooltip,
    isRoot,
    format = "square",
    loadOnImageClick = true,
}: IInfoCardLogoInputProps) => {
    const ref = useRef<HTMLInputElement | null>(null);

    const onInputClick = () => {
        (ref.current as unknown as HTMLInputElement).click();
        onClick();
    };

    return (
        <div className="flex flex-col gap-1.5 w-full">
            <p className="inline-flex gap-1">
                <span className="text-gray">{label}</span>
                {isRoot && (
                    <Tooltip placement="top" content={tooltip}>
                        <Icon
                            icon={Icons.Question}
                            color="fill-gray-light"
                            width={14}
                            height={14}
                            className="cursor-pointer"
                        />
                    </Tooltip>
                )}
            </p>
            <div className="group flex space-x-7.5 w-full">
                <LogoInput onClick={() => loadOnImageClick && onInputClick()} avatarId={value} format={format} />
                <input
                    ref={ref}
                    id="logoSquareInput"
                    className="hidden"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={onAvatarChange}
                />
                {isRoot && (
                    <div className="flex">
                        <Button className="my-auto" onClick={onInputClick} id="adminLicenseLogoSquareBtn">
                            Загрузить
                        </Button>
                        {value && (
                            <Button
                                variant="outline"
                                color="common"
                                className="border-[#EAEDF3] ml-4 my-auto font-normal"
                                onClick={() => onChangeAvatar(null)}
                                disabled={!value}
                                id="adminLicenseLogoSquareBtnDelete"
                            >
                                Удалить
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
