import React, { useRef, useState } from "react";
import { extendedRegexp, wholeNumberRegexp } from "Uikit/Forms/Input";
import { Button, flash, FormGroup, Icon, Icons, Input, Label, Section, Toggle } from "Uikit";
import { AchievementRequest } from "Api/Requests/AchievementRequest";
import { AvatarEditor } from "Uikit/AvatarEditor/AvatarEditor";
import { getFileSizeErrorMessage } from "helpers/file";
import { UIErrorMessages } from "Enums";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

interface IAchievementSettings {
    errors: any;
    achievement: AchievementRequest;
    onChange: (achievement: AchievementRequest) => void;
    onChangeLogo: (data: File, image: string) => void;
}
export const AchievementSettings = ({ errors, achievement, onChange, onChangeLogo }: IAchievementSettings) => {
    const inputRef = useRef(null);
    const [avatarEditor, setAvatarEditor] = useState<string | undefined>(undefined);

    const onAvatarChange = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (files.length > 1) {
            flash.error("Выбрано более 1 файла");
            return;
        }

        if (!RegExp(/^image\/(jpg|jpeg|png)/).exec(files[0].type)) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2МБ"));
            return;
        }
        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => setAvatarEditor(reader.result!.toString());
    };

    return (
        <>
            <AvatarEditor
                isOpen={avatarEditor !== undefined}
                type="circle"
                title="Загрузка аватара"
                img={avatarEditor ?? ""}
                onDismiss={() => setAvatarEditor(undefined)}
                onSubmit={(img: string, blob: Blob) => onChangeLogo(new File([blob], "avatar.jpg"), img)}
            />

            <Section label="Внешний вид">
                <div className="group flex space-x-6 mb-5.5">
                    {achievement.logoId && (
                        <img
                            className={`rounded-full w-[150px] h-[150px] object-cover cursor-pointer group-hover:brightness-95 ${
                                errors["logoId"] ? " border border-red" : ""
                            }`}
                            src={
                                achievement.logoId
                                    ? achievement.logoId.includes("base64")
                                        ? achievement.logoId
                                        : Api.Upload.GetLogo(achievement.logoId, LogoSize.THUMB_MINI)
                                    : ""
                            }
                            alt={achievement.name}
                            onClick={() => (inputRef.current as unknown as HTMLInputElement).click()}
                        />
                    )}
                    {!achievement.logoId && (
                        <div
                            className={`flex justify-center items-center rounded-full w-[150px] h-[150px] bg-gray-blue cursor-pointer group-hover:brightness-95 ${
                                errors["logoId"] ? " border border-red" : ""
                            }`}
                            onClick={() => (inputRef.current as unknown as HTMLInputElement).click()}
                        >
                            <Icon icon={Icons.Upload} width={24} height={24} color="fill-gray" />
                        </div>
                    )}
                    <input
                        ref={inputRef}
                        id="logoInput"
                        className="hidden"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={onAvatarChange}
                    />
                    <Button
                        className="my-auto"
                        onClick={() => (inputRef.current as unknown as HTMLInputElement).click()}
                        id="adminSettingsNewAchievementBtnLoad"
                    >
                        Загрузить картинку
                    </Button>
                </div>
                <FormGroup>
                    <Label isRequired={true}>Название ачивки</Label>
                    <Input
                        isRequired={true}
                        placeholder="Введите название"
                        value={achievement.name}
                        onChange={(e) => onChange({ ...achievement, name: e.target.value })}
                        error={errors["name"]}
                        id="adminSettingsNewAchievementInputName"
                        cutRegExp={extendedRegexp}
                    />
                </FormGroup>
                <FormGroup>
                    <Label isRequired={true}>Количество баллов</Label>
                    <Input
                        isRequired={true}
                        placeholder="Введите баллы"
                        cutRegExp={wholeNumberRegexp}
                        min={-1000}
                        max={1000}
                        value={achievement.rating}
                        onChange={(e) => onChange({ ...achievement, rating: e.target.value })}
                        error={errors["rating"]}
                        id="adminSettingsNewAchievementInputGrade"
                    />
                </FormGroup>
            </Section>
            <Section label="Выдача ачивок">
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="flex-grow"
                        label="Лимит выдачи на одного пользователя"
                        enabled={achievement.maxQuantity !== null}
                        onChange={() =>
                            onChange({ ...achievement, maxQuantity: achievement.maxQuantity !== null ? null : "1" })
                        }
                        id="adminSettingsNewAchievementToggleSingle"
                    />
                    {achievement.maxQuantity !== null && (
                        <div className="max-w-30">
                            <Input
                                className="text-center"
                                cutRegExp={new RegExp(/\D/gi)}
                                min={0}
                                max={100}
                                value={achievement.maxQuantity}
                                error={errors["maxQuantity"]}
                                errorClassName="!-right-8"
                                onChange={(e) => {
                                    const value = e.target.value !== "" && +e.target.value === 0 ? "1" : e.target.value;
                                    onChange({ ...achievement, maxQuantity: value });
                                }}
                                before={
                                    <div
                                        className="cursor-pointer z-10"
                                        onClick={() => {
                                            onChange({
                                                ...achievement,
                                                maxQuantity:
                                                    Number(achievement.maxQuantity) > 1
                                                        ? (Number(achievement.maxQuantity) - 1).toString()
                                                        : "1",
                                            });
                                        }}
                                    >
                                        <Icon icon={Icons.Minus} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                                after={
                                    <div
                                        className="cursor-pointer z-10"
                                        onClick={() => {
                                            onChange({
                                                ...achievement,
                                                maxQuantity:
                                                    Number(achievement.maxQuantity) < 100
                                                        ? (Number(achievement.maxQuantity) + 1).toString()
                                                        : "100",
                                            });
                                        }}
                                    >
                                        <Icon icon={Icons.Plus} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                                id="adminSettingsNewAchievementInputCount"
                            />
                        </div>
                    )}
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        label="Запретить выдавать ачивку в профиле пользователя"
                        enabled={achievement.userProfileAssignmentDisabled}
                        onChange={() =>
                            onChange({
                                ...achievement,
                                userProfileAssignmentDisabled: !achievement.userProfileAssignmentDisabled,
                            })
                        }
                        id="adminSettingsNewAchievementToggleRestrict"
                    />
                </FormGroup>
            </Section>
        </>
    );
};
