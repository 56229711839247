import React from "react";
import { Breadcrumbs, Content, Tabs, TabsWrapper } from "Uikit";
import { PositionsTab } from "./Tabs/PositionsTab";
import { OfficesTab } from "./Tabs/OfficesTab";

export const SettingsMembers = () => {
    return (
        <div
            className="flex flex-col w-full h-full px-4 pb-4 sm:overflow-y-scroll custom-scrollbar"
            id="settingsMembers"
        >
            <Breadcrumbs className="mb-2.5" id="adminSettingsBreadcrumbsMembers">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Настройки" />
                <Breadcrumbs.Link title="Участники" url={"/admin/settings/members"} />
            </Breadcrumbs>

            <TabsWrapper className="mt-2 flex flex-col grow">
                <div className="flex">
                    <TabsWrapper.Tabs classname="h-max space-x-4 flex-grow" id="adminSettingsTabsMembers">
                        <Tabs.Tab title="Должности" />
                        <Tabs.Tab title="Офисы" />
                    </TabsWrapper.Tabs>
                </div>

                <TabsWrapper.Content className="flex flex-col grow">
                    <Content.Body className="w-full h-full">
                        <PositionsTab />
                    </Content.Body>

                    <Content.Body className="w-full h-full">
                        <OfficesTab />
                    </Content.Body>
                </TabsWrapper.Content>
            </TabsWrapper>
        </div>
    );
};
