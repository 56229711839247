import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { TreeTable } from "Uikit/Tree/TableTree";
import { Breadcrumbs, Button, Icon, Icons } from "Uikit";
import { TeamActionModal } from "./TeamActionModal";
import { TeamAccessModal } from "./TeamAccessModal";
import { RowActionPopover } from "./RowActionPopover";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import Api from "Api";
import { useCurrentUser } from "hooks/useCurrentUser";
import { OneLineClampWithTooltip } from "Uikit/OneLineClampWithTooltip";
import { UserRole } from "types/User";
import { RemoveEmptyRootTeamModal } from "./RemoveEmptyRootTeamModal";
import { RemoveFullRootTeamModal } from "./RemoveFullRootTeamModal";
import { flattenTeams } from "models/Team";
import { RemoveEmptyChildTeamModal } from "./RemoveEmptyChildTeamModal";
import { RemoveFullChildTeamModal } from "./RemoveFullChildTeamModal";
import { FilterLink } from "Components/Common/FilterLink";
import { Sorting } from "@devexpress/dx-react-grid";
import { Empty } from "Uikit/Page/Empty";

export const SettingsTeams = () => {
    const { data: user } = useCurrentUser();
    const [sorting, setSorting] = useState<Sorting[]>([{ columnName: "name", direction: "asc" }]);

    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);

    const [selectedTeam, setSelectedTeam] = useState<Partial<TeamTreeAllBasicInfoResponse>>();
    const [actionModalType, setActionModalType] = useState<"create" | "edit" | "remove">("create");

    const [removeEmptyRootTeamModalOpen, setRemoveEmptyRootTeamModalOpen] = useState(false);
    const [removeFullRootTeamModalOpen, setRemoveFullRootTeamModalOpen] = useState(false);
    const [removeEmptyChildTeamModalOpen, setRemoveEmptyChildTeamModalOpen] = useState(false);
    const [removeFullChildTeamModalOpen, setRemoveFullChildTeamModalOpen] = useState(false);

    const data = useQuery(
        ["teams", "tree", "basic-info", "collection", sorting],
        async () => await Api.Teams.TreeAllBasicInfo(sorting),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const handleRootAdd = (parent: TeamTreeAllBasicInfoResponse) => {
        setSelectedTeam({ parentId: parent.id });
        setActionModalType("create");
        setIsActionModalOpen(true);
    };

    const handleDelete = useCallback(
        (team: TeamTreeAllBasicInfoResponse) => {
            const rootTeam = !team.parentId;
            const isLastRootTeam = (data.data?.length ?? 0) <= 1;

            if (rootTeam) {
                if (isLastRootTeam) {
                    setRemoveEmptyRootTeamModalOpen(true);
                } else {
                    setRemoveFullRootTeamModalOpen(true);
                    setSelectedTeam(team);
                }
            } else {
                const hasMembersDeeply = flattenTeams([team]).some((team) => team.usersCount > 0);
                setSelectedTeam(team);

                if (hasMembersDeeply) {
                    setRemoveFullChildTeamModalOpen(true);
                } else {
                    setRemoveEmptyChildTeamModalOpen(true);
                }
            }
        },
        [data],
    );

    const columns = useMemo(
        () => [
            {
                headerLeftComponent: () => (
                    <div className="flex justify-center min-w-7">
                        <Icon color="fill-primary" icon={Icons.Subtract} width={18} height={18} />
                    </div>
                ),
                name: "name",
                title: "ВСЕ ДОСТУПНЫЕ КОМАНДЫ",
                width: "45%",
                cellFormatter: (value: any, children: any, { row }: any) => {
                    return value === "Без команды" ? (
                        <div className="group flex TableTreeCell-container py-3 !px-0">
                            {/*py-[2.1%]*/}
                            <Icon icon={Icons.LockOpen} className="my-auto" />
                            <span className="ml-2 group-hover:text-blue">{value}</span>
                        </div>
                    ) : (
                        <div className="hover:text-blue flex overflow-hidden space-x-2 group">
                            <OneLineClampWithTooltip tooltip={row.name}>{children}</OneLineClampWithTooltip>
                            {(user?.role === UserRole.ROOT || user?.role === UserRole.SUPER_ADMIN) && (
                                <div
                                    className="cursor-pointer self-center hidden group-hover:block "
                                    onClick={() => handleRootAdd(row)}
                                >
                                    <Icon icon={Icons.PlusFilled} color="fill-blue hover:fill-blue-hover" />
                                </div>
                            )}
                        </div>
                    );
                },
                hasLayoutBorders: false,
            },
            {
                name: "accessCount",
                title: "ДОСТУПЫ",
                width: "15%",
                cellFormatter: () => "—",
            },
            {
                name: "mentorsCount",
                title: "НАСТАВНИКОВ",
                width: "15%",
                cellFormatter: (value: [], _unused: any, team: TeamTreeAllBasicInfoResponse) => (
                    <FilterLink
                        href={`/admin/members?teamId.in=${team.id}&isMentor.equal=true`}
                        value={0}
                        name={value + " настав."}
                    />
                ),
            },
            {
                name: "usersCount",
                title: "УЧАСТНИКОВ",
                width: "15%",
                cellFormatter: (value: number, _unused: any, team: TeamTreeAllBasicInfoResponse) => (
                    <FilterLink href={`/admin/members?teamId.in=${team.id}`} value={value} name={value + " уч."} />
                ),
            },
            {
                name: "",
                title: "",
                width: "10%",
                cellFormatter: (value: any, children: any, row: TeamTreeAllBasicInfoResponse) => {
                    return row.name === "Без команды" ? null : (
                        <div className="flex" id={"teamSettingsTableGroupButton" + row.id}>
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    setActionModalType("edit");
                                    setSelectedTeam(row);
                                    setIsActionModalOpen(true);
                                }}
                                className="!h-8"
                            />

                            <RowActionPopover
                                onDelete={() => handleDelete(row)}
                                _onAccessChange={() => {
                                    setSelectedTeam(row);
                                    setIsAccessModalOpen(true);
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        [user, handleDelete],
    );

    const rightSideToolbar = (
        <div className="flex align-center items-center">
            <Button
                size={"medium"}
                className="rounded-lg whitespace-nowrap font-medium"
                icon={<Icon className="mr-1.5" icon={Icons.PlusFilled} color="fill-white" width={17} height={17} />}
                iconPlacement={"left"}
                onClick={() => {
                    setSelectedTeam(undefined);
                    setActionModalType("create");
                    setIsActionModalOpen(true);
                }}
                id="adminSettingsBtnCreateTeam"
            >
                Создать команду
            </Button>
        </div>
    );

    const handleActionModalClose = () => {
        setIsActionModalOpen(false);
    };

    return (
        <div className="flex flex-col h-full w-full px-4 pb-4 sm:overflow-y-scroll custom-scrollbar" id="settingsTeams">
            <TeamActionModal
                isOpen={isActionModalOpen}
                onClose={handleActionModalClose}
                type={actionModalType}
                selectedTeam={selectedTeam}
            />
            <TeamAccessModal
                isOpen={isAccessModalOpen}
                setIsOpen={setIsAccessModalOpen}
                selectedTeam={selectedTeam as TeamTreeAllBasicInfoResponse}
            />
            <Breadcrumbs id="adminSettingsBreadcrumbsTeams">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Настройки" />
                <Breadcrumbs.Link title="Команды" url={"/admin/settings/teams"} />
            </Breadcrumbs>
            {data.data && data.data?.length === 0 && (
                <Empty
                    title="Нет команд"
                    description="Создайте первую команду, чтобы она отобразилась на этой странице"
                    topElement={
                        <div className="flex-center mb-5">
                            <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                <Icon icon={Icons.Users} width={36} height={36} color="fill-primary" />
                            </div>
                        </div>
                    }
                >
                    {rightSideToolbar}
                </Empty>
            )}
            {data.data && data.data?.length !== 0 && (
                <TreeTable
                    data={data.data}
                    columns={columns}
                    isRowSelectable={false}
                    isAllRowSelectable={false}
                    rightSideToolbar={rightSideToolbar}
                    childrenProp="subTeams"
                    id="adminSettingsTeamsTree"
                    sorting={sorting}
                    onSortingChange={setSorting}
                    hasLayoutBorders={false}
                />
            )}
            <RemoveEmptyRootTeamModal
                isOpen={removeEmptyRootTeamModalOpen}
                onClose={() => setRemoveEmptyRootTeamModalOpen(false)}
            />
            <RemoveFullRootTeamModal
                isOpen={removeFullRootTeamModalOpen}
                team={selectedTeam}
                onClose={() => {
                    setRemoveFullRootTeamModalOpen(false);
                    setSelectedTeam(undefined);
                }}
            />
            <RemoveEmptyChildTeamModal
                isOpen={removeEmptyChildTeamModalOpen}
                team={selectedTeam}
                onClose={() => {
                    setRemoveEmptyChildTeamModalOpen(false);
                    setSelectedTeam(undefined);
                }}
            />
            <RemoveFullChildTeamModal
                isOpen={removeFullChildTeamModalOpen}
                team={selectedTeam}
                onClose={() => {
                    setRemoveFullChildTeamModalOpen(false);
                    setSelectedTeam(undefined);
                }}
            />
        </div>
    );
};
