import { ProgressStatus, ResourceState, ResourceType } from "Enums";
import { BaseRequest } from "../BaseRequest";

export class FavoriteAddRequest extends BaseRequest {
    public resourceId: string;
    public title: string;
    public logoId: string;
    public state: ResourceState;
    public type: ResourceType;
    public progressStatus: ProgressStatus;
}

export class FavoriteRemoveRequest extends BaseRequest {
    public resourceId: string;
    public courseId?: string;
    public componentId?: string;
    public type: ResourceType;
    public passingNumber: number;
}
