import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "Uikit/Button/Button";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { ProgramSectionItem } from "Api/Responses/ProgramResponse";
import { numCapEnd } from "helpers/numCapEnd";
import { TVoidFunction } from "types";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

interface IProgramContentItem {
    item: ProgramSectionItem;
    onRemove: TVoidFunction;
    isGotNextSection: boolean;
}

export const ProgramContentItem = ({ item, isGotNextSection, onRemove }: IProgramContentItem) => {
    const { listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
        id: item.id,
    });

    const style = {
        transform: CSS.Transform.toString(
            transform ? { x: transform?.x, y: transform?.y, scaleX: transform?.scaleX, scaleY: 1 } : null,
        ),
        transition,
    };

    return (
        <div
            className="flex items-center w-full gap-4 pb-4 cursor-default group first:border-b border-[#EAEDF3]"
            ref={setNodeRef}
            style={style}
        >
            <div className="h-9 rounded-md flex items-center overflow-hidden">
                <img
                    className="rounded-md object-cover !max-h-full"
                    width={54}
                    height={36}
                    src={Api.Upload.GetLogo(item.logoId, LogoSize.THUMB_MICRO)}
                    alt={item.title}
                />
            </div>
            <div
                className={`flex items-center justify-between w-full border-b border-[#EAEDF3] group-last:border-0 gap-4 ${
                    isGotNextSection ? "!border-0" : ""
                }`}
            >
                <MultiClumpTooltip
                    label={item.title}
                    clamp={1}
                    className={"text-[#262626] font-normal hover:text-[#1280CE] cursor-pointer"}
                ></MultiClumpTooltip>
                <div className="flex items-center shrink-0">
                    <span className="mr-16 text-[#939393] text-sm font-normal">
                        {`${item.componentCount} ${numCapEnd(
                            { one: "материал", two: "материала", few: "материалов" },
                            item.componentCount ?? 0,
                        )}`}
                    </span>
                    <Button
                        variant="outline"
                        color="common"
                        className="mr-4 !p-0 border-0 focus:!ring-0"
                        onClick={onRemove}
                    >
                        <Icon icon={Icons.Close} width={20} height={20} color="fill-[#878E9C]" />
                    </Button>
                    <div ref={setActivatorNodeRef} {...listeners}>
                        <Button variant="outline" color="common" className="!p-0 border-0 focus:!ring-0 cursor-grab">
                            <Icon icon={Icons.Grid} width={20} height={20} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
