import { ArchiveResponse } from "Api/Responses/ArchiveResponse";
import { Expose, Type } from "class-transformer";
import BaseApi from "../BaseApi";
import { BaseIdRequest1 } from "../BaseRequest";
import { BasePaginationResponse, BaseResponse } from "../BaseResponse";
import {
    MaterialChangeStateRequest,
    MaterialCreateRequest,
    MaterialEditRequest,
    MaterialUnArchiveRequest,
    PlaybackRequest,
} from "../Requests/MaterialRequest";
import {
    MaterialCreateResponse,
    MaterialFilterRatingResponse,
    MaterialListResponse,
    MaterialReadResponse,
} from "../Responses/MaterialResponse";
import { SortingState } from "@tanstack/react-table";

export class UserResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public login: string;
}

export default class MaterialApi extends BaseApi {
    List = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<MaterialListResponse>> => {
        const filters = Object.keys(filter || {})
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(MaterialListResponse),
            `/materials/all?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    ArchiveList = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<ArchiveResponse>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(ArchiveResponse),
            `/materials/archive/list?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    Read = async (data: BaseIdRequest1): Promise<MaterialReadResponse> => {
        return await this.Get(new MaterialReadResponse(), `/materials/${data.id}`);
    };

    createMaterial = async (data: MaterialCreateRequest): Promise<MaterialCreateResponse> => {
        return await this.Post(new MaterialCreateResponse(), "/materials", data);
    };

    editMaterial = async (data: MaterialEditRequest): Promise<MaterialCreateResponse> => {
        return await this.Post(MaterialCreateResponse, "/materials/edit", data);
    };

    changeState = async (data: MaterialChangeStateRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/resource-state", data);
    };

    UnArchive = async (data: MaterialUnArchiveRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/materials/unarchive", data);
    };

    GetFilterRating = async (): Promise<MaterialFilterRatingResponse> => {
        return await this.Get(MaterialFilterRatingResponse, "/materials/filter-rating-info");
    };

    Playback = async (data: PlaybackRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/materials/playback", data);
    };
}
