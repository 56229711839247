import React, { FunctionComponent, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import InfiniteTree from "react-infinite-tree";
import AutoSizer from "react-virtualized-auto-sizer";
import { useDebounceValue } from "hooks/useDebounceValue";
import TreeNode from "./TreeNode";
import { createCheckedBucket } from "./utils";
import { Icon, Icons } from "../Icon/Icon";
import { Checkbox } from "../Forms/Checkbox";
import { Button } from "../Button/Button";
import { TreeSearchProps } from "./TreeSearch";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useTreeRef } from "hooks/useTreeRef";
import { Empty } from "Uikit/Page/Empty";
import { TVoidFunction } from "types";

export const toggleTreeNodes = (tree: any, collapse: boolean, query?: string, selectedNodesIds?: string[]) => {
    let firstFindedNode: any;
    let timeoutId: any;

    function toggleNode(node: any) {
        const toggleNodeState: {
            highlight: boolean;
        } = {
            highlight: true,
        };
        if (selectedNodesIds && selectedNodesIds?.includes(node.id)) {
            tree.selectNode(node);
        }
        if (query && String(node.name).toLowerCase().includes(String(query).toLowerCase())) {
            if (!firstFindedNode) {
                firstFindedNode = node;
            }

            if (node.parent?.id) {
                let parent = node.parent;

                while (parent?.id) {
                    tree.openNode(parent, { silent: true });
                    parent = parent.parent;
                }
            }
        } else if (!query || !node.name.includes(query) || node.highlight !== undefined) {
            toggleNodeState.highlight = false;
        }

        tree.updateNode(node, toggleNodeState);

        node.children.forEach(toggleNode);

        if (collapse && node.state.open) {
            tree.toggleNode(node, { silent: true });
        }
    }

    if (tree) {
        tree.nodes.forEach((n: any) => {
            if (n.state.open) {
                tree.closeNode(n, { silent: true });
            }
        });

        for (const node of tree.nodes) {
            toggleNode(node);
        }

        if (timeoutId) clearTimeout(timeoutId);

        setTimeout(() => {
            tree.scrollToNode(firstFindedNode);
            firstFindedNode = undefined;
        }, 200);
    }
};

interface IBrowseAll {
    link: string;
    title: string;
    onAddClick: TVoidFunction;
}

interface IFooter {
    title: string;
    action: TVoidFunction;
    render?: (title?: string, action?: TVoidFunction) => React.ReactElement;
}

export interface IRenderSelectAllHeadProps {
    selectAll?: (e: boolean) => void;
    onSelectAll?: TVoidFunction;
}

export interface ITreeProps {
    id?: string;
    nodeId?: string;
    data: any;
    autoOpen?: boolean;
    isDeselecting?: boolean;
    onLoadTree?: any;
    searchable?: boolean;
    renderSearch?: FunctionComponent<TreeSearchProps>;
    selectable?: boolean;
    checkable?: boolean;
    checkedChange?: (nodes: any, targetNode: any, tree: any) => void;
    onUpdate?: (node: any) => void;
    onSelectNode?: (node: any) => void;
    onOrderChange?: (nodes: any) => void;
    browseAll?: null | IBrowseAll;
    editable?: boolean;
    onEditClick?: (value: string, id: string) => void;
    onDeleteClick?: (value: string, id: string) => void;
    onNodeAddClick?: (event: any, nodeId: any) => void;
    nodeAddList?: { name: string }[];
    onSelectAll?: TVoidFunction;
    inLayout?: boolean;
    renderSelectAllHead?: ({ selectAll, onSelectAll }: IRenderSelectAllHeadProps) => React.ReactElement;
    nodeWrapperClass?: string;
    nodeAddComponent?: ({ membersCount, node }: { membersCount?: number; node: any }) => React.ReactElement;
    rowHeight?: number;
    headerClassName?: string;
    treeNodeClassName?: string;
    footer?: IFooter;
    outerRef?: MutableRefObject<any>;
    onNodeAddComponentClick?: (arg?: string) => void;
    isCheckboxInside?: boolean;
    nodeAddComponentLink?: string;
    checkOnNameClick?: boolean;
    disabled?: boolean;
    onTogglerClick?: (node: any) => void;
    checkedWhenDisabled?: boolean;
    onSearchFilterChange?: (value: any) => void;
    isTeamTree?: boolean;
    renderHeader?: (() => ReactNode) | null;
    allChecked?: boolean;
    disableRootSelection?: boolean;
    showNodesTableHeader?: boolean;
    showSelectAllCheckBox?: boolean;
    checkedNodesIds?: string[];
    markCheckedNodeHover?: boolean;
    emptyMessage?: string;
}

export const Tree = ({
    id,
    nodeId,
    data,
    autoOpen,
    isDeselecting = false,
    onLoadTree,
    searchable = true,
    renderSearch: Search,
    selectable = false,
    checkable = true,
    checkedChange,
    onUpdate,
    onSelectNode,
    onOrderChange,
    browseAll = null,
    editable = false,
    onEditClick,
    onDeleteClick,
    onNodeAddClick,
    nodeAddList,
    onSelectAll,
    inLayout = false,
    renderSelectAllHead: SelectAllHead,
    nodeWrapperClass,
    nodeAddComponent,
    rowHeight = 42,
    headerClassName,
    treeNodeClassName,
    footer,
    outerRef,
    onNodeAddComponentClick,
    nodeAddComponentLink,
    isCheckboxInside,
    checkOnNameClick = false,
    disabled = false,
    onTogglerClick,
    checkedWhenDisabled = false,
    onSearchFilterChange,
    isTeamTree = false,
    renderHeader = () => null,
    allChecked,
    disableRootSelection,
    showNodesTableHeader = false,
    showSelectAllCheckBox = true,
    checkedNodesIds = [],
    markCheckedNodeHover,
    emptyMessage,
}: ITreeProps) => {
    const treeRef = useTreeRef(outerRef);
    // бэкап data на случай отмены изменений, внесённых в дереве
    const dataBackup = useRef(data);
    const [allNodesDisabled, setAllNodesDisabled] = useState(disabled ?? false);
    const [manualSelectedNode, setManualSelectedNode] = useState<any>(null);

    const [query, setQuery] = useState("");
    const [draggable, setDraggable] = useState(false);
    const [rolledBack, setRolledBack] = useState(false);
    const [allNodesChecked, setAllNodesChecked] = useState(false);
    const [debouncedValue] = useDebounceValue(query, 300);

    useEffect(() => {
        setAllNodesChecked(allChecked ?? false);
        if (treeRef.current) {
            checkAllNodes(allChecked ?? false);

            toggleTreeNodes(treeRef?.current?.tree, false);
            onSelectAll?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allChecked]);

    const checkedBucket = React.useRef(createCheckedBucket());

    const onCheckedChange = useCallback(
        (targetNode: any) => {
            checkedBucket.current.change(treeRef.current.tree);

            checkedChange?.(checkedBucket.current.getState(), targetNode, treeRef.current.tree);
        },
        [checkedBucket, treeRef, checkedChange],
    );

    useEffect(() => {
        if (typeof disabled === "undefined") {
            return;
        }
        setAllNodesDisabled(disabled);
    }, [disabled]);

    // При выделении (selected: true) ноды, снимаем с неё выбор (checked: false)
    useEffect(() => {
        if (!checkable && selectable && checkedNodesIds.length && treeRef.current) {
            const { tree } = treeRef.current;
            checkedNodesIds.forEach((id) => {
                const node = tree.getNodeById(id);
                // setManualSelectedNode(node)
                tree.checkNode(node, false);
            });
        }
    }, [checkable, checkedNodesIds, selectable, treeRef]);

    const onNodeClick = (node: any) => {
        if (selectable && !node.isDisabled) {
            treeRef.current.tree.selectNode(node);
            setManualSelectedNode(node);
        }
    };

    const _renderSearch = () => {
        if (Search) {
            return (
                <Search
                    query={query}
                    setQuery={setQuery}
                    disabled={draggable}
                    onSearchFilterChange={onSearchFilterChange}
                />
            );
        }

        return (
            <div className="flex items-center px-4 py-[6px]">
                <Icon icon={Icons.Search} width={20} height={20} color="fill-gray-text" />
                <input
                    className="w-full border-0 focus:ring-0"
                    type="text"
                    placeholder="Поиск по дереву"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    disabled={draggable}
                />
            </div>
        );
    };

    // Шапка "Выбрать всё"
    const _renderSelectAllHead = () => {
        if (SelectAllHead) {
            return (
                <SelectAllHead
                    selectAll={checkAllNodes}
                    onSelectAll={() => {
                        setAllNodesDisabled(!allNodesDisabled);
                    }}
                />
            );
        }

        return (
            <div className="flex items-center h-full">
                <div className="flex justify-center min-w-7 cursor-pointer"></div>
                Выбрать всё
            </div>
        );
    };

    // Ссылка просмотра всех новостей, опросов и т.п.
    const _renderBrowseAll = ({ link, title, onAddClick }: IBrowseAll) => {
        return (
            <div className="relative group">
                <Link
                    to={link}
                    className={clsx(
                        "group-hover:bg-blue/5 group-hover:no-underline flex items-center gap-2 py-2.5 pl-5 pr-2 text-primary",
                        !nodeId && "bg-blue/5",
                    )}
                >
                    <Icon className="" color="fill-primary" icon={Icons.List} width={15} height={15} />
                    {title}
                </Link>
                <div
                    className={clsx(
                        "absolute right-2 top-1/2 -translate-y-1/2 group items-center gap-2",
                        !draggable && "hidden group-hover:flex",
                        draggable && "flex",
                    )}
                >
                    {!draggable ? (
                        <>
                            {treeRef?.current?.tree.nodes.length > 0 ? (
                                <Button
                                    className={"border-none w-3.5 h-3.5 focus:!ring-0"}
                                    shape={"round"}
                                    size={"medium"}
                                    icon={
                                        <Icon
                                            className=""
                                            color="fill-primary"
                                            icon={Icons.SortButton}
                                            width={15}
                                            height={15}
                                        />
                                    }
                                    iconPlacement={"center"}
                                    color={"common"}
                                    onClick={() => {
                                        setQuery("");
                                        setDraggable(true);
                                    }}
                                />
                            ) : null}
                            <Button
                                className={"border-none w-3.5 h-3.5 focus:!ring-0"}
                                shape={"round"}
                                size={"medium"}
                                icon={
                                    <Icon
                                        className=""
                                        color="fill-primary"
                                        icon={Icons.PlusFilled}
                                        width={15}
                                        height={15}
                                    />
                                }
                                iconPlacement={"center"}
                                color={"common"}
                                onClick={onAddClick}
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                className={"border-none w-3.5 h-3.5 focus:!ring-0"}
                                shape={"round"}
                                size={"medium"}
                                icon={
                                    <Icon className="" color="fill-primary" icon={Icons.Check} width={12} height={8} />
                                }
                                iconPlacement={"center"}
                                color={"common"}
                                onClick={() => {
                                    if (treeRef?.current?.tree && onOrderChange) {
                                        onOrderChange(treeRef.current.tree.nodes);
                                    }
                                    setDraggable(false);
                                }}
                            />
                            <Button
                                className={"border-none w-3.5 h-3.5 focus:!ring-0"}
                                shape={"round"}
                                size={"medium"}
                                icon={<Icon className="" color="fill-red" icon={Icons.Close} width={8} height={8} />}
                                iconPlacement={"center"}
                                color={"common"}
                                onClick={() => {
                                    if (treeRef?.current?.tree) {
                                        treeRef.current.tree.clear();
                                        setRolledBack(true);
                                    }
                                    setDraggable(false);
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    };

    // Footer button;
    const renderFooter = () => {
        if (draggable) {
            return null;
        }

        if (footer?.render) {
            return footer.render(footer.title, footer.action);
        }

        return (
            <Button
                variant="outline"
                icon={<Icon icon={Icons.PlusFilled} width={20} height={20} />}
                iconPlacement="left"
                color="secondary"
                className="mx-4 my-3 border-gray-input font-medium focus:ring-0"
                onClick={footer?.action}
            >
                {footer?.title}
            </Button>
        );
    };

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            toggleTreeNodes(tree, debouncedValue.trim().length === 0, debouncedValue.trim());
        }
    }, [debouncedValue, treeRef]);

    // Восстановление пред. состояния (бэкап)
    useEffect(() => {
        if (rolledBack) {
            treeRef?.current?.addChildNodes(dataBackup.current);
            setRolledBack(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggable]);

    const onOpenNode = useCallback(
        (/* node: any */) => {
            if (selectable && manualSelectedNode) {
                treeRef.current.tree.selectNode(manualSelectedNode);
            }
        },
        [manualSelectedNode, selectable, treeRef],
    );

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            if (tree && onLoadTree) {
                onLoadTree(tree, treeRef);
            }
        }
    }, [treeRef, onLoadTree]);

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            if (tree) {
                tree.loadData(data);
            }
        }
    }, [data, treeRef]);

    // event handlers are not passing to the tree through props so fixing it manually
    useEffect(() => {
        if (treeRef?.current?.tree) {
            const events = [
                { key: "selectNode", handler: onSelectNode },
                { key: "openNode", handler: onOpenNode },
            ];
            const { tree } = treeRef.current;
            if (tree) {
                events.forEach((ev) => {
                    if (tree._events[ev.key]) {
                        tree.off(ev.key, tree._events[ev.key]);
                    }
                    if (ev.handler) {
                        tree.on(ev.key, ev.handler);
                    }
                });
            }
            return () => {
                if (tree) {
                    events.forEach((ev) => {
                        if (ev.handler) {
                            tree.off(ev.key, ev.handler);
                        }
                    });
                }
            };
        }
    }, [onOpenNode, onSelectNode, treeRef]);

    useEffect(() => {
        if (treeRef?.current?.tree && treeRef?.current?.tree?.nodes.length) {
            setAllNodesChecked(
                treeRef?.current?.tree?.nodes.every((node: { state: { checked: boolean } }) => node.state.checked),
            );
        }
    }, [treeRef, treeRef?.current?.tree]);

    const checkAllNodes = useCallback(
        (checked: boolean) => {
            setAllNodesChecked(checked);

            treeRef.current.tree.nodes.forEach((node: any) => {
                treeRef.current.tree.checkNode(node, checked);
            });
        },
        [treeRef],
    );

    const openFirstAvailableNode = (tree: any) => {
        const toggleNode = (node: any, firstEnabledNodeFound = false) => {
            if (!node.isDisabled && !firstEnabledNodeFound) {
                let parent = node.parent;

                while (parent) {
                    if (parent.id !== null) {
                        tree.openNode(parent, { silent: true });
                    }

                    parent = parent.parent;
                }

                return true;
            }

            for (const child of node.children) {
                if (toggleNode(child, firstEnabledNodeFound)) {
                    firstEnabledNodeFound = true;
                }
            }

            return firstEnabledNodeFound;
        };

        tree?.nodes?.forEach((rootNode: any) => toggleNode(rootNode));
    };

    openFirstAvailableNode(treeRef?.current?.tree);

    useEffect(() => {
        setTimeout(() => {
            if (!treeRef.current) {
                return;
            }

            treeRef.current.tree.checkAllNodes = checkAllNodes;
            treeRef.current.tree.setAllNodesDisabled = setAllNodesDisabled;
        }, 100);
    }, [treeRef, checkAllNodes]);

    return (
        <div className={clsx("w-full h-full flex flex-col", inLayout && "!h-auto")}>
            {searchable && _renderSearch()}
            {browseAll && _renderBrowseAll(browseAll)}
            {(onSelectAll && !renderHeader) || showNodesTableHeader ? (
                // rowHeight
                <div className={clsx("flex pr-4", `h-8`, headerClassName)}>
                    <div className={"flex w-full h-full items-center"}>
                        {onSelectAll && showSelectAllCheckBox && (
                            <div className="w-4 h-4 mx-2">
                                <Checkbox
                                    checked={allNodesChecked}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        checkAllNodes(e.target.checked);

                                        toggleTreeNodes(treeRef?.current?.tree, false);
                                        onSelectAll();
                                    }}
                                    disabled={allNodesDisabled}
                                />
                            </div>
                        )}
                        {_renderSelectAllHead()}
                    </div>
                </div>
            ) : null}
            {renderHeader?.()}
            {data.length === 0 && emptyMessage && (
                <div className="h-full flex-center">
                    <Empty
                        title={"Ничего не найдено"}
                        description={emptyMessage}
                        topElement={
                            <div className="flex-center mb-4">
                                <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                    <Icon icon={Icons.EmojiSad} width={"36px"} height={"36px"} color={"fill-primary"} />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
            {data.length > 0 && (
                <div
                    className={clsx("tree-autosizer-wrapper grow pt-1", inLayout && "invisible")}
                    id={id + "Container"}
                >
                    <AutoSizer className="tree-autosizer overflow-x-visible" defaultHeight={500}>
                        {({ width, height }: { width: number; height: number }) => {
                            return (
                                <InfiniteTree
                                    id={id}
                                    ref={treeRef}
                                    autoOpen={autoOpen}
                                    selectable
                                    tabIndex={0}
                                    data={data}
                                    width={Number.isNaN(width) ? 0 : width}
                                    height={Number.isNaN(height) ? 0 : height}
                                    className={"tree"}
                                    rowHeight={rowHeight}
                                    shouldSelectNode={(node: any) => {
                                        // Defaults to null
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        if (event && event.target?.tagName?.toLowerCase() === "label") {
                                            return false;
                                        }
                                        const { tree } = treeRef.current;
                                        return !(!isDeselecting && node === tree.getSelectedNode());
                                    }}
                                    onKeyUp={(event: any) => {
                                        console.log("onKeyUp", event.target);
                                    }}
                                    onKeyDown={(event: any) => {
                                        const { tree } = treeRef.current;
                                        const node = tree.getSelectedNode();
                                        const nodeIndex = tree.getSelectedIndex();
                                        if (event.keyCode === 37) {
                                            // Left
                                            tree.closeNode(node);
                                        } else if (event.keyCode === 38) {
                                            // Up
                                            const prevNode = tree.nodes[nodeIndex - 1] || node;
                                            tree.selectNode(prevNode);
                                        } else if (event.keyCode === 39) {
                                            // Right
                                            tree.openNode(node);
                                        } else if (event.keyCode === 40) {
                                            // Down
                                            const nextNode = tree.nodes[nodeIndex + 1] || node;
                                            tree.selectNode(nextNode);
                                        }
                                    }}
                                    onContentDidUpdate={() => {
                                        const { tree } = treeRef.current;
                                        onUpdate?.(tree);
                                    }}
                                    onContentWillUpdate={() => {
                                        const getDisabledNodes = (node: any): any[] => {
                                            let response: any[] = [];

                                            node.children.forEach((subNode: any) => {
                                                if (subNode.isDisabled) {
                                                    response.push({
                                                        id: subNode.id,
                                                        checked: subNode.state.checked,
                                                    });
                                                }

                                                response = [...response, ...getDisabledNodes(subNode)];
                                            });

                                            return response;
                                        };

                                        let disabledNodes: any[] = [];

                                        data.forEach((node: any) => {
                                            disabledNodes = [...disabledNodes, ...getDisabledNodes(node)];
                                        });

                                        disabledNodes.forEach((node) => {
                                            if (node.id.indexOf("root:") !== -1) {
                                                return;
                                            }

                                            const treeNode = treeRef.current.tree.getNodeById(node.id);

                                            if (treeNode.state.checked !== node.checked) {
                                                treeRef.current.tree.checkNode(treeNode, node.checked);
                                            }
                                        });
                                    }}
                                    onSelectNode={onSelectNode}
                                >
                                    {({ node, tree }: any) => {
                                        const hasChildren = node.hasChildren();
                                        let toggleState = "";

                                        if ((!hasChildren && node.loadOnDemand) || (hasChildren && !node.state.open)) {
                                            toggleState = "closed";
                                        }

                                        if (hasChildren && node.state.open) {
                                            toggleState = "opened";
                                        }

                                        return (
                                            <TreeNode
                                                {...{
                                                    nodeId,
                                                    node,
                                                    tree,
                                                    toggleState,
                                                    onNodeClick,
                                                    checkable,
                                                    editable,
                                                    onEditClick,
                                                    onDeleteClick,
                                                    onNodeAddClick,
                                                    nodeAddList,
                                                    nodeWrapperClass,
                                                    nodeAddComponent,
                                                    // setTreeData: (data: any) => {
                                                    //     dataBackup.current = data;
                                                    // },
                                                    checkedChange: onCheckedChange,
                                                    treeNodeClassName,
                                                    disabled: allNodesDisabled || node.isDisabled,
                                                    onNodeAddComponentClick,
                                                    nodeAddComponentLink,
                                                    isCheckboxInside,
                                                    checkOnNameClick,
                                                    onTogglerClick,
                                                    checkedWhenDisabled,
                                                    isTeamTree,
                                                    disableRootSelection,
                                                    markCheckedNodeHover,
                                                }}
                                            />
                                        );
                                    }}
                                </InfiniteTree>
                            );
                        }}
                    </AutoSizer>
                </div>
            )}
            {footer && renderFooter()}
        </div>
    );
};
