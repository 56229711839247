import { useMutation, useQueryClient } from "react-query";
import { SortingState } from "@tanstack/react-table";

import { ResourceType } from "Enums";
import { BasePaginationResponse, BaseResponse } from "Api/BaseResponse";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { TaskPublicListResponse } from "Api/Responses/TaskPublicResponse";
import Api from "Api/index";

type Filters = { [key: string]: any };
type Resource = TaskPublicListResponse;

export const tasksKeys = {
    lists: ["ui/tests"] as const,
    listAvailable: () => [...tasksKeys.lists, "available"],
    listReview: () => [...tasksKeys.lists, "review"],
    listResult: (sorting: SortingState, filters: Filters) => [...tasksKeys.lists, "result", { sorting, filters }],
};

async function toggleFavoriteStatus({ resource, isFavorite }: { resource: Resource; isFavorite: boolean }) {
    if (isFavorite) {
        return await Api.Favorite.Add(
            Object.assign(new CommonsAddFavoritesRequest(), {
                resourceId: resource.resourceId,
                resourceSolutionId: resource.id,
                passingNumber: resource.passingNumber,
                title: resource?.title,
                logoId: resource?.logoId,
                state: "ACTIVE",
                type: ResourceType.EXERCISE,
                progressStatus: resource.progressStatus,
                ratingPoints: resource?.ratingPoints,
                deadlineTimestamp: resource?.deadlineTime || null,
            }),
        );
    } else {
        return await Api.Commons.removeFavorites(
            Object.assign(new CommonsRemoveFavoritesRequest(), {
                resourceId: resource.resourceId,
                passingNumber: resource.passingNumber,
                type: ResourceType.EXERCISE,
            }),
        );
    }
}

export function useChangeTaskFavoriteStatus() {
    const queryClient = useQueryClient();

    return useMutation<BaseResponse, unknown, { resource: Resource; isFavorite: boolean; queryKeys: any }>(
        toggleFavoriteStatus,
        {
            onSuccess: (_data, variables) => {
                if (variables.queryKeys.includes("result")) {
                    queryClient.setQueryData<{
                        pages: BasePaginationResponse<Resource>[];
                        pageParams: unknown[];
                    }>(variables.queryKeys, (oldData) => {
                        let resource: Resource | undefined;

                        oldData?.pages?.forEach((page) => {
                            resource = page.Content.find((item) => item.id === variables.resource.id);
                        });

                        if (resource) {
                            resource.isFavorite = variables.isFavorite;
                        }

                        return oldData!;
                    });
                } else {
                    queryClient.setQueriesData<Resource[]>(variables.queryKeys, (oldData) => {
                        return oldData!.map((t) =>
                            t.id === variables.resource.id ? { ...t, isFavorite: variables.isFavorite } : t,
                        );
                    });
                }
            },
        },
    );
}
