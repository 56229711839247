import React, { ReactNode } from "react";
import { Tab as HeadlessUiTab } from "@headlessui/react";
import clsx from "clsx";

interface BodyProps {
    children?: ReactNode;
    className?: string;
}

export const Body = ({ children, className }: BodyProps) => {
    return (
        <HeadlessUiTab.Panel
            key={1}
            className={clsx(
                "w-full",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                className,
            )}
        >
            {children}
        </HeadlessUiTab.Panel>
    );
};
