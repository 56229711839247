import React, { useEffect, useState } from "react";
import { generateAvatar } from "helpers/generateAvatar";
import clsx from "clsx";

interface UserAvatarProps {
    avatarId?: string | null;
    userInitials?: string;
    color?: string;
    size: number;
    className?: string;
}

export const UserAvatar = ({ avatarId, userInitials = "", color, size = 40, className }: UserAvatarProps) => {
    const [avatar, setAvatar] = useState("");

    useEffect(() => {
        if (avatarId) {
            setAvatar(avatarId.includes("base64") ? avatarId : "/service/lms-upload/api/file/download/" + avatarId);
        } else {
            setAvatar(generateAvatar(userInitials || "АБ", color, size));
        }
    }, [avatarId, size, color, userInitials]);

    return (
        <div className={clsx("flex flex-shrink-0", className)}>
            <img
                className="rounded-full object-cover group-hover:brightness-95"
                style={{ width: size, height: size, opacity: avatar ? 1 : 0 }}
                src={avatar}
                alt="Аватарка юзера"
            />
        </div>
    );
};
