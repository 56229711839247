import React from "react";
import clsx from "clsx";
import { TVoidFunction } from "types";

import { Icon, Icons } from "Uikit/index";
import { Bar } from "./Bar";
import { Volume } from "./Volume";

interface ControlsProps {
    className?: string;
    rewind?: boolean;
    status: string;
    watchedTime: number;
    currentTime: number;
    volume: number;
    getDuration: () => number | undefined;
    getCurrentTime: () => number | undefined;
    onPlayStatusChange: TVoidFunction;
    onFullscreenHandler: TVoidFunction;
    onTimeChange: (time: number) => void;
    onVolumeChange: (value: number) => void;
}

export const Controls = ({
    className,
    rewind = true,
    status = "PAUSE",
    watchedTime,
    currentTime,
    volume = 1,
    getDuration,
    getCurrentTime,
    onPlayStatusChange,
    onFullscreenHandler,
    onTimeChange = () => {},
    onVolumeChange = () => {},
}: ControlsProps) => {
    return (
        <div className={clsx("flex items-center justify-center w-full bg-black-70", className)}>
            <div className="flex items-center">
                <button
                    className="flex justify-center items-center shrink-0 w-10 h-10 p-0 border-0 m-0 cursor-pointer text-center"
                    onClick={onPlayStatusChange}
                >
                    {status === "PLAY" && <Icon icon={Icons.Pause} width={20} height={20} color="fill-white" />}
                    {status === "PAUSE" && <Icon icon={Icons.PlayFilled} width={20} height={20} color="fill-white" />}
                    {status !== "PLAY" && status !== "PAUSE" && (
                        <Icon icon={Icons.Refresh} width={20} height={20} color="fill-white" />
                    )}
                </button>
            </div>

            <Volume volume={volume} onVolumeUpdate={onVolumeChange} />
            <Bar
                rewind={rewind}
                watchedTime={watchedTime}
                currentTime={currentTime}
                getDuration={getDuration}
                getCurrentTime={getCurrentTime}
                setCurrentTime={onTimeChange}
            />
            <div className="pr-3 cursor-pointer" onClick={onFullscreenHandler}>
                <Icon icon={Icons.Maximise} width={20} height={20} color="fill-white" />
            </div>
        </div>
    );
};
