import React, { useMemo } from "react";
import { Modal } from "Uikit/Modal/Modal";
import { useQuery } from "react-query";
import Api from "Api/index";
import { AchievementResponse } from "Api/Responses/AchievementResponse";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Table } from "Uikit/Table/Table";
import { SecondaryButton } from "Uikit/Button/SecondaryButton";
import { Except } from "type-fest";
import { Achievement } from "Api/Responses/UserResponse";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";

interface AchievementChoiceModalProps {
    except?: Achievement[];
    isOpen: boolean;
    onClose: TVoidFunction;
    onChange: (achievement: AchievementResponse) => void;
}

export const AchievementChoiceModal = ({ isOpen, onClose, ...rest }: AchievementChoiceModalProps) => {
    return (
        <Modal title="Выдать ачивку" isOpen={isOpen} onClose={onClose} className="max-w-162.5">
            <ModalContent onClose={onClose} {...rest} />
        </Modal>
    );
};

const ModalContent = ({ onClose, except = [], onChange }: Except<AchievementChoiceModalProps, "isOpen">) => {
    const { data: allAchievements, isLoading } = useQuery(["achievements", "collection"], () =>
        Api.Achievement.List(0, 1000, [], {}),
    );

    const columns = useMemo<ColumnDef<AchievementResponse>[]>(
        () => [
            {
                header: "Ачивка",
                accessorKey: "name",
                cell: ({
                    row: {
                        original: { logoId, name },
                    },
                    getValue,
                }) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            {logoId && (
                                <img
                                    className="rounded-full w-9 h-9 object-cover"
                                    src={Api.Upload.GetLogo(logoId, LogoSize.THUMB_MICRO)}
                                    alt={name}
                                />
                            )}
                            {!logoId && <div className="rounded-full w-9 h-9 bg-gray-blue" />}
                            <span className="group-hover:text-blue">{String(getValue())}</span>
                        </div>
                    );
                },
            },
            {
                header: "Баллы",
                accessorKey: "rating",
            },
            {
                header: "Доступно",
                accessorKey: "maxQuantity",
                cell: (info) => {
                    return <span>{info.getValue() === -1 ? "ꝏ" : String(info.getValue())}</span>;
                },
                footer: (props) => props.column.id,
            },
        ],
        [],
    );

    if (!allAchievements) {
        return null;
    }

    const handleRowClick = (row: Row<AchievementResponse>) => {
        onChange(row.original);
    };

    const exceptAchievementIds = except.map((item) => item.id);
    const achievements = allAchievements.Content.filter((test) => !exceptAchievementIds.includes(test.id));

    return (
        <>
            <Modal.Body className="mt-6">
                <div className="max-h-112.5 overflow-auto">
                    <Table
                        columns={columns}
                        emptyMessage="По заданным параметрам результатов нет"
                        searchTitle="Поиск по названию"
                        isFetching={isLoading}
                        data={achievements}
                        rowSelection={{}}
                        onRowClick={handleRowClick}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <SecondaryButton onClick={onClose}>Закрыть</SecondaryButton>
            </Modal.Footer>
        </>
    );
};
