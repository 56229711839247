import React from "react";
import { ColumnDef, ColumnFiltersState } from "@tanstack/react-table";
import { Button, Icon, Icons, Table } from "Uikit";
import { AccessItem } from "Api/Responses/AccessResponse";
import { TVoidFunction } from "types";

interface TeamAccessModalTableProps {
    data: AccessItem[] | undefined;
    goNextStep: TVoidFunction;
    setSelectedAccessElement: (element: AccessItem) => void;
}

export const TeamAccessModalTable = ({ data, goNextStep, setSelectedAccessElement }: TeamAccessModalTableProps) => {
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

    const columns = React.useMemo<ColumnDef<AccessItem>[]>(
        () => [
            {
                header: "Название",
                accessorKey: "Title",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <img className="rounded" width={60} height={60} src={info.row.original.Image} />
                            <span className="group-hover:text-blue">{String(info.getValue())}</span>
                        </div>
                    );
                },
            },
            {
                header: "Статус",
                accessorKey: "IsHidden",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <div className="flex items-center space-x-3">
                            <span className={info.getValue() ? "text-[#888E9C]" : ""}>
                                {info.getValue() ? "Скрыт" : "Активен"}
                            </span>
                        </div>
                    );
                },
            },
            {
                header: "",
                id: "buttons",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 30,
                cell: (info) => (
                    <Button
                        shape="round"
                        color="common"
                        iconPlacement="center"
                        icon={
                            <Icon
                                icon={Icons.Close}
                                width={24}
                                height={24}
                                color="fill-blue-drk hover:fill-blue-hover"
                            />
                        }
                        onClick={() => {
                            goNextStep();
                            setSelectedAccessElement(info.row.original);
                        }}
                    />
                ),
            },
        ],
        [goNextStep, setSelectedAccessElement],
    );

    return (
        <Table
            columnClassName="!px-0"
            bodyCellClassName="!px-0"
            columns={columns}
            data={data}
            rowSelection={{}}
            onColumnFiltersChange={setColumnFilters}
            columnFilters={columnFilters}
            emptyMessage="По заданным параметрам результатов нет"
        />
    );
};
