import React from "react";
import { TestUserListResponse } from "Api/Responses/TestResponse";
import { Icon, Icons } from "Uikit";
import { ProgressStatus } from "Enums";
import { TestsCardTime } from "./TestsCardTime";
import { formatDeadlineTime } from "../Tasks/utils";
import { formatStatusChangeTime } from "helpers/dateHelper";
import { FavoriteButton } from "Uikit/FavoriteButton/FavoriteButton";
import clsx from "clsx";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

interface ITestsCard {
    isFinished: boolean;
    test: TestUserListResponse;
    onClick: (taskId: string) => void;
    showStatusChangeTime: boolean;
    statusChangeTimePre?: string;
    onFavoriteChange?: (test: TestUserListResponse, isFavorite: boolean) => void;
}
export const TestsCard = ({
    isFinished,
    test,
    onClick,
    showStatusChangeTime,
    statusChangeTimePre = "Отправлено",
    onFavoriteChange,
}: ITestsCard) => {
    const onFavoriteClick = (isFavorite: boolean) => {
        onFavoriteChange?.(test, isFavorite);
    };

    return (
        <div
            className="group w-full sm:w-[274px] 2xl:w-[342px] mb-5 sm:mb-0 cursor-pointer"
            onClick={() => onClick(test.id)}
        >
            <div className="relative w-full sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 mb-3">
                <div className="relative w-full h-full bg-gray-lighter rounded-2xl 2xl:rounded-3xl overflow-hidden">
                    {test.logoId && (
                        <img
                            className={`w-full h-full rounded-2xl 2xl:rounded-3xl object-cover group-hover:scale-125 transition-transform ease-in-out ${
                                isFinished && "grayscale ease-in-out duration-200 hover:grayscale-0"
                            }`}
                            src={Api.Upload.GetLogo(test.logoId, LogoSize.THUMB_MINI)}
                            alt={test.title}
                        />
                    )}
                    {test.progressStatus === ProgressStatus.IN_PROGRESS && (
                        <div className="w-full absolute bottom-0 h-2 after:content-[''] after:block after:bg-primary after:w-1/2 after:h-full bg-blue-gray"></div>
                    )}
                </div>
                <div className="absolute top-3 left-3 right-3 2xl:top-3.75 2xl:left-3.75 2xl:right-3.75 flex items-start gap-1.5 2xl:gap-2">
                    {showStatusChangeTime && !!test.statusChangeTime && (
                        <TestsCardTime bg={test.progressStatus === "FAILED" ? "bg-red" : undefined}>
                            <span>
                                {statusChangeTimePre} {formatStatusChangeTime(test.statusChangeTime)}
                            </span>
                        </TestsCardTime>
                    )}
                    {!isFinished && test.deadlineTime && (
                        <TestsCardTime time={test.deadlineTime}>
                            <>
                                <Icon
                                    icon={Icons.Time}
                                    width={14}
                                    height={14}
                                    color="fill-white 2xl:!w-4.5 2xl:!h-4.5"
                                />
                                <span>{formatDeadlineTime(test.deadlineTime)}</span>
                            </>
                        </TestsCardTime>
                    )}
                    <div className="ml-auto">
                        <FavoriteButton
                            className={clsx(
                                "opacity-0 transition-opacity",
                                test.isFavorite ? "!opacity-100" : "group-hover:opacity-100",
                            )}
                            isFavorite={test.isFavorite}
                            onChange={onFavoriteClick}
                        />
                    </div>
                </div>
                <div className="absolute bottom-3 left-3 2xl:bottom-3.75 2xl:left-3.75 flex gap-1.5 2xl:gap-2 h-5.25 2xl:h-6.5">
                    {test.hasNewLabel && (
                        <div className="flex items-center py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 bg-red rounded-md 2xl:rounded-lg text-white p3 2xl:text-2sm">
                            <span>NEW</span>
                        </div>
                    )}
                    {(test.ratingPoints || test.ratingPoints === 0) && (
                        <div className="flex items-center gap-1 2xl:gap-1.25 p3 py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 bg-black-50 rounded-md 2xl:rounded-lg text-white 2xl:text-2sm">
                            <Icon icon={Icons.TrophyFilled} width={14} height={14} color="fill-white" />
                            <span>{test.ratingPoints}</span>
                        </div>
                    )}
                </div>
            </div>
            <MultiClumpTooltip label={test.title} textClassName="2xl:text-2md" />
        </div>
    );
};
