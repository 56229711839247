import BaseApi from "../BaseApi";
import { BaseArrayResponse, BaseResponse } from "../BaseResponse";
import { RoleSetRequest } from "../Requests/RoleRequest";
import { RoleResponse, RoleItemResponse } from "../Responses/RoleResponse";
import { AccountableUser } from "Api/Responses/UserResponse";

export default class RoleApi extends BaseApi {
    List = (): Promise<BaseArrayResponse<RoleResponse>> => {
        return this.Get(new BaseArrayResponse(RoleResponse), "/role/all");
    };

    Id = (id: string): Promise<RoleItemResponse> => {
        return this.Get(RoleItemResponse, `/role/get/${id}`);
    };

    Set = (data: RoleSetRequest): Promise<BaseResponse> => {
        return this.Post(BaseResponse, "/role/set", data);
    };

    GetResponsibleList = async (reviewerId?: string): Promise<AccountableUser[]> => {
        return this.Get([], "/api/v1/user/responsible-list" + (reviewerId ? `?current_id=${reviewerId}` : ""));
    };
}
