import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { AsyncPaginate } from "react-select-async-paginate";
import { CustomLoadingMessage, CustomOption } from "Uikit";
import { DropdownIndicator, Menu, MenuList, MultiValue } from "Uikit/Forms/ComboBox";

const defaultMultiState: any = [];

export const AsyncSelectField = ({
    isMulti,
    placeholder,
    accessor,
    value,
    onChange,
    components,
    loadOptions,
    isClearable,
    formatOptionLabel,
    lazy = false,
}: any) => {
    const [option, setOption] = useState(value || isMulti ? defaultMultiState : undefined);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        if (value === undefined) {
            setOption(isMulti ? defaultMultiState : undefined);
            return;
        }

        setOption(value);
    }, [isMulti, value]);

    useEffect(() => {
        if (!option) {
            return;
        }

        if ((isMulti && option.length === 0) || (!isMulti && option.value === undefined)) {
            onChange(accessor, undefined);
            return;
        }

        onChange(accessor, option);
    }, [isMulti, accessor, option, onChange]);

    return (
        <>
            {!lazy ? (
                <AsyncSelect
                    formatOptionLabel={formatOptionLabel}
                    classNamePrefix="ui-combo-box"
                    className="ui-combo-box mt-4"
                    isMulti={isMulti}
                    placeholder={placeholder}
                    cacheOptions={true}
                    defaultOptions={[]}
                    value={option}
                    components={{
                        DropdownIndicator,
                        Menu,
                        MenuList,
                        MultiValue,
                        LoadingIndicator: () => <div></div>,
                        LoadingMessage: CustomLoadingMessage,
                        ...components,
                    }}
                    onChange={(option) => setOption(option)}
                    loadOptions={loadOptions}
                    isClearable={isClearable}
                    menuShouldScrollIntoView={false}
                    noOptionsMessage={() => "Ничего не найдено"}
                    menuPlacement="auto"
                    hideSelectedOptions
                />
            ) : null}
            {lazy ? (
                <AsyncPaginate
                    formatOptionLabel={formatOptionLabel}
                    classNamePrefix="ui-combo-box"
                    className="ui-combo-box mt-4 custom-scroll"
                    isMulti={isMulti}
                    placeholder={placeholder}
                    defaultOptions
                    value={option}
                    components={{
                        DropdownIndicator,
                        Menu,
                        MultiValue,
                        LoadingIndicator: () => <div></div>,
                        LoadingMessage: CustomLoadingMessage,
                        Option: CustomOption,
                        ...components,
                    }}
                    onChange={(newOption) => {
                        setOption(newOption);
                    }}
                    loadOptions={loadOptions}
                    isClearable={false}
                    noOptionsMessage={({ inputValue }) => {
                        return inputValue ? "Ничего не найдено" : "Начните вводить запрос";
                    }}
                    additional={{
                        page: 0,
                        displaySelectedOptionsMenuIsOpen: true,
                    }}
                    menuShouldScrollIntoView={false}
                    menuPlacement="auto"
                    hideSelectedOptions={false}
                    // loadOptionsOnMenuOpen
                    onFocus={() => {
                        setIsInputFocused((prev) => !prev);
                    }}
                    onBlur={() => {
                        setIsInputFocused((prev) => !prev);
                    }}
                    defaultAdditional={{
                        isInputFocused,
                    }}
                />
            ) : null}
        </>
    );
};
