import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SortingState } from "@tanstack/react-table";
import { Breadcrumbs, TabsWrapper, Tabs, Content, Notification } from "Uikit";
import { TreeWrapperContext } from "Containers/TreeWrapper/TreeWrapperContext";
import { AchievementsTable } from "./AchievementsTable";
import { AchievementsHistoryTable } from "./AchievementsHistoryTable";
import Api from "Api";

export const Achievements = () => {
    const { setTreeProps } = useContext(TreeWrapperContext);
    const [usersOptions, setUsersOptions] = useState<{ label: string; value: string | undefined }[]>([]);

    const fetchAchievements = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => {
        return await Api.Achievement.List(page, size, sort, filter);
    };
    const fetchAchievementsHistory = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => {
        return await Api.Achievement.History(page, size, sort, filter);
    };

    const onRemoveAchievements = async (data: string[]) => {
        try {
            await Api.Achievement.Remove({ ids: data });
            toast(
                ({ closeToast }) => {
                    return <Notification text="Ачивка успешно удалена!" type="success" onClose={closeToast} />;
                },
                {
                    position: "bottom-right",
                },
            );
        } catch {
            toast(
                ({ closeToast }) => {
                    return <Notification text="Ошибка удаления ачивки!" type="error" onClose={closeToast} />;
                },
                {
                    position: "bottom-right",
                },
            );
        }
    };

    useEffect(() => {
        const fetch = async () => {
            const users = await Api.User.GetActiveList();
            const usersOptions: { label: string; value: string | undefined }[] = users.Content.map((p) => {
                return { label: p.firstName + " " + p.lastName, value: p.id };
            });

            setUsersOptions(usersOptions);
        };
        fetch().then();
    }, []);
    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps(undefined);
    }, [setTreeProps]);

    return (
        <div className="flex flex-col px-4 pb-4 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-2.5" id="adminSettingsBreadcrumbsAchievements">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Настройки" />
                <Breadcrumbs.Link title="Ачивки" url="/admin/settings/achievements" />
            </Breadcrumbs>
            <TabsWrapper className="mt-2 h-fit flex flex-col flex-grow">
                <TabsWrapper.Tabs classname="h-max space-x-4" id="adminSettingsTabsAchievements">
                    <Tabs.Tab title="Ачивки" />
                    <Tabs.Tab title="История" />
                </TabsWrapper.Tabs>
                <TabsWrapper.Content
                    className="!h-auto flex-grow flex flex-col"
                    panelClassName="!h-auto flex-grow flex flex-col"
                >
                    <Content.Body className="mt-6 h-fit flex-grow">
                        <AchievementsTable fetch={fetchAchievements} onRemove={onRemoveAchievements} />
                    </Content.Body>
                    <Content.Body className="mt-6 h-fit flex-grow">
                        <AchievementsHistoryTable usersOptions={usersOptions} fetch={fetchAchievementsHistory} />
                    </Content.Body>
                </TabsWrapper.Content>
            </TabsWrapper>
        </div>
    );
};
