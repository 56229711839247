import React, { Children, ReactNode } from "react";
import { Popover } from "./Popover";
import clsx from "clsx";
import { TippyProps } from "@tippyjs/react";
import { Except } from "type-fest";

interface PopoverListProps extends Except<TippyProps, "children"> {
    className?: string;
    children?: ReactNode;
}

export const PopoverList = ({ placement = "bottom", className, children, ...rest }: PopoverListProps) => {
    const childrenArray = Children.toArray(children);
    const innerContent = childrenArray.filter((child: any) => child.type !== PopoverList.Item);
    const totalItems = childrenArray.length - innerContent.length;
    const items = childrenArray.map((child: any, position) => {
        return child.type === PopoverList.Item ? React.cloneElement(child, { position, total: totalItems }) : null;
    });

    const content = (
        <Popover.Content className="ui-popover-list-content p-1 flex flex-col gap-1">{items}</Popover.Content>
    );

    return (
        <Popover
            content={content}
            placement={placement}
            className={clsx("ui-popover-list", "w-52", "overflow-hidden", className)}
            {...rest}
        >
            {innerContent}
        </Popover>
    );
};

interface ItemProps {
    position?: number;
    total?: number;
    name: string;
    isActive?: boolean;
    children: ReactNode;
    className?: string;
    onClickItem: (event: any, name: string) => void;
}

const Item = ({ name, isActive = false, children, className, onClickItem, ...rest }: ItemProps) => {
    const onClick = (event: any) => onClickItem(event, name);

    return (
        <div
            className={clsx(
                "ui-popover-list-item",
                "flex cursor-pointer space-x-2.5 px-4.5 py-2.4 align-text-top font-normal leading-p150 rounded-md tracking-normal hover:bg-blue-lightest",
                isActive ? "bg-blue-gray" : null,
                className,
            )}
            onClick={onClick}
            {...rest}
        >
            {children}
        </div>
    );
};

PopoverList.Item = Item;
