import React, { SyntheticEvent } from "react";
import { Icon, Icons } from "Uikit/Icon/Icon";

interface IClearAllButtonProps {
    itemsLength: number;
    onClick: (e?: SyntheticEvent) => void;
}

export const ClearAllButton = ({ itemsLength, onClick }: IClearAllButtonProps) => {
    return (
        <div
            className={`absolute group right-0 flex-center rounded-full top-1/2 -translate-y-1/2 h-5.5 text-sm bg-primary text-white leading-0 w-5.5 ${
                itemsLength > 99 ? "w-auto min-w-5.5 px-1" : ""
            }`}
        >
            <span className="group-hover:invisible">{itemsLength}</span>
            <div
                onClick={onClick}
                onMouseDown={(e) => e.stopPropagation()}
                className="absolute hidden group-hover:block"
            >
                <Icon icon={Icons.Close} width={22} height={22} color="fill-white" />
            </div>
        </div>
    );
};
