import { createSlice, Draft } from "@reduxjs/toolkit";
import { RootState } from "../store";

export enum SourcePage {
    HOME = "HOME",
    TESTS = "TESTS",
}

export interface UtilSliceState {
    sourcePage?: SourcePage | null;
}

const initialState: UtilSliceState = {};

export const utilSlice = createSlice({
    name: "util",
    initialState,
    reducers: {
        setSourcePage: (state: Draft<UtilSliceState>, action) => {
            state.sourcePage = action.payload;
        },
    },
});

export const getSourcePage = (state: RootState) => {
    return state.util.sourcePage;
};

export const { setSourcePage } = utilSlice.actions;

export const utilReducer = utilSlice.reducer;
