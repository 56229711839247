import React from "react";
import { Button, Icon, Icons } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { TVoidFunction } from "types";

interface IProgramsEmpty {
    isEmptyCategories: boolean;
    onCreateCategoryClick: TVoidFunction;
    onCreateProgramClick: TVoidFunction;
}
export const ProgramsEmpty = ({ isEmptyCategories, onCreateCategoryClick, onCreateProgramClick }: IProgramsEmpty) => {
    return (
        <Empty
            title={isEmptyCategories ? "Созданных программ обучения пока нет" : "В категории пока нет программ"}
            description={
                isEmptyCategories
                    ? `Программа без категории существовать не может. Сначала стоит создать категорию, а затем создавать 
                    программу. Название категории отображается в пользовательском интерфейсе`
                    : `Создайте программы обучения и поместите их в эту категорию, чтобы они здесь появились`
            }
        >
            {isEmptyCategories && (
                <>
                    <Button
                        icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-white" />}
                        iconPlacement="left"
                        onClick={onCreateCategoryClick}
                    >
                        Создать категорию
                    </Button>
                    <Button
                        variant="outline"
                        color="secondary"
                        icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-primary" />}
                        iconPlacement="left"
                        onClick={onCreateProgramClick}
                    >
                        Создать программу
                    </Button>
                </>
            )}
            {!isEmptyCategories && (
                <Button
                    icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-white" />}
                    iconPlacement="left"
                    onClick={onCreateProgramClick}
                >
                    Создать программу
                </Button>
            )}
        </Empty>
    );
};
