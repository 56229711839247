import React, { useState } from "react";
import { Button, PopoverList } from "Uikit";
import { TVoidFunction } from "types";

interface IAchievementsRowActionPopover {
    onRemove: TVoidFunction;
}
export const AchievementsRowActionPopover = ({ onRemove }: IAchievementsRowActionPopover) => {
    const [isShow, setIsShow] = useState(false);

    return (
        <PopoverList className="border-gray" offset={[0, 18]} visible={isShow} onClickOutside={() => setIsShow(false)}>
            <Button
                className="!text-blue-drk hover:!text-blue-hover"
                shape="round"
                color="common"
                onClick={() => setIsShow(!isShow)}
            >
                ...
            </Button>
            <PopoverList.Item
                className="border-gray"
                name="remove"
                onClickItem={() => {
                    onRemove();
                    setIsShow(false);
                }}
            >
                Удалить
            </PopoverList.Item>
        </PopoverList>
    );
};
