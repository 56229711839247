import React from "react";
import { useNavigate } from "react-router-dom";
import { TestsCardMobile } from "./TestsCardMobile";
import { TestUserListResponse } from "Api/Responses/TestResponse";

interface ITestsCardListMobile {
    id?: string;
    isFinished: boolean;
    isReview: boolean;
    title?: string;
    data: TestUserListResponse[];
}
export const TestsCardListMobile = ({ id, isFinished, isReview, title, data }: ITestsCardListMobile) => {
    const navigate = useNavigate();

    return (
        <div className="mb-7" key={id}>
            {title && (
                <h3 id={id + "Title"} className="mb-4 text-black">
                    {title}
                </h3>
            )}
            {data.map((test) => {
                return (
                    <TestsCardMobile
                        key={test.id + test.statusChangeTime}
                        test={test}
                        onClick={() =>
                            isFinished || isReview
                                ? navigate(`/test/${test.id}/statistics`)
                                : navigate(`/test/${test.id}?parent=tests`)
                        }
                    />
                );
            })}
        </div>
    );
};
