import React, { useState } from "react";
import {
    Breadcrumbs,
    Button,
    Checkbox,
    ComboBox,
    Content,
    FormGroup,
    Icon,
    Icons,
    Input,
    Label,
    Modal,
    ModalTree,
    Notification,
    PopoverList,
    RadioGroup,
    Select,
    Tabs,
    TabsWrapper,
    Textarea,
    Toggle,
    Tree,
    Typography,
} from "Uikit";
import clsx from "clsx";
import { TableDemo } from "Uikit/Table/TableDemo";

export const tree = [
    {
        id: "GENERAL",
        name: "Общая статистика",
        nodeType: "PROJECT",
        children: [],
    },
    {
        id: "DOCUMENTS",
        name: "Документы",
        nodeType: "PROJECT",
        children: [
            // {
            //     id: 'PROJECT_INFO',
            //     name: 'Информация по проекту',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'SECTION_INFO',
            //     name: 'Информация по разделам',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'DOCUMENTS_INFO',
            //     name: 'Информация по документам',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ARTICLES_IN_PROJECTS',
            //     name: 'Статьи в проекте',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'NEWS_IN_PROJECTS',
            //     name: 'Новости в проекте',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ARTICLES_IN_SECTIONS',
            //     name: 'Статьи в разделе',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'GENERAL_NEWS',
            //     name: 'Общие новости',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            {
                id: "CONTENT_REPORT",
                name: "Отчет по контенту",
                nodeType: "SECTION",
                children: null,
            },
            // {
            //     id: 'FILES_IN_PROJECTS',
            //     name: 'Файлы в проектах',
            //     nodeType: 'SECTION',
            //     children: [],
            // },
            // {
            //     id: 'FILES_IN_SECTIONS',
            //     name: 'Файлы в разделах',
            //     nodeType: 'SECTION',
            //     children: [],
            // },
            // {
            //     id: 'FILES_IN_DOCUMENTS',
            //     name: 'Файлы в документах',
            //     nodeType: 'SECTION',
            //     children: [],
            // },
            {
                id: "FILES_REPORTS",
                name: "Отчет по файлам",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "DOCUMENTS_POPULARITY_BY_RATING",
                name: "Популярность документов по оценкам",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "DOCUMENTS_EVALUATION",
                name: "Оценивание документа",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "COMMENTS_IN_DOCUMENTS",
                name: "Комментарии в документе",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "ARTICLE_RATING_BY_COMMENTS",
                name: "Популярность статей по комментариям",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "ERROR_NOTIFICATIONS",
                name: "Уведомления о ошибках",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "EDIT_NOTIFICATIONS",
                name: "Уведомления о изменениях",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "REFERENCES_TO_ARTICLES",
                name: "Обращение к статьям",
                nodeType: "SECTION",
                children: null,
            },
        ],
    },
    {
        id: "USERS",
        name: "Участники",
        nodeType: "PROJECT",
        children: [
            {
                id: "USERS_INFO",
                name: "Информация о участниках",
                nodeType: "SECTION",
                children: null,
            },
            // {
            //     id: 'USER_IN_PROJECTS',
            //     name: 'Участники в проектах',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'USER_IN_SECTIONS',
            //     name: 'Участники в разделах',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'USER_IN_DOCUMENTS',
            //     name: 'Участники в документах',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            {
                id: "USERS_REPORT",
                name: "Отчет по участникам",
                nodeType: "SECTION",
                children: null,
            },
        ],
    },
    {
        id: "ACTIVITIES",
        name: "Активность",
        nodeType: "PROJECT",
        children: [
            // {
            //     id: 'ACTIONS_IN_PROJECTS',
            //     name: 'События в проектах',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ACTIONS_IN_SECTIONS',
            //     name: 'События в разделах',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ACTIONS_IN_ARTICLES',
            //     name: 'События в статьях',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ACTIONS_IN_NEWS',
            //     name: 'События в новостях',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            {
                id: "ACTIONS_REPORT",
                name: "Отчет по активностям",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "READS",
                name: "Прочтение документов",
                nodeType: "SECTION",
                children: null,
            },
        ],
    },
    {
        id: "SEARCH",
        name: "Поиск",
        nodeType: "PROJECT",
        children: [
            {
                id: "SEARCH_REQUESTS",
                name: "Поисковые запросы",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "DETAILED_SEARCH_REQUEST",
                name: "Детализация запроса",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "ARTICLES_OPTIMIZE",
                name: "Оптимизация поиска",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "TOP_RESULTS",
                name: "Топ выдачи",
                nodeType: "SECTION",
                children: null,
            },
            {
                id: "ANTI_TOP_ARTICLES",
                name: "Антитоп выдачи",
                nodeType: "SECTION",
                children: null,
            },
        ],
    },
];

export const KitPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [firstToggleEnabled, setFirstToggleEnabled] = useState(false);
    const [secondToggleEnabled, setSecondToggleEnabled] = useState(false);

    const [radioButtonValue, setRadioButtonValue] = useState(false);

    const [checkedNodes, setCheckedNodes] = useState(false);

    const modalFooter = [
        <Button
            key={0}
            onClick={() => setIsOpen(false)}
            variant="outline"
            size={"small"}
            color={"secondary"}
            className={"border-[#EAEDF3] "}
        >
            Отмена
        </Button>,
        <Button key={1} onClick={() => setIsOpen(false)} size={"small"} color={"danger"}>
            Удалить
        </Button>,
    ];

    const modalBody = (
        <div>
            <div className="text-sm small leading-6 text-[#9B9B9B] mr-auto">«Техники продаж»</div>

            <div className="mb-6 mt-3">
                <p className="text-sm text-[#262626]-500">
                    Вы действительно хотите удалить папку? Все вложенные в нее элементы попадут в Архив. Восстановить их
                    сможет только администратор
                </p>
            </div>
        </div>
    );

    const onSubmit = () => {
        console.log(checkedNodes);
    };

    const onCheckedChange = (nodes: any) => {
        setCheckedNodes(nodes["PROJECT"].concat(nodes["SECTION"]).concat(nodes["ARTICLE"]));
    };

    return (
        <div className="p-4 w-full h-full">
            <h1 className="text-3xl font-bold underline">Ui Kit</h1>

            {/*Version 1*/}
            <TabsWrapper>
                <TabsWrapper.Tabs>
                    <Tabs.Tab title="Типографика" />
                    <Tabs.Tab title="Кнопки" />
                    <Tabs.Tab title="Модальное окно" />
                    <Tabs.Tab title="Инпут" />
                    <Tabs.Tab title="Чекбокс" />
                    <Tabs.Tab title="Оповещения" />
                    <Tabs.Tab title="Дерево" />
                    <Tabs.Tab title="Селект" />
                    <Tabs.Tab title="Popover List" />
                    <Tabs.Tab title="Переключатель" />
                    <Tabs.Tab title="Radiobutton" />
                    <Tabs.Tab title="Хлебные крошки" />
                    <Tabs.Tab title="Таблица" />
                    <Tabs.Tab title="Модальное окно с деревом" />
                    <Tabs.Tab title="Сайд меню" />
                    <Tabs.Tab title="Все иконки" />
                </TabsWrapper.Tabs>

                <TabsWrapper.Content>
                    <Content.Body>
                        <Typography />
                    </Content.Body>
                    <Content.Body>
                        <div className="w-full space-y-10">
                            <div className="space-x-4">
                                <Button>Нажми меня</Button>
                                <Button color="warning">Нажми меня</Button>
                                <Button variant="outline">Нажми меня</Button>
                                <Button variant="link" color="danger">
                                    Нажми меня
                                </Button>

                                <Button
                                    className={"border border-[#E8E8E8]"}
                                    shape={"square"}
                                    size={"medium"}
                                    icon={<Icon icon={Icons.ArrowRight} width={"22px"} height={"22px"} />}
                                    iconPlacement={"center"}
                                    color={"common"}
                                />

                                <Button
                                    className={"border border-[#E8E8E8]"}
                                    shape={"round"}
                                    size={"medium"}
                                    icon={<Icon icon={Icons.Eye} width={"25px"} height={"25px"} />}
                                    iconPlacement={"center"}
                                    color={"common"}
                                />
                            </div>
                        </div>
                    </Content.Body>

                    <Content.Body>
                        <div className="space-y-10">
                            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={"Удаление папки"}>
                                <Modal.Body>{modalBody}</Modal.Body>
                                <Modal.Footer>{modalFooter}</Modal.Footer>
                            </Modal>
                            <Button color={"danger"} onClick={() => setIsOpen(true)}>
                                Удалить
                            </Button>
                        </div>
                    </Content.Body>

                    <Content.Body className="lg:max-w-screen-sm">
                        <FormGroup>
                            <Label>Инпут</Label>
                            <Input />
                        </FormGroup>
                        <FormGroup>
                            <Label>Текст</Label>
                            <Textarea />
                        </FormGroup>
                        <DefaultComboBoxDemo />
                        <TagsCreatorComboBoxDemo />
                        <UseCustomSchemaAsOptions />
                        <RenderCustomOptionItems />
                    </Content.Body>

                    <Content.Body>
                        <Label>Чекбокс</Label>
                        <div>
                            <FormGroup className="flex">
                                <Checkbox className="mr-1" checked={true} disabled={true} />
                                <Checkbox className="mr-1" indeterminate={true} disabled={true} />
                                <Checkbox disabled />
                            </FormGroup>
                            <FormGroup>
                                <Checkbox checked={true} label="Чекбокс" />
                            </FormGroup>
                            <FormGroup>
                                <Checkbox indeterminate={true} label="Чекбокс" />
                            </FormGroup>
                            <FormGroup>
                                <Checkbox label="Чекбокс" />
                            </FormGroup>
                            <FormGroup>
                                <Checkbox label="Чекбокс" disabled />
                            </FormGroup>
                        </div>
                    </Content.Body>

                    <Content.Body>
                        <div className="flex flex-col gap-4">
                            <Notification text="Выберите материал" type="error" />
                            <Notification text="Все изменения сохранены" type="success" />
                        </div>
                    </Content.Body>

                    <Content.Body>
                        <Label>Дерево</Label>
                        <Tree data={tree} />
                    </Content.Body>

                    <Content.Body>
                        <FormGroup>
                            <Label>Small</Label>

                            <Select size="small">
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                            </Select>
                        </FormGroup>

                        <FormGroup>
                            <Label>Medium</Label>

                            <Select size="medium">
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                            </Select>
                        </FormGroup>

                        <FormGroup>
                            <Label>Large</Label>

                            <Select size="large">
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                            </Select>
                        </FormGroup>
                    </Content.Body>

                    <Content.Body>
                        <PopoverListDemo />
                    </Content.Body>

                    <Content.Body>
                        <div>
                            <Toggle enabled={firstToggleEnabled} onChange={setFirstToggleEnabled} />
                        </div>
                        <div className={"mt-2"}>
                            <Toggle
                                label={"Назначить отложенную дату публикации"}
                                enabled={secondToggleEnabled}
                                onChange={setSecondToggleEnabled}
                            />
                        </div>
                    </Content.Body>

                    <Content.Body>
                        <RadioGroup
                            label={"Кто такой Джон Голд?"}
                            value={radioButtonValue}
                            onChange={setRadioButtonValue}
                        >
                            <RadioGroup.Button label="Политик, теоретик из будущего инопланетян" value={"value1"} />
                            <RadioGroup.Button label="Человек из космоса" value={"value2"} />
                            <RadioGroup.Button
                                label="Предприниматель крупной фирмы по плавке металла"
                                value={"value3"}
                            />
                        </RadioGroup>
                    </Content.Body>

                    <Content.Body>
                        <Breadcrumbs>
                            <Breadcrumbs.Link title={"Администратор"} url={""} />
                            <Breadcrumbs.Link title={"Курсы"} url={""} />
                            <Breadcrumbs.Link title={"Материалы"} url={""} />
                        </Breadcrumbs>
                    </Content.Body>
                    <Content.Body>
                        <TableDemo />
                    </Content.Body>
                    <Content.Body>
                        <ModalTree
                            isOpen={isOpen}
                            title="Добавление материала"
                            setIsOpen={setIsOpen}
                            treeData={tree}
                            checkedChange={onCheckedChange}
                            onSubmit={onSubmit}
                        />
                        <Button color={"success"} onClick={() => setIsOpen(true)}>
                            Открыть модальное окно
                        </Button>
                    </Content.Body>

                    <Content.Body>
                        <a href={"/admin/courses"}>переход к примеру</a>
                    </Content.Body>

                    <Content.Body>
                        <div className="grid grid-cols-10 gap-4 pb-4 h-full overflow-auto">
                            {Object.keys(Icons).map((key: string) => {
                                return (
                                    <div className={"p-2 rounded border border-[#eee] bg-[#eee]"} key={String(key)}>
                                        <p className={"mb-1"}>{key}</p>
                                        <Icon icon={Icons[key as keyof typeof Icons]} width={18} height={18} />
                                    </div>
                                );
                            })}
                        </div>
                    </Content.Body>
                </TabsWrapper.Content>
            </TabsWrapper>
        </div>
    );
};

const PopoverListDemo = () => {
    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const onClickItem = (event_: any, element: any) => {
        console.log("Clicked on", event_, element);
        setVisible(false);
    };

    return (
        <div className="h-32">
            <PopoverList offset={[0, 18]} visible={visible} onClickOutside={hide}>
                <Button onClick={visible ? hide : show}>Кнопка</Button>
                <PopoverList.Item name="list" onClickItem={onClickItem}>
                    Пункт 0.1.1
                </PopoverList.Item>
                <PopoverList.Item name="guides" onClickItem={onClickItem}>
                    Пункт 0.2
                </PopoverList.Item>
            </PopoverList>
        </div>
    );
};

const DefaultComboBoxDemo = () => {
    return (
        <FormGroup>
            <Label>Скидка</Label>
        </FormGroup>
    );
};

const TagsCreatorComboBoxDemo = () => {
    const tags = [
        { value: 1, label: "5%" },
        { value: 2, label: "10%" },
    ];

    return (
        <FormGroup>
            <Label>Тэги</Label>
            <ComboBox
                isCreatable
                isMulti
                closeMenuOnSelect
                options={tags}
                defaultValue={tags}
                placeholder="добавить тэг"
            />
        </FormGroup>
    );
};

const UseCustomSchemaAsOptions = () => {
    const options = [
        { id: 1, name: "Перец" },
        { id: 2, name: "Морковка" },
    ];

    return (
        <FormGroup>
            <Label>Какой ты фрукт?</Label>

            <ComboBox
                options={options}
                getOptionValue={(option) => option.id.toString()}
                getOptionLabel={(option) => option.name}
            />
        </FormGroup>
    );
};

const RenderCustomOptionItems = () => {
    const options = [
        { id: 1, name: "Перец", description: "Lorem ipsum", price: 123.45 },
        { id: 2, name: "Морковка", description: "Dolor sit amet", price: 456.78 },
    ];

    const Option = ({ innerProps, innerRef, label, data, isSelected, isFocused }: any) => (
        <div
            ref={innerRef}
            className={clsx(
                "flex items-center border-b border-gray-blue p-4",
                isSelected && "bg-success-dark text-white",
                !isSelected && isFocused && "bg-success-light",
            )}
            {...innerProps}
        >
            <div className="mr-auto">
                <p className="font-bold">{label}</p>
                <span className="text-sm text-black">{data.description}</span>
            </div>
        </div>
    );

    return (
        <div className="h-60">
            <FormGroup>
                <Label>Какой ты фрукт?</Label>

                <ComboBox
                    options={options}
                    getOptionValue={(option) => option.id.toString()}
                    getOptionLabel={(option) => option.name}
                    components={{ Option }}
                />
            </FormGroup>
        </div>
    );
};
