import React, { useEffect, useId } from "react";
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { FormGroup, Label, Toggle, Icon, Icons, Button, Checkbox, TextareaAutoSizable } from "Uikit";
import { testingRegexp } from "Uikit/Forms/Input";
import { getObjectUniqueId } from "helpers/objectUniqueId";
import { TaskAnswerResponseItem, TaskReadResponseItem } from "Api/Responses/TaskResponse";
import { TaskItemChoiceItem } from "./TaskItemChoiceItem";
import { ITaskQuestionProps } from "./TaskContentItem";

const getOrderedAnswers = (answers: TaskAnswerResponseItem[]) => {
    return answers.map((item, index) => {
        return {
            ...item,
            orderNumber: index,
        };
    });
};

export const TaskQuestionMultipleChoice = ({
    taskItem,
    onEditTaskItem,
    errors = {},
    expanded = false,
}: ITaskQuestionProps) => {
    const id = useId();
    const maxLength = 1024;
    const maxReviewerNoteLength = 256;
    useEffect(() => {
        if (!taskItem.answers || taskItem.answers.length === 0) {
            for (let i = 0; i <= 1; i++) {
                taskItem.answers.push({ ...new TaskAnswerResponseItem(), answer: ``, orderNumber: i });
            }
            onEditTaskItem(taskItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const onChangeChoiceItem = (index: number, value: string) => {
        taskItem.answers[index].answer = value;
        onEditTaskItem(taskItem);
    };

    const onDeleteChoiceItem = (index: number) => {
        taskItem.answers.splice(index, 1);
        onEditTaskItem({
            ...taskItem,
            answers: getOrderedAnswers(taskItem.answers),
        });
    };

    const onChangeFieldValueHandler = (fieldName: keyof TaskReadResponseItem, value: string | boolean) => {
        (taskItem as unknown as Record<typeof fieldName, string | boolean>)[fieldName] = value;
        onEditTaskItem(taskItem);
    };

    const onAddVariantHandler = () => {
        taskItem.answers.push({
            ...new TaskAnswerResponseItem(),
            answer: ``,
            orderNumber: taskItem.answers.length,
        });
        onEditTaskItem(taskItem);
    };

    return (
        <>
            {expanded ? (
                <>
                    <FormGroup>
                        <Label isRequired={taskItem.mandatory}>Текст вопроса</Label>
                        <TextareaAutoSizable
                            placeholder="Введите текст вопроса"
                            value={taskItem.question}
                            error={errors?.question}
                            maxLength={maxLength}
                            cutRegExp={testingRegexp}
                            className="resize-none"
                            id={id + "adminNewTaskContentTabInputTextQuestion"}
                            onChange={(e) => {
                                onChangeFieldValueHandler("question", e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={false}>Краткое пояснение</Label>
                        <TextareaAutoSizable
                            placeholder="Введите краткое пояснение"
                            value={taskItem.description}
                            maxLength={maxReviewerNoteLength}
                            cutRegExp={testingRegexp}
                            className="resize-none"
                            onChange={(e) => {
                                onChangeFieldValueHandler("description", e.target.value);
                            }}
                            id={id + "adminNewTaskContentTabInputDescription"}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={taskItem.mandatory}>Комментарий для проверяющего</Label>
                        <TextareaAutoSizable
                            placeholder="Введите комментарий"
                            value={taskItem.reviewerNote}
                            error={errors?.reviewerNote}
                            maxLength={maxLength}
                            cutRegExp={testingRegexp}
                            className="resize-none"
                            id={id + "adminNewTaskContentTabInputComment"}
                            onChange={(e) => {
                                onChangeFieldValueHandler("reviewerNote", e.target.value);
                            }}
                        />
                    </FormGroup>
                    <div className="pt-5 pb-5 border-t border-gray-blue">
                        <Label isRequired={taskItem.mandatory}>Варианты ответа</Label>
                        <div>
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                            >
                                <SortableContext
                                    items={taskItem.answers.map((item) => {
                                        return item.orderNumber + 1;
                                    })}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {taskItem.answers
                                        .sort((a, b) => a.orderNumber - b.orderNumber)
                                        .map((variant, variantIndex) => {
                                            return (
                                                <TaskItemChoiceItem
                                                    key={`TaskItemChoiceItem_${getObjectUniqueId(variant)}`}
                                                    variant={variant}
                                                    index={variantIndex}
                                                    onChange={onChangeChoiceItem}
                                                    onDelete={onDeleteChoiceItem}
                                                    deletable={taskItem.answers.length > 2}
                                                    error={
                                                        (errors?.answers as unknown as { answer: string }[])?.[
                                                            variantIndex
                                                        ]?.answer
                                                    }
                                                />
                                            );
                                        })}
                                </SortableContext>
                            </DndContext>
                        </div>
                        <Button
                            className="w-full"
                            variant="standard"
                            color="gray"
                            onClick={onAddVariantHandler}
                            id={id + "adminNewTaskContentTabBtnAddVar"}
                        >
                            <Icon
                                className="mr-1.5 !fill-primary"
                                icon={Icons.PlusFilled}
                                width={20}
                                height={20}
                                color="fill-blue-DEFAULT"
                            />
                            <span className="text-blue-DEFAULT">Добавить вариант</span>
                        </Button>
                    </div>
                    <FormGroup className="flex items-center mt-2 pt-7.5 border-t border-gray-blue">
                        <Toggle
                            label="Обязательный вопрос"
                            enabled={taskItem.mandatory}
                            onChange={(e) => {
                                onChangeFieldValueHandler("mandatory", e);
                            }}
                            id={id + "adminNewTaskContentTabToggleRequired"}
                        />
                    </FormGroup>
                </>
            ) : (
                <FormGroup
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="flex flex-col gap-4 !cursor-default"
                >
                    <div className="flex flex-col gap-2">
                        <Label className="!pb-0" isRequired={taskItem.mandatory}>
                            {taskItem.question}
                        </Label>
                        {taskItem.description && <p className="text-sm text-gray">{taskItem.description}</p>}
                    </div>
                    {taskItem.answers
                        .sort((a, b) => a.orderNumber - b.orderNumber)
                        .map((variant) => {
                            return (
                                <Checkbox
                                    key={`TaskItemChoiceItem_${getObjectUniqueId(variant)}`}
                                    label={variant.answer}
                                    value={variant.answer}
                                    className="pointer-events-none"
                                />
                            );
                        })}
                </FormGroup>
            )}
        </>
    );

    function handleDragEnd(event: DragEndEvent) {
        const { answers } = taskItem;
        const { active, over } = event;
        if (active.id !== over?.id) {
            const oldIndex = answers.findIndex((i: TaskAnswerResponseItem) => i.orderNumber + 1 === active.id);
            const newIndex = answers.findIndex((i: TaskAnswerResponseItem) => i.orderNumber + 1 === over?.id);

            onEditTaskItem({
                ...taskItem,
                answers: getOrderedAnswers(arrayMove(answers, oldIndex, newIndex)),
            });
        }
    }
};
