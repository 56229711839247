import React from "react";
import { components, GroupBase, ValueContainerProps } from "react-select";
import Api from "Api";
import { LogoSize } from "Api/Services/UploadApi";
import { IStatisticValue } from "./utils";

type StatisticValueWithImage = IStatisticValue & { logoId?: string };

export const SingleValueContainer = ({
    children,
    getValue,
    ...rest
}: ValueContainerProps<IStatisticValue, true, GroupBase<any>>) => {
    const [value]: readonly StatisticValueWithImage[] = getValue();

    if (value?.logoId) {
        return (
            <components.ValueContainer getValue={getValue} {...rest}>
                <div className="flex space-x-2 items-center">
                    <div>
                        <img
                            className="rounded w-7 h-5"
                            src={Api.Upload.GetLogo(value.logoId, LogoSize.THUMB_MICRO)}
                            alt={value.name}
                        />
                    </div>
                    <div>{children}</div>
                </div>
            </components.ValueContainer>
        );
    }

    return (
        <components.ValueContainer getValue={getValue} {...rest}>
            <div className="flex items-center">{children}</div>
        </components.ValueContainer>
    );
};
