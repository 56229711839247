import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Api from "Api/index";
import { ProgressStatus, ResourceType } from "Enums";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { ContentLayout } from "Containers/ContentLayout";
import { Loader } from "Uikit/Loader/Loader";
import { Button } from "Uikit/Button/Button";
import { Icon, Icons } from "Uikit/Icon/Icon";
import clsx from "clsx";
import { MaterialReadResponse } from "Api/Responses/MaterialResponse";
import { CourseReadResponse, CourseReadResponseSectionItem } from "Api/Responses/CourseResponse";
import { ScormWithAttemptsModal } from "./ScormWithAttemptsModal";
import { Tooltip } from "Uikit/Tooltip";
import { ScormProgressResponse } from "Api/Responses/ScormProgressResponse";
import NotificationConnect, { NotificationSubscribe, WssMessage, WssMessageType } from "Api/Wss/Notifications";
import { useChangeMaterialFavoriteStatus } from "./Course.hooks";
import { FavoriteButton } from "Uikit/FavoriteButton/FavoriteButton";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";

export const CourseMaterialScorm = ({
    course,
    material,
    component,
    onMaterialStart,
    onNextMaterial,
    isLastComponent,
    onClose,
}: {
    course: CourseReadResponse;
    material: MaterialReadResponse;
    component: CourseReadResponseSectionItem | null;
    onMaterialStart: TVoidFunction;
    onNextMaterial: TVoidFunction;
    isLastComponent: boolean | undefined;
    onClose: TVoidFunction;
}) => {
    const dispatch = useDispatch();
    const { mutate: changeFavoriteStatus } = useChangeMaterialFavoriteStatus();

    const [isScormWithAttemptModalOpen, setIsScormWithAttemptModalOpen] = useState(false);
    const [coverLoading, setCoverLoading] = useState(true);
    const [scormProgress, setScormProgress] = useState<ScormProgressResponse>();

    const [canGoNextMaterial, setCanGoNextMaterial] = useState<boolean>(material.complexity !== "SCORM");

    const onFavoriteChange = async () => {
        changeFavoriteStatus({
            courseId: course.resourceId,
            componentId: component!.id,
            resourceId: material.id,
            materialType: material.type,
        });
    };

    const openScormPlayerInNewWindow = () => {
        if (component?.progressStatus === ProgressStatus.NOT_STARTED) {
            onMaterialStart();
        }

        sessionStorage.setItem("currentMaterial", JSON.stringify(material));
        sessionStorage.setItem("currentCourseComponent", JSON.stringify(component));
        sessionStorage.setItem("courseId", course.resourceId || "");
        sessionStorage.setItem("scormViewMode", "user");
        window.open(`/scorm/${material.file.id}`, "_blank");
    };

    const handleComplete = async () => {
        if (
            component?.progressStatus !== ProgressStatus.PASSED &&
            material.complexity === "SCORM" &&
            (!material.scoreRewriteLimit ||
                (scormProgress?.passingCount && material.scoreRewriteLimit > scormProgress?.passingCount))
        ) {
            setIsScormWithAttemptModalOpen(true);
        } else {
            onNextMaterial();
        }
    };

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("SCORM"));
        dispatch(setIsFavorite(true));

        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.messageType === WssMessageType.NOTIFICATION) {
                if (
                    message.body.actionType === ProgressStatus.PASSED &&
                    message.body.objectType === ResourceType.MATERIAL
                ) {
                    setCanGoNextMaterial(true);
                }
            }
        });
    }, [dispatch]);
    useEffect(() => {
        if (
            (!scormProgress || scormProgress.materialId !== material.id) &&
            component?.progressStatus !== "NOT_STARTED"
        ) {
            const fetchScormProgress = async () => {
                try {
                    const scormProgress = await Api.ScormProgress.GetProgress(material.id);
                    setScormProgress(scormProgress);

                    const scormCompleted =
                        scormProgress?.completionStatus === "completed" ||
                        scormProgress?.completionStatus === "passed" ||
                        scormProgress?.completionStatus === "failed";
                    const scormSucceed =
                        scormProgress?.successStatus === "passed" || scormProgress?.successStatus === "failed";
                    setCanGoNextMaterial(material.complexity !== "SCORM" || scormCompleted || scormSucceed);
                } catch (error) {
                    console.log(error);
                }
            };

            fetchScormProgress().then();
        }
    }, [material.id, material.complexity, component?.progressStatus, scormProgress]);

    return (
        <ContentLayout className="h-full !px-0">
            <ScormWithAttemptsModal
                isOpen={isScormWithAttemptModalOpen}
                onClose={() => setIsScormWithAttemptModalOpen(false)}
                onSuccess={onNextMaterial}
                isLastComponent={isLastComponent}
            />
            <div className="relative sm:pt-6">
                <Button
                    className="hidden sm:flex absolute -top-3 right-5 flex-center active:!ring-0 hover:!shadow-none active:!bg-transparent w-10 h-10"
                    variant="outline"
                    color="secondary"
                    icon={
                        <Icon
                            icon={Icons.Close}
                            width={20}
                            height={20}
                            color="fill-[#868E9C] 2xl:!w-6.25 2xl:!h-6.25"
                        />
                    }
                    iconPlacement={"center"}
                    shape={"square"}
                    onClick={onClose}
                ></Button>
                {material && (
                    <div className="pb-17.5">
                        <div className="flex flex-col items-center w-full sm:w-[700px] 2xl:w-[875px] mx-auto">
                            <div className="relative w-full h-[60vw] sm:h-[350px] 2xl:h-[492px] mb-5 2xl:mb-6.25">
                                <div className="absolute top-5 2xl:top-6.25 right-5 2xl:right-6.25">
                                    <FavoriteButton
                                        className="hidden sm:flex"
                                        onChange={onFavoriteChange}
                                        isFavorite={material?.isFavorite}
                                    />
                                </div>
                                {coverLoading && (
                                    <div className="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2">
                                        <Loader />
                                    </div>
                                )}
                                <img
                                    className={clsx(
                                        "rounded-3xl 2xl:rounded-3.5xl object-cover w-full h-full",
                                        coverLoading && "invisible",
                                    )}
                                    src={Api.Upload.GetLogo(material.logoId ?? "", LogoSize.ORIGINAL)}
                                    onLoad={() => {
                                        setCoverLoading(false);
                                    }}
                                />
                            </div>
                            <div className="w-full sm:w-auto sm:text-center">
                                <h1
                                    className="hidden sm:block max-w-175 2xl:max-w-[875px] 2xl:!text-4xl 2xl:!leading-[41px] text-center text-black break-anywhere"
                                    id="userTaskTitle"
                                >
                                    {material.title}
                                </h1>
                                <h2 className="block sm:hidden mb-2 w-full text-black break-anywhere">
                                    {material.title}
                                </h2>
                                <p
                                    className="mt-2 2xl:mt-2.5 w-full sm:max-w-175 2xl:max-w-[875px] sm:text-center p3 2xl:text-md text-gray-dark sm:text-blue-drk break-anywhere"
                                    id="userTaskDescription"
                                >
                                    {material.description}
                                </p>
                            </div>
                            <div
                                className={clsx(
                                    "fixed sm:relative bottom-14 sm:bottom-0 flex sm:flex-row sm:space-x-5 2xl:space-x-6.25 w-full sm:w-auto text-center mt-4 sm:mt-5 2xl:mt-6.25",
                                    component?.progressStatus === ProgressStatus.PASSED
                                        ? "flex-col-reverse"
                                        : "flex-col",
                                )}
                            >
                                <Button
                                    size="medium"
                                    className="m-2.5 sm:m-0 h-12 sm:h-10 rounded-lg whitespace-nowrap font-medium"
                                    icon={
                                        component?.progressStatus === ProgressStatus.PASSED ? null : (
                                            <Icon
                                                className="2xl:!w-6.25 2xl:!h-6.25"
                                                icon={Icons.PlayCircle}
                                                color="fill-white"
                                                width={20}
                                                height={20}
                                            />
                                        )
                                    }
                                    color={component?.progressStatus === ProgressStatus.PASSED ? "gray" : "primary"}
                                    iconPlacement="left"
                                    onClick={openScormPlayerInNewWindow}
                                    id="userTaskingBtn"
                                >
                                    {component?.progressStatus === ProgressStatus.NOT_STARTED && "Начать прохождение"}
                                    {component?.progressStatus === ProgressStatus.IN_PROGRESS &&
                                        "Продолжить прохождение"}
                                    {component?.progressStatus === ProgressStatus.PASSED && "Посмотреть"}
                                </Button>
                                {(component?.progressStatus === ProgressStatus.IN_PROGRESS ||
                                    component?.progressStatus === ProgressStatus.PASSED) && (
                                    <Tooltip
                                        className="flex"
                                        content={"Действие недоступно, пока SCORM не пройден"}
                                        disabled={canGoNextMaterial}
                                        maxWidth={500}
                                    >
                                        <Button
                                            size="medium"
                                            color={
                                                component?.progressStatus === ProgressStatus.PASSED && !isLastComponent
                                                    ? "primary"
                                                    : "gray"
                                            }
                                            className="m-2.5 sm:m-0 w-full h-12 sm:h-10 rounded-lg whitespace-nowrap font-medium"
                                            icon={
                                                isLastComponent ? (
                                                    <Icon
                                                        className="2xl:!w-6.25 2xl:!h-6.25"
                                                        icon={Icons.Check}
                                                        color={canGoNextMaterial ? "fill-[#0084E2]" : "fill-[#C1C6CA]"}
                                                        width={20}
                                                        height={20}
                                                    />
                                                ) : (
                                                    <Icon
                                                        className="2xl:!w-3.75 2xl:!h-3.75"
                                                        icon={Icons.ChevronRight}
                                                        color={
                                                            canGoNextMaterial
                                                                ? component.progressStatus === ProgressStatus.PASSED
                                                                    ? "fill-white"
                                                                    : "fill-[#0084E2]"
                                                                : "fill-[#C1C6CA]"
                                                        }
                                                        width={12}
                                                        height={12}
                                                    />
                                                )
                                            }
                                            disabled={!canGoNextMaterial}
                                            iconPlacement={isLastComponent ? "left" : "right"}
                                            onClick={handleComplete}
                                        >
                                            <span
                                                className={
                                                    canGoNextMaterial ? "" : "!text-gray-light !disabled:text-[#C1C6CA]"
                                                }
                                            >
                                                {isLastComponent ? "Завершить курс" : "Следующий материал"}
                                            </span>
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                            <div
                                id="userTestingInfo"
                                className="flex flex-col sm:flex-row flex-wrap justify-center sm:mt-5 2xl:mt-6.25 sm:mb-12 w-full sm:w-125"
                            >
                                <p className="mb-2 sm:px-3 sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                    <span className="inline-block w-30 sm:w-auto">Займет:&nbsp;</span>
                                    <span className="p3 2xl:p2">
                                        ~{Math.abs(material.approxCompletionMinutes).toString()} мин
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ContentLayout>
    );
};
