import React, { useEffect, useState } from "react";
import { Button, ComboBox, FormGroup, Label, OptionsDropdownIndicator } from "Uikit";
import { ResourceState, StatisticsAttempts } from "Enums";
import { TasksModalTree } from "../../Tasks/TasksModalTree";
import { CourseListResponse } from "Api/Responses/CourseResponse";
import { IStatisticValue, structurizeCourseTreeData, structurizeTestTreeData } from "../../utils";
import { useQuery } from "react-query";
import { TTaskFilterValue, attemptsOptions } from "../TestWithoutCourse";
import Api from "Api";
import { TestListResponse } from "Api/Responses/TestResponse";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { ReportTeamsTable } from "./ReportTeamsTable";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { StatisticTestTeamsRequest } from "Api/Requests/StatisticRequest";
import { reportSettings } from "./TestTeamsWithoutCourse";
import { numWord } from "helpers/numWord";
import axios from "axios";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { flattenTeams } from "models/Team";
import { SingleValueContainer } from "../../SingleValueContainer";

export const TestTeamsWithinCourse = () => {
    const [selectedCourseItems, setSelectedCourseItems] = useState<any[]>([]);
    const [selectedAcceptedCourseItems, setSelectedAcceptedCourseItems] = useState<any[]>([]);
    const [coursesValues, setCoursesValues] = useState<IStatisticValue[]>([]);
    const [showReportTeamsTable, setShowReportTeamsTable] = useState(false);
    const [isModalCourseTreeOpen, setIsModalCourseTreeOpen] = useState(false);
    const [coursesTree, setCoursesTree] = useState<any[]>([]);
    const [isCourseFilterEmpty, setIsCourseFilterEmpty] = useState(true);
    const [selectedCoursesCount, setSelectedCoursesCount] = useState(0);
    const [selectedTestItems, setSelectedTestItems] = useState<any[]>([]);
    const [selectedAcceptedTestItems, setSelectedAcceptedTestItems] = useState<any[]>([]);
    const [isModalTestTreeOpen, setIsModalTestTreeOpen] = useState(false);
    const [testsTree, setTestsTree] = useState<any[]>([]);
    const [testsValues, setTestsValues] = useState<IStatisticValue[]>([]);
    const [isTestFilterEmpty, setIsTestFilterEmpty] = useState(true);
    const [selectedTestsCount, setSelectedTestsCount] = useState(0);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
    const [attempts, setAttempts] = useState(attemptsOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const CancelToken = axios.CancelToken;
    const cancelTokenSource = CancelToken.source();

    //запрашиваем список всех команд
    const { data: teams = [] as TeamTreeAllBasicInfoResponse[] } = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    // all courses request
    const coursesDataQuery = useQuery(
        ["coursesTree"],
        async () => {
            return await Api.Course.List(0, 999, [{ id: "modifyTimestamp", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: courseTreeData } = coursesDataQuery;

    // all tests request
    const testsDataQuery = useQuery(
        ["testsTree", coursesValues],
        async () => {
            return await Api.Test.List(0, 999, [{ id: "modifyTime", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
                "courseId.in": [coursesValues.map(({ id }) => id)],
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: testTreeData } = testsDataQuery;

    const onSubmitCourseSection = () => {
        setSelectedAcceptedCourseItems(selectedCourseItems);
        setIsModalCourseTreeOpen(false);
        setShowReportTeamsTable(false);
    };

    const onSubmitTestSection = () => {
        setSelectedAcceptedTestItems(selectedTestItems);
        setIsModalTestTreeOpen(false);
        setShowReportTeamsTable(false);
    };

    const createReport = () => {
        setShowReportTeamsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestTeamsRequest = {
            courseIds: coursesValues.map(({ id }) => id),
            quizIds: testsValues.map(({ id }) => id),
            teamsIds: selectedTeams,
            attemptNumber: attempts.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateTestTeamsStatisticReportFile(request, cancelTokenSource.token);
        } catch (error: any) {
            console.log(error);
        }
    };

    //записываем id всеx команд в выбранные
    useEffect(() => {
        setSelectedTeams(flattenTeams(teams).map((team) => team.id));
    }, [teams]);

    // Setting courseTree data
    useEffect(() => {
        if (courseTreeData?.Content && selectedAcceptedCourseItems.length === 0) {
            setCoursesTree(
                structurizeCourseTreeData(
                    courseTreeData?.Content,
                    selectedAcceptedCourseItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                            selected: true,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseTreeData]);

    useEffect(() => {
        setSelectedCoursesCount(selectedCourseItems.length);
    }, [selectedCourseItems]);

    useEffect(() => {
        setCoursesTree(
            structurizeCourseTreeData(
                courseTreeData?.Content as unknown as CourseListResponse[],
                selectedAcceptedCourseItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setCoursesValues(
            selectedAcceptedCourseItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setSelectedAcceptedTestItems([]);
        setSelectedTestItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedCourseItems]);

    // Setting testTree data
    useEffect(() => {
        if (testTreeData?.Content && selectedAcceptedTestItems.length === 0) {
            setTestsTree(
                structurizeTestTreeData(
                    testTreeData?.Content,
                    selectedAcceptedTestItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                            selected: true,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testTreeData]);

    useEffect(() => {
        setSelectedTestsCount(selectedTestItems.length);
    }, [selectedTestItems]);

    useEffect(() => {
        setTestsTree(
            structurizeTestTreeData(
                testTreeData?.Content as unknown as TestListResponse[],
                selectedAcceptedTestItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setTestsValues(
            selectedAcceptedTestItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        if (selectedAcceptedTestItems.length === 0) {
            setAttempts(attemptsOptions[0]);
            setSelectedTeams(flattenTeams(teams).map((team) => team.id));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedTestItems]);

    return (
        <>
            <div className="grid items-center gap-6.5 grid-cols-2">
                {/* Выбор курса */}
                <ComboBox
                    placeholder="Выберите курс"
                    onChange={(value) => {
                        setSelectedAcceptedCourseItems([value]);
                        setShowReportTeamsTable(false);
                    }}
                    value={coursesValues[0] ?? null}
                    components={{
                        DropdownIndicator: OptionsDropdownIndicator({
                            onClick: (e) => {
                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                    setIsModalCourseTreeOpen((prevIsOpen) => !prevIsOpen);
                                }
                            },
                        }),

                        ValueContainer: SingleValueContainer,
                    }}
                    onMenuOpen={() => setIsModalCourseTreeOpen((prevIsOpen) => !prevIsOpen)}
                    /*
                    allPlaceholder={
                        ((courseTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                        coursesValues.length === courseTreeData?.Content.length
                    }
                    */
                    isSearchable={false}
                    isCreatable={false}
                    isClearable={false}
                    menuIsOpen={false}
                />
                {/* Выбор теста */}
                {selectedAcceptedCourseItems.length > 0 && (
                    <ComboBox
                        placeholder="Выберите тест"
                        onChange={(value) => {
                            setSelectedAcceptedTestItems([value]);
                            setShowReportTeamsTable(false);
                        }}
                        value={testsValues[0] ?? null}
                        components={{
                            DropdownIndicator: OptionsDropdownIndicator({
                                onClick: (e) => {
                                    if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        setIsModalTestTreeOpen((prevIsOpen) => !prevIsOpen);
                                    }
                                },
                            }),

                            ValueContainer: SingleValueContainer,
                        }}
                        onMenuOpen={() => setIsModalTestTreeOpen((prevIsOpen) => !prevIsOpen)}
                        /*
                            allPlaceholder={
                                ((courseTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                                coursesValues.length === courseTreeData?.Content.length
                            }
                            */
                        isSearchable={false}
                        isCreatable={false}
                        isClearable={false}
                        menuIsOpen={false}
                    />
                )}
            </div>
            {/* Выбор курса - модалка */}
            <TasksModalTree
                isOpen={isModalCourseTreeOpen}
                title="Выбор курса"
                setIsOpen={(open: boolean) => {
                    setSelectedCourseItems(selectedAcceptedCourseItems);
                    setCoursesTree(
                        structurizeCourseTreeData(
                            courseTreeData?.Content as unknown as CourseListResponse[],
                            selectedAcceptedCourseItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalCourseTreeOpen(open);
                    setIsCourseFilterEmpty(true);
                }}
                treeData={coursesTree}
                /*
                checkedChange={(selectedNodes) => {
                    setSelectedCourseItems(selectedNodes);
                }}
                */
                onSelectNode={(node) => setSelectedCourseItems([node])}
                checkable={false}
                selectable={true}
                checkOnNameClick={true}
                showSelectedCounter={false}
                onSubmit={onSubmitCourseSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedCoursesCount}
                disableRootSelection
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setCoursesTree(
                        structurizeCourseTreeData(
                            (courseTreeData?.Content as unknown as CourseListResponse[]).filter(({ state, title }) => {
                                return (
                                    (!query ||
                                        title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                        query.trim() === "") &&
                                    (state === value || value === "ALL")
                                );
                            }),
                            selectedCourseItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );
                    setIsCourseFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={
                    isCourseFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"
                }
            />
            {/* Выбор теста - модалка */}
            <TasksModalTree
                isOpen={isModalTestTreeOpen}
                title="Выбор теста"
                setIsOpen={(open: boolean) => {
                    setSelectedTestItems(selectedAcceptedTestItems);
                    setTestsTree(
                        structurizeTestTreeData(
                            testTreeData?.Content as unknown as TestListResponse[],
                            selectedAcceptedTestItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalTestTreeOpen(open);
                    setIsTestFilterEmpty(true);
                }}
                treeData={testsTree}
                /*
                checkedChange={(selectedNodes) => {
                    setSelectedCourseItems(selectedNodes);
                }}
                */
                onSelectNode={(node) => setSelectedTestItems([node])}
                checkable={false}
                selectable={true}
                checkOnNameClick={true}
                showSelectedCounter={false}
                onSubmit={onSubmitTestSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedTestsCount}
                disableRootSelection
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setTestsTree(
                        structurizeTestTreeData(
                            (testTreeData?.Content as unknown as TestListResponse[]).filter(({ state, title }) => {
                                return (
                                    (!query ||
                                        title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                        query.trim() === "") &&
                                    (state === value || value === "ALL")
                                );
                            }),
                            selectedTestItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );
                    setIsTestFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={isTestFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"}
            />
            {selectedAcceptedTestItems.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            <FormGroup className="!mb-0">
                                <Label>Команды</Label>
                                <TeamMultiSelect
                                    value={selectedTeams}
                                    onChange={(v) => {
                                        setSelectedTeams(v);
                                        setShowReportTeamsTable(false);
                                    }}
                                    placeholder="Выберите команды"
                                    checkable
                                    showTeams
                                    stacked={true}
                                    allPlaceholder={flattenTeams(teams).length === selectedTeams.length}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Собрать статистику</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAttempts(value as { value: StatisticsAttempts; label: string });
                                        setShowReportTeamsTable(false);
                                    }}
                                    value={attempts}
                                    options={attemptsOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mt-9">
                        {showReportTeamsTable ? (
                            <>
                                <ReportTeamsTable
                                    queryPayload={createPayloadParams()}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={selectedTeams.length === 0}
                            >
                                {`Сформировать отчет по ${selectedTeams.length} ${numWord(selectedTeams.length, [
                                    "команде",
                                    "командам",
                                    "командам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
