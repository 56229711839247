import { BaseResponse } from "Api/BaseResponse";
import { Expose, Type } from "class-transformer";
import { ResourceState, ProgressStatus } from "Enums";

export class NextCourse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    title: string;

    @Expose()
    @Type(() => String)
    description: string;

    @Expose()
    @Type(() => String)
    logoId: string;

    @Expose()
    @Type(() => Number)
    public createTime: number;

    @Expose()
    @Type(() => Number)
    public modifyTime: number;

    @Expose()
    @Type(() => Number)
    public deadlineTime: number;

    @Expose()
    @Type(() => String)
    state: ResourceState;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;
}

export class ProgramStartResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    progressStatus: ProgressStatus;

    @Expose()
    @Type(() => NextCourse)
    public nextCourse: NextCourse;
}

export class ProgramProgressResponse {
    @Expose()
    @Type(() => Number)
    public totalCount: number;

    @Expose()
    @Type(() => Number)
    public passedCount: number;

    @Expose()
    @Type(() => Number)
    public timeSpent: number;
}
