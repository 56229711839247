import React from "react";
import { Modal } from "Uikit/Modal/Modal";
import { SecondaryButton } from "Uikit/Button/SecondaryButton";
import { TVoidFunction } from "types";

interface RemoveEmptyRootTeamModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
}

export const RemoveEmptyRootTeamModal = ({ isOpen, onClose }: RemoveEmptyRootTeamModalProps) => {
    return (
        <Modal isOpen={isOpen} title="Удаление команды невозможно" onClose={onClose}>
            <Modal.Body>В дереве команд должна быть как минимум одна корневая команда.</Modal.Body>
            <Modal.Footer>
                <SecondaryButton onClick={onClose} id="removeEmptyRootTeamBtnCancel">
                    Закрыть
                </SecondaryButton>
            </Modal.Footer>
        </Modal>
    );
};
