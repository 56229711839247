import { useEffect } from "react";
import { useCombinedRefs } from "hooks/useCombinedRef";

export const useTreeRef = (...refs: any[]) => {
    const treeRef = useCombinedRefs(refs);

    useEffect(() => {
        let handler: number;
        const tryRef = () => {
            if (refs.length > 0) {
                if (treeRef.current) {
                    refs.forEach((ref) => {
                        if (typeof ref === "function") {
                            ref(treeRef.current);
                        } else if (ref) {
                            ref.current = treeRef.current;
                        }
                    });
                } else {
                    handler = window.setTimeout(tryRef, 100);
                }
            }
        };

        if (refs.length > 0) {
            tryRef();
        }

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [treeRef, refs]);

    return treeRef;
};
