import React, { CSSProperties, ReactNode, useState } from "react";
import clsx from "clsx";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { CardProps } from "Components/Card";
import chunk from "lodash/chunk";
import { TVoidFunction } from "types";

enum Direction {
    LEFT = "left",
    RIGHT = "right",
}

interface CardsBlockProps {
    title?: string;
    subtitle?: string;
    children: ReactNode;
    flippable?: boolean;
    rows?: number;
    onSeeAll?: TVoidFunction;
    id?: string;
    total: number;
}

const ITEMS_PER_ROW = 4;

export const CardsBlock = ({
    title,
    subtitle,
    children,
    flippable = true,
    rows = 1,
    onSeeAll,
    id,
    total,
}: CardsBlockProps) => {
    const childrenArray = React.Children.toArray(children);
    const referenceItemIndex = childrenArray.findIndex((child) => {
        return React.isValidElement<ReferenceItemProps>(child) && child.props?.__TYPE === REFERENCE_TYPE;
    });
    const referenceElement = referenceItemIndex >= 0 ? childrenArray[referenceItemIndex] : null;
    const cardElements =
        referenceItemIndex >= 0 ? childrenArray.filter((_, index) => index !== referenceItemIndex) : childrenArray;

    const childrenCount = total;
    const flipEnabled = flippable && childrenCount > ITEMS_PER_ROW;
    const [direction, setDirection] = useState<Direction | null>(Direction.RIGHT);

    const [translateClass, setTranslateClass] = useState("");

    const handleFlip = () => {
        const className =
            direction === Direction.RIGHT
                ? `sm:-translate-x-[1200px] 2xl:-translate-x-[1496px] transition-transform duration-1000`
                : "translate-x-0 transition-transform duration-1000";

        setTranslateClass(className);
        setDirection(direction === Direction.RIGHT ? Direction.LEFT : Direction.RIGHT);
    };

    const chunkedChildren = rows > 1 ? chunk(cardElements, ITEMS_PER_ROW) : [cardElements];

    return (
        <div className="space-y-8 2xl:space-y-9" id={id}>
            <div className="px-3 sm:px-0 -mb-3 flex items-center">
                {title && (
                    <h3 className="block sm:hidden" id={id + "Title"}>
                        {title}
                    </h3>
                )}
                {title && (
                    <h2
                        className="hidden sm:block 2xl:!text-2xl 2xl:!leading-[35px] sm:mr-5 2xl:mr-6.25 light"
                        id={id + "Title"}
                    >
                        {title}
                    </h2>
                )}
                {onSeeAll && total > 1 && (
                    <div className="block sm:hidden ml-auto w-11 p3 text-primary cursor-pointer" onClick={onSeeAll}>
                        Все&nbsp;{total}
                    </div>
                )}
                {subtitle && <div className="hidden sm:block p4 2xl:text-sm text-disabled-stroke">{subtitle}</div>}
                {onSeeAll && cardElements.length > ITEMS_PER_ROW && (
                    <div
                        className="hidden sm:block grow text-end text-gray sm:text-xs 2xl:text-base cursor-pointer"
                        onClick={onSeeAll}
                    >
                        Посмотреть все
                    </div>
                )}
            </div>
            {chunkedChildren.map((childrenChunk, chunkIndex) => {
                if (chunkIndex + 1 > rows) {
                    return null;
                }

                return (
                    <div id={id + "Cards" + chunkIndex} key={chunkIndex} className="relative">
                        <div className="scrollbar-hidden overflow-auto sm:overflow-hidden">
                            <div
                                className={clsx(
                                    "relative flex space-x-3 px-3 sm:space-x-6.5 2xl:space-x-8 sm:px-0",
                                    translateClass,
                                )}
                            >
                                {childrenChunk.map((child, index) => {
                                    if (!React.isValidElement<CardProps>(child)) {
                                        return child;
                                    }

                                    let Item: React.ReactElement = child;
                                    const itemNumber = (chunkIndex + 1) * (index + 1);
                                    const isLast = Math.max(2, rows) * ITEMS_PER_ROW === itemNumber;
                                    if (isLast && childrenCount > itemNumber) {
                                        Item = (referenceElement as React.ReactElement) ?? child;
                                    }

                                    return (
                                        <div key={index} className="relative">
                                            {React.cloneElement(Item)}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {flipEnabled && (
                            <Control
                                direction={direction!}
                                onClick={handleFlip}
                                className="hidden sm:flex !m-0 absolute"
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

interface ControlProps {
    direction: "left" | "right";
    className?: string;
    onClick: TVoidFunction;
}

const Control = ({ direction, className, onClick }: ControlProps) => {
    const style: CSSProperties = {};

    if (direction === "right") {
        style.right = -15;
    }

    if (direction === "left") {
        style.left = -15;
    }

    return (
        <div
            className={clsx(
                "flex items-center justify-center sm:w-10 2xl:w-12.5 sm:h-10 2xl:h-12.5 sm:top-21 2xl:top-[110px] rounded-full bg-white cursor-pointer",
                className,
            )}
            onClick={onClick}
            style={{ filter: "drop-shadow(0px 4px 10px rgba(38, 38, 38, 0.1))", ...style }}
        >
            <Icon
                icon={direction === "left" ? Icons.ChevronLeft : Icons.ChevronRight}
                width={16}
                height={16}
                color="fill-blue-dark"
                className="2xl:!w-5 2xl:!h-5"
            />
        </div>
    );
};

interface ReferenceItemProps {
    __TYPE?: string;
    onClick: TVoidFunction;
    subtitle?: string;
}

const REFERENCE_TYPE = "CardsBlock.ReferenceItem";
