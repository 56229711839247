import React from "react";
import { Toggle, Button, Icon, Icons, Tooltip } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { MaterialType } from "Enums";
import { breadCrumbsMaterialType } from "../MaterialForm";
import { TVoidFunction } from "types";

interface MaterialHeaderProps {
    materialId?: string;
    isFormDirty?: boolean;
    title?: string;
    type: MaterialType | undefined;
    isActive: boolean;
    onMaterialStatusChange: (isActive: boolean) => void;
    onCancelChange: TVoidFunction;
    onClose: TVoidFunction;
    onSubmitHandler: TVoidFunction;
    isSubmitDisabled?: boolean;
}

export const MaterialHeader = ({
    materialId,
    isFormDirty,
    title,
    type,
    isActive,
    onCancelChange,
    onClose,
    onMaterialStatusChange,
    onSubmitHandler,
    isSubmitDisabled = false,
}: MaterialHeaderProps) => {
    const materialType: { [key: string]: string } = {
        VIDEO: "Видео",
        DOCUMENT: "Документ",
        ARTICLE: "Статья",
        SCORM: "SCORM",
        HTML: "HTML5",
    };

    const onMaterialStatusChangeHandler = (isActive: boolean) => {
        onMaterialStatusChange(isActive);
    };

    return (
        <div className="flex items-center gap-4">
            <div className="flex flex-grow items-center gap-4">
                <h1 className="break-all" id="adminNewMaterialTitle">
                    <MultiClumpTooltip
                        label={!title ? (type ? breadCrumbsMaterialType[type] : "") : title}
                        clamp={1}
                        textClassName="!leading-8"
                    ></MultiClumpTooltip>
                </h1>
                <div className="text-xs text-gray-text border rounded-md border-gray-light px-2.5 py-1 mr-2.5">
                    {type ? materialType[type] : null}
                </div>
            </div>
            <div className="flex space-x-4">
                <Toggle
                    enabled={isActive}
                    onChange={onMaterialStatusChangeHandler}
                    label={isActive ? "Активен" : "Скрыт"}
                    className="mr-4 min-w-[115px]"
                    id="adminNewMaterialToggleIsActive"
                />

                {isFormDirty || (!isFormDirty && !materialId) ? (
                    <>
                        <Button
                            className="mr-4"
                            variant="outline"
                            color="secondary"
                            onClick={onCancelChange}
                            id="adminNewMaterialBtnCancel"
                        >
                            Отменить
                        </Button>
                        <Tooltip content="Дождитесь завершения загрузки файла" disabled={!isSubmitDisabled}>
                            <Button
                                className="mr-4"
                                onClick={onSubmitHandler}
                                id="adminNewMaterialBtnOk"
                                disabled={isSubmitDisabled}
                            >
                                Сохранить
                            </Button>
                        </Tooltip>
                    </>
                ) : (
                    <Button
                        className="w-10 h-10 !p-0"
                        variant="outline"
                        color="secondary"
                        onClick={onClose}
                        id="adminNewMaterialBtnCrest"
                        shape="square"
                    >
                        <Icon icon={Icons.Close} width={24} height={24} color="fill-[#939393]" />
                    </Button>
                )}
            </div>
        </div>
    );
};
