import React from "react";

interface ProgressBarProps {
    value: number;
}

export const ProgressBar = ({ value = 0 }: ProgressBarProps) => {
    const progress = React.useMemo(() => value, [value]);

    return (
        <div className="flex w-full items-center">
            <div className="w-full h-2 bg-gray-progress rounded-25 mr-3">
                <div
                    className="bg-blue rounded-25 h-2"
                    style={{
                        width: (progress || 0) + "%",
                    }}
                ></div>
            </div>
            {progress ? <div className="shrink-0">{Math.ceil(progress).toFixed(0)} %</div> : null}
        </div>
    );
};
