import BaseApi from "../BaseApi";
import { MainPageResponse, SystemPropertyResponse } from "Api/Responses/CommonsResponses";
import { BaseResponse } from "../BaseResponse";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "../Requests/CommonsRequest";

export default class CommonsApi extends BaseApi {
    getHomePageData = (): Promise<MainPageResponse> => {
        return this.Get(new MainPageResponse(), "/ui/main-page");
    };

    addFavorites = async (data: CommonsAddFavoritesRequest): Promise<BaseResponse> => {
        return this.Post(BaseResponse, "/favorites/add", data);
    };

    removeFavorites = async (data: CommonsRemoveFavoritesRequest): Promise<BaseResponse> => {
        return this.Post(BaseResponse, "/favorites/remove", data);
    };

    getSystemProperties = async (): Promise<SystemPropertyResponse[]> => {
        return this.Get([], "/system-properties");
    };
}
