import BaseApi from "../BaseApi";
import { BasePaginationResponse, BaseResponse } from "Api/BaseResponse";
import { NotificationListResponse } from "Api/Responses/NotificationResponse";

export default class NotificationApi extends BaseApi {
    Count = async (): Promise<string> => {
        return await this.Get("", "/notifications/unread/count");
    };
    List = async (page: number, size: number): Promise<BasePaginationResponse<NotificationListResponse>> => {
        return await this.Get(
            new BasePaginationResponse(NotificationListResponse),
            `/notifications?page=${page}&size=${size}`,
        );
    };
    Read = async (id: string): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, `/notifications/${id}/read`);
    };
    ReadAll = async (): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, "/notifications/all/read");
    };
}
