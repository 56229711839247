import BaseApi from "../BaseApi";
import { BaseObjectToArrayResponse } from "../BaseResponse";

export enum LogoSize {
    ORIGINAL = "ORIGINAL",
    THUMB_MINI = "THUMB_MINI",
    THUMB_MICRO = "THUMB_MICRO",
}

export default class UploadApi extends BaseApi {
    Download = async (id: string): Promise<""> => {
        return await this.Get("", `/file/download/${id}`);
    };

    GetLogo = (id: string, size: LogoSize): string => {
        return `/service/lms-upload/api/logo/get/${id}/${size}`;
    };

    GetDefaultLogos = async (): Promise<BaseObjectToArrayResponse<string>> => {
        return await this.Get(new BaseObjectToArrayResponse(), `/default/logos`);
    };
}
