import React, { ReactNode } from "react";
import isSameDay from "date-fns/isSameDay";
import isTomorrow from "date-fns/isTomorrow";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";
import clsx from "clsx";

interface ITaskCardTimeProps {
    children: ReactNode;
    time?: number | null;
    className?: string;
}

const today = new Date();
const getBgClass = (time: number | null) => {
    let bgClass = "bg-black-50";

    if (time) {
        const date = new Date(time * 1000);
        const hoursToDate = differenceInHours(date, today);
        if ([0, 1].indexOf(differenceInDays(date, today)) !== -1) {
            bgClass = "bg-red";
        } else if (isSameDay(today, date) || isTomorrow(date) || (!isTomorrow(date) && hoursToDate < 47)) {
            bgClass = "bg-red";
        } else if (48 <= hoursToDate && hoursToDate < 168) {
            bgClass = "bg-primary";
        }
    }

    return bgClass;
};

export const TaskCardTime = ({ children, time = null, className = "" }: ITaskCardTimeProps) => {
    const bgClass = getBgClass(time);

    return (
        <div
            className={clsx(
                "rounded-md 2xl:rounded-lg text-white flex items-center gap-1 2xl:gap-1.25 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 h-5.25 2xl:h-6.5 2xl:text-2sm",
                bgClass,
                className,
            )}
        >
            {children}
        </div>
    );
};
