import React from "react";
import { Button, Modal } from "Uikit";

interface RemoveAccessMaterialModalProps {
    className?: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    materialTitle: string;
    id: string;
}

export const RemoveAccessMaterialModal = ({
    className,
    isOpen,
    setIsOpen,
    materialTitle,
    id,
}: RemoveAccessMaterialModalProps) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={"Изменения не сохранены"} className={className} id={id}>
            <Modal.Body>
                <div className="text-sm small leading-6 text-[#9B9B9B] mr-auto">{materialTitle}</div>

                <div className="mb-6 mt-3">
                    <p className="text-sm text-[#262626]-500">
                        Найдены несохраненные изменения, вы уверены, что хотите закрыть? Все изменения будут сброшены
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    key={0}
                    onClick={() => setIsOpen(false)}
                    variant="outline"
                    size={"small"}
                    color={"secondary"}
                    className={"border-[#EAEDF3] "}
                >
                    Отмена
                </Button>
                <Button key={1} onClick={() => setIsOpen(false)} size={"small"} color="danger">
                    Забрать доступ
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
