import React from "react";
import { TextareaAutoSizable } from "Uikit";
import { testingRegexp } from "Uikit/Forms/Input";

interface ITaskQuestionTextProps {
    value: string;
    name?: string;
    onChange?: (e: React.ChangeEvent<any>) => void;
    error?: string;
    placeholder?: string;
    available?: boolean;
}

export const TaskQuestionText = ({
    value,
    onChange,
    error,
    name = "",
    placeholder = "Введите ответ",
    available = true,
}: ITaskQuestionTextProps) => {
    return (
        <TextareaAutoSizable
            name={name}
            error={error}
            maxLength={1024}
            cutRegExp={testingRegexp}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className={`!bg-white border-0 resize-none ${!available ? "pointer-events-none" : ""}`}
        />
    );
};
