import React from "react";
import { Breadcrumbs, TabsWrapper, Tabs, Content } from "Uikit";
import { LicenseGeneral } from "./LicenseGeneral";
import { LicenseMembers } from "./LicenseMembers";

export const License = () => {
    return (
        <div className="flex flex-col px-4 pb-4 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-2.5" id="adminSettingsBreadcrumbsAchievements">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Настройки" />
                <Breadcrumbs.Link title="Лицензии" />
            </Breadcrumbs>
            <TabsWrapper className="flex flex-col h-fit grow">
                <TabsWrapper.Tabs classname="h-max space-x-4 relative" id="adminSettingsTabsAchievements">
                    <Tabs.Tab title="Общее" />
                    <Tabs.Tab title="Участники" />
                </TabsWrapper.Tabs>
                <TabsWrapper.Content className="flex flex-col !h-fit grow" panelClassName={"!h-fit flex flex-col grow"}>
                    <Content.Body className="mt-6 h-fit grow">
                        <LicenseGeneral />
                    </Content.Body>
                    <Content.Body className="mt-6 h-fit">
                        <LicenseMembers id="adminMembersActive" />
                    </Content.Body>
                </TabsWrapper.Content>
            </TabsWrapper>
        </div>
    );
};
