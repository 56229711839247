import React from "react";
import "react-day-picker/dist/style.css";
import { Icon, Icons, Tooltip } from "Uikit";

interface IInfoCardSupAdminContentProps {
    actual: number | string;
    summ?: number | string;
    available: number | string;
    tooltip?: string;
}

export const InfoCardSupAdminContent = ({
    actual,
    summ,
    available,
    tooltip = "Доступно",
}: IInfoCardSupAdminContentProps) => {
    return (
        <p className="inline-flex gap-1">
            <span>{actual}</span>
            {summ && <span className="text-gray">{summ}</span>}
            <span className="text-primary">({available})</span>
            <Tooltip placement="top" content={tooltip}>
                <Icon icon={Icons.Question} color="fill-gray-light" width={14} height={14} className="cursor-pointer" />
            </Tooltip>
        </p>
    );
};
