import React from "react";
import { ValueContainerProps, components } from "react-select";
import { ClearAllButton } from "./ClearAllButton";

interface ISelectValueContainerProps {
    onClick?: (e: any) => void;
    allPlaceholder?: boolean;
    clearAllButton?: boolean;
}

// Кастомный компонент для react-select
// Контейнер для выбираемого значения в селекте
export const SelectValueContainer =
    ({ onClick, allPlaceholder = false, clearAllButton = true }: ISelectValueContainerProps) =>
    ({ children, ...props }: ValueContainerProps<any>) => {
        const itemsLength = props.getValue().length;

        const clearAll = () => {
            props.clearValue();
        };

        return (
            <div
                onClick={(e) => {
                    onClick?.(e);
                }}
                style={{ width: "calc(100% - 34px)" }}
            >
                <components.ValueContainer
                    {...props}
                    className={`gap-1 !pr-7 relative ${
                        (props?.selectProps as any).defaultAdditional?.isInputFocused && props?.selectProps.menuIsOpen
                            ? " !pl-4.5 ui-combo-box__value-container--focused"
                            : ""
                    }`}
                >
                    {allPlaceholder ? (
                        <div className="pl-3.5">
                            <span>Все</span>
                        </div>
                    ) : (
                        <>{children}</>
                    )}
                    {clearAllButton && itemsLength > 0 && !allPlaceholder && (
                        <ClearAllButton onClick={clearAll} itemsLength={itemsLength} />
                    )}
                </components.ValueContainer>
            </div>
        );
    };
