import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "Components/Header/Header";

interface IMenuProps {
    isInterfaceElementsHidden?: boolean;
    children: ReactNode;
}

export const Wrapper = ({ children, isInterfaceElementsHidden = false }: IMenuProps) => {
    const location = useLocation();
    const isSideMenu =
        (location.pathname.indexOf("/admin/settings") !== -1 &&
            (location.pathname.indexOf("/admin/settings/achievements") !== -1 ||
                location.pathname.indexOf("/admin/settings/achievement") === -1)) ||
        location.pathname.indexOf("/admin/statistics") !== -1;

    return (
        <div className="relative flex flex-col justify-start w-full h-full overflow-hidden">
            {!isInterfaceElementsHidden && <Header sideMenu={isSideMenu} />}
            <div className={`grow max-h-full min-h-0 ${!isSideMenu ? "overflow-y-scroll" : ""} custom-scrollbar`}>
                {children}
            </div>
            <div className="block sm:hidden min-h-[56px] w-full"></div>
        </div>
    );
};
