import React, { useState } from "react";
import { Button } from "../Button/Button";
import clsx from "clsx";
import { Input } from "../Forms/Input";

interface PaginationProps {
    total: number;
    count: number;
    offset: number;
    onPaginate: (page: number, count: number) => void;
}

export const Pagination = ({ total, count, offset, onPaginate }: PaginationProps) => {
    const [goToPageIndex, setGoToPageIndex] = useState<string>("1");

    const paginate = (page: any, count: number) => {
        onPaginate(page, count);
    };

    let buttonsIndex = 0;
    let buttonsCount = Math.ceil(total / count);

    if (Math.ceil(total / count) > 6) {
        buttonsIndex = offset / count > 3 ? offset / count - 3 : 0;
        buttonsIndex = buttonsIndex + 2 >= total / count - 2 ? total / count - 6 : buttonsIndex;
        buttonsCount = total / count >= 6 ? buttonsIndex + 6 : total / count - 1;
    }

    const buttons = [];

    for (let i = Math.ceil(buttonsIndex); i < buttonsCount; i++) {
        buttons.push({ id: i, label: i + 1 });
    }

    if (buttons.length === 0) {
        buttons.push({ id: 0, label: 1 });
    }

    return (
        <div className="flex items-center">
            <div className="flex flex-grow items-center">
                {total > 45 && (
                    <Button
                        className="border rounded-lg !p-4 !bg-[#F4FAFD] !text-[#1380CE] font-medium mr-2 2xl:mr-2.5"
                        onClick={() => paginate(0, count)}
                    >
                        В начало
                    </Button>
                )}

                <div className="space-x-2 2xl:space-x-2.5">
                    {buttons.map((item) => {
                        return (
                            <Button
                                color="common"
                                key={item.id}
                                className={clsx(
                                    offset / count === item.id ? "bg-primary" : "!text-black",
                                    "font-normal rounded-lg !p-3 !text-sm w-10 2xl:w-12.5 2xl:!text-md",
                                )}
                                onClick={() => paginate(item.id * count, count)}
                            >
                                {item.label}
                            </Button>
                        );
                    })}
                </div>

                {buttonsCount < total / count ? (
                    <div className="flex space-x-2 2xl:space-x-2.5 ml-2 2xl:ml-2.5">
                        <Button color="common" className="!inline-block !align-text-bottom !text-black !px-0.5">
                            ...
                        </Button>

                        <Button
                            className="rounded !text-black !p-3 font-normal !text-sm w-10 2xl:w-12.5 2xl:!text-md"
                            onClick={() => paginate(total, count)}
                            color="common"
                        >
                            {Math.ceil(total / count)}
                        </Button>
                    </div>
                ) : null}
            </div>

            <div className="flex items-center space-x-2 2xl:space-x-2.5">
                <div className="whitespace-nowrap px-3.5 py-2.5 2xl:py-3 2xl:px-4.5 2xl:text-md">
                    Перейти на страницу
                </div>
                <Input
                    cutRegExp={new RegExp(/\D/gi)}
                    value={goToPageIndex}
                    onChange={(e) => {
                        setGoToPageIndex(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (e.key !== "Enter") {
                            return;
                        }

                        paginate(
                            (Number(goToPageIndex) > Math.ceil(total / count)
                                ? Math.ceil(total / count) - 1
                                : Number(goToPageIndex) - 1) * count,
                            count,
                        );
                    }}
                    className="border p-1 rounded !w-16.75 2xl:!w-21 h-10"
                />

                <Button
                    className=""
                    color="gray"
                    onClick={() => {
                        paginate(
                            (Number(goToPageIndex) > Math.ceil(total / count)
                                ? Math.ceil(total / count) - 1
                                : Number(goToPageIndex) - 1) * count,
                            count,
                        );
                    }}
                >
                    Перейти
                </Button>
            </div>
        </div>
    );
};
