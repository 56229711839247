import BaseApi from "../BaseApi";
import { BaseArrayResponse, BasePaginationResponse, BaseResponse } from "../BaseResponse";
import { JobResponse } from "../Responses/JobResponse";
import { JobsRequest } from "../Requests/JobsRequest";
import { ID } from "types/ID";
import { SortingState } from "@tanstack/react-table";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class JobsApi extends BaseApi {
    GetJobs = (): Promise<BaseArrayResponse<JobResponse>> => {
        return this.Get(new BaseArrayResponse(JobResponse), "/jobs?page=0&size=500");
    };

    GetJobsPaginated = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<JobResponse>> => {
        const params = LMSFilterParams({ filter, sort, size, page });
        return this.Get(new BasePaginationResponse(JobResponse), `/jobs?${params.toString()}`);
    };

    Create = (data: JobsRequest): Promise<BaseResponse> => {
        return this.Post(BaseResponse, `/jobs`, data);
    };

    Update = (data: JobsRequest): Promise<BaseResponse> => {
        return this.Put(BaseResponse, `/jobs`, data);
    };

    Remove = (id: ID): Promise<BaseResponse> => {
        return this.Delete(BaseResponse, `/jobs/${id}`);
    };
}
