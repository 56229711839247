import React, { useState } from "react";
import { Breadcrumbs, Button, Icon, Icons, Textarea } from "Uikit";
import { RemarkListResponse } from "Api/Responses/VerificationResponse";
import { useLocation } from "react-router-dom";
import { SectionsWrapper } from "Uikit/Forms/SectionsWrapper";
import { Section } from "Uikit/Forms/Section";
import { ValidationStatus } from "Enums";

export const RemarkPage = () => {
    const { state } = useLocation();
    const data = state as RemarkListResponse;

    const [commentHeight, setCommentHeight] = useState(1);

    function getValidationStatusLabel(status: ValidationStatus) {
        switch (status) {
            case ValidationStatus.Approved:
                return "Проблем не найдено";
            case ValidationStatus.Fixed:
                return "Исправлено";
            default:
                return "Отклонено";
        }
    }

    function handleCommentSize(event: any) {
        const height = event.target.scrollHeight;
        const rowHeight = 17;
        const trows = Math.ceil(height / rowHeight) - 1;

        if (trows !== commentHeight) {
            setCommentHeight(trows);
        }
    }

    return (
        <div className="w-full h-full px-6 relative">
            <Breadcrumbs className="mb-6">
                <Breadcrumbs.Link title={"Администратор"} url={"/admin"} />
                <Breadcrumbs.Link title={"Проверка"} url={"/admin/validations"} />
                <Breadcrumbs.Link title={"Замечания"} url={"/admin/validations"} />
                <Breadcrumbs.Link title={data.Title} url={"/admin/validations/remark/" + data.Id} />
            </Breadcrumbs>

            <div className="flex">
                <div className="flex-grow">
                    <h2>{data.Title}</h2>
                </div>

                <div className="flex space-x-4">
                    {data.Status === ValidationStatus.In_Progress ? (
                        <>
                            <Button
                                key={0}
                                // onClick={() => }
                                size={"medium"}
                                color={"danger"}
                                className={"border-[#EAEDF3] !text-base !font-medium whitespace-nowrap"}
                            >
                                Проблем не найдено
                            </Button>

                            <Button
                                key={0}
                                // onClick={() => }
                                size="medium"
                                color="primary"
                                className="border-[#EAEDF3] !text-base !font-medium whitespace-nowrap"
                            >
                                Исправлено
                            </Button>

                            <Button
                                key={0}
                                // onClick={() => }
                                size="medium"
                                variant="outline"
                                color="common"
                                className="border-[#EAEDF3] !text-base !font-medium whitespace-nowrap"
                            >
                                Пропустить
                            </Button>
                        </>
                    ) : (
                        <>
                            <span className="my-auto">{getValidationStatusLabel(data.Status)}</span>
                            <Button
                                key={0}
                                // onClick={() => }
                                size="medium"
                                color="primary"
                                className="border-[#EAEDF3] !text-base !font-medium whitespace-nowrap"
                            >
                                Вернуть на проверку
                            </Button>
                        </>
                    )}

                    <Button
                        shape="square"
                        color="common"
                        variant="outline"
                        icon={<Icon icon={Icons.Close} width="12px" height="12px" />}
                        iconPlacement="center"
                        className="border-[#EAEDF3] !text-base !font-medium"
                        // onClick={this.editRow({value})}
                    />
                </div>
            </div>

            <SectionsWrapper
                firstColumnTitle="Тема замечания"
                secondColumnTitle="Описание проблемы"
                columnProportion="40/50"
                className="mt-8 mb-16 !h-fit"
            >
                <Section label={data.Title}>
                    <div className="bg-[#EAEDF3] rounded-lg px-4 py-2">
                        <p>{data.Comment}</p>
                    </div>
                </Section>
            </SectionsWrapper>

            {data.Status === ValidationStatus.In_Progress ? null : (
                <div className="flex  w-full bottom-1">
                    <div className="flex flex-grow h-10 w-3/6 space-x-2">
                        <img className="rounded-full" width={"40px"} height={"10px"} src={data.Supervisor.Image} />
                        <div className="my-auto">
                            <span className="whitespace-nowrap my-auto">
                                {data.Supervisor.FirstName + " " + data.Supervisor.LastName}
                            </span>
                        </div>
                    </div>
                    <div className="flex w-full ml-12">
                        {data.Comment ? (
                            <Textarea
                                name="Comment"
                                value={data.Comment}
                                onClick={handleCommentSize}
                                className="w-full bg-[#EAEDF3] ml-20 mr-36 !text-black"
                                rows={commentHeight}
                            />
                        ) : (
                            <span className="w-full ml-1">Проверяющий не оставил комментарий</span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
