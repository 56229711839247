import React from "react";
import { SideMenu } from "Uikit";
import { SettingsTeams } from "./Group/Teams/SettingsTeams";
import { SettingsMembers } from "./Group/Members/SettingsMembers";
import { Achievements } from "./Group/Achievements/Achievements";
import { SettingsRating } from "./Group/Rating/SettingsRating";
import { SettingsSystem } from "./Group/System/SettingsSystem";
import { License } from "./Group/License/License";
import { NewCompany } from "./Group/License/NewCompany";
import { useCurrentUser } from "hooks/useCurrentUser";

export const Settings = () => {
    const { data: user } = useCurrentUser();

    return (
        <div className="h-full">
            <SideMenu
                id="adminSettings"
                description="Внимание! Настройки в данном разделе влияют на работу всей системы"
            >
                <SideMenu.Item title="Команды" path="teams" content={<SettingsTeams />} />
                <SideMenu.Item title="Участники" path="users" content={<SettingsMembers />} />
                <SideMenu.Item title="Ачивки" path="achievements" content={<Achievements />} />
                <SideMenu.Item title="Рейтинг" path="rating" content={<SettingsRating />} />
                {user?.role === "ROOT" && <SideMenu.Item title="Система" path="system" content={<SettingsSystem />} />}
                <SideMenu.Item title="Лицензии" path="licences" content={<License />} />
                <SideMenu.Item title="Лицензии" path="licences/create" content={<NewCompany />} className="hidden" />
            </SideMenu>
        </div>
    );
};
