import { useEffect, useState } from "react";
import { TScreenSize } from "types/Screen";

export const MEDIA_QUERY_SM = "(min-width: 640px)";
export const MEDIA_QUERY_2XL = "(min-width: 1600px)";

export const useMediaQuery = (query: string): boolean => {
    const getMatches = (query: string): boolean => {
        // Prevents SSR issues
        if (typeof window !== "undefined") {
            return window.matchMedia(query).matches;
        }
        return false;
    };

    const [matches, setMatches] = useState<boolean>(getMatches(query));

    function handleChange() {
        setMatches(getMatches(query));
    }

    useEffect(() => {
        const matchMedia = window.matchMedia(query);

        // Triggered at the first client-side load and if query changes
        handleChange();

        // Listen matchMedia
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        } else {
            matchMedia.addEventListener("change", handleChange);
        }

        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            } else {
                matchMedia.removeEventListener("change", handleChange);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return matches;
};

export const useScreenSize = () => {
    const matches2XL = useMediaQuery(MEDIA_QUERY_2XL);
    const matchesSM = useMediaQuery(MEDIA_QUERY_SM);
    const size: TScreenSize = matches2XL ? "large" : matchesSM ? "medium" : "small";

    return { size };
};
