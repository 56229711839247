import React, { useState } from "react";
import { Button, Modal, SubmitButton } from "Uikit";
import { UsersTable } from "./UsersTable";
import { Except } from "type-fest";
import { UserListResponse } from "Api/Responses/UserResponse";
import { TVoidFunction } from "types";

interface UsersModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    // onUsersLoaded: (users: UserListResponse[]) => void;
    onSubmit: (users: UserListResponse[]) => void;
    allUsers: UserListResponse[];
    selectedUsersDefault: UserListResponse[];
}

export const UsersModal = ({ isOpen, onClose, /* onUsersLoaded,  */ ...rest }: UsersModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Выбор участников"
            className="!w-142 !max-w-142 !overflow-visible"
        >
            <ModalContent onClose={onClose} /* onUsersLoaded={onUsersLoaded} */ {...rest} />
        </Modal>
    );
};

const ModalContent = ({
    onClose,
    // onUsersLoaded,
    onSubmit,
    allUsers,
    selectedUsersDefault,
}: Except<UsersModalProps, "isOpen">) => {
    const [selectedUsers, setSelectedUsers] = useState<UserListResponse[]>(selectedUsersDefault);

    const handleSubmit = async () => {
        onSubmit(selectedUsers);
        onClose();
    };

    const handleUsersChanged = (selected: UserListResponse[]) => {
        setSelectedUsers(selected);
    };

    return (
        <>
            <Modal.Body>
                <UsersTable
                    onChange={handleUsersChanged}
                    selectedUsers={selectedUsers}
                    // onUsersLoaded={onUsersLoaded}
                    allUsers={allUsers}
                />
            </Modal.Body>

            <Modal.Footer>
                <div className="grow self-center text-gray">Выбрано {selectedUsers.length}</div>
                <Button
                    key={1}
                    onClick={onClose}
                    variant="outline"
                    size={"medium"}
                    color={"secondary"}
                    className="border-[#EAEDF3]"
                >
                    Отмена
                </Button>
                <SubmitButton key={2} onClick={handleSubmit} disabled={selectedUsers.length === 0}>
                    Выбрать
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};
