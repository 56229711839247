import React, { useEffect, useState } from "react";
import { Checkbox } from "Uikit";

export const CheckboxField = ({ label, accessor, value, onChange }: any) => {
    const [checked, setChecked] = useState(value || false);

    useEffect(() => {
        setChecked(value === undefined ? false : value);
    }, [value]);
    useEffect(() => {
        onChange(accessor, checked ? true : undefined);
    }, [accessor, checked, onChange]);

    return (
        <Checkbox
            className="mt-3 sm:mt-4 !w-full"
            name="additional"
            value="hidden"
            label={label}
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
        />
    );
};
