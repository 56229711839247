import { useState, useEffect } from "react";

export function useDebounceValue(value: string, delay: number): [string, ReturnType<typeof setTimeout> | undefined] {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const [cancel, setCancel] = useState<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        setCancel(handler);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return [debouncedValue, cancel];
}
