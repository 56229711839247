import React, { MutableRefObject } from "react";
import { ITreeProps } from "Uikit";
import { SidebarApi } from "Uikit/SidebarList/SidebarApi";

interface ITreeWrapperContext {
    sidebarApi?: MutableRefObject<SidebarApi>;
    treeRef?: any;
    setTreeProps?: React.Dispatch<React.SetStateAction<ITreeProps | undefined>>;
}
export const TreeWrapperContext = React.createContext<ITreeWrapperContext>({});
