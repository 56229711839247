import React from "react";
import { Except } from "type-fest";
import { Modal } from "Uikit/Modal/Modal";
import { Button } from "Uikit/Button/Button";
import { SubmitButton } from "Uikit/Button/SubmitButton";
import { TVoidFunction } from "types";

interface ScormWithAttemptsModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    onSuccess: TVoidFunction;
    isLastComponent: boolean | undefined;
}

export const ScormWithAttemptsModal = ({ isOpen, onClose, isLastComponent, ...rest }: ScormWithAttemptsModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isLastComponent ? "Завершить курс" : "Следующий материал"}
            className="!w-142 !max-w-142"
        >
            <ModalContent onClose={onClose} isLastComponent={isLastComponent} {...rest} />
        </Modal>
    );
};

const ModalContent = ({ onClose, onSuccess }: Except<ScormWithAttemptsModalProps, "isOpen">) => {
    const handleSubmit = async () => {
        onSuccess();
        onClose();
    };

    return (
        <>
            <Modal.Body>
                <p>
                    В материале остались попытки прохождения теста в SCORM. Они будут списаны при переходе в следующий
                    материал. Продолжить?
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    key={1}
                    onClick={onClose}
                    variant="outline"
                    size={"medium"}
                    color={"secondary"}
                    className="border-[#EAEDF3]"
                >
                    Отмена
                </Button>
                <SubmitButton key={2} onClick={handleSubmit}>
                    Продолжить
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};
