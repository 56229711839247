import React, { ReactNode } from "react";

interface IInfoCardListProps {
    title: string;
    children: ReactNode;
}

export const InfoCardList = ({ title, children }: IInfoCardListProps) => {
    return (
        <div className="max-w-[700px]">
            <h2 className="mb-5">{title}</h2>
            <div className="flex gap-5 flex-wrap">{children}</div>
        </div>
    );
};
