import React from "react";
import { StarRating } from "Uikit/Rating/StarRating";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { formatCheckTime } from "helpers/dateHelper";
import { FeedbackListResponse } from "Api/Responses/FeedbackResponse";

interface IFeedbackListProps {
    data?: FeedbackListResponse[];
    title?: string;
}

export const FeedbackList = ({ data = [], title = "Отзывы о программе обучения" }: IFeedbackListProps) => {
    if (data.length === 0) {
        return null;
    }

    return (
        <>
            {data && (
                <div className="mt-12 2xl:mt-15 flex flex-col gap-6 2xl:gap-8">
                    <h2 className="2xl:!text-2xl 2xl:!leading-[35px]">{title}</h2>
                    <div>
                        {data.map(
                            ({
                                id,
                                publicationDate,
                                rating,
                                comment,
                                user: { avatarId, firstName, lastName, defaultAvatarColor },
                            }) => {
                                const notLast = "[&:not(:last-child)]";
                                return (
                                    <div
                                        key={id}
                                        id={`comment__${id}`}
                                        className={`flex items-start py-6 2xl:py-7.5 first:pt-0 ${notLast}:border-solid ${notLast}:border-b-[1px] ${notLast}:border-gray-blue`}
                                    >
                                        <div className="flex gap-2.5 2xl:gap-3 items-center pr-6 grow max-w-75">
                                            <UserAvatar
                                                avatarId={avatarId}
                                                color={defaultAvatarColor!}
                                                userInitials={`${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`}
                                                size={window.screen.width >= 1600 ? 45 : 36}
                                            />
                                            <div className="flex flex-col gap-1 2xl:gap-1.25">
                                                <p className="p2 2xl:p1 font-normal">
                                                    {firstName} {lastName}
                                                </p>
                                                <p className="p4 2xl:p3 text-gray">
                                                    {formatCheckTime(publicationDate, {
                                                        showTime: true,
                                                        delimiter: ",",
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2 2xl:gap-2.5 grow pr-6 max-w-[574px]">
                                            <StarRating value={rating} />
                                            <p>{comment}</p>
                                        </div>
                                        {/*
                                        <div className="ml-auto">
                                            <ActionsPopover
                                                onCopyHandler={() => {
                                                    const { origin, pathname } = window.location;
                                                    navigator.clipboard
                                                        .writeText(`${origin}${pathname}?comment=${id}`)
                                                        .then();
                                                }}
                                            />
                                        </div>
                                        */}
                                    </div>
                                );
                            },
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
