import React, { ElementType, ReactNode } from "react";
import clsx from "clsx";
import { Loader } from "Uikit/Loader/Loader";

const colors = {
    standard: {
        common: "border-transparent text-white", // Common classes for each style
        primary: "bg-primary hover:bg-primary-hover disabled:bg-secondary disabled:text-gray-light active:bg-primary",
        secondary:
            "bg-secondary text-white hover:bg-secondary-dark disabled:bg-secondary border-transparent active:bg-secondary",
        success: "bg-success hover:bg-success-dark disabled:bg-success active:bg-success",
        warning: "bg-warning hover:bg-warning-dark disabled:bg-warning active:bg-warning",
        caution: "bg-caution hover:bg-caution-dark disabled:bg-caution active:bg-caution",
        danger: "bg-danger hover:bg-danger-hover disabled:bg-danger active:bg-danger-active",
        gray: "bg-background hover:bg-gray-blue active:bg-gray-blue disabled:bg-gray-lighter !text-primary",
        background: "bg-background-light hover:bg-gray-blue disabled:bg-primary active:bg-gray-blue !text-primary",
    },
    outline: {
        common: "bg-white border hover:bg-white active:text-white", // Common classes for each style
        default:
            "border-gray-light hover:border-gray-dark active:bg-primary-lighter active:text-black active:border-primary",
        primary:
            "text-primary border-primary hover:text-primary-dark hover:border-primary-dark active:bg-primary-light",
        secondary:
            "border-gray-stroke hover:border-primary hover:text-primary hover:shadow-[0px_8px_20px_rgba(0,0,0,0.12)] active:bg-primary-lightest active:border-primary active:!text-primary",
        success:
            "text-success border-success hover:text-success-dark hover:border-success-dark active:bg-success-light",
        warning:
            "text-warning border-warning hover:text-warning-dark hover:border-warning-dark active:bg-warning-light",
        caution:
            "text-caution border-caution hover:text-caution-dark hover:border-caution-dark active:bg-caution-light",
        danger: "text-danger border-danger hover:text-danger-dark hover:border-danger-dark active:bg-danger-light",
        gray: "text-danger border-danger hover:text-danger-dark hover:border-danger-dark active:bg-danger-light",
        background:
            "border-gray-stroke hover:border-primary hover:text-primary hover:shadow-[0px_8px_20px_rgba(0,0,0,0.12)] active:bg-primary-lightest active:border-primary active:text-primary",
    },
    link: {
        common: "border-transparent hover:underline",
        primary: "text-primary",
        secondary: "text-secondary",
        success: "text-success",
        warning: "text-warning",
        caution: "text-caution",
        danger: "text-danger",
        gray: "text-danger",
        background: "text-secondary",
    },
};

const sizes = {
    default: {
        tiny: "px-2 py-0.5 text-xs leading-xs", // 20px
        small: "px-3 py-2 h-7.5 text-sm leading-sm", // 30px
        medium: "px-5.5 py-2.5 h-10 text-base leading-base 2xl:h-12.5 2xl:text-2md 2xl:px-7 2xl:py-3", // 40px
        large: "px-6 py-4 h-[50px] text-md leading-md", // 50px
        xl: "px-6 py-4 h-[60px] text-md leading-md", // 60px
    },
    round: {
        tiny: "text-xs leading-xs",
        small: "w-7.5 h-7.5 text-sm leading-sm",
        medium: "w-10 h-10 text-sm leading-sm",
        large: "w-[50px] h-[50px] text-md leading-md",
        xl: "w-[60px] h-[60px] text-md leading-md",
    },
    square: {
        tiny: "text-xs leading-xs",
        small: "w-7.5 h-7.5 text-sm leading-sm",
        medium: "w-10 h-10 text-base leading-base 2xl:h-12.5 2xl:w-12.5",
        large: "w-[50px] h-[50px] text-md leading-md",
        xl: "w-[60px] h-[60px] text-md leading-md",
    },
};

const shapes = {
    default: "rounded-lg 2xl:rounded-2lg",
    round: "rounded-full",
    square: "rounded-md 2xl:rounded-2lg",
};

export interface ButtonProps {
    as?: ElementType;
    iconPlacement?: "left" | "right" | "center";
    icon?: ReactNode;
    children?: ReactNode;
    size?: "tiny" | "small" | "medium" | "large" | "xl";
    shape?: keyof typeof shapes;
    variant?: keyof typeof colors;
    color?: "common" | "primary" | "secondary" | "success" | "warning" | "caution" | "danger" | "gray" | "background";
    className?: string;
    onClick?: (event?: any) => void;
    disabled?: boolean;
    type?: "button" | "reset" | "submit";
    title?: string;
    loading?: boolean;
    id?: string;
    tabIndex?: number;
}

export const Button = ({
    as: Tag = "button",
    size = "medium",
    shape = "default",
    variant = "standard",
    color = "primary",
    type = "button",
    icon,
    iconPlacement,
    children,
    className,
    loading,
    onClick,
    ...rest
}: ButtonProps) => {
    return (
        <Tag
            className={clsx(
                "ui-button border-gra",
                "inline-flex border transition-colors focus:ring disabled:cursor-default disabled:bg-gray-lighter disabled:text-disabled-stroke",
                "items-center justify-center font-medium",
                loading &&
                    "relative overflow-hidden cursor-not-allowed pointer-events-none focus:ring-0 active:bg-primary-lightest active:border-gray-stroke active:text-black",
                colors[variant].common,
                colors[variant][color],
                sizes[shape][size],
                shapes[shape],
                className,
            )}
            {...rest}
            type={type}
            onClick={onClick}
        >
            {loading && (
                <div className="absolute z-10 inset-0 flex justify-center items-center py-0.5 bg-white-60">
                    <Loader size={18} />
                </div>
            )}
            {icon && iconPlacement === "left" ? <span className="mr-1.5 2xl:mr-2 flex-shrink-0">{icon}</span> : null}
            {icon && iconPlacement === "center" ? <span>{icon}</span> : null}
            {children}
            {icon && iconPlacement === "right" ? <span className="ml-1.5 2xl:ml-2 flex-shrink-0">{icon}</span> : null}
        </Tag>
    );
};
