import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import Api from "Api/index";
import { MultiSelect } from "Uikit/Forms/MultiSelect";
import { Option } from "Uikit/types/Option";
import { ID } from "types/ID";

interface JobMultiSelectProps {
    onChange: (jobs: ID[]) => void;
}

export const JobMultiSelect = ({ onChange }: JobMultiSelectProps) => {
    const [value, setValue] = useState<Option[]>([]);

    const { data: { Content: jobs = [] } = {} } = useQuery(["jobs", "collection"], () => Api.Job.GetJobs());

    const handleChange = (values: readonly Option[]) => {
        setValue(values as Option[]);
        onChange(values.map((option) => option.value));
    };

    const options = useMemo(() => jobs.map((job) => ({ label: job.name, value: job.id } as Option)), [jobs]);

    return (
        <MultiSelect
            options={options}
            value={value}
            classNames={{ menuList: () => "!max-h-45" }}
            onChange={handleChange}
            placeholder="Должности"
            components={{
                MultiValueContainer: () => null,
            }}
        />
    );
};
