import React, { useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { TeamUser } from "Api/Responses/TeamResponse";
import { Table } from "Uikit";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { useCurrentUser } from "hooks/useCurrentUser";

interface IModalUsersTableProps {
    data: TeamUser[];
}

export const ModalUsersTable = ({ data }: IModalUsersTableProps) => {
    const navigate = useNavigate();
    const [dataDisplayed, setDataDisplayed] = useState<TeamUser[]>(data);
    const currentUser = useCurrentUser();

    const columns = useMemo<ColumnDef<TeamUser>[]>(
        () => [
            {
                header: "участник",
                accessorKey: "firstName,lastName",
                cell: ({ row: { original: user } }) => {
                    console.log(user);
                    return (
                        <div className="group flex items-center space-x-4 2xl:space-x-5 max-w-75 2xl:text-md">
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={window.screen.width >= 1600 ? 56 : 45}
                            />
                            <div
                                className={clsx("line-clamp-1", "cursor-pointer group-hover:text-blue")}
                                onClick={() => {
                                    navigate(
                                        currentUser?.data?.id !== user.id ? `/admin/member/${user.id}` : `/personal`,
                                    );
                                }}
                            >
                                {user.firstName} {user.lastName}
                            </div>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Должность",
                cell: ({ row: { original: user } }) => {
                    const { jobTitle } = user;

                    return <div className="2xl:text-md">{jobTitle?.name ?? "—"}</div>;
                },
                accessorKey: "jobTitle",
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data],
    );

    const onSearch = (searchQuery: string) => {
        setDataDisplayed(() => {
            return data.filter(
                ({ firstName, lastName }) => firstName.includes(searchQuery) || lastName.includes(searchQuery),
            );
        });
    };

    return (
        <Table
            id="modalTeamUsers"
            columns={columns}
            searchTitle="Поиск по наставникам"
            searchBlockClassName="w-full"
            searchClassName="!w-full"
            data={dataDisplayed}
            rowSelection={{}}
            emptyMessage={"По заданным параметрам результатов нет"}
            onSearch={onSearch}
            bodyCellClassName={"!py-2 2xl:!py-[9px]"}
            rowTrClassName={"hover:!bg-transparent"}
            theadClassName="sticky z-40"
            tableWrapperClassName={"-ml-3 h-100 2xl:h-135 overflow-y-auto overflow-x-hidden"}
            columnClassName="sticky top-0 bg-white !opacity-100 first-of-type:pl-2 !pl-3.5 pb-2"
            withoutLines
        />
    );
};
