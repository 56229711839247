import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { SortingState } from "@tanstack/react-table";
import { useNavigate, useParams } from "react-router-dom";
import { useInvalidate } from "hooks/useInvalidate";
import { TreeWrapperContext, ContentLayout } from "Containers";
import { Breadcrumbs } from "Uikit";
import { PromptModal } from "Uikit/Modal/PromptModal";
import { CategoryModal } from "Uikit/CategoryModalNew/CategoryModal";
import { TestsEmpty } from "./TestsEmpty";
import { TestsTable } from "./TestsTable";
import { TestCategoryResponse } from "Api/Responses/TestResponse";
import Api from "Api";
import { ResourceType } from "Enums";

const updateCategories = async (updateFunction: React.Dispatch<React.SetStateAction<TestCategoryResponse[]>>) => {
    const categories = await Api.Test.CategoryGet();
    updateFunction(categories.Content);
};

export const Tests = () => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();

    const { id } = useParams();
    const { setTreeProps, sidebarApi } = useContext(TreeWrapperContext);

    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const [category, setCategory] = useState<TestCategoryResponse | undefined>(undefined);
    const [categories, setCategories] = useState<TestCategoryResponse[]>([]);

    const [editingCategory, setEditingCategory] = useState<Partial<TestCategoryResponse> | undefined>(undefined);
    const [deletingCategory, setDeletingCategory] = useState<Partial<TestCategoryResponse> | undefined>(undefined);

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const onChangeCategory = async (title: string) => {
        let response;

        try {
            if (!editingCategory?.id) {
                response = await Api.Test.CategoryCreate({ title: title });
            } else {
                response = await Api.Test.CategoryEdit({ id: editingCategory.id, title: title });
            }
        } catch (e) {
            return e;
        }

        await updateCategories(setCategories);

        navigate("/admin/tests/" + response.id);
        setEditingCategory(undefined);

        return response;
    };

    const onDeleteCategory = async () => {
        if (!deletingCategory) {
            return;
        }

        await Api.Test.CategoryDelete(deletingCategory.id!);
        setDeletingCategory(undefined);

        await updateCategories(setCategories);

        navigate("/admin/tests");
    };

    const onCreateCategory = () => {
        setEditingCategory({});
    };

    const fetchTests = async (page: number, size: number, sort: SortingState, filter: { [id: string]: string }) => {
        const filters = Object.keys(filter).reduce((acc: any, key) => {
            if (filter[key]) {
                acc[key] = filter[key];
            }

            return acc;
        }, {});

        return await Api.Test.List(page, size, sort, filters);
    };
    const onCopy = async (id: string) => navigate(`/admin/test/${id}/copy`);
    const onState = async (ids: string[], state: string, resourceType: ResourceType) => {
        await Api.Test.State(ids, state, resourceType);
    };

    const handleNavigate = useCallback(
        (node: any) => {
            if (node?.id) {
                navigate(`/admin/tests/${node.id}`);
            } else {
                navigate(`/admin/tests`);
            }
        },
        [navigate],
    );

    const treeProps = useMemo(() => {
        return {
            nodeId: id,
            data: categories.map((category) => {
                return {
                    id: category.id,
                    name: category.title,
                    nodeType: "PROJECT",
                    children: [],
                };
            }),
            searchable: true,
            editable: true,
            selectable: true,
            browseAll: {
                link: "/admin/tests",
                title: "Все тесты",
                onAddClick: () => navigate("/admin/test"),
            },
            onNodeAddClick: (event: any, nodeId: string) => {
                event.stopPropagation();
                navigate(`/admin/test?categoryId=${nodeId}`);
            },
            onEditClick: (title: string, id: string) => setEditingCategory({ id: id, title: title }),
            onDeleteClick: (title: string, id: string) => setDeletingCategory({ id: id, title: title }),
            onSelectNode: handleNavigate,
            getNodeLink: (id: any) => `/admin/tests/${id}`,
            onOrderChange: async (nodes: any) => {
                await Api.Test.CategoryReorder({ categoryIds: nodes.map(({ id }: { id: string }) => id) });
                await updateCategories(setCategories);
            },
            onCheckedChange: (nodesIds: any) => setSelectedCategories(nodesIds),
            footer: {
                title: "Создать категорию",
                action: () => setEditingCategory({}),
            },
            id: "adminTestsSideBar",
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, categories, setEditingCategory, setDeletingCategory, setSelectedCategories]);

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps({
            ...treeProps,
            ...{
                selectedNode: category && id ? category : undefined,
            },
        });
        setIsFetched(true);

        return () => {
            setTreeProps?.({ data: null });
        };
    }, [navigate, invalidate, id, categories, setTreeProps, category, treeProps]);

    useEffect(() => {
        if (id === undefined) {
            setCategory(undefined);
            setIsEmpty(categories.length === 0);
        } else {
            const category = categories.find((p) => p.id === id);

            if (category === undefined) {
                return;
            }

            setCategory(category);
        }

        setIsFetched(true);
    }, [navigate, id, categories]);
    useEffect(() => {
        const fetch = async () => {
            const categories = await Api.Test.CategoryGet();
            setCategories(categories.Content);
        };
        fetch().then();

        const sbApi = sidebarApi?.current;
        return () => {
            sbApi?.setCheckedBucket?.({});
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentLayout className="h-full">
            <CategoryModal
                isOpen={!!editingCategory}
                categoryId={editingCategory?.id}
                categoryName={editingCategory?.title}
                onSubmit={onChangeCategory}
                onClose={() => setEditingCategory(undefined)}
            />
            <PromptModal
                onConfirm={onDeleteCategory}
                onClose={() => setDeletingCategory(undefined)}
                isOpen={!!deletingCategory}
                title="Удаление категории"
                dialogText={["Отмена", "Удалить"]}
            >
                <div className="space-y-3.5">
                    <div className="text-gray-text">&quot;{deletingCategory?.title}&quot;</div>
                    <div>Вы действительно хотите удалить категорию? Все вложенные в нее элементы попадут в Архив</div>
                </div>
            </PromptModal>
            <Breadcrumbs className="mb-2.5" id="adminTestsBreadcrumbs">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Тесты" url="/admin/tests" />
                {category && <Breadcrumbs.Link title={category.title} url={"/admin/tests/" + category.id} />}
            </Breadcrumbs>
            {isFetched && isEmpty && (
                <div className="h-full flex justify-center">
                    <TestsEmpty
                        isEmptyCategories={id === undefined}
                        onCreateCategoryClick={onCreateCategory}
                        onCreateTestClick={() => navigate(`/admin/test${id ? `?categoryId=${id}` : ""}`)}
                    />
                </div>
            )}
            {isFetched && !isEmpty && (
                <TestsTable
                    categoryId={id}
                    fetchTests={fetchTests}
                    onCopy={onCopy}
                    onState={onState}
                    selectedCategories={selectedCategories}
                    onCreateCategory={onCreateCategory}
                />
            )}
        </ContentLayout>
    );
};
