import { Expose, Type } from "class-transformer";
import { MaterialType } from "Enums";

export class ArchiveCategoryResponse {
    @Expose({ name: "id" })
    @Type(() => String)
    public id: string;

    @Expose({ name: "title" })
    @Type(() => String)
    public title: string;
}

export class ArchiveResponseUser {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;
}

export class ArchiveResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => ArchiveResponseUser)
    public archivingUser: ArchiveResponseUser;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => Number)
    public archivingTime: number;

    @Expose()
    @Type(() => ArchiveCategoryResponse)
    public category: ArchiveCategoryResponse;
}

export class ArchiveListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public type: MaterialType;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => Number)
    public createTimestamp: number;

    @Expose()
    @Type(() => Number)
    public modifyTimestamp: number;

    @Expose()
    @Type(() => String)
    public categoryId: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public managerUserId: string;

    @Expose()
    @Type(() => String)
    public complexity: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;
}
