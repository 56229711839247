import React from "react";
import { Button, Modal } from "Uikit";
import { TVoidFunction } from "types";

interface RatingModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setRatingSettingsChanged: (ratingSettingsChanged: boolean) => void;
    resetFunc: TVoidFunction;
    saveFunc: TVoidFunction;
    type: "cancel" | "save";
    id: string;
}

export const RatingModal = ({
    isOpen,
    setIsOpen,
    setRatingSettingsChanged,
    resetFunc,
    saveFunc,
    type,
    id,
}: RatingModalProps) => {
    const modalFooter = (
        <div className="space-x-4" id={id}>
            <Button
                key={0}
                onClick={() => {
                    setRatingSettingsChanged(false);
                    resetFunc();
                    setIsOpen(false);
                }}
                variant="outline"
                size={"small"}
                color={"secondary"}
                className={"border-[#EAEDF3] "}
            >
                Отмена
            </Button>

            <Button
                key={1}
                onClick={() => {
                    saveFunc();
                    setIsOpen(false);
                }}
                size={"small"}
                color={"primary"}
            >
                Сохранить
            </Button>

            {type === "cancel" && (
                <Button key={2} onClick={() => setIsOpen(false)} size={"small"} color="danger">
                    Закрыть без сохранения
                </Button>
            )}
        </div>
    );

    const modalTitle = {
        cancel: {
            text: "Изменения не сохранены",
        },
        save: {
            text: "Сохранение",
        },
    };

    const modalBody = (
        <div>
            {type === "cancel" ? (
                <div className="mb-6 mt-3">
                    Найдены несохраненные изменения, вы уверены, что хотите закрыть? Все изменения будут сброшены
                </div>
            ) : (
                <div className="mb-6 mt-3">
                    Вы уверены, что хотите сохранить изменения? Изменения могут примениться не сразу
                </div>
            )}
        </div>
    );
    return (
        <>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={modalTitle[type].text}>
                <Modal.Body>{modalBody}</Modal.Body>
                <Modal.Footer>{modalFooter}</Modal.Footer>
            </Modal>
            {/* <CustomToastContainer autoClose={5000} hideProgressBar={true} /> */}
        </>
    );
};
