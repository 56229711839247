import React from "react";
import { Modal, Button } from "Uikit";
import { TVoidFunction } from "types";

interface ICancelModal {
    id: string;
    className?: string;
    isEdit: boolean;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    onSubmit: TVoidFunction;
}
export const CancelModal = ({ id, className, isEdit, isOpen, setIsOpen, onSubmit }: ICancelModal) => {
    if (!isOpen) {
        return null;
    }

    return (
        <Modal id={id} className={className} title="Отменить изменения" isOpen={isOpen} setIsOpen={setIsOpen}>
            <Modal.Body>
                {isEdit && "Внесенные изменения будут утеряны, продолжить?"}
                {!isEdit && "Найдены несохраненные изменения, вы уверены, что хотите их отменить?"}
            </Modal.Body>
            <Modal.Footer>
                <Button className="border-[#EAEDF3]" variant="outline" color="common" onClick={() => setIsOpen(false)}>
                    Отмена
                </Button>
                <Button onClick={onSubmit} color="danger">
                    {isEdit && "Подтвердить"}
                    {!isEdit && "Закрыть без сохранения"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
