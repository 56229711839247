import React from "react";
import { TaskPublicListResponse } from "Api/Responses/TaskPublicResponse";
import { Icon, Icons } from "Uikit";
import { ProgressStatus } from "Enums";
import { formatDeadlineTime } from "./utils";
import { formatStatusChangeTime } from "helpers/dateHelper";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

interface ITestsCard {
    task: TaskPublicListResponse;
    onClick: (taskId: string) => void;
}
export const TasksCardMobile = ({ task, onClick }: ITestsCard) => {
    return (
        <div className="flex p-2 mb-3 bg-background-light rounded-2xl" onClick={() => onClick(task.id)}>
            <div className="mr-2 w-20 h-14 bg-gray-lighter rounded-lg">
                {task.logoId && (
                    <img
                        className="w-full h-full rounded-lg object-cover"
                        src={Api.Upload.GetLogo(task.logoId, LogoSize.THUMB_MINI)}
                        alt={task.title}
                    />
                )}
            </div>
            <div className="flex flex-col">
                <p className="mb-1 text-black p2-accent">{task.title}</p>
                <div className="flex items-center">
                    <div className="flex items-center">
                        <Icon className="mr-0.5" icon={Icons.Trophy} width={10} height={10} color="fill-blue-drk" />
                        <span className="text-blue-drk p4">{task.ratingPoints}</span>
                    </div>
                    {!!task.deadlineTime && (
                        <div className="flex items-center ml-3">
                            <Icon className="mr-0.5" icon={Icons.Time} width={10} height={10} color="fill-blue-drk" />
                            <span className="text-blue-drk p4">{formatDeadlineTime(task.deadlineTime)}</span>
                        </div>
                    )}
                    {task.progressStatus !== ProgressStatus.NOT_STARTED &&
                        task.progressStatus !== ProgressStatus.RE_WORK && (
                            <span className="ml-3 text-blue-drk p4">
                                {task.progressStatus === ProgressStatus.FAILED ? "Провален " : ""}
                                {formatStatusChangeTime(task.statusChangeTime)}
                            </span>
                        )}
                </div>
            </div>
        </div>
    );
};
