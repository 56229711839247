import React from "react";

export const Typography = () => {
    return (
        <table>
            <tbody>
                <tr className="bg-blue-10">
                    <td className="p-8 space-y-5">
                        <h1>H1 Title</h1>
                        <div className="text-black opacity-65">{"<h1>H1 Title</h1>"}</div>
                    </td>
                    <td className="p-8 space-y-5">
                        <h2>H2 Title</h2>
                        <div className="text-black opacity-65">{"<h2>H2 Title</h2>"}</div>
                    </td>
                    <td className="p-8 space-y-5">
                        <h2 className="light">H2 Light</h2>
                        <div className="text-black opacity-65">{'<h2 className="light">H2 Light</h2>'}</div>
                    </td>
                </tr>
                <tr className="bg-blue-gray">
                    <td className="p-8 space-y-5">
                        <h3>H3 Title</h3>
                        <div className="text-black opacity-65">{"<h3>H3 Title</h3>"}</div>
                    </td>
                    <td className="p-8 space-y-5">
                        <h4>H4 Title</h4>
                        <div className="text-black opacity-65">{"<h4>H4 Title</h4>"}</div>
                    </td>
                    <td className="p-8 space-y-5">
                        <h4 className="light">H4 Title</h4>
                        <div className="text-black opacity-65">{'<h4 className="light">H4 Title</h4>'}</div>
                    </td>
                </tr>
                <tr className="bg-blue-10">
                    <td className="p-8 space-y-5">
                        <p className="p1">Main Paragraph</p>
                        <div className="text-black opacity-65">{'<p className="p1">Main Paragraph</p>'}</div>
                    </td>
                    <td className="p-8 space-y-5" colSpan={2}>
                        <p className="p1-accent">Main Accent</p>
                        <div className="text-black opacity-65">{'<p className="p1-accent">Main Accent</p>'}</div>
                    </td>
                </tr>
                <tr className="bg-blue-gray">
                    <td className="p-8 space-y-5">
                        <p className="p2-accent">Small Accent</p>
                        <div className="text-black opacity-65">{'<p className="p2-accent">Small Accent</p>'}</div>
                    </td>
                    <td className="p-8 space-y-5">
                        <p className="p2">Small Paragraph</p>
                        <div className="text-black opacity-65">{'<p className="p2">Small Paragraph</p>'}</div>
                    </td>
                    <td className="p-8 space-y-5">
                        <p className="p3">Small 12</p>
                        <div className="text-black opacity-65">{'<p className="p3">Small 12</p>'}</div>
                    </td>
                </tr>
                <tr className="bg-blue-10">
                    <td className="p-8 space-y-5">
                        <p className="p4-accent">Small Accent</p>
                        <div className="text-black opacity-65">{'<p className="p4-accent">Micro Accent</p>'}</div>
                    </td>
                    <td className="p-8 space-y-5">
                        <p className="p4">Micro</p>
                        <div className="text-black opacity-65">{'<p className="p4">Micro</p>'}</div>
                    </td>
                    <td className="p-8 space-y-5">
                        <p className="p4-table">Micro Table</p>
                        <div className="text-black opacity-65">{'<p className="p4-table">Micro Table</p>'}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
