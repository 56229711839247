import React, { useState } from "react";
import { Button, FormGroup, Input, Label, Modal } from "Uikit";
import { Table } from "Uikit/Table/Table";
import { ColumnDef, PaginationState, Row } from "@tanstack/react-table";
import { Achievement } from "Uikit/Table/fetchTestData";
import clsx from "clsx";
import { BaseIdPaginationRequest } from "Api/BaseRequest";
import Api from "../../../../Api";
import { useQuery } from "react-query";
import { AchievementResponse } from "Api/Responses/AchievementResponse";
import { numCapEnd } from "helpers/numCapEnd";

interface AddAchievementModalProps {
    className?: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    id: string;
}

export const AddAchievementModal = ({ className, isOpen, setIsOpen, id }: AddAchievementModalProps) => {
    const [rowSelection, setRowSelection] = useState({});

    const [achievementSelected, setAchievementSelected] = useState<Row<Achievement>>();

    const fetchAllAchievements = async (count: number, offset: number) => {
        const request = new BaseIdPaginationRequest();
        request.uuid = "lskadfj323";
        request.page = count;
        request.size = offset;

        return await Api.Achievement.List(0, 10, [], {});
    };

    const [{ pageIndex, pageSize }] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const data = useQuery(["data", pagination], () => fetchAllAchievements(pageIndex, pageSize), {
        keepPreviousData: true,
    });

    const columns = React.useMemo<ColumnDef<AchievementResponse>[]>(
        () => [
            {
                header: "Ачивка",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <img className="rounded-full h-10 w-10" />
                            <span className="group-hover:text-blue">{String(info.getValue())}</span>
                        </div>
                    );
                },
                accessorKey: "title",
            },
            {
                header: "Баллы",
                footer: (props) => props.column.id,
                accessorKey: "points",
            },
            {
                header: "Доступно",
                accessorKey: "limit",
                footer: (props) => props.column.id,
            },
        ],
        [],
    );

    const selectedAchievementTitle = achievementSelected?.original?.title;

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={"Выдать ачивку"}
            className={clsx("max-w-2xl", className)}
            id={id}
        >
            <Modal.Body className="mt-4 mb-2">
                {achievementSelected?.original ? (
                    <div className="mb-40">
                        <div className="flex space-x-2 mt-6">
                            <div className="flex flex-grow space-x-4">
                                <img className="rounded-full w-20 h-20" src={achievementSelected?.original?.image} />
                                <div>
                                    <span className="inline-block align-middle mt-6">{selectedAchievementTitle}</span>
                                </div>
                            </div>

                            <div>
                                <span className="inline-block align-middle mt-6 text-[#1380CE]">
                                    {achievementSelected?.original?.points}{" "}
                                    {numCapEnd(
                                        { one: "балл", two: "балла", few: "баллов" },
                                        Number(achievementSelected?.original?.points),
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className="mt-6">
                            <FormGroup>
                                <Label>Комментарий</Label>
                                <Input />
                            </FormGroup>
                        </div>
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        id="adminNewMemberAchievementTabModalGiveAch"
                        searchTitle="Поиск по названию"
                        data={data.data?.Content}
                        rowSelection={rowSelection}
                        onRowSelectionChange={setRowSelection}
                        onRowClick={setAchievementSelected}
                        rowClassName="border-none hover:bg-gray"
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                {achievementSelected?.original ? (
                    <div className="space-x-4">
                        <Button
                            key={0}
                            onClick={() => setAchievementSelected({} as Row<Achievement>)}
                            variant="outline"
                            size={"small"}
                            color={"secondary"}
                            className={"border-[#EAEDF3] "}
                            id="adminNewMemberAchievementTabModalGiveAchBack"
                        >
                            Назад
                        </Button>
                        <Button
                            key={0}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            size={"small"}
                            color={"primary"}
                            className={"border-[#EAEDF3] "}
                            id="adminNewMemberAchievementTabModalGiveAchOk"
                        >
                            Выдать
                        </Button>
                    </div>
                ) : (
                    <Button
                        key={0}
                        onClick={() => setIsOpen(false)}
                        variant="outline"
                        size={"small"}
                        color={"common"}
                        className={"border-[#EAEDF3] "}
                        id="adminNewMemberAchievementTabModalGiveAchClose"
                    >
                        Закрыть
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
