import { Expose, Type } from "class-transformer";

export class OfficeResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public region: number;

    @Expose()
    @Type(() => String)
    public address: string;

    @Expose()
    @Type(() => String)
    public country: string;

    @Expose()
    @Type(() => Number)
    public createDate: number;

    @Expose()
    @Type(() => Number)
    public lastModificationDate: number;

    @Expose()
    @Type(() => String)
    public userList: string[];
}
