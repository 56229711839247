import React, { ReactNode, Children } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import clsx from "clsx";
import { followCursor } from "tippy.js";
import "tippy.js/dist/border.css";
import "tippy.js/dist/tippy.css";
import { getChildrenByType } from "helpers/children";
import styles from "./Popover.module.css";
import { Except } from "type-fest";

interface PopoverProps extends Except<TippyProps, "children"> {
    className?: string;
    skidding?: number;
    distance?: number;
    children?: ReactNode;
}

export const Popover = ({ className, children, skidding = 0, distance = 10, ...rest }: PopoverProps) => {
    const content = getChildrenByType(children, Popover.Content);
    const target = Children.toArray(children).filter((child: any) => child.type !== Popover.Content);

    return (
        <Tippy
            interactive
            zIndex={50}
            content={content}
            className={clsx("ui-popover", styles.main, "!rounded-lg !border-gray-light shadow-xl", className)}
            plugins={[followCursor]}
            offset={[skidding ?? 0, distance ?? 10]}
            {...rest}
        >
            <span>{target}</span>
        </Tippy>
    );
};

const Content = ({ className, children }: { className?: string; children?: ReactNode }) => {
    return <div className={clsx("ui-popover-content", className)}>{children}</div>;
};

Popover.Content = Content;
