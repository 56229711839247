import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";

export class CompanyReadResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public contractNumber: string;

    @Expose()
    @Type(() => Number)
    public maxUsers: number;

    @Expose()
    @Type(() => Number)
    public activeUsers: number;

    @Expose()
    @Type(() => Number)
    public maxMemoryGb: number;

    @Expose()
    @Type(() => Number)
    public memoryUsedGb: number;

    @Expose()
    @Type(() => Number)
    public licenseExpirationDate: number;

    // логотип компании 1:1
    @Expose()
    @Type(() => String)
    public logoSquareId?: string;

    // логотип компании 1:3
    @Expose()
    @Type(() => String)
    public logoRectangleId?: string;
}

export class CompanyValidateResponse extends BaseResponse {
    @Expose()
    @Type(() => Boolean)
    public validName: boolean;

    @Expose()
    @Type(() => Boolean)
    public validContractNumber: boolean;
}
