import React, { ForwardedRef, forwardRef, useEffect } from "react";
import { Icon, Icons, flash } from "Uikit";
import { Accept, useDropzone } from "react-dropzone";
import { useCombinedRefs } from "hooks/useCombinedRef";
import { humanFileSize } from "helpers/humanFileSize";
import { FILES_TYPE_ICONS } from "constants/attachments";
import { ProgressBar } from "./ProgressBar";
import { TVoidFunction } from "types";
import { getFileSizeErrorMessage } from "helpers/file";
import { UIErrorMessages } from "Enums";

export interface FileSizeByType {
    [key: string]: number;
}

interface FileUploadProps {
    className?: string;
    onChange?: (file: File) => void;
    onCancelUpload?: TVoidFunction;
    maxSize?: FileSizeByType | number;
    accept?: Accept;
    acceptDescription?: string;
    multiple?: boolean;
    attachment?: IFile;
    id?: string;
    isVideoProcessed?: boolean;
}

interface IFile extends File {
    extension?: string;
    uploaded?: number;
    uploadedPercent?: number;
    status?: number;
}

export const FileUpload = forwardRef(
    (
        {
            className,
            onChange,
            onCancelUpload,
            maxSize,
            accept = {},
            acceptDescription = "",
            multiple = false,
            attachment,
            id,
            isVideoProcessed = false,
        }: FileUploadProps,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        const inputRef = useCombinedRefs(ref);
        const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, accept: accept, onDropRejected });

        const [file, setFile] = React.useState<any>(attachment);

        const isMaxSizeValid = (file: File): boolean => {
            if (!maxSize) {
                return true;
            }

            if (typeof maxSize === "number") {
                return file.size < maxSize;
            }

            return Object.keys(maxSize).some((type) => {
                if (RegExp(type).exec(file.type)) {
                    console.log(type, " --- ", file.size, maxSize[type], file.size < maxSize[type]);

                    return file.size < maxSize[type];
                }

                return false;
            });
        };

        function onDrop<T extends IFile>(files: T[]) {
            if (!files?.length) {
                return;
            }

            if (!Object.keys(accept).some((type) => RegExp(type).exec(files[0].type))) {
                flash.error("Для загрузки допустимы следующие форматы - MP4");

                return;
            }

            if (!isMaxSizeValid(files[0])) {
                flash.error(getFileSizeErrorMessage("2 ГБ"));

                return;
            }

            onChange?.(files[0]);
        }

        function onInputChange(e: any) {
            const {
                target: { files },
            } = e;

            if (!files?.length) {
                return;
            }

            if (!Object.keys(accept).some((type) => RegExp(type).exec(files[0].type))) {
                // flash.error("Для загрузки допустимы следующие форматы - MP4");
                flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);

                return;
            }

            if (!isMaxSizeValid(files[0])) {
                flash.error(getFileSizeErrorMessage("2 ГБ"));

                return;
            }

            onChange?.(files[0]);
        }

        function onDropRejected() {
            console.log("onDropRejected files", fileRejections);
            fileRejections.forEach((f) => {
                f.errors.forEach((e) => {
                    if (e.code === "file-invalid-type") {
                        flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
                    }
                });
            });
        }

        useEffect(() => {
            setFile(attachment ?? undefined);
        }, [attachment]);

        const isVideo = (file?.file?.type || file?.contentType)?.includes("video") ?? false;

        return !file ? (
            <div
                {...getRootProps({
                    className:
                        "group flex flex-col items-center p-4.5 max-w-200 border border-dashed border-gray-light rounded-lg cursor-pointer hover:border-primary " +
                        className,
                })}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (inputRef) {
                        inputRef.current.click();
                    }
                }}
                id={id + "Field"}
            >
                <input {...getInputProps({ multiple })} onChange={onInputChange} ref={inputRef} />
                <Icon
                    className="mb-2"
                    icon={Icons.Upload}
                    width="58px"
                    height="58px"
                    color="fill-gray-blue group-hover:fill-blue"
                />
                <div className="pb-1 text-gray-dark">Выберите файл или перетащите мышью</div>
                <div className="text-xs text-gray-text">{acceptDescription}</div>
            </div>
        ) : (
            <div
                className="relative flex w-full items-center p-4 max-w-200 border border-gray-light rounded-lg"
                id={id + "Process"}
            >
                <div>
                    <Icon
                        className="mr-6"
                        icon={FILES_TYPE_ICONS[(file?.file || file)?.extension || ""] || Icons.FileOther}
                        width="32px"
                        height="32px"
                    />
                </div>
                <div className="w-full">
                    <div className="flex items-center w-full pb-0.5">
                        <div>{(file.file || file)?.name}</div>
                        {isVideoProcessed && (
                            <div className="ml-auto cursor-pointer" onClick={onCancelUpload}>
                                <Icon icon={Icons.CloseBig} width={16} height={16} />
                            </div>
                        )}
                    </div>
                    <div className="text-gray">
                        {isVideo && !isVideoProcessed ? "Видео обрабатывается" : humanFileSize(file.size, true)}
                    </div>
                    {((file.status && file.status !== "done") || (!file.status && !file.disposableLink)) && (
                        <div className="mt-2">
                            <ProgressBar value={file.uploadedPercent || 0} />
                        </div>
                    )}
                </div>
            </div>
        );
    },
);
