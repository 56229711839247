import React, { useState, useEffect } from "react";
import { Breadcrumbs, Content, Tabs, TabsWrapper } from "Uikit";
import { MembersTable } from "./MembersTable";
import { useQuery } from "react-query";
import { SortingState } from "@tanstack/react-table";
import Api from "../../../Api";
import { ContentLayout } from "Containers/ContentLayout";
import { UserState } from "types/User";
import { useLocation } from "react-router-dom";

export const Members = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    const location = useLocation();

    const roles = useQuery(["roles", "collection"], () => Api.Role.List());
    const teams = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );
    const offices = useQuery(["offices", "collection"], () => Api.Office.List());
    const jobs = useQuery(["jobs", "collection"], () => Api.Job.GetJobs());

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const showBlockedUsers = queryParams.get("showBlockedUsers");
        if (showBlockedUsers) {
            setSelectedTab(1);
        }
    }, [location.search]);

    const fetchUsers = async (page: number, size: number, sort: SortingState, filter: { [id: string]: string }) => {
        const filters = Object.keys(filter).reduce((acc: any, key) => {
            if (filter[key]) {
                acc[key] = filter[key];
            }

            return acc;
        }, {});

        const response = await Api.User.GetMembersList(page, size, sort, filters);
        setTotal(response.Total);

        return response;
    };

    const tabsComponent = () => {
        return `${total} участников`;
    };

    if (teams.isLoading || roles.isLoading || offices.isLoading || jobs.isLoading) {
        return null;
    }

    return (
        <ContentLayout id="members" className="h-full">
            <Breadcrumbs id="adminMembersBreadcrumbs">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Участники" url="/admin/members" />
            </Breadcrumbs>
            <TabsWrapper className="mt-2 grow" selectedIndex={selectedTab} onChange={setSelectedTab}>
                <TabsWrapper.Tabs
                    classname="flex flex-grow justify-between items-center h-max"
                    id="adminMembersTabs"
                    label={tabsComponent()}
                >
                    <Tabs.Tab title="Активные" />
                    <Tabs.Tab title="Заблокированные" />
                </TabsWrapper.Tabs>
                <TabsWrapper.Content>
                    <Content.Body className="mt-6 h-full">
                        <MembersTable
                            id="adminMembersActive"
                            type={UserState.ACTIVE}
                            roles={roles.data!.Content}
                            teams={teams.data!}
                            fetch={fetchUsers}
                            offices={offices?.data?.Content ?? []}
                            jobs={jobs?.data?.Content ?? []}
                        />
                    </Content.Body>
                    <Content.Body className="mt-6 h-full">
                        <MembersTable
                            id="adminMembersBlocked"
                            type={UserState.BLOCKED}
                            roles={roles.data!.Content}
                            teams={teams.data!}
                            fetch={fetchUsers}
                            offices={offices?.data?.Content ?? []}
                            jobs={jobs?.data?.Content ?? []}
                        />
                    </Content.Body>
                </TabsWrapper.Content>
            </TabsWrapper>
        </ContentLayout>
    );
};
