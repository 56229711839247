import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";

const today = new Date();

// Форматирование времени дедлайна
export const formatDeadlineTime = (time: number | null, showTime = false) => {
    let timeFormatted = "";

    if (!time) {
        return timeFormatted;
    }

    const date = new Date(time * 1000);
    const hoursToDate = differenceInHours(date, today);

    if (isAfter(today, date)) {
        timeFormatted = "Просрочено";
    } else {
        if (isSameDay(today, date)) {
            if (showTime) {
                timeFormatted = `Сегодня ${date.toLocaleTimeString("ru-ru", {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`;
            } else {
                timeFormatted = "Сегодня";
            }
        } else if ([0, 1].indexOf(differenceInDays(date, today)) !== -1) {
            if (showTime) {
                timeFormatted = `Завтра ${date.toLocaleTimeString("ru-ru", {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`;
            } else {
                timeFormatted = "Завтра";
            }
        }

        if (hoursToDate >= 48) {
            const dateFormat: Intl.DateTimeFormatOptions = {
                month: "short",
                day: "numeric",
                hour: showTime ? "numeric" : undefined,
                minute: showTime ? "numeric" : undefined,
            };

            if (today.getFullYear() < date.getFullYear()) {
                dateFormat.year = "numeric";
            }

            timeFormatted = date.toLocaleDateString("ru-ru", dateFormat);
        }
    }

    return timeFormatted;
};

const secondsInHour = 3600;
const secondsInMinute = 60;
const getSecondsInHour = (hours: number) => hours * secondsInHour;

export const formatTime = (seconds?: number | null, roundMinutes = true) => {
    let timeFormatted = "";

    if (seconds) {
        const hours = Math.floor(seconds / secondsInHour);
        if (hours) {
            timeFormatted += `${hours} ч`;
        }
        const minutesNumber = (seconds - getSecondsInHour(hours)) / secondsInMinute;
        const minutes = roundMinutes ? Math.ceil(minutesNumber) : Math.floor(minutesNumber);
        if (minutes) {
            timeFormatted += ` ${minutes} мин`;
        }

        if (!roundMinutes) {
            timeFormatted += ` ${seconds - (getSecondsInHour(hours) + minutes * secondsInMinute)} сек`;
        }
    }

    return timeFormatted;
};

export const formatLeadTime = (seconds: number | undefined) => {
    if (seconds === undefined || seconds < 0) {
        return "";
    }

    const days = Math.floor(seconds / (60 * 60 * 24));
    const hours = Math.floor((seconds / (60 * 60)) % 24);
    const minutes = Math.floor((seconds / 60) % 60);

    if (days >= 10) {
        return `${days} д`;
    }
    if (days >= 1 && days < 10) {
        return `${days} д ${hours} ч`;
    }
    if (hours >= 1 && hours < 24) {
        return `${hours} ч ${minutes} мин`;
    }

    return `${minutes + 1} мин`;
};

// Форматирование времени проверки (комментария проверяющего)
export const formatCheckTime = (
    time: number | null,
    options: {
        showTime?: boolean;
        delimiter?: string;
    } = {
        showTime: false,
        delimiter: "",
    },
) => {
    let timeFormatted = "";
    const { showTime, delimiter = "" } = options;

    if (!time) {
        return timeFormatted;
    }

    const date = new Date(time * 1000);
    const hoursToDate = Math.abs(differenceInHours(date, today));

    if (isSameDay(today, date)) {
        if (showTime) {
            timeFormatted = `Сегодня${delimiter} ${date.toLocaleTimeString("ru-ru", {
                hour: "2-digit",
                minute: "2-digit",
            })}`;
        } else {
            timeFormatted = "Сегодня";
        }
    } else {
        if ([0, 1].indexOf(differenceInDays(date, today)) !== -1) {
            if (showTime) {
                timeFormatted = `Вчера${delimiter} ${date.toLocaleTimeString("ru-ru", {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`;
            } else {
                timeFormatted = "Вчера";
            }
        }

        if (hoursToDate > 24) {
            const dateFormat: Intl.DateTimeFormatOptions = {
                month: "2-digit",
                day: "numeric",
                hour: showTime ? "numeric" : undefined,
                minute: showTime ? "numeric" : undefined,
            };
            if (today.getFullYear() !== date.getFullYear()) {
                dateFormat.year = "numeric";
            }
            // Янв., Февр., Март, Апр., Май, Июнь, Июль, Авг., Сент., Окт., Нояб., Дек.
            timeFormatted = date
                .toLocaleDateString("ru-ru", dateFormat)
                .replace(".01", " Янв.")
                .replace(".02", " Февр.")
                .replace(".03", " Март")
                .replace(".04", " Апр.")
                .replace(".05", " Май")
                .replace(".06", " Июнь")
                .replace(".07", " Июль")
                .replace(".08", " Авг.")
                .replace(".09", " Сент.")
                .replace(".10", " Окт.")
                .replace(".11", " Нояб.")
                .replace(".12", " Дек.");
        }
    }

    return timeFormatted;
};
