import { Expose, Type } from "class-transformer";
import { HistoryType, TestQuestionStatus, ValidationStatus } from "Enums";
import { UserResponse } from "./UserResponse";
import { MaterialListResponse } from "./MaterialResponse";
import { CourseListResponse } from "./CourseResponse";

export interface TaskListResponseFieldAnswer {
    Value: any;
}

export class TaskListResponseField {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => String)
    public Type: string;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => String)
    public Description: string;

    @Expose()
    @Type(() => Object)
    public Answer: TaskListResponseFieldAnswer;

    @Expose()
    @Type(() => String)
    public Comment: string;
}

export class TaskListTestResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => UserResponse)
    public User: UserResponse;

    @Expose()
    @Type(() => UserResponse)
    public Supervisor: UserResponse;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => String)
    public Description: string;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => Number)
    public Status: ValidationStatus;

    @Expose()
    @Type(() => Number)
    public Points: number;

    @Expose()
    @Type(() => TaskListResponseField)
    public Fields: TaskListResponseField[];

    @Expose()
    @Type(() => Date)
    public SentDate: Date;

    @Expose()
    @Type(() => String)
    public Comment: string;
}

export interface TestListResponseFieldAnswer {
    Value: any;
}

export class TestListResponseField {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => String)
    public Type: string;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => String)
    public Points: number;

    @Expose()
    @Type(() => Object)
    public Answer: TestListResponseFieldAnswer;

    @Expose()
    @Type(() => Number)
    public Status: TestQuestionStatus;

    @Expose()
    @Type(() => String)
    public Comment: string;
}

export class TestListResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => UserResponse)
    public User: UserResponse;

    @Expose()
    @Type(() => UserResponse)
    public Supervisor: UserResponse;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => Number)
    public Description: string;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => Number)
    public Status: ValidationStatus;

    @Expose()
    @Type(() => TestListResponseField)
    public Fields: TestListResponseField[];

    @Expose()
    @Type(() => Date)
    public SentDate: Date;

    @Expose()
    @Type(() => String)
    public Comment: string;
}

export class RemarkListResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => UserResponse)
    public User: UserResponse;

    @Expose()
    @Type(() => UserResponse)
    public Supervisor: UserResponse;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => MaterialListResponse)
    public Source: MaterialListResponse;

    @Expose()
    @Type(() => Number)
    public Status: ValidationStatus;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => String)
    public Comment: string;

    @Expose()
    @Type(() => Date)
    public SentDate: Date;
}

export class HistoryListResponse {
    @Expose()
    @Type(() => String)
    public Id: string;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => UserResponse)
    public User: UserResponse;

    @Expose()
    @Type(() => Number)
    public Type: HistoryType;

    @Expose()
    @Type(() => String)
    public Status: ValidationStatus;

    @Expose()
    @Type(() => Number)
    public Source: number;

    @Expose()
    @Type(() => Date)
    public VerificationDate: Date;
}

export class ReviewListResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => UserResponse)
    public User: UserResponse;

    @Expose()
    @Type(() => UserResponse)
    public Supervisor: UserResponse;

    @Expose()
    @Type(() => String)
    public Text: string;

    @Expose()
    @Type(() => Number)
    public Rating: number;

    @Expose()
    @Type(() => CourseListResponse)
    public Course: CourseListResponse;

    @Expose()
    @Type(() => Date)
    public Date: Date;
}

export class CommentListResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => UserResponse)
    public User: UserResponse;

    @Expose()
    @Type(() => UserResponse)
    public Supervisor: UserResponse;

    @Expose()
    @Type(() => String)
    public Text: string;

    @Expose()
    @Type(() => MaterialListResponse)
    public Source: MaterialListResponse;

    @Expose()
    @Type(() => Date)
    public Date: Date;
}
