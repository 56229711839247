import React from "react";
import { Option } from "../types/Option";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Checkbox } from "Uikit/Forms/Checkbox";
import Select, {
    ClassNamesConfig,
    ClearIndicatorProps,
    DropdownIndicatorProps,
    GroupBase,
    InputProps,
    OptionProps,
    components,
} from "react-select";

type MultiSelectProps = {
    placeholder?: string;
    options: Option[];
    value?: Option[];
    classNames?: ClassNamesConfig<Option, true, GroupBase<Option>>;
    onChange: (value: readonly Option[]) => void;
    components?: any;
};

export const MultiSelect = ({
    placeholder = "",
    classNames,
    options,
    value = [],
    onChange,
    components,
}: MultiSelectProps) => {
    return (
        <Select
            hideSelectedOptions={false}
            isMulti
            className="basic-single"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            onChange={onChange}
            isClearable
            isSearchable
            name="color"
            value={value}
            options={options}
            placeholder={placeholder}
            classNames={classNames}
            components={{
                Option: CustomOption,
                ClearIndicator: CustomClearIndicator,
                Input: CustomInput,
                DropdownIndicator: CustomDropdownIndicator,
                // MultiValueContainer: () => null,
                Placeholder: () => null,
                // IndicatorSeparator: () => null,

                ...components,
            }}
            styles={{
                control: (baseStyles: any) => ({
                    ...baseStyles,
                    border: 0,
                    outline: 0,
                    boxShadow: "none",
                    background: "#F5F7F9",
                    ":hover": {
                        border: 0,
                        outline: 0,
                    },
                }),
                container: (baseStyles: any) => ({
                    ...baseStyles,
                    position: "unset",
                }),
                menu: (baseStyles: any) => ({
                    ...baseStyles,
                    position: "absolute",
                    left: 0,
                    right: 24,
                    width: "100%",
                }),
                menuList: (baseStyles: any) => ({
                    ...baseStyles,
                    padding: "10px 12px",
                }),
            }}
        />
    );
};

const CustomOption = (props: OptionProps<Option, true>) => {
    const checked = props.getValue().includes(props.data);

    return (
        <div
            ref={props.innerRef}
            {...props.innerProps}
            className="flex px-2 py-1.5 space-x-2 items-center cursor-pointer hover:bg-blue-lightest rounded-lg"
        >
            <div>
                <Checkbox name="position" checked={checked} className="!w-full pointer-events-none" />
            </div>
            <div>{props.label}</div>
        </div>
    );
};

const CustomClearIndicator = (props: ClearIndicatorProps<Option, true>) => {
    const itemsLength = props.getValue().length;

    return (
        <div
            {...props.innerProps}
            className={`group flex-center rounded-full h-5.5 w-5.5 text-sm bg-primary text-white cursor-pointer mr-1 ${
                itemsLength > 99 ? "w-auto min-w-5.5 px-1" : ""
            }`}
        >
            <span className="group-hover:hidden">{itemsLength}</span>
            <div className="hidden group-hover:block">
                <Icon icon={Icons.Close} width={18} height={18} color="fill-white" />
            </div>
        </div>
    );
};

const CustomInput = (props: InputProps<Option, true>) => {
    return (
        <components.Input
            {...props}
            placeholder={props.selectProps.placeholder as string}
            className="multi-select p-0 border-transparent focus:border-transparent focus:ring-0 w-full min-w-0.5 bg-blue-lightest !grid-cols-1"
        />
    );
};

const CustomDropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
    return (
        <div className="p-2" {...props.innerProps}>
            <Icon icon={props.selectProps.menuIsOpen ? Icons.ChevronUp : Icons.ChevronDown} width={14} height={14} />
        </div>
    );
};
