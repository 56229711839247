import React, { useState, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    UniqueIdentifier,
} from "@dnd-kit/core";
import { useDialog } from "hooks/useDialog";
import { Empty } from "Uikit/Page/Empty";
import { Button, Icon, Icons } from "Uikit";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { TestContentItem } from "./TestContentItem";
import { TestRequest, TestRequestQuestion, TestRequestQuestionAnswer } from "Api/Requests/TestRequest";
import { TestQuestionType } from "Enums";
import clsx from "clsx";
import { IOption } from "types";

interface ITestContent {
    errors: any;
    complexity: IOption[];
    test: TestRequest;
    onChange: (test: TestRequest) => void;
    solutionsOnReview: boolean;
}
export const TestContent = forwardRef(
    ({ errors, complexity, test, onChange, solutionsOnReview }: ITestContent, ref) => {
        const { dialogState, openDialog, closeDialog } = useDialog();
        const [currentIndex, setCurrentIndex] = useState<number | undefined>(test.questions.length ? undefined : 0);

        const sensors = useSensors(
            useSensor(PointerSensor),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates,
            }),
        );

        useImperativeHandle(ref, () => ({
            collapseTestItem() {
                setCurrentIndex(undefined);
            },
        }));

        const onCreate = () => {
            const questions: TestRequestQuestion[] = Object.assign(test.questions);
            const question = {
                id: test.questions.length.toString(),
                logoId: null,
                questionType: TestQuestionType.ONE_OPTION,
                questionText: "",
                complexity: "MEDIUM",
                answerNote: "",
                correctAnswer: "",
                materialsOnError: [],
                answers: [] as TestRequestQuestionAnswer[],
            };

            question.answers.push({
                id: "0",
                pictureId: null,
                answerText: "",
                isCorrect: false,
            });

            question.answers.push({
                id: "1",
                pictureId: null,
                answerText: "",
                isCorrect: false,
            });

            questions.push(question);
            onChange({ ...test, questions: questions });

            setCurrentIndex(questions.length - 1);

            if (test.questions.length <= 2) {
                return;
            }

            setTimeout(() => {
                const wrapperBlock = document.querySelectorAll(".test-content-item");
                wrapperBlock[wrapperBlock.length - 1].scrollIntoView({ behavior: "smooth", block: "center" });
            }, 100);
        };

        const onEdit = (index: number, question: TestRequestQuestion) => {
            const questions = Object.assign(test.questions);

            questions[index] = question;
            onChange({ ...test, questions: questions });
        };

        const onCopy = (question: TestRequestQuestion) => {
            const questions: TestRequestQuestion[] = Object.assign(test.questions);

            const copy: TestRequestQuestion = JSON.parse(JSON.stringify(question));
            copy.logoId = question.logoId;

            question.answers.forEach((answer, answerIndex) => {
                if (
                    copy.answers[answerIndex].pictureId &&
                    copy.answers[answerIndex].pictureId.file &&
                    !Object.keys(copy.answers[answerIndex].pictureId.file).length
                ) {
                    copy.answers[answerIndex].pictureId.file = answer.pictureId.file;
                }
            });

            copy.id = test.questions.length.toString();
            questions.push(copy);

            onChange({ ...test, questions });
            setCurrentIndex(questions.length - 1);

            if (test.questions.length > 2) {
                const wrapperBlock = document.querySelectorAll(".test-content-item");
                wrapperBlock[wrapperBlock.length - 1].scrollIntoView({ behavior: "smooth", block: "start" });
            }
        };

        const onDelete = (question: TestRequestQuestion) => {
            if (
                !question.questionText &&
                !question.answerNote &&
                !question.correctAnswer &&
                question.answers.filter((p) => p.pictureId || p.answerText).length === 0
            ) {
                const questions = Object.assign(test.questions);
                questions.splice(test.questions.indexOf(question), 1);

                onChange({ ...test, questions });
                return;
            }

            openDialog({
                title: "Удаление вопроса",
                description: question.questionText ? "«" + question.questionText + "»" : "",
                content: "Вы действительно хотите удалить вопрос?",
                closeBtnText: "Отмена",
                submitBtnText: "Удалить",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    const questions = Object.assign(test.questions);
                    questions.splice(test.questions.indexOf(question), 1);

                    onChange({ ...test, questions });
                    closeDialog();
                },
            });
        };

        const onDragOver = (event: DragEndEvent) => {
            const { active, over } = event;

            if (active.id === over?.id) {
                return;
            }

            const questions: TestRequestQuestion[] = Object.assign(test.questions);

            const oldIndex = questions.findIndex((p) => p.id === active.id);
            const newIndex = questions.findIndex((p) => p.id === over?.id);

            onChange({ ...test, questions: arrayMove(questions, oldIndex, newIndex) });
        };

        return (
            <>
                <Confirmation {...dialogState} />
                <Button
                    className="fixed z-10 bottom-6.5 right-6.5 flex justify-center items-center"
                    onClick={onCreate}
                    id="adminNewTesttestingTabAddSection"
                    shape="round"
                    size="xl"
                >
                    <Icon icon={Icons.Plus} width={26} height={26} color="fill-white" />
                </Button>
                {solutionsOnReview && (
                    <div className={clsx("py-6 border-blue-gray", test.questions.length !== 0 ? "border-b" : "")}>
                        <div className="flex items-center gap-1.5 py-2.5 px-4 rounded-2lg border border-yellow">
                            <Icon icon={Icons.Warning} width={24} height={24} color="fill-yellow" />
                            Изменения на вкладке «Содержимое» не будут сохранены, потому что кто-то уже прошел тест и
                            отправил его на
                            <a
                                href={`/admin/validations?tab=tests&testTitle=${test.title}&testId=${test.id}`}
                                target="_blank"
                                className="text-current underline"
                                rel="noreferrer"
                            >
                                проверку
                            </a>
                        </div>
                    </div>
                )}
                {test.questions.length === 0 && (
                    <Empty
                        title="В тесте пока нет вопросов"
                        description="Создавайте любые вопросы, назначайте на них баллы из которых будет формироваться рейтинг. Чтобы создать вопрос, нажмите на плюс в правом нижнем углу экрана"
                        className="h-[calc(100vh-244px)]"
                    />
                )}
                {test.questions.length !== 0 && (
                    <div className={classNames("mb-20", { isElementDragging: "overflow-hidden" })}>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragOver={(event: DragEndEvent) => onDragOver(event)}
                            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                        >
                            <SortableContext
                                strategy={verticalListSortingStrategy}
                                items={test.questions.map((item) => item.id as UniqueIdentifier)}
                            >
                                {test.questions.map((question, index) => {
                                    return (
                                        <TestContentItem
                                            key={index}
                                            index={index}
                                            complexity={complexity}
                                            question={question}
                                            current={currentIndex}
                                            setCurrent={(index) => {
                                                setCurrentIndex(index);

                                                if (!index) {
                                                    return;
                                                }

                                                setTimeout(() => {
                                                    const wrapperBlock =
                                                        document.querySelectorAll(".test-content-item");
                                                    wrapperBlock[index].scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "center",
                                                    });
                                                }, 100);
                                            }}
                                            onEdit={onEdit}
                                            onCopy={onCopy}
                                            onDelete={onDelete}
                                            errors={
                                                errors["questions"] &&
                                                typeof errors["questions"] !== "string" &&
                                                errors["questions"][question.id!]
                                                    ? errors["questions"][question.id!]
                                                    : {}
                                            }
                                        />
                                    );
                                })}
                            </SortableContext>
                        </DndContext>
                    </div>
                )}
            </>
        );
    },
);
