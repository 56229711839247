import React from "react";

interface ITestingHeaderLinear {
    count: number;
    current: number;
}
export const TestingHeaderLinear = ({ count, current }: ITestingHeaderLinear) => {
    return (
        <div className="mt-3 sm:mt-15 2xl:mt-[75px] mb-5 2xl:mb-6.25 w-full sm:w-175 2xl:w-[875px]">
            <div className="flex items-center mb-3 2xl:mb-3.75">
                <h3 className="mr-1 2xl:mr-1.5 text-gray-dark 2xl:!text-lg 2xl:!leading-[30px]">
                    Вопрос {current + 1}
                </h3>
                <span className="text-gray 2xl:text-md">из {count}</span>
            </div>
            <div className="w-full h-1 2xl:h-1.5 bg-background rounded-lg 2xl:rounded-2lg">
                <div
                    className="h-1 2xl:h-1.5 bg-blue rounded-lg 2xl:rounded-2lg"
                    style={{ width: `${((current + 1) * 100) / count}%` }}
                ></div>
            </div>
        </div>
    );
};
