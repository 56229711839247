import React from "react";

interface ITeamUserAvatarProps {
    avatarId: string;
    defaultAvatarColor: string;
    firstName: string;
    lastName: string;
    size?: "normal" | "large";
}

export const TeamUserAvatar = ({
    avatarId,
    defaultAvatarColor,
    firstName,
    lastName,
    size = "normal",
}: ITeamUserAvatarProps) => {
    const sizes = {
        normal: "w-5 h-5 2xl:w-6.25 2xl:h-6.25",
        large: "w-9 2xl:w-[45px] h-9 2xl:h-[45px]",
    };
    const rounds = {
        normal: "rounded-[10px]",
        large: "rounded-[18px] 2xl:rounded-full",
    };
    const shapeClass = `${sizes[size]} rounded-full`;

    return (
        <div className={`-mr-1.5 2xl:-mr-2 outline outline-1 outline-white ${rounds[size]}`}>
            {avatarId ? (
                <img src={`/service/lms-upload/api/file/download/${avatarId}`} className={shapeClass} />
            ) : (
                <div
                    className={`flex-center ${shapeClass}`}
                    style={{ backgroundColor: `${defaultAvatarColor ?? "#0084E2"}` }}
                >
                    <span className="text-white text-xxs 2xl:text-sm">
                        {firstName[0]}
                        {lastName[0]}
                    </span>
                </div>
            )}
        </div>
    );
};
