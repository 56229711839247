import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import { Breadcrumbs } from "Uikit";
import { PromptModal } from "Uikit/Modal/PromptModal";
import { CategoryModal } from "Uikit/CategoryModalNew/CategoryModal";
import { ContentLayout, TreeWrapperContext } from "Containers";
import { MaterialsTable } from "./MaterialsTable";
import { EmptyMaterialList } from "./EmptyMaterialList";
import { MaterialListRequest } from "Api/Requests/MaterialRequest";
import { MaterialCategoryTreeResponse } from "Api/Responses/MaterialCategoryResponse";
import { MaterialCategoryEditRequest } from "Api/Requests/MaterialCategoryRequest";
import Api from "Api";

const NEW_CATEGORY = {};
const PAGINATION_COUNT = 15;

export const Materials = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { setTreeProps, sidebarApi } = useContext(TreeWrapperContext);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    const [treeData, setTreeData] = useState<{ id: string; name: string; nodeType: string; children: any[] }[]>([]);

    const [editingCategory, setEditingCategory] = useState<Partial<MaterialCategoryTreeResponse> | null>(null);
    const [deletingCategory, setDeletingCategory] = useState<Partial<MaterialCategoryTreeResponse> | null>(null);
    const [checkedCategoryIds, setCheckedCategoryIds] = useState<string[]>([]);

    const [sorting, setSorting] = useState<SortingState>([{ id: "modifyTimestamp", desc: true }]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});

    const [selectedCategory, setSelectedCategory] = useState<Partial<{
        id: string;
        name: string;
        nodeType: string;
        children: any[];
    }> | null>(null);

    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGINATION_COUNT,
    });

    const fetchMaterialsList = async (count: number, offset: number, _sorting: any, _filters?: any) => {
        const materialsRequest = new MaterialListRequest() as any;
        materialsRequest.size = offset;
        materialsRequest.page = count;
        materialsRequest.sort =
            _sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "modifyTimestamp,desc";

        const filters = Object.keys(_filters).reduce((acc: any, key) => {
            if (_filters[key] !== null) {
                acc[key] = _filters[key];
            }

            return acc;
        }, {});

        filters["state.in"] = "ACTIVE,HIDDEN";

        const res = await Api.Material.List(materialsRequest.page, materialsRequest.size, _sorting, filters);

        return {
            rows: res.Content,
            pageCount: Math.ceil(res.Total / res.Size),
        };
    };

    const dataQuery = useQuery(
        ["materials", "collection", pagination, sorting, filters, selectedCategory, checkedCategoryIds],
        () => {
            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "ratingPoints") {
                    console.log(filters[element]);
                }
                if (element === "modifyTimestamp") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (element === "ratingPoints") {
                    if (filters[element]["minValue"]) {
                        filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    }
                    if (filters[element]["maxValue"]) {
                        filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                    }
                } else if (element === "managerUserId.in" && Array.isArray(filters[element])) {
                    console.log(filters[element]);

                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "type.in" && Array.isArray(filters[element])) {
                    filtersData["type.in"] = filters[element].map((p: any) => p.value).join(",");
                } else {
                    filtersData[element] = filters[element];
                }
            }

            if (checkedCategoryIds.length > 0) {
                filtersData["categoryId.in"] = checkedCategoryIds.join(",");
            } else if (selectedCategory?.id) {
                filtersData["categoryId.in"] = selectedCategory.id;
            } else {
                filtersData["categoryId.in"] = null;
            }

            return fetchMaterialsList(pagination.pageIndex, pagination.pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const handleAddCategory = useCallback(() => {
        setEditingCategory(NEW_CATEGORY);
    }, [setEditingCategory]);

    const handleEditCategory = useCallback((title: string, id: string) => {
        setEditingCategory({ id, title });
    }, []);

    const handleDeleteCategory = useCallback(
        (title: string, id: string) => {
            setDeletingCategory({ id, title });
        },
        [setDeletingCategory],
    );

    const handleNavigate = useCallback(
        (node: any) => {
            if (node?.id) {
                navigate(`/admin/materials?categoryId=${node.id}`);
            } else {
                navigate(`/admin/materials`);
            }
        },
        [navigate],
    );

    const onOrderChange = async (nodes: any) => {
        try {
            await Api.MaterialCategory.Reorder({ categoryIds: nodes.map((item: any) => item.id) });

            setTreeData(nodes);
        } catch (error) {
            console.log(error);
        }
    };

    const onCheckedChange = (nodesIds: any) => {
        setCheckedCategoryIds(nodesIds);
    };

    const handleCloseCategoryModal = () => {
        setEditingCategory(null);
    };

    const handleDeleteCategoryClose = () => {
        setDeletingCategory(null);
    };

    const onCategorySubmit = async (title: string) => {
        try {
            if (editingCategory?.id) {
                const request = new MaterialCategoryEditRequest();
                request.id = editingCategory.id;
                request.title = title;
                await Api.MaterialCategory.Edit(request);

                setTreeData((prevState) =>
                    (prevState || []).map((item) => {
                        if (item.id === editingCategory.id) {
                            return { ...item, name: title };
                        }

                        return item;
                    }),
                );
                setTreePropsData([
                    ...treeData.map((item: any) => {
                        if (item.id === editingCategory.id) {
                            return { ...item, name: title };
                        }

                        return item;
                    }),
                ]);
            } else {
                const response = await Api.MaterialCategory.Create({ title });

                setTreeData((prevState) => [
                    ...(prevState || []),
                    { id: response.id, name: response.title, nodeType: "PROJECT", children: [] },
                ]);
                setTreePropsData([...treeData, { id: response.id, name: response.title, nodeType: "PROJECT" }]);
                navigate(`/admin/materials?categoryId=${response.id}`);
            }
        } catch (error) {
            return error;
        }
    };

    const handleConfirmDeleteCategory = async () => {
        if (deletingCategory?.id) {
            await Api.MaterialCategory.Remove({ uuid: deletingCategory.id });
            await dataQuery.refetch();

            setTreeData((prevState) => (prevState || []).filter((item) => item.id !== deletingCategory.id));
            setTreePropsData([...treeData.filter((item: any) => item.id !== deletingCategory.id)]);
        }
        handleDeleteCategoryClose();
        navigate("/admin/materials", { replace: true });
    };

    const treeProps = useMemo(() => {
        return {
            searchable: true,
            editable: true,
            selectable: true,
            browseAll: {
                link: "/admin/materials",
                title: "Все материалы",
                onAddClick: () => {
                    console.log("создать материал");
                },
            },
            nodeAddList: [
                { name: "Статья", type: "ARTICLE" },
                { name: "Видео", type: "VIDEO" },
                { name: "Документ", type: "DOCUMENT" },
                { name: "SCORM", type: "SCORM" },
            ],
            onNodeAddClick: (item: any, nodeId: string) => {
                navigate(`/admin/material/add/${item.type}?categoryId=${nodeId}`);
            },
            onEditClick: handleEditCategory,
            onDeleteClick: handleDeleteCategory,
            onSelectNode: handleNavigate,
            onOrderChange: onOrderChange,
            onCheckedChange: onCheckedChange,
            getNodeLink: (id: any) => `/admin/materials?categoryId=${id}`,
            footer: {
                title: "Создать категорию",
                action: handleAddCategory,
            },
            id: "adminMaterialsSideBar",
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, handleAddCategory, handleEditCategory, handleDeleteCategory, handleNavigate]);

    const setTreePropsData = useCallback(
        (data: any) => {
            setTreeProps?.({ ...treeProps, data });
        },
        [setTreeProps, treeProps],
    );

    const onCreateHandler = (e: MouseEvent, name: string) => {
        navigate(`/admin/material/add/${name}`);
    };

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps({
            ...treeProps,
            ...{
                data: treeData,
                selectedNode: selectedCategory,
            },
        });

        return () => {
            setTreeProps?.({ data: null });
        };
    }, [setTreeProps, treeProps, treeData, selectedCategory]);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setSelectedCategory(treeData?.find((item) => item.id === params.get("categoryId")) ?? null);
    }, [location, treeData]);

    useEffect(() => {
        const fetch = async () => {
            const categories = await Api.MaterialCategory.List();
            const categoriesTree = categories.Content.map((cateogry) => ({
                id: cateogry.id,
                name: cateogry.title,
                nodeType: "PROJECT",
                children: [],
            }));

            setTreeData(categoriesTree);
            setIsFetched(true);
        };
        fetch().then();

        const sbApi = sidebarApi?.current;
        return () => {
            sbApi?.setCheckedBucket?.({});
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: PAGINATION_COUNT,
        });
        setFilters({});
    }, [selectedCategory]);

    return (
        <ContentLayout className="h-full">
            <Breadcrumbs className="mb-2.5" id="adminMaterialBreadcrumbs">
                <Breadcrumbs.Link title={"Администратор"} />
                <Breadcrumbs.Link title={"Материалы"} url={"/admin/materials"} />
                {selectedCategory?.id ? <span>{selectedCategory?.name}</span> : null}
            </Breadcrumbs>
            {isFetched && treeData?.length === 0 && (
                <div className="h-full flex justify-center">
                    <EmptyMaterialList onCreateCategory={handleAddCategory} onCreateMaterial={onCreateHandler} />
                </div>
            )}
            {isFetched && treeData && treeData.length > 0 && (
                <MaterialsTable
                    categoryId={selectedCategory?.id}
                    materials={dataQuery.data?.rows ?? []}
                    refetchMaterials={dataQuery.refetch}
                    pageCount={dataQuery.data?.pageCount ?? 0}
                    pagination={pagination}
                    sorting={sorting}
                    filters={filters}
                    setPagination={setPagination}
                    setSorting={setSorting}
                    setFilters={setFilters}
                    isFetching={dataQuery.isFetching}
                    handleAddCategory={handleAddCategory}
                />
            )}
            <CategoryModal
                isOpen={!!editingCategory}
                onClose={handleCloseCategoryModal}
                categoryId={editingCategory?.id}
                categoryName={editingCategory?.title}
                onSubmit={onCategorySubmit}
            />

            <PromptModal
                onConfirm={handleConfirmDeleteCategory}
                onClose={handleDeleteCategoryClose}
                isOpen={!!deletingCategory}
                title="Удаление категории"
                dialogText={["Отмена", "Удалить"]}
            >
                <div className="space-y-3.5">
                    <div className="text-gray-text">&quot;{deletingCategory?.title}&quot;</div>
                    <div>Вы действительно хотите удалить категорию? Все вложенные в нее элементы попадут в Архив</div>
                </div>
            </PromptModal>
        </ContentLayout>
    );
};
