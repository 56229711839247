import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "Uikit";
import { TestsCard } from "./TestsCard";
import { TestUserListResponse } from "Api/Responses/TestResponse";

interface ITestsCardList {
    isFinished: boolean;
    isReview: boolean;
    title?: string;
    data: TestUserListResponse[];
    controlDisplayedItemsLength?: boolean;
    statusChangeTimePre?: string;
    showStatusChangeTime?: boolean;
    id?: string;
    onFavoriteChange: (test: TestUserListResponse, isFavorite: boolean) => void;
}
export const TestsCardList = ({
    isFinished,
    isReview,
    title,
    data,
    statusChangeTimePre,
    controlDisplayedItemsLength = false,
    showStatusChangeTime = false,
    id,
    onFavoriteChange,
}: ITestsCardList) => {
    const navigate = useNavigate();
    const [displayedItemsLength, setDisplayedItemsLength] = useState(controlDisplayedItemsLength ? 8 : data.length);

    const displayedItems = data.slice(0, displayedItemsLength);

    useEffect(() => {
        setDisplayedItemsLength(controlDisplayedItemsLength ? 8 : data.length);
    }, [controlDisplayedItemsLength, data]);

    return (
        <div className="mb-6 sm:mb-8 2xl:mb-9">
            {title && (
                <div className="flex items-center gap-5 2xl:gap-6.25 mb-5">
                    <h2 id={id + "Title"} className="hidden sm:block 2xl:!text-2xl 2xl:!leading-[35px]">
                        {title}
                    </h2>
                    <h3 id={id + "Title"} className="block sm:hidden">
                        {title}
                    </h3>
                    <span className="hidden sm:block p4 text-disabled-stroke 2xl:text-sm">Всего {data.length}</span>
                </div>
            )}
            <div
                className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] sm:gap-x-6.5 2xl:gap-x-8 gap-y-8 2xl:gap-y-9"
                id={id + "Section"}
            >
                {displayedItems.map((test) => {
                    let statusChangeTimePreValue = statusChangeTimePre;

                    if (!statusChangeTimePre) {
                        if (test.progressStatus === "PASSED") {
                            statusChangeTimePreValue = "Пройден";
                        } else if (test.progressStatus === "FAILED") {
                            statusChangeTimePreValue = "Провален";
                        } else {
                            statusChangeTimePreValue = "Отправлен";
                        }
                    }

                    return (
                        <TestsCard
                            key={test.id + test.statusChangeTime}
                            isFinished={isFinished}
                            showStatusChangeTime={showStatusChangeTime}
                            statusChangeTimePre={statusChangeTimePreValue}
                            test={test}
                            onClick={() =>
                                isFinished || isReview
                                    ? navigate(`/test/${test.resourceId}/statistics`)
                                    : navigate(`/test/${test.resourceId}?parent=tests`)
                            }
                            onFavoriteChange={onFavoriteChange}
                        />
                    );
                })}
            </div>
            {controlDisplayedItemsLength && data.length > displayedItems.length && (
                <div className="mb-10 sm:mt-8 2xl:mt-9 sm:mb-0 flex justify-center">
                    <Button
                        variant="outline"
                        className="w-full rounded-lg whitespace-nowrap font-medium !text-black !border-gray-stroke active:!bg-transparent"
                        onClick={() => {
                            setDisplayedItemsLength((prevState) => {
                                return prevState + 8;
                            });
                        }}
                    >
                        Посмотреть еще
                    </Button>
                </div>
            )}
        </div>
    );
};
