import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Link } from "react-router-dom";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import classNames from "classnames";
import { useFormikContext, FormikValues } from "formik";
import isEqual from "lodash/isEqual";
import { TaskItemType } from "Enums";
import { useDialog } from "hooks/useDialog";
import { Button, Icon, Icons } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { TaskReadResponse, TaskReadResponseItem } from "Api/Responses/TaskResponse";
import { TaskContentItem } from "./TaskContentItem";

interface ITaskContent {
    task: TaskReadResponse;
    onChange: (task: TaskReadResponse, watchDirty?: boolean) => void;
}

type TTaskContentItems = {
    textContent?: TaskReadResponseItem[];
    numericContent?: TaskReadResponseItem[];
    imageContent?: TaskReadResponseItem[];
    fileContent?: TaskReadResponseItem[];
    singleChoiceContent?: TaskReadResponseItem[];
    multipleChoiceContent?: TaskReadResponseItem[];
};

export const TaskContent = forwardRef(({ task, onChange }: ITaskContent, ref) => {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const [isItemsOrdered, setIsItemsOrdered] = useState(true);
    const [isContentItemAdded, setIsContentItemAdded] = useState(false);
    const [currentTaskItem, setCurrentTaskItem] = useState<TaskReadResponseItem | undefined>(undefined);
    const { setFieldValue, values, errors } = useFormikContext();
    // не в MVP
    // const [displayMobileNotification, setDisplayMobileNotification] = useState(false);
    const {
        textContent,
        numericContent,
        imageContent,
        fileContent,
        singleChoiceContent,
        multipleChoiceContent,
        hasUserAnswersOnReview,
        id: taskId,
    } = task;

    const [allTaskItems, setAllTaskItems] = useState<TaskReadResponseItem[]>(
        [
            ...textContent,
            ...numericContent,
            ...imageContent,
            ...fileContent,
            ...singleChoiceContent,
            ...multipleChoiceContent,
        ].sort((a, b) => a.orderNumber - b.orderNumber),
    );
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    useImperativeHandle(ref, () => ({
        collapseTaskItem() {
            setCurrentTaskItem(undefined);
        },
        resetAllTaskItems(taskItems: TaskReadResponseItem[]) {
            setAllTaskItems(taskItems);
        },
    }));

    const taskContentMapping = {
        [TaskItemType.Text]: "textContent",
        [TaskItemType.Number]: "numericContent",
        [TaskItemType.Image]: "imageContent",
        [TaskItemType.File]: "fileContent",
        [TaskItemType.SingleChoice]: "singleChoiceContent",
        [TaskItemType.MultipleChoice]: "multipleChoiceContent",
    };

    const getNewTaskItem = (init?: TaskReadResponseItem): TaskReadResponseItem => {
        return {
            id: "",
            contentType: TaskItemType.SingleChoice,
            question: "",
            orderNumber: allTaskItems.length,
            description: "",
            reviewerNote: "",
            answers: [],
            mandatory: true,
            allowScan: false,
            onlyCameraPhotos: false,
            ...init,
        };
    };

    const onCreateTaskItem = () => {
        const newItem = getNewTaskItem();

        setAllTaskItems([...allTaskItems, newItem]);
        setCurrentTaskItem(newItem);
        setFieldValue("singleChoiceContent", [...(values as FormikValues)["singleChoiceContent"], newItem]).then();

        setIsItemsOrdered(false);
        setIsContentItemAdded(true);
    };

    const onEditTaskItem = (updatedItem: TaskReadResponseItem, updatedFields?: [key: string, value: any]) => {
        const updatedAllTaskItems = allTaskItems.map((item) => {
            if (item.orderNumber === updatedItem.orderNumber && item.id === updatedItem.id) {
                return updatedItem;
            }
            return item;
        });

        setAllTaskItems(updatedAllTaskItems);

        const updatedTypeItems = updatedAllTaskItems.filter((item) => item.contentType === updatedItem.contentType);
        const contentKey = taskContentMapping[updatedItem.contentType] as keyof TaskReadResponse;
        if (updatedFields) {
            const [key, value] = updatedFields;
            const updatedItemIndex = updatedTypeItems.findIndex((item) => isEqual(item, updatedItem));
            setFieldValue(`${contentKey}.${updatedItemIndex}.${key}`, value).then();
        }

        onChange({ ...task, [contentKey]: updatedTypeItems });
    };

    const onChangeTaskItemType = (newTaskItem: TaskReadResponseItem, oldTaskItem: TaskReadResponseItem) => {
        const oldContentKey = taskContentMapping[oldTaskItem.contentType] as keyof TaskReadResponse;
        const newContentKey = taskContentMapping[newTaskItem.contentType] as keyof TaskReadResponse;

        setFieldValue(
            oldContentKey,
            (task[oldContentKey] as TaskReadResponseItem[])?.filter(
                (item) => item.orderNumber !== oldTaskItem.orderNumber,
            ),
        ).then();
        setFieldValue(newContentKey, [...(task[newContentKey] as TaskReadResponseItem[]), newTaskItem]).then();

        const taskUpdated = {
            ...task,
            [oldContentKey]: (task[oldContentKey] as TaskReadResponseItem[])?.filter((item) => {
                return item.orderNumber !== oldTaskItem.orderNumber;
            }),
            [newContentKey]: [...(task[newContentKey] as TaskReadResponseItem[]), newTaskItem],
        };

        onChange(taskUpdated);
    };

    const deleteTaskItem = (deletedItem: TaskReadResponseItem) => {
        const deletedContentKey = taskContentMapping[deletedItem.contentType] as keyof TaskReadResponse;

        onChange({
            ...task,
            [deletedContentKey]: (task[deletedContentKey] as TaskReadResponseItem[])?.filter(
                (item) => !isEqual(item, deletedItem),
            ),
        });

        setFieldValue(
            deletedContentKey,
            (task[deletedContentKey] as TaskReadResponseItem[])?.filter((item) => !isEqual(item, deletedItem)),
        ).then();

        setAllTaskItems((prevItems) => {
            return prevItems
                .filter((item) => {
                    return item.orderNumber !== deletedItem.orderNumber;
                })
                .map((item, index) => {
                    return {
                        ...item,
                        orderNumber: index,
                    };
                });
        });

        setIsItemsOrdered(false);
    };

    const onDeleteTaskItem = (deletedItem: TaskReadResponseItem, isItemChanged: boolean) => {
        if (isItemChanged) {
            openDialog({
                title: "Удаление вопроса",
                description: deletedItem.question ? "«" + deletedItem.question + "»" : "",
                content: "Вы действительно хотите удалить вопрос?",
                closeBtnText: "Отмена",
                submitBtnText: "Удалить",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    deleteTaskItem(deletedItem);
                    closeDialog();
                },
            });
        } else {
            deleteTaskItem(deletedItem);
        }
    };

    const handleCopyItem = async (taskItem: TaskReadResponseItem) => {
        const { orderNumber, contentType } = taskItem;
        const copyIndex = orderNumber + 1;

        // Копирование полей для валидации
        const contentTypeValues = (values as FormikValues)?.[taskContentMapping[contentType]];
        const prevValues = contentTypeValues.slice(0, copyIndex);
        const nextValues = contentTypeValues.slice(copyIndex);
        await setFieldValue(taskContentMapping[contentType], [...prevValues, taskItem, ...nextValues]);

        setAllTaskItems((prevItems) => {
            const prevs = prevItems.slice(0, copyIndex);
            const next = prevItems.slice(copyIndex);

            const copy = {
                ...taskItem,
                orderNumber: copyIndex,
            };

            if (taskItem.answers) {
                copy.answers = taskItem.answers.map((item) => {
                    return structuredClone(item);
                });
            }

            setCurrentTaskItem(copy);
            setIsItemsOrdered(false);

            return [
                ...prevs,
                {
                    ...copy,
                },
                ...next.map((item) => {
                    return {
                        ...item,
                        orderNumber: item.orderNumber + 1,
                    };
                }),
            ];
        });

        const wrapperBlock = document.querySelectorAll(".task-content-item");
        wrapperBlock[copyIndex].scrollIntoView({ behavior: "smooth", block: "start" });
    };

    useEffect(() => {
        if (!isItemsOrdered) {
            const updatedItems: TTaskContentItems = {};
            Object.values(taskContentMapping).forEach((val) => {
                updatedItems[val as keyof TTaskContentItems] = [];
            });
            [
                ...allTaskItems.map((item, index) => {
                    return {
                        ...item,
                        orderNumber: index,
                    };
                }),
            ].forEach((item) => {
                const key = taskContentMapping[item.contentType] as keyof TTaskContentItems;
                updatedItems[key] = [...(updatedItems[key] as TaskReadResponseItem[]), item];
            });

            onChange({ ...task, ...updatedItems });
            setIsItemsOrdered(true);
        }

        // Перемотка экрана к добавленному вопросу
        if (isContentItemAdded) {
            const wrapperBlock = document.querySelectorAll(".task-content-item");
            wrapperBlock[wrapperBlock.length - 1].scrollIntoView({ behavior: "smooth", block: "start" });
            setIsContentItemAdded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allTaskItems]);

    // не в MVP
    // useEffect(() => {
    //     setDisplayMobileNotification(task.geoLocationRequired || task.imageContent.some(({ onlyCameraPhotos }) => onlyCameraPhotos));
    // }, [task])

    return (
        <>
            <Confirmation {...dialogState} />
            <Button
                className="fixed z-10 bottom-6.5 right-6.5 flex justify-center items-center"
                onClick={onCreateTaskItem}
                id="adminNewTaskContentTabBtnAddSection"
                shape="round"
                size="xl"
            >
                <Icon icon={Icons.Plus} width={26} height={26} color="fill-white" />
            </Button>
            {allTaskItems.length === 0 && (
                <Empty
                    title={"В задании пока ничего нет"}
                    description={
                        "Добавляйте разные поля и создавайте форму задания. Чтобы создать поле, нажмите на плюс в правом нижнем углу экрана"
                    }
                    className="h-[calc(100vh-244px)]"
                />
            )}
            {allTaskItems.length !== 0 && (
                <div className={classNames("mb-20", { isElementDragging: "overflow-hidden" })}>
                    {hasUserAnswersOnReview && (
                        <div className="inline-flex items-center gap-1.5 border border-yellow mt-6 px-4.5 py-2.5 rounded-lg">
                            <Icon icon={Icons.Warning} width={24} height={24} color="fill-yellow" />
                            <span>
                                Изменения на вкладке «Содержимое» не будут сохранены, потому что кто-то уже прошел
                                задание и отправил его на{" "}
                                <Link target="_blank" rel="noopener noreferrer" to={`/admin?tab=tasks&task=${taskId}`}>
                                    проверку
                                </Link>
                            </span>
                        </div>
                    )}
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    >
                        <SortableContext
                            items={allTaskItems.map((item) => {
                                return item.orderNumber + 1;
                            })}
                            strategy={verticalListSortingStrategy}
                        >
                            {allTaskItems.map((taskItem, index) => {
                                const taskItemTypeItems = allTaskItems.filter(
                                    (item) => item.contentType === taskItem.contentType,
                                );
                                const taskItemItemIndex = taskItemTypeItems.findIndex((item) =>
                                    isEqual(item, taskItem),
                                );
                                const contentKey = taskContentMapping[taskItem.contentType] as keyof TaskReadResponse;
                                const contentItemErrors = (errors as any)?.[contentKey]?.[taskItemItemIndex];

                                return (
                                    <TaskContentItem
                                        key={`taskItem_${taskItem.orderNumber}`}
                                        index={index}
                                        taskItem={taskItem}
                                        onEditTaskItem={onEditTaskItem}
                                        onDeleteTaskItem={(isItemChanged: boolean) => {
                                            onDeleteTaskItem(taskItem, isItemChanged);
                                        }}
                                        onIsDraggingChange={() => {}}
                                        currentTaskItem={currentTaskItem}
                                        setCurrentTaskItem={setCurrentTaskItem}
                                        handleCopyItem={handleCopyItem}
                                        errors={contentItemErrors ?? {}}
                                        onChangeTaskItemType={onChangeTaskItemType}
                                    />
                                );
                            })}
                        </SortableContext>
                    </DndContext>
                </div>
            )}
        </>
    );

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;
        if (active.id !== over?.id) {
            setAllTaskItems((items) => {
                const oldIndex = items.findIndex((i: TaskReadResponseItem) => i.orderNumber + 1 === active.id);
                const newIndex = items.findIndex((i: TaskReadResponseItem) => i.orderNumber + 1 === over?.id);
                setIsItemsOrdered(false);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }
});
