import React, { ReactNode } from "react";
import { toast, ToastOptions } from "react-toastify";
import { Notification } from "Uikit/Notification/Notification";
import { ToastPosition } from "react-toastify/dist/types";

type TShowText = string | ReactNode;

interface ShowProps extends ToastOptions {
    text: TShowText;
    type: "error" | "success" | "info";
    options?: ToastOptions;
}

export const flash = {
    show({ text, type, position = "bottom-right", options }: ShowProps) {
        toast(
            ({ closeToast }) => {
                if (typeof text === "string") {
                    return <Notification text={text} type={type} onClose={closeToast} />;
                }
                return (
                    <Notification type={type} onClose={closeToast}>
                        {text}
                    </Notification>
                );
            },
            {
                position,
                ...options,
            },
        );
    },

    error(text: TShowText, position?: ToastPosition, options?: ToastOptions) {
        this.show({ text, type: "error", position, options });
    },

    success(text: TShowText, position?: ToastPosition, options?: ToastOptions) {
        this.show({ text, type: "success", position, options });
    },

    info(text: TShowText, position?: ToastPosition, options?: ToastOptions) {
        this.show({ text, type: "info", position, options });
    },
};
