import React from "react";
import { NoticeProps } from "react-select";
import { Loader } from "Uikit/Loader/Loader";

// Кастомный компонент для react-select
// Прелоадер для выполняемого запроса
export const CustomLoadingMessage = (props: NoticeProps) => {
    return (
        <div {...props.innerProps} className="w-full py-3 flex-center">
            <Loader size={28} />
        </div>
    );
};
