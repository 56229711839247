import React, { ReactNode } from "react";
import { Icon, Icons } from "../Icon/Icon";
import clsx from "clsx";
import { TVoidFunction } from "types";

interface NotificationProps {
    text?: string;
    type: keyof typeof NotificationParams;
    onClose?: TVoidFunction;
    children?: ReactNode;
}

export const NotificationParams = {
    success: {
        color: "bg-[#1380CE]",
        iconColor: "fill-white",
        icon: Icons.Check,
    },
    error: {
        color: "bg-[#FF6872]",
        iconColor: "fill-white",
        icon: Icons.Ban,
    },
    info: {
        color: "bg-[#1380CE]",
        iconColor: "fill-white",
        icon: Icons.Info,
    },
};

export const Notification = ({ text, type, onClose, children }: NotificationProps) => {
    return (
        <div
            className={clsx(
                "flex items-center p-4 w-full max-w-lg text-white rounded-lg gap-4",
                NotificationParams[type].color,
            )}
            role="alert"
        >
            <div>
                <Icon
                    icon={NotificationParams[type].icon}
                    color={NotificationParams[type].iconColor}
                    width={24}
                    height={24}
                />
            </div>
            <div className="p2">{text ?? children}</div>
            <div onClick={onClose} className={"ml-auto -mx-1.5 -my-1.5 p-1.5 cursor-pointer"}>
                <Icon icon={Icons.Close} color="fill-white" width={12} height={12} />
            </div>
        </div>
    );
};
