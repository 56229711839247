import { Expose, Type } from "class-transformer";
import { ProgressStatus, Complexity, ResourceState, TaskItemType } from "Enums";
import { BaseResponse } from "../BaseResponse";
import { AccountableUser } from "./UserResponse";
import { TaskReadResponseItem } from "./TaskResponse";

class TaskCategory {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;
}

/* 
 "passingLimit": null,
        "passingNumber": 1,
*/

export class TaskPublicListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => Number)
    public deadlineTime: number;

    @Expose()
    @Type(() => TaskCategory)
    public category: TaskCategory;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Boolean)
    public hasNewLabel: boolean;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public statusChangeTime: number;

    @Expose()
    @Type(() => Number)
    public passingLimit: number | null;

    @Expose()
    @Type(() => Number)
    public passingNumber: number;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;
}

export class TaskPublicNewCountResponse extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public allTab: number;

    @Expose()
    @Type(() => Number)
    public resultsTab: number;
}

class TaskComment {
    @Expose()
    @Type(() => AccountableUser)
    public user: AccountableUser;

    @Expose()
    @Type(() => Number)
    public checkedTime: number;

    @Expose()
    @Type(() => Boolean)
    public isApproved: boolean;

    @Expose()
    @Type(() => String)
    public comment: string;
}

export class TaskPublicPreviewResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => Number)
    public questionCount: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public passingNumber: number;

    @Expose()
    @Type(() => Number)
    public availablePassingCount: number;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => TaskComment)
    public comment: TaskComment;

    @Expose()
    @Type(() => Number)
    public statusChangeTime: number;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => Number)
    public deadlineTime: number | null;
}

class LastCheckedComment {
    @Expose()
    @Type(() => Number)
    public checkedTime: number;

    @Expose()
    @Type(() => Boolean)
    public isApproved: boolean;

    @Expose()
    @Type(() => String)
    public comment: string;

    @Expose()
    @Type(() => AccountableUser)
    public user: AccountableUser;
}

export class TaskUser {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor?: string;
}

export class TaskPublicPassingResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public attemptId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => String)
    public complexity: Complexity;

    @Expose()
    @Type(() => String)
    public exerciseId: string;

    @Expose()
    @Type(() => TaskUser)
    public user: TaskUser;

    @Expose()
    @Type(() => Number)
    public statusChangeTime: number;

    @Expose()
    @Type(() => Number)
    public passingNumber: number;

    @Expose()
    @Type(() => TaskReadResponseItem)
    public textContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public numericContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public imageContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public fileContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public singleChoiceContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public multipleChoiceContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => String)
    public checkComment: string;

    @Expose()
    @Type(() => AccountableUser)
    public checkedBy: AccountableUser;

    @Expose()
    @Type(() => Boolean)
    public geoLocationRequired: boolean;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => LastCheckedComment)
    public lastCheckedComment: LastCheckedComment;
}

export type TaskPublicAttemptsContent = {
    id: string;
    contentType: TaskItemType;
    question: string;
    description?: string;
    orderNumber: number;
    mandatory: boolean;
    reviewerNote?: string;
};

export type TaskPublicAttemptsChoiceContentItem = {
    id: string;
    answer: string;
    isCorrect: boolean;
    orderNumber: number;
    isUserChecked: boolean;
};

export type TaskPublicAttemptsFileContentItem = {
    id: string;
    name: string;
    type: string;
    size: number;
    extension: string;
};

export type TTaskPublicAttemptsTextContent = TaskPublicAttemptsContent & {
    userText: string;
    allowScan: boolean;
};

export type TTaskPublicAttemptsNumberContent = TaskPublicAttemptsContent & {
    userNumber: string;
};

export type TTaskPublicAttemptsChoiceContent = TaskPublicAttemptsContent & {
    answers: Array<TaskPublicAttemptsChoiceContentItem>;
};

export type TTaskPublicAttemptsFileContent = TaskPublicAttemptsContent & {
    files: Array<TaskPublicAttemptsFileContentItem>;
};

export type TTaskPublicAttemptsImageContent = TaskPublicAttemptsContent & {
    images: Array<TaskPublicAttemptsFileContentItem>;
};

export class TTaskPublicStatisticsResponseAttempt {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => Number)
    public questionAnsweredCount: number;

    @Expose()
    @Type(() => Number)
    public questionCount?: number;

    @Expose()
    @Type(() => Number)
    public timeSpent: number;

    @Expose()
    @Type(() => Number)
    public finishTime: number;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    // @Type(() => TaskStatisticsComment)
    public comment?: TaskStatisticsComment;

    @Expose()
    // @Type(() => Array<TTaskPublicAttemptsTextContent>)
    public textContent?: Array<TTaskPublicAttemptsTextContent>;

    @Expose()
    // @Type(() => Array<TTaskPublicAttemptsNumberContent>)
    public numericContent?: Array<TTaskPublicAttemptsNumberContent>;

    @Expose()
    // @Type(() => Array<TTaskPublicAttemptsChoiceContent>)
    public singleChoiceContent?: Array<TTaskPublicAttemptsChoiceContent>;

    @Expose()
    // @Type(() => Array<TTaskPublicAttemptsChoiceContent>)
    public multipleChoiceContent?: Array<TTaskPublicAttemptsChoiceContent>;

    @Expose()
    // @Type(() => Array<TTaskPublicAttemptsFileContent>)
    public imageContent?: Array<TTaskPublicAttemptsFileContent>;

    @Expose()
    // @Type(() => Array<TTaskPublicAttemptsFileContent>)
    public fileContent?: Array<TTaskPublicAttemptsImageContent>;
}

class TCommentAttemptUser extends AccountableUser {
    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => String)
    public phone: string;
}

class TaskStatisticsComment {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public comment: string;

    @Expose()
    @Type(() => TCommentAttemptUser)
    public user: TCommentAttemptUser;

    @Expose()
    @Type(() => Number)
    public checkedTime: number;
}

export class TaskPublicStatisticsResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public passingNumber: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => Boolean)
    public deadLinePassed: boolean;

    @Expose()
    @Type(() => Number)
    public finishTime: number;

    @Expose()
    @Type(() => Number)
    public onReviewTime: number;

    @Expose()
    @Type(() => Number)
    public timeSpent: number;

    @Expose()
    @Type(() => Number)
    public filesDeleteTime: number;

    @Expose()
    public attempts: TTaskPublicStatisticsResponseAttempt[];
}

class VerificationAccountableUser extends AccountableUser {
    @Expose()
    @Type(() => String)
    public phone: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;
}

export class TaskPublicVerificatedResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => VerificationAccountableUser)
    public user: VerificationAccountableUser;

    @Expose()
    @Type(() => Number)
    public statusChangeTime: number;

    @Expose()
    @Type(() => Number)
    public passingNumber: number;
}

export class TaskPublicVerificatedReadResponse {
    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public attemptId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;

    @Expose()
    @Type(() => String)
    public complexity: Complexity;

    @Expose()
    @Type(() => String)
    public exerciseId: string;

    @Expose()
    @Type(() => TaskUser)
    public user: TaskUser;

    @Expose()
    @Type(() => Number)
    public statusChangeTime: number;

    // @Expose()
    // @Type(() => VerificationAccountableUser)
    // public user: VerificationAccountableUser;

    @Expose()
    public textContent?: Array<TTaskPublicAttemptsTextContent>;

    @Expose()
    public numericContent?: Array<TTaskPublicAttemptsNumberContent>;

    @Expose()
    public singleChoiceContent?: Array<TTaskPublicAttemptsChoiceContent>;

    @Expose()
    public multipleChoiceContent?: Array<TTaskPublicAttemptsChoiceContent>;

    @Expose()
    public imageContent?: Array<TTaskPublicAttemptsImageContent>;

    @Expose()
    public fileContent?: Array<TTaskPublicAttemptsFileContent>;

    @Expose()
    @Type(() => String)
    public checkComment: string;

    @Expose()
    @Type(() => TCommentAttemptUser)
    public checkedBy: TCommentAttemptUser;

    @Expose()
    @Type(() => LastCheckedComment)
    public lastCheckedComment: LastCheckedComment;

    @Expose()
    @Type(() => Number)
    public passingNumber: number;
}

export class TaskPublicReviewResponse {
    @Expose()
    @Type(() => String)
    public nextExerciseId: string;
}
