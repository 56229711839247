import React from "react";
import { DropdownIndicatorProps, components } from "react-select";
import { Icon, Icons } from "Uikit";

interface IOptionsDropdownIndicatorProps {
    onClick?: (e: any) => void;
    className?: string;
    iconClassName?: string;
}

// Кастомный компонент для react-select
// Иконка опций для селекта с модальным окном
export const OptionsDropdownIndicator =
    ({ onClick, iconClassName = "", className = "" }: IOptionsDropdownIndicatorProps) =>
    (props: DropdownIndicatorProps<any, true>) => {
        return (
            <div
                onClick={(e) => {
                    onClick?.(e);
                }}
            >
                <components.DropdownIndicator {...props} className={`${className}`}>
                    <Icon
                        className={`options-list ${iconClassName}`}
                        icon={Icons.List}
                        width={"20px"}
                        height={"18px"}
                    />
                </components.DropdownIndicator>
            </div>
        );
    };
