import React from "react";
import "react-day-picker/dist/style.css";
import { Button, FormGroup, Icon, Icons, Input, Label } from "Uikit";
import { InfoCard } from "./InfoCard";
import { TVoidFunction } from "types";

interface IInfoCardExpandableInputProps {
    value: string;
    isRoot: boolean;
    onChange: (value: string) => void;
    error: string;
    label: string;
    placeholder: string;
    onEdit: TVoidFunction;
    isEdit: boolean;
    maxLength?: number;
    cutRegExp?: RegExp | RegExp[];
}

export const InfoCardExpandableInput = ({
    value,
    isRoot,
    onChange,
    error,
    label,
    placeholder,
    onEdit,
    isEdit,
    maxLength,
    cutRegExp,
}: IInfoCardExpandableInputProps) => {
    return (
        <InfoCard title={!isEdit ? label : ""} className={isEdit ? "w-[700px] shrink-0" : ""}>
            <div className="flex items-center gap-1.5 w-full">
                {!isEdit && <p className="font-medium">{value}</p>}
                {isRoot && (
                    <>
                        {!isEdit ? (
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        color="fill-primary"
                                        width={20}
                                        height={20}
                                        className="cursor-pointer"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={onEdit}
                                className="!h-5 !w-5"
                            />
                        ) : (
                            <FormGroup className="!mb-0 w-full">
                                <Label isRequired>{label}</Label>
                                <Input
                                    maxLength={maxLength}
                                    isRequired={true}
                                    value={value}
                                    onChange={({ target: { value } }) => {
                                        onChange(value);
                                    }}
                                    placeholder={placeholder}
                                    error={error}
                                    onBlur={() => {}}
                                    cutRegExp={cutRegExp}
                                />
                            </FormGroup>
                        )}
                    </>
                )}
            </div>
        </InfoCard>
    );
};
