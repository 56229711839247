import { createSlice } from "@reduxjs/toolkit";
import { CurrentUserResponse } from "Api/Responses/UserResponse";
import { RootState } from "store";

export interface IUserSlice {
    user?: CurrentUserResponse;
}
const initialState: IUserSlice = {
    user: undefined,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const getCurrentUser = (state: RootState) => {
    return state.user.user;
};

export const { setCurrentUser } = userSlice.actions;
export default userSlice.reducer;
