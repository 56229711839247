import { createSlice } from "@reduxjs/toolkit";

export interface IAuthSlice {
    isAuth: boolean;
    isRoot: boolean;
}
const initialState: IAuthSlice = {
    isAuth: false,
    isRoot: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        setIsRoot: (state, action) => {
            state.isRoot = action.payload;
        },
    },
});

export const { setIsAuth, setIsRoot } = authSlice.actions;
export default authSlice.reducer;
