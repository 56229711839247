import React, { useMemo, useState, useCallback } from "react";
import { useQuery } from "react-query";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import isEmpty from "lodash/isEmpty";
import { Button, Checkbox, Icon, Icons, Table, SelectMultiValueRemove, SelectValueContainer } from "Uikit";
import { StarRating } from "Uikit/Rating/StarRating";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { FeedbackListRequest } from "Api/Requests/FeedbackRequest";
import { FeedbackListResponse } from "Api/Responses/FeedbackResponse";
import Api from "Api";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { DateFormat, formatDate } from "helpers/dateHelper";

interface IFeedbackTableProps {
    resourceId: string;
}

export const FeedbackTable = ({ resourceId }: IFeedbackTableProps) => {
    const [rowSelection, setRowSelection] = useState({});
    const { dialogState, openDialog, closeDialog } = useDialog();
    const invalidate = useInvalidate();

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([
        /* { id: "modifyTime", desc: true } */
    ]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["feedback", "collection", pagination, sorting, filters, resourceId],
        async () => {
            const request = new FeedbackListRequest();
            request.resourceId = resourceId;
            request.size = pageSize;
            request.page = pageIndex;
            request.sort = sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "desc";

            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                // Дата отправки
                if (element === "publicationDate") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                    // Участники
                } else if (element === "user.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                    // Оценка, баллы
                } else if (element === "rating") {
                    if (filters[element]["minValue"]) {
                        filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    }
                    if (filters[element]["maxValue"]) {
                        filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                    }
                    // Только с текстовым отзывом
                } else if (element === "isCommentRequired") {
                    filtersData["isCommentRequired.equal"] = true;
                    // Без текстового отзыва
                } else if (element === "isCommentNotRequired") {
                    filtersData["isCommentRequired.equal"] = false;
                } else {
                    filtersData[element] = filters[element];
                }
            }

            request.filters = filtersData;

            return await Api.Feedback.FeedbackList(request);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );
    const onDeleteCommentHandler = useCallback(
        async (data: string[]) => {
            const isMultiple = data.length > 1;

            openDialog({
                title: isMultiple ? "Удалить текст отзывов" : "Удалить текст отзыва",
                description: isMultiple
                    ? `Выбрано элементов: ${data.length}`
                    : `«${
                          dataQuery.data?.Content.find(({ id }) => {
                              return id === data[0];
                          })?.comment
                      }»`,
                content: isMultiple
                    ? "Удалить возможно только текст отзывов. Оценка останется без изменений"
                    : "Удалить возможно только текст отзыва. Оценка останется без изменений",
                closeBtnText: "Отмена",
                submitBtnText: "Удалить",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    Api.Feedback.FeedbackCheck({
                        feedbackIds: data,
                        isApproved: false,
                    }).then(() => {
                        invalidate("feedback");
                        closeDialog();
                    });
                },
            });
        },
        [openDialog, dataQuery.data?.Content, closeDialog, invalidate],
    );

    const columns = useMemo<ColumnDef<FeedbackListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Участник",
                accessorKey: "userFullName",
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: {
                            user: { avatarId, firstName, lastName, defaultAvatarColor },
                        },
                    },
                }) => {
                    return (
                        <div className="group flex items-center space-x-3 w-60">
                            <UserAvatar
                                avatarId={avatarId}
                                color={defaultAvatarColor!}
                                userInitials={`${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <span className="hover:text-blue">
                                {lastName} {firstName}
                            </span>
                        </div>
                    );
                },
            },
            {
                header: "Оценка",
                accessorKey: "rating",
                cell: ({
                    row: {
                        original: { rating },
                    },
                }) => {
                    return <StarRating value={rating} />;
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Отзыв",
                accessorKey: "comment",
                cell: ({
                    row: {
                        original: { comment },
                    },
                }) => {
                    return <div className="w-10/12">{comment}</div>;
                },
                footer: (props) => props.column.id,
            },
            {
                id: "buttons",
                header: "Действия",
                accessor: "",
                enableResizing: true,
                size: 30,
                cell: ({
                    row: {
                        original: { id },
                    },
                }) => {
                    return (
                        <div className="flex">
                            <Button
                                shape="round"
                                color="common"
                                onClick={() => {
                                    onDeleteCommentHandler([id]).then();
                                }}
                                icon={
                                    <Icon
                                        icon={Icons.Delete}
                                        width={16}
                                        height={16}
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement="center"
                                className="w-auto"
                            />
                        </div>
                    );
                },
            },
            {
                header: "Отправлен",
                accessorKey: "publicationDate",
                cell: ({
                    row: {
                        original: { publicationDate },
                    },
                }) => {
                    return formatDate(new Date(publicationDate * 1000), DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
        ],
        [onDeleteCommentHandler],
    );

    const controlButtons = (
        <div className="flex items-center space-x-4 w-full">
            <div className="flex flex-grow space-x-4">
                {!isEmpty(rowSelection) && (
                    <Button
                        onClick={() => onDeleteCommentHandler(Object.keys(rowSelection))}
                        color="danger"
                        size="medium"
                        id="adminTasksBtnArchive"
                    >
                        Удалить текст
                    </Button>
                )}
            </div>
            <Button
                className="border-input-stroke rounded-lg font-medium"
                variant="outline"
                color="secondary"
                size="medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                onClick={() => setIsFilterShow(true)}
                iconPlacement="left"
            >
                Фильтры
            </Button>
        </div>
    );

    const loadActiveUserOptions = async (
        inputValue: string,
        prevOptions: unknown,
        { page }: { page: number },
    ): Promise<any> => {
        if (inputValue === "") {
            return {
                options: [],
                hasMore: false,
            };
        }

        const users = await Api.User.GetActiveListOptions(inputValue, page);
        const usersSelectOptions: { label: string; value: AccountableUser | UserListResponse | undefined }[] =
            users.Content.map((p) => ({ label: p.lastName + " " + p.firstName, value: p }));

        return {
            options: usersSelectOptions,
            hasMore: users.TotalPages > page,
            additional: {
                page: page + 1,
            },
        };
    };

    const formatUserOptionsLabel = ({
        label,
        value,
    }: {
        label: string;
        value: AccountableUser | UserListResponse | undefined;
    }): React.ReactElement => {
        return (
            <div className="flex gap-2">
                {value?.avatarId ? (
                    <img
                        src={`/service/lms-upload/api/file/download/${value.avatarId}`}
                        className="w-5 h-5 rounded-full"
                    />
                ) : (
                    <div
                        className={`flex-center w-5 h-5 rounded-full`}
                        style={{ backgroundColor: value?.defaultAvatarColor }}
                    >
                        <span className="text-white text-xxs">
                            {value?.firstName[0]}
                            {value?.lastName[0]}
                        </span>
                    </div>
                )}
                <div>{label}</div>
            </div>
        );
    };

    const filtersConfig = useMemo(
        () => [
            {
                label: "Дата отправки",
                resetButton: false,
                fields: [
                    {
                        accessor: "publicationDate",
                        type: "date-range",
                    },
                ],
            },
            {
                label: "Участники",
                fields: [
                    {
                        accessor: "user.in",
                        type: "async-multi-select",
                        placeholder: "Выберите участника",
                        loadOptions: loadActiveUserOptions,
                        formatOptionLabel: formatUserOptionsLabel,
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Оценка, баллы",
                fields: [
                    {
                        accessor: "rating",
                        type: "range",
                    },
                ],
            },
            {
                label: "Дополнительно",
                className: "!border-b-0 !p-0 !-mb-6",
                resetButton: false,
                fields: [
                    {
                        accessor: "isCommentRequired",
                        label: "Только с текстовым отзывом",
                        type: "checkbox",
                        default: false,
                    },
                ],
            },
            {
                className: "!border-b-0 !p-0 !-mb-6",
                resetButton: false,
                fields: [
                    {
                        accessor: "isCommentNotRequired",
                        label: "Без текстового отзыва",
                        type: "checkbox",
                        default: false,
                    },
                ],
            },
        ],
        [],
    );

    return (
        <div className="group flex justify-between items-center pb-4 pt-4 first:border-0 border-t border-[#EAEDF3] h-full">
            <Confirmation {...dialogState} />
            <Filter
                isActive={isFilterShow}
                setIsActive={setIsFilterShow}
                configuration={filtersConfig as IFilterRow[]}
                filters={filters}
                onChange={setFilters}
            />
            <Table
                columns={columns}
                controlButtons={controlButtons}
                columnFilters={columnFilters}
                pageCount={dataQuery.data?.TotalPages}
                pagination={pagination}
                emptyTitle="Отзывов пока нет"
                emptyMessage="Отзывы появятся, когда кто-то из пользователей пройдёт курс и оставит отзыв или оценку"
                searchTitle="Поиск..."
                isFetching={dataQuery.isFetching}
                data={dataQuery.data?.Content}
                rowSelection={rowSelection}
                onPaginationChange={setPagination}
                onRowSelectionChange={setRowSelection}
                onColumnFiltersChange={setColumnFilters}
                sorting={sorting}
                onSortingChange={setSorting}
            />
        </div>
    );
};
