import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";

export class FileReadResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public disposableLink?: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public extension?: string;

    @Expose()
    @Type(() => Number)
    public size: number;

    @Expose()
    @Type(() => String)
    public contentType?: string;
}

export class DisposableLinkResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    disposableLink: string;
}

export class FileInfoResponse extends FileReadResponse {
    @Expose()
    @Type(() => Boolean)
    public isProcessed: boolean;
}
