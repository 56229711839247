import React from "react";

import { useAudioPlayer } from "hooks/useAudioPlayer";
import { useCombinedRefs } from "hooks/useCombinedRef";
import { Icon, Icons } from "Uikit/index";
import cx from "./audio-player.module.scss";
import Bar from "./Bar";
import Volume from "./Volume";

interface AudioPlayerProps {
    src: string;
}

const AudioPlayer = React.forwardRef(({ src = "", ...props }: AudioPlayerProps, ref) => {
    const { volume, curTime, duration, playing, setPlaying, setClickedTime, setClickedVolume } = useAudioPlayer();
    const innerRef = useCombinedRefs(ref);
    console.log(src);

    const togglePlay = () => {
        setPlaying(!playing);
    };

    const replay = () => {
        setClickedTime(0);
    };

    return (
        <div className={cx.player}>
            <audio id="audioPlayer" ref={innerRef} {...props}>
                <source src={src} />
                Your browser does not support the <code>audio</code> element.
            </audio>
            <div className={cx.controls}>
                <div className={cx.controlsBtn}>
                    <button className={cx.replayButton} onClick={replay}>
                        <Icon icon={Icons.RewindBack} width={20} height={20} />
                    </button>
                    <button className={cx.playButton} onClick={togglePlay}>
                        {playing ? (
                            <Icon icon={Icons.Pause} width={20} height={20} />
                        ) : (
                            <Icon icon={Icons.Play} width={20} height={20} />
                        )}
                    </button>
                </div>
                <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
                <Volume volume={volume} onVolumeUpdate={(volume) => setClickedVolume(volume)} />
            </div>
        </div>
    );
});

export default AudioPlayer;
