import React, { ChangeEvent, useId } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icon, Icons, TextareaAutoSizable } from "Uikit";
import { testingRegexp } from "Uikit/Forms/Input";
import { TaskAnswerResponseItem } from "Api/Responses/TaskResponse";

interface TaskItemChoiceItemProps {
    variant: TaskAnswerResponseItem;
    index: number;
    onChange: (index: number, value: string) => void;
    onDelete: (index: number) => void;
    deletable: boolean;
    error?: string;
}

export const TaskItemChoiceItem = ({
    variant: { orderNumber, answer },
    index,
    onChange,
    onDelete,
    deletable,
    error = "",
}: TaskItemChoiceItemProps) => {
    const { listeners, setNodeRef, transform, transition, setActivatorNodeRef, isDragging } = useSortable({
        id: orderNumber + 1,
    });
    const id = useId();

    const style = {
        transform: CSS.Transform.toString(
            transform ? { x: transform?.x, y: transform?.y, scaleX: transform?.scaleX, scaleY: 1 } : null,
        ),
        transition,
        zIndex: isDragging ? 10 : "initial",
        boxShadow: isDragging ? "0px 8px 20px rgba(0,0,0,0.12)" : "none",
        borderRadius: isDragging ? 10 : 0,
    };

    const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(index, e.target.value);
    };

    const onClickHandler = () => {
        if (deletable) {
            onDelete(index);
        }
    };

    return (
        <div ref={setNodeRef} style={style} className="flex items-center gap-4 mb-5 !cursor-auto">
            <div className="relative grow shrink-0">
                <TextareaAutoSizable
                    value={answer}
                    className="resize-none"
                    placeholder="Введите ответ"
                    error={error}
                    name="comment"
                    onChange={onChangeHandler}
                    cutRegExp={testingRegexp}
                    maxLength={512}
                    id={id + "taskItemChoiceItemInput"}
                />
            </div>
            <div
                className={deletable ? "cursor-pointer" : "opacity-50"}
                onClick={onClickHandler}
                id={id + "taskItemChoiceItemBtnClose"}
            >
                <Icon icon={Icons.Close} width={20} height={20} color="fill-gray-light" />
            </div>
            <div className="cursor-grab" ref={setActivatorNodeRef} {...listeners} id={id + "taskItemChoiceItemBtnGrid"}>
                <Icon icon={Icons.Grid} width={20} height={20} color="fill-gray-light" />
            </div>
        </div>
    );
};
