import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { Button, Checkbox, Icon, Icons, PopoverList, SelectMultiValueRemove, SelectValueContainer, Table } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { BasePaginationResponse } from "Api/BaseResponse";
import { TestListResponse } from "Api/Responses/TestResponse";
import { ResourceState, ResourceType, RoleName } from "Enums";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import Api from "Api";
import { useCurrentUser } from "hooks/useCurrentUser";
import { TestsEmpty } from "./TestsEmpty";
import { TestsRowActionPopover } from "./TestsRowActionPopover";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

interface ITestsTable {
    categoryId?: string;
    fetchTests: (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => Promise<BasePaginationResponse<TestListResponse>>;
    onCopy: (id: string) => void;
    onState: (ids: string[], state: string, resourceType: ResourceType) => Promise<void>;
    selectedCategories: string[];
    onCreateCategory: TVoidFunction;
}
export const TestsTable = ({
    categoryId,
    fetchTests,
    onCopy,
    onState,
    selectedCategories,
    onCreateCategory,
}: ITestsTable) => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const currentUser = useCurrentUser();

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [sorting, setSorting] = useState<SortingState>([{ id: "modifyTime", desc: true }]);

    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [isFilters, setIsFilters] = useState(false);
    const [filters, setFilters] = useState<{ [id: string]: any }>({
        // 'accountableUser.equal': null
    });

    const [isSelectedState, setIsSelectedState] = useState<boolean>(false);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

    const { data: ratingInfo } = useQuery(["tasks/filter-rating-info"], Api.Test.FilterRatingInfo);

    const dataQuery = useQuery(
        ["tests", "collection", pagination, sorting, filters, categoryId, selectedCategories],
        async () => {
            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "modifyTime") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (element === "questionsCount" || element === "ratingPoints") {
                    if (filters[element]["minValue"]) {
                        filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    }
                    if (filters[element]["maxValue"]) {
                        filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                    }
                } else if (element === "accountableUser.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "assignedToUser.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value).join(",");
                } else if (element === "assignedToTeam.in") {
                    filtersData[element] = filters[element].join(",");
                } else {
                    filtersData[element] = filters[element];
                }
            }

            filtersData["state.notIn"] = [ResourceState.ARCHIVED, ResourceState.DELETED];
            if (!filtersData["assignedToTeam.in"]?.length) {
                delete filtersData["assignedToTeam.in"];
            }

            if (categoryId) {
                filtersData["categoryId.equal"] = categoryId;
            }

            if (selectedCategories.length !== 0) {
                filtersData["categoryId.in"] = selectedCategories.join(",");
            }

            return await fetchTests(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const onCopyHandler = useCallback(
        async (course: TestListResponse) => {
            openDialog({
                title: "Создание копии теста",
                description: "«" + course.title + "»",
                content: "Вы уверены, что хотите создать копию теста? Копируется весь тест со всеми настройками",
                closeBtnText: "Отмена",
                submitBtnText: "Создать копию",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onCopy(course.id);

                    invalidate("tests");
                    closeDialog();
                },
            });
        },
        [invalidate, closeDialog, openDialog, onCopy],
    );
    const onArchiveHandler = useCallback(
        async (data: TestListResponse | string[]) => {
            setIsSelectedState(false);

            const ids: string[] = !Array.isArray(data) ? [data.id] : data;
            const isMultiple = Array.isArray(ids) && ids.length > 1;

            const available = await Api.Test.StateCheck(ids, ResourceState.ARCHIVED, ResourceType.QUIZ);

            if (available.length !== ids.length) {
                openDialog({
                    title: "Архивирование невозможно",
                    description: isMultiple
                        ? `Выбрано тестов: ${ids.length}`
                        : `«${dataQuery.data?.Content.find((i) => i.id === ids[0])?.title}»`,
                    content: isMultiple
                        ? "Тесты нельзя архивировать, так как один или несколько тестов находятся в разделе «Проверка». Вы можете скрыть тесты для пользователей или архивировать после проверки результатов тестов"
                        : "Тест нельзя архивировать, так как тест находится в разделе «Проверка». Вы можете скрыть тест для пользователей или архивировать после проверки результатов теста",
                    closeBtnText: "Отмена",
                    submitBtnText: "Скрыть",
                    submitBtnColor: "danger",
                    onRequestClose: () => closeDialog(),
                    onRequestSubmit: () => {
                        onState(ids, ResourceState.HIDDEN, ResourceType.QUIZ);
                        setRowSelection({});

                        invalidate("tests");
                        closeDialog();
                    },
                });

                return;
            }

            openDialog({
                title: `Архивирование ${isMultiple ? "тестов" : "теста"}`,
                description: isMultiple
                    ? `Выбрано тестов: ${ids.length}`
                    : `«${dataQuery.data?.Content.find((i) => i.id === ids[0])?.title}»`,
                content: isMultiple
                    ? "Вы действительно хотите архивировать тесты? Тесты переместятся в Архив и исчезнут у пользователей"
                    : "Вы действительно хотите архивировать тест? Тест переместится в Архив и исчезнет у пользователей",
                closeBtnText: "Отмена",
                submitBtnText: "Архивировать",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onState(ids, ResourceState.ARCHIVED, ResourceType.QUIZ);
                    setRowSelection({});

                    invalidate("tests");
                    closeDialog();
                },
            });
        },
        [invalidate, closeDialog, openDialog, onState, dataQuery.data],
    );
    const onStateHandler = useCallback(
        (state: string) => {
            setIsSelectedState(false);

            const ids: string[] = Object.keys(rowSelection);
            const isMultiple = Array.isArray(ids) && ids.length > 1;

            openDialog({
                title: `Изменить статус на «${state === ResourceState.ACTIVE ? "Активен" : "Скрыт"}»`,
                description: isMultiple
                    ? `Выбрано тестов: ${ids.length}`
                    : `«${dataQuery.data!.Content.find((i) => i.id === ids[0])?.title}»`,
                content:
                    state === ResourceState.ACTIVE
                        ? `Все пользователи, у кого есть доступ, увидят ${!isMultiple ? "данный тест" : "данные тесты"}`
                        : `У всех пользователей ${!isMultiple ? "пропадет данный тест" : "пропадут данные тесты"}`,
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: async () => {
                    setRowSelection({});
                    await onState(ids, state, ResourceType.QUIZ);

                    invalidate("tests");
                    closeDialog();
                },
            });
        },
        [invalidate, closeDialog, openDialog, rowSelection, onState, dataQuery.data],
    );

    const responsibleList = useResponsibleList();
    const loadResponsibleListOptions = useCallback(
        async (inputValue: string): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const selectOptions = responsibleList
                .filter((p) => p.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
                .map((p) => ({ label: p.label, value: p.payload }));

            return {
                options: selectOptions,
                hasMore: false,
            };
        },
        [responsibleList],
    );

    const formatUserOptionsLabel = ({
        label,
        value,
    }: {
        label: string;
        value: AccountableUser | UserListResponse | undefined;
    }): React.ReactElement => {
        return (
            <div className="flex gap-2">
                {value?.avatarId ? (
                    <img
                        src={`/service/lms-upload/api/file/download/${value.avatarId}`}
                        className="w-5 h-5 rounded-full"
                    />
                ) : (
                    <div
                        className={`flex-center w-5 h-5 rounded-full`}
                        style={{ backgroundColor: value?.defaultAvatarColor }}
                    >
                        <span className="text-white text-xxs">
                            {value?.firstName[0]}
                            {value?.lastName[0]}
                        </span>
                    </div>
                )}
                <div>{label}</div>
            </div>
        );
    };

    const columns = React.useMemo<ColumnDef<TestListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Название",
                accessorKey: "title",
                className: "w-1/2",
                cell: (info) => {
                    return (
                        <div
                            className="group flex items-center space-x-3 cursor-pointer"
                            onClick={() => navigate(`/admin/test/${info.row.original.id}`)}
                        >
                            {info.row.original.logoId && (
                                <img
                                    className="rounded-md w-[54px] h-9 object-cover cursor-pointer shrink-0"
                                    src={Api.Upload.GetLogo(info.row.original.logoId, LogoSize.THUMB_MICRO)}
                                    alt={info.row.original.title}
                                />
                            )}
                            {!info.row.original.logoId && (
                                <div className="rounded-md w-[54px] h-9 bg-gray-blue cursor-pointer" />
                            )}
                            <MultiClumpTooltip
                                className="group-hover:text-blue"
                                clamp={1}
                                label={String(info.getValue())}
                            />
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Статус",
                accessorKey: "state",
                enableResizing: true,
                size: 100,
                cell: (info) =>
                    info.getValue() === ResourceState.ACTIVE ? (
                        "Активен"
                    ) : (
                        <span className="text-gray-text">Скрыт</span>
                    ),
                footer: (props) => props.column.id,
            },
            {
                header: "Вопросов",
                accessorKey: "questionsCount",
                enableResizing: true,
                size: 100,
                cell: (info) => {
                    return <div>{String(info.getValue())}</div>;
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Баллы",
                accessorKey: "ratingPoints",
                enableResizing: true,
                size: 100,
                cell: (info) => {
                    return <div>{String(info.getValue())}</div>;
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Обновлен",
                accessorKey: "modifyTime",
                enableResizing: true,
                size: 200,
                cell: (info) => {
                    return formatDate(new Date(Number(info.getValue()) * 1000), DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
            {
                id: "buttons",
                header: "",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 90,
                cell: ({ row }) => {
                    return (
                        <div className="flex" id={"adminTestsTableGroupButton" + row.id}>
                            <Button
                                shape="round"
                                color="common"
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement="center"
                                onClick={() => navigate(`/admin/test/${row.original.id}/edit`)}
                            />
                            <TestsRowActionPopover
                                item={row.original}
                                onCopyHandler={onCopyHandler}
                                onArchiveHandler={onArchiveHandler}
                            />
                        </div>
                    );
                },
            },
        ],
        [navigate, onArchiveHandler, onCopyHandler],
    );
    const filtersConfig = useMemo(
        () => [
            {
                label: "Дата обновления",
                fields: [
                    {
                        accessor: "modifyTime",
                        type: "date-range",
                    },
                ],
            },
            {
                label: "Кол-во вопросов, шт",
                fields: [
                    {
                        accessor: "questionsCount",
                        type: "range",
                        min: ratingInfo?.minQuestionsCount,
                        max: ratingInfo?.maxQuestionsCount,
                    },
                ],
            },
            {
                label: "Награда, баллы",
                fields: [
                    {
                        accessor: "ratingPoints",
                        type: "range",
                        min: ratingInfo?.minRatingPoints,
                        max: ratingInfo?.maxRatingPoints,
                    },
                ],
            },
            {
                label: "Ответственный",
                fields: [
                    {
                        accessor: "accountableUser.in",
                        type: "async-multi-select",
                        placeholder: "Выберите ответственного",
                        loadOptions: loadResponsibleListOptions,
                        formatOptionLabel: formatUserOptionsLabel,
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Назначенные на команду",
                fields: [
                    {
                        accessor: "assignedToTeam.in",
                        type: "team-multi-select",
                    },
                ],
            },
            // {
            //     label: "Назначенные на участника",
            //     fields: [
            //         {
            //             accessor: "assignedToUser.in",
            //             type: "async-multi-select",
            //             placeholder: "Выберите участника",
            //             loadOptions: loadActiveUserOptions,
            //             formatOptionLabel: formatUserOptionsLabel,
            //             components: {
            //                 MultiValueRemove: SelectMultiValueRemove,
            //                 ValueContainer: SelectValueContainer({}),
            //             },
            //             lazy: true,
            //         },
            //     ],
            // },
            {
                label: "Дополнительно",
                fields: [
                    {
                        accessor: "hasDeadline.equal",
                        label: "С дедлайном",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "isDeadlinePassed.equal",
                        label: "Дедлайн прошел",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "isHidden.equal",
                        label: "Скрытые",
                        type: "checkbox",
                        default: false,
                    },
                    // {
                    //     accessor: "hasPublicAccess.equal",
                    //     label: "С общим доступом",
                    //     type: "checkbox",
                    //     default: false,
                    // },
                    {
                        accessor: "isNotAssigned.equal",
                        label: "Никому не назначены",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "courseId.isNull",
                        label: "Не задействованы в курсах",
                        type: "checkbox",
                        default: false,
                    },
                ],
            },
        ],
        [ratingInfo, loadResponsibleListOptions],
    );

    const controlButtons = (
        <div className="flex justify-end items-center space-x-4">
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement="left"
                onClick={() => setIsFilters(true)}
                id="adminTestsBtnFilter"
            >
                Фильтры
            </Button>
            <Button
                className="rounded-lg whitespace-nowrap font-medium"
                size="medium"
                icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-[#FFFFFF]" />}
                iconPlacement="left"
                onClick={() => navigate(categoryId ? `/admin/test?categoryId=${categoryId}` : "/admin/test")}
                id="adminTestBtnCreateTest"
            >
                Создать тест
            </Button>
        </div>
    );
    const selectButtons = (
        <div className="flex justify-end items-center space-x-5">
            <Button
                onClick={() => onArchiveHandler(Object.keys(rowSelection))}
                color="danger"
                size="medium"
                id="adminTestsBtnArchive"
            >
                Архивировать
            </Button>
            <PopoverList offset={[0, 10]} visible={isSelectedState} onClickOutside={() => setIsSelectedState(false)}>
                <Button
                    variant="outline"
                    size="medium"
                    color="common"
                    className="border-gray-blue"
                    icon={<Icon className="" icon={Icons.ChevronDown} width={10} height={6} />}
                    iconPlacement="right"
                    onClick={() => setIsSelectedState(true)}
                    id="adminTestsBtnChangeStatus"
                >
                    Изменить статус
                </Button>
                <PopoverList.Item name="setStatusHide" onClickItem={() => onStateHandler(ResourceState.HIDDEN)}>
                    Скрыт
                </PopoverList.Item>
                <PopoverList.Item name="setStatusActive" onClickItem={() => onStateHandler(ResourceState.ACTIVE)}>
                    Активен
                </PopoverList.Item>
            </PopoverList>
        </div>
    );

    useEffect(() => {
        setFilters({});
    }, [categoryId]);

    return (
        <>
            <Confirmation {...dialogState} />

            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <TestsEmpty
                    isEmptyCategories={categoryId === undefined}
                    onCreateCategoryClick={onCreateCategory}
                    onCreateTestClick={() => navigate(`/admin/test${categoryId ? `?categoryId=${categoryId}` : ""}`)}
                />
            )}

            {!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0) ? (
                <>
                    <Filter
                        isActive={isFilters}
                        setIsActive={setIsFilters}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                        hideTeamMembers={currentUser?.data?.role === RoleName.ADMIN}
                    />
                    <Table
                        id="adminTests"
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data?.TotalPages}
                        pagination={pagination}
                        emptyMessage="По заданным параметрам результатов нет"
                        searchTitle="Поиск по названию"
                        isFetching={dataQuery.isFetching}
                        data={dataQuery.data?.Content}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        selectButtons={selectButtons}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                    />
                </>
            ) : null}
        </>
    );
};
