import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";
import { AccountableUser } from "./UserResponse";

export class CommentReadResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => Number)
    public parentId: number;

    @Expose()
    @Type(() => AccountableUser)
    public user: AccountableUser;

    @Expose()
    @Type(() => Number)
    public point: number;

    @Expose()
    @Type(() => String)
    public comment: string;

    @Expose()
    @Type(() => Date)
    public createDate: Date;
}
