import { Expose, Type } from "class-transformer";

export class Resource {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    imageId?: string;

    @Expose()
    @Type(() => String)
    name: string;

    @Expose()
    @Type(() => Date)
    updateTime: Date;

    @Expose()
    @Type(() => String)
    type: string;

    @Expose()
    @Type(() => String)
    progress: string;
}

export class Content {
    @Expose()
    @Type(() => Resource)
    training?: Resource[] = [];

    @Expose()
    @Type(() => Resource)
    courses?: Resource[];

    @Expose()
    @Type(() => Resource)
    quiz?: Resource[];

    @Expose({ name: "EXERCISE" })
    @Type(() => Resource)
    exercises: Resource[];

    @Expose()
    @Type(() => Resource)
    questions?: Resource[];

    @Expose()
    @Type(() => Resource)
    news?: Resource[];

    @Expose()
    @Type(() => Resource)
    library?: Resource[];
}

export class ResourceAccess {
    @Expose()
    @Type(() => String)
    userId: string;

    @Expose()
    @Type(() => Content)
    content: Content;
}
