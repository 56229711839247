export const translit = (str: string) => {
    let res = "";

    const ru =
        "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split(
            "-",
        );
    const en =
        "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split(
            "-",
        );

    for (let i = 0, l = str.length; i < l; i++) {
        const s = str.charAt(i);
        const n = ru.indexOf(s);

        if (n >= 0) {
            res += en[n];
        } else {
            res += s;
        }
    }

    return res;
};

export const generateLogin = (firstName: string, lastName: string) => {
    if (!firstName && !lastName) {
        return "";
    }

    return (
        (firstName.length !== 0 ? translit(firstName[0]) : "") +
        "_" +
        (lastName.length !== 0
            ? translit(lastName.replaceAll(/[ьЬъЪ]/gi, "")).replaceAll(/[^a-zA-Zа-яА-ЯёЁ0-9_]/g, "_")
            : "")
    );
};
