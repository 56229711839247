import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { Icon, Icons, Button } from "../..";
import { TVoidFunction } from "types";

export type TLogoFormat = "square" | "rectangle";

interface TLogoInputProps {
    onClick: TVoidFunction;
    avatarId?: string | null;
    format?: TLogoFormat;
    className?: string;
    alt?: string;
}

export const LogoInput = ({
    onClick,
    avatarId,
    className,
    format = "square",
    alt = "Логотип компании",
}: TLogoInputProps) => {
    const [avatar, setAvatar] = useState("");

    useEffect(() => {
        if (avatarId) {
            setAvatar(avatarId.includes("base64") ? avatarId : "/service/lms-upload/api/file/download/" + avatarId);
        } else {
            setAvatar("");
        }
    }, [avatarId]);

    let width = 104;
    let height = 104;
    if (format === "rectangle") {
        width = 240;
        height = 80;
    }
    let sizeClassName = `w-[104px] h-[104px]`;
    if (format === "rectangle") {
        sizeClassName = `w-[240px] h-[80px]`;
    }

    return (
        <div className={clsx("flex flex-shrink-0", className)}>
            {avatar ? (
                <img
                    className="rounded-md group-hover:brightness-95"
                    style={{ width, height, opacity: avatar ? 1 : 0 }}
                    src={avatar}
                    alt={alt}
                />
            ) : (
                <div className={`${sizeClassName} bg-blue-gray rounded-md relative`}>
                    <Button
                        shape={"round"}
                        color={"common"}
                        icon={<Icon icon={Icons.Upload} color="fill-blue-dark" width={20} height={20} />}
                        iconPlacement={"center"}
                        onClick={onClick}
                        className="!h-full !w-full flex-center"
                    />
                </div>
            )}
        </div>
    );
};
