import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import isAfter from "date-fns/isAfter";
import { useNavigate } from "react-router-dom";
import Api from "Api";
import { useInvalidate } from "hooks/useInvalidate";
import { TaskPublicListResponse } from "Api/Responses/TaskPublicResponse";
import { Empty } from "Uikit/Page/Empty";
import { Icon, Icons } from "Uikit";
import { TaskCardsList } from "./TaskCardsList";
import { TasksCardListMobile } from "./TasksCardListMobile";
import Skeleton from "react-loading-skeleton";
import { tasksKeys, useChangeTaskFavoriteStatus } from "./task.hooks";
import { Preloader } from "Components/Preloader/Preloader";
import { useScreenSize } from "hooks/useMediaQuery";

export const TasksUnderReview = () => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const [isMounted, setIsMounted] = useState(false);

    const dataQuery = useQuery(
        tasksKeys.listReview(),
        async () => {
            return (await Api.TaskPublic.ReviewList()) as unknown as TaskPublicListResponse[];
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isMounted,
        },
    );

    const { mutate: mutateFavoriteStatus } = useChangeTaskFavoriteStatus();

    const onFavoriteChange = (resource: TaskPublicListResponse, isFavorite: boolean) => {
        mutateFavoriteStatus({ resource, isFavorite, queryKeys: tasksKeys.listReview() });
    };

    const { data, isFetching } = dataQuery;
    const dataLength = data?.length ?? 0;
    const onCardClick = (taskId: string) => {
        navigate(`/task/${taskId}/statistics`);
    };

    useEffect(() => {
        setIsMounted(true);
        invalidate("tasks");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { size } = useScreenSize();
    const isSmall = size === "small";

    return (
        <>
            {!isFetching && data && dataLength > 0 && (
                <>
                    <div className="hidden sm:block">
                        <TaskCardsList dataLength={data.length} id="userTasksReview">
                            {data
                                ?.sort((item1: TaskPublicListResponse, item2: TaskPublicListResponse) => {
                                    if (isAfter(new Date(item2.statusChangeTime), new Date(item1.statusChangeTime))) {
                                        return 1;
                                    }
                                    if (isAfter(new Date(item1.statusChangeTime), new Date(item2.statusChangeTime))) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                .map((item) => {
                                    return (
                                        <TaskCardsList.TaskCard
                                            key={item.id}
                                            task={item}
                                            onClick={onCardClick}
                                            showStatusChangeTime={true}
                                            onFavoriteChange={onFavoriteChange}
                                        />
                                    );
                                })}
                        </TaskCardsList>
                    </div>
                    {isSmall && (
                        <div className="block sm:hidden">
                            <TasksCardListMobile
                                id="userTasksReview_mobile"
                                isFinished={false}
                                isReview={true}
                                data={data}
                            />
                        </div>
                    )}
                </>
            )}
            <div className="relative">
                <Preloader
                    className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] gap-y-5 sm:gap-x-6.5 2xl:gap-x-8 sm:gap-y-9"
                    isShow={isFetching}
                >
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={p}>
                                <div className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                                <div className="leading-5 line-clamp-2 pt-3">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                            </div>
                        );
                    })}
                </Preloader>
            </div>
            {!isFetching && dataLength === 0 && (
                <div className="h-full flex justify-center">
                    <Empty
                        title="На проверке ничего нет"
                        description={
                            <span>
                                На эту вкладку будут попадать задания,
                                <br /> которые находятся на проверке
                            </span>
                        }
                        topElement={
                            <div className="flex-center mb-4 2xl:mb-5">
                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.EmojiSad}
                                        width={"36px"}
                                        height={"36px"}
                                        color={"fill-primary"}
                                        className="2xl:!w-11.25 2xl:!h-11.25"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
        </>
    );
};
