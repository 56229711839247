export const ValidateTitle = (str: string) => {
    if (!str) {
        return "Поле обязательно для заполнения";
    }

    if (str.length > 128) {
        return "Невозможно ввести символов больше разрешенной длины";
    }

    if (!RegExp(/^[0-9a-zA-Zа-яА-ЯёЁ .,\-"'()«»!@#№$%&*_+=?;:/]*$/).exec(str)) {
        return "В поле нельзя ввести недопустимые символы";
    }

    return undefined;
};

export const ValidateDescription = (str: string) => {
    if (str.length > 1024) {
        return "Невозможно ввести символов больше разрешенной длины";
    }

    if (!RegExp(/^[0-9a-zA-Zа-яА-ЯёЁ .,\-"'()«»!@#№$%&*_+=?;:/]*$/).exec(str)) {
        return "В поле нельзя ввести недопустимые символы";
    }

    return undefined;
};

export const ValidateSections = (data: any[]) => {
    if (
        data.length === 0 ||
        data.every(({ components }: any) => {
            return components?.length === 0;
        })
    ) {
        const errors: Record<string, string> = {};

        data.forEach((section) => {
            errors[section.id] = "Для сохранения требуется хотя бы один активный материал в содержимом курса";
        });

        return errors;
    }

    return undefined;
};

export const ValidateName = (str: string, required: boolean) => {
    if (required && !str) {
        return "Поле обязательно для заполнения";
    }

    if (!required && !str) {
        return undefined;
    }

    if (str.length > 64) {
        return "Длина поля может быть от 1 до 64 символа";
    }

    if (!RegExp(/^[a-zA-Zа-яА-ЯёЁ'` —–-]*$/).exec(str) || str.match(/(^[`—–-])/gm) || str.match(/([`—–-]$)/gm)) {
        return "Для ввода допустимы буквы русского и латинского алфавита, пробел, тире и апостроф";
    }

    return undefined;
};

export const ValidateLogin = (str: string) => {
    if (!str) {
        return "Поле обязательно для заполнения";
    }

    if (str.length > 128) {
        return "Такой логин уже существует";
    }

    if (str.length < 3 || str.length > 128) {
        return "Длина поля может быть от 3 до 128 символов";
    }

    if (!RegExp(/[a-zA-Z]/).exec(str)) {
        return "Логин должен содержать хотя бы одну букву";
    }

    if (!RegExp(/[a-zA-Z0-9_.]/).exec(str)) {
        return "В поле нельзя ввести недопустимые символы";
    }

    return undefined;
};

export const ValidateEmail = (str: string) => {
    if (!str) {
        return "Поле обязательно для заполнения";
    }

    if (str.length < 5 || str.length > 256) {
        return "Поле заполнено некорректно";
    }

    if (
        !RegExp(
            /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]{1,63}(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?$/,
        ).exec(str)
    ) {
        return "Поле заполнено некорректно";
    }

    return undefined;
};

export const ValidatePassword = (str: string, required: boolean) => {
    if (required && !str) {
        return "Поле обязательно для заполнения";
    }

    if (!required && !str) {
        return undefined;
    }

    if (str.length < 8) {
        return "Минимальная длина пароля - 8 символов";
    }

    if (str.length > 16) {
        return "Максимальная длина пароля - 16 символов";
    }

    // eslint-disable-next-line no-useless-escape
    if (!RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9.,\-"'()«»!@#№$%^&*_+=?;:|\\\/~><\[\]{}]{8,16}$/).exec(str)) {
        return "Пароль должен содержать как минимум одну прописную букву, одну заглавную букву и одну цифру.";
    }

    return undefined;
};
