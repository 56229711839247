import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import Api from "Api/index";
import { Option } from "Uikit/types/Option";
import { MultiSelect } from "Uikit/Forms/MultiSelect";
import { ID } from "types/ID";

interface OfficeMultiselectProps {
    onChange: (offices: ID[]) => void;
}

export const OfficeMultiselect = ({ onChange }: OfficeMultiselectProps) => {
    const [value, setValue] = useState<Option[]>([]);

    const { data: { Content: offices = [] } = {} } = useQuery(["offices", "collection"], () => Api.Office.List());

    const handleChange = (values: readonly Option[]) => {
        setValue(values as Option[]);
        onChange(values.map((option) => option.value));
    };

    const options = useMemo(
        () => offices.map((office) => ({ label: office.name, value: office.id } as Option)),
        [offices],
    );

    return (
        <MultiSelect
            options={options}
            onChange={handleChange}
            classNames={{ menuList: () => "!max-h-45" }}
            value={value}
            placeholder="Офисы"
            components={{
                MultiValueContainer: () => null,
            }}
        />
    );
};
