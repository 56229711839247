import BaseApi from "../BaseApi";
import { BaseArrayResponse, BasePaginationResponse, BaseResponse } from "../BaseResponse";
import { OfficeRequest } from "../Requests/OfficeRequest";
import { OfficeResponse } from "../Responses/OfficeResponse";
import { SortingState } from "@tanstack/react-table";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class OfficeApi extends BaseApi {
    List = async (): Promise<BaseArrayResponse<OfficeResponse>> => {
        return await this.Get(new BaseArrayResponse(OfficeResponse), "/offices");
    };

    ListPaginated = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<OfficeResponse>> => {
        const params = LMSFilterParams({ filter, sort, size, page });
        return this.Get(new BasePaginationResponse(OfficeResponse), `/offices?${params.toString()}`);
    };

    Create = async (data: OfficeRequest): Promise<OfficeResponse> => {
        return await this.Post(OfficeResponse, "/offices", data);
    };

    Edit = async (data: OfficeRequest): Promise<OfficeResponse> => {
        return await this.Put(OfficeResponse, "/offices", data);
    };

    Remove = async (id: string): Promise<BaseResponse> => {
        return await this.Delete(BaseResponse, "/offices/" + id);
    };
}
