import React, { useState } from "react";
import { Breadcrumbs, Button, FormGroup, Input, Label, Notification } from "Uikit";
import { SystemModal } from "./SystemModal";
import { toast } from "react-toastify";

export const SettingsSystem = () => {
    const initialSettings = {
        materialsShelfLife: 15,
    };

    const [systemModalOpen, setSystemModalOpen] = useState(false);
    const [isSystemSettingsChanged, setIsSystemSettingsChanged] = useState(false);
    const [systemModalType, setSystemModalType] = useState<"save" | "cancel">("save");

    const [systemSettings, setSystemSettings] = useState(initialSettings);

    const changeSystemSettings = (e: any) => {
        setSystemSettings({ ...systemSettings, [e.target.name]: e.target.value });
        setIsSystemSettingsChanged(true);
    };

    return (
        <div className="w-full h-full px-4 pb-4 sm:overflow-y-scroll custom-scrollbar" id="settingsSystem">
            <Breadcrumbs className="mb-8" id="settingsSystemBreadcrumbs">
                <Breadcrumbs.Link title="Администратор" url={"/admin"} />
                <Breadcrumbs.Link title="Настройки" url={"/admin/settings"} />
                <Breadcrumbs.Link title="Система" url={"/admin/settings/system"} />
            </Breadcrumbs>

            <FormGroup id="settingsSystemForm">
                <div className="flex">
                    <div className="my-auto py-2 flex-grow p2-accent">
                        <Label className="!font-medium">Срок хранения загруженных участниками материалов</Label>
                    </div>
                    {isSystemSettingsChanged && (
                        <div className="space-x-4">
                            <Button
                                key={0}
                                onClick={() => {
                                    setSystemModalType("cancel");
                                    setSystemModalOpen(true);
                                }}
                                variant="outline"
                                size={"medium"}
                                color={"common"}
                                className={"border-[#EAEDF3]"}
                            >
                                Отменить
                            </Button>

                            <Button
                                key={1}
                                onClick={() => {
                                    setSystemModalType("save");
                                    setSystemModalOpen(true);
                                }}
                                size={"medium"}
                                color={"primary"}
                            >
                                Сохранить
                            </Button>
                        </div>
                    )}
                </div>

                <div className="flex space-x-5 max-w-xs">
                    <Input
                        type="number"
                        name="materialsShelfLife"
                        value={systemSettings.materialsShelfLife}
                        onChange={changeSystemSettings}
                        id="materialsShelfLife"
                        disabled
                    />
                    <span className="my-auto">дней</span>
                </div>
            </FormGroup>

            <SystemModal
                isOpen={systemModalOpen}
                setIsOpen={setSystemModalOpen}
                setSystemSettingsChanged={setIsSystemSettingsChanged}
                resetFunc={() => setSystemSettings(initialSettings)}
                saveFunc={() => {
                    toast(
                        ({ closeToast }) => {
                            return <Notification text="Все изменения сохранены!" type="success" onClose={closeToast} />;
                        },
                        {
                            position: "bottom-right",
                        },
                    );
                }}
                id="settingsSystemModal"
                type={systemModalType}
            />
        </div>
    );
};
