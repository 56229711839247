import React, { ChangeEventHandler, MouseEventHandler, useMemo } from "react";
import uniqueId from "lodash/uniqueId";
import clsx from "clsx";

const checkmark = {
    default:
        "shrink-0 relative bg-white w-4 h-4 border border-gray-input rounded xl:rounded-[5px] cursor-pointer 2xl:w-4.5 2xl:h-4.5",
    checked: "shrink-0 border-0 after:absolute after:content-[''] after:block after:border-white after:rotate-45",
    indeterminate: "shrink-0 border-0 after:absolute after:content-[''] after:block after:border-white",
    disabled: "shrink-0 border-1 !bg-gray-blue cursor-not-allowed",
    error: "border-red",
};

const size = {
    checked: {
        regular:
            "after:top-[3px] after:left-1.5 after:w-1 after:h-2 2xl:after:w-[5px] 2xl:after:h-[9px] 2xl:after:left-[6px] after:border-r after:border-b",
        large: "after:top-[4px] after:left-2 after:w-2 after:h-3 2xl:after:w-[9px] 2xl:after:h-[13px] 2xl:after:left-[9px] after:border-r after:border-b",
    },
    indeterminate: {
        regular: "after:top-[1px] after:left-1 after:w-2 after:h-2 after:border-b 2xl:after:w-2.5 2xl:after:top-[2px]",
        large: "after:top-[1px] after:left-[5px] after:w-3.5 after:h-3 after:border-b-2 2xl:after:w-4 2xl:after:top-[2px]",
    },
};

interface CheckboxProps {
    className?: string;
    checked?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    id?: string;
    indeterminate?: boolean;
    label?: string;
    variant?: "regular" | "large";
    onClick?: MouseEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    value?: string;
    name?: string;
    error?: boolean;
    stopClickPropagation?: boolean;
}

export const Checkbox = ({
    className,
    indeterminate,
    label,
    id,
    variant = "regular",
    checked = false,
    readOnly = false,
    onChange = () => ({}),
    stopClickPropagation = true,
    ...attrs
}: CheckboxProps) => {
    const key = useMemo(() => id ?? uniqueId(label), [id, label]);

    const containerClasses = clsx("relative flex items-center text-base w-full", className);
    const checkmarkClasses = clsx(
        checkmark.default,
        variant === "regular" && "w-4 h-4 2xl:w-4.5 2xl:h-4.5",
        variant === "large" && "w-[24px] h-[24px] 2xl:w-[26px] 2xl:h-[26px]",
        (checked || indeterminate) && !attrs.disabled && "!bg-blue",
        readOnly && "cursor-default",
        checked && !indeterminate && checkmark.checked + " " + size.checked[variant],
        indeterminate && checkmark.indeterminate + " " + size.indeterminate[variant],
        attrs.disabled && checkmark.disabled,
        checked && attrs.disabled && "!bg-gray-light",
        attrs.disabled && indeterminate && "bg-gray-light",
        attrs.error && checkmark.error,
    );

    delete attrs.error;

    return (
        <label
            className={containerClasses}
            onClick={(e) => {
                if (stopClickPropagation) {
                    e.stopPropagation();
                }
            }}
        >
            <div className={checkmarkClasses} />
            {label && <span className="pl-2 2xl:pl-2.5 2xl:text-md">{label}</span>}
            <input
                {...attrs}
                checked={checked}
                onChange={onChange}
                className="absolute opacity-0 h-0 w-0 left-0 disabled:cursor-not-allowed invisible"
                id={key}
                type="checkbox"
            />
        </label>
    );
};
