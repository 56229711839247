import React from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";

import { Button, Icon, Icons } from "../..";

import "./CustomDatePicker.css";
import clsx from "clsx";

interface ICustomDatePickerProps extends ReactDatePickerProps {
    startDate: Date | null;
    onChange: (date: Date) => void;
    endDate?: Date | null;
    selected?: Date | null;
    withIcons?: boolean;
    dateFormat?: string;
    className?: string;
}

interface DatePickerIconsProps {
    error?: boolean;
}

const renderDayContents = (day: any, date: any) => {
    return (
        <span className={`day ${[0, 6].some((item: number) => item === date.getDay()) ? "day--weekend text-red" : ""}`}>
            {date.getDate()}
        </span>
    );
};

const DatePickerIcons = ({ error = false }: DatePickerIconsProps) => {
    // Calendar
    return (
        <div className="flex align-center gap-3 absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none">
            {error && <Icon icon={Icons.Alert} width="15px" height="15px" color={"fill-red"} />}
            <Icon icon={Icons.UserMenuIconCalendar} width="15px" height="15px" />
        </div>
    );
};

const DatePickerCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => (
    <div className="flex items-center">
        <Button
            onClick={decreaseYear}
            disabled={prevYearButtonDisabled}
            color="common"
            className="text-black font-light disabled:!bg-transparent disabled:opacity-50 disabled:cursor-not-allowed"
            icon={<Icon className="rotate-90" icon={Icons.Union} width="8px" height="8px" color="fill-black" />}
            iconPlacement={"center"}
        />
        <Button
            /* className="p-4" */ onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            color="common"
            className="text-black font-light disabled:!bg-transparent disabled:opacity-50 disabled:cursor-not-allowed"
            icon={<Icon className="rotate-180" icon={Icons.ArrowRight} width="10px" height="10px" color="fill-black" />}
            iconPlacement={"center"}
        />
        <span className="block grow text-base">{`${format(date, "LLLL", { locale: ru })} ${date.getFullYear()}`}</span>

        <Button
            /* className="p-4" */ onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            color="common"
            className="text-black font-light disabled:!bg-transparent disabled:opacity-50 disabled:cursor-not-allowed"
            icon={<Icon className="" icon={Icons.ArrowRight} width="10px" height="10px" color="fill-black" />}
            iconPlacement={"center"}
        />
        <Button
            /* className="p-4" */ onClick={increaseYear}
            disabled={nextYearButtonDisabled}
            color="common"
            className="text-black font-light disabled:!bg-transparent disabled:opacity-50 disabled:cursor-not-allowed"
            icon={<Icon className="-rotate-90" icon={Icons.Union} width="8px" height="8px" color="fill-black" />}
            iconPlacement={"center"}
        />
    </div>
);

export const CustomDatePicker = ({
    selected,
    startDate,
    endDate,
    onChange,
    className,
    dateFormat = "от dd.MM.yyyy",
    withIcons = false,
    ...rest
}: ICustomDatePickerProps) => {
    return (
        <div className={clsx("custom-datepicker", className)}>
            <DatePicker
                selected={selected}
                onChange={onChange}
                selectsStart
                dateFormat={dateFormat}
                startDate={startDate}
                endDate={endDate}
                renderDayContents={renderDayContents}
                locale={ru}
                renderCustomHeader={(params) => <DatePickerCustomHeader {...params} />}
                {...rest}
            />
            {withIcons ? <DatePickerIcons /> : null}
        </div>
    );
};
