import React, { ReactNode } from "react";
import { TaskCard } from "./TaskCard";
import { numCapEnd } from "helpers/numCapEnd";

interface ITaskCardsInfiniteListProps {
    title?: string;
    dataLength: number;
    children: ReactNode;
}

export const TaskCardsInfiniteList = ({ title, dataLength, children }: ITaskCardsInfiniteListProps) => {
    const dataLegthUnits = numCapEnd({ one: "задание", two: "задания", few: "заданий" }, dataLength);

    return (
        <div className="mb-6 sm:mb-8 2xl:mb-9">
            {title && (
                <div className="flex items-center gap-5 mb-5">
                    <h2>{title}</h2>
                    <span className="text-gray-light">
                        {dataLength} {dataLegthUnits}
                    </span>
                </div>
            )}
            <div className="sm:gap-x-6.5 2xl:gap-x-8 gap-y-8 2xl:gap-y-9 grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)]">
                {children}
            </div>
        </div>
    );
};

TaskCardsInfiniteList.TaskCard = TaskCard;
