import React from "react";
import { Button, Icon, Icons } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { TVoidFunction } from "types";

interface EmptyTaskListProps {
    categoryId?: string;
    onCreateCategory?: TVoidFunction;
    onCreateTask: TVoidFunction;
}

export const TasksEmpty = ({ categoryId, onCreateCategory, onCreateTask }: EmptyTaskListProps) => {
    return (
        <Empty
            title={categoryId ? "В категории пока нет заданий" : "Созданных заданий пока нет"}
            description={
                categoryId
                    ? `Создайте задания и поместите их в эту категорию, чтобы они здесь появились`
                    : `Задание без категории существовать не может. Сначала создайте категорию, затем задание. 
                    Название категории отображается в пользовательском интерфейсе`
            }
        >
            {categoryId && (
                <Button
                    size="medium"
                    variant="standard"
                    color="primary"
                    icon={<Icon icon={Icons.PlusFilled} color="fill-white" width={20} height={20} />}
                    iconPlacement="left"
                    onClick={onCreateTask}
                    id="emptyPageBtnCreateTaskCategory"
                >
                    Создать задание
                </Button>
            )}
            {!categoryId && (
                <>
                    <Button
                        size={"medium"}
                        className="rounded-lg whitespace-nowrap font-normal"
                        icon={<Icon icon={Icons.PlusFilled} color="fill-white" width={20} height={20} />}
                        iconPlacement={"left"}
                        onClick={() => {
                            onCreateCategory?.();
                        }}
                        id="emptyPageBtnCreateTask"
                    >
                        Создать категорию
                    </Button>
                    <Button
                        size={"medium"}
                        variant="outline"
                        color="secondary"
                        icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-primary" />}
                        iconPlacement={"left"}
                        onClick={onCreateTask}
                        id="emptyPageBtnCreateTask"
                    >
                        Создать задание
                    </Button>
                </>
            )}
        </Empty>
    );
};
