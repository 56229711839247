import { BaseRequest } from "../BaseRequest";

export class MaterialCategoryCreateRequest extends BaseRequest {
    public title: string;
}

export class MaterialCategoryEditRequest extends BaseRequest {
    public id: string;
    public title: string;
}
