import React from "react";
import clsx from "clsx";
import { InputProps, Input } from "Uikit";
import { Icon, Icons } from "Uikit/Icon/Icon";

export const Search = ({ placeholder = "Поиск", className, ...rest }: InputProps) => {
    return (
        <div className={clsx("flex items-center grow gap-4 min-w-[350px]", className)}>
            <div className="w-full flex justify-between relative grow">
                <span className="z-20 absolute inset-y-0 left-1 flex items-center pl-2">
                    <button className="z-20 p-1 focus:outline-none focus:shadow-outline">
                        <Icon
                            icon={Icons.Search}
                            width={18}
                            height={18}
                            color="fill-[#939393]"
                            className="2xl:!w-5.5 2xl:!h-5.5"
                        />
                    </button>
                </span>
                <Input
                    className={clsx(
                        "z-10 block w-[350px] 2xl:w-105 border border-[#E6E9ED] placeholder:text-[#939393] pl-10 2xl:pl-11",
                    )}
                    placeholder={placeholder}
                    {...rest}
                />
            </div>
        </div>
    );
};
