import { Expose, Type } from "class-transformer";

export class NotificationListResponseSender {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public date: string;
}
export class NotificationListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public objectId: string;

    @Expose()
    @Type(() => NotificationListResponseSender)
    public sender: NotificationListResponseSender;

    @Expose()
    @Type(() => String)
    public visibilityType: string;

    @Expose()
    @Type(() => String)
    public objectType: string;

    @Expose()
    @Type(() => String)
    public actionType: string;

    @Expose()
    @Type(() => String)
    public message: string;

    @Expose()
    @Type(() => Boolean)
    public readByUser: boolean;

    @Expose()
    public data: any;

    @Expose()
    @Type(() => Number)
    public timestamp: number;
}
