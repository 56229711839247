import { QueryClient, useQueryClient } from "react-query";
import { useCallback } from "react";
import { ID } from "../types";

export const useInvalidate = () => {
    const queryClient = useQueryClient();

    return useCallback(
        (collection: string | string[], id?: ID) => {
            invalidate(queryClient, collection, id);
        },
        [queryClient],
    );
};

export const invalidate = (queryClient: QueryClient, collection: string | string[], id?: ID) => {
    const queryKey = Array.isArray(collection) ? collection : [collection];
    queryClient.invalidateQueries(queryKey.concat("collection")).then();

    if (id) {
        queryClient.invalidateQueries(queryKey.concat(`${id}`)).then();
    }
};
