import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";
import { JobResponse } from "./JobResponse";

class TeamTreeResponseStatisticsItem {
    @Expose()
    @Type(() => Number)
    public resourceCount: number;

    @Expose()
    @Type(() => Number)
    public finishedPercent: number;

    @Expose()
    @Type(() => Number)
    public inProgressPercent: number;

    @Expose()
    @Type(() => Number)
    public availablePercent: number;
}

class TeamTreeResponseStatisticsItemQuiz {
    @Expose()
    @Type(() => Number)
    public resourceCount: number;

    @Expose()
    @Type(() => Number)
    public finishedPercent: number;

    @Expose()
    @Type(() => Number)
    public failedPercent: number;

    @Expose()
    @Type(() => Number)
    public availablePercent: number;
}

class TeamTreeResponseStatistics {
    @Expose()
    @Type(() => TeamTreeResponseStatisticsItem)
    public courseStats: TeamTreeResponseStatisticsItem;

    @Expose()
    @Type(() => TeamTreeResponseStatisticsItem)
    public programStats: TeamTreeResponseStatisticsItem;

    @Expose()
    @Type(() => TeamTreeResponseStatisticsItemQuiz)
    public quizStats: TeamTreeResponseStatisticsItemQuiz;
}

class TeamsItem {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;
}

export class TeamUser {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public email: string;

    @Expose()
    @Type(() => Number)
    public dateOfBirth: number;

    @Expose()
    @Type(() => JobResponse)
    public jobTitle: JobResponse;

    @Expose()
    @Type(() => Number)
    public rating: number;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor: string;

    @Expose()
    @Type(() => Number)
    public courseCount: number;

    @Expose()
    @Type(() => Number)
    public quizCount: number;

    @Expose()
    @Type(() => Number)
    public exerciseCount: number;

    @Expose()
    @Type(() => Number)
    public rank: number;

    @Expose()
    @Type(() => TeamsItem)
    public teams: TeamsItem[];

    @Expose()
    @Type(() => Number)
    public lastActivityDate: number;
}

export class TeamTreeResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public parentId?: string;

    @Expose()
    @Type(() => Number)
    public parentName?: number;

    @Expose()
    @Type(() => TeamTreeResponse)
    public subTeams: TeamTreeResponse[];

    @Expose()
    @Type(() => TeamUser)
    public users: TeamUser[];

    @Expose()
    @Type(() => TeamUser)
    public mentors: TeamUser[];

    @Expose()
    @Type(() => Number)
    public accessCount: number;

    @Expose()
    @Type(() => Number)
    public usersCount: number;

    @Expose()
    @Type(() => TeamTreeResponseStatistics)
    public statistics: TeamTreeResponseStatistics;
}

export class TeamInUserSpaceResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => Number)
    public accessCount: number;

    @Expose()
    @Type(() => Number)
    public mentorsCount: number;

    @Expose()
    @Type(() => Number)
    public usersCount: number;

    @Expose()
    @Type(() => String)
    public companyId: string;

    @Expose({ name: "subteams" })
    @Type(() => TeamInUserSpaceResponse, {})
    public subTeams: TeamInUserSpaceResponse[];

    @Expose()
    @Type(() => TeamUser)
    public mentors: TeamUser[];

    @Expose()
    @Type(() => TeamTreeResponseStatistics)
    public statistics?: TeamTreeResponseStatistics;
}

export class TeamTreeAllBasicInfoResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public parentId: string;

    @Expose()
    @Type(() => Number)
    public usersCount: number;

    @Expose()
    @Type(() => Number)
    public mentorsCount: number;

    @Expose()
    @Type(() => Boolean)
    public accessible: boolean;

    @Expose()
    @Type(() => TeamTreeAllBasicInfoResponse)
    public subTeams: TeamTreeAllBasicInfoResponse[];
}

export class TeamTreeCurrentUserResponseTeamUser {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor: string;
}

export class TeamTreeCurrentUserResponseTeam {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public parentId: string;

    @Expose()
    @Type(() => Number)
    public usersCount: number;

    @Expose()
    @Type(() => Number)
    public mentorsCount: number;

    @Expose()
    @Type(() => TeamTreeCurrentUserResponseTeamUser)
    public users: TeamTreeCurrentUserResponseTeamUser[];

    @Expose()
    @Type(() => TeamTreeCurrentUserResponseTeamUser)
    public mentors: TeamTreeCurrentUserResponseTeamUser[];
}

export class TeamTreeCurrentUserResponse extends BaseResponse {
    @Expose()
    @Type(() => TeamTreeCurrentUserResponseTeam)
    public teams: TeamTreeCurrentUserResponseTeam[];

    @Expose()
    @Type(() => TeamTreeCurrentUserResponseTeam)
    public mentorTeams: TeamTreeCurrentUserResponseTeam[];
}
