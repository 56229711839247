import { SortingState } from "@tanstack/react-table";
import BaseApi from "../BaseApi";
import { BaseRequest } from "../BaseRequest";
import { BaseArrayResponse, BasePaginationResponse, BaseResponse } from "../BaseResponse";
import {
    CourseCategoryReorderRequest,
    CourseCategoryRequest,
    CourseRequest,
    CourseUnArchiveRequest,
} from "../Requests/CourseRequest";
import {
    CourseCategoryResponse,
    CourseCategoryGetResponse,
    CourseListResponse,
    CourseReadResponse,
    CourseCreateResponse,
    ArchiveCountResponse,
    UiCoursesCategoryList,
    UiCoursesList,
    FilterRatingInfo,
    CourseReadResponseAdmin,
    CoursePublicNewCountResponse,
} from "../Responses/CourseResponse";
import { ArchiveResponse } from "../Responses/ArchiveResponse";
import { ResourceType } from "Enums";

export default class CourseApi extends BaseApi {
    CategoryGet = async (): Promise<BaseArrayResponse<CourseCategoryGetResponse>> => {
        return await this.Get(new BaseArrayResponse(CourseCategoryGetResponse), "/course-category/all");
    };

    CategoryCreate = async (data: CourseCategoryRequest): Promise<CourseCategoryResponse> => {
        return await this.Post(new CourseCategoryResponse(), "/course-category", data);
    };

    CategoryEdit = async (data: CourseCategoryRequest): Promise<CourseCategoryResponse> => {
        return await this.Post(new CourseCategoryResponse(), "/course-category/edit", data);
    };

    CategoryDelete = async (id: string): Promise<BaseResponse> => {
        return await this.Delete(new BaseResponse(), "/course-category/delete/" + id);
    };

    CategoryReorder = async (data: CourseCategoryReorderRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/course-category/reorder", data);
    };

    List = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: any },
    ): Promise<BasePaginationResponse<CourseListResponse>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(CourseListResponse),
            `/course/all?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    TrainingList = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<UiCoursesCategoryList>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(UiCoursesCategoryList),
            `/training?page=${page}&size=${size}&items=8${sorting}${filters}`,
        );
    };

    TrainingMore = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [key: string]: string },
    ): Promise<BasePaginationResponse<UiCoursesList>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(UiCoursesList),
            `/training/more?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    TrainingPassed = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [key: string]: string },
    ): Promise<BasePaginationResponse<UiCoursesList>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(UiCoursesList),
            `/training/passed?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    TrainingRequired = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: any },
    ): Promise<BasePaginationResponse<UiCoursesList>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(UiCoursesList),
            `/training/required?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    ArchiveList = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<ArchiveResponse>> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Get(
            new BasePaginationResponse(ArchiveResponse),
            `/course/archive/list?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    Copy = async (id: string): Promise<BaseResponse> => {
        return await this.Get(new BaseResponse(), `/course/copy/${id}`);
    };

    State = async (resourceIds: string[], state: string, type: string): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/resource-state", {
            resourceIds,
            state,
            type,
        } as BaseRequest);
    };

    StateCheck = async (resourceIds: string[], state: string, type: ResourceType): Promise<string[]> => {
        return await this.Post([], "/resource-state/check", {
            resourceIds,
            state,
            type,
        } as BaseRequest);
    };

    ReadUI = async (id: string): Promise<CourseReadResponse> => {
        return await this.Get(CourseReadResponse, "/course-user/" + id);
    };

    Read = async (id: string): Promise<CourseReadResponseAdmin> => {
        return await this.Get(CourseReadResponseAdmin, "/course/" + id);
    };

    Create = async (data: CourseRequest): Promise<CourseCreateResponse> => {
        return await this.Post(CourseCreateResponse, "/course", data);
    };

    Edit = async (data: CourseRequest): Promise<CourseCreateResponse> => {
        return await this.Post(CourseCreateResponse, "/course/edit", data);
    };

    UnArchive = async (data: CourseUnArchiveRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/course/unarchive", data);
    };

    ArchiveCount = async (): Promise<ArchiveCountResponse> => {
        return await this.Get(ArchiveCountResponse, "/archive/count");
    };

    FilterRatingInfo = async (): Promise<FilterRatingInfo> => {
        return await this.Get(FilterRatingInfo, "/course/filter-rating-info");
    };

    NewCount = async (): Promise<string> => {
        return await this.Get("", `/training/new/count`);
    };

    NewCountTabs = async (): Promise<CoursePublicNewCountResponse> => {
        return await this.Get(new CoursePublicNewCountResponse(), `/training/new/count/tabs`);
    };
}
