import React, { ReactNode } from "react";
import clsx from "clsx";

interface IPreloader {
    className: string;
    isShow: boolean;
    children: ReactNode;
}
export const Preloader = ({ className, isShow, children }: IPreloader) => {
    return (
        <div
            className={clsx(
                "absolute top-0 left-0 w-full overflow-hidden",
                isShow ? "h-screen opacity-100 z-10" : "h-0 opacity-0 -z-10",
                className,
            )}
            style={{ transition: "height 0s 0.3s, opacity 0.3s ease-in-out" }}
        >
            {children}
        </div>
    );
};
