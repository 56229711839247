import React, { useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import { PositionModal } from "../Modal/PositionModal";
import Api from "../../../../../../Api";
import { Button, Icon, Icons, Table } from "Uikit";
import { RowActionPopover } from "../RowActionPopover";
import { JobResponse } from "Api/Responses/JobResponse";
import { numWord } from "helpers/numWord";
import { FilterLink } from "Components/Common/FilterLink";
import { Empty } from "Uikit/Page/Empty";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

export const PositionsTab = () => {
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

    const [controlPositionModalOpen, setControlPositionModalOpen] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState<JobResponse | null>();
    const [controlPositionModalType, setControlPositionModalType] = useState<"add" | "edit" | "remove">("add");

    const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);
    const [search, setSearch] = useState<string>("");

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

    const { data: { Content: jobs = [], TotalPages, Total } = {}, isLoading } = useQuery(
        ["jobs", "collection", pagination, sorting, search],
        () => {
            const filtersData: any = {};

            if (search) {
                filtersData["searchQuery.contains"] = search;
            }

            return Api.Job.GetJobsPaginated(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const positionsColumns = React.useMemo<ColumnDef<JobResponse>[]>(
        () => [
            {
                header: "должность",
                accessorKey: "name",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <MultiClumpTooltip
                                className="group-hover:text-blue"
                                clamp={1}
                                label={String(info.getValue())}
                            />
                        </div>
                    );
                },
            },
            {
                header: "кол-во участников",
                accessorKey: "userCount",
                enableResizing: true,
                size: 170,
                cell: (info) => {
                    const value = Number(info.getValue());
                    return (
                        <div className="flex items-center space-x-3">
                            <FilterLink
                                href={`/admin/members?jobTitle.in=${info.row.original.id}`}
                                value={value}
                                name={`${value} ${numWord(value, ["участник", "участника", "участников"])}`}
                            />
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "",
                id: "buttons",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 90,
                cell: (info) => {
                    return (
                        <div className="flex" id={"memberSettingsPosTableGroupButton" + info.row.id}>
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    setSelectedPosition(info.row.original);
                                    setControlPositionModalType("edit");
                                    setControlPositionModalOpen(true);
                                }}
                            />

                            <RowActionPopover
                                onDelete={() => {
                                    setSelectedPosition(info.row.original);
                                    setControlPositionModalType("remove");
                                    setControlPositionModalOpen(true);
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const positionsControlButtons = (
        <div className="flex justify-end items-center space-x-5" id="settingsMembersPositionTabTableControlButtons">
            <Button
                size={"medium"}
                className="rounded-lg whitespace-nowrap font-medium"
                icon={<Icon className="mr-1.5" icon={Icons.PlusFilled} color="fill-white" width={17} height={17} />}
                iconPlacement={"left"}
                onClick={() => {
                    setControlPositionModalType("add");
                    setControlPositionModalOpen(true);
                }}
                id="adminSettingsBtnCreatePosition"
            >
                Создать должность
            </Button>
        </div>
    );

    const handleClosePositionModal = () => {
        setControlPositionModalOpen(false);
        setSelectedPosition(undefined);
    };

    return (
        <div id="settingsMembersPositionTab" className="pt-6 h-full">
            {!isLoading && !search && Total === 0 && (
                <Empty
                    title="Должности не созданы"
                    description="Создайте должности и распределите в них участников. Это расширит возможности поиска."
                >
                    <Button
                        size={"medium"}
                        className="rounded-lg whitespace-nowrap font-medium"
                        icon={
                            <Icon
                                className="mr-1.5"
                                icon={Icons.PlusFilled}
                                color="fill-white"
                                width={17}
                                height={17}
                            />
                        }
                        iconPlacement={"left"}
                        onClick={() => {
                            setControlPositionModalType("add");
                            setControlPositionModalOpen(true);
                        }}
                        id="adminSettingsBtnCreatePosition"
                    >
                        Создать должность
                    </Button>
                </Empty>
            )}
            {(search || (!isLoading && Total !== 0)) && (
                <Table
                    id="settingsMembersPositionTab"
                    columns={positionsColumns}
                    searchTitle="Поиск по имени"
                    data={jobs}
                    isFetching={isLoading}
                    rowSelection={rowSelection}
                    onRowSelectionChange={setRowSelection}
                    onColumnFiltersChange={setColumnFilters}
                    columnFilters={columnFilters}
                    controlButtons={positionsControlButtons}
                    emptyMessage={"По заданным параметрам результатов нет"}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    pageCount={TotalPages ?? 0}
                    pagination={pagination}
                    onPaginationChange={setPagination}
                    onSearch={setSearch}
                />
            )}
            <PositionModal
                isOpen={controlPositionModalOpen}
                onClose={handleClosePositionModal}
                selectedPosition={selectedPosition}
                type={controlPositionModalType}
                id="settingsMembersPositionModal"
            />
        </div>
    );
};
