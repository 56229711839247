import React from "react";
import { Icon, Icons } from "Uikit";
import { UiCoursesCategoryListItem, UiCoursesList } from "Api/Responses/CourseResponse";
import { formatStatusChangeTime } from "helpers/dateHelper";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

interface ITrainingCardMobile {
    course: UiCoursesCategoryListItem | UiCoursesList;
    showStatusChangeTime: boolean;
    onClick: (taskId: string) => void;
}
export const TrainingCardMobile = ({ course, showStatusChangeTime, onClick }: ITrainingCardMobile) => {
    return (
        <div className="flex p-2 mb-3 bg-background-light rounded-2xl" onClick={() => onClick(course.resourceId)}>
            <div className="mr-2 w-20 h-14 bg-gray-lighter rounded-lg">
                {course.logoId && (
                    <img
                        className="w-full h-full rounded-lg object-cover"
                        src={Api.Upload.GetLogo(course.logoId, LogoSize.THUMB_MINI)}
                        alt={course.title}
                    />
                )}
            </div>
            <div className="flex flex-col">
                <p className="mb-1 text-black p2-accent">{course.title}</p>
                <div className="flex items-center">
                    <span className="text-blue-drk p4">Курс</span>
                    <div className="flex items-center ml-3">
                        <Icon className="mr-0.5" icon={Icons.Trophy} width={10} height={10} color="fill-blue-drk" />
                        <span className="text-blue-drk p4">{course.ratingPoints}</span>
                    </div>
                    {showStatusChangeTime && (
                        <span className="ml-3 text-blue-drk p4">
                            {formatStatusChangeTime(course.deadlineTimestamp)}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
