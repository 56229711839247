import React from "react";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Badge } from "Uikit/Badge";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import { durationHours } from "helpers/dateHelper";
import clsx from "clsx";
import { formatTime } from "Pages/User/Tasks/utils";
import { FavoriteButton } from "Uikit/FavoriteButton/FavoriteButton";
import { MaterialType, materialTypesTranslate, ResourceType } from "Enums";
import isSameDay from "date-fns/isSameDay";
import { isYesterday } from "date-fns";
import { TVoidFunction } from "types";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

export interface CardProps {
    className?: string;
    required?: boolean;
    type?: MaterialType | ResourceType;
    deadline?: Dayjs;
    progress?: number;
    duration?: number;
    logoId?: string | null;
    score?: number; // оценка
    points?: number; // баллы
    isFavorite?: boolean;
    favoriteShowOnHover?: boolean;
    onFavoriteChange?: (isFavorite: boolean) => void;
    onClick?: TVoidFunction;
    completionTime?: number | null;
    hasNewLabel?: boolean;
}

export const Card = ({
    className = "w-full h-full",
    required = false,
    type,
    deadline,
    score,
    points,
    progress,
    duration,
    logoId,
    isFavorite,
    favoriteShowOnHover = true,
    onFavoriteChange = () => {},
    onClick = () => {},
    completionTime,
    hasNewLabel = false,
}: CardProps) => {
    const durationStr = formatTime(duration);

    const getPassedLabel = () => {
        return formatPassTime(completionTime, type);
    };

    return (
        <div
            className={clsx(
                "group relative bg-gray-stroke rounded-2xl 2xl:rounded-3xl cursor-pointer overflow-hidden",
                className,
            )}
            onClick={onClick}
        >
            {logoId && (
                <div className="relative w-full h-full bg-gray-lighter rounded-2xl 2xl:rounded-3xl overflow-hidden">
                    <img
                        className="w-full h-full rounded-2xl 2xl:rounded-3xl object-cover cursor-pointer group-hover:scale-125 transition-transform ease-in-out"
                        src={Api.Upload.GetLogo(logoId, LogoSize.THUMB_MINI)}
                        alt={logoId}
                    />
                </div>
            )}
            <div className="absolute top-3 left-3 right-11 2xl:top-3.75 2xl:left-3.75 2xl:right-[55px] flex justify-between">
                <div className="flex flex-wrap gap-1.5 2xl:gap-2 h-5.25 2xl:h-6.5">
                    {required && (
                        <div className="flex items-center justify-center rounded-md 2xl:rounded-lg w-5.25 2xl:w-6.5 bg-white ">
                            <Icon icon={Icons.Pin} width={15} height={15} className="2xl:!w-5 2xl:!h-5" />
                        </div>
                    )}
                    {type && <Badge color="blue-50">{materialTypesTranslate[type]}</Badge>}
                    {completionTime && (
                        <div className="flex items-center rounded-md 2xl:rounded-lg p3 gap-1 py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 bg-black-50 text-white">
                            {getPassedLabel()}
                        </div>
                    )}
                    {deadline && (
                        <Badge
                            color={getDeadlineColor(deadline)}
                            icon={Icons.Time}
                            classNames={{ icon: "!fill-white 2xl:!w-4.5 2xl:!h-4.5" }}
                        >
                            {formatDeadline(deadline)!}
                        </Badge>
                    )}
                </div>
            </div>
            <div className="hidden sm:block absolute top-3 right-3 2xl:top-3.75 2xl:right-3.75">
                <FavoriteButton
                    onChange={onFavoriteChange}
                    isFavorite={isFavorite}
                    className={clsx(
                        "hidden",
                        favoriteShowOnHover && !isFavorite ? "group-hover:!inline-flex" : "!inline-flex",
                    )}
                />
            </div>

            <div className="absolute bottom-3 left-3 right-3 2xl:bottom-3.75 2xl:left-3.75 2xl:right-3.75 flex space-x-1.5 2xl:space-x-2">
                {hasNewLabel && (
                    <div className="flex items-center py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 bg-red rounded-md 2xl:rounded-lg text-white p3 2xl:text-2sm">
                        <span>NEW</span>
                    </div>
                )}
                {score && (
                    <Badge icon={Icons.Star} classNames={{ icon: "!fill-white stroke-white" }} color="blue-50">
                        {score}
                    </Badge>
                )}
                {Number.isFinite(points) && (
                    <Badge icon={Icons.TrophyFilled} classNames={{ icon: "!fill-white" }} color="blue-50">
                        {points}
                    </Badge>
                )}

                <div className="flex flex-row-reverse grow ml-auto">
                    {!!duration && <Badge color="blue-50">{durationStr}</Badge>}
                </div>
            </div>

            <ProgressBar value={progress} />
        </div>
    );
};

const ProgressBar = ({ value: progress }: { value?: number }) => {
    if (!progress) {
        return null;
    }

    const factor = (progress ?? 0) > 1 ? 1 : 100;
    const percentProgress = progress && Math.min(100, Math.abs(Math.round(progress * factor)));
    const rightBorderRadius = (percentProgress ?? 0) >= 98 ? "rounded-br-xl" : "";

    return (
        <div className={"rounded-b-xl h-1.75 left-0 w-full absolute bottom-0 bg-blue-gray"}>
            <div
                style={{ width: `${percentProgress}%` }}
                className={clsx("rounded-bl-xl h-full bg-blue", rightBorderRadius)}
            />
        </div>
    );
};

const getDeadlineColor = (date: Dayjs) => {
    const now = dayjs();

    if (date.isBefore(now)) {
        return "red";
    }

    const duration = durationHours(now, date);

    if (duration > 168) {
        return "blue-50";
    }

    if (duration > 48) {
        return "blue";
    }
    return "red";
};

const isCurrentYear = (date?: Dayjs) => {
    const now = dayjs();
    return date && date.year() === now.year();
};

const formatDeadline = (date?: Dayjs) => {
    if (date) {
        const now = dayjs();

        if (date.isBefore(now)) {
            return "Просрочено";
        }

        const duration = durationHours(now, date);
        if (duration < 48) {
            return date.day() === now.day() ? "Сегодня" : "Завтра";
        }

        return date.locale("ru").format(`D MMM${isCurrentYear(date) ? "" : " YYYY"}`);
    }
};

const formatPassTime = (time?: number | null, type?: MaterialType | ResourceType) => {
    if (time) {
        const now = new Date();
        const date = new Date(time * 1000);

        if (isSameDay(now, date)) {
            if (type === ResourceType.COURSE) {
                return "Пройден сегодня";
            } else if (type === ResourceType.PROGRAM) {
                return "Пройдена сегодня";
            } else {
                return "";
            }
        } else if (isYesterday(date)) {
            if (type === ResourceType.COURSE) {
                return "Пройден вчера";
            } else if (type === ResourceType.PROGRAM) {
                return "Пройдена вчера";
            } else {
                return "";
            }
        } else {
            if (type === ResourceType.COURSE) {
                return `Пройден ${dayjs(date)
                    .locale("ru")
                    .format(`D MMM${isCurrentYear(dayjs(date)) ? "" : " YYYY"}`)}`;
            } else if (type === ResourceType.PROGRAM) {
                return `Пройдена ${dayjs(date)
                    .locale("ru")
                    .format(`D MMM${isCurrentYear(dayjs(date)) ? "" : " YYYY"}`)}`;
            } else {
                return "";
            }
        }
    }
};
