import BaseApi from "../BaseApi";
import { BaseResponse } from "../BaseResponse";
import { LoginRequest } from "../Requests/AuthRequest";

export default class AuthApi extends BaseApi {
    CheckIsAuth = async (): Promise<string> => {
        return await this.Get("", "/api/login/authenticated");
    };
    GetSession = async (data: LoginRequest): Promise<string> => {
        return await this.Post("", "/service/lms-authorization/api/authorization/session", data);
    };
    Logout = (): Promise<BaseResponse> => {
        return this.Post("", "/api/logout", {});
    };
}
