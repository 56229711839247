import { BaseRequest, BasePaginationRequest } from "../BaseRequest";
import { ResourceState, ResourceType } from "Enums";

export class ProgramCreateSection {
    title: string;
    courseIds: string[];
}

export class ProgramRequest extends BaseRequest {
    public id?: string;
    public title: string;
    public description: string;
    public logoId?: string | null;
    public categoryId: string;
    public publicAccess: boolean;
    public isRequired: boolean;
    public issueCertificate: boolean;
    public hideAvgReviewScore: boolean;
    public hideUserReviews: boolean;
    public state: ResourceState;
    public publicationTime?: number | null;
    public expirationTime?: number | null;
    public deadlineTime?: number | null;
    public managerUserId: string;
    public sections: ProgramCreateSection[];
}

export class ProgramListRequest extends BasePaginationRequest {
    // public "categoryId.equal": string | null;
    // public "categoryId.in": string | null;
}

export class ProgramCategoryRequest extends BaseRequest {
    public id?: string;
    public title: string;
}

export class ProgramCategoryReorderRequest extends BaseRequest {
    public categoryIds: string[];
}

export class ProgrammUnArchiveRequest extends BaseRequest {
    public categoryId: string;
    public programIds: string[];
}

export class FeedbackRequest extends BaseRequest {
    public userId: string;
    public resourceId: string;
    public type: ResourceType;
    public rating: number;
    public comment: string | null;
}

export class FeedbackListRequest extends BasePaginationRequest {
    public resourceId: string;
}

export class FeedbackCheckRequest extends BaseRequest {
    public feedbackIds: string[];
    public isApproved: boolean;
}
