import React, { useState, useEffect, Fragment, useMemo } from "react";
import { InfiniteData, useInfiniteQuery } from "react-query";
import { MultiValueGenericProps, ValueContainerProps, components } from "react-select";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import Api from "Api";
import { TaskPublicListResponse } from "Api/Responses/TaskPublicResponse";
import { TaskPublicResultsListRequest } from "Api/Requests/TaskPublicRequest";
import { Empty } from "Uikit/Page/Empty";
import { Icon, Icons, Button, SelectMultiValueRemove } from "Uikit";
import { InfiniteList } from "Components/InfiniteList/InfiniteList";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { TasksSearch } from "./TasksSearch";
import { TaskCardsInfiniteList } from "./TaskCardsInfiniteList";
import { TasksCardListMobile } from "./TasksCardListMobile";
import { BasePaginationResponse } from "Api/BaseResponse";
import Skeleton from "react-loading-skeleton";
import { tasksKeys, useChangeTaskFavoriteStatus } from "./task.hooks";
import { Preloader } from "Components/Preloader/Preloader";
import { useScreenSize } from "hooks/useMediaQuery";

interface IMultiLabelOption {
    readonly value: string;
    readonly label: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

const SelectAvatarMultiValueLabel =
    () =>
    ({ children }: MultiValueGenericProps<IMultiLabelOption>) => {
        return (
            <div className={"flex items-center py-1 pl-1.5 gap-2 cursor-pointer 2xl:py-2.25 2xl:pl-4 2xl:text-md"}>
                <span>{children}</span>
            </div>
        );
    };

const SelectValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
    const itemsLength = props.getValue().length;
    return (
        <components.ValueContainer {...props} className={"gap-1 !pr-7 relative"}>
            {children}
            {itemsLength > 0 && (
                <div className="absolute right-0 flex-center rounded-full top-1/2 -translate-y-1/2 h-5.5 min-w-[22px] text-sm bg-primary text-white 2xl:text-md 2xl:min-w-7 2xl:h-7">
                    {itemsLength}
                </div>
            )}
        </components.ValueContainer>
    );
};

export const TasksResults = () => {
    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const [categories, setCategories] = useState<{ label: string; value: string | undefined }[]>([]);
    const [tasks, setTasks] = useState<InfiniteData<BasePaginationResponse<TaskPublicListResponse>> | undefined>(
        undefined,
    );
    const [displayFilters, setDisplayFilters] = useState(false);
    const navigate = useNavigate();

    const [isFilterShow, setIsFilterShow] = useState(false);
    const sorting = [{ id: "lastModified", desc: true }];

    const parsedFiltersData = useMemo(() => {
        const filtersKeys = Object.keys(filters);
        const filtersData: any = {};

        for (const element of filtersKeys) {
            if (
                element === "complexity" // награда
            ) {
                filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"] || 0;
                let maxValue = filters[element]["maxValue"];
                if (!maxValue && tasks) {
                    maxValue =
                        tasks?.pages
                            .reduce((prev, curr) => [...prev, ...curr.Content], [] as TaskPublicListResponse[])
                            .reduce((prev, curr) => (prev.ratingPoints > curr.ratingPoints ? prev : curr), {
                                ratingPoints: 0,
                            }).ratingPoints ?? 0;
                }
                if (maxValue) {
                    filtersData[element + ".lessThanOrEqual"] = maxValue;
                }
            } else if (element === "approvedTime") {
                filtersData[element + ".greaterThanOrEqual"] = filters[element]["date"]["startDate"].toISOString();
                filtersData[element + ".lessThanOrEqual"] = filters[element]["date"]["endDate"].toISOString();
            } else if (element === "searchQuery") {
                filtersData[element + ".contains"] = filters[element];
            } else if (element === "progressStatus") {
                filtersData[element + ".equal"] = filters[element].value;
            } else if (element === "category") {
                filtersData["categoryId.in"] = filters[element].map((p: any) => p.value).join(",");
            } else {
                filtersData[element] = filters[element];
            }
        }

        return filtersData;
    }, [filters, tasks]);

    const dataQuery = useInfiniteQuery(
        tasksKeys.listResult(sorting, parsedFiltersData),
        async ({ pageParam }) => {
            const tasksRequest = new TaskPublicResultsListRequest();
            tasksRequest.size = pageParam ? 4 : 24;
            tasksRequest.page = pageParam ?? 0;
            tasksRequest.sort = sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "desc";

            tasksRequest.filters = parsedFiltersData;

            return await Api.TaskPublic.ResultsList(tasksRequest);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                return lastPage.Page + 1;
            },
        },
    );

    const { mutate: mutateFavoriteStatus } = useChangeTaskFavoriteStatus();

    const onFavoriteChange = (resource: TaskPublicListResponse, isFavorite: boolean) => {
        mutateFavoriteStatus({ resource, isFavorite, queryKeys: tasksKeys.listResult(sorting, parsedFiltersData) });
    };

    const { data, isLoading, isFetching, fetchNextPage, isFetched } = dataQuery;
    const dataLength = data?.pages[0].Content?.length ?? 0;

    useEffect(() => {
        setTasks(data);
        const dataLength = data?.pages[0].Content?.length ?? 0;
        if (dataLength > 0 || Object.keys(filters).length > 0) {
            setDisplayFilters(true);
        }
    }, [data, filters]);

    const filtersConfig = [
        {
            label: "Пройдено",
            fields: [
                {
                    accessor: "approvedTime",
                    type: "date-range",
                },
            ],
        },
        {
            label: "Награда, баллы",
            fields: [
                {
                    accessor: "complexity",
                    type: "range",
                    min:
                        data?.pages.length !== 0
                            ? data?.pages
                                  .reduce((prev, curr) => [...prev, ...curr.Content], [] as TaskPublicListResponse[])
                                  .reduce((prev, curr) => (prev.ratingPoints < curr.ratingPoints ? prev : curr), {
                                      ratingPoints: 0,
                                  }).ratingPoints ?? 0
                            : 0,
                    max:
                        data?.pages.length !== 0
                            ? data?.pages
                                  .reduce((prev, curr) => [...prev, ...curr.Content], [] as TaskPublicListResponse[])
                                  .reduce((prev, curr) => (prev.ratingPoints > curr.ratingPoints ? prev : curr), {
                                      ratingPoints: 0,
                                  }).ratingPoints ?? 0
                            : 0,
                },
            ],
        },
        // {
        //     label: "Результат",
        //     fields: [
        //         {
        //             accessor: "status",
        //             type: "select",
        //             placeholder: "Выберите результат",
        //             options: [
        //                 {
        //                     label: "Все",
        //                     value: "",
        //                 },
        //                 {
        //                     label: "Пройдено",
        //                     value: "PASSED",
        //                 },
        //                 {
        //                     label: "Провалено",
        //                     value: "FAILED",
        //                 },
        //             ],
        //         },
        //     ],
        // },
        {
            label: "Категория",
            fields: [
                {
                    accessor: "category",
                    type: "multi-select",
                    default: "",
                    placeholder: "Выберите категорию",
                    options: categories,
                    components: {
                        MultiValueLabel: SelectAvatarMultiValueLabel(),
                        MultiValueRemove: SelectMultiValueRemove,
                        ValueContainer: SelectValueContainer,
                    },
                },
            ],
        },
    ];

    const onCardClick = (solutionId: string) => {
        navigate(`/task/${solutionId}/statistics`);
    };

    useEffect(() => {
        const fetch = async () => {
            // Категория
            const categories = await Api.TaskCategoryApi.List();
            const categoriesOptions = categories.Content.map(({ id, title }) => {
                return { label: title, value: id as string | undefined };
            });
            setCategories(categoriesOptions);
        };
        fetch().then();
    }, []);

    const debounceSearch = debounce((value) => {
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                searchQuery: value,
            };
            if (!value) {
                delete newFilters.searchQuery;
            }
            return newFilters;
        });
    }, 500);

    const { size } = useScreenSize();
    const isSmall = size === "small";

    return (
        <>
            {isSmall && (
                <div
                    className="absolute top-3.5 right-10 block sm:hidden z-[100]"
                    onClick={() => setIsFilterShow(true)}
                >
                    <Icon icon={Icons.Filter} width={18} height={18} color="stroke-blue-drk" />
                </div>
            )}
            <Filter
                isActive={isFilterShow}
                setIsActive={setIsFilterShow}
                configuration={filtersConfig as IFilterRow[]}
                filters={filters}
                onChange={setFilters}
            />
            {displayFilters && (
                <div className="hidden sm:flex justify-between mb-5 2xl:mb-6.25">
                    <TasksSearch onChange={debounceSearch} />
                    <Button
                        variant="outline"
                        color="secondary"
                        size="medium"
                        className="border-[#E6E9ED] rounded-lg font-medium"
                        icon={
                            <Icon
                                icon={Icons.Filter}
                                width={20}
                                height={20}
                                color="stroke-blue"
                                className="2xl:!w-6.25 2xl:!h-6.25"
                            />
                        }
                        iconPlacement={"left"}
                        onClick={() => setIsFilterShow(true)}
                        id="userTasksBtnFilter"
                    >
                        Фильтры
                    </Button>
                </div>
            )}
            {data && dataLength > 0 && (
                <InfiniteList
                    isLoading={isFetching}
                    hasMore={!data.pages[data.pages.length - 1].Last}
                    onLoadMore={() => {
                        fetchNextPage().then();
                    }}
                    className={"flex-center flex-col w-full sm:w-[1174px] 2xl:w-[1464px]"}
                    id="userTasksResults"
                    isSkeletonLoader={true}
                >
                    <div className="hidden sm:block w-full">
                        <TaskCardsInfiniteList dataLength={dataLength}>
                            {data.pages.map((page, index) => (
                                <Fragment key={`page__${index}`}>
                                    {page.Content.map((item) => {
                                        return (
                                            <TaskCardsInfiniteList.TaskCard
                                                key={item.id}
                                                statusChangeTimePre={"Пройдено"}
                                                task={item}
                                                onClick={onCardClick}
                                                showStatusChangeTime={true}
                                                isDone={true}
                                                onFavoriteChange={onFavoriteChange}
                                            />
                                        );
                                    })}
                                </Fragment>
                            ))}
                        </TaskCardsInfiniteList>
                    </div>
                    {isSmall && (
                        <div className="block sm:hidden w-full">
                            <TasksCardListMobile
                                id="userTasksResults_mobile"
                                isFinished={true}
                                isReview={false}
                                data={data.pages.map((p) => p.Content).flat()}
                            />
                        </div>
                    )}
                </InfiniteList>
            )}
            <div className="relative">
                <Preloader className="flex flex-col" isShow={!isFetched}>
                    <div className="mb-5 w-full h-10 rounded-2xl overflow-hidden leading-0">
                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                    </div>
                    <div className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] gap-y-5 sm:gap-x-6.5 2xl:gap-x-8 sm:gap-y-9">
                        {Array.from(Array(16).keys()).map((p) => {
                            return (
                                <div key={p}>
                                    <div className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                    </div>
                                    <div className="leading-5 line-clamp-2 pt-3">
                                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Preloader>
            </div>
            {!isLoading && dataLength === 0 && Object.keys(filters).length === 0 && (
                <div className="h-full flex justify-center">
                    <Empty
                        title="Ничего не пройдено"
                        description={
                            <span>
                                Все, что было пройдено, будет храниться
                                <br />в этой вкладке
                            </span>
                        }
                        topElement={
                            <div className="flex-center mb-4 2xl:mb-5">
                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.EmojiSad}
                                        width={"36px"}
                                        height={"36px"}
                                        color={"fill-primary"}
                                        className="2xl:!w-11.25 2xl:!h-11.25"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
            {!isLoading && dataLength === 0 && Object.keys(filters).length !== 0 && (
                <div className="h-full flex justify-center">
                    <Empty
                        title="Ничего не найдено"
                        description="По заданным параметрам результатов нет"
                        topElement={
                            <div className="flex-center mb-4 2xl:mb-5">
                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.EmojiSad}
                                        width={"36px"}
                                        height={"36px"}
                                        color={"fill-primary"}
                                        className="2xl:!w-11.25 2xl:!h-11.25"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
        </>
    );
};
