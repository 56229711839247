import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useBlocker } from "react-router-dom";
import { setIsBack, setIsFavorite, setIsHidden, setIsNotifications, setTitle } from "../../../slices/headerSlice";
import { Notifications } from "Components/Notifications/Notifications";

export const MobileNotifications = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Уведомления"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIsNotifications(true));
    }, [dispatch]);

    useBlocker(() => {
        dispatch(setIsNotifications(false));
        return false;
    });

    return <Notifications />;
};
