import React, { ChangeEvent, useEffect, useState } from "react";
import { Input } from "Uikit";
import { wholeNumberRegexp } from "Uikit/Forms/Input";

export const RangeField = ({ minLimit, maxLimit, accessor, value, onChange }: any) => {
    const [range, setRange] = useState<{ minValue: string; maxValue: string }>(value || { minValue: "", maxValue: "" });

    const onMinRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const minRange = e.target.value;

        if (minRange || value?.minValue) {
            onChange(accessor, { ...range, minValue: minRange });
            return;
        }

        setRange((prevRange) => ({ ...prevRange, minValue: minRange }));
    };

    const onMaxRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const maxRange = e.target.value;

        if (maxRange || value?.maxValue) {
            onChange(accessor, { ...range, maxValue: maxRange });
            return;
        }

        setRange((prevRange) => ({ ...prevRange, maxValue: maxRange }));
    };

    const onMinRangeBlur = () => {
        if (value && !range.minValue && range?.minValue !== value?.minValue) {
            onChange(accessor, { ...range });
            return;
        }

        if (!range.minValue) {
            return;
        }

        const newRange = { ...range };

        const isMinValueGreaterThanMaxLimit = maxLimit && Number(range.minValue) > maxLimit;
        const isMinValueLessThanMinLimit = minLimit && Number(range.minValue) < minLimit;
        const isMinValueGreaterThanMaxValue =
            range.minValue && range.maxValue && Number(range.minValue) > Number(range.maxValue);

        if (isMinValueLessThanMinLimit || (!range.maxValue && isMinValueGreaterThanMaxLimit)) {
            newRange.minValue = minLimit || "";
        } else if (isMinValueGreaterThanMaxValue) {
            newRange.minValue = range.maxValue;
        }

        onChange(accessor, newRange);
    };

    const onMaxRangeBlur = () => {
        if (value && !range.maxValue && range?.maxValue !== value?.maxValue) {
            onChange(accessor, { ...range });

            return;
        }

        if (!range.maxValue) {
            return;
        }

        const newRange = { ...range };

        const isMaxValueGreaterThanMaxLimit = maxLimit && Number(range.maxValue) > Number(maxLimit);
        const isMaxValueLessThanMinLimit = minLimit && Number(range.maxValue) < Number(minLimit);
        const isMaxValueLessThanMinValue =
            range.minValue && range.maxValue && Number(range.maxValue) < Number(range.minValue);

        if (isMaxValueGreaterThanMaxLimit || (!range.minValue && isMaxValueLessThanMinLimit)) {
            newRange.maxValue = maxLimit || "";
        } else if (isMaxValueLessThanMinValue) {
            newRange.maxValue = newRange.minValue;
        }

        onChange(accessor, newRange);
    };

    useEffect(() => {
        setRange(value || { minValue: "", maxValue: "" });
    }, [value]);

    return (
        <div className="flex mt-3 sm:mt-4 space-x-2 2xl:space-x-6">
            <Input
                before="от"
                placeholder={minLimit}
                cutRegExp={wholeNumberRegexp}
                value={range.minValue}
                onChange={onMinRangeChange}
                onBlur={onMinRangeBlur}
                className="2xl:!px-12"
            />
            <Input
                before="до"
                placeholder={maxLimit}
                cutRegExp={wholeNumberRegexp}
                value={range.maxValue}
                onChange={onMaxRangeChange}
                onBlur={onMaxRangeBlur}
                className="2xl:!px-12"
            />
        </div>
    );
};
