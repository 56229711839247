import React, { useId } from "react";
import { FormGroup, Input, Label, TextareaAutoSizable, Toggle /* , Icon, Icons */ } from "Uikit";
import { testingRegexp } from "Uikit/Forms/Input";
import { TaskReadResponseItem } from "Api/Responses/TaskResponse";
import { ITaskQuestionProps } from "./TaskContentItem";

export const TaskQuestionText = ({ taskItem, onEditTaskItem, errors = {}, expanded = false }: ITaskQuestionProps) => {
    const id = useId();
    const maxLength = 1024;
    const maxReviewerNoteLength = 256;

    const onChangeFieldValueHandler = (fieldName: keyof TaskReadResponseItem, value: string | boolean) => {
        (taskItem as unknown as Record<typeof fieldName, string | boolean>)[fieldName] = value;
        onEditTaskItem(taskItem);
    };

    return (
        <>
            {expanded ? (
                <>
                    <FormGroup>
                        <Label isRequired={taskItem.mandatory}>Текст вопроса</Label>
                        <TextareaAutoSizable
                            placeholder="Введите текст вопроса"
                            value={taskItem.question}
                            error={errors?.question}
                            maxLength={maxLength}
                            cutRegExp={testingRegexp}
                            className="resize-none"
                            id={id + "adminNewTaskContentTabInputtextQuestion"}
                            onChange={(e) => {
                                onChangeFieldValueHandler("question", e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={false}>Краткое пояснение</Label>
                        <TextareaAutoSizable
                            placeholder="Введите краткое пояснение"
                            value={taskItem.description}
                            className="resize-none"
                            id={id + "adminNewTaskContentTabInputDescription"}
                            maxLength={maxReviewerNoteLength}
                            cutRegExp={testingRegexp}
                            onChange={(e) => {
                                onChangeFieldValueHandler("description", e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={taskItem.mandatory}>Комментарий для проверяющего</Label>
                        <TextareaAutoSizable
                            placeholder="Введите комментарий"
                            value={taskItem.reviewerNote}
                            error={errors?.reviewerNote}
                            maxLength={maxLength}
                            cutRegExp={testingRegexp}
                            className="resize-none"
                            id={id + "adminNewTaskContentTabInputComment"}
                            onChange={(e) => {
                                onChangeFieldValueHandler("reviewerNote", e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="flex items-center mt-2 pt-7.5 border-t border-gray-blue">
                        <Toggle
                            label="Обязательный вопрос"
                            enabled={taskItem.mandatory}
                            onChange={(e) => {
                                onChangeFieldValueHandler("mandatory", e);
                            }}
                            id={id + "adminNewTaskContentTabToggleRequired"}
                        />
                    </FormGroup>
                </>
            ) : (
                <FormGroup
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Label isRequired={taskItem.mandatory}>{taskItem.question}</Label>
                    {taskItem.description && <p className="text-sm pb-2.5 text-gray-dark">{taskItem.description}</p>}
                    <Input
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        // не в MVP
                        // after={<Icon icon={Icons.Scan} width={18} height={18} color="fill-blue-drk" />}
                        className="pointer-events-none"
                        id={id + "adminNewTaskContentTabInputScan"}
                    />
                </FormGroup>
            )}
        </>
    );
};
