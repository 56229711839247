import React, { forwardRef, useState } from "react";
import { SingleValue } from "react-select";
import { FormikErrors, useFormikContext } from "formik";
import { Section } from "Uikit/Forms/Section";
import { ComboBox, FormGroup, Icon, Icons, Input, Label, Textarea, Toggle, CoverInput } from "Uikit";
import { Complexity, ResourceState } from "Enums";
import Api from "Api";
import { TaskReadResponse } from "Api/Responses/TaskResponse";
import { TaskCategoryTreeResponse } from "Api/Responses/TaskCategoryResponse";
import { CategoryModal } from "Uikit/CategoryModalNew/CategoryModal";
import { CategoryMenuList, UsersOption, UsersSingleValue } from "Uikit/Forms/SelectComponents";
import { AccountableUser } from "Api/Responses/UserResponse";
import { DateTimeSelector } from "Uikit/DateTimeSelector/DateTimeSelector";
import { numCapEnd } from "helpers/numCapEnd";

interface IRatingOption {
    label: Complexity;
    value: number | string;
}

interface IAdministratorsOption {
    label: string;
    value: string;
    payload: AccountableUser;
}

interface ITaskSettings {
    task: TaskReadResponse;
    onChange: (task: TaskReadResponse, watchDirty?: boolean) => void;
    onChangeCover: (data: File | null, imgBase64?: string) => void;
    categories: TaskCategoryTreeResponse[];
    onChangeCategories: (category: any) => void;
    rating: IRatingOption[];
    responsibles: IAdministratorsOption[];
    defaultLogos: string[];
    isPassingLimitEnabled: boolean;
    setIsPassingLimitEnabled: (arg: boolean) => void;
}
export const TaskSettings = forwardRef(
    ({
        task,
        onChange,
        onChangeCover,
        categories,
        onChangeCategories,
        rating,
        responsibles,
        defaultLogos,
        isPassingLimitEnabled,
        setIsPassingLimitEnabled,
    }: ITaskSettings) => {
        const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
        const { errors, setFieldValue } = useFormikContext();

        const onAddCategoryClick = () => {
            setIsCategoryModalOpen(true);
        };
        const onDefaultImageChange = (id: string) => {
            onChange({
                ...task,
                logoId: id,
            });
        };
        const onCategorySubmit = async (title: string) => {
            try {
                const response = await Api.TaskCategoryApi.Create({ title });
                onChangeCategories({ id: response.id, title: title });

                onChange({ ...task, category: { id: response.id, title: title } });
                await setFieldValue("category.id", response.id);

                return response;
            } catch (error) {
                return error;
            }
        };

        return (
            <>
                <Section label="Внешний вид">
                    <FormGroup>
                        <Label>Обложка задания</Label>
                        <CoverInput
                            btn={false}
                            logo={task?.logoId || null}
                            onChange={onChangeCover}
                            onChangeDefaultLogo={onDefaultImageChange}
                            defaultLogoIds={defaultLogos}
                            id="adminNewTask"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={true}>Заголовок задания</Label>
                        <Input
                            isRequired={true}
                            value={task.title}
                            onChange={({ target: { value } }) => {
                                onChange({ ...task, title: value });
                                setFieldValue("title", value).then();
                            }}
                            placeholder="Введите заголовок"
                            error={(errors as FormikErrors<TaskReadResponse>).title}
                            id="adminNewTaskInputName"
                            maxLength={128}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Краткое описание</Label>
                        <Textarea
                            value={task.description}
                            onChange={(e) => onChange({ ...task, description: e.target.value })}
                            placeholder="Введите краткое описание"
                            id="adminNewTaskInputDescription"
                            maxLength={1024}
                        />
                    </FormGroup>
                </Section>
                <Section label="Оценивание">
                    <FormGroup>
                        <Label isRequired={true}>Сложность задания</Label>
                        <ComboBox
                            isSearchable={false}
                            placeholder=""
                            value={rating[rating.findIndex((p) => p.label === task.complexity)]}
                            getOptionValue={(option) => {
                                return String(option.value);
                            }}
                            getOptionLabel={({ label, value }) => {
                                if ([Complexity.LOW, Complexity.MEDIUM, Complexity.HIGH].includes(label)) {
                                    let prefix = "";
                                    switch (label) {
                                        case Complexity.LOW:
                                            prefix = "Низкая";
                                            break;
                                        case Complexity.MEDIUM:
                                            prefix = "Средняя";
                                            break;
                                        case Complexity.HIGH:
                                            prefix = "Высокая";
                                    }
                                    return `${prefix} (${value} ${numCapEnd(
                                        { one: "балл", two: "балла", few: "баллов" },
                                        Number(value),
                                    )})`;
                                }
                                return `Без оценки (0 баллов)`;
                            }}
                            options={rating}
                            onChange={(option: SingleValue<any>) => {
                                const value = option.label.toUpperCase();
                                onChange({ ...task, complexity: value });
                                setFieldValue("complexity", value).then();
                            }}
                            error={(errors as FormikErrors<TaskReadResponse>).complexity}
                            id="adminNewTaskComboBoxComplexity"
                        />
                    </FormGroup>
                </Section>
                <Section label="Месторасположение">
                    <FormGroup>
                        <Label isRequired={true}>Категория</Label>
                        <ComboBox
                            isSearchable
                            placeholder="Выберите категорию"
                            value={categories[categories.findIndex((p) => task.category.id === p.id)]}
                            getOptionValue={(option) => option.id.toString()}
                            getOptionLabel={(option) => option.title}
                            // options={categories}
                            options={categories}
                            addOptionClick={onAddCategoryClick}
                            components={{ MenuList: CategoryMenuList, NoOptionsMessage: () => <div></div> }}
                            onChange={({ id, title }: SingleValue<any>) => {
                                onChange({ ...task, category: { id, title } });
                                setFieldValue("category.id", id).then();
                            }}
                            error={(errors as FormikErrors<TaskReadResponse>).category?.id}
                            id="adminNewTaskComboBoxCategory"
                        />
                    </FormGroup>
                </Section>
                <Section label="Контроль">
                    <FormGroup>
                        <Label isRequired={true}>Ответственный</Label>
                        <ComboBox
                            isSearchable
                            placeholder="Выберите ответственного"
                            getOptionValue={(option) => option.payload.id}
                            value={
                                task.accountableUser
                                    ? responsibles[
                                          responsibles.findIndex((p) => task.accountableUser.id === p.payload.id)
                                      ]
                                    : undefined
                            }
                            // value={task.accountableUser}
                            components={{
                                SingleValue: UsersSingleValue,
                                Option: UsersOption,
                            }}
                            options={responsibles}
                            onChange={({ payload }: SingleValue<any>) => {
                                onChange({ ...task, accountableUser: payload });
                                setFieldValue("accountableUser.id", payload.id).then();
                            }}
                            error={(errors as FormikErrors<TaskReadResponse>).accountableUser?.id}
                            id="adminNewTaskComboBoxManager"
                        />
                    </FormGroup>
                </Section>
                <Section label="Публикация">
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto"
                            label="Назначить отложенную дату публикации"
                            enabled={!!task.publicationTime}
                            onChange={(p) => {
                                const defaultDate = new Date();

                                defaultDate.setHours(10);
                                defaultDate.setMinutes(0);

                                defaultDate.setSeconds(0);
                                defaultDate.setMilliseconds(0);

                                const updatedTask: TaskReadResponse = {
                                    ...task,
                                    publicationTime: p ? defaultDate.getTime() / 1000 + 86400 : undefined,
                                };
                                if (p && task.state === ResourceState.ACTIVE) {
                                    updatedTask.state = ResourceState.HIDDEN;
                                }
                                onChange(updatedTask);
                            }}
                            id="adminNewTaskTogglePublicLater"
                        />
                        {!!task.publicationTime && (
                            <DateTimeSelector
                                isPastTime={false}
                                error={
                                    task.publicationTime &&
                                    task.archivingTime &&
                                    task.archivingTime <= task.publicationTime
                                        ? "Дата снятия с публикации не может быть раньше даты публикации"
                                        : ""
                                }
                                dateTime={task.publicationTime}
                                onChange={(dateTime) => onChange({ ...task, publicationTime: dateTime })}
                                showTimeInput
                            />
                        )}
                    </FormGroup>
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto"
                            enabled={!!task.archivingTime}
                            onChange={(p) => {
                                const defaultDate = new Date();

                                defaultDate.setHours(10);
                                defaultDate.setMinutes(0);

                                defaultDate.setSeconds(0);
                                defaultDate.setMilliseconds(0);

                                const updatedTask: TaskReadResponse = {
                                    ...task,
                                    archivingTime: p ? defaultDate.getTime() / 1000 + 86400 : undefined,
                                };
                                if (p && task.state === ResourceState.HIDDEN) {
                                    updatedTask.state = !task.publicationTime
                                        ? ResourceState.ACTIVE
                                        : ResourceState.HIDDEN;
                                }
                                onChange(updatedTask);
                            }}
                            label="Назначить дату снятия с публикации"
                            id="adminNewTaskTogglePublicEnd"
                        />
                        {!!task.archivingTime && (
                            <DateTimeSelector
                                isPastTime={false}
                                error={
                                    task.publicationTime &&
                                    task.archivingTime &&
                                    task.archivingTime <= task.publicationTime
                                        ? "Дата снятия с публикации не может быть раньше даты публикации"
                                        : ""
                                }
                                dateTime={task.archivingTime}
                                onChange={(dateTime) => onChange({ ...task, archivingTime: dateTime })}
                                showTimeInput
                            />
                        )}
                    </FormGroup>
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto"
                            enabled={!!task.deadlineTime}
                            onChange={(p) => {
                                const defaultDate = new Date();

                                defaultDate.setHours(10);
                                defaultDate.setMinutes(0);

                                onChange({
                                    ...task,
                                    deadlineTime: p ? defaultDate.getTime() / 1000 + 86400 : undefined,
                                });
                            }}
                            label="Назначить дедлайн"
                            tip="Задание получит статус «Просрочено», но будет доступно для прохождения"
                            id="adminNewTaskToggleDeadLine"
                        />
                        {!!task.deadlineTime && (
                            <DateTimeSelector
                                isPastTime={true}
                                dateTime={task.deadlineTime}
                                onChange={(dateTime) => onChange({ ...task, deadlineTime: dateTime })}
                                showTimeInput
                            />
                        )}
                    </FormGroup>
                </Section>
                <Section label="Прочее" className="!border-0">
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto min-h-[40px]"
                            enabled={isPassingLimitEnabled}
                            onChange={(p) => {
                                const taskUpdated = task.passingLimit
                                    ? task
                                    : {
                                          ...task,
                                          passingLimit: 1,
                                      };
                                onChange(taskUpdated);
                                setIsPassingLimitEnabled(p);
                            }}
                            label="Лимит на количество прохождений задания"
                            tip="Количество выполнений задания будет ограничено. Если настройка выключена, то задание можно выполнять неограниченное количество раз."
                            id="adminNewMaterialToggleLimit"
                        />
                        {isPassingLimitEnabled && (
                            <div className="max-w-30">
                                <Input
                                    type="number"
                                    className="text-center"
                                    value={task.passingLimit as string | number | undefined}
                                    onChange={(e) => {
                                        let val: number | undefined = Number(e.target.value);
                                        const limit = 99;
                                        const min = 0;
                                        val = val <= min ? undefined : val > limit ? limit : val;

                                        onChange({
                                            ...task,
                                            passingLimit: val,
                                        });
                                    }}
                                    onBlur={(e) => {
                                        let val = Number(e.target.value);
                                        const min = 1;

                                        if (val < min) {
                                            val = min;
                                        }
                                        onChange({ ...task, passingLimit: val });
                                    }}
                                    before={
                                        <div
                                            className={`cursor-pointer ${
                                                task.passingLimit === 1 &&
                                                " pointer-events-none cursor-default opacity-50"
                                            }`}
                                            onClick={() => {
                                                const val =
                                                    task.passingLimit && task.passingLimit > 1
                                                        ? task.passingLimit - 1
                                                        : 1;
                                                onChange({
                                                    ...task,
                                                    passingLimit: val,
                                                });
                                            }}
                                            id="adminNewMaterialBtnDecrementLimit"
                                        >
                                            <Icon icon={Icons.Minus} width={20} height={20} color="fill-[#878E9C]" />
                                        </div>
                                    }
                                    after={
                                        <div
                                            className={`cursor-pointer ${
                                                task.passingLimit === 99 &&
                                                " pointer-events-none cursor-default opacity-50"
                                            }`}
                                            onClick={() => {
                                                const val = task.passingLimit ? task.passingLimit + 1 : 1;
                                                onChange({
                                                    ...task,
                                                    passingLimit: val,
                                                });
                                            }}
                                            id="adminNewMaterialBtnIncrementLimit"
                                        >
                                            <Icon icon={Icons.Plus} width={20} height={20} color="fill-[#878E9C]" />
                                        </div>
                                    }
                                    id="adminNewMaterialInputLimit"
                                />
                            </div>
                        )}
                    </FormGroup>
                    {/*
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto min-h-[40px]"
                            enabled={task.geoLocationRequired}
                            onChange={(p) => onChange({ ...task, geoLocationRequired: p })}
                            label="Сбор геолокации"
                            tip="При отправке задания, приложение сохранит точку, откуда оно было отправлено"
                            id="adminNewMaterialToggleGetLocation"
                        />
                    </FormGroup>
                    */}
                </Section>
                <CategoryModal
                    isOpen={isCategoryModalOpen}
                    onClose={() => setIsCategoryModalOpen(false)}
                    onSubmit={onCategorySubmit}
                />
            </>
        );
    },
);
