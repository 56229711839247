import React from "react";
import { useNavigate } from "react-router-dom";
import { TasksCardMobile } from "./TasksCardMobile";
import { TaskPublicListResponse } from "Api/Responses/TaskPublicResponse";

interface ITestsCardListMobile {
    id?: string;
    isFinished: boolean;
    isReview: boolean;
    title?: string;
    data: TaskPublicListResponse[];
}
export const TasksCardListMobile = ({ id, isFinished, isReview, title, data }: ITestsCardListMobile) => {
    const navigate = useNavigate();

    return (
        <div className="mb-7" key={id}>
            {title && (
                <h3 id={id + "Title"} className="mb-4 text-black">
                    {title}
                </h3>
            )}
            {data.map((task) => {
                return (
                    <TasksCardMobile
                        key={task.id + task.statusChangeTime}
                        task={task}
                        onClick={() =>
                            isFinished || isReview
                                ? navigate(`/task/${task.id}/statistics`)
                                : navigate(`/task/${task.id}?parent=tests`)
                        }
                    />
                );
            })}
        </div>
    );
};
