import React, { useState, useRef } from "react";
import { Icon, Icons } from "Uikit";
import { getObjectUniqueId } from "helpers/objectUniqueId";
import { TUserAttachments } from "Api/Requests/TaskPublicRequest";
import { TaskQuestionFileAttachment } from "./TaskQuestionFileAttachment";

interface ITaskQuestionFileProps {
    onChange: (event: any) => File | null;
    onDeleteAttachment: (deletedAttchmentId: string, file: File | null) => void;
    onLoadAttachment: (loadedAttchmentId: TUserAttachments, file: File | null) => void;
    accept: string;
    value?: TUserAttachments[];
    onAttachmentClick: ({
        type,
        id,
        size,
        name,
        extension,
        disposableLink,
    }: {
        type: string;
        id: string;
        size: string;
        name: string;
        extension: string;
        disposableLink: string;
    }) => void;
}

export const TaskQuestionFile = ({
    onChange,
    onLoadAttachment,
    onDeleteAttachment,
    accept,
    value = [],
    onAttachmentClick,
}: ITaskQuestionFileProps) => {
    const [handledFiles, setHandledFiles] = useState<any[]>([]);
    const fileInputRef = useRef(null);

    const deleteHandledFile = (fileIndex: number) => {
        const newFiles = [...handledFiles].filter((item, itemIndex) => itemIndex !== fileIndex);

        setHandledFiles(newFiles);
    };

    return (
        <div className="flex flex-col gap-4 2xl:gap-5">
            {handledFiles?.length <= 9 && (
                <div className="relative flex items-center w-full px-4 2xl:px-5 py-4.5 2xl:py-5.5 rounded-lg 2xl:rounded-2lg bg-white text-blue gap-2 2xl:gap-2.5">
                    <Icon
                        icon={Icons.Clip}
                        width="20px"
                        height="20px"
                        color="fill-blue"
                        className="2xl:!w-6.25 2xl:!h-6.25"
                    />
                    <span className="2xl:text-md">Прикрепить файл</span>
                    <input
                        ref={fileInputRef}
                        type="file"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                        }}
                        onChange={(event) => {
                            const file = onChange(event);
                            if (file) {
                                setHandledFiles((prevFiles) => {
                                    return [
                                        ...prevFiles,
                                        {
                                            file,
                                            fileId: null,
                                        },
                                    ];
                                });
                                onLoadAttachment({ file }, file);
                            }
                        }}
                        accept={accept}
                    />
                </div>
            )}
            {value.map((valueItem, index) => {
                return (
                    <TaskQuestionFileAttachment
                        key={`file__${getObjectUniqueId(valueItem)}`}
                        fileObj={valueItem}
                        onAttachmentClick={onAttachmentClick}
                        onLoadAttachment={onLoadAttachment}
                        isUploaded={!!valueItem.id}
                        onDelete={() => {
                            deleteHandledFile(index);
                        }}
                        clearFileInput={() => {
                            (fileInputRef.current as unknown as HTMLInputElement).value = "";
                        }}
                        onDeleteAttachment={onDeleteAttachment}
                    />
                );
            })}
        </div>
    );
};
