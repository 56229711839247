import React from "react";
import { MultiValueGenericProps } from "react-select";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";

export interface IMultiLabelOption {
    readonly value: string;
    readonly label: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

interface ISelectAvatarMultiValueLabelProps {
    withAvatar?: boolean;
}

// Кастомный компонент для react-select
// Отображение выбранного значения в мультиселекте с аватаркой/без аватарки
export const SelectAvatarMultiValueLabel =
    ({ withAvatar = true }: ISelectAvatarMultiValueLabelProps) =>
    (props: MultiValueGenericProps<IMultiLabelOption>) => {
        const {
            data: { value },
        } = props;
        const avatarId = value?.avatarId ?? "";
        const defaultAvatarColor = value?.defaultAvatarColor;
        const firstName = value?.firstName ?? "Без";
        const lastName = value?.lastName ?? "Команды";
        return (
            <div className={"flex items-center py-1 pl-1.5 gap-2 cursor-pointer !shrink-0"}>
                {withAvatar && (
                    <UserAvatar
                        avatarId={avatarId}
                        color={defaultAvatarColor}
                        userInitials={`${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`}
                        size={20}
                    />
                )}
                <span>{props.children}</span>
            </div>
        );
    };
