import React, { ReactNode } from "react";

interface IInforCardProps {
    title: string;
    className?: string;
    children: ReactNode;
}

export const InfoCard = ({ title, children, className = "" }: IInforCardProps) => {
    return (
        <div className={`min-w-36 grow-0 ${className}`}>
            {title && <p className="text-gray mb-1.5">{title}</p>}
            {children}
        </div>
    );
};
