import React from "react";
import classNames from "classnames";
import cx from "./audio-player.module.scss";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

interface BarProps {
    duration: number;
    curTime: number;
    onTimeUpdate: (e: number) => void;
}

export default function Bar({ duration, curTime, onTimeUpdate }: Readonly<BarProps>) {
    const curPercentage = (curTime / duration) * 100;

    function formatDuration(duration: number) {
        return dayjs
            .duration({ minutes: Math.floor(duration / 60), seconds: Math.floor(duration) % 60 })
            .format("mm:ss");
    }

    function calcClickedTime(e: MouseEvent) {
        const clickPositionInPage = e.pageX;
        const bar = document.getElementById("progress-bar") as HTMLDivElement;

        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;

        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;

        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e: MouseEvent) {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = (eMove: MouseEvent) => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        document.addEventListener("mousemove", updateTimeOnMove);
        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateTimeOnMove);
        });
    }

    return (
        <div className={cx.bar}>
            <span>{formatDuration(curTime)}</span>
            <div
                id="progress-bar"
                className={classNames(cx.barProgress, cx.durationValue)}
                style={{
                    background: `linear-gradient(to right, #1280CE ${curPercentage}%, #1280CE 0, #EAEDF3  ${curPercentage}%, #EAEDF3 100%)`,
                }}
                onMouseDown={(e) => handleTimeDrag(e as unknown as MouseEvent)}
            >
                <span className={cx.barProgressKnob} style={{ left: `${curPercentage - 2}%` }} />
            </div>
            <span>{formatDuration(duration)}</span>
        </div>
    );
}
