import React, { useRef, useState } from "react";
import { useRequestAnimationFrame } from "hooks/useRequestAnimationFrame";

interface BarProps {
    rewind: boolean;
    currentTime: number;
    watchedTime?: number;
    getDuration: () => number | undefined;
    getCurrentTime: () => number | undefined;
    setCurrentTime: (time: number) => void;
}

export const Bar = ({
    rewind,
    currentTime,
    watchedTime = 0,
    getDuration,
    getCurrentTime,
    setCurrentTime,
}: BarProps) => {
    const progressRef = useRef<HTMLDivElement | null>(null);
    const [progress, setProgress] = useState(0);
    const [blockedProgress, setBlockedProgress] = useState<number>((watchedTime / (getDuration() ?? 0)) * 100 || 100);

    function calcClickedTime(e: any): number {
        const clickPositionInPage = e.pageX;
        const bar = progressRef.current;

        if (bar) {
            const barStart = bar.getBoundingClientRect().left + window.scrollX;
            const barWidth = bar.offsetWidth;

            const clickPositionInBar = clickPositionInPage - barStart;
            const timePerPixel = (getDuration() ?? 0) / barWidth;

            return timePerPixel * clickPositionInBar;
        }

        return 0;
    }
    const formatTime = (s: string) => {
        let seconds: string | number = parseInt(s, 10);
        let hours: string | number = Math.floor(seconds / 3600);
        let minutes: string | number = Math.floor((seconds - hours * 3600) / 60);
        seconds = seconds - hours * 3600 - minutes * 60;

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        const time = hours + ":" + minutes + ":" + seconds;
        return time;
    };

    function handleTimeDrag(e: any) {
        const clickedTime = calcClickedTime(e);

        if (!rewind && clickedTime > watchedTime) {
            return;
        }

        setCurrentTime(clickedTime);

        const updateTimeOnMove = (eMove: any) => {
            const clickedTime = calcClickedTime(eMove);

            if (!rewind && clickedTime > watchedTime) {
                return;
            }
            setCurrentTime(clickedTime);
        };

        document.addEventListener("mousemove", updateTimeOnMove);
        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateTimeOnMove);
        });
    }

    useRequestAnimationFrame(() => {
        if (!rewind) {
            setBlockedProgress((watchedTime / (getDuration() ?? 0)) * 100);
        }
        setProgress(((getCurrentTime() ?? 0) / (getDuration() ?? 0)) * 100);
    });

    return (
        <div className="select-none w-full flex items-center grow px-3">
            <span className="text-white leading-2 pr-1.5">{formatTime(String(currentTime))}</span>
            <div
                ref={progressRef}
                className="relative flex-1 rounded-md h-1 flex items-center cursor-pointer bg-gray"
                onMouseDown={(e) => handleTimeDrag(e)}
            >
                <div className="absolute z-20 h-full bg-[#1280CE]" style={{ width: `${progress}%` }}></div>
                {!rewind && (
                    <div
                        className="absolute z-10 border-r border-r-white h-full"
                        style={{
                            width: rewind ? "100%" : blockedProgress + "%",
                        }}
                    ></div>
                )}
            </div>
            <span className="text-white leading-2 pl-1.5">{formatTime(String(getDuration() ?? 0))}</span>
        </div>
    );
};
