import React, { useId } from "react";
import { FormGroup, Label, TextareaAutoSizable, Toggle /* , Icon, Icons */ } from "Uikit";
import { testingRegexp } from "Uikit/Forms/Input";
import Attachments from "Uikit/Forms/Attachments/Attachments";
import { TaskReadResponseItem } from "Api/Responses/TaskResponse";
import { ITaskQuestionProps } from "./TaskContentItem";

export const TaskQuestionImage = ({ taskItem, onEditTaskItem, errors = {}, expanded = false }: ITaskQuestionProps) => {
    const id = useId();
    const maxLength = 1024;

    const onChangeFieldValueHandler = (fieldName: keyof TaskReadResponseItem, value: string | boolean) => {
        (taskItem as unknown as Record<typeof fieldName, string | boolean>)[fieldName] = value;
        onEditTaskItem(taskItem);
    };

    return (
        <>
            {expanded ? (
                <>
                    <FormGroup>
                        <Label isRequired={taskItem.mandatory}>Текст вопроса</Label>
                        <TextareaAutoSizable
                            placeholder="Введите текст вопроса"
                            value={taskItem.question}
                            error={errors?.question}
                            maxLength={maxLength}
                            cutRegExp={testingRegexp}
                            className="resize-none"
                            id={id + "adminNewTaskContentTabInputTextQuestion"}
                            onChange={(e) => {
                                onChangeFieldValueHandler("question", e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={taskItem.mandatory}>Комментарий для проверяющего</Label>
                        <TextareaAutoSizable
                            placeholder="Введите комментарий"
                            value={taskItem.reviewerNote}
                            error={errors?.reviewerNote}
                            maxLength={maxLength}
                            cutRegExp={testingRegexp}
                            className="resize-none"
                            id={id + "adminNewTaskContentTabInputComment"}
                            onChange={(e) => {
                                onChangeFieldValueHandler("reviewerNote", e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="flex items-center mt-2 pt-7.5 border-t border-gray-blue">
                        <Toggle
                            label="Обязательный вопрос"
                            enabled={taskItem.mandatory}
                            onChange={(e) => {
                                onChangeFieldValueHandler("mandatory", e);
                            }}
                            id={id + "adminNewTaskContentTabToggleRequired"}
                        />
                    </FormGroup>
                </>
            ) : (
                <FormGroup
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="flex flex-col gap-4 !cursor-default"
                >
                    <Label isRequired={taskItem.mandatory}>{taskItem.question}</Label>
                    <Attachments files={[]} className="pointer-events-none" type={1} />
                </FormGroup>
            )}
        </>
    );
};
