import { components, ValueContainerProps } from "react-select";
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
    useLayoutEffect,
    CSSProperties,
    SyntheticEvent,
} from "react";
import { useResize } from "hooks/useResize";
import tail from "lodash/tail";
import take from "lodash/take";
import { ClearAllButton } from "./ClearAllButton";

export const StackedValueContainer = ({
    children,
    style,
    watchVisibleElements = true,
    ...props
}: ValueContainerProps<any> & { style?: CSSProperties; watchVisibleElements?: boolean }) => {
    const itemsLength = props.getValue().length;
    const target = useRef<HTMLDivElement>(null);
    const isResized = useResize(target);
    const { onClick, allPlaceholder, optionsPlaceholder } = props.selectProps;

    const options = (children as ReactNode[])[0] as ReactNode[];
    const anotherChildren = tail(children as ReactNode[]);
    const [visibleElements, setVisibleElements] = useState(options.length);

    const isOverflowing = useCallback(() => {
        let padding = 0;
        if (!watchVisibleElements && target.current) {
            padding = ["padding-left", "padding-right"].reduce((prev, curr) => {
                return (
                    prev +
                    parseInt(window.getComputedStyle(target.current as HTMLDivElement, null).getPropertyValue(curr))
                );
            }, padding);
        }
        return watchVisibleElements
            ? target.current!.scrollWidth > target.current!.offsetWidth
            : target.current!.scrollWidth - target.current!.offsetWidth > target.current!.offsetWidth - padding;
    }, [watchVisibleElements]);

    useEffect(() => {
        setVisibleElements(itemsLength);
    }, [itemsLength]);

    useLayoutEffect(() => {
        if (target.current && isOverflowing()) {
            setVisibleElements(visibleElements - 1);
        }
    }, [isResized, isOverflowing, target, visibleElements]);

    const clearAll = (e: SyntheticEvent) => {
        e.stopPropagation();
        props.clearValue();
    };

    const visibleOptions = take(options, visibleElements);

    return (
        <div
            onClick={(e) => {
                onClick?.(e);
            }}
            style={{
                width: "calc(100% - 34px)",
                ...style,
            }}
        >
            <components.ValueContainer
                {...props}
                innerProps={{ ref: target }}
                className="gap-1.5 !pr-7 relative !flex-nowrap ui-combo-box__value-container--stacked"
            >
                {itemsLength === 0 && props.selectProps.placeholder && (
                    <div className="absolute left-4.5 2xl:left-5 top-1/2 -translate-y-1/2 text-blue-drk 2xl:!leading-p200">
                        {props.selectProps.placeholder}
                    </div>
                )}
                <>
                    {itemsLength && (allPlaceholder || optionsPlaceholder) ? (
                        <div className="ui-combo-box__multi-value pl-2.75 !bg-transparent">
                            {allPlaceholder && "Все"}
                            {!allPlaceholder && optionsPlaceholder && optionsPlaceholder(itemsLength)}
                        </div>
                    ) : (
                        visibleOptions
                    )}
                    {itemsLength > visibleElements && <span>...</span>}
                    {anotherChildren}
                </>
                {itemsLength > 0 && !allPlaceholder && (
                    <ClearAllButton onClick={clearAll as (e?: SyntheticEvent) => void} itemsLength={itemsLength} />
                )}
            </components.ValueContainer>
        </div>
    );
};
