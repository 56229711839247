import * as Yup from "yup";

export const articleValidationSchema = Yup.object().shape({
    settings: Yup.object()
        .shape({
            title: Yup.string().required("Поле обязательно для заполнения"),
            category: Yup.object().shape({
                label: Yup.string().required("Поле обязательно для заполнения"),
                value: Yup.string().required("Поле обязательно для заполнения"),
            }),
            complexity: Yup.object().shape({
                label: Yup.string().required("Поле обязательно для заполнения"),
                value: Yup.string().required("Поле обязательно для заполнения"),
            }),
            userId: Yup.string().required(),
        })
        .required(),
    article: Yup.object().shape({
        content: Yup.string(),
        attachments: Yup.array(),
    }),
});

export const videoValidationSchema = Yup.object().shape({
    settings: Yup.object()
        .shape({
            title: Yup.string().required("Поле обязательно для заполнения"),
            category: Yup.object().shape({
                label: Yup.string().required("Поле обязательно для заполнения"),
                value: Yup.string().required("Поле обязательно для заполнения"),
            }),
            complexity: Yup.object().shape({
                label: Yup.string().required("Поле обязательно для заполнения"),
                value: Yup.string().required("Поле обязательно для заполнения"),
            }),
            userId: Yup.string().required(),
        })
        .required(),
    fileId: Yup.string().required("Необходимо добавить файл"),
});

export const documentValidationSchema = Yup.object().shape({
    settings: Yup.object()
        .shape({
            title: Yup.string().required("Поле обязательно для заполнения"),
            category: Yup.object().shape({
                label: Yup.string().required("Поле обязательно для заполнения"),
                value: Yup.string().required("Поле обязательно для заполнения"),
            }),
            complexity: Yup.object().shape({
                label: Yup.string().required("Поле обязательно для заполнения"),
                value: Yup.string().required("Поле обязательно для заполнения"),
            }),
            userId: Yup.string().required(),
        })
        .required(),
    fileId: Yup.string().required("Необходимо добавить файл"),
});

export const scormValidationSchema = Yup.object().shape({
    settings: Yup.object()
        .shape({
            title: Yup.string().required("Поле обязательно для заполнения"),
            category: Yup.object().shape({
                label: Yup.string().required("Поле обязательно для заполнения"),
                value: Yup.string().required("Поле обязательно для заполнения"),
            }),
            complexity: Yup.object().shape({
                label: Yup.string().required("Поле обязательно для заполнения"),
                value: Yup.string().required("Поле обязательно для заполнения"),
            }),
            userId: Yup.string().required(),
        })
        .required(),
    fileId: Yup.string().required("Необходимо добавить файл"),
});

export const htmlValidationSchema = Yup.object().shape({
    settings: Yup.object()
        .shape({
            title: Yup.string().required("Поле обязательно для заполнения"),
            description: Yup.string().required("Поле обязательно для заполнения"),
        })
        .required(),
});
