import { BaseRequest } from "../BaseRequest";

export class OfficeRequest extends BaseRequest {
    public id: string;
    public name: string;
    public country: string;
    public region: string;
    public address: string;
    public userIds?: string[];
}
