import React, { ReactNode } from "react";
import clsx from "clsx";

interface IEmpty {
    className?: string;
    title: string;
    description?: string | ReactNode;
    children?: ReactNode;
    topElement?: ReactNode;
    childrenClassName?: string;
    titleId?: string;
    descriptionId?: string;
}
export const Empty = ({
    className,
    title,
    description,
    children,
    topElement,
    childrenClassName = "",
    titleId = "",
    descriptionId = "",
}: IEmpty) => {
    return (
        <div className={clsx("flex flex-col grow justify-center items-center text-center sm:px-10", className)}>
            {topElement && <>{topElement}</>}
            <h1
                className="mb-2 2xl:mb-2.5 font-semibold text-black 2xl:!text-4xl 2xl:!leading-[40px]"
                id={titleId || "emptyPageTitle"}
            >
                {title}
            </h1>
            {description && (
                <p className="text-blue-drk 2xl:!text-2md" id={descriptionId || "emptyPageDecription"}>
                    {description}
                </p>
            )}
            {children && <div className={`flex mt-7.5 space-x-5 ${childrenClassName}`}>{children}</div>}
        </div>
    );
};
