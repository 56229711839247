import { Expose, Type } from "class-transformer";

export class MaterialCategoryTreeResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;
}
