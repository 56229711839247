import React, { SyntheticEvent, useMemo, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button, Checkbox, Icon, Icons, PopoverList } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import TreeToggler from "./TreeToggler";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

export enum NodeType {
    ACTUAL_ROOT = "ACTUAL_ROOT",
    PROJECT = "PROJECT",
    SECTION = "SECTION",
    ARTICLE = "ARTICLE",
}

const TreeNode = ({
    nodeId,
    node,
    tree,
    toggleState,
    checkedChange,
    onNodeClick,
    onNodeAddClick,
    nodeAddList,
    checkable = true,
    editable = false,
    onEditClick,
    onDeleteClick,
    nodeWrapperClass,
    nodeAddComponent: NodeAddComponent,
    treeNodeClassName,
    disabled = false,
    onNodeAddComponentClick,
    nodeAddComponentLink,
    isCheckboxInside,
    checkOnNameClick,
    onTogglerClick,
    checkedWhenDisabled,
    isTeamTree,
    disableRootSelection,
    markCheckedNodeHover = false,
}: any) => {
    const isGotChildren = node.children.length > 0;

    const [addOptionsVisible, setAddOptionsVisible] = useState(false);
    const [optionsVisible, setOptionsVisible] = useState(false);

    const addList = useMemo(() => {
        return nodeAddList?.map((item: any) => (
            <PopoverList.Item
                name={item.type}
                key={item.name}
                onClickItem={(event: any) => {
                    event.stopPropagation();
                    setAddOptionsVisible(false);
                    onNodeAddClick(item, node.id);
                }}
            >
                {item.name}
            </PopoverList.Item>
        ));
    }, [nodeAddList, onNodeAddClick, node]);

    const openCategoryNode = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (toggleState === "closed") {
            tree.openNode(node);
        } else if (toggleState === "opened") {
            tree.closeNode(node, { silent: true });
        }
    };

    const onCheckHandler = (e: any) => {
        e?.stopPropagation();

        let checked = node.state.checked;
        const hasActualRoot = !!node.children.find((subNode: any) => subNode.nodeType === NodeType.ACTUAL_ROOT);
        const allChildrenDisabledExceptRoot = node.children.every(
            (subNode: any) => subNode.isDisabled || subNode.nodeType === NodeType.ACTUAL_ROOT,
        );

        if (checked && hasActualRoot && !allChildrenDisabledExceptRoot && !node.indeterminate) {
            tree.updateNode(node, { indeterminate: true });
            tree.openNode(node);

            node.children.forEach((subNode: any) => {
                if (subNode.nodeType !== NodeType.ACTUAL_ROOT && !subNode.isDisabled) {
                    const nd = tree.getNodeById(subNode.id);

                    tree.updateNode(nd, { checked: false });
                    tree.checkNode(nd, false);
                }
            });
        } else {
            if (node.indeterminate) {
                node.indeterminate = false;
            }

            tree.updateNode(node, { indeterminate: false });

            checked = allChildrenDisabledExceptRoot ? !node.state.checked : !checked;
            checkNode(node, checked);
        }

        checkedChange?.(node, checked);
    };

    const checkNode = (node: any, checked: boolean) => {
        tree.checkNode(node, checked);
    };

    const handleNameClick = (e: any) => {
        if (checkable && checkOnNameClick && !disabled) {
            onCheckHandler(e);
        } else if (disableRootSelection) {
            if ((node.children?.length ?? 0) > 0) {
                e.stopPropagation();
                e.preventDefault();
            }
        }
    };

    return (
        <div
            data-node-id={node.id}
            className={clsx(
                "node h-full relative pr-2 hover:bg-background group rounded-md pl-1",
                node.id === nodeId && "bg-[rgba(242,249,253,1)] text-blue",
                node.state.selected && "bg-background",
                node.highlight && "bg-background",
                "cursor-pointer",
                (optionsVisible || addOptionsVisible) && "z-10",
                !isTeamTree && "pl-4",
                markCheckedNodeHover && node.state.checked && !node.state.indeterminate && "bg-background",
                treeNodeClassName,
            )}
            onClick={() => {
                onNodeClick(node);
            }}
        >
            <div className="h-full flex">
                {isTeamTree &&
                    Array.from(Array(node.state.depth).keys()).map((p) => {
                        return <div key={p} className={clsx("mx-3 w-0.25 h-full bg-gray-blue")} />;
                    })}
                {isTeamTree && isGotChildren && (
                    <TreeToggler
                        state={toggleState}
                        onClick={(event) => {
                            openCategoryNode(event);
                            onTogglerClick?.(node);
                        }}
                    />
                )}
                {isCheckboxInside && node.children.length === 0 && (
                    <div className="ml-2 mr-3 w-0.25 h-full bg-gray-blue" />
                )}
                {isTeamTree && node.name === "Текущая команда" && (
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 1V14C12 14.5523 12.4477 15 13 15H21"
                            stroke="#EAEDF3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
                {isTeamTree && node.name !== "Текущая команда" && node.children.length === 0 && <div className="w-6" />}
                {checkable && (
                    <div className={clsx("flex items-center my-1")}>
                        <Checkbox
                            checked={node.state.checked || (disabled && checkedWhenDisabled)}
                            indeterminate={node.state.indeterminate}
                            error={node.state.error || node.error}
                            disabled={disabled}
                            onClick={(e) => e.stopPropagation()}
                            onChange={onCheckHandler}
                        />
                    </div>
                )}
                <div
                    className={clsx(
                        "group flex items-center h-full overflow-hidden text-ellipsis flex-shrink grow relative",
                        nodeWrapperClass,
                        isCheckboxInside && node.children.length === 0 && "!pl-2",
                    )}
                >
                    {!isTeamTree && isGotChildren && (
                        <TreeToggler
                            state={toggleState}
                            onClick={(event) => {
                                openCategoryNode(event);
                                onTogglerClick?.(node);
                            }}
                        />
                    )}
                    <div
                        className={clsx(
                            "node-name flex grow items-center p2 font-medium whitespace-nowrap cursor-pointer px-2 h-full flex-shrink min-w-0",
                            (optionsVisible || addOptionsVisible) && "pr-11",
                            editable && "group-hover:pr-11",
                            node.children.length !== 0 ? "text-black" : "text-gray-dark",
                            ((!checkable &&
                                node.state.checked &&
                                tree.nodes.every((node: { state: { selected: boolean } }) => !node.state.selected)) ||
                                (checkable && node.state.checked && !node.state.indeterminate) ||
                                node.state.selected) &&
                                "!text-blue",
                        )}
                        onClick={handleNameClick}
                    >
                        {node.logoId && (
                            <img
                                className="rounded mr-1.5 w-7 h-5 flex-shrink-0"
                                src={Api.Upload.GetLogo(node.logoId, LogoSize.THUMB_MICRO)}
                                alt={node.name}
                            />
                        )}
                        {node.href ? (
                            <a href={node.href} className={"overflow-hidden text-ellipsis whitespace-break-spaces"}>
                                <MultiClumpTooltip label={node.name} clamp={1} />
                            </a>
                        ) : (
                            <span
                                onClick={(event) => {
                                    if (!isTeamTree && isGotChildren) {
                                        openCategoryNode(event);
                                    }
                                }}
                                className={"overflow-hidden text-ellipsis whitespace-break-spaces"}
                            >
                                <MultiClumpTooltip
                                    label={node.name}
                                    clamp={1}
                                    textClassName={node.isDisabled ? "!text-gray-light" : ""}
                                />
                            </span>
                        )}
                    </div>
                    {NodeAddComponent ? (
                        <NodeAddComponent
                            membersCount={node.membersCount}
                            node={node}
                            onClick={() => {
                                onNodeAddComponentClick?.(node.id);
                            }}
                            link={`${nodeAddComponentLink}${node.id?.replace("root:", "")}`}
                        />
                    ) : null}
                </div>
            </div>
            {editable && (
                <div
                    className={clsx(
                        "node-edit-buttons absolute hidden right-0 pr-3 top-1/2 -translate-y-1/2 h-full items-center gap-2 pl-2 group-hover:flex",
                        (optionsVisible || addOptionsVisible) && "!flex z-10",
                    )}
                >
                    {/* optionsVisible */}
                    {nodeAddList?.length <= 1 && (
                        <Button
                            className={"border-none w-3.5 h-3.5 focus:!ring-0"}
                            shape={"round"}
                            size={"medium"}
                            icon={
                                <Icon
                                    className=""
                                    color="fill-primary"
                                    icon={Icons.PlusFilled}
                                    width={15}
                                    height={15}
                                />
                            }
                            iconPlacement={"center"}
                            color={"common"}
                            onClick={onNodeAddClick}
                        />
                    )}

                    {/* Список создания новых сущностей */}
                    {nodeAddList?.length > 1 && (
                        <PopoverList
                            offset={[68, 4]}
                            visible={addOptionsVisible}
                            onClickOutside={() => setAddOptionsVisible(false)}
                            className=""
                        >
                            <Button
                                className={"border-none w-3.5 h-3.5 translate-y-0.5 focus:!ring-0"}
                                shape={"round"}
                                size={"medium"}
                                icon={
                                    <Icon
                                        className=""
                                        color="fill-primary"
                                        icon={Icons.PlusFilled}
                                        width={15}
                                        height={15}
                                    />
                                }
                                iconPlacement={"center"}
                                color={"common"}
                                onClick={
                                    addOptionsVisible
                                        ? (event: any) => {
                                              event.stopPropagation();
                                              setAddOptionsVisible(false);
                                          }
                                        : (event: any) => {
                                              event.stopPropagation();
                                              setAddOptionsVisible(true);
                                          }
                                }
                            />

                            {addList}
                        </PopoverList>
                    )}

                    {/* Вариант с PopoverList */}
                    <PopoverList
                        offset={[-68, 4]}
                        visible={optionsVisible}
                        onClickOutside={() => setOptionsVisible(false)}
                        className=""
                    >
                        <Button
                            className={"border-none w-3.5 h-3.5 -translate-y-1 focus:!ring-0"}
                            shape={"round"}
                            size={"medium"}
                            icon={<Icon className="" color="fill-primary" icon={Icons.Dots} width={8} height={2} />}
                            iconPlacement={"center"}
                            color={"common"}
                            onClick={
                                optionsVisible
                                    ? (event: any) => {
                                          event.stopPropagation();
                                          setOptionsVisible(false);
                                      }
                                    : (event: any) => {
                                          event.stopPropagation();
                                          setOptionsVisible(true);
                                      }
                            }
                        />
                        <PopoverList.Item
                            name="teamAdd"
                            onClickItem={(event: any) => {
                                event.stopPropagation();
                                setOptionsVisible(false);
                                onEditClick(node.name, node.id);
                            }}
                        >
                            Переименовать
                        </PopoverList.Item>
                        <PopoverList.Item
                            name="teamRemove"
                            onClickItem={(event: any) => {
                                event.stopPropagation();
                                setOptionsVisible(false);

                                onDeleteClick(node.name, node.id);
                            }}
                        >
                            Удалить
                        </PopoverList.Item>
                    </PopoverList>
                </div>
            )}
        </div>
    );
};

TreeNode.propTypes = {
    node: PropTypes.object.isRequired,
    tree: PropTypes.object.isRequired,
    toggleState: PropTypes.string.isRequired,
    highlight: PropTypes.string,
    disabled: PropTypes.bool,
};

export default TreeNode;
