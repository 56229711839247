import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import Api from "Api";
import { TestVerificatedCheckRequestQuestion, TestVerificatedCheckRequest } from "Api/Requests/TestRequest";
import { Breadcrumbs, Button, Icon, Icons, Textarea, flash } from "Uikit";
import { Section } from "Uikit/Forms/Section";
import { SectionsWrapper } from "Uikit/Forms/SectionsWrapper";
import { Loader } from "Uikit/Loader/Loader";
import { numWord } from "helpers/numWord";
import { useInvalidate } from "hooks/useInvalidate";
import { formatDateTime } from "helpers/dateHelper";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";

export const TestPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [readyToSend, setReadyToSend] = useState(false);
    const [provedQuestions, setProvedQuestions] = useState<TestVerificatedCheckRequestQuestion[]>([]);
    const invalidate = useInvalidate();

    const dataQuery = useQuery(
        ["task", "collection", id],
        async () => {
            return await Api.Test.VerificatedRead(id as string);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data, isLoading, isError } = dataQuery;

    const { mutateAsync: verificatedCheck } = useMutation((payload: TestVerificatedCheckRequest) => {
        return Api.Test.VerificatedCheck(payload);
    });

    const { mutateAsync: verificatedSave } = useMutation((payload: TestVerificatedCheckRequest) => {
        return Api.Test.VerificatedSave(payload);
    });

    const { mutateAsync: verificatedSkip } = useMutation((payload: TestVerificatedCheckRequest) => {
        return Api.Test.VerificatedSkip(payload);
    });

    const onVerificatedCheck = async () => {
        const result = await verificatedCheck({
            solutionId: id as string,
            checkedQuestions: provedQuestions,
        });

        if (result?.progressId && result?.progressId !== id) {
            invalidate("review");
            flash.success("Результат отправлен");
            navigate(`/admin/validations/test/${result.progressId}`);
        } else {
            navigate(`/admin/validations?tab=tests`);
        }
    };

    const onVerificatedSkip = async () => {
        const { nextQuizId } = await verificatedSkip({
            solutionId: id as string,
            checkedQuestions: provedQuestions,
        });

        if (nextQuizId) {
            navigate(`/admin/validations/test/${nextQuizId}`);
        }
    };

    const goBack = async () => {
        await verificatedSave({
            solutionId: id as string,
            checkedQuestions: provedQuestions,
        });

        navigate(`/admin/validations?tab=tests`);
    };

    useEffect(() => {
        if (data) {
            setProvedQuestions(
                data.questions.map(({ id, approved }) => {
                    return {
                        questionId: id,
                        approved,
                        comment: "",
                    };
                }),
            );
        }
    }, [data]);

    useEffect(() => {
        setReadyToSend(provedQuestions.every(({ approved }) => approved !== null));
    }, [provedQuestions, setReadyToSend]);

    const handleQuestionCheckChange = (id: string, approved: boolean, comment = "") => {
        setProvedQuestions((prevQuestions) => {
            return prevQuestions.map((question) =>
                question.questionId === id ? { ...question, approved, comment } : question,
            );
        });
    };

    return (
        <div className="w-full h-full px-6 relative">
            <Breadcrumbs className="mb-6">
                <Breadcrumbs.Link title={"Администратор"} />
                <Breadcrumbs.Link title={"Проверка"} url={"/admin/validations"} />
                <Breadcrumbs.Link title={"Тесты"} url={"/admin/validations?tab=tests"} />
                {data && <Breadcrumbs.Link title={data.title} url={`/admin/validations/test/${id}`} />}
            </Breadcrumbs>

            {isLoading && (
                <div className="w-full h-full flex-center px-10">
                    <Loader />
                </div>
            )}
            {isError && (
                <p className="text-md text-center text-red">Произошла ошибка запроса - невозможно загрузить задание</p>
            )}

            {data && !isError && (
                <>
                    <div className="flex">
                        <div className="w-4/5">
                            <div className="flex items-center gap-3 mb-2">
                                <div className="flex items-center gap-1">
                                    <UserAvatar
                                        avatarId={data.user.avatarId}
                                        color={data.user.defaultAvatarColor}
                                        userInitials={`${data.user.firstName?.slice(0, 1)}${data.user.lastName?.slice(
                                            0,
                                            1,
                                        )}`}
                                        size={20}
                                    />
                                    <p className="text-xxs text-gray">
                                        {data.user.lastName} {data.user.firstName}
                                    </p>
                                </div>
                                <div className="h-4 border-r border-solid border-gray-stroke"></div>
                                <p className="text-xxs text-gray">
                                    Отправлен {formatDateTime(data.statusChanged * 1000)}
                                </p>
                            </div>
                            <div>
                                <h2>{data.title}</h2>
                            </div>
                            {data.description && (
                                <div className="w-1/2 mt-2">
                                    <p className="text-[#939393]">{data.description}</p>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-end w-full space-x-4">
                            <Button
                                key={0}
                                onClick={readyToSend ? onVerificatedCheck : onVerificatedSkip}
                                size={"medium"}
                                variant={readyToSend ? "standard" : "outline"}
                                color={readyToSend ? "primary" : "common"}
                                className={"border-[#EAEDF3] !text-base !font-medium whitespace-nowrap"}
                            >
                                {readyToSend ? "Отправить результат" : "Пропустить тест"}
                            </Button>

                            <Button
                                shape="square"
                                color="common"
                                size={"medium"}
                                variant="outline"
                                icon={<Icon icon={Icons.Close} width={24} height={24} />}
                                iconPlacement={"center"}
                                className={"border-[#EAEDF3] !text-base !font-medium"}
                                onClick={goBack}
                            />
                        </div>
                    </div>

                    {provedQuestions.length > 0 ? (
                        <SectionsWrapper
                            firstColumnTitle="Вопросы"
                            secondColumnTitle="Ответы пользователя"
                            columnProportion="40/50"
                            className="mt-8 mb-16 !h-fit"
                        >
                            {data.questions.map(({ id, customAnswer, text, ratingPoints, answerNote }, index) => {
                                const testQuestion = provedQuestions.find(({ questionId }) => questionId === id);
                                if (testQuestion) {
                                    const { approved, comment } = testQuestion;
                                    const isGotDecision = approved !== null;
                                    const isApproved = isGotDecision && approved;
                                    const isNotApproved = isGotDecision && !approved;

                                    return (
                                        <Section
                                            key={index}
                                            label={text}
                                            description={`${ratingPoints} ${numWord(ratingPoints, [
                                                "балл",
                                                "балла",
                                                "баллов",
                                            ])}`}
                                            rightColumnClassName="max-w-full"
                                        >
                                            <div>
                                                <div className="bg-[#F5F7F9] rounded-lg mt-4 px-4 py-2">
                                                    <span className="text-sm text-[#939393]">Ответ пользователя</span>
                                                    <p>{customAnswer}</p>
                                                </div>
                                                {answerNote && (
                                                    <div className="bg-[#EAEDF3] rounded-lg mt-4 px-4 py-2">
                                                        <span className="text-sm text-[#939393]">
                                                            Комментарий автора
                                                        </span>
                                                        <p>{answerNote}</p>
                                                    </div>
                                                )}
                                                <Textarea
                                                    placeholder="Ваш комментарий"
                                                    name="comment"
                                                    value={comment}
                                                    onChange={(e) => {
                                                        handleQuestionCheckChange(id, approved, e.target.value);
                                                    }}
                                                    className="bg-white resize-none overflow-hidden mt-4"
                                                    maxLength={512}
                                                />
                                                <div className="mt-6 space-x-4">
                                                    <Button
                                                        key={0}
                                                        onClick={() => {
                                                            handleQuestionCheckChange(id, false, comment);
                                                        }}
                                                        variant={
                                                            isGotDecision && isNotApproved ? "standard" : "outline"
                                                        }
                                                        size={"medium"}
                                                        color={isNotApproved ? "danger" : "common"}
                                                        className={"border-[#EAEDF3] !text-base !font-medium"}
                                                    >
                                                        Отклонить
                                                    </Button>

                                                    <Button
                                                        key={1}
                                                        onClick={() => {
                                                            handleQuestionCheckChange(id, true, comment);
                                                        }}
                                                        variant={isGotDecision && isApproved ? "standard" : "outline"}
                                                        size={"medium"}
                                                        color={isApproved ? "primary" : "common"}
                                                        className={"border-[#EAEDF3] !text-base !font-medium"}
                                                    >
                                                        Принять
                                                    </Button>
                                                </div>
                                            </div>
                                        </Section>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </SectionsWrapper>
                    ) : null}
                </>
            )}
        </div>
    );
};
