import React, { ChangeEvent, useMemo, useRef, useState } from "react";

import { Button, flash, Icon, Icons } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { getFileSizeErrorMessage } from "helpers/file";
import { UIErrorMessages } from "Enums";
import { TVoidFunction } from "types";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

type TCoverInputProps = {
    logo: File | string | null;
    defaultLogoIds?: string[];
    btn?: boolean;
    onChange: (file: File | null) => void;
    onChangeDefaultLogo?: (id: string) => void;
    id?: string;
    onError?: TVoidFunction;
};

const DEFAULT_COVER_WIDTH = 2055;
const DEFAULT_COVER_HEIGHT = 1230;

export const CoverInput = ({
    onChange,
    onChangeDefaultLogo,
    logo,
    defaultLogoIds = [],
    btn = false,
    id,
    onError,
}: TCoverInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [cover, setCover] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const img = useMemo(() => {
        if (logo) {
            if (typeof logo !== "string") {
                return URL.createObjectURL(logo);
            } else {
                return Api.Upload.GetLogo(logo, LogoSize.ORIGINAL);
            }
        } else if (cover) {
            return cover;
        }

        return undefined;
    }, [cover, logo]);

    const handleChange = async (e: ChangeEvent) => {
        const files = (e?.target as HTMLInputElement)?.files;
        if (!files || files?.length <= 0) {
            return;
        }
        setIsLoading(true);

        if (!RegExp(/^image\/(jpg|jpeg|png)/).exec(files?.[0].type)) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);

            setIsLoading(false);
            return;
        }

        if (files?.[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2 МБ"));
            if (inputRef.current) {
                inputRef.current.value = "";
            }
            setIsLoading(false);
            return;
        }

        const oFReader = new FileReader();

        oFReader.readAsDataURL(files?.[0]);

        oFReader.onload = function () {
            const img = new Image();
            img.src = oFReader.result as string;

            img.onerror = function () {
                flash.error(UIErrorMessages.FILE_LOADING_ERROR);
                setIsLoading(false);
            };

            img.onload = function () {
                const desiredAspectRatio = DEFAULT_COVER_WIDTH / DEFAULT_COVER_HEIGHT;
                const currentAspectRatio = img.width / img.height;

                let cropX, cropY, cropWidth, cropHeight;

                if (currentAspectRatio > desiredAspectRatio) {
                    cropWidth = img.height * desiredAspectRatio;
                    cropHeight = img.height;

                    cropX = (img.width - cropWidth) / 2;
                    cropY = 0;
                } else {
                    cropWidth = img.width;
                    cropHeight = img.width / desiredAspectRatio;

                    cropX = 0;
                    cropY = (img.height - cropHeight) / 2;
                }

                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                canvas.width = cropWidth;
                canvas.height = cropHeight;

                ctx?.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                canvas.toBlob(function (blob) {
                    const croppedFile = new File([blob ?? ""], files?.[0].name, { type: files?.[0].type });
                    onChange(croppedFile);

                    setCover(oFReader.result as string);
                    setIsLoading(false);
                }, files?.[0].type);
            };
        };
    };

    const getRandomDefImgUrl = () => {
        onChange(null);
        setCover("");
        setIsLoading(true);

        if (inputRef.current) {
            inputRef.current.value = "";
        }

        const randomCount = Math.floor(Math.random() * defaultLogoIds.length);

        onChangeDefaultLogo?.(defaultLogoIds[randomCount]);
    };

    return (
        <div className="relative">
            {!btn && (
                <div
                    className="relative inline-block w-[418px] h-[250px] overflow-hidden rounded-lg bg-gray-light"
                    id={id + "CoverBlock"}
                >
                    <input
                        className="invisible pointer-events-none absolute"
                        id={id + "CoverImgInput"}
                        onChange={handleChange}
                        ref={inputRef}
                        type="file"
                        multiple={false}
                        accept="image/png, image/jpeg"
                    />
                    <div className="absolute z-10 top-5 right-5 flex items-center gap-2.5">
                        <Button
                            shape={"round"}
                            color={"common"}
                            className="flex items-center !w-8 !h-8 !rounded-lg bg-[rgba(38,38,38,0.5)] active:!ring-0 focus:!ring-0"
                            icon={<Icon icon={Icons.Upload} color="fill-white" width="20px" height="20px" />}
                            iconPlacement={"center"}
                            title="Загрузить обложку"
                            onClick={() => inputRef.current?.click()}
                            id={id + "CoverBtnUpload"}
                        ></Button>

                        <Button
                            shape={"round"}
                            color={"common"}
                            className="flex items-center !w-8 !h-8 !rounded-lg bg-[rgba(38,38,38,0.5)] active:!ring-0 focus:!ring-0"
                            icon={<Icon icon={Icons.Exchange} color="fill-white" width="20px" height="20px" />}
                            iconPlacement={"center"}
                            onClick={getRandomDefImgUrl}
                            title="Заменить случайной обложкой"
                            id={id + "CoverBtnExchange"}
                        />
                    </div>
                    {(logo || defaultLogoIds.length > 0) && (
                        <img
                            className="rounded-lg object-cover w-full h-full"
                            src={img}
                            onLoad={() => {
                                setIsLoading(false);
                            }}
                            onError={() => {
                                getRandomDefImgUrl();
                                onError?.();
                            }}
                        />
                    )}
                    {isLoading && (
                        <div className="absolute flex justify-center items-center z-20 top-0 right-0 bottom-0 left-0 pointer-events-none">
                            <Loader></Loader>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
