import React, { useEffect } from "react";
import { SingleValue } from "react-select";
import { ComboBox, FormGroup, Input, Label, Toggle, CoverInput, flash } from "Uikit";
import { Section } from "Uikit/Forms/Section";
import { TestRequest } from "Api/Requests/TestRequest";
import { CategoryMenuList, UsersOption, UsersSingleValue } from "Uikit/Forms/SelectComponents";
import { CategoryModal } from "Uikit/CategoryModalNew/CategoryModal";
import Api from "Api/index";
import { AccountableUser } from "Api/Responses/UserResponse";
import { ResourceState, UIErrorMessages } from "Enums";
import "react-day-picker/dist/style.css";
import { DateTimeSelector } from "Uikit/DateTimeSelector/DateTimeSelector";
import { IOption } from "types";

interface ITestSettings {
    errors: any;
    users: { label: string; value: string; payload: AccountableUser }[];
    categories: IOption[];
    onCategoriesChange: (category: any) => void;
    test: TestRequest;
    defaultLogos: string[];
    cover: File | null;
    onChangeCover: (data: File | null, imgBase64?: string) => void;
    onChange: (course: TestRequest) => void;
}
export const TestSettings = ({
    errors,
    users,
    categories,
    onCategoriesChange,
    test,
    cover,
    defaultLogos,
    onChangeCover,
    onChange,
}: ITestSettings) => {
    const [isCategoryModalOpen, setIsCategoryModalOpen] = React.useState(false);
    const [categoryOptions, setCategoryOptions] = React.useState<IOption[]>([]);

    const onAddCategoryClick = () => {
        setIsCategoryModalOpen(true);
    };

    const onCategorySubmit = async (title: string) => {
        try {
            const response = await Api.Test.CategoryCreate({ title: title });

            onCategoriesChange({ label: title, value: response.id });
            onChange({ ...test, categoryId: response.id });
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if (categories.length) {
            setCategoryOptions(categories);
        }
    }, [categories]);

    const coverInputErrorHandler = () => {
        flash.error(UIErrorMessages.FILE_LOADING_ERROR);
    };

    return (
        <>
            <CategoryModal
                isOpen={isCategoryModalOpen}
                onClose={() => setIsCategoryModalOpen(false)}
                onSubmit={onCategorySubmit}
            />
            <Section label="Внешний вид">
                <FormGroup>
                    <Label>Обложка теста</Label>
                    <CoverInput
                        btn={false}
                        logo={cover ?? test.logoId ?? null}
                        defaultLogoIds={defaultLogos}
                        onChange={onChangeCover}
                        onChangeDefaultLogo={(id) => onChange({ ...test, logoId: id })}
                        id="adminNewTest"
                        onError={coverInputErrorHandler}
                    />
                </FormGroup>
                <FormGroup>
                    <Label isRequired={true}>Заголовок теста</Label>
                    <Input
                        isRequired={true}
                        placeholder="Введите заголовок"
                        value={test.title}
                        onChange={(e) => onChange({ ...test, title: e.target.value })}
                        error={errors["title"]}
                        maxLength={128}
                        id="adminNewTestInputName"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Краткое описание</Label>
                    <Input
                        as="textarea"
                        placeholder="Введите краткое описание"
                        value={test.description}
                        onChange={(e) => onChange({ ...test, description: e.target.value })}
                        error={errors["description"]}
                        maxLength={1024}
                        id="adminNewTestInputDescription"
                    />
                </FormGroup>
            </Section>
            <Section label="Месторасположение">
                <FormGroup>
                    <Label isRequired={true}>Категория</Label>
                    <ComboBox
                        isSearchable
                        placeholder="Выберите категорию"
                        value={categoryOptions[categoryOptions.findIndex((p) => test.categoryId === p.value)]}
                        options={categoryOptions}
                        addOptionClick={onAddCategoryClick}
                        components={{ MenuList: CategoryMenuList, NoOptionsMessage: () => <div></div> }}
                        onChange={(option: SingleValue<any>) => onChange({ ...test, categoryId: option.value })}
                        error={errors["categoryId"]}
                        id="adminNewTestComboBoxCategory"
                    />
                </FormGroup>
            </Section>
            <Section label="Контроль">
                <FormGroup>
                    <Label isRequired={true}>Ответственный</Label>
                    <ComboBox
                        isSearchable
                        placeholder="Выберите ответственного"
                        value={users[users.findIndex((p) => p.value === test.reviewerId)]}
                        options={users}
                        onChange={(option: SingleValue<any>) => onChange({ ...test, reviewerId: option.value })}
                        error={errors["manager"]}
                        components={{
                            SingleValue: UsersSingleValue,
                            Option: UsersOption,
                        }}
                        id="adminNewTestComboBoxManager"
                    />
                </FormGroup>
            </Section>
            <Section label="Публикация">
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Назначить отложенную дату публикации"
                        enabled={!!test.publicationTime}
                        onChange={(p) => {
                            const defaultDate = new Date();

                            defaultDate.setHours(10);
                            defaultDate.setMinutes(0);

                            defaultDate.setSeconds(0);
                            defaultDate.setMilliseconds(0);

                            const updatedTest = {
                                ...test,
                                publicationTime: p ? defaultDate.getTime() / 1000 + 86400 : null,
                            };
                            if (p && test.state === ResourceState.ACTIVE) {
                                updatedTest.state = ResourceState.HIDDEN;
                            }
                            onChange(updatedTest);
                        }}
                        id="adminNewTestTogglePublicLater"
                    />
                    {!!test.publicationTime && (
                        <DateTimeSelector
                            isPastTime={false}
                            error={
                                test.publicationTime && test.archiveTime && test.archiveTime <= test.publicationTime
                                    ? "Дата снятия с публикации не может быть раньше даты публикации"
                                    : ""
                            }
                            dateTime={test.publicationTime}
                            onChange={(dateTime) => onChange({ ...test, publicationTime: dateTime })}
                            showTimeInput
                        />
                    )}
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        enabled={!!test.archiveTime}
                        onChange={(p) => {
                            const defaultDate = new Date();

                            defaultDate.setHours(10);
                            defaultDate.setMinutes(0);

                            defaultDate.setSeconds(0);
                            defaultDate.setMilliseconds(0);

                            const updatedTest = {
                                ...test,
                                archiveTime: p ? defaultDate.getTime() / 1000 + 86400 : null,
                            };
                            if (p && test.state === ResourceState.HIDDEN) {
                                updatedTest.state = !test.publicationTime ? ResourceState.ACTIVE : ResourceState.HIDDEN;
                            }
                            onChange(updatedTest);
                        }}
                        label="Назначить дату снятия с публикации"
                        id="adminNewTestTogglePublicEnd"
                    />
                    {!!test.archiveTime && (
                        <DateTimeSelector
                            isPastTime={false}
                            error={
                                test.publicationTime && test.archiveTime && test.archiveTime <= test.publicationTime
                                    ? "Дата снятия с публикации не может быть раньше даты публикации"
                                    : ""
                            }
                            dateTime={test.archiveTime}
                            onChange={(dateTime) => onChange({ ...test, archiveTime: dateTime })}
                            showTimeInput
                        />
                    )}
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        enabled={!!test.deadlineTime}
                        onChange={(p) => {
                            const defaultDate = new Date();

                            defaultDate.setHours(10);
                            defaultDate.setMinutes(0);

                            onChange({ ...test, deadlineTime: p ? defaultDate.getTime() / 1000 + 86400 : null });
                        }}
                        label="Назначить дедлайн"
                        tip="Тест получит статус «Просрочен», но будет доступен для прохождения"
                        id="adminNewTestToggleDeadLine"
                    />
                    {!!test.deadlineTime && (
                        <DateTimeSelector
                            isPastTime={true}
                            dateTime={test.deadlineTime}
                            onChange={(dateTime) => onChange({ ...test, deadlineTime: dateTime })}
                            showTimeInput
                        />
                    )}
                </FormGroup>
            </Section>
        </>
    );
};
