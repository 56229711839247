import React, { useEffect, useState, useId } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { Icon, Icons, PopoverList } from "Uikit";
import { TaskReadResponseItem } from "Api/Responses/TaskResponse";
import { TaskItemType } from "Enums";
import { TaskQuestionText } from "./TaskQuestionText";
import { TaskQuestionNumber } from "./TaskQuestionNumber";
import { TaskQuestionSingleChoice } from "./TaskQuestionSingleChoice";
import { TaskQuestionMultipleChoice } from "./TaskQuestionMultipleChoice";
import { TaskQuestionFile } from "./TaskQuestionFile";
import { TaskQuestionImage } from "./TaskQuestionImage";

export interface ITaskQuestionProps {
    taskItem: TaskReadResponseItem;
    onEditTaskItem: (updatedItem: TaskReadResponseItem, updatedFields?: [key: string, value: any]) => void;
    expanded?: boolean;
    errors?: { [key: string]: string };
}

interface ITaskContentItemProps {
    taskItem: TaskReadResponseItem;
    index: number;
    onEditTaskItem: (updatedItem: TaskReadResponseItem) => void;
    onDeleteTaskItem: (isItemChanged: boolean) => void;
    onIsDraggingChange: (isDragging: boolean) => void;
    currentTaskItem: TaskReadResponseItem | undefined;
    setCurrentTaskItem: (currentTaskItem: TaskReadResponseItem | undefined) => void;
    handleCopyItem: (taskItem: TaskReadResponseItem) => void;
    errors: { [key: string]: string };
    onChangeTaskItemType?: (newTaskItem: TaskReadResponseItem, oldTaskItem: TaskReadResponseItem) => void;
}

const taskItemTypes = [
    { value: TaskItemType.Text, label: "Текстовое поле" },
    { value: TaskItemType.Number, label: "Числовое поле" },
    { value: TaskItemType.SingleChoice, label: "Один из списка" },
    { value: TaskItemType.MultipleChoice, label: "Несколько из списка" },
    { value: TaskItemType.File, label: "Загрузка файлов" },
    { value: TaskItemType.Image, label: "Загрузка изображения" },
];

export const TaskContentItem = ({
    taskItem,
    index,
    onEditTaskItem,
    onDeleteTaskItem,
    onIsDraggingChange,
    currentTaskItem,
    setCurrentTaskItem,
    handleCopyItem,
    errors,
    onChangeTaskItemType,
}: ITaskContentItemProps) => {
    const [typePopoverState, setTypePopoverState] = useState<boolean>(false);
    const { question, reviewerNote, description } = taskItem;
    const id = useId();

    const { listeners, setNodeRef, transform, transition, setActivatorNodeRef, isDragging } = useSortable({
        id: taskItem.orderNumber + 1,
    });

    const style = {
        transform: CSS.Transform.toString(
            transform ? { x: transform?.x, y: transform?.y, scaleX: transform?.scaleX, scaleY: 1 } : null,
        ),
        transition,
        zIndex: isDragging ? 10 : "initial",
        boxShadow: isDragging ? "0px 8px 20px rgba(0,0,0,0.12)" : "none",
        borderRadius: isDragging ? 10 : 0,
    };

    useEffect(() => {
        onIsDraggingChange(isDragging);
    }, [isDragging, onIsDraggingChange]);

    const handleContentItemClick = () => {
        if (taskItem !== currentTaskItem) {
            setCurrentTaskItem(taskItem);
        }
    };
    const isExpanded = isEqual(omit(taskItem, "answers"), omit(currentTaskItem, "answers"));

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="task-content-item relative flex justify-between pt-6 last:border-0 border-b border-gray-blue bg-white"
            onClick={handleContentItemClick}
        >
            <div className="flex flex-col w-75">
                <div className="flex items-center mb-0.25 relative">
                    <PopoverList
                        offset={[0, 18]}
                        visible={typePopoverState}
                        onClickOutside={(instance, event) => {
                            if (!(event?.target as HTMLElement)?.closest(".popover-trigger")) {
                                setTypePopoverState(false);
                            }
                        }}
                        className={"!left-[132px]"}
                    >
                        {taskItemTypes.map((taskItemType, taskItemTypeIndex) => {
                            return (
                                <PopoverList.Item
                                    key={taskItemTypeIndex}
                                    name="list"
                                    onClickItem={() => {
                                        if (taskItem.contentType !== taskItemType.value) {
                                            const oldTaskItem = { ...taskItem };
                                            taskItem.contentType = taskItemType.value;
                                            taskItem.answers = [];
                                            onChangeTaskItemType?.(taskItem, oldTaskItem);
                                        }
                                        setTypePopoverState((prev) => {
                                            return !prev;
                                        });
                                    }}
                                    isActive={taskItem.contentType === taskItemType.value}
                                >
                                    {taskItemType.label}
                                </PopoverList.Item>
                            );
                        })}
                    </PopoverList>
                    <div className="cursor-grab" ref={setActivatorNodeRef} {...listeners}>
                        <Icon className="mr-2" icon={Icons.Grid} width={20} height={20} color="fill-gray-light" />
                    </div>
                    <div
                        className="flex items-center"
                        onClick={() => {
                            setTypePopoverState((prev) => {
                                return !prev;
                            });
                        }}
                    >
                        <b
                            className={`popover-trigger mr-1 text-black${
                                Object.keys(errors).length > 0 ? " text-red" : ""
                            }`}
                        >
                            {
                                taskItemTypes.find((item) => {
                                    return item.value === taskItem.contentType;
                                })?.label
                            }
                        </b>
                        {isExpanded && (
                            <div className="popover-trigger cursor-pointer translate-y-1/4 mb-1">
                                <Icon icon={Icons.ChevronDown} width={12} height={12} color="fill-blue-blue" />
                            </div>
                        )}
                    </div>
                </div>
                <span className="ml-7 text-xs text-gray-light">Вопрос {index + 1}</span>
            </div>
            {/* Поля вопроса */}
            <div className="flex flex-col mx-auto w-full  max-w-175">
                {/* Компонент вопроса */}
                {taskItem.contentType === TaskItemType.Text && (
                    <TaskQuestionText
                        taskItem={taskItem}
                        onEditTaskItem={onEditTaskItem}
                        expanded={isExpanded}
                        errors={errors}
                    />
                )}
                {taskItem.contentType === TaskItemType.Number && (
                    <TaskQuestionNumber
                        taskItem={taskItem}
                        onEditTaskItem={onEditTaskItem}
                        expanded={isExpanded}
                        errors={errors}
                    />
                )}
                {taskItem.contentType === TaskItemType.SingleChoice && (
                    <TaskQuestionSingleChoice
                        taskItem={taskItem}
                        onEditTaskItem={onEditTaskItem}
                        expanded={isExpanded}
                        errors={errors}
                    />
                )}
                {taskItem.contentType === TaskItemType.MultipleChoice && (
                    <TaskQuestionMultipleChoice
                        taskItem={taskItem}
                        onEditTaskItem={onEditTaskItem}
                        expanded={isExpanded}
                        errors={errors}
                    />
                )}
                {taskItem.contentType === TaskItemType.File && (
                    <TaskQuestionFile
                        taskItem={taskItem}
                        onEditTaskItem={onEditTaskItem}
                        expanded={isExpanded}
                        errors={errors}
                    />
                )}
                {taskItem.contentType === TaskItemType.Image && (
                    <TaskQuestionImage
                        taskItem={taskItem}
                        onEditTaskItem={onEditTaskItem}
                        expanded={isExpanded}
                        errors={errors}
                    />
                )}
                {/* /Компонент вопроса */}
            </div>
            {/* Кнопки управления вопроса */}
            <div className="flex items-start justify-end w-75" id={id}>
                {!isExpanded && (
                    <div className="cursor-pointer mr-4" onClick={() => setCurrentTaskItem(taskItem)}>
                        <Icon icon={Icons.Pencil} width={20} height={20} color="fill-blue-drk hover:fill-blue-hover" />
                    </div>
                )}
                {isExpanded && (
                    <div className="cursor-pointer mr-4" onClick={() => setCurrentTaskItem(undefined)}>
                        <Icon icon={Icons.ChevronUp} width={20} height={20} color="fill-blue-drk" />
                    </div>
                )}
                <div
                    className="cursor-pointer mr-4"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCopyItem(taskItem);
                    }}
                >
                    <Icon icon={Icons.Copy} width={20} height={20} color="fill-blue-drk" />
                </div>
                <div
                    className="cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDeleteTaskItem([question, description, reviewerNote].some((item) => !!item));
                    }}
                >
                    <Icon icon={Icons.Delete} width={20} height={20} color="fill-blue-drk" />
                </div>
            </div>
        </div>
    );
};
