import { Exclude, Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";
import { ProgressStatus } from "Enums";

export class SearchResource {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public userResourceId: string;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public logoId: string | null;

    @Expose()
    @Type(() => String)
    public firstName: string | null;

    @Expose()
    @Type(() => String)
    public lastName: string | null;

    @Expose()
    @Type(() => String)
    public login: string | null;

    @Expose()
    @Type(() => String)
    public email: string | null;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public passingNumber?: number;
}

@Exclude()
export class SearchResultItem {
    @Expose()
    @Type(() => String)
    public label: string;

    @Expose()
    @Type(() => String)
    public took: string | null;

    @Expose()
    @Type(() => SearchResource)
    public resources: SearchResource[];

    @Expose()
    @Type(() => Number)
    public total: number;
}

export class SearchMultiResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public query?: string | null;

    @Expose()
    @Type(() => String)
    public took?: string | null;

    @Expose()
    public result: { [key: string]: SearchResultItem };
}

export class SearchListResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public query: string;

    @Expose()
    @Type(() => String)
    public took: string;

    @Expose()
    @Type(() => SearchResource)
    public resources: SearchResource[];

    @Expose()
    @Type(() => Number)
    public total: number;
}
