import React, { useState, useCallback, useEffect, Fragment } from "react";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { v4 as uuidv4 } from "uuid";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useDialog } from "hooks/useDialog";
import { Button, Icon, Icons, ModalTree } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Empty } from "Uikit/Page/Empty";
import { Confirmation } from "Components/Confirmation/Confirmation";
import Api from "Api";
import { ProgramReadSection, ProgramSectionItem, ProgramReadResponse } from "Api/Responses/ProgramResponse";
import { ProgramSectionModal } from "./ProgramSectionModal";
import { ProgramContentItem } from "./ProgramContentItem";
import { ResourceType } from "Enums";

const DND_SECTION = "DND_SECTION";

interface IProgramContentProps {
    program: ProgramReadResponse;
    onChange: (proram: ProgramReadResponse) => void;
    errors: any;
}

const getItemSection = (item: ProgramSectionItem, sections: ProgramReadSection[]) => {
    return sections.find((section) => section.courses.includes(item));
};

const itemsToSections = (components: ProgramSectionItem[], sections: ProgramReadSection[]) => {
    let currentSection: ProgramReadSection | undefined = undefined;
    for (const component of components) {
        if (component.type === DND_SECTION) {
            currentSection = sections.find((section) => section.id === component.id)!;
            currentSection.courses = [];
        } else if (currentSection) {
            currentSection.courses.push(component);
        }
    }
    return sections;
};

const flattenDndItems = (program: ProgramReadResponse) => {
    const result: ProgramSectionItem[] = [];
    for (const section of program.sections) {
        result.push(
            Object.assign(new ProgramSectionItem(), {
                id: section.id,
                type: DND_SECTION,
                title: section.title,
            }),
        );
        for (const component of section.courses) {
            result.push(component);
        }
    }
    return result;
};

export const ProgramContent = ({ program, onChange, errors }: IProgramContentProps) => {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [isCourseModal, setIsCourseModal] = useState(false);
    const [coursesTree, setCoursesTree] = useState<any>([]);
    const [showCoursesTree, setShowCoursesTree] = useState<any>([]);

    const [isSectionModal, setIsSectionModal] = useState(false);

    const [section, setSection] = useState<ProgramReadSection | undefined>(undefined);

    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [components, setComponents] = useState<ProgramSectionItem[]>(flattenDndItems(program));

    const updateProgram = (program: ProgramReadResponse) => {
        onChange(program);
        setComponents(flattenDndItems(program));
    };

    const requestCoursesTree = useCallback(async () => {
        const courses = await Api.Course.List(0, 2000, [], {
            "state.in": "ACTIVE",
        });

        const coursesTree: any[] = [];
        courses.Content.forEach((p) => {
            let categoryIndex = coursesTree.findIndex((p1) => p1.id === p.category.id);

            if (categoryIndex === -1) {
                coursesTree.push({
                    id: p.category.id,
                    nodeType: "PROJECT",
                    name: p.category.title,
                    children: [],
                    state: {
                        open: false,
                    },
                });

                categoryIndex = coursesTree.length - 1;
            }

            coursesTree[categoryIndex].children.push({
                id: p.id,
                logoId: p.logoId,
                name: p.title,
                nodeType: "SECTION",
                type: ResourceType.COURSE,
                componentCount: p.componentCount,
                approxCompletionMinutes: p.approxCompletionMinutes,
                children: null,
            });
        });

        if (coursesTree.length !== 0) {
            coursesTree[0].state.open = true;
        }

        setCoursesTree(coursesTree);
    }, []);

    const onSectionSubmit = ({ name }: { name: string }) => {
        const newSections = Object.assign(program.sections);

        if (!section) {
            newSections.push({
                id: uuidv4(),
                title: name,
                courses: [],
            });
        } else {
            newSections[newSections.indexOf(section)].title = name;
        }

        updateProgram({ ...program, sections: newSections });

        setSection(undefined);
        setIsSectionModal(false);
    };

    const onRemoveClick = (section: ProgramReadSection) => {
        openDialog({
            title: "Удаление секции",
            description: "«" + section.title + "»",
            content:
                "Вы действительно хотите удалить секцию? Все вложенные в нее курсы будут исключены из программы. Восстановить секцию невозможно, её придётся собирать заново",
            closeBtnText: "Отмена",
            submitBtnText: "Удалить",
            submitBtnColor: "danger",
            onRequestClose: () => closeDialog(),
            onRequestSubmit: () => {
                const newSections = Object.assign(program.sections);
                const sectionIndex = newSections.indexOf(section);

                newSections.splice(sectionIndex, 1);

                updateProgram({ ...program, sections: newSections });
                closeDialog();
            },
        });
    };

    const onUpClick = (section: ProgramReadSection) => {
        const newSections = Object.assign(program.sections);
        const sectionIndex = newSections.indexOf(section);

        newSections.splice(sectionIndex, 1);
        newSections.splice(sectionIndex - 1, 0, section);

        updateProgram({ ...program, sections: newSections });
    };
    const onDownClick = (section: ProgramReadSection) => {
        const newSections = Object.assign(program.sections);
        const sectionIndex = newSections.indexOf(section);

        newSections.splice(sectionIndex, 1);
        newSections.splice(sectionIndex + 1, 0, section);

        updateProgram({ ...program, sections: newSections });
    };

    const onSelectSectionCourses = async (/* section: ProgramSection */) => {
        const programSections = Object.assign(program.sections);
        const currentComponents = programSections.reduce((acc: ProgramSectionItem[], cur: ProgramReadSection) => {
            return acc.concat(cur.courses);
        }, []);
        const tree = structuredClone(coursesTree);
        const showTree = [];

        for (const element of tree) {
            const categoryTree = element.children.filter(
                (p: any) => !currentComponents.find((p1: ProgramSectionItem) => p1.id === p.id),
            );

            if (categoryTree.length === 0) {
                continue;
            }

            element.children = categoryTree;
            showTree.push(element);
        }

        setShowCoursesTree(showTree);
    };

    const onAddCourses = () => {
        const programSections = Object.assign(program.sections);
        const currentSection: ProgramReadSection = programSections.find(
            (p: ProgramReadSection) => p.id === section?.id,
        );

        const currentSectionComponents = structuredClone(currentSection.courses);

        for (const selectedItem of selectedItems) {
            currentSectionComponents.push({
                id: selectedItem.id,
                type: selectedItem.type,
                title: selectedItem.name,
                logoId: selectedItem.logoId,
                description: selectedItem.description,
                createTime: selectedItem.createTime,
                modifyTime: selectedItem.modifyTime,
                deadlineTime: selectedItem.deadlineTime,
                state: selectedItem.state,
                ratingPoints: selectedItem.ratingPoints,
                componentCount: selectedItem.componentCount,
                avgReviewRating: selectedItem.avgReviewRating,
                progressPercent: selectedItem.progressPercent,
                approxCompletionMinutes: selectedItem.approxCompletionMinutes,
            });
        }
        currentSection.courses = currentSectionComponents;

        updateProgram({ ...program });

        setSection(undefined);
        setIsCourseModal(false);
    };

    const onRemoveSectionItem = (item: ProgramSectionItem) => {
        const sections: ProgramReadSection[] = Object.assign(program.sections);
        const currentSection = getItemSection(item, sections);
        if (currentSection) {
            currentSection.courses.splice(
                currentSection.courses.findIndex((p) => p.id === item.id),
                1,
            );

            updateProgram({ ...program, sections: sections });
        }
    };

    const sortedIds = components.map((component) => component.id);

    const onDragOver = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id === over?.id) {
            return;
        }

        const oldIndex = sortedIds.findIndex((id) => id === active.id);
        const newIndex = sortedIds.findIndex((id) => id === over?.id);

        if (newIndex === 0) {
            return;
        }

        const sectionItems = arrayMove(components, oldIndex, newIndex);
        const programSections = structuredClone(program.sections);

        updateProgram({ ...program, sections: itemsToSections(sectionItems, programSections) });
    };

    useEffect(() => {
        const fetch = async () => {
            await requestCoursesTree();
        };
        fetch().then();
    }, [requestCoursesTree]);

    return (
        <>
            <Confirmation {...dialogState} />
            <ProgramSectionModal
                isOpen={isSectionModal}
                section={section}
                onSubmit={onSectionSubmit}
                onClose={() => {
                    setSection(undefined);
                    setIsSectionModal(false);
                }}
            />
            <ModalTree
                isOpen={isCourseModal}
                title="Добавление курса"
                setIsOpen={setIsCourseModal}
                treeData={showCoursesTree}
                checkedChange={setSelectedItems}
                onSubmit={onAddCourses}
            />
            <Button
                className="fixed bottom-6.5 right-6.5 flex justify-center items-center"
                onClick={() => {
                    setSection(undefined);
                    setIsSectionModal(true);
                }}
                shape="round"
                size="xl"
            >
                <Icon icon={Icons.Plus} width={26} height={26} color="fill-white" />
            </Button>
            {program.sections.length === 0 && (
                <Empty
                    topElement={
                        <div className="flex-center bg-blue-10 w-16.5 h-16.5 rounded-full mb-4">
                            <Icon icon={Icons.Program} width={27} height={27} color="fill-primary" />
                        </div>
                    }
                    title="В программе обучения пока ничего нет"
                    description={
                        <span className="inline-block max-w-125">
                            Чтобы добавить курсы в программу обучения, необходимо создать секцию. Секции — это блоки, на
                            которые разделена программа. Чтобы ее создать, нажмите на плюс в правом нижнем углу экрана
                        </span>
                    }
                    className="h-full"
                />
            )}
            {program.sections.length !== 0 && (
                <div id="adminNewCourseSections">
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragOver={(event: DragEndEvent) => onDragOver(event)}
                        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    >
                        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
                            <div className="flex flex-col">
                                {components.map((item, index) => {
                                    const isSectionElement = item.type === DND_SECTION;

                                    const findSection = (id: string) =>
                                        program.sections.find((section) => section.id === id);

                                    const handleAddElement = (section: ProgramReadSection) => {
                                        setSection(section);
                                        setIsCourseModal(true);
                                        onSelectSectionCourses(/* section as ProgramSection */).then();
                                    };

                                    const handleEdit = (sectionId: string) => {
                                        setSection(findSection(sectionId));
                                        setIsSectionModal(true);
                                    };

                                    const handleRemove = (sectionId: string) => {
                                        onRemoveClick(findSection(sectionId)!);
                                    };

                                    const handleMoveDown = (sectionId: string) => {
                                        onDownClick(findSection(sectionId)!);
                                    };

                                    const handleMoveUp = (sectionId: string) => {
                                        onUpClick(findSection(sectionId)!);
                                    };

                                    const isLast =
                                        components.findIndex(
                                            (component, innerIndex) =>
                                                innerIndex > index && component.type === DND_SECTION,
                                        ) < 0;

                                    const isGotNextSection =
                                        components.findIndex(
                                            (component, innerIndex) =>
                                                innerIndex === index + 1 && component.type === DND_SECTION,
                                        ) > 0;

                                    return (
                                        <Fragment key={uuidv4()}>
                                            {isSectionElement ? (
                                                <SectionElement
                                                    section={item as unknown as ProgramReadSection}
                                                    onAddElement={handleAddElement}
                                                    onEdit={handleEdit}
                                                    onRemove={handleRemove}
                                                    onMoveDown={handleMoveDown}
                                                    onMoveUp={handleMoveUp}
                                                    isFirst={index === 0}
                                                    isLast={isLast}
                                                    errors={errors?.sections?.[item.id]}
                                                />
                                            ) : (
                                                <ProgramContentItem
                                                    item={item}
                                                    onRemove={() => onRemoveSectionItem(item)}
                                                    isGotNextSection={isGotNextSection}
                                                />
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </SortableContext>
                    </DndContext>
                </div>
            )}
        </>
    );
};

interface SectionElementProps {
    onEdit: (sectionId: string) => void;
    section: ProgramReadSection;
    onAddElement: (section: ProgramReadSection) => void;
    isFirst: boolean;
    isLast: boolean;
    onMoveUp: (sectionId: string) => void;
    onMoveDown: (sectionId: string) => void;
    onRemove: (sectionId: string) => void;
    errors: any;
}

const SectionElement = ({
    onEdit,
    section,
    onAddElement,
    isLast,
    isFirst,
    onMoveUp,
    onMoveDown,
    onRemove,
    errors,
}: SectionElementProps) => {
    const { setNodeRef } = useSortable({
        id: section.id,
    });
    return (
        <div
            ref={setNodeRef}
            className="group flex justify-between items-center pb-4 pt-4 first:border-0 border-t border-[#EAEDF3]"
        >
            <div className="flex items-center">
                <MultiClumpTooltip
                    clamp={1}
                    label={section.title}
                    className={`mr-2 text-md text-[#262626] font-semibold${errors?.courses ? " text-red" : ""}`}
                />
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 mr-2.5 border-0 focus:!ring-0 disabled:!bg-transparent opacity-0 group-hover:opacity-100"
                    onClick={() => onEdit(section.id)}
                >
                    <Icon icon={Icons.Pencil} width={20} height={20} color="fill-blue-drk hover:fill-blue-hover" />
                </Button>
            </div>
            <div className="flex items-center shrink-0">
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 mr-7.5 border-0 focus:!ring-0"
                    onClick={() => {
                        onAddElement(section);
                    }}
                >
                    <Icon className="mr-1" icon={Icons.PlusFilled} width={16} height={16} color="fill-[#1280CE]" />
                    <span className="text-sm font-normal text-[#1280CE]">Добавить курс</span>
                </Button>
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 mr-2.5 border-0 focus:!ring-0 disabled:!bg-transparent"
                    disabled={isFirst}
                    onClick={() => onMoveUp(section.id)}
                >
                    <Icon
                        className={isFirst ? "hover:fill-[#C9C9C9]" : "hover:fill-[#1280CE]"}
                        icon={Icons.ArrowUp}
                        width={22}
                        height={22}
                        color={isFirst ? "fill-[#C9C9C9]" : "fill-[#878E9C]"}
                    />
                </Button>
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 mr-5 border-0 focus:!ring-0 disabled:!bg-transparent"
                    disabled={isLast}
                    onClick={() => onMoveDown(section.id)}
                >
                    <Icon
                        className={isLast ? "hover:fill-[#C9C9C9]" : "hover:fill-[#1280CE]"}
                        icon={Icons.ArrowDown}
                        width={22}
                        height={22}
                        color={isLast ? "fill-[#C9C9C9]" : "fill-[#878E9C]"}
                    />
                </Button>
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 border-0 focus:!ring-0"
                    onClick={() => onRemove(section.id)}
                >
                    <Icon icon={Icons.Close} width={24} height={24} />
                </Button>
            </div>
        </div>
    );
};
