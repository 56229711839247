import { object, string, array, boolean } from "yup";
import {
    TaskPublicUserAnswersRequestData,
    TaskPublicUserAnswersRequestAnswer,
    TaskPublicUserAnswersRequest,
} from "Api/Requests/TaskPublicRequest";

const requiredError = "Это обязательный вопрос";

export const validationSchema = object({
    textContent: array().of(
        object().shape({
            mandatory: boolean(),
            userText: string()
                .when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                })
                .test("userText", "Недопустимые символы!", (value) => {
                    return value
                        ? // eslint-disable-next-line no-useless-escape
                          !!value?.match(/^[а-яА-ЯёЁa-zA-Z0-9\s.,\-–—"'()«»!@#№$%^&*_+=?;:|\/~\\><\[\]}{]*$/gi)
                        : true;
                }),
        }),
    ),
    numericContent: array().of(
        object().shape({
            mandatory: boolean(),
            userNumber: string().when("mandatory", {
                is: true,
                then: (schema) => schema.required(requiredError),
                otherwise: (schema) => schema.notRequired(),
            }),
        }),
    ),
    singleChoiceContent: array().of(
        object().shape({
            mandatory: boolean(),
            answerId: string().when("mandatory", {
                is: true,
                then: (schema) => schema.required(requiredError),
                otherwise: (schema) => schema.notRequired(),
            }),
        }),
    ),
    multipleChoiceContent: array().of(
        object().shape({
            mandatory: boolean(),
            answerIds: array().when("mandatory", {
                is: true,
                then: () => array().min(1).required(requiredError),
                otherwise: (schema) => schema.notRequired(),
            }),
        }),
    ),
    imageContent: array().of(
        object().shape({
            mandatory: boolean(),
            userAttachments: array()
                .ensure()
                .when("mandatory", {
                    is: true,
                    then: (schema) => schema.min(1).required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
        }),
    ),
    fileContent: array().of(
        object().shape({
            mandatory: boolean(),
            userAttachments: array()
                .ensure()
                .when("mandatory", {
                    is: true,
                    then: (schema) => schema.min(1).required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
        }),
    ),
});

export const normalizeRequestData = (values: TaskPublicUserAnswersRequestData, id: string) => {
    const { textContent, numericContent, singleChoiceContent, multipleChoiceContent, imageContent, fileContent } =
        values;
    const result: TaskPublicUserAnswersRequest = {
        exerciseId: id,
        answers: [],
    };
    const blankAnswer: TaskPublicUserAnswersRequestAnswer = {
        questionId: "",
        answerIds: [],
        customAnswer: "",
    };
    if (textContent.length > 0) {
        textContent.forEach(({ id, userText }) => {
            result.answers.push({
                ...blankAnswer,
                questionId: id,
                customAnswer: userText,
            });
        });
    }
    if (numericContent.length > 0) {
        numericContent.forEach(({ id, userNumber }) => {
            result.answers.push({
                ...blankAnswer,
                questionId: id,
                customAnswer: userNumber,
            });
        });
    }
    if (singleChoiceContent.length > 0) {
        singleChoiceContent.forEach(({ id, answerId }) => {
            result.answers.push({
                ...blankAnswer,
                questionId: id,
                answerIds: [answerId],
            });
        });
    }
    if (multipleChoiceContent.length > 0) {
        multipleChoiceContent.forEach(({ id, answerIds }) => {
            result.answers.push({
                ...blankAnswer,
                questionId: id,
                answerIds,
            });
        });
    }
    if (imageContent.length > 0) {
        imageContent.forEach(({ id, userAttachments }) => {
            result.answers.push({
                ...blankAnswer,
                questionId: id,
                answerIds: (userAttachments?.map(({ id }) => id) as string[]) ?? [],
            });
        });
    }
    if (fileContent.length > 0) {
        fileContent.forEach(({ id, userAttachments }) => {
            result.answers.push({
                ...blankAnswer,
                questionId: id,
                answerIds: (userAttachments?.map(({ id }) => id) as string[]) ?? [],
            });
        });
    }

    return result;
};
