import { MaterialListResponse } from "Api/Responses/MaterialResponse";
import React, { useState } from "react";
import { Button, PopoverList } from "Uikit";

interface RowActionPopoverProps {
    material: MaterialListResponse;
    onCopyHandler: (material: MaterialListResponse) => void;
    onArchiveHandler: (material: MaterialListResponse) => void;
}

export const MaterialsRowActionPopover = ({ material, onCopyHandler, onArchiveHandler }: RowActionPopoverProps) => {
    const [visible, setVisible] = useState(false);

    const copy = () => {
        onCopyHandler(material);
        setVisible(false);
    };

    const archive = () => {
        onArchiveHandler(material);
        setVisible(false);
    };

    return (
        <div>
            <PopoverList offset={[0, 18]} visible={visible} onClickOutside={() => setVisible(false)} className="">
                <Button
                    shape={"round"}
                    color={"common"}
                    className="!text-blue-drk hover:!text-blue-hover"
                    onClick={() => setVisible(!visible)}
                >
                    ...
                </Button>
                <PopoverList.Item name="teamAdd" onClickItem={copy}>
                    Создать копию
                </PopoverList.Item>
                <PopoverList.Item name="teamRemove" onClickItem={archive}>
                    Архивировать
                </PopoverList.Item>
            </PopoverList>
        </div>
    );
};
