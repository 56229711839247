import { Client } from "@stomp/stompjs";
import { getCookie } from "typescript-cookie";
import { ResourceType } from "Enums";

let stompClient: any = null;
const subscribers: any[] = [];

const NotificationConnect = () => {
    if (stompClient) {
        return;
    }

    stompClient = new Client({
        brokerURL:
            (process.env.NODE_ENV && process.env.NODE_ENV === "production" ? "wss://" : "ws://") +
            window.location.host +
            "/service/lms-notification/websocket/notifications",
    });

    stompClient.onConnect = () => {
        stompClient.publish({
            destination: "/websocket/notifications",
            body: `{"messageType":"SESSION","body":{"sessionId":"${getCookie("USER_SESSION")}"}}`,
        });
        stompClient.subscribe("/user/topic/notifications", (message: any) => {
            subscribers.forEach((callback) => callback(message));
        });
    };

    stompClient.activate();
};

export enum WssMessageType {
    NOTIFICATION = "NOTIFICATION",
    NOTIFICATION_COUNT = "NOTIFICATION_COUNT",
    LOGOUT = "LOGOUT",
    TEAMS = "CACHE_REVOKE_TEAM_LIST",
}

export interface WssMessage {
    messageType: WssMessageType;
    body: {
        actionType?: string;
        objectType?: ResourceType;
        visibilityType?: string;
        objectId?: string;
        count?: number;
    };
}

export const NotificationSubscribe = (onMessage: (message: WssMessage) => Promise<void>) => {
    subscribers.push((message: any) => {
        let parsedMessage;

        if (message.isBinaryBody) {
            const stringFromBinary = new TextDecoder().decode(message._binaryBody);
            parsedMessage = JSON.parse(stringFromBinary);
        } else {
            parsedMessage = JSON.parse(message._body);
        }

        onMessage(parsedMessage).then();
    });
};

export default NotificationConnect;
