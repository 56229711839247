import React, { ChangeEvent } from "react";
import { Icon, Icons, Input } from "..";

interface ISearchProps {
    value?: string;
    onChange: (e: ChangeEvent<HTMLInputElement> | null) => void;
}

export const SearchInput = ({ value = "", onChange }: ISearchProps) => {
    return (
        <div className="flex items-center grow gap-4">
            <div className="w-full flex justify-between relative grow min-w-[350px]">
                <span className="z-20 absolute inset-y-0 left-1 flex items-center pl-2">
                    <button className="z-20 p-1 focus:outline-none focus:shadow-outline">
                        <Icon
                            icon={Icons.Search}
                            width={18}
                            height={18}
                            color="fill-gray-text"
                            className="2xl:!w-5.5 2xl:!h-5.5"
                        />
                    </button>
                </span>
                <Input
                    id="userTrainingSearchInput"
                    className={`z-10 block w-[350px] 2xl:w-105 border border-gray-stroke placeholder:text-gray-text pl-10 2xl:pl-11 ${
                        value ? " !pr-10 2xl:!pr-11" : ""
                    }`}
                    placeholder="Поиск..."
                    value={value}
                    onChange={onChange}
                    maxLength={256}
                />
                {value && (
                    <div
                        className="absolute top-1/2 -translate-y-1/2 right-3 w-5 h-5 flex-center cursor-pointer z-[10]"
                        onClick={() => {
                            onChange(null);
                        }}
                    >
                        <Icon
                            icon={Icons.Close}
                            width={20}
                            height={20}
                            color="fill-gray-text"
                            className="2xl:!w-5.5 2xl:!h-5.5"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
