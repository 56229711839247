import { configureStore } from "@reduxjs/toolkit";
import authSlice, { IAuthSlice } from "slices/authSlice";
import userSlice, { IUserSlice } from "slices/userSlice";
import { utilReducer } from "slices/utilSlice";
import { UtilSliceState } from "./slices/utilSlice";
import headerSlice, { IHeaderSlice } from "./slices/headerSlice";
import programSlice, { IProgramSlice } from "./slices/programSlice";

export interface IReducer {
    auth: IAuthSlice;
    user: IUserSlice;
    util: UtilSliceState;
    header: IHeaderSlice;
    program: IProgramSlice;
}
const store = configureStore({
    reducer: {
        auth: authSlice,
        user: userSlice,
        util: utilReducer,
        header: headerSlice,
        program: programSlice,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
