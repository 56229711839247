import React, { useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, SortingState } from "@tanstack/react-table";
import { useInfiniteQuery } from "react-query";
import { Icon, Icons, Search, Table } from "Uikit";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { useNavigate } from "react-router-dom";
import { UserStatisticListResponse } from "Api/Responses/UserResponse";
import Api from "Api";
import { InfiniteList } from "Components/InfiniteList/InfiniteList";
import { formatLastActivityTime } from "helpers/dateHelper";
import { useDebounceValue } from "hooks/useDebounceValue";
import clsx from "clsx";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Empty } from "Uikit/Page/Empty";
import { numWord } from "helpers/numWord";

interface ITeamUsersTableProps {
    id: string;
}

export const TeamUsersTable = ({ id }: ITeamUsersTableProps) => {
    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [debounceSearch] = useDebounceValue(search, 500);

    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [sorting, setSorting] = useState<SortingState>([
        /* { id: "lastModified", desc: true } */
    ]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const dataQuery = useInfiniteQuery(
        ["tasks", "collection", sorting, filters, debounceSearch, id],
        async ({ pageParam }) => {
            const page = pageParam ?? 0;

            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                filtersData[element] = filters[element];
            }

            if (debounceSearch) {
                filtersData["searchQuery.contains"] = debounceSearch;
            }

            const sortingCopy = structuredClone(sorting);
            const sortingJobTitleIndex = sorting.findIndex((item) => item.id === "jobTitle");
            if (sortingCopy[sortingJobTitleIndex]) {
                sortingCopy[sortingJobTitleIndex].id = "jobTitle.name";
            }
            const sortingCourseStatisticsIndex = sorting.findIndex((item) => item.id === "courseStatistics");
            if (sortingCopy[sortingCourseStatisticsIndex]) {
                sortingCopy[sortingCourseStatisticsIndex].id = "courseStatistics.passedCoursesCount";
            }

            return await Api.User.GetTeamUsers(
                id,
                page,
                15,
                filtersData,
                sortingCopy.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "desc",
            );
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                return lastPage.Page + 1;
            },
        },
    );

    const { data, isFetching, fetchNextPage } = dataQuery;

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const columns = useMemo<ColumnDef<UserStatisticListResponse>[]>(
        () => [
            {
                header: "Участник",
                enableResizing: true,
                size: 400,
                footer: (props) => props.column.id,
                cell: ({ row: { original: user } }) => {
                    return (
                        <div className="group flex items-center space-x-3 2xl:space-x-4 w-full overflow-hidden">
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={window.screen.width >= 1600 ? 45 : 36}
                            />
                            <div
                                className={clsx("w-full, cursor-pointer")}
                                onClick={() => navigate(`/personal/${user.id}`)}
                            >
                                <MultiClumpTooltip
                                    className="group-hover:text-blue"
                                    clamp={1}
                                    label={String(user.firstName + " " + user.lastName)}
                                />
                            </div>
                        </div>
                    );
                },
                accessorKey: "firstName",
            },
            {
                header: "Должность",
                enableResizing: true,
                size: 300,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { jobTitle },
                    },
                }) => {
                    return <>{jobTitle?.name}</>;
                },
                accessorKey: "jobTitle",
            },
            {
                header: "Пройдено курсов",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: {
                            courseStatistics: { courseCount, passedCoursesCount },
                        },
                    },
                }) => {
                    return (
                        <>
                            {`${
                                courseCount
                                    ? Number(String(passedCoursesCount / courseCount).substring(0, 4)) * 100
                                    : courseCount
                            }%`}
                            <span className="text-gray">{` | ${passedCoursesCount} из ${courseCount}`}</span>
                        </>
                    );
                },
                accessorKey: "courseStatistics",
            },
            {
                header: "Был в сети",
                enableResizing: true,
                size: 200,
                accessorKey: "lastActivityDate",
                cell: ({
                    row: {
                        original: { lastActivityDate },
                    },
                }) => {
                    return <>{lastActivityDate ? formatLastActivityTime(lastActivityDate * 1000) : "—"}</>;
                },
                footer: (props) => props.column.id,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate],
    );

    const renderData = data?.pages
        .map((page) => {
            return page.Content.map((item) => item);
        })
        .reduce((acc, cur) => {
            return [...acc, ...cur];
        }, []);

    const getTableLabel = () => {
        if (data && data.pages[0].Total !== 0) {
            return `${data?.pages[0].Total} ${numWord(data?.pages[0].Total, ["участник", "участника", "участников"])}`;
        } else {
            return "";
        }
    };

    return (
        <>
            {(!isFiltersEmpty || (data?.pages && data?.pages.length > 0)) && (
                <div className="flex sm:hidden flex-col">
                    <div className="mb-5">
                        <h3 className="mb-5">{data?.pages[0].Total} участников</h3>
                        <Search
                            id="teamUsersTableSearchInput"
                            placeholder="Поиск по названию"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                    </div>
                    {renderData?.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className="flex justify-between items-center mb-3 last:mb-0 py-4 px-3 rounded-lg bg-background"
                                onClick={() => navigate(`/personal/${item.id}`)}
                            >
                                <div className="flex items-center">
                                    <UserAvatar
                                        className="mr-2"
                                        avatarId={item.avatarId}
                                        color={item.defaultAvatarColor}
                                        userInitials={`${item.firstName?.slice(0, 1)}${item.lastName?.slice(0, 1)}`}
                                        size={40}
                                    />
                                    <div className="flex flex-col">
                                        <p className="mb-1 p2-accent">
                                            {item.firstName} {item.lastName}
                                        </p>
                                        <p className="p4">
                                            <span className="text-gray">Пройдено курсов:&nbsp;</span>
                                            <span>{`${
                                                item.courseStatistics.courseCount
                                                    ? Number(
                                                          String(
                                                              item.courseStatistics.passedCoursesCount /
                                                                  item.courseStatistics.courseCount,
                                                          ).substring(0, 4),
                                                      ) * 100
                                                    : item.courseStatistics.courseCount
                                            }%`}</span>
                                            <span className="text-gray">{` | ${item.courseStatistics.passedCoursesCount} из ${item.courseStatistics.courseCount}`}</span>
                                        </p>
                                    </div>
                                </div>
                                <Icon icon={Icons.ChevronRight} width={14} height={14} color="fill-blue" />
                            </div>
                        );
                    })}
                </div>
            )}
            {isFiltersEmpty && renderData && renderData.length === 0 && (
                <Empty
                    title="Участников пока нет"
                    description="В команде еще нет участников. Добавьте пользователей в команду, чтобы они стали участниками"
                    topElement={
                        <div className="flex-center mb-4 2xl:mb-5">
                            <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                <Icon
                                    icon={Icons.Users}
                                    width={"36px"}
                                    height={"36px"}
                                    color={"fill-primary"}
                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                />
                            </div>
                        </div>
                    }
                    className="!px-0 max-w-[474px] mx-auto"
                ></Empty>
            )}
            {!isFiltersEmpty || (renderData && renderData.length > 0) ? (
                <InfiniteList
                    isLoading={isFetching}
                    hasMore={!data?.pages[data?.pages.length - 1].Last}
                    onLoadMore={() => {
                        fetchNextPage().then();
                    }}
                    className={"hidden sm:flex flex-center flex-col w-full pb-6"}
                    id="userTasksResults"
                >
                    <Table
                        id="adminTasks"
                        columns={columns}
                        searchTitle="Поиск по названию"
                        pageCount={data?.pages[0].Total}
                        data={renderData}
                        rowSelection={rowSelection}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        columnFilters={columnFilters}
                        emptyMessage={"По заданным параметрам результатов нет"}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                        searchAlwaysRight={true}
                        title={getTableLabel()}
                        searchRowClassName="!justify-end items-center"
                        titleClassName="grow 2xl:!text-3xl 2xl:!leading-[35px]"
                        withoutLines={true}
                    />
                </InfiniteList>
            ) : null}
        </>
    );
};
