import React from "react";
import { BaseInput, BaseInputProps } from "./BaseInput";
import { Except } from "type-fest";

export const Select = (props: Except<BaseInputProps, "as">) => {
    const { children, ...rest } = props;

    return (
        <BaseInput {...rest} as="select">
            {children}
        </BaseInput>
    );
};
