import { BaseRequest } from "../BaseRequest";
import { ID } from "types/ID";

export class TeamCreateRequest extends BaseRequest {
    public name: string;
    public teamParentId?: ID;
}

export class TeamUpdateRequest extends BaseRequest {
    public id: ID;
    public name: string;
}

export class MoveUserRequest extends BaseRequest {
    fromTeam: Record<string, string[]>;
    toTeam: Record<string, string[]>;
}
