export function secondsToHms(seconds: number): string {
    const d = Number(seconds);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + " ч." : "";
    const mDisplay = m > 0 ? m + " мин. " : "";
    const sDisplay = s > 0 ? s + " с. " : "";
    return hDisplay + mDisplay + sDisplay;
}
