import React, { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { Loader } from "Uikit/Loader/Loader";
import Skeleton from "react-loading-skeleton";
import { Preloader } from "Components/Preloader/Preloader";
import { TVoidFunction } from "types";

interface InfiniteListProps {
    className?: string;
    isLoading: boolean;
    hasMore?: boolean;
    onLoadMore: TVoidFunction;
    children: ReactNode;
    id?: string;
    isSkeletonLoader?: boolean;
    isSkeletonLoaderTable?: boolean;
}

/**
 * Component for implementing infinite scroll functionality.
 * It shows a loader whenever the next page of items is loading.
 * It calls `onLoadMore` whenever the very bottom of the list is visible on the screen.
 * That allows it to work both "windowed" (with fixed height) and when the list is changing the height of the whole page.
 * Unlike `Virtuoso` it doesn't require a fixed height.
 */
export const InfiniteList = ({
    className,
    isLoading,
    hasMore = false,
    onLoadMore,
    children,
    id,
    isSkeletonLoader = false,
    isSkeletonLoaderTable = false,
}: InfiniteListProps) => {
    const lastElementRef = useRef<HTMLDivElement>(null);
    const isLastElementOnScreen = useOnScreen(lastElementRef);

    useEffect(() => {
        if (hasMore && isLastElementOnScreen && !isLoading) {
            onLoadMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLastElementOnScreen]);

    return (
        <div className={className} id={id + "Section"}>
            {children}
            <div style={{ height: 1 }} ref={lastElementRef} />
            {!isSkeletonLoader && !isSkeletonLoaderTable && isLoading && <Loader />}
            {isSkeletonLoader && (
                <div className="relative">
                    <Preloader className="sm:grid sm:grid-cols-[repeat(4,274px)] sm:gap-7" isShow={!isLoading}>
                        {Array.from(Array(16).keys()).map((p) => {
                            return (
                                <div key={p}>
                                    <div className="w-60 sm:w-68.5 sm:h-41 rounded-2xl overflow-hidden leading-0">
                                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                    </div>
                                    <div className="leading-5 line-clamp-2 pt-3">
                                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                    </div>
                                </div>
                            );
                        })}
                    </Preloader>
                </div>
            )}
            {isSkeletonLoaderTable && (
                <div className="relative">
                    <Preloader className="mt-4" isShow={isLoading}>
                        {Array.from(Array(16).keys()).map((p) => {
                            return (
                                <div key={p} className="py-1 h-16">
                                    <Skeleton className="rounded-none" width="100%" height="100%" />
                                </div>
                            );
                        })}
                    </Preloader>
                </div>
            )}
        </div>
    );
};

const useOnScreen = (ref: RefObject<HTMLElement | null>) => {
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [isOnScreen, setIsOnScreen] = useState(false);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => {
            setIsOnScreen(entry.isIntersecting);
        });
    }, []);

    useEffect(() => {
        observerRef.current?.observe(ref.current!);

        return () => {
            observerRef.current?.disconnect();
        };
    }, [ref]);

    return isOnScreen;
};
