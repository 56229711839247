import { BaseRequest, BasePaginationRequest } from "../BaseRequest";
import { Complexity } from "Enums";

export class TaskPublicResultsListRequest extends BasePaginationRequest {}

export type TaskPublicUserAnswersRequestContent = {
    id: string;
    question: string;
    description?: string;
    orderNumber?: number;
    mandatory: boolean;
    reviewerNote?: string;
};

export type TUserAttachments = {
    id?: string;
    name?: string;
    type?: string;
    size?: number; // bites - общий вес файла
    uploaded?: number; // bites - загружено
    status?: "loading"; // 'loading'|'done'
    timeLeft?: string; // 'loading'|'done'
    base64?: string; // для изображений
    file?: File;
};

export class TaskPublicUserAnswersRequestData extends BaseRequest {
    resourceId: string;
    title: string;
    logoId: string;
    ratingPoints: number;
    complexity: Complexity;
    textContent: Array<
        TaskPublicUserAnswersRequestContent & {
            userText: string;
            allowScan: boolean;
        }
    >;
    numericContent: Array<
        TaskPublicUserAnswersRequestContent & {
            userNumber: string;
        }
    >;
    singleChoiceContent: Array<
        TaskPublicUserAnswersRequestContent & {
            answerId: string;
        }
    >;
    multipleChoiceContent: Array<
        TaskPublicUserAnswersRequestContent & {
            answerIds: string[];
        }
    >;
    imageContent: Array<
        TaskPublicUserAnswersRequestContent & {
            userAttachments: TUserAttachments[];
        }
    >;
    fileContent: Array<
        TaskPublicUserAnswersRequestContent & {
            userAttachments: TUserAttachments[];
        }
    >;
}

export type TQuestionsContent =
    | Array<
          TaskPublicUserAnswersRequestContent & {
              userText: string;
              allowScan: boolean;
          }
      >
    | Array<
          TaskPublicUserAnswersRequestContent & {
              userNumber: number;
          }
      >
    | Array<
          TaskPublicUserAnswersRequestContent & {
              answerId: string;
          }
      >
    | Array<
          TaskPublicUserAnswersRequestContent & {
              answerIds: string[];
          }
      >
    | Array<
          TaskPublicUserAnswersRequestContent & {
              userAttachments: TUserAttachments[];
          }
      >;

export type TaskPublicUserAnswersRequestAnswer = {
    questionId: string;
    answerIds: string[];
    customAnswer: string | number;
};

// Отправляемые ответы задания
export class TaskPublicUserAnswersRequest extends BaseRequest {
    exerciseId: string;
    answers: TaskPublicUserAnswersRequestAnswer[];
}

// Приём/отклонение задания при проверке админом
export class TaskReviewOperationMultipleRequest extends BaseRequest {
    exerciseIds: string[];
    isApproved: boolean;
    comment?: string;
}

// Пропуск задания при проверке админом
export class TaskReviewRequest extends BaseRequest {
    exerciseId: string;
    rating?: number;
    comment?: string;
}

// Пропуск задания при проверке админом
export class TaskReviewOperationRequest extends BaseRequest {
    exerciseId: string;
    isApproved: boolean;
    comment?: string;
    ratingPoints: number;
}
