import BaseApi from "Api/BaseApi";
import { BaseIdRequest, BasePaginationRequest } from "Api/BaseRequest";
import { BasePaginationResponse, BaseResponse } from "Api/BaseResponse";
import { TaskCreateRequest, TaskEditRequest, TaskPassRequest, TaskUnArchiveRequest } from "Api/Requests/TaskRequest";
import { TaskListResponse, TaskReadResponse } from "Api/Responses/TaskResponse";
import { SortingState } from "@tanstack/react-table";
import { ArchiveResponse } from "Api/Responses/ArchiveResponse";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class TaskApi extends BaseApi {
    List = ({
        filters,
        page,
        size,
        sort,
    }: BasePaginationRequest): Promise<BasePaginationResponse<TaskListResponse>> => {
        const params = LMSFilterParams({
            filter: filters as { [id: string]: string },
            page,
            size,
        });
        params.append("sort", sort ?? "");

        return this.Get(new BasePaginationResponse(TaskListResponse), `/all?${params.toString()}`);
    };

    Read = async (data: BaseIdRequest): Promise<TaskReadResponse> => {
        return await this.Get(TaskReadResponse, "/" + data.uuid);
    };

    Create = async (data: TaskCreateRequest): Promise<TaskReadResponse> => {
        return await this.Post(TaskReadResponse, "", data);
    };

    Edit = async (data: TaskEditRequest): Promise<TaskReadResponse> => {
        return await this.Put(TaskReadResponse, "", data);
    };

    Pass = async (_data: TaskPassRequest): Promise<BaseResponse> => {
        return {};
    };

    ArchiveList = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<ArchiveResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size });
        return this.Get(new BasePaginationResponse(ArchiveResponse), `/archive/list?${params.toString()}`);
    };

    UnArchive = async (data: TaskUnArchiveRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/unarchive", data);
    };

    Copy = async (data: BaseIdRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/copy/" + data.uuid);
    };
}
