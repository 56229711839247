import React, { ReactNode, useState } from "react";
import { Item } from "./Item";
import { useNavigate } from "react-router-dom";
import { Icon, Icons } from "../Icon/Icon";
import clsx from "clsx";

interface GroupProps {
    className?: string;
    children: ReactNode;
    title: string;
    path: string;
    content: ReactNode;
    open?: boolean;
}

export const Group = ({ className, title, path, children, open = false }: GroupProps) => {
    const navigate = useNavigate();
    const [isGroupOpen, setIsGroupOpen] = useState(open);

    return (
        <div>
            <section>
                <details open={isGroupOpen}>
                    <summary
                        onClick={(e) => {
                            e.preventDefault();
                            if (path) {
                                navigate(path);
                                return;
                            }

                            setIsGroupOpen((prevIsGroupOpen) => !prevIsGroupOpen);
                        }}
                        className={clsx(
                            "group flex items-center text-base py-4 px-2 h-12 overflow-hidden ",
                            "text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100",
                            "transition duration-300 ease-in-out cursor-pointer",
                            className,
                        )}
                    >
                        <Icon
                            icon={Icons.ChevronDown}
                            width={"12px"}
                            height={"12px"}
                            className={isGroupOpen ? "" : "-rotate-90"}
                        />
                        <span className="group-hover:text-primary pl-2 pr-3 group-[.is-active]:text-primary text-gray-dark text-base font-medium leading-[14px] transition-all ease-in-out duration-300">
                            {title}
                        </span>
                    </summary>
                    <div className={`pl-9`}>{children}</div>
                </details>
            </section>
        </div>
    );
};

Group.Item = Item;
