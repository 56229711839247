export function generateAvatar(letters, color, size = 200) {
    size = size * 4;
    let canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = size;
    canvas.height = size;

    const fontSize = Math.round(canvas.width / 2.8);
    context.font = fontSize + "px Noto Sans";
    context.textAlign = "center";
    context.textBaseline = "middle";

    // Setup background and front color
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#FFF";
    context.fillText(letters, size / 2, size / 1.88);

    // Set image representation in default format (png)
    const dataURI = canvas.toDataURL();

    // Dispose canvas element
    canvas = null;

    return dataURI;
}
