import React from "react";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { Modal } from "Uikit/Modal/Modal";
import { SecondaryButton } from "Uikit/Button/SecondaryButton";
import { SubmitButton } from "Uikit/Button/SubmitButton";
import { useMutation } from "react-query";
import Api from "Api/index";
import { TVoidFunction } from "types";

interface RemoveFullRootTeamModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    team?: Partial<TeamTreeAllBasicInfoResponse>;
}

export const RemoveFullRootTeamModal = ({ isOpen, onClose, team }: RemoveFullRootTeamModalProps) => {
    const { mutateAsync: removeTeam, isLoading } = useMutation(() => {
        return Api.Teams.Remove(team!.id!);
    });

    const handleSubmit = async () => {
        await removeTeam();
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Удаление корневой команды" description={team?.name}>
            <Modal.Body>
                Вы действительно хотите удалить корневую команду? Также будут удалены все вложенные в нее команды (если
                они есть). Пользователи будут исключены из команды и вложенных в неё команд.
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton onClick={onClose} disabled={isLoading} id="removeFullRootTeamBtnCancel">
                    Отмена
                </SecondaryButton>
                <SubmitButton onClick={handleSubmit} isLoading={isLoading} color="danger" id="removeFullRootTeamBtnOk">
                    Удалить
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};
