import React, { useState } from "react";
import { Table } from "Uikit/Table/Table";
import { ColumnDef, ColumnFiltersState, PaginationState, Row, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import { Button, Checkbox, Icon, Icons } from "Uikit";
import { AddAchievementModal } from "./Modal/AddAchievementModal";
import { RemoveAchievementModal } from "./Modal/RemoveAchievementModal";
import Api from "../../../Api";
import { UserAchievementResponse } from "Api/Responses/AchievementResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";

interface MemberAchievementsProps {
    className?: string;
}

export const MemberAchievements = ({ className }: MemberAchievementsProps) => {
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<SortingState>([]);

    const [giveAchievementModalOpen, setGiveAchievementModalOpen] = useState(false);
    const [cancelAchievementData, setCancelAchievementData] = useState<Row<UserAchievementResponse>>();
    const [takeAwayAchievementModalOpen, setTakeAwayAchievementModalOpen] = useState(false);

    const fetchUserAchievementsList = async (count: number, offset: number, sort: any) => {
        return await Api.Achievement.UserList("", offset, count, sort, {});
    };

    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const data = useQuery(
        ["data", pagination, sorting],
        () => fetchUserAchievementsList(pageIndex, pageSize, sorting),
        {
            keepPreviousData: true,
        },
    );

    const columns = React.useMemo<ColumnDef<UserAchievementResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Ачивка",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <img className="rounded-full" width={"40px"} height={"40px"} />
                            <span className="group-hover:text-blue">{info.row.original.achievement.name}</span>
                        </div>
                    );
                },
                accessorKey: "title",
            },
            {
                header: "Кто выдал",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <div className="flex items-center space-x-3">
                            <img
                                className="rounded-full"
                                width={"40px"}
                                height={"40px"}
                                src={info.row.original.givenBy.avatarId}
                            />
                            <span>
                                {String(info.row.original.givenBy.firstName + " " + info.row.original.givenBy.lastName)}
                            </span>
                        </div>
                    );
                },
                accessorKey: "giver",
            },
            {
                header: "Комментарий",
                accessorKey: "comment",
                footer: (props) => props.column.id,
            },
            {
                header: "Выдана",
                accessorKey: "date",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <div className="flex items-center space-x-3 truncate">
                            <p className="font-normal">
                                {info.row.original.givenAt
                                    ? formatDate(info.row.original.givenAt, DateFormat.DATE_TIME_LONG)
                                    : null}
                            </p>
                        </div>
                    );
                },
            },
            {
                header: "",
                id: "buttons",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 30,
                cell: (info) => {
                    return (
                        <div className="flex" id="adminNewMemberAchievementTabTableGroupButtons">
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Close}
                                        width="10px"
                                        height="10px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    setCancelAchievementData(info.row);
                                    setTakeAwayAchievementModalOpen(true);
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const controlButtons = (
        <div className="flex justify-end items-center space-x-5" id="memberPageAchievementTableControlButtons">
            <Button
                size={"medium"}
                className="rounded-lg whitespace-nowrap font-medium"
                icon={<Icon className="mr-1.5" icon={Icons.PlusFilled} color="fill-white" width={17} height={17} />}
                iconPlacement={"left"}
                onClick={() => setGiveAchievementModalOpen(true)}
                id="adminNewMemberAchievementTabBtnGiveAchieve"
            >
                Выдать ачивку
            </Button>
        </div>
    );

    return (
        <div className={className} id="memberPageAchievement">
            {data.data?.Total && data.data?.Total > 0 ? (
                <Table
                    id="adminNewMemberAchievementTab"
                    columns={columns}
                    searchTitle="Поиск по имени"
                    pageCount={data.data?.Total}
                    data={data.data?.Content}
                    pagination={pagination}
                    onPaginationChange={setPagination}
                    isFetching={data.isFetching}
                    rowSelection={rowSelection}
                    onRowSelectionChange={setRowSelection}
                    onColumnFiltersChange={setColumnFilters}
                    columnFilters={columnFilters}
                    controlButtons={controlButtons}
                    emptyMessage={"По заданным параметрам результатов нет"}
                    sorting={sorting}
                    onSortingChange={setSorting}
                />
            ) : (
                <div className={"flex flex-col justify-center items-center my-auto text-center px-64 mt-60"}>
                    <h2>Ачивок пока не назначено</h2>

                    <p className="pt-4 pb-8">
                        Выдавайте участнику ачивки, повышайте мотивацию, награждайте за успехи и заслуги. Ачивки
                        отображаются в личном кабинете участника
                    </p>

                    <Button
                        size={"medium"}
                        className="rounded-lg whitespace-nowrap font-medium"
                        icon={
                            <Icon
                                className="mr-1.5"
                                icon={Icons.PlusFilled}
                                color="fill-white"
                                width={17}
                                height={17}
                            />
                        }
                        iconPlacement={"left"}
                        onClick={() => setGiveAchievementModalOpen(true)}
                        id="adminNewMemberAchievementTabBtnGiveAchieve"
                    >
                        Выдать ачивку
                    </Button>
                </div>
            )}

            <AddAchievementModal
                id="memberPageAddAchievementModal"
                isOpen={giveAchievementModalOpen}
                setIsOpen={setGiveAchievementModalOpen}
            />
            <RemoveAchievementModal
                id="memberPageRemoveAchievementModal"
                isOpen={takeAwayAchievementModalOpen}
                setIsOpen={setTakeAwayAchievementModalOpen}
                achievement={cancelAchievementData}
            />
        </div>
    );
};
