import { Expose, Type } from "class-transformer";

export class ReviewCountResponse {
    @Expose()
    @Type(() => Number)
    public allTab: number;

    @Expose()
    @Type(() => Number)
    public quizTab: number;

    @Expose()
    @Type(() => Number)
    public exerciseTab: number;

    @Expose()
    @Type(() => Number)
    public feedbackTab: number;

    @Expose()
    @Type(() => Number)
    public commentTab: number;
}
