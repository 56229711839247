import React, { useEffect, useMemo, useRef, useState } from "react";
import { ColumnDef, ColumnFiltersState, Table as TanstackTable } from "@tanstack/react-table";
import { Button, Checkbox, Icon, Icons, Table } from "Uikit";
import { useQuery } from "react-query";
import { ID } from "types/ID";
import Api from "Api/index";
import { UserListResponse } from "Api/Responses/UserResponse";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { OfficeMultiselect } from "Components/Common/OfficeMultiselect";
import { JobMultiSelect } from "Components/Common/JobMultiSelect";
import intersection from "lodash/intersection";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { BasePaginationRequest } from "Api/BaseRequest";

interface AccessOpenTableProps {
    resourceId: ID;
    onChange: (selectedUses: ID[], oldUsers: ID[]) => void;
    hideTeamMembers?: boolean;
}

export const AccessOpenTable = ({ resourceId, onChange, hideTeamMembers = false }: AccessOpenTableProps) => {
    const tableInstanceRef = useRef<TanstackTable<any> | null>(null);

    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [users, setUsers] = useState<UserListResponse[]>([]);
    const [usersFiltered, setUsersFiltered] = useState<UserListResponse[]>([]);
    const [teamsOnly, setTeamsOnly] = useState<ID[]>([]);
    const [jobsOnly, setJobsOnly] = useState<ID[]>([]);
    const [officesOnly, setOfficesOnly] = useState<ID[]>([]);
    const [search, setSearch] = useState("");

    const { data: { users: skipUsers = [] } = {}, isFetching: loadingSkipUsers } = useQuery(
        ["userAccess", "collection"],
        () => Api.LMSRoles.getUserAccess(resourceId, new BasePaginationRequest()),
        {
            onSuccess: (_data) => {
                const users = allUsers;
                setUsers(users);
                setUsersFiltered(users);
            },
        },
    );

    const { data: { Content: allUsers = [] } = {}, isFetching: loadingUsers } = useQuery(
        ["users", "collection", search],
        () => {
            const filtersData: any = {};
            if (search.length > 0) {
                filtersData["searchQuery.contains"] = search;
            }
            filtersData["state.equal"] = "ACTIVE";

            return Api.User.GetList(0, 2000, [], filtersData);
        },
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                const users = data.Content;
                setUsers(users);
                setUsersFiltered(users);
            },
        },
    );

    const { data: userAccess } = useQuery(
        ["userAccess", "collection"],
        () => Api.LMSRoles.getUserAccess(resourceId, new BasePaginationRequest()),
        {
            enabled: !!resourceId,
        },
    );

    const { data: teamAccess } = useQuery(["teamAccess", resourceId], () => Api.LMSRoles.getTeamAccess(resourceId), {
        enabled: !!resourceId,
    });

    const columns = useMemo<ColumnDef<UserListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <div
                        className={
                            "flex items-center relative h-9 after:content-[''] after:hidden after:absolute after:top-0 after:-left-2 after:w-[calc(100%+8px)] after:h-full after:rounded-l-lg after:bg-[#F5F7F9] group-hover:after:z-0 after:z-0 group-hover:after:block"
                        }
                    >
                        <div className="relative z-10">
                            {skipUsers.findIndex((test) => test.user.id === row.original.id) >= 0 ? (
                                <Checkbox className="pl-2" checked disabled />
                            ) : (
                                <Checkbox
                                    className="pl-2"
                                    checked={
                                        row.getIsSelected() ||
                                        skipUsers.findIndex((test) => test.user.id === row.original.id) >= 0
                                    }
                                    indeterminate={row.getIsSomeSelected()}
                                    onChange={row.getToggleSelectedHandler()}
                                />
                            )}
                        </div>
                    </div>
                ),
            },
            {
                header: () => <div className="flex h-9 items-center">Участник</div>,
                footer: (props) => props.column.id,
                accessorKey: "firstName",
                cell: ({ row, row: { original: user } }) => {
                    return (
                        <div
                            className={
                                "group flex items-center relative h-9 after:content-[''] after:hidden after:absolute after:top-0 after:-left-2 after:w-[calc(100%+8px)] after:h-full after:bg-[#F5F7F9] group-hover:after:z-0 after:z-0 group-hover:after:block"
                            }
                            onClick={row.getToggleSelectedHandler()}
                        >
                            <div className="flex items-center space-x-3 relative z-10 group-hover:text-blue">
                                <UserAvatar
                                    avatarId={user.avatarId}
                                    color={user.defaultAvatarColor}
                                    userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                    size={28}
                                />
                                <div
                                    className="line-clamp-1 cursor-pointer"
                                    // onClick={() => navigate(`/admin/members/${user.id}/edit`)}
                                >
                                    {String(user.lastName + " " + user.firstName)}
                                </div>
                            </div>
                        </div>
                    );
                },
                accessorFn: (row: any) => `${row.firstName} ${row.lastName}`,
                enableSorting: false,
            },
            {
                header: "Доступ",
                id: "buttons",
                accessorKey: "access",
                enableResizing: true,
                size: 120,
                cell: ({ row: { original: user } }) => {
                    const gotUserAccess = userAccess?.users?.find((access) => access.user.id === user.id);
                    const gotTeamAccess =
                        teamAccess?.allTeams ||
                        intersection(
                            user.teams.map((t) => t.id),
                            teamAccess?.teams ?? [],
                        ).length > 0;
                    let accessTitle = "";
                    if (gotUserAccess) {
                        accessTitle = "Индивидуальный";
                    } else if (gotTeamAccess) {
                        accessTitle = "Командный";
                    }

                    return (
                        <div className="flex items-center relative h-9 after:content-[''] after:hidden after:absolute after:top-0 after:-right-2 after:w-[calc(100%+8px)] after:h-full after:bg-[#F5F7F9] after:rounded-r-lg group-hover:after:z-0 after:z-0 group-hover:after:block">
                            <div className="flex items-center relative z-10 gap-2">
                                <span className="p2 text-gray group-hover:hidden">{accessTitle}</span>
                                <a
                                    className="hidden items-center gap-1.25 group-hover:flex cursor-pointer no-underline hover:no-underline"
                                    href={`/admin/member/${user.id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Icon icon={Icons.ShareBox} width={17} height={17} color="fill-blue" />
                                    Посмотреть
                                </a>
                            </div>
                        </div>
                    );
                },
                enableSorting: false,
            },
        ],
        [skipUsers, userAccess, teamAccess],
    );

    useEffect(() => {
        setUsersFiltered(() => {
            let usersFiltered = users;
            if (teamsOnly.length > 0) {
                usersFiltered = usersFiltered.filter((test) => test.teams.some((test) => teamsOnly.includes(test.id)));
            }
            if (officesOnly.length > 0) {
                usersFiltered = usersFiltered.filter((test) => officesOnly.includes(test.officeId));
            }
            if (jobsOnly.length > 0) {
                usersFiltered = usersFiltered.filter(({ jobTitle = {} }) => {
                    return jobTitle?.id && jobsOnly.includes(jobTitle?.id);
                });
            }
            return usersFiltered;
        });
    }, [users, teamsOnly, officesOnly, jobsOnly /* , skipUsers */]);

    useEffect(() => {
        const selectedUsers: UserListResponse[] = [];
        for (const id in rowSelection) {
            const rowUser = usersFiltered.find((u) => u.id === id);

            if (rowUser) {
                selectedUsers.push(rowUser);
            }
        }
        onChange(
            selectedUsers.map((user) => user?.id),
            skipUsers.map((element) => element.user?.id),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelection /* users, skipUsers */]);

    const controlButtons = (
        <div className="flex w-full justify-start items-center space-x-2">
            <div className="max-w-42 w-full">
                <TeamMultiSelect
                    value={teamsOnly}
                    onChange={(v) => Array.isArray(v) && setTeamsOnly(v.map((v) => v))}
                    showTeams={false}
                    hideMembers={hideTeamMembers}
                />
            </div>
            <div className="max-w-42 w-full">
                <OfficeMultiselect onChange={setOfficesOnly} />
            </div>
            <div className="max-w-42 w-full">
                <JobMultiSelect onChange={setJobsOnly} />
            </div>
        </div>
    );

    const selectedRowButtons = (
        <div className="flex justify-end items-center space-x-5">
            <Button
                size="medium"
                color="danger"
                className="rounded-lg whitespace-nowrap font-medium"
                onClick={() => {
                    console.log("Забрать доступ");
                }}
            >
                Забрать доступ
            </Button>
        </div>
    );

    return (
        <div className="h-135">
            <Table
                columns={columns}
                searchTitle="Поиск по участникам"
                data={usersFiltered}
                isFetching={loadingUsers || loadingSkipUsers}
                rowSelection={rowSelection}
                onRowSelectionChange={setRowSelection}
                onColumnFiltersChange={setColumnFilters}
                columnFilters={columnFilters}
                controlButtons={controlButtons}
                selectButtons={selectedRowButtons}
                emptyMessage="По заданным параметрам результатов нет"
                rowClassName="!p-0 border-none group"
                searchAlwaysLeft={true}
                controlsWrapperClassNames={"mt-6 flex-col gap-4 grow"}
                setTableInstance={(tableInstance: TanstackTable<any>) => {
                    tableInstanceRef.current = tableInstance;
                }}
                searchClassName="!w-full"
                tableWrapperClassName="overflow-y-auto overflow-x-hidden -ml-2"
                columnClassName="sticky top-0 bg-white !opacity-100 first-of-type:pl-2 pl-0 py-0"
                theadClassName="sticky z-40 pb-4"
                onSearch={(searchQuery: string) => setSearch(searchQuery)}
                searchValue={search}
            />
        </div>
    );
};
