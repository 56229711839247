import React from "react";
import clsx from "clsx";
import { TaskPublicListResponse } from "Api/Responses/TaskPublicResponse";
import { Icon, Icons } from "Uikit";
import { ProgressStatus } from "Enums";
import { TaskCardTime } from "./TaskCardTime";
import { formatDeadlineTime } from "./utils";
import { formatStatusChangeTime } from "helpers/dateHelper";
import { FavoriteButton } from "Uikit/FavoriteButton/FavoriteButton";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

interface ITaskCardProps {
    task: TaskPublicListResponse;
    onClick: (solutionId: string, resourceId: string) => void;
    showStatusChangeTime?: boolean;
    isDone?: boolean;
    statusChangeTimePre?: string;
    onFavoriteChange?: (test: TaskPublicListResponse, isFavorite: boolean) => void;
}

export const TaskCard = ({
    task,
    onClick,
    showStatusChangeTime = false,
    statusChangeTimePre = "Отправлено",
    isDone = false,
    onFavoriteChange,
}: ITaskCardProps) => {
    const {
        id,
        resourceId,
        logoId,
        ratingPoints,
        progressStatus,
        title,
        deadlineTime,
        statusChangeTime,
        hasNewLabel,
        passingLimit,
        passingNumber,
    } = task;

    const onFavoriteClick = (isFavorite: boolean) => {
        onFavoriteChange?.(task, isFavorite);
    };

    return (
        <div
            className="group w-full sm:w-[274px] 2xl:w-[342px] mb-5 sm:mb-0 cursor-pointer"
            onClick={() => onClick(id, resourceId)}
        >
            <div className="relative w-full sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 mb-3">
                <div className="relative w-full h-full bg-gray-lighter rounded-2xl 2xl:rounded-3xl overflow-hidden">
                    <img
                        src={Api.Upload.GetLogo(logoId, LogoSize.THUMB_MINI)}
                        className={clsx(
                            "w-full h-full object-cover group-hover:scale-125 transition-transform ease-in-out",
                            isDone && "grayscale ease-in-out duration-200 hover:grayscale-0",
                        )}
                    />
                    {progressStatus === ProgressStatus.IN_PROGRESS && (
                        <div className="w-full absolute bottom-0 h-2 after:content-[''] after:block after:bg-primary after:w-1/2 after:h-full bg-blue-gray"></div>
                    )}
                </div>
                <div className="absolute top-3 left-3 right-3 2xl:top-3.75 2xl:left-3.75 2xl:right-3.75 flex flex-col items-start gap-1.5 2xl:gap-2">
                    {showStatusChangeTime && !!statusChangeTime && (
                        <TaskCardTime>
                            <span>
                                {statusChangeTimePre} {formatStatusChangeTime(statusChangeTime)}
                            </span>
                        </TaskCardTime>
                    )}

                    {!!deadlineTime && (
                        <TaskCardTime time={deadlineTime}>
                            <>
                                <Icon
                                    icon={Icons.Time}
                                    width={"14px"}
                                    height={"14px"}
                                    color={"fill-white 2xl:!w-4.5 2xl:!h-4.5"}
                                />
                                <span>{formatDeadlineTime(deadlineTime)}</span>
                            </>
                        </TaskCardTime>
                    )}
                </div>
                <div className="absolute right-3 top-3 2xl:right-3.75 2xl:top-3.75">
                    <FavoriteButton
                        className={clsx(
                            "opacity-0 transition-opacity",
                            task.isFavorite ? "!opacity-100" : "group-hover:opacity-100",
                        )}
                        isFavorite={task.isFavorite}
                        onChange={onFavoriteClick}
                    />
                </div>
                <div className="absolute bottom-3 left-3 right-3 2xl:bottom-3.75 2xl:left-3.75 2xl:right-3.75 flex gap-1.5 2xl:gap-2 h-5.25 2xl:h-6.5">
                    {hasNewLabel && (
                        <div className="flex items-center rounded-md bg-red text-white px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 p3 2xl:rounded-lg 2xl:text-2sm">
                            <span>NEW</span>
                        </div>
                    )}
                    {!!ratingPoints && (
                        <div className="rounded-md 2xl:rounded-lg bg-black-50 text-white flex items-center gap-1 2xl:gap-1.25 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                            <Icon icon={Icons.TrophyFilled} width={"14px"} height={"14px"} color={"fill-white"} />
                            <span>{ratingPoints}</span>
                        </div>
                    )}
                    {Number(passingLimit) !== 1 && (
                        <div className="rounded-md 2xl:rounded-lg bg-black-50 text-white flex items-center gap-0.5 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 ml-auto 2xl:text-2sm">
                            <span>{passingNumber} прохождение</span>
                        </div>
                    )}
                </div>
            </div>
            <MultiClumpTooltip label={title} textClassName="2xl:text-2md" />
        </div>
    );
};
