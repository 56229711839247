import React, { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    className?: string;
}

export const ModalFooter = ({ children, className, ...rest }: FooterProps) => {
    return (
        <div
            className={clsx(
                "border-t-[1px] border-[#EAEDF3] px-6 2xl:px-7.5 py-4 2xl:py-5 flex space-x-4 justify-end",
                className,
            )}
            {...rest}
        >
            {children}
        </div>
    );
};
