import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { Button, Icon, Icons } from "Uikit";
import { Table } from "Uikit/Table/Table";
import Api from "../../../Api";
import { BaseIdPaginationRequest } from "Api/BaseRequest";
import { AccessModal } from "./Modal/AccessModal";
import { Resource } from "Api/Responses/lmsRoles/ResourceAccess";
import { formatDate } from "helpers/dateHelper";
import { LogoSize } from "Api/Services/UploadApi";

interface IMemberAccessTableProps {
    type: string;
    data: Resource[];
}

export const MemberAccessTable = ({ data, type }: IMemberAccessTableProps) => {
    const [accessAction, setAccessAction] = useState<"grant" | "revoke">("grant");
    const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState<Resource[]>([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const fetchUserAccessList = async (count: number, offset: number, sort: any) => {
        const request = new BaseIdPaginationRequest();
        request.uuid = "0";
        request.page = count;
        request.size = offset;
        request.sort = sort;

        return await Api.User.AccessList(request);
    };

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const dataQuery = useQuery(["data", pagination, sorting], () => fetchUserAccessList(pageIndex, pageSize, sorting), {
        keepPreviousData: true,
    });

    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const columns = useMemo<ColumnDef<Resource>[]>(
        () => [
            {
                header: "Название",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <div className="group flex items-center gap-4">
                            <img
                                className="rounded-[5px]"
                                width={"54px"}
                                height={"40px"}
                                src={Api.Upload.GetLogo(info.row.original.imageId ?? "", LogoSize.THUMB_MICRO)}
                            />
                            <span className="group-hover:text-blue">{info.row.original.name}</span>
                        </div>
                    );
                },
                accessorKey: "Title",
            },
            {
                header: "Оценка",
                footer: (props) => props.column.id,
                cell: (_info) => {
                    return <>--</>;
                },
                accessorKey: "Grade",
            },
            {
                header: "Баллы",
                footer: (props) => props.column.id,
                cell: (_info) => {
                    return <>--</>;
                },
                accessorKey: "Score",
            },
            {
                header: "Обновлён",
                footer: (props) => props.column.id,
                cell: (info) => {
                    const { updateTime } = info.row.original;
                    const dateStr = updateTime ? formatDate(updateTime) : "-";
                    return <>{dateStr}</>;
                },
                accessorKey: "Updated",
            },
            {
                header: "Прогресс",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return <>{info.row.original.progress}</>;
                },
                accessorKey: "Progress",
            },
            {
                header: "",
                id: "TargetItemId",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return (
                        <a
                            href={`/admin/news/${info.row.original.id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center gap-2 "
                        >
                            <Icon className="" icon={Icons.ShareBox} width={"12px"} height={"12px"} />
                            Посмотреть
                        </a>
                    );
                },
            },
            {
                header: "",
                id: "buttons",
                enableResizing: true,
                size: 30,
                cell: (info) => {
                    return (
                        <div className="flex">
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Close}
                                        width="10px"
                                        height="10px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    setAccessAction("revoke");
                                    setSelectedRows([info.row.original]);
                                    setIsAccessModalOpen(true);
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <Table
                columns={columns}
                data={data}
                // pageCount={dataQuery.data?.Total}
                // data={dataQuery.data?.Content}
                // pagination={pagination}
                onPaginationChange={setPagination}
                isFetching={dataQuery.isFetching}
                rowSelection={rowSelection}
                onRowSelectionChange={setRowSelection}
                onColumnFiltersChange={setColumnFilters}
                columnFilters={columnFilters}
                emptyMessage={"На пользователя ничего не назначено"}
                rowTrClassName="group"
                bodyCellClassName="group-[.last]:border-0"
                noSearch
                sorting={sorting}
                onSortingChange={setSorting}
            />
            {isAccessModalOpen && (
                <AccessModal
                    isOpen={isAccessModalOpen}
                    setIsOpen={setIsAccessModalOpen}
                    selectedItems={selectedRows}
                    accessAction={accessAction}
                    type={type}
                />
            )}
        </>
    );
};
