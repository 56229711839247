import BaseApi from "../BaseApi";
import { BaseIdRequest } from "../BaseRequest";
import { AccessGetCommandResponse } from "../Responses/AccessResponse";
import { fetchUserAccessData } from "Uikit/Table/fetchTestData";

export default class AccessApi extends BaseApi {
    GetCommand = async (_data: BaseIdRequest): Promise<AccessGetCommandResponse> => {
        return await fetchUserAccessData();
    };
}
