import React from "react";
import { MultiValueGenericProps } from "react-select";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

export interface IMultiLabelOption {
    readonly value: string;
    readonly label: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

interface ISelectImgMultiValueLabelProps {
    withImg?: boolean;
}

// Кастомный компонент для react-select
// Отображение выбранного значения в мультиселекте с аватаркой/без аватарки
export const SelectImgMultiValueLabel =
    ({ withImg = true }: ISelectImgMultiValueLabelProps) =>
    (props: MultiValueGenericProps<IMultiLabelOption>) => {
        const {
            data: { logoId, name },
            // innerProps
        } = props;

        return (
            <div className={"flex items-center py-1 pl-1.5 gap-2 cursor-pointer !shrink-0"}>
                {withImg && (
                    <img
                        className="rounded w-7 h-5"
                        src={Api.Upload.GetLogo(logoId, LogoSize.THUMB_MICRO)}
                        alt={name}
                    />
                )}

                <span>{name}</span>
            </div>
        );
    };
